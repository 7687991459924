import React from 'react';
import { object } from 'prop-types';

// Components
import { Switch, Route } from 'react-router-dom';
import { useDifcamHotjar } from '@/utils/pixels/difcam';

// Containers
import SettingsMenu from './containers/SettingsMenu';

// Scenes
import BasicPage from './scenes/BasicInformation';
import GoalsPage from './scenes/Goals';
import ParticipantsOnboardingPage from './scenes/Onboarding';
import ExponentPage from './scenes/Exponents';
import DiffusionPage from './scenes/Diffusion';
import { ReportingsPage } from './scenes/Reporting';
import MatchingPage from './scenes/MatchingCriteria';
import ValidationPage from './scenes/Validation';
import PartnerPage from './scenes/Partners';
import ModulesPage from './scenes/Modules/scenes';
import ModulesDefaultRedirectionPage from './scenes/Modules/scenes/DefaultRedirection';
import FeedbackPage from './scenes/Modules/scenes/Feedback';
import CornerPage from './scenes/Modules/scenes/Corner';
import LivetalkPage from './scenes/Modules/scenes/LiveTalks';
import PartnerModule from './scenes/Modules/scenes/Partner';
import DashboardCandidate from './scenes/Modules/scenes/DashboardCandidate';
import LimitsOrganizationsPage from './scenes/Limits/scenes/Organizations';
import LimitsCandidatesPage from './scenes/Limits/scenes/Candidates';
import LimitsOwnersPage from './scenes/Limits/scenes/Owners';

// Styles & Translations
import styles from './styles.less';
import NotFound from '@/scenes/NotFound/Loadable';

const OwnerSettingsScene = ({
  match: { path },
  event,
  authUser,
}) => {
  useDifcamHotjar(event.slug);
  const { isAdmin } = authUser;

  return (
    <div className={styles.container}>
      {!window.location.pathname.includes('/owner/settings/matching/preview') ? <SettingsMenu event={event} isAdmin={isAdmin} /> : ''}
      <Switch>
        <Route exact path={`${path}`} component={BasicPage} />
        <Route path={`${path}/goals`} component={GoalsPage} />
        <Route path={`${path}/onboarding`} component={ParticipantsOnboardingPage} />
        <Route path={`${path}/diffusion`} component={DiffusionPage} />
        {isAdmin && <Route path={`${path}/reportings`} component={ReportingsPage} />}
        <Route path={`${path}/exponents`} component={ExponentPage} />
        <Route path={`${path}/matching`} component={MatchingPage} />
        <Route path={`${path}/validation`} component={ValidationPage} />
        <Route path={`${path}/partners`} component={PartnerPage} />
        <Route path={`${path}/modules/landing`} component={ModulesDefaultRedirectionPage} />
        <Route path={`${path}/modules/corner`} component={CornerPage} />
        <Route path={`${path}/modules/feedback`} component={FeedbackPage} />
        <Route path={`${path}/modules/conference`} component={LivetalkPage} />
        <Route path={`${path}/modules/partner`} component={PartnerModule} />
        <Route path={`${path}/modules/dashboardCandidate`} component={DashboardCandidate} />
        <Route path={`${path}/modules`} component={ModulesPage} />
        <Route path={`${path}/limits/candidates`} component={LimitsCandidatesPage} />
        <Route path={`${path}/limits/organizations`} component={LimitsOrganizationsPage} />
        <Route path={`${path}/limits/owners`} component={LimitsOwnersPage} />
      </Switch>
    </div>
  );
};

OwnerSettingsScene.propTypes = {
  match: object,
  event: object,
  authUser: object,
};

export default OwnerSettingsScene;
