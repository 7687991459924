import React from 'react';
import { string, func, object, bool } from 'prop-types';

// utils
import Dropzone from 'react-dropzone';
import { Form } from 'antd';
import { useFormatMessage } from 'react-intl-hooks';
import S3 from '@/services/S3';

// components
import CoverEditor from '@/components/CoverEditor';
import Icon from '@/components/Icon';

import styles from '../../../../../../styles.less';

const FormItem = Form.Item;

const Cover = ({
  required = true,
  isFieldTouched,
  getFieldError,
  pictureUrl,
  imageUrl,
  handleOnDrop,
  organization,
  getFieldDecorator,
  initialValue,
  generateFileName,
  shouldCloseModalAfterSubmitting = false,
  form,
  authToken,
}) => {// eslint-disable-line
  const t = useFormatMessage();
  const error = isFieldTouched('cover') && getFieldError('cover');

  const onDrop = (type) => (files, callback) => {
    const { setFieldsValue } = form;
    const file = files[0];
    const s3Url = S3.getS3Url(generateFileName(file), 'organization', organization._id, authToken);

    s3Url.then((url) => {
      setFieldsValue({
        [type]: url
      });
      handleOnDrop({
        [type]: url,
        wasEdit: true
      });

      if (shouldCloseModalAfterSubmitting) {
        callback();
      }
    });
  };

  return (
    <>
      <CoverEditor
        value={imageUrl}
        onChange={onDrop('cover')}
      />
      <FormItem
        validateStatus={error ? 'error' : ''}
        help={error || ''}
      >
        {getFieldDecorator('cover', {
          initialValue,
          rules: [
            { required, message: t({ id: 'event.recruiter.preparation.stand.item.cover.error' }) },
          ],
        })(
          <input type="hidden" />
        )}
      </FormItem>
      <Dropzone
        accept="image/png, image/jpeg, image/gif"
        onDrop={onDrop('pictureUrl')}
        className={styles.logoZone}
        activeClassName={styles.activeDropzone}
        style={{ backgroundImage: `url(${pictureUrl})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }}
      >
        <div className={styles.help}>
          <Icon name="upload" />
          {t({ id: 'event.recruiter.preparation.stand.item.logo.label' })} <span className="mandatory-symbol">*</span>
        </div>
      </Dropzone>
    </>
  );
};

Cover.propTypes = {
  initialValue: string,
  authToken: string,
  pictureUrl: string,
  imageUrl: string,
  handleOnDrop: func,
  getFieldDecorator: func,
  getFieldError: func,
  generateFileName: func,
  required: bool,
  isFieldTouched: bool,
  shouldCloseModalAfterSubmitting: bool,
  form: object,
  organization: object,
};

export { Cover };
