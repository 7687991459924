import React, { useEffect } from 'react';
import { array, object } from 'prop-types';
import { useSelector } from 'react-redux';
import { compose } from 'redux';
import { H3, Body1, Button } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { getCurrentParticipant } from '@/store/participant/selectors';
import styles from './styles.less';
import { SearchRow } from '@/components/ParticipantCards/components/components';
import {
  AvatarWithMainInfo,
  Description, Educations, Positions,
} from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/ProfileCheck/components';
import { toJS } from '@/utils';
import { Bloc } from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/components/Bloc';
import Pdf from '@/components/Pdf';
import {
  FooterNav
} from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/components/FooterNav/FooterNav';
import { useHistory } from 'react-router-dom';
import Wrapper from '@/components/Wrapper';
import { getUserResumeUrl } from "@/helpers/resume";
import { getAuthToken } from "@/store/auth/selectors";

const ProfileCheck = ({
  authUser,
  defaultCriteria,
}) => {
  const t = useFormatMessage();
  const history = useHistory();
  const authToken = useSelector(getAuthToken);
  const participant = useSelector(getCurrentParticipant)?.toJS();

  useEffect(() => {
    if (!authUser.resumeS3Key && !authUser.resumeUrl) {
      history.push('profile');
    }
  }, [])

  if (participant === undefined) return null;

  return (
    <Wrapper size="large">
      <div className="commonStep">
        <H3 className="educationTitle mb-8">
          { t({ id: 'profile.up.to.date' })}
        </H3>
        <Body1 className="text-neutral-400 mb-30">{t({ id: 'onboarding.checking.title' })}</Body1>
        <Bloc maxWidth="100%">
          <div className={styles.content}>
            <AvatarWithMainInfo participant={participant} />
            <SearchRow participant={participant} authUser={authUser} user={authUser} defaultCriteria={defaultCriteria} />
            <hr />
            <Description participant={participant} />
            <Positions participant={participant} />
            <Educations participant={participant} />
            <div className={styles.pdfWithoutBorder} style={{ marginTop: '20px' }}>
              <Pdf
                name="CV"
                downloadSrc={getUserResumeUrl(authToken, false)}
                src={getUserResumeUrl(authToken, true)}
                showTop
                participant={participant}
              />
            </div>
          </div>
        </Bloc>

        <FooterNav>
          <div />
          <div>
            <Button onClick={() => history.push('profile')} color="neutral" variant="outline">{t({ id: 'edit' })}</Button>
            <Button onClick={() => history.push('matching')} type="submit">{t({ id: 'continue' })}</Button>
          </div>
        </FooterNav>
      </div>
    </Wrapper>
  );
}

ProfileCheck.propTypes = {
  authUser: object,
  defaultCriteria: array,
}

export default compose(
)(toJS(ProfileCheck))

