import React from 'react';
import { func } from 'prop-types';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import BlankState from '@/components/BlankState';
import styles from './styles.less';
import messages from './messages';


const BlanksStatePreventAddingExponent = ({ action }) => (
  <BlankState
    className={styles.blankPreventAddingJD}
    content={<FormattedHTMLMessage {...messages.blankStatePreventAddingExponent} />}
    icon={'blank-state-cactus'}
    buttonLabel={<FormattedMessage {...messages.blankStatePreventAddingExponentBtn} />}
    handleAction={action}
    buttonType="default"
  />
);

const BlanksStateNoExponent = ({ action }) => (
  <BlankState
    content={<FormattedHTMLMessage {...messages.blankState} />}
    icon={'blank-state-plus'}
    buttonLabel={<FormattedMessage {...messages.addExponent} />}
    handleAction={action}
    buttonType="default"
  />
);

BlanksStatePreventAddingExponent.propTypes = {
  action: func,
};
BlanksStateNoExponent.propTypes = {
  action: func,
};

export {
  BlanksStatePreventAddingExponent,
  BlanksStateNoExponent,
};
