import { USERS_EVENT_ROLES, USERS_TYPE } from '@/utils/constants';
import { arrayContain } from '@/utils/math';
import { isUserRecruiter } from '@/store/availableActions/utils/user/isUserRecruiter';

export function getUserType(authUser, event) {
  if (arrayContain(event.userContext.roles, [USERS_EVENT_ROLES.owner, USERS_EVENT_ROLES.admin])) {
    return USERS_TYPE.owner;
  }

  if (event.userContext.roles.includes(USERS_EVENT_ROLES.recruiter) || isUserRecruiter(authUser)) {
    return USERS_TYPE.recruiter;
  }

  return USERS_TYPE.candidate;
}
