import React from 'react';
import { bool, func, object } from 'prop-types';
import { Modal } from 'antd';
import { Button } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';

import styles from './styles.less';

const ModalFormatNotAvailable = ({
  event,
  onCancel,
  onOk,
  isOpen,
  authUser,
}) => {
  const t = useFormatMessage();
  return (
    <Modal
      visible={isOpen}
      width={647}
      footer={false}
      className={`customConfirm ${styles.modalFormatNotAvailableContainer}`}
      okText={t({ id: 'add' })}
      cancelText={t({ id: 'cancel' })}
    >
      <h4>Le format de RDV n’est pas disponible sur la période sélectionnée</h4>
      <ul>
        <li>
          {t({ id: 'modal.badFormat.physical'}, 
            {
              dateOne: moment(event?.keyMoments?.jobfair[0].beginAt).format('DD/MM'),
              hour: moment(event?.keyMoments?.jobfair[0].beginAt).format('HH:mm'),
              dateTwo: moment(event?.keyMoments?.jobfair[0].endAt).format('DD/MM') 
            })} {moment(event?.keyMoments?.jobfair[0].endAt).format('HH:mm')} (UTC +{authUser.timezone.offset})
        </li>
        <li>
          {t({ id: 'modal.badFormat.physical' },
            {
              dateOne: moment(event?.keyMoments?.jobfair[1].beginAt).format('DD/MM'),
              hour: moment(event?.keyMoments?.jobfair[1].beginAt).format('HH:mm'), 
              dateTwo: moment(event?.keyMoments?.jobfair[1].endAt).format('DD/MM') 
            })} {moment(event?.keyMoments?.jobfair[1].endAt).format('HH:mm')} (UTC +{authUser.timezone.offset})
        </li>
      </ul>
      <div className={styles.buttonsContainer}>
        <Button
          variant="tonal"
          className="mr-6"
          onClick={() => onCancel()}
        >
          {t({ id: 'know.more' })}
        </Button>

        <Button onClick={() => onOk()}>
          {t({ id: 'understand' })}
        </Button>
      </div>
    </Modal>
  );
}

ModalFormatNotAvailable.propTypes = {
  event: object,
  onCancel: func,
  onOk: func,
  isOpen: bool,
  authUser: object,
};

export default ModalFormatNotAvailable;
