import React from 'react';
import { isEmpty } from 'lodash';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { object, oneOf, func, array } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import classnames from 'classnames';
import { Typography } from '@seekube-tech/ui'
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Avatar from '@/components/Avatar';
import { eventSelectors } from '../../../../../../../../../../store/event';
import { toJS } from '@/utils';

import messages from '../../messages';
import styles from './styles.less';
import {
  MoreActions
} from '@/scenes/Event/scenes/Owner/scenes/OnBoarding/containers/Dashboard/components/Recruiters/MoreActions';



const Recruiters = ({ exponent = null, users = [],
  template = 'default',
  contexts = ['admin'],
  handleRecruiterAction,
  event,
  currentEvent,
  organization,
}) => (
  <div className={classnames(styles.recruiterList, styles[template])}>
    {users.filter((user) => (exponent && !isEmpty(user._user)) || !isEmpty(user)).map((user, i) => (
      <Recruiter
        key={i}
        exponentUser={exponent ? user : { _user: user }}
        organization={organization}
        contexts={contexts}
        onActionClick={(type) => handleRecruiterAction(type, user, exponent)}
        event={event && event.modules ? event : currentEvent}
      />
    ))}
  </div>
);

const Recruiter = ({ exponentUser, contexts, onActionClick, event, organization }) => {
  const { _user, role, countOffersPublished, countSlotsAvailable, countAppointmentInterview, countAppointmentInformal, countInformalSlotsAvailable } = exponentUser;
  const t = useFormatMessage();
  const isInformal1to1Enabled = event?.modules?.informal1to1?.enable || false;

  if (!_user) {
    return null;
  }

  return (
    <div className={styles.userCard}>
      <div className={styles.userInfos}>
        <div className={styles.userIdentity}>
          <Avatar src={_user.pictureUrl} user={_user} size={70} />
          <div className={styles.userText}>
            <div className={styles.email}>
              <a role="button" tabIndex={0} onClick={() => onActionClick('recruiterClick')}>{_user.fullName}</a>
            </div>
            {_user.isActive ? (
              <div className={styles.title}>
                <Typography variant="caption1">{_user.title}</Typography>
              </div>
            ) : (
              <div className={styles.title}>
                <Typography variant="caption1">{t({ id: 'owner.onboarding.dashboard.recruiters.inviteSent' })}</Typography>
              </div>
            )}
          </div>
        </div>
        {_user.isActive && (
          <div className={styles.userStats}>
            <div className={countOffersPublished ? '' : styles.warning}>
              <Typography variant="body2">
                {t({ id: 'owner.onboarding.dashboard.recruiters.offers' })}
                <span className="count"> {countOffersPublished}</span>
              </Typography>
            </div>
            <div>
              <Typography variant="body2">
                {t({ id: 'owner.onboarding.dashboard.recruiters.freeTimeslots' })}
                <span className={countSlotsAvailable === 0 && countInformalSlotsAvailable === 0 ? styles.warning : ''}>
                  { isInformal1to1Enabled ? (
                    <FormattedHTMLMessage
                      id='owner.onboarding.dashboard.recruiters.freeTimeslots.value'
                      values={{
                        interviewSlots: countSlotsAvailable,
                        informalSlots: countInformalSlotsAvailable
                      }} />
                  ) : (
                    <span>{countSlotsAvailable}</span>
                  )}
                </span>
              </Typography>
            </div>
            <div className={countAppointmentInterview === 0 && countAppointmentInformal === 0 ? styles.warning : null}>
              <Typography variant="body2">
                {t({ id: 'owner.onboarding.dashboard.recruiters.appointments' })}
                <span className={countAppointmentInterview === 0 && countAppointmentInformal === 0 ? styles.warning : ''}>
                  { isInformal1to1Enabled ? (
                    <FormattedHTMLMessage
                      id='owner.onboarding.dashboard.recruiters.appointments.value'
                      values={{
                        interviewSlots: countAppointmentInterview,
                        informalSlots: countAppointmentInformal
                      }} />
                  ) : (
                    <FormattedHTMLMessage
                      id='owner.onboarding.dashboard.recruiters.interviewAppointments.value'
                      values={
                        {
                          interviewSlots: countAppointmentInterview
                        }
                      } />
                  )}
                </span>
              </Typography>
            </div>
          </div>
        )}
      </div>
      <MoreActions
        organization={organization}
        _user={_user}
        contexts={contexts}
        onActionClick={onActionClick}
        role={role}
      />
    </div>
  );
};


Recruiters.propTypes = {
  template: oneOf(['default', 'withExponentStats']),
  users: array,
  contexts: array,
  exponent: object,
  organization: object,
  handleRecruiterAction: func,
  event: object,
  currentEvent: object,
};

Recruiter.propTypes = {
  exponentUser: object,
  contexts: oneOf(['admin', 'recruiter']),
  organization: object,
  onActionClick: func,
  event: object
};

const mapStateToProps = createStructuredSelector({
  currentEvent: eventSelectors.getCurrentEvent,
})

const mapDispatchToProps = {}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(toJS(Recruiters));
