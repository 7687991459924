import React from 'react';
import { object, array } from 'prop-types';

import { useFormatMessage } from 'react-intl-hooks';
import { pick, keys, map, omit } from 'lodash';
import styles from './styles.less'

import { Stats, WithPercentage } from '@/components/Stats';


const InformalStats = ({ stats = {}, omitStats = [] }) => {
  const t = useFormatMessage();
  const pageStats = {
    countTimeslots: {
      title: t({ id: 'informal1to1.stats.totalSlot' }),
      icon: 'calendar',
      noSeparator: true,
      count: 0,
      tooltip: t({ id: 'informal1to1.stats.totalSlot.tooltip' }),
    },
    countRecruiterTimeslots: {
      title: t({ id: 'informal1to1.stats.recruiterSlot' }),
      icon: 'user',
      noSeparator: true,
      count: 0,
      additionalText: ` - ${stats.countPercentRecruiterTimeslots}%`,
      tooltip: t({ id: 'informal1to1.stats.recruiterSlot.tooltip' }),
      additionalTooltip: t({ id: 'informal1to1.stats.recruiterSlot.additionalTooltip' }),
    },
    countOrganizationTimeslots: {
      title: t({ id: 'informal1to1.stats.organizationsSlot' }),
      icon: 'building',
      count: 0,
      additionalText: ` - ${stats.countPercentOrganizationTimeslots}%`,
      tooltip: t({ id: 'informal1to1.stats.organizationsSlot.tooltip' }),
      additionalTooltip: t({ id: 'informal1to1.stats.organizationsSlot.additionalTooltip' }),
    },
  }

  const pageStatsBis = {
    countAcceptedInformal1to1: {
      title: t({ id: 'informal1to1.stats.informalAccepted' }),
      noSeparator: true,
      count: 0,
      tooltip: t({ id: 'informal1to1.stats.informalAccepted.tooltip' }),
    },
    countAcceptedCandidatesInformal1to1: {
      title: t({ id: 'informal1to1.stats.informalCandidates' }),
      icon: 'user',
      noSeparator: true,
      count: 0,
      additionalText: ` - ${stats.countPercentAcceptedCandidatesInformal1to1}%`,
      tooltip: t({ id: 'informal1to1.stats.informalCandidates.tooltip' }),
      additionalTooltip: t({ id: 'informal1to1.stats.informalCandidates.additionalTooltip' }),
    },
    countAverageAcceptedCandidatesInformal1to1: {
      title: t({ id: 'informal1to1.stats.informalCandidatesAverage' }),
      icon: 'user',
      count: 0,
      tooltip: t({ id: 'informal1to1.stats.informalCandidatesAverage.tooltip' }),
    },
  }

  const statsList = keys(omit(pageStats, omitStats));
  const statsFound = keys(pick(stats, statsList));
  const result = map(statsFound, (key) => ({ ...pageStats[key], count: stats[key] }));
  
  const statsListBis = keys(omit(pageStatsBis, omitStats));
  const statsFoundBis = keys(pick(stats, statsListBis));
  const resultBis = map(statsFoundBis, (key) => ({ ...pageStatsBis[key], count: stats[key] }));

  return (
    <div className={styles.informalStatsContainer}>
      <div className={styles.statsContainer}>
        <p className={styles.title}>{t({ id: 'informal1to1.stats.timeslot.title' })}</p>
        <Stats list={result} />
      </div>
      <div className={styles.separator} />
      <div className={styles.statsContainer}>
        <p className={styles.title}>{t({ id: 'informal1to1.stats.informal.title' })}</p>
        <Stats list={resultBis} />
      </div>
    </div>
  ) 
};

InformalStats.propTypes = {
  stats: object,
  omitStats: array,
};

export default InformalStats;
