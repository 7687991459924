import { Form, Modal } from 'antd';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { func, object, bool } from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { Button } from '@seekube-tech/ui-kit';
import { Alert } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { toJS } from '@/utils';
import { authSelectors } from '@/store/auth';
import InputMaterial from '@/components/Input/InputMaterial';
import Icon from '@/components/Icon';
import UserCard from '@/components/UserCard';
import H3 from '@/components/Title/H3';
import { TemplateMessage } from './components';
import styles from './styles.less';
import { useCreateTemplate } from '@/queries/templates/useCreateTemplate';
import { useUpdateTemplate } from '@/queries/templates/useUpdateTemplate';

const FormItem = Form.Item;

const hasErrors = (fieldsError) => (
  Object.keys(fieldsError).some((field) => fieldsError[field])
);

const NewTemplate = (props) => {
  const { authUser, onClose, submitCallback, defaultVisible } = props;
  const t = useFormatMessage();
  const mutateCreateTemplate = useCreateTemplate({
    userId: authUser._id, onSuccess: () => { onClose(); submitCallback() }
  });
  const mutateUpdateTemplate = useUpdateTemplate({
    userId: authUser._id, onSuccess: () => { onClose(); submitCallback() }
  });
  const [isAddTemplateModalVisible, setIsAddTemplateModalVisible] = useState(defaultVisible || false);

  useEffect(() => {
    props.form.validateFields();
  }, []);

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const { form, template, onClose, submitCallback } = props;
    const modal = isEmpty(template) ? modalProperties.new : modalProperties.edit;
    const callback = () => { onClose(); submitCallback(); };

    form.validateFields((err, values) => {
      modal.cta({ ...template, ...values }, callback);
    });
  };

  const handleClose = () => {
    props.onClose();
  };

  const signatureRecruiter = () => (
    <div>
      <UserCard
        user={authUser}
        userSize={56}
      />
    </div>
  );

  const modalProperties = {
    edit: {
      title: t({ id: 'settings.templatesMail.edit.title' }),
      hint: t({ id: 'settings.templatesMail.add.hint' }),
      cta: (template, callback) => template._user ? mutateUpdateTemplate.mutate(template)
        : modalProperties.new.cta(template, callback),
    },
    new: {
      title: t({ id: 'settings.templatesMail.add.title' }),
      hint: t({ id: 'settings.templatesMail.add.hint' }),
      cta: (template, callback) => mutateCreateTemplate.mutate(template),
    },
  };

  const { template, form: { getFieldDecorator } } = props;
  const modal = isEmpty(template) ? modalProperties.new : modalProperties.edit;

  return (
    <Modal
      visible={isAddTemplateModalVisible}
      footer={false}
      className="appointmentModal"
      zIndex={1001}
    >
      <a role="button" tabIndex={0} className="modal-close" onClick={handleClose}>
        <Icon name="close" className="modal-close-icon" />
      </a>
      <div className="formWrapper" style={{ padding: '30px 60px' }}>
        <Form className={styles.emailFormWrapper} onSubmit={handleOnSubmit}>
          <H3 className={styles.title}>{modal.title}</H3>
          <FormItem style={{ marginBottom: '10px' }}>

            {getFieldDecorator('name', {
              initialValue: template.name,
              rules: [
                { required: true, message: ' ' },
              ],
            })(
              <InputMaterial
                label={t({ id: 'recruiter.template.edit.input.label' })}
                placeholder={t({ id: 'recruiter.template.edit.input.placeholder' })}
                type="text"
              />
            )}
          </FormItem>

          <Alert>{modal.hint}</Alert>

          <TemplateMessage {...props} />

          {signatureRecruiter()}
          <div style={{ marginTop: '30px' }}>
            <Button
              type="submit"
              disabled={hasErrors(props.form.getFieldsError())}
            >
              {t({ id: 'save' })}
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
}

NewTemplate.propTypes = {
  authUser: object,
  defaultVisible: bool,
  form: object,
  template: object,
  onClose: func,
  submitCallback: func,
};

const mapStateToProps = createStructuredSelector({ authUser: authSelectors.getAuthUser });

const withConnect = connect(mapStateToProps, null);

const withForm = Form.create();

export default compose(withConnect, withForm, injectIntl)(toJS(NewTemplate));
