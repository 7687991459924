import React, { useState } from 'react';
import { object } from 'prop-types';
import styles from './styles.less'
import { IconChevronLeft, IconChevronRight, IconX } from '@seekube-tech/ui-kit';

const ModalPictures = ({
  profile,
  pictureIndexInitial = 0,
  setIsModalVisible,
}) => {

  if (!profile) {
    return null;
  }

  const [pictureIndex, setPictureIndex] = useState(pictureIndexInitial);
  const [touchStart, setTouchStart] = useState(null)
  const [touchEnd, setTouchEnd] = useState(null)

  const minSwipeDistance = 50

  const onTouchStart = (e) => {
    setTouchEnd(null)
    setTouchStart(e.targetTouches[0].clientX)
  }

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) {
      return null
    }
    const distance = touchStart - touchEnd
    const isLeftSwipe = distance > minSwipeDistance
    const isRightSwipe = distance < -minSwipeDistance
    if (isRightSwipe && pictureIndex !== 0) {
      setPictureIndex(pictureIndex - 1)
    }
    if (isLeftSwipe && pictureIndex !== profile.pictures.length - 1) {
      setPictureIndex(pictureIndex + 1)
    }
  }

  return (
    <div className={styles.modalVideosContainer}>
      <div className={styles.closableContainer}>
        <div onClick={() => setIsModalVisible(false)} className={styles.closableWrapper}>
          <IconX />
        </div>
      </div>
      <div className={styles.modalVideosWrapper} onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
        {pictureIndex !== 0 &&
          <div onClick={() => setPictureIndex(pictureIndex - 1)} className={styles.chevronIcon}><IconChevronLeft size={34} /> </div>
        }
        <div className={styles.modalVideosWrapper}>
          <img
            src={profile.pictures[pictureIndex]}
          />
        </div>
        {pictureIndex < profile.pictures.length - 1 &&
          <div onClick={() => setPictureIndex(pictureIndex + 1)} className={styles.chevronIcon}><IconChevronRight size={34} /> </div>
        }
      </div>
    </div>
  )
}

ModalPictures.propTypes = {
  profile: object
}

export default ModalPictures;
