import React, { memo } from 'react';
import { node, object } from 'prop-types';
import { isEmpty } from 'lodash';
import { JobdatingsDiscovery } from '@/scenes/Events/scenes/components/JobdatingsDiscovery';
import CandidateExplorer from '@/scenes/Events/scenes/Explore/Candidate';
import Wrapper from '@/components/Wrapper';

const Component = memo(({ authUser }) => {
  const role = authUser && !isEmpty(authUser._currentOrganization) ? 'recruiter' : 'candidate';
  const isCandidate = role === 'candidate';
  
  if (!isCandidate) {
    return <JobdatingsDiscovery authUser={authUser} />;
  }
  
  return <CandidateExplorer />;
});

const Explore = ({
  children,
  authUser
}) => (
    <Wrapper type="scene">
      {children}
      <Component authUser={authUser} />
    </Wrapper>
  )

Explore.propTypes = {
  children: node,
  authUser: object
}

export { Explore };