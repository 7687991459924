import { normalize } from 'normalizr';
import { isEmpty } from 'lodash';

import { call, put, select, takeLatest } from 'redux-saga/effects';
import request from '@/utils/request';
import * as actions from './actions';
import * as types from './types';
import { organizationSchema, organizationListSchema } from './schema';
import { notificationActions } from '../notification';
import { entitiesActions } from '../entities';
import { exponentActions } from '../exponent';
import { authSelectors, authActions } from '../auth';
import * as userActions from '../user/actions';
import { objectToParams } from '../../utils/url';
import { getAuthToken } from '../auth/selectors';
import { trackError } from '../../utils/analytics/helpers';

/**
 * Get an organization
 */
function* getSpecificOrganization({ payload: organizationId }) {
  const requestUrl = `${process.env.FRONT_API_URL}/organizations/${organizationId}`;

  try {
    const result = yield call(request, requestUrl, {
      method: 'GET',
    });

    // Normaliz data
    const dataNormalized = normalize(result, organizationSchema);

    // Save entities
    yield put(entitiesActions.replaceEntities({ id: organizationId, type: 'organizations', entities: dataNormalized.entities }));
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    // Set Current organization
    yield put(actions.getSpecificOrganizationSuccess(dataNormalized.result));
  } catch (err) {
    trackError(err);
  }
}

function* getOrganization({ payload: organizationId }) {
  const requestUrl = `${process.env.FRONT_API_URL}/organizations/${organizationId}`;

  try {
    const result = yield call(request, requestUrl, {
      method: 'GET',
    });

    // Normaliz data
    const dataNormalized = normalize(result, organizationSchema);

    // Save entities
    yield put(entitiesActions.replaceEntities({ id: organizationId, type: 'organizations', entities: dataNormalized.entities }));
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    // Set Current organization
    yield put(actions.setCurrentOrganization(dataNormalized.result));
  } catch (err) {
    trackError(err);
  }
}

function* getMergeList() {
  const requestUrl = `${process.env.FRONT_API_URL}/organizations/merge-list`;

  try {
    const result = yield call(request, requestUrl, {
      method: 'GET',
    });

    // Save default criteria IDs
    yield put(actions.getMergeListSuccess(result));
  } catch (err) {
    trackError(err);
  }
}

/**
 * Post an organization
 */
function* postOrganization({ payload: { organizationParams, notificationParams, redirection, callback, callbackError } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/organizations`;
  const authUser = yield select(authSelectors.getAuthUser);

  try {
    yield put(actions.resetAlreadyExist());
    const result = yield call(request, requestUrl, {
      method: 'POST',
      body: JSON.stringify(organizationParams),
    });
    // Normaliz data
    const dataNormalized = normalize(result, organizationSchema);

    // Save entities
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    // Set Current organization
    yield put(actions.setCurrentOrganization(dataNormalized.result));

    // Update User
    if (authUser) {
      yield put(userActions.getUser());
    }

    const today = new Date();
    const createdAt = new Date(result.createdAt);
    const diff = (createdAt - today) / 1000;
    const newDiff = diff / 60;

    const diffMins = Math.abs(Math.round(newDiff));

    if (diffMins > 1) {
      yield put(actions.postOrganizationError());
    } else if (redirection) {
      window.location.replace(redirection.replace('%organizationId%', result._id));
    }

    // Success Notification
    if (notificationParams?.success) {
      yield put(notificationActions.sendNotification({
        message: notificationParams.success.message,
        kind: notificationParams.success.kind,
      }));
    }

    if (typeof callback === 'function' && diffMins < 1) {
      callback(result);
    }
  } catch (err) {
    trackError(err);

    if (typeof callbackError === 'function') {
      callbackError();
    }

    if (organizationParams.exponentId && organizationParams.eventId) {
      yield put(exponentActions.getExponent({
        exponentId: organizationParams.exponentId,
        eventId: organizationParams.eventId,
      }));
    }

    yield put(actions.postOrganizationError());
  }
}

/**
 * Patch an organization
 */
function* patchOrganization({ payload: { organizationId, organizationParams, notificationParams, syncExponents, callback } }) {
  const params = objectToParams({ syncExponents });
  const requestUrl = `${process.env.FRONT_API_URL}/organizations/${organizationId}?${params}`;
  const authUser = yield select(authSelectors.getAuthUser);

  try {
    yield put(actions.resetAlreadyExist());
    const result = yield call(request, requestUrl, {
      method: 'PATCH',
      body: JSON.stringify(organizationParams),
    });
    // Normalize data
    const dataNormalized = normalize(result, organizationSchema);

    // Save entities
    yield put(entitiesActions.replaceEntities({ id: organizationId, type: 'organizations', entities: dataNormalized.entities }));
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    if (authUser.getIn('_currentOrganization', '_id') === result._id) {
      yield put(authActions.authRefreshUser({ user: authUser.get('_id') }));
    }

    if (organizationParams.exponentId && organizationParams.eventId && !organizationParams.noIndex) {
      yield put(exponentActions.patchExponent({
        exponentId: organizationParams.exponentId,
        eventId: organizationParams.eventId,
        exponentParams: {
          profileUpdatedAt: new Date(),
          profileUpdatedBy: authUser.get('_id'),
        },
      }));
    }

    if (authUser) {
      yield put(userActions.getUser());
    }

    if (typeof callback === 'function') {
      callback(result);
    }
    const today = new Date();
    const createdAt = new Date(result.createdAt);
    const diffMs = (createdAt - today); // milliseconds between now & createdAt
    const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

    if (diffMins > 1) {
      yield put(actions.patchOrganizationError());
    }

    // Success Notification
    if (notificationParams?.success) {
      yield put(notificationActions.sendNotification({
        message: notificationParams.success.message,
        kind: notificationParams.success.kind,
        style: notificationParams.success.style,
      }));
    }
  } catch (err) {
    trackError(err);

    if (notificationParams?.error) {
      yield put(notificationActions.sendNotification({
        message: notificationParams.error.message || err.message,
        kind: 'error',
        style: {
          bottom: '10%',
          top: 'inherit',
        },
      }));
    }

    Raven.captureException(err); //eslint-disable-line

    // eslint-disable-line
    yield put(actions.patchOrganizationError());

    if (organizationParams.exponentId && organizationParams.eventId && isEmpty(err)) {
      yield put(exponentActions.getExponent({
        exponentId: organizationParams.exponentId,
        eventId: organizationParams.eventId,
      }));
    }
  }
}

/**
 * GET organizations
 */
function* getOrganizations({ payload: { search = {}, limit, offset, page, callback } }) {
  const searchParams = objectToParams(search);

  const requestUrl = `${process.env.FRONT_API_URL}/organizations/new-schools?${searchParams}&offset=${offset}&limit=${limit}`;

  try {
    const { docs, limit, total } = yield call(request, requestUrl, {
      method: 'GET',
    });

    // Normaliz data
    const dataNormalized = normalize(docs, organizationListSchema);

    // Save entities
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    // Merge allIds
    yield put(actions.getOrganizationsSuccess({
      result: dataNormalized.result,
      currentPage: page,
      offset,
      limit,
      total,
    }));

    if (typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    trackError(err);

    yield put(notificationActions.sendNotification({
      message: err.message,
      kind: 'error',
      style: {},
    }));
  }
}

/**
 * GET all organizations list
 */
function* getAllOrganizationsList({ payload: { search, limit, offset, page, callback, onlyClient = false } }) {
  const searchString = search ? `&search=${search}` : '';
  const onlyClientString = onlyClient ? `&onlyClient=${onlyClient}` : '';
  const requestUrl = `${process.env.FRONT_API_URL}/organizations?offset=${offset}&limit=${limit}&page=${page}${searchString}${onlyClientString}`;

  try {
    const { docs, limit, total } = yield call(request, requestUrl, {
      method: 'GET',
    });
    // Normaliz data
    const dataNormalized = normalize(docs, organizationListSchema);

    // Save entities
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    // Merge allIds
    yield put(actions.getAllOrganizationsListSuccess({
      result: dataNormalized.result,
      currentPage: page,
      offset,
      limit,
      total,
    }));

    if (typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    trackError(err);

    yield put(notificationActions.sendNotification({
      message: err.message,
      kind: 'error',
      style: {},
    }));
  }
}

function* mergeOrganization({ payload: { oldId, newId, callback } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/organizations/merge`;

  try {
    const result = yield call(request, requestUrl, {
      method: 'POST',
      body: JSON.stringify({ oldId, newId }),
    });

    // Normaliz data
    if (result) {
      yield put(actions.getMergeList());
    }

    if (typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    trackError(err);
  }
}

function* mergeOrganizationName({ payload: { _id, newName, oldName, notificationParams, callback } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/organizations/merge-name`;

  try {
    const result = yield call(request, requestUrl, {
      method: 'PATCH',
      body: JSON.stringify({ _id, newName, oldName }),
    });

    // Normaliz data
    if (result) {
      // yield put(actions.mergeOrganization({ oldId: result.oldId, newId, callback }));

      if (typeof callback === 'function') {
        callback();
      }
    }

    if (notificationParams?.success) {
      yield put(notificationActions.sendNotification({
        message: notificationParams.success.message,
        kind: notificationParams.success.kind,
      }));
    }
  } catch (err) {
    trackError(err);
  }
}

/**
 * delete an organization
 */
function* deleteOrganization({ payload: { organizationId, callback } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/organizations/${organizationId}`;

  try {
    const result = yield call(request, requestUrl, {
      method: 'DELETE',
    });

    // Normaliz data
    if (result) {
      const dataNormalized = normalize(result, organizationSchema);

      // Save entities
      yield put(entitiesActions.replaceEntities({ id: organizationId, type: 'organizations', entities: dataNormalized.entities }));
    }

    if (typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    trackError(err);
  }
}

/**
 * delete an organization
 */
function* postExponents({ payload: { organizationId, eventIds, keyMomentFormatsByEvent = {}, userIds, notificationParams, callback, callbackError } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/organizations/${organizationId}/exponents`;

  try {
    yield call(request, requestUrl, {
      method: 'POST',
      body: JSON.stringify({ eventIds, userIds, keyMomentFormatsByEvent }),
    });

    if (notificationParams?.success) {
      yield put(notificationActions.sendNotification({
        message: notificationParams.success.message,
        kind: notificationParams.success.kind,
      }));
    }

    // Normaliz data
    if (typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    trackError(err);
  }
}


/**
 * Listen Actions
 */
export default [
  takeLatest(types.POST_ORGANIZATION, postOrganization),
  takeLatest(types.GET_ORGANIZATION, getOrganization),
  takeLatest(types.GET_SPECIFIC_ORGANIZATION, getSpecificOrganization),
  takeLatest(types.PATCH_ORGANIZATION, patchOrganization),
  takeLatest(types.GET_ORGANIZATIONS, getOrganizations),
  takeLatest(types.GET_MERGE_LIST, getMergeList),
  takeLatest(types.MERGE_ORGANIZATION, mergeOrganization),
  takeLatest(types.MERGE_ORGANIZATION_NAME, mergeOrganizationName),
  takeLatest(types.GET_ALL_ORGANIZATIONS_LIST, getAllOrganizationsList),
  takeLatest(types.DELETE_ORGANIZATION, deleteOrganization),
  takeLatest(types.POST_EXPONENTS, postExponents),
];
