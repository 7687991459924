import React, { useState, useEffect, useRef, useMemo } from 'react';
import { func, object, bool, array } from 'prop-types';

// Components
import { debounce, get } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { Form } from 'antd';
import { useFormatMessage } from 'react-intl-hooks';
import { Table, H4, Pagination } from '@seekube-tech/ui-kit';
import { toJS } from '@/utils';
import { authSelectors } from '@/store/auth';
import { exponentActions, exponentSelectors } from '@/store/exponent';
import { eventActions, eventSelectors } from '@/store/event';
import InputMaterial from '@/components/Input/InputMaterial';
import { Bloc, BlocHeader } from '@/components/Bloc';
import { isHybrid } from '@/helpers/event/isHybrid';
import DataSource from './DataSource';

import RecruiterMaxColumn from '../../../../../../OnBoarding/containers/Dashboard/components/RecruiterMaxColumn';
import { OfferMaxColumn } from '../../../../../../OnBoarding/containers/Dashboard/components/OfferMaxColumn';
import { CvthequeCountdownColumn } from '../../../../../../OnBoarding/containers/Dashboard/components/CvthequeCountdownColumn';
import { LiveMaxColumn } from '../../../../../../OnBoarding/containers/Dashboard/components/LiveMaxColumn';
import { OrganizationColumn } from '../../../../../../OnBoarding/containers/Dashboard/components/OrganizationColumn';

import {
  KeyMomentFormatsAccessor
} from '@/scenes/Event/scenes/Owner/scenes/OnBoarding/containers/Dashboard/components/KeyMomentFormatsAccessor';

import styles from '../../../../../../OnBoarding/containers/Dashboard/styles.less';

const OrganizationsForm = ({
  form: { validateFields },
  patchExponent,
  getExponents,
  exponents,
  event,
  fetchingExponents,
  pagination,
  onSubmit,
  match,
  refetch,
  setRefetch,
}) => {
  const handleSubmit = (e) => {
    e.preventDefault();

    return validateFields(onSubmit);
  };
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const containerRef = useRef(null)
  const t = useFormatMessage();

  useEffect(() => {
    fetchExponents();
  }, []);

  useEffect(() => {
    if (refetch) {
      fetchExponents();
      setRefetch(false)
    }
  }, [refetch]);

  const fetchExponents = (params = {}) => {

    getExponents({
      eventId: match.params.eventSlug,
      offset: 0,
      page: 1,
      search: {},
      limit: 10,
      owner: true,
      all: true,
      ...params,
    });
  };

  const cols = [
    {
      Header: t({ id: 'event.owner.setting.limits.organizations.exponent' }),
      dataIndex: 'exponent',
      id: 'exponent',
      width: '28%',
      accessor: (record) => <OrganizationColumn
        context="corners"
        corners={record.corners}
        cornersList={event?.modules?.corner?.items || []}
        organization={record._organization}
        byOrganization={false}
        organizationProfile={record.organizationProfile}
      />,
    },
    {
      id: 'format',
      Header: t({ id: 'participation.pl' }, { count: 0 }),
      className: 'text-center',
      accessor: ({ exponent }) => <KeyMomentFormatsAccessor {...exponent} />,
      disableSortBy: true,
    },
    {
      Header: t({ id: 'event.owner.setting.limits.organizations.recruiterMax' }),
      dataIndex: 'exponent',
      id: 'recruiterMax',
      accessor: ({ exponent, actions = {} }) => (
        <span role="button" tabIndex={0} onClick={actions.preventExpand}>
          <RecruiterMaxColumn recruiterMax={get(exponent, 'limits.recruiterMax')} exponent={exponent} patchExponent={actions.patchExponent} />
        </span>
      ),
    },
    {
      Header: t({ id: 'event.owner.setting.limits.organizations.offerMax' }),
      dataIndex: 'exponent',
      id: 'offerMax',
      accessor: ({ exponent, actions = {} }) => (
        <span role="button" tabIndex={0} onClick={actions.preventExpand}>
          <OfferMaxColumn exponent={exponent} patchExponent={actions.patchExponent} />
        </span>
      ),
    },
    {
      Header: t({ id: 'event.owner.setting.limits.organizations.CvthequeCountdown' }),
      dataIndex: 'exponent',
      id: 'CvthequeCountdown',
      accessor: ({ exponent, actions = {} }) => (
        <span role="button" tabIndex={0} onClick={actions.preventExpand}>
          <CvthequeCountdownColumn exponent={exponent} patchExponent={actions.patchExponent} />
        </span>
      ),
    },
    {
      Header: t({ id: 'event.owner.setting.limits.organizations.liveMax' }),
      dataIndex: 'exponent',
      id: 'liveMax',
      accessor: ({ exponent, actions = {} }) => (
        <span role="button" tabIndex={0} onClick={actions.preventExpand}>
          <LiveMaxColumn disabled={!get(event, 'modules.live.recruiterEnable')} exponent={exponent} patchExponent={actions.patchExponent} />
        </span>
      ),
    },
  ].filter((col) => !(col.id === 'format' && !isHybrid(event)));

  const handleOnChange = (page) => {
    const offset = (page * pagination.pageSize) - pagination.pageSize;
    fetchExponents({ offset, page });

    setCurrentPage(page);
    containerRef.current.scrollIntoView();
  };

  const handleOnChangeSearchText = ({ target: { value } }) => {
    setSearchText(value);
    debounceSearch(() => {
      fetchExponents({ search: { keywords: value } });
      setCurrentPage(pagination.current);
    });
  };

  return (
    <div ref={containerRef}>
      <Bloc>
        <Form onSubmit={handleSubmit} style={{ textAlign: 'left' }} layout="vertical">
          <BlocHeader>
            <H4 className="mb-24">
              {t({ id: 'event.owner.setting.limits.specific.title' })}
            </H4>
          </BlocHeader>
          <div className={styles.searchOrganization}>
            <InputMaterial
              value={searchText}
              type="string"
              placeholder={t({ id: 'input.search.company' })}
              addOnIcon="search"
              onChange={handleOnChangeSearchText}
            />
          </div>
          <div>
            <Table
              getCustomTableProps={() => ({ style: { width: '100%' } })}
              isLoadingData={fetchingExponents}
              data={
                DataSource(
                  exponents, {
                    actions: {
                      patchExponent,
                    },
                  })}
              columns={cols}
            />
            <Pagination
              className="flex justify-center mt-20"
              current={currentPage}
              pageSize={pagination.pageSize}
              total={pagination.total}
              onChange={handleOnChange}
            />
          </div>
        </Form>
      </Bloc>
    </div>
  );
};

const debounceSearch = debounce((fct) => fct(), 500);

const mapStateToProps = createStructuredSelector({
  event: eventSelectors.getCurrentEvent,
  authUser: authSelectors.getAuthUser,
  exponents: exponentSelectors.getExponents,
  pagination: exponentSelectors.getExponentPagination,
  fetchingExponents: exponentSelectors.getExponentsFetching,
});

const mapDispatchToProps = {
  patchEvent: eventActions.patchEvent,
  patchExponent: exponentActions.patchExponent,
  getExponents: exponentActions.getExponents,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withForm = Form.create();

OrganizationsForm.propTypes = {
  onSubmit: func.isRequired,
  authUser: object,
  form: object,
  event: object,
  patchExponent: func,
  getExponents: func,
  match: object,
  exponents: array,
  fetchingExponents: bool,
  pagination: object,
};

export default compose(
  withConnect,
  withForm,
  toJS,
)(OrganizationsForm);
