import React from 'react';
import { func } from 'prop-types';
import { Form, Select as SelectAntd, Input, Icon } from 'antd';

// Components
import { Select } from '@/components/Form';

import styles from './styles.less';

const Option = SelectAntd.Option;
const FormItem = Form.Item;

/**
 * Filters
 */
const Filters = ({ onSearch }) => (
  <Form className={styles.searchFormContainer} data-cy="filters-form">
    <FormItem className={styles.formSearchItem}>
      <Input
        className={styles.searchInput}
        placeholder="Recherche"
        prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
        onChange={onSearch}
      />
    </FormItem>

    <FormItem className={styles.formItem} style={{ width: '100px' }}>
      <Select
        className={styles.select}
        showSearch
        getPopupContainer={() => document.getElementById('app')}
        placeholder="Taille"
        optionFilterProp="children"
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        <Option key="test">Développement informatique</Option>
      </Select>
    </FormItem>

    <FormItem className={styles.formItem} style={{ width: '145px' }}>
      <Select
        className={styles.select}
        showSearch
        placeholder="Localisation"
        optionFilterProp="children"
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        <Option key="test">Test</Option>
      </Select>
    </FormItem>

    <FormItem className={styles.formItem} style={{ width: '122px', marginRight: 0 }}>
      <Select
        className={styles.select}
        showSearch
        placeholder="Secteur"
        optionFilterProp="children"
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        <Option key="test">Test</Option>
      </Select>
    </FormItem>

  </Form>
);

Filters.propTypes = {
  onSearch: func,
};

export default Filters;
