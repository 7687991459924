import React, { useState } from 'react';
import { object} from 'prop-types';
import { EVENT_FORMAT } from '@/utils/constants';

import { Tooltip } from '@/components/Tooltip';

import { IconMapPin, IconWifi } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import styles from './styles.less';
import { trackCandidateClickedModifyParticipationMode } from '@/utils/analytics';
import ChangeParticipantParticipationModal from '../ChangeParticipantParticipationModal';
import { useSelector } from 'react-redux';
import { getAuthUser } from '@/store/auth/selectors';
import { getCurrentEvent } from '@/store/event/selectors';
import { isEmpty } from 'lodash';

const HybridTagMenu = ({ participant }) => {
  const authUser = useSelector(getAuthUser).toJS();
  const event = useSelector(getCurrentEvent).toJS();

  if (!event || !event.keyDates || isEmpty(participant?.keyMomentFormats)) return '';

  const t = useFormatMessage()
  const [isOpen, setIsOpen] = useState(false)

  const onEditParticipationModal = () => {
    setIsOpen(true);
    trackCandidateClickedModifyParticipationMode({ authUser, event });
  }

  return (
    <div className={styles.hybridTagContainer}>
      <Tooltip
        overlayStyle={{ whiteSpace: 'normal', zIndex: 5 }}
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
        color="dark"
        title={t({ id: `tooltip.candidate.format.${participant?.keyMomentFormats.length > 1 ? 'all' : participant?.keyMomentFormats.includes(EVENT_FORMAT.PHYSICAL) ? 'physical' : 'virtual'}` })}
        placement="bottom"
      >
        {participant?.keyMomentFormats.length > 1 &&
          <div className={styles.hybridTag}>
            <span><IconMapPin size={12} /> / </span>
            <span><IconWifi size={12} /></span>
          </div>
        }
        {participant?.keyMomentFormats.length === 1 && participant?.keyMomentFormats.includes(EVENT_FORMAT.PHYSICAL) &&
          <div className={styles.hybridTag}>
            <IconMapPin size={12} />
          </div>
        }
        {participant?.keyMomentFormats.length === 1 && participant?.keyMomentFormats.includes(EVENT_FORMAT.VIRTUAL) &&
          <div className={styles.hybridTag}>
            <IconWifi size={12} />
          </div>
        }
      </Tooltip>
      {participant.keyMomentFormats.length < 2 && <button onClick={onEditParticipationModal}>{t({ id: "edit" })}</button>}

      <ChangeParticipantParticipationModal isOpen={isOpen} setIsOpen={setIsOpen} event={event} />
    </div>
  );
};

HybridTagMenu.propTypes = {
  event: object,
};

export default HybridTagMenu;
