import * as React from 'react';
import { bool, object, func, string } from 'prop-types';
import { get } from 'lodash';

// utils
import Dropzone from 'react-dropzone';
import mime from 'mime-types';
import uuidv1 from 'uuid/v1';
import { useFormatMessage } from 'react-intl-hooks';
import S3 from '@/services/S3';

// Components
import Modal from '@/components/Modal';
import Icon from '@/components/Icon';

import styles from '../../styles.less';

function AddLogo({ record, patchOrganization, callback, onClose, getExponents, intl, isOpen, authToken }) {
  const [pictureUrl, setPictureUrl] = React.useState(get(record, 'record._organization.profile') ? record.record._organization.profile.pictureUrl : null)

  const t = useFormatMessage();

  const generateFileName = (file) => {
    // need to rename file with random name provided by uuid

    if (!file) {
      throw new Error('Invalid File');
    }

    const ext = file.name ? file.name.substr(file.name.lastIndexOf('.') + 1) : mime.extension(file.type);

    const fileName = `${uuidv1()}.${ext}`;

    try {
      return new File([file], fileName, { type: file.type });
    } catch (err) {
      return file;
    }
  }

  const onDrop = () => (files) => {
    const file = files[0];
    const s3Url = S3.getS3Url(generateFileName(file), 'organization', record._organization._id, authToken);

    s3Url.then((url) => {
      setPictureUrl(url);
    });
  }

  const handleOnOk = () => {
    patchOrganization({
      organizationId: record._organization._id,
      organizationParams: { profile: { ...record._organization.profile, pictureUrl }, exponentId: record.key, eventId: record._event._id },
      notificationParams: {
        success: {
          message: t({ id: 'toasters.uploadLogo.success' }),
          kind: 'info',
          style: {
            bottom: '10%',
            top: 'inherit',
          },
        },
        error: true,
      },
      callback: () => {
        callback();
        getExponents({
          eventId: record._event._id,
          offset: 0,
          page: 1,
          search: {},
          limit: 300,
          all: true,
          owner: true,
        });
      },
    });

    onClose();
  }

  return (
    <Modal
      title={t({ id: 'owner.onboarding.dashboard.organization.addLogo.title' })}
      onOk={handleOnOk}
      onCancel={onClose}
      visible={isOpen}
      className={styles.logoZone}
      cancelText={t({ id: 'cancel' })}
      okText={t({ id: 'owner.onboarding.dashboard.organization.addLogo.add' })}
    >
      <div className={styles.dropzone}>
        <Dropzone
          accept="image/png, image/jpeg, image/gif"
          onDrop={onDrop('pictureUrl')}
          className={styles.logoZone}
          activeClassName={styles.activeDropzone}
          style={{ backgroundImage: `url(${pictureUrl})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', margin: 0 }}
        >
          <div className={styles.help}>
            <Icon name="upload" />
            {t({ id: 'owner.onboarding.dashboard.organization.addLogo.information' })}<span className="mandatory-symbol">*</span>
          </div>
        </Dropzone>
      </div>
    </Modal>
  );
}

AddLogo.propTypes = {
  isOpen: bool,
  record: object,
  authToken: string,
  patchOrganization: func,
  onClose: func,
  getExponents: func,
  callback: func,
  intl: object
};

AddLogo.defaultProps = {
  callback: () => { },
};

export default AddLogo;
