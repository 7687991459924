import React, { useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { node, func, bool, string, object, array, any } from 'prop-types';
import classnames from 'classnames';
import { isEmpty, isUndefined } from 'lodash';

// Components
import { Row, Col } from 'antd';
import logoColor from '@/assets/images/logo-color.svg';

// Styles
import logo from '@/assets/images/mainLogo.svg';
import recruiter1 from '@/assets/images/recruiter1.png';
import recruiter2 from '@/assets/images/recruiter2.png';
import recruiter3 from '@/assets/images/recruiter4.png';
import recruiter4 from '@/assets/images/recruiter7.png';
import LanguageSelector from '@/components/LanguageSelector';
import Icon from '@/components/Icon';
import { useKeyPress } from '@/utils/hooks';
import styles from './styles.less';
import messages from './messages';
import MobileDetect from 'mobile-detect';

/**
 * ModalBig
 */
function ModalBig({
  isOpen,
  full = false,
  onClose,
  children,
  sideContent,
  hideSideContent,
  keyboard = true,
  layoutScrollable = false,
  offerFormHeight,
  closable = true,
  noWrapper = false,
  noFooter = false,
  noScrollbar,
  className,
  context = 'default',
  isAlwaysOpen = false,
  onboardingStep,
  exponents,
  event,
  footer,
  offers,
  intl,
  currentStep = null,
  mainPanelClassName = null,
  zindex
}) {
  const [countRecruiters, setCountRecruiter] = React.useState(0)

  React.useEffect(() => {
    const newState = {};

    if (!isEmpty(exponents)) {
      const _countRecruiters = exponents
        .map((exponent) => exponent.users ? exponent.users.length : 0)
        .reduce((acc, value) => acc + value);

      newState.countRecruiters = _countRecruiters;
    }

    if (newState.countRecruiters !== undefined && countRecruiters !== newState.countRecruiters) {
      setCountRecruiter(newState.countRecruiters);
    }
  }, [isOpen, exponents, offers, shuffleArray, countRecruiters])

  /**
   * Shuffles array in place.
   * @param {Array} array
   */
  function shuffleArray(array) {
    let j;
    let x;
    let i;

    const newArray = array;

    for (i = array.length - 1; i > 0; i -= 1) {
      j = Math.floor(Math.random() * (i + 1));
      x = array[i];
      newArray[i] = array[j];
      newArray[j] = x;
    }
    return newArray;
  }

  function toggleNoScrollClass() {
    const body = document.getElementsByTagName('body')[0];
    if (!isAlwaysOpen) {
      if (isOpen) {
        body.classList.add('noscroll');
      } else {
        body.classList.remove('noscroll');
      }
    }
  }

  React.useEffect(() => {
    toggleNoScrollClass()
  }, [isOpen])

  /**
   * Close with ESC keydown
   */
  useKeyPress({ targetKey: 'Escape', callback: onClose });

  const md = new MobileDetect(window.navigator.userAgent);
  const isOpenClass = classnames(
    styles.modalBigContainer,
    isOpen ? styles.open : null,
    isOpen ? 'modalIsOpen' : null,
    context === 'homepage' ? styles.homePage : '',
    ['onboarding', 'signup'].includes(context) ? styles.onBoarding : '',
    isAlwaysOpen ? styles.alwaysOpen : '',
  );
  const sidePanelHeight = isOpen && layoutScrollable && !hideSideContent ? offerFormHeight : 'auto';
  const mainPanelClasses = classnames(
    'bigModalMainPanel',
    context === 'signup' ? 'bigModalPanelHeight' : '',
    context === 'signup' && currentStep === null ? styles.noScroll : '',
    className,
    styles.mainPanel,
    noScrollbar ? styles.noScrollbar : '',
    context === 'homepage' ? styles.homePage : '',
    ['onboarding', 'signup'].includes(context) ? styles.onBoarding : '',
    layoutScrollable ? styles.layoutScrollable : '',
  );
  const sidePanelClasses = classnames(
    styles.sidePanel,
    context === 'homepage' ? styles.homePage : '',
    ['onboarding', 'signup'].includes(context) ? styles.onBoarding : '',
    context === 'signup' && currentStep === 1 ? 'desktop' : '',
  );

  return (
    <div className={isOpenClass} style={{zIndex: zindex ?? ''}}>
      {/* OverLay */}
      <div className={styles.overlayContainer} />
      {/* Content */}
      <div className={classnames(full ? styles.full : '', styles.contentContainer, onboardingStep === 'signup' ? styles.signupPage : '')}>
        {['onboarding', 'homepage', 'signup'].includes(context) && (
          <header className={classnames(styles.header, onboardingStep === 'signup' ? styles.signupHeader : '')}>
            <div className={styles.colLogo}>

              <img src={logoColor} alt="Seekube" />
            </div>
            <div>
              <LanguageSelector />
            </div>
          </header>
        )}
        {['onboarding', 'signup'].includes(context) && onboardingStep === 'signup' ? (
          <div className={classnames(styles.signupMobile, context === 'signup' && currentStep === 1 ? 'desktop discovery' : '')}>
            <Row className={styles.logoRow}>
              <Col span={7}>
                <img src={logo} alt="seekube-white-logo" style={{ width: 130, height: 36 }} />
              </Col>
              <Col span={2}>
                <img src={recruiter1} alt="seekube-white-logo" style={{ width: 26, height: 26 }} className={styles.animate2} />
              </Col>
              <Col span={1}>
                <Icon name="cross" style={{ width: 16, height: 16 }} />
              </Col>
              <Col span={2}>
                <img src={recruiter2} alt="seekube-white-logo" style={{ width: 26, height: 26 }} className={styles.animate2} />
              </Col>
            </Row>
            <div className={styles.titleRow}>
              <Col span={1}>
                <img src={recruiter3} alt="seekube-white-logo" style={{ width: 26, height: 26 }} className={styles.animate} />
                <Icon name="star-mini" style={{ width: 11, height: 11, color: '#33A8EB', opacity: 0.5 }} />
              </Col>
              <Col span={1}>
                <Icon name="circle-mini" style={{ width: 7, height: 7, color: '#385077' }} />
              </Col>
              <Col span={8}>
                {context === 'onboarding' ? (
                  <div className={styles.countRecruiter}>
                    <strong>{`${countRecruiters} ${intl.formatMessage(messages.companies)}`}</strong>
                    <p>{`${intl.formatMessage(messages.companiesWaitingForYou)} ${!isEmpty(event) ? event.name : ''}`}</p>
                  </div>
                ) : (
                  <div className={styles.displayTitle}>
                    <div className={styles.title}><strong>{intl.formatMessage(messages.signupPanelContent1)}</strong></div>
                    <div className={styles.subTitle}>{intl.formatMessage(messages.signupPanelContent2)}</div>
                  </div>
                )}
              </Col>
              <Col span={2}>
                <Icon name="square" style={{ width: 17.5, height: 17.5, transform: 'rotate(70deg)', opacity: 0.6 }} />
                <img src={recruiter4} alt="seekube-white-logo" style={{ width: 26, height: 26 }} className={styles.animate} />
              </Col>
            </div>
          </div>
        ) : ''}
        {closable ? (
          <a role="button" tabIndex={0} className={`bigModalCloser ${styles.closeBtn}`} onClick={onClose}>
            <Icon name="close" className={styles.icon} />
          </a>
        ) : ''}
        <div id="bigModalMainPanel" className={classnames(mainPanelClasses, mainPanelClassName)}>
          <div
            className={classnames(styles.dialogContainer, onboardingStep !== 'signup' ? styles.dialogContainerMobile : '', !noWrapper ? styles.dialogContainer : '')}
          >
            {children}
          </div>
          {!noFooter && ((context === 'onboarding' && onboardingStep !== 'signup') || context === 'signup') ? (
            <footer
              className={classnames(styles.footerFix, styles.footerHiddenMobile, context === 'signup' ? 'footerSignup' : '')}
            >
              {footer}
            </footer>
          ) : null}

          {isOpen && !noFooter && !isEmpty(footer) ? (
            <footer
              className={styles.footerFix}
            >
              {footer}
            </footer>
          ) : null}
        </div>
        {isUndefined(hideSideContent) || !hideSideContent ? (
          <div className={sidePanelClasses} style={{ height: sidePanelHeight }}>
            {sideContent}
          </div>
        ) : ''}
      </div>
    </div>
  );
}

ModalBig.propTypes = {
  isOpen: bool,
  full: bool,
  onClose: func,
  children: node,
  sideContent: node,
  hideSideContent: bool,
  keyboard: bool,
  layoutScrollable: bool,
  offerFormHeight: string,
  closable: bool,
  noWrapper: bool,
  noFooter: bool,
  noScrollbar: bool,
  className: any,
  context: string,
  isAlwaysOpen: bool,
  onboardingStep: any,
  exponents: array,
  event: object,
  footer: node,
  offers: array,
  intl: object,
  currentStep: any,
  mainPanelClassName: string,
}

export default injectIntl(ModalBig);
