import { object, func } from 'prop-types';
import React, { useState } from 'react'
import { useFormatMessage } from 'react-intl-hooks';
import { Button, H3, IconPlusCircle, Pagination, Table } from '@seekube-tech/ui-kit';
import { isEmpty } from 'lodash';
import styles from './styles.less'
import Modal from '@/components/Modal';
import AddActivity from '../AddActivity';
import PreviewActivity from '@/scenes/Event/scenes/Owner/scenes/Dashboard/components/PreviewActivity';
import {
  useGetPosts
} from '@/scenes/Event/scenes/Owner/scenes/Dashboard/components/ModalActivities/queries/useGetPosts';
import { Columns } from '@/scenes/Event/scenes/Owner/scenes/Dashboard/components/ModalActivities/Columns';
import {
  ModalDeleteActivity
} from '@/scenes/Event/scenes/Owner/scenes/Dashboard/components/ModalActivities/ModalDeleteActivity';

const ModalActivities = ({ onCancel, event }) => {
  const t = useFormatMessage();

  const [isAddActivityOpen, setIsAddActivityOpen] = useState(false);
  const [activityToUpdate, setActivityToUpdate] = useState({});
  const [activityPreview, setActivityPreview] = useState(null);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [page, setPage] = useState(1);

  const activiesQuery = useGetPosts({ eventId: event._id, enabled: !isAddActivityOpen, page });


  const onUpdate = (activity) => {
    setActivityPreview(null);
    setActivityToUpdate(activity);
    setIsAddActivityOpen(true);
  }

  const onPost = (activity) => {
    setActivityPreview(null);
    setActivityToUpdate(activity);

    if (activity.status === 'published' && !isEmpty(activity.sendNotificationAt)) {
      setIsAddActivityOpen(false);
    }
  }

  const onPreview = (activity) => {
    setActivityPreview(activity);
  }

  const handleOnClose = () => {
    setIsAddActivityOpen(false);
    setActivityToUpdate({})
  }

  return (
    <Modal
      width={1072}
      visible
      mask={!isAddActivityOpen}
      footer={null}
      className={styles.modalActivities}
      onCancel={onCancel}
    >
      {isAddActivityOpen &&
        <AddActivity
          onPost={onPost}
          page={page}
          activityToUpdate={activityToUpdate}
          event={event}
          visible={isAddActivityOpen}
          handleOnClose={handleOnClose}
          onPreview={onPreview}
        />
      }
      <PreviewActivity
        post={activityPreview}
        isVisible={activityPreview !== null}
        onClose={() => setActivityPreview(null)}
        onUpdate={onUpdate}
      />

      <ModalDeleteActivity
        page={page}
        event={event}
        onClose={() => setItemToDelete(null)}
        itemToDelete={itemToDelete}
      />

      <div className={styles.header}>
        <H3>
          {t({ id: 'your.activities' })}
        </H3>
        <a href='https://help.seekube.com/fr/articles/7037945-communiquer-sur-le-dashboard-des-candidats' target="_blank">
          <Button className={styles.helpBtn} variant='text'>
            {t({ id: 'help' })}
          </Button>
        </a>
      </div>
      <div className={styles.description}>
        {t({ id: 'modal.your.activities.description' })}
      </div>
      <div className={styles.addBtnContainer}>
        <Button onClick={() => setIsAddActivityOpen(true)} imageComponentLeft={<IconPlusCircle size={16} />}>
          {t({ id: 'btn.add.post' })}
        </Button>
      </div>
      <div className={styles.tableActivities}>
        <Table
          columns={Columns({
            onPreview,
            onUpdate,
            onDelete: setItemToDelete
          })}
          data={activiesQuery?.data?.docs}
          isLoadingData={activiesQuery.isLoading}
        />
        <Pagination
          pageSize={activiesQuery?.data?.limit}
          total={activiesQuery?.data?.total}
          onChange={(page) => {setPage(page);}}
          className="flex justify-center mt-20"
          current={page}
        />
      </div>

    </Modal>
  )
}

ModalActivities.propTypes = {
  event: object,
  onCancel: func,
};

export default ModalActivities;
