import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { string, object, bool } from 'prop-types';

import Html from '@/components/Html';
import Icon from '@/components/Icon';

import styles from './styles.less';
import messages from './messages';
import { SceneAlerts } from '@/scenes/Event/scenes/Recruiter/scenes/Jobdating/container/SceneAlerts';

const PageDescription = ({
  context = '',
  keyDates,
  skipAppointmentValidation,
  intl,
}) => {
  let text = '';
  let title = '';
  let icon = '';

  switch (context) {
    case 'sourcing':
      title = 'Sourcing';
      icon = 'sourcing2';
      text = intl.formatMessage(messages.sourcing);
      break;
    case 'applications':
      title = intl.formatMessage(messages[skipAppointmentValidation ? 'pendingListTitle' : 'applicationTitle']);
      icon = 'hourglass';
      text = intl.formatMessage(messages[skipAppointmentValidation ? 'pendingList' : 'application']);
      break;
    case 'confirmed':
    case 'unconfirmed':
    case 'refused':
    case 'interview':
    case 'transferred':
      title = intl.formatMessage(messages.interviewTitle);
      icon = 'users';
      text = getInterviewDescription(context, intl, keyDates); break;
    default: return null;
  }

  return (
    <div className={styles.pageDescription}>
      <div className={styles.title}>
        <Icon name={icon} />
        <h2>{title}</h2>
      </div>
      <div>
        <span className={styles.text}>{text}</span>
        <SceneAlerts context={context} />
      </div>
    </div>
  );
};

const getInterviewDescription = (context, intl) => {
  if (context === 'unconfirmed')
    return <>
      {intl.formatMessage(messages.unconfirmed)}<div style={{ marginTop: '25px' }}>


    </div>
    </>;
  if (context === 'confirmed') return intl.formatMessage(messages.confirmed);
  if (context === 'refused') return intl.formatMessage(messages.refused);
  if (context === 'interview') return intl.formatMessage(messages.pending);
  if (context === 'transferred') return intl.formatMessage(messages.transferred);
  return null;
};

PageDescription.propTypes = {
  context: string,
  keyDates: object,
  skipAppointmentValidation: bool,
  intl: object,
};

export default injectIntl(PageDescription);
