import React from 'react';
import { object } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { useGetDashboardStats } from '@/queries/participants/useGetDashboardStats';
import { getLocale } from '@/utils/localStorage';
import { MyStat } from './MyStat';
import styles from './styles.less';


export const MyStats = ({ event }) => {
  const t = useFormatMessage();
  const locale = getLocale();
  const { data: stats } = useGetDashboardStats();
  const { modules } = event;

  return (
    <div className={styles.myStats}>
      {modules.offer?.enable &&
        <MyStat
          title={t({ id: 'event.candidate.appointments.title' })}
          count={stats?.countParticipantInterview}
          module="interview"
          popover={{
            title: t({ id: 'the.interviews' }),
            description: t({ id: 'candidate.dashboard.stats.interview.popover.description' }),
            cta: 'jobs',
          }}
        />}
      {modules.informal1to1?.enable &&
        <MyStat
          title={t({ id: 'my.message' }, { name: modules.informal1to1[`labelMultiple_${locale}`] })}
          count={stats?.countAppointmentsInformal1to1Accepted}
          module={'informal'}
          popoverTitle={t({ id: 'the.message' }, { name: modules.informal1to1[`labelMultiple_${locale}`] })}
          popover={{
            title: t({ id: 'the.message' }, { name: modules.informal1to1[`labelMultiple_${locale}`] }),
            description: t({ id: 'candidate.dashboard.stats.informal1to1.popover.description' }),
            cta: 'informal1to1',
          }}
        />
      }
      {modules.live.participantEnable &&
        <MyStat
          title={t({ id: 'my.message' }, { name: modules.live[`labelMultiple_${locale}`] })}
          count={stats?.countParticipantsWithConferences}
          module="live"
          popover={{
            title: t({ id: 'the.message' }, { name: modules.live[`labelMultiple_${locale}`] }),
            description: t({ id: 'candidate.dashboard.stats.conference.popover.description' }),
            cta: 'conferences/discovery',
          }}
        />
      }
      {modules.offer?.enable || modules.informal1to1?.enable &&
        <MyStat title={t({ id: 'my.applications' })} count={stats?.countApplications}/>
      }
    </div>
  )
}

MyStats.propTypes = {
  event: object,
}
