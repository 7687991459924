import React from 'react';
import { object, bool, string, number, array, oneOf, any } from 'prop-types';
import classnames from 'classnames';

// Components
import { Tag } from '@seekube-tech/ui-kit';
import Avatars from '@/components/Avatars';
import ImgCdn from '@/components/ImgCdn';
import Identity from '../Identity';

// Styles
import styles from './styles.less';
import { useFormatMessage } from 'react-intl-hooks';


const PresentationCard = ({
  organization,
  link,
  countOffers,
  preview,
  profile,
  isList,
  offers,
  event,
  exponent,
  context,
  children,
}) => {
  const t = useFormatMessage();

  let organizationSectors;
  const profileStand = exponent?.profile ? exponent.profile : organization?.profile;
  if (preview) {
    organizationSectors = profile.sectors;
  } else {
    organizationSectors = profileStand ? profileStand.sectors : [];
  }
  const status = (profileStand && profileStand.status) || 'draft';
  const exponentProfile = isList ? (
    <div className={preview ? styles.profileContainerPreview : styles.profileContainer}>
      <div className={preview ? styles.identityContainerPreview : styles.identityContainer}>
        <Identity
          context={context}
          organization={organization}
          link={link}
          preview={preview}
          sectors={organizationSectors}
          event={event}
          countOffers={countOffers}
          offers={offers}
          exponent={exponent}
        />
      </div>
    </div>
  ) : '';

  const cover = isList ? (
    <div className={styles.slider}>
      <div className={styles.overlay} />
      <ImgCdn size={700} src={profileStand?.cover ? profileStand.cover : 'https://cdn-app-assets.seekube.com/public/cover-placeholder2.png'} alt={organization.name} />
    </div>) : (
    <div className={classnames(styles.slider, styles.fullCover)}>
      <div className={styles.overlay} />
      <ImgCdn size={1600} src={profileStand?.cover ? profileStand.cover : 'https://cdn-app-assets.seekube.com/public/cover-placeholder2.png'} alt={organization.name} />
    </div>
  );

  return (
    <div className={classnames(styles.presentationCard, isList ? styles.isList : '')} >
      <div className="absolute right-50 top-50 z-10 flex items-center">
        {children}
        {status === 'draft' ? (<Tag variant="tonal" color="neutral">{t({ id: 'organization.status.draft' })}</Tag>) : null}
      </div>

      <div style={{ opacity: status === 'draft' ? 0.7 : 1 }}>

        {cover}
        {exponentProfile}
      </div>

    </div>
  );
};

PresentationCard.propTypes = {
  link: string,
  organization: object,
  event: object,
  intl: object,
  countOffers: number,
  isList: bool,
  preview: bool,
  profile: object,
  children: any,
  offers: array,
  exponent: object,
  context: oneOf(['owner', undefined]),
};

Avatars.defaultProps = {
  countOffers: 0,
  isList: false,
  preview: false,
  sectors: [],
  offers: [],
  exponent: {},
};

export default PresentationCard;
