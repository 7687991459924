import React, { useEffect } from 'react';
import { object, func, bool } from 'prop-types';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Button } from '@seekube-tech/ui';
import { useFormatMessage } from 'react-intl-hooks';
import { toJS } from '@/utils';
import { setLocalStorage } from '@/utils/localStorage';
import { timeslotActions } from '@/store/timeslot';
import { authSelectors } from '@/store/auth';
import { appActions, appSelectors } from '@/store/app';

import styles from './styles.less';


const RecruiterPreparationTimeslotsScene = ({
  toggleCalendar,
  currentEvent,
  authUser,
  getUserTimeslots,
  calendarIsOpen,
}) => { // eslint-disable-line
  const t = useFormatMessage();

  useEffect(() => {
    getUserTimeslots({ loader: true, currentEvent, currentUser: authUser });
    setLocalStorage('agenda_view', 'calendar');

    if (!calendarIsOpen) {
      toggleCalendar();
    }
  }, []);

  return <div className={styles.openCalendar}>
      <Button onClick={() => { toggleCalendar() }} size="large" color="primary">
        {t({ id: "recruiter.preparation.timeslots" })}
      </Button>
    </div>;
};

RecruiterPreparationTimeslotsScene.propTypes = {
  toggleCalendar: func,
  currentEvent: object,
  authUser: object,
  getUserTimeslots: func,
  calendarIsOpen: bool,
};

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
  calendarIsOpen: appSelectors.getCalendarIsOpen,
});

const mapDispatchToProps = {
  getUserTimeslots: timeslotActions.getUserTimeslots,
  toggleCalendar: appActions.toggleCalendar,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  injectIntl,
)(toJS(RecruiterPreparationTimeslotsScene));
