import React from 'react';
import { func, object, any } from 'prop-types';
import { If, Then, Else } from 'react-if';

import { compose } from 'redux';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';
import { createStructuredSelector } from 'reselect';
import { appointmentSelectors, appointmentActions } from '@/store/appointment';
import { authSelectors } from '@/store/auth';
import { eventSelectors } from '@/store/event';
import { toJS } from '@/utils/index';
import { isNil, isNull } from 'lodash';
import { queryStringToObject } from '@/utils/url';

// Store
import { participantSelectors } from '@/store/participant';

// Components

import { Spin } from 'antd';
import Wrapper from '@/components/Wrapper';
import ModalBig from '@/components/ModalBig';

import AppointmentConfirm from './components/AppointmentConfirm';
import AppointmentDetails from './components/AppointmentDetails';

// Styles
import './styles.less';

/**
 * <Appointment />
 *
 * @description : Let user select a timeslot for his appointment
 */
class Appointment extends React.PureComponent {
  static propTypes = {
    match: object,
    getAppointment: func,
    push: func,
    appointment: any,
    authUser: any,
    event: object,
  }

  state = {
    modalIsOpen: true,
    hideSideContent: true,
  }

  /**
   * componentDidMount
   *
   * @description : Check if user is logged, if not, autologin with token, Handle ScreenSize for responsive modal
   *
   */
  componentDidMount() {
    const { props: { getAppointment, push, authUser, match: { params: { appointmentID, eventSlug } } } } = this;
    const authToken = queryStringToObject(window.location.search).token;

    if (!isNil(authUser)) {
      if (authToken) {
        push(`/${eventSlug}/recruiter/jobdating/appointment/${appointmentID}`);
      }

      getAppointment({
        appointmentID,
        eventSlug,
      });
    } else {
      // TODO : If not authed & no token, redirect somewhere ?
    }

    this.handleScreenSize();
    window.addEventListener('resize', this.handleScreenSize);
  }

  /**
   * componentDidUpdate
   *
   * @description : get appointment details if user was not authed previously
   *
   */
  componentDidUpdate(prevProps) {
    const {
      props: { getAppointment, push, authUser, match: { params: { appointmentID, eventSlug } } },
    } = this;

    if (isNil(prevProps.authUser) && !isNil(authUser)) {
      getAppointment({
        appointmentID,
        eventSlug,
      });
    }

    if (!isNull(prevProps.appointment) && isNull(this.props.appointment)) {
      push(`/${eventSlug}/recruiter/jobdating/applications`);
    }
  }

  /**
   * handleScreenSize
   *
   * @description : Handle screen resize to hide side content
   */
  handleScreenSize = () => {
    this.setState({
      hideSideContent: window.innerWidth < 960,
    });
  }

  /**
   * handleCloseOffer
   *
   * @description : Handle closing modal
   */
  handleCloseAppointment = () => {
    const { props: { match: { params: { eventSlug } }, push } } = this;
    this.setState({ modalIsOpen: false, showOfferDetails: false });
    push(`/${eventSlug}/recruiter/jobdating/applications`);
  };

  render() {
    const {
      handleCloseAppointment,
      state: { hideSideContent, modalIsOpen },
      props: { appointment, event },
    } = this;

    return (
      <Wrapper>
        <div className="appointement-modal">
          <If condition={appointment !== null}>
            <Then>
              <ModalBig
                isOpen={modalIsOpen}
                onClose={handleCloseAppointment}
                sideContent={<AppointmentDetails appointment={appointment} />}
                hideSideContent={hideSideContent}
              >
                <If condition={modalIsOpen}>
                  <AppointmentConfirm appointment={appointment} event={event} />
                </If>
              </ModalBig>
            </Then>
            <Else>
              <Spin size="large" />
            </Else>
          </If>
        </div>
      </Wrapper>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  appointment: appointmentSelectors.getAppointment,
  event: eventSelectors.getCurrentEvent,
  authUser: authSelectors.getAuthUser,
  participant: participantSelectors.getCurrentParticipant,
});

const mapDispatchToProps = {
  push,
  getAppointment: appointmentActions.getAppointment,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withRouter,
  withConnect,
)(toJS(Appointment));
