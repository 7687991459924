import { useEffect } from 'react';
import { hotjar } from 'react-hotjar';

const useDifcamHotjar = (slug) => {
  useEffect(() => {
    if (window.location.hostname === 'app.seekube.com' && [
      'jobdating-difcam-alternance-banque-and-assurance-region-est1',
      'jobdating-difcam-alternance-banque-and-assurance-region-ouest-21',
      'jobdating-difcam-alternance-banque-and-assurance-ile-de-france1',
    ].includes(slug)) {
      hotjar.initialize(1610582, 6);
    }
  }, []);
};



export { useDifcamHotjar };