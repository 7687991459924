import { useInfiniteQuery } from 'react-query';
import { objectToParams } from '@/utils/url';
import fetchService from '@/services/fetch';

export function useGetPublicEvents({ filters, limit = 50 }) {
  
  const queryFn = (pageParams) => {
    const { pageParam: page } = pageParams;
    const filtersParams = objectToParams(filters);
    const params = `limit=${limit}&page=${page}&${filtersParams}`;
    const requestUrl = `${process.env.FRONT_API_URL}/events/public?${params}`;

    return fetchService(requestUrl, {
      method: 'GET',
    }).then((response) => response.json());
  }

  const query = useInfiniteQuery({
    queryKey: ['publicEvents', filters],
    getNextPageParam: (lastPage) => {
      if ((lastPage.page * lastPage.limit) < lastPage.total) {
        return (lastPage.page + 1);
      }
    },
    select: (data) => ({
      pages: [...data.pages],
      pageParams: [...data.pageParams],
    }),
    queryFn,
    refetchOnWindowFocus: false,
    keepPreviousData: true
  });

  return query;
}
