import React, { useEffect, useState } from 'react';
import { object, any , func, string, array } from 'prop-types';
import { isEmpty } from 'lodash';
import classnames from 'classnames';
import { CardEvent } from '@seekube-tech/ui-kit';
import { ANALYTICS_CANDIDATE } from '@/utils/constants';
import { track } from '@/utils/analytics';
import { trackGtmEvent } from "@/utils/pixels/helloWork";
import styles from './styles.less';
import ImgCdn from '@/components/ImgCdn';
import { Actions } from '@/scenes/Events/scenes/components/JobdatingsExplore/components/JobdatingCard/Actions';
import { Header } from '@/scenes/Events/scenes/components/JobdatingsExplore/components/JobdatingCard/Header';
import { Infos } from '@/scenes/Events/scenes/components/JobdatingsExplore/components/JobdatingCard/Infos';

const JobdatingCard = ({
  event,
  context = 'default',
  onCardClick,
  selectedEvents,
  authUser,
  classname,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (!isEmpty(selectedEvents) && !isEmpty(event)) {
      const isCheckedFromProps = selectedEvents.includes(event._id.toString());

      if (isChecked !== isCheckedFromProps) {
        setIsChecked(isCheckedFromProps);
      }
    }
  }, [selectedEvents, event]);

  const onActionClick = (event) => {
    track({
      name: ANALYTICS_CANDIDATE.CLICKED_FORUM,
      properties: {
        stage: event.dateStatus,
        registered: false,
      },
    });

    trackGtmEvent('learn_more', {
      event: event.slug,
      page_category: 'profile',
      page_subject: 'jobdating',
      jobdating_name: event.slug,
      event_info: 'CTA',
    });

    window.location.href = `/${event.slug}?utm_source=seekube&utm_campaign=seekube_home`;
  };

  const handleOnEventClick = (_e) => {
    if (context !== 'default') {
      setIsChecked(!isChecked);

      if (typeof onCardClick === 'function') {
        onCardClick(event._id);
      }
    }
  };

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={handleOnEventClick}
    >
      <CardEvent
        variant="vertical"
        picture={<div className={styles.coverContainer}><ImgCdn size={500} src={event.theme.coverPictureUrl} background={{ backgroundSize: 'cover' }} withshadow /></div>}
        title={event.name}
        disabled={false}
        className={classnames(
          context === 'onboarding' ? styles.onboardingContainer : styles.container,
          isChecked ? styles.isSelected : '',
          classname
        )}
        header={<Header event={event} context={context} isChecked={isChecked} />}
        actions={context === 'default' && (
          <Actions event={event} authUser={authUser} onActionClick={onActionClick} />)
      }
        infos={<Infos event={event} context={context} authUser={authUser} />}
      />
    </div>
  )
}

JobdatingCard.propTypes = {
  event: object,
  context: string,
  onCardClick: func,
  selectedEvents: array,
  authUser: object,
  classname: any,
}

export { JobdatingCard };
