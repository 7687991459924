import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import styles from './styles.less';
import { bool } from 'prop-types';
import Benefits from './components/Benefits';
import Steps from './components/Steps';

const BenefitsSteps = ({ disabledFields, organizationId, profile, push}) => {
  const t = useFormatMessage();

  return (
    <div className={styles.benefitsStepsContainer}>
      <Benefits disabledFields={disabledFields} push={push} />
      <div />
      <Steps disabledFields={disabledFields} organizationId={organizationId} push={push} />
    </div>
  );
}

BenefitsSteps.propTypes = {
  disabledFields: bool,
};

export default BenefitsSteps;
