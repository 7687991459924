import React, { useState } from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import styles from './styles.less';
import { Field } from 'react-final-form';
import { bool } from 'prop-types';
import { TextField, TextareaControlled } from '@seekube-tech/ui';
import { Body1, Body2, Button, Caption1, H4, H5, IconAlignLeft, IconPlusCircle, IconTrash2 } from '@seekube-tech/ui-kit';
import { Modal } from 'antd';
import useValidations from '@/utils/validations/useValidations';
import { FieldArray } from 'react-final-form-arrays';

const AdditionnalBlocs = ({ disabledFields, profile, push}) => {
  const t = useFormatMessage();

  const { isFilled } = useValidations();
  const [deleteModalIsVisble, setDeleteModalIsVisible] = useState(false);
  const [trigger, setTrigger] = useState(false);

  const buttonAddBlock = () => {
    return (
      <Button disabled={disabledFields} onClick={() => push('additionnalTexts', { title: '', content: '<p></p>' })} variant='outline' color='primary' imageComponentLeft={<IconPlusCircle size={16} />}>
        {t({ id: 'event.edit.addBlock' })}
      </Button>
    )
  }

  return (
    <div className={styles.additionnalBlocsContainer}>
      <div className={styles.headerPresentation}>
        <div>
          <H5>{t({id: 'additonnal.blocs'})}</H5>
          <Body1 className={styles.subtitleBloc}>{t({id: 'stand.blocs.subtitle'})}</Body1>
        </div>
        {profile.additionnalTexts.length > 0 ?
          <Caption1 className={styles.required}>{t({id: 'form.helper.multi-required'})}</Caption1>
        :
          buttonAddBlock()
        }
      </div>

      <FieldArray name="additionnalTexts">
        {({ fields }) => {
          return (
            <>
              <Modal
                visible={deleteModalIsVisble !== false}
                onCancel={() => setDeleteModalIsVisible(false)}
                closable
                footer={false}
                maskClosable
                width={720}
                className={styles.modalDelete}
              >
                <H4>{t({id: 'delete.bloc.title'})}</H4>
                <Body2>{t({id: 'action.not.cancel'})}</Body2>
                <div className={styles.btnContainer}>
                  <Button onClick={() => setDeleteModalIsVisible(false)} variant='outline' color='neutral'>{t({id: 'cancel'})}</Button>
                  <Button onClick={() => {fields.remove(deleteModalIsVisble); setDeleteModalIsVisible(false); setTrigger(!trigger) }} >{t({id: 'submit'})}</Button>
                </div>
              </Modal>
              {fields.map((name, index) => {
                return (
                  <div className={styles.blockContainer} key={name}>
                    <Field
                      name={`${name}.title`}
                      id={`${name}.title`}
                      label={t({ id: 'owner.settings.criteriaTable.view.title' })}
                      placeholder={t({ id: 'owner.settings.criteriaTable.view.title' })}
                      component={TextField}
                      fullWidth
                      required
                      validate={(value) => isFilled(value)}
                      icon={<IconAlignLeft />}
                      disabled={disabledFields}
                    />
                    <div className={styles.textArea}>
                      <Field
                        name={`${name}.content`}
                        id={index}
                        placeholder={t({ id: 'stand.key.description' })}
                        fullWidth
                        component={TextareaControlled}
                        required
                        test={index}
                        key={trigger ? index : index + 1000}
                        disabled={disabledFields}
                        validate={(value) => isFilled(value) || value === '<p class="ql-align-justify"><br></p>' || value === '<p><br></p>'}
                      />
                    </div>
                    <div className={styles.removeBtnContainer}>
                      <Button
                        disabled={disabledFields}
                        onClick={() => setDeleteModalIsVisible(index)}
                        variant='tonal' color='error'
                        imageComponentLeft={<IconTrash2 size={16}/>}
                      >
                        {t({id: 'delete'})}
                      </Button>
                    </div>
                  </div>
                )
              })}
            </>
          )
        }
        }
      </FieldArray>
      {profile.additionnalTexts.length > 0 &&
        <div className={styles.removeBtnContainer}>
          {buttonAddBlock()}
        </div>
      }
    </div>
  );
}

AdditionnalBlocs.propTypes = {
  disabledFields: bool,
};

export default AdditionnalBlocs;
