import React from 'react';
import { object } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';

import { Tooltip } from '@/components/Tooltip';
import Avatar from '@/components/Avatar';

import styles from './styles.less';

const Owner = ({
  _organizationUser,
}) => {
  const t = useFormatMessage();

  const { shortName, pictureUrl } = _organizationUser;

  return (
    (
      <div>
        <Tooltip
          title={`${shortName} ${t({ id: 'appointment.inCharge' })}`}
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
          color="dark"
          overlayClassName="darkTooltip"
          placement="bottom"
        >
          <div className={styles.owner}>
            <Avatar
              style={{ marginLeft: '5px' }}
              user={_organizationUser}
              size={25}
              src={pictureUrl}
            />
          </div>
        </Tooltip>
      </div>
    )
  );
};

Owner.propTypes = {
  _organizationUser: object,
};

export { Owner };
