import React, { useEffect, useState } from 'react';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isEmpty, get, omit } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { oneOf, object, func, bool, number, array } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { IconPlay, IconMessageCircle, IconVideo } from '@seekube-tech/ui-kit';
import { toJS } from '@/utils';
import { getId } from '@/utils/global';
import { eventSelectors } from '@/store/event';
import { authSelectors } from '@/store/auth';
import { exponentActions, exponentSelectors } from '@/store/exponent';
import { organizationActions } from '@/store/organization';
import ModalBig from '@/components/ModalBig';
import { Steps, EditLivetalkStep1, EditLivetalkStep2, EditLivetalkStep3, EditLivetalkStep4 } from './components';
import { getRangeDateForLive } from './helpers';
import styles from './styles.less';
import messages from '../../messages';
import EditLivetalkStep5 from './components/EditLivetalkStep5';
import ConferencePreview from './components/ConferencePreview/ConferencePreview';
import { useCreateConference } from '@/queries/conferences/useCreateConference';
import { usePatchConference } from '@/queries/conferences/usePatchConference';
import { useGetExponentsByOrganization } from '@/queries/exponent/useGetExponentsByOrganization';


const ModalEditLiveTalk = (props) => {
  const [visible, setVisible] = useState(props.visible);
  const [currentStep, setCurrentStep] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const isEditing = !isEmpty(get(props, 'conference._id')) && !props.conference.isDuplicating;
  const [showPreview, setShowPreview] = useState(false);
  const [conference, setConference] = useState({
    format: props.format,
    _owner: getId(props.authUser),
    _event: props.event?._id,
    ...props.conference
  })

  useEffect(() => {
      setVisible(props.visible);

      if (!props.visible) {
        setCurrentStep(0);
      }
    },
    [props.visible]);

  useEffect(() => {
    if(props.conference.isDuplicating && conference._id === undefined) {
      setConference({
        ...omit(props.conference, ['beginAt', 'id', 'providerUrl', 'liveUrl', 'duration']),
        format: props.format,
        _owner: getId(props.authUser),
        _event: props.event?._id,
      });
    }
    else {
      setConference({
        ...props.conference,
        format: props.format,
        _owner: getId(props.authUser),
        _event: props.event?._id,
      });
    }
  },[props.conference]);

  return (
    <div className={styles.modalIndex}>
      <ModalBig
        isOpen={visible}
        className={styles.modalContainer}
        full
        sideContent={!showPreview && <Steps step={currentStep} onStepChange={(step) => setCurrentStep(step)} context={props.context} isEditing={isEditing} />}
        onClose={() => { props.onClose((() => { setVisible(false); setCurrentStep(0); }), submitted); }}
        hideSideContent={showPreview}
      >
        {
          visible ?
            <EditLive
              {...props}
              showPreview={showPreview}
              setShowPreview={setShowPreview}
              onFinish={() => { props.onClose((() => { setVisible(false); setCurrentStep(0); }), submitted); }}
              conference={conference}
              setConference={setConference}
              isEditing={isEditing}
              isDuplicating={props.conference.isDuplicating}
              setSubmitted={setSubmitted}
              setCurrentStep={setCurrentStep}
              currentStep={currentStep} />
            : null
        }
      </ModalBig>
    </div>
  );
};

ModalEditLiveTalk.propTypes = {
  visible: bool,
  conference: object,
  event: object,
  authUser: object,
  format: oneOf(['webinar', 'chat', 'meeting']),
  context: oneOf(['nonClient', 'owner', 'client']),
  onClose: func,
};

const EditLive  =  ({
  context,
  format,
  authUser,
  conference,
  setConference,
  onFinish,
  getOrganization,
  setCurrentStep,
  setSubmitted,
  event,
  visible,
  isEditing,
  isDuplicating,
  currentStep,
  showPreview,
  setShowPreview,
}) => {
  const t = useFormatMessage();
  const [organizer, setOrganizer] = useState(null)
  const [eventName, setEventName] = useState('')

  const exponents = useGetExponentsByOrganization({
    organizationId: getId(authUser._currentOrganization),
    page: 1,
    offset: 0,
    limit: 100,
    archive: false,
    context: 'live',
    lean: true,
    enable: context === 'client',
  });
  const mutateCreateConference = useCreateConference({
    onSuccess: () => {
      setSubmitted(true)
    }
  });
  const mutatePatchConference = usePatchConference({
    conferenceId: conference._id,
    onSuccess: () => {
      setSubmitted(true)
    }
  });

  useEffect(() => {
    getOrganization(getId(authUser._currentOrganization))
  }, [])

  const PAGE_TITLES = {
    meeting: {
      icon: <IconVideo />,
      messageName: 'meeting',
    },
    webinar: {
      icon: <IconPlay />,
      messageName: 'webinar',
    },
    chat: {
      icon: <IconMessageCircle /> ,
      messageName: 'chat',
    },
  };

  const incCurrentStep = (nbr = 1) => currentStep + nbr;

  const getEventKeyDates = () => (
    context === 'client' ?
      exponents?.data?.docs?.find((expo) => getId(expo._event) === getId(conference._event))?._event?.keyDates
      : event.keyDates
  );

  const getDynamicStep = (stepNumber) => (context !== 'client') || (context === 'client' && isEditing) ? stepNumber - 1 : stepNumber;

  if(showPreview) {
    return (
      <div className={styles.conferencePreviewContainer}>
        <ConferencePreview
          authUser={authUser}
          conferenceLocal={conference}
          closePreview={() => setShowPreview(false)}
        />
      </div>
    )
  }

  const getLivetalkStep = () => {
    if (!visible) return null;

    switch (currentStep) {
      case getDynamicStep(0): return (
        <EditLivetalkStep3
          context={context}
          exponents={exponents?.data?.docs}
          eventId={conference._event}
          handleNext={(values, _eventName) => {
            setCurrentStep(incCurrentStep());
            setConference({...conference, ...values })
            setEventName(_eventName)
          }}
        />
      );
      case getDynamicStep(1): {
        const keyDates = getEventKeyDates();

        return (
          <EditLivetalkStep1
            context={context}
            eventId={conference._event}
            conference={conference}
            setConference={setConference}
            dateAvailable={getRangeDateForLive([keyDates?.live || keyDates?.jobfair])}
            handleNext={(values, _organizer) => {
              const cover = conference.cover || values._organization?.profile?.cover
              if (values._organization === undefined && typeof values.cover === 'object') {
                values._organization = values.cover
              }
              setCurrentStep(incCurrentStep());
              setConference({...conference, ...values, cover})
              setOrganizer(_organizer)
            }}
          />
        );
      }
      case getDynamicStep(2): return (
        <EditLivetalkStep2
          provider={conference.provider}
          handleNext={(value) => {
            setCurrentStep(incCurrentStep());
            setConference({...conference, ...value })
          }}
        />
      );
      case getDynamicStep(3): return (
        <EditLivetalkStep4
          provider={conference.provider}
          providerUrl={conference.providerUrl}
          handleNext={(values) => {
            setCurrentStep(incCurrentStep());
            setConference({...conference, ...values })
          }}
        />
      );
      case getDynamicStep(4): return (
        <EditLivetalkStep5
          conference={{ ...conference, eventName }}
          isLoading={mutatePatchConference.isLoading || mutateCreateConference.isLoading}
          onClose={onFinish}
          onModify={() => {
            setCurrentStep(0)
          }}
          openPreview={() => {
            setShowPreview(true);
          }}
          onSubmit={() => {
            if (conference._id && !isDuplicating) {
              mutatePatchConference.mutate({ conference, context, onSuccess: () => setSubmitted(true) })
            } else {
              mutateCreateConference.mutate(omit(conference, ['id', 'createdAt', 'updateAt', ]));
            }
          }}
        />
      );
      default: return null;
    }
  };

  const getPageTitleProperties = () => PAGE_TITLES[format];

  const titleProperties = getPageTitleProperties();

  const getTitle = () => {
    switch (currentStep) {
      case getDynamicStep(0): return (
        'conference.create.selectJobdating'
      );
      case getDynamicStep(1): return (
        'conference.create.introduce'
      );
      case getDynamicStep(2): return (
        'conference.create.selectTool'
      );
      case getDynamicStep(3): return (
        'conference.create.configureTool'
      );
      case getDynamicStep(4): return (
        'conference.create.confirmAndPublish'
      );
      default: return '';
    }
  }

  return (
    <div className="modalBigWrapper">
      <div className={styles.editLiveContainer} id="editLiveContainer">
        <div className={styles.header}>
          <div className={styles.title}>
            {t({ id: getTitle() })}
          </div>
          <div className={styles.format}>
            {titleProperties.icon}
            <FormattedMessage {...messages[`editTitle_${titleProperties.messageName}`]} />
          </div>
          <hr />
        </div>
        {getLivetalkStep()}
      </div>
    </div>
  );
}

EditLive.propTypes = {
  context: oneOf(['nonClient', 'owner', 'client']),
  format: oneOf(['webinar', 'chat', 'meeting']),
  authUser: object,
  conference: object,
  conferenceInfo: object,
  exponents: array,
  onClose: func,
  onFinish: func,
  getOrganization: func,
  setCurrentStep: func,
  setConference: func,
  setShowPreview: func,
  setSubmitted: func,
  event: object,
  visible: bool,
  showPreview: bool,
  isDuplicating: bool,
  isEditing: bool,
  handleNextStep: number,
  currentStep: number,
};

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
  event: eventSelectors.getCurrentEvent,
  exponents: exponentSelectors.getExponents,
});

const mapDispatchToProps = {
  getOrganization: organizationActions.getOrganization,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(toJS(ModalEditLiveTalk));
