import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { func, object, any, bool } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useFormatMessage } from 'react-intl-hooks';
import { get, isEmpty } from 'lodash';
import { INTERVIEW_CONTEXTS, ENABLE_SERVICE_FOR_TEST } from '@/utils/constants';
import { trackRecruiterClickedLinkToSync } from '@/utils/analytics';
import { arrayContain, getLastWord } from '@/utils/math';
import { push } from 'connected-react-router';

import { If } from '@/components/If';
import Wrapper from '@/components/Wrapper';
import { Alert } from '@seekube-tech/ui-kit';

import { toJS } from '@/utils';
import { createStructuredSelector } from 'reselect';
import { authSelectors } from '@/store/auth';
import { userActions } from '@/store/user';
import { appActions, appSelectors } from '@/store/app';

import styles from '../../styles.less';
import messages from '../../messages.js';

function SyncCalendarAlert({ closeCalendar, patchMe, calendarIsOpen, push, style, authUser }) {
  const t = useFormatMessage();
  if (!(authUser.isAdmin || ENABLE_SERVICE_FOR_TEST.SYNC_CALENDAR_USERS.includes(authUser.username) || arrayContain(get(authUser, '_currentOrganization.activePlans'), ENABLE_SERVICE_FOR_TEST.SYNC_CALENDAR) || authUser.syncAgendaAuthorization)) return null;
  const EXACT_VIEWS = [...INTERVIEW_CONTEXTS, 'timeslots'];
  const status = get(authUser, 'syncCalendar.syncState');
  if (status === 'running') return null;

  const onLinkClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    closeCalendar();
    push('?settings=syncCalendar');
    trackRecruiterClickedLinkToSync({ authUser, alertType: isEmpty(status) ? 'info' : 'warning' });
  };

  return (
    <If condition={!authUser.hideSyncHelp && (EXACT_VIEWS.includes(getLastWord(window.location.pathname)) || calendarIsOpen)}>
      <Wrapper size="large" className={styles.wrapperMobile} style={style}>
        <Alert
          className="mt-20"
          color={isEmpty(status) ? 'info' : 'warning'}
          onClose={() => patchMe({
            userParams: { hideSyncHelp: true },
            notificationParams: {
              success: {
                message: t({ id: 'notifications.update.success' }),
                kind: 'info',
                style: {
                  bottom: '5%',
                  top: 'inherit',
                },
              },
            }
          })}
        >
          <span>
            <FormattedMessage {...messages[isEmpty(status) ? 'syncCalendarAlert' : 'syncCalendarExpiredAlert']} />
            <a role="button" target="_blank" tabIndex={0} onClick={onLinkClick}>
              <FormattedMessage {...messages[isEmpty(status) ? 'syncCalendarAlertLink' : 'syncCalendarExpiredAlertLink']} />
            </a>
          </span>
        </Alert>
      </Wrapper>
    </If>
  );
}

SyncCalendarAlert.propTypes = {
  authUser: object,
  closeCalendar: func,
  patchUser: func,
  push: func,
  calendarIsOpen: bool,
  className: any,
  style: any,
};

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
  calendarIsOpen: appSelectors.getCalendarIsOpen,
});

const mapDispatchToProps = {
  patchMe: userActions.patchMe,
  closeCalendar: appActions.closeCalendar,
  push,
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, toJS)(SyncCalendarAlert);
