import { useInfiniteQuery } from 'react-query';
import request from '@/utils/request';
import { objectToParams } from '@/utils/url';

const defaultSearch = {
  page: 1,
}

export function useInfiniteGetPlanning({
  userId,
  search = defaultSearch,
  archive,
  eventId,
  ...props
}) {


  const queryFn = (pageParams) => {
    const { pageParam: page } = pageParams;
    const searchParams = objectToParams({ ...search, userId, page, eventId, archive });

    const requestUrl = `${process.env.FRONT_API_URL}/organizations/agenda/planning/list?${searchParams}`

    return request(requestUrl, { method: 'GET' })
  };

  const query = useInfiniteQuery({
    queryKey: ['agenda', 'planning', archive, eventId, userId],
    keepPreviousData: true,
    cacheTime: 10000,
    getNextPageParam: (lastPage, pages) => {
      if ((lastPage.page) < lastPage.pages) {
        return ((lastPage.page) + 1);
      }
    },
    select: (data) => ({
        pages: [...data.pages],
        pageParams: [...data.pageParams],
        total: data?.pages?.[0]?.total,
      }),
    queryFn,
    refetchOnWindowFocus: false,
    ...props,
  });

  return query;
}
