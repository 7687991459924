import React from 'react';
import { isEmpty } from 'lodash';
import { H5 } from '@seekube-tech/ui-kit';

import { useFormatMessage } from 'react-intl-hooks';
import { useGetDashboardPosts } from '@/queries/participants/useGetDashboardPosts';
import { Post } from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Dashboard/components/Posts/Post';
import styles from './styles.less';
import {
  BlankNoPost
} from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Dashboard/components/Posts/BlankNoPost';

export const Posts = () => {
  const t = useFormatMessage();
  const dashboardPostsQuery = useGetDashboardPosts({ status: 'published' });
  const noPosts = isEmpty(dashboardPostsQuery?.data?.docs);

  return (
    <div className={styles.posts}>
      <div className={styles.title}><H5> {t({ id: 'the.last.news' })} </H5></div>

      {noPosts && dashboardPostsQuery.isFetched && <BlankNoPost />}

      {!noPosts && dashboardPostsQuery.isSuccess &&
        dashboardPostsQuery?.data?.docs?.map(post => {
          if (post.status === 'published') {
            return (
              <Post key={post._id} post={post} />
            )
          }
          return null
        })
      }
    </div>
  )
};

