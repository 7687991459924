import React from 'react';
import { object, func, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl'
import { useFormatMessage } from 'react-intl-hooks'
import { H3, Body1} from '@seekube-tech/ui-kit'
import colors from '@seekube-tech/ui-kit/dist/colors'
import { isEmpty } from 'lodash';
import { toJS } from '@/utils';

// Store
import { authSelectors } from '@/store/auth';
import { eventActions, eventSelectors } from '@/store/event';
import { criterionSelectors } from '@/store/criterion';
import { notificationActions } from '@/store/notification';

// Components

import {
  DataOverview,
  EnableReporting,
  EmailingSchedule,
} from '@/scenes/Event/scenes/Owner/scenes/Setting/scenes/Reporting/components';
import { useGetReport } from '@/queries/reports/useGetReport';
import { useGetNextReportsToSend } from '@/queries/reports/useGetNextReportsToSend';
import styles from '../../styles.less';

const ReportingScene = ({
  event,
  patchEvent,
  authToken,
}) => {
  const t = useFormatMessage();
  const reportsQuery = useGetReport({ authToken, eventId: event._id });
  const nextReportsToSendQuery = useGetNextReportsToSend({ authToken, eventId: event._id });

  const nextReportsToSend = isEmpty(nextReportsToSendQuery.data) ? [] : nextReportsToSendQuery.data;

  const reports = reportsQuery.data;

  const handleSubmit = (eventParams) => {
    patchEvent({
      event: eventParams,
      notificationParams: {
        success: {
          message: `🙌 ${t({ id: 'toaster.edit.success' })}`,
          kind: 'info',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        },
      },
    });
  };


  return (
    <div className={styles.formWrapper}>
      <div className={styles.marginBottom}>
        <H3>
          {t({ id: 'reporting.pl' }, { count: 2 })}
        </H3>
        <Body1 color={colors.neutral['500']}>
          {t({ id: 'owner.reporting.description' })}
        </Body1>
      </div>

      <EnableReporting handleSubmit={handleSubmit} />

      {
        event.modules?.reports?.enable && event.keyDatesOk && (
          <>
            <EmailingSchedule reports={reports} />
            <DataOverview nextReportsToSend={nextReportsToSend} />
          </>
        )
      }
    </div>
  );
};

ReportingScene.propTypes = {
  authToken: string,
  event: object,
  patchEvent: func,
};

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
  authToken: authSelectors.getAuthToken,
  event: eventSelectors.getCurrentEvent,
  defaultCriteria: criterionSelectors.getDefaultCriteria,
});

const mapDispatchToProps = {
  patchEvent: eventActions.patchEvent,
  sendNotification: notificationActions.sendNotification,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  injectIntl,
  toJS,
)(ReportingScene);
