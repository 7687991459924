import React, { useEffect, useState } from 'react';
import { array, func, object, bool } from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { push } from 'connected-react-router';
import { isEmpty, join } from 'lodash';
import { Select as SelectAntd } from 'antd';
import { H3 } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { eventActions, eventSelectors } from '@/store/event';
import { authSelectors } from '@/store/auth';
import { appointmentActions, appointmentSelectors } from '@/store/appointment';
import { ANALYTICS_RECRUITER } from '@/utils/constants';
import { track } from '@/utils/analytics';
import { toJS } from '@/utils';
import Select from '@/components/Form/Select';
import Icon from '@/components/Icon';
import JobdatingsYours from '@/scenes/Events/scenes/components/JobdatingsYours';
import styles from './styles.less';
import { getAuthUser } from '@/store/auth/selectors';
import { useGetUserEvents } from '@/queries/events/useGetUserEvents';
import computeEventsData from '@/scenes/Events/container/EventsList/utils/computeEventsData';

const { Option } = SelectAntd;

const DEFAULT_PAGINATION = {
  pageSize: 5,
  limit: 5,
  page: 1,
  offset: 0,
  total: 0,
};

const EventsList = ({
  archive,
  goTo,
  events,
  history,
  byUserPagination,
  pagination,
  isFetching,
  ...props
}) => {
  const t = useFormatMessage();
  const authUser = useSelector(getAuthUser)?.toJS();
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [userPagination, setUserPagination] = useState(DEFAULT_PAGINATION);
  const targets = authUser?.matching?.targets || [];
  const authEvents = useGetUserEvents({
    ...userPagination,
    getPastEvent: archive,
    loader: true,
    authUser
  });
  const [authEventsData, setAuthEventsData] = useState([]);

  useEffect(() => {
    if (!authEvents.isLoading && authEvents.data) {
      // FIXME: This logic should be done in backend if possible see https://app.asana.com/0/0/1206018744596637/f
      const events = computeEventsData(authEvents.data);

      setAuthEventsData(events);
    }
  }, [authEvents.data]);

  useEffect(() => {
    const { event, getUserAppointments } = props;

    props.setCurrentEvent(null);

    if (!isEmpty(event) && !isEmpty(authUser)) {
      getUserAppointments({
        eventSlug: event.slug,
        userId: authUser._id,
      });
    }

    if (isEmpty(authUser._currentOrganization)) {
      props.getEvents({
        search: { targets },
        offset: 0,
        page: 1,
        limit: 6,
      });
    }
  }, []);

  const getNotification = (messageIntl, kind, options = { emoji: '', withHtml: false }) => {
    const { emoji, withHtml } = options;
    const messageFormated = withHtml ? messageIntl : t(messageIntl);
    const space = ' ';
    const message = emoji ? [emoji, messageFormated] : [messageFormated];
    const messageWithHtml = <>{emoji} {messageFormated}</>;

    return {
      message: withHtml ? messageWithHtml : join(message, space),
      kind,
      style: {
        bottom: '5%',
        top: 'inherit',
      },
    };
  };

  const handleOnChangePagination = (pagination) => {
    setUserPagination({
      ...DEFAULT_PAGINATION,
      page: pagination,
      offset: (pagination - 1) * authEvents.data.limit
    });

    window.scrollTo(0, 0);
  };

  const downloadAllCV2 = (event) => {
    setLoadingDownload(true);

    const recruiterId = props.authUser._id;
    const callback = () => {
      track({
        name: ANALYTICS_RECRUITER.DOWNLOADED_APPOINTMENT_PLANNING,
        user: props.authUser,
        event,
        properties: {
          owner: recruiterId.length === 1 && recruiterId[0] === props.authUser._id,
        },
      });
      setTimeout(
        () => {
          setLoadingDownload(false);
        },
        3000
      );
    };

    const notificationParams = {
      success: getNotification(<p><span role="img" aria-labelledby="">⏱️</span> {t({ id: 'download.running' })} <br /> {t({ id: 'recruiter.export.appointments' })}</p>, 'info', { emoji: ' ', withHtml: true }),
      error: true,
    };
    props.downloadAppointments({ appointment: props.userAppointments[0], recruiterId, eventSlug: event.slug, notificationParams, callback });
  };

  const role = authUser && !isEmpty(authUser._currentOrganization) ? 'recruiter' : 'candidate';
  const isCandidate = role === 'candidate';
  
  if (isCandidate && !authUser.isActive && authEvents?.data?.events?.length) {
    goTo(`${authEvents.data?.events[0].slug}/candidate/preparation/onboarding/profile`)
  }
  
  const Filters = () => {
    const hasFutureEvents = authUser.countFutureParticipations > 0;
    
    if (isCandidate && !hasFutureEvents && !archive) {
      return null;
    }
    
    return (
      <div className={styles.titleContainer}>
        <H3 className="page-title">{t({ id: 'events.your.title' })}</H3>
      
        <Select
            defaultValue={archive ? 'past' : 'current'}
            className={styles.inputSelect}
            suffixIcon={<Icon name="chevron" />}
            onSelect={(value) => goTo(value === 'current' ? '/events' : '/events/archive')}
          >
            {[(
              <Option key="current" value="current">
                {t({ id: 'events.now' })}
              </Option>
            ), (
              <Option key="past" value="past">
                {t({ id: 'events.past' })}
              </Option>
            )]}
          </Select>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.eventsContainer}>
        <JobdatingsYours
          events={authEventsData}
          authEvents={authEvents}
          total={authEvents.data?.total}
          isFetching={authEvents.isLoading}
          authUser={authUser}
          goTo={goTo}
          downloadAllCV2={(event) => downloadAllCV2(event)} loadingDownload={loadingDownload}
          isArchiveView={archive}
          onPaginationChange={handleOnChangePagination}
          userPagination={userPagination}
          filters={<Filters />}
        />
      </div>
    </div>
  );
}

EventsList.propTypes = {
  authUser: object,
  byUserPagination: object,
  pagination: object,
  authUserEvents: array,
  events: array,
  getEvents: func,
  getAuthUserEvents: func,
  setCurrentEvent: func,
  archive: bool,
  goTo: func,
  history: object,
  isFetching: bool,
  event: object,
  getUserAppointments: func,
  downloadAppointments: func,
  userAppointments: array,
};

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
  events: eventSelectors.getEvents,
  pagination: eventSelectors.getEventPagination,
  byUserPagination: eventSelectors.getAuthUserEventsPagination,
  isFetching: eventSelectors.getEventsFetching,
  userAppointments: appointmentSelectors.getUserAppointments,
});

const mapDispatchToProps = {
  setCurrentEvent: eventActions.setCurrentEvent,
  getEvents: eventActions.getEvents,
  goTo: push,
  downloadAppointments: appointmentActions.downloadAppointments,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(toJS(EventsList));

