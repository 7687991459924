import { useMutation } from 'react-query';
import request from '@/utils/request';

export function useUploadResume(
  {
    onSuccess = () => {},
    ...props
  }) {

  // do not change props cause it populates mutationVariables
  const mutationFn = ({ ...props } ) => {
    const { file } = props;
    const formData = new FormData();
    formData.append('cvFile', file, file.name);

    return request(`${process.env.FRONT_API_URL}/auth/me/upload-cv`, {
      method: 'POST',
      body: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  return useMutation({
    mutationFn,
    onSuccess: async (res, mutationVariables) => onSuccess(res, mutationVariables),
    ...props,
  });
}
