import React, { useEffect } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { object } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { isEmpty } from 'lodash';
import { replace, push } from 'connected-react-router';
import { organizationActions } from '@/store/organization';
import { getAuthUser } from '@/store/auth/selectors';
import ClientMembersPage from './scenes/Members';
import ClientOffersPage from './scenes/Offers';
import ClientEditOfferPage from './scenes/EditOffer';
import ClientOffersSpreadingPage from './scenes/OffersSpreading';
import ClientStandScene from './scenes/Stand';
import ConferencesScene from './scenes/Conferences';
import ClientStatsByPeriodScene from './scenes/Stats/containers/ByPeriod';
import ClientStatsByEventScene from './scenes/Stats/containers/ByEvent';
import { withAuth } from '@/containers/RequiredAuth';
import { withLayout } from '@/containers/Layout';
import ConferenceParticipants from '../../containers/ConferenceParticipants';
import { queryStringToObject } from '@/utils/url';

const LivetalkAll = () => (<ConferenceParticipants context="all" userType="recruiter" />);

const LivetalkPresent = () => (<ConferenceParticipants context="present" userType="recruiter" />);

function ClientScene({ match }) {
  const dispatch = useDispatch();
  const authUser = useSelector(getAuthUser)?.toJS();

  useEffect(() => {
    if (authUser && authUser._currentOrganization) {
      dispatch(organizationActions.getOrganization(authUser._currentOrganization._id));
    }
  }, [])

  if (match.isExact) {
    dispatch(replace(`${match.path}/`));
    return null;
  }

  return (
    <div>
      <Switch>
        <Route path="/client/offer-edit" component={ClientEditOfferPage} />
        <Route
          path="/client/offers/spreading"
          render={(routeProps) => {
            const paramObject = queryStringToObject(routeProps.history.location.search);

            if (isEmpty(paramObject)) {
              dispatch(push('/client/offers?step=1'));
              return null;
            }

            return <ClientOffersSpreadingPage {...routeProps} {...paramObject} />;
          }}
        />
        <Route
          path="/client/offers"
          render={(routeProps) => {
            const paramObject = queryStringToObject(routeProps.history.location.search);

            if (isEmpty(paramObject)) {
              dispatch(push('/client/offers?page=1&limit=10&offset=0&status=published'));
              return null;
            }

            return <ClientOffersPage {...routeProps} {...paramObject} />;
          }}
        />
        <Route path="/client/member" component={ClientMembersPage} />
        <Route path="/client/conferences/:conferenceID/all" component={LivetalkAll} />
        <Route path="/client/conferences/:conferenceID/present" component={LivetalkPresent} />
        <Route path="/client/conferences" component={(routeProps) => <ConferencesScene {...routeProps} />} />
        <Route path="/client/stand"
          render={(routeProps) => {
            const paramObject = queryStringToObject(routeProps.history.location.search);

            return <ClientStandScene {...routeProps} {...paramObject} />;
          }} />
        <Route path="/client/stats/by-period" component={ClientStatsByPeriodScene} />
        <Route path="/client/stats/by-event" component={ClientStatsByEventScene} />
      </Switch>
    </div>
  );
}

ClientScene.propTypes = {
  match: object
};

export default compose(
  withRouter,
  withAuth(),
  withLayout({
    size: 'full',
    client: true,
    navType: 'owner',
    withHeader: true,
  }),
)(ClientScene);
