import React, { useEffect, useState } from 'react';
import { object, func } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';
import { createStructuredSelector } from 'reselect';
import { head, isEmpty } from 'lodash';
import moment from 'moment';
import { Affix, Col, Row } from 'antd';
import { useFormatMessage } from 'react-intl-hooks';
import { Button, Alert } from '@seekube-tech/ui-kit';
import { toJS } from '@/utils';
import { actionActions } from '@/store/action';
import { eventSelectors } from '@/store/event';
import { interactionSelectors } from '@/store/interaction';
import Wrapper from '@/components/Wrapper';
import OrganizationLayout from '@/components/OrganizationLayout';
import { If } from '@/components/If';
import Icon from '@/components/Icon';
import { H3 } from '@/components/Title';
import P from '@/components/P';
import Html from '@/components/Html';
import Separator from '@/components/Separator';
import AvatarWithDate from '@/components/AvatarWithDate';
import Carousel from '@/components/Carousel';
import { getCdnUrl } from '@/components/ImgCdn';
import { TopicTag } from '@/components/CardLive/components/TopicTag';
import styles from './styles.less';
import { Presenters } from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Conferences/scenes/Conference/Presenters';
import { useGetConference } from '@/queries/conferences/useGetConference';
import request from '@/utils/request';

const ConferencePreview =  ({
  conferenceLocal = {},
  closePreview,
}) => {
  const t = useFormatMessage();

  const [organization, setOrganization] = useState()

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const conferenceApi = useGetConference({conferenceId: location.pathname.split('/')[4]}).data || {};
  const conference = isEmpty(conferenceLocal) ? conferenceApi : conferenceLocal;

  useEffect(() => {
    if (typeof conference._organization === 'string') {
      request.get(`${process.env.FRONT_API_URL}/organizations/${conference._organization}`)
        .then(({ data }) => {
          setOrganization(data)
        });
    }
  }, [conference])

  const renderPictures = (pictures) => {

    if (pictures.length > 0) {
      return (
        <div className="carousel">
          <Carousel
            arrows={false}
            pictures={pictures}
            withEnlargeBtn
            dots
            customPaging={() => <a />}
          >
            {pictures.map((src, index) => (<div key={`pictureImg${index}`}><img src={getCdnUrl(src, 999)} alt={conference._organization?.name} /></div>))}
          </Carousel>
        </div>
      );
    }

    return null;
  };

  const pictures = conference._organization?.profile?.pictures?.filter((item) => !isEmpty(item)) || [];
  return (
    <Wrapper size="full">
      <OrganizationLayout
        organization={organization || conference._organization}
        cover={conference.cover}
        pictureUrl={conference.pictureUrl}
      >
        <Row gutter={0}>
          <Col xs={12} sm={12} md={7} lg={7}>
            <div className="offerInfos">
              <TopicTag topic={head(conference.topics)} classname={styles.topics} />

              <h1 className="mainTitle">{conference.title}</h1>

              <div className="appointmentData">
                <ul>
                  <li><Icon name="visio" /> {conference.format}</li>
                  <li><Icon name="user" /> {t({ id: 'conference.maxAttendees' }, { count: conference.maxAttendees })} </li>
                </ul>
              </div>

              <Alert>
                {t({ id: 'conference.enableDevices' })}
              </Alert>

              <div className="organizationLayoutContent">
                <H3 bold>{t({ id: 'conference.description' })}</H3>
                <P><Html value={conference.description} /></P>

                <If condition={!isEmpty(conference.presenters)}>
                  <H3 bold>{t({ id: 'the.presenters' })}</H3>
                  <Presenters presenters={conference.presenters} />
                </If>

                <H3 bold>{t({id: 'about'})}</H3>
                {renderPictures(pictures)}
              </div>
            </div>
            {!isEmpty(conferenceLocal) &&
              <div className={styles.goBackToCreation}>
                <Button color='primary' variant='fill' onClick={closePreview}>Retourner à la création</Button>
              </div>
            }
          </Col>
          <Col xs={12} md={5} lg={5}>
            <Affix offsetTop={130}>
              <div className="offerInfos" style={{ border: 'none', paddingBottom: '32px' }}>
                <div className="appointment">
                  <div className="appointmentData">
                    <div className="appointmentDay">
                      <AvatarWithDate
                        src={conference._organization?.profile?.pictureUrl}
                        alt={conference._organization?.name}
                        date={conference.beginAt}
                      />
                    </div>
                    <ul>
                      <li><Icon name="clock" /> {moment(conference.beginAt).format('HH[h]mm')}</li>
                      <li><Icon name="calendar" /> {t({ id: 'conference.duration' }, { duration: conference.duration })}</li>
                    </ul>
                  </div>

                  <Separator height={20} />
                </div>

                <Separator height={105} />
              </div>
            </Affix>
          </Col>
        </Row>
      </OrganizationLayout>
    </Wrapper>
  );
}

ConferencePreview.propTypes = {
  conferenceLocal: object,
  closePreview: func,
};


const mapStateToProps = createStructuredSelector({
  event: eventSelectors.getCurrentEvent,
  interactions: interactionSelectors.getInteractions,
});

const mapDispatchToProps = {
  push,
  postAction: actionActions.postAction,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withRouter,
  withConnect,
)(toJS(ConferencePreview));
