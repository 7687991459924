import React from 'react';
import { injectIntl } from 'react-intl';
import { object, func, string } from 'prop-types';
import { isUndefined, isNull, isEmpty, get } from 'lodash';
import { If, Then, Else } from 'react-if';
import { Link } from 'react-router-dom';

// Components
import { Button } from '@seekube-tech/ui-kit';
import Icon from '@/components/Icon';
import AvatarStatus from '../AvatarStatus';

// Styles
import styles from './styles.less';
import messages from '../../../messages';
import { getApiUrl } from '@/utils/url';

/**
 * Contact
 *
 * @param {object} props
 */
const Contact = ({ offer, event, handleOpenOffer, interaction, appointment, context, intl }) => {
  let status = appointment ? appointment.status : 'default';

  if (status === null && interaction && interaction._applicant) {
    status = 'pending';
  }

  if (!offer) {
    return '';
  }

  let contactAppointment = (
    <div className={styles.job}>
      {intl.formatMessage(messages.applicationPending)}
    </div>
  );

  if (interaction && (!appointment || appointment.status === 'canceled') && !interaction.pending) {
    status = 'refused';
    contactAppointment = (
      <div className={styles.job}>
        {intl.formatMessage(messages.applicationrefuse)}
      </div>
    );
  }
  const recruiterName = () => {
    if (get(offer, '_organization.isClient')) return offer._user.shortName;
    return (offer && offer._user ? offer._user.fullName : '');
  };

  const mediumLink = (appt) => {
    switch (appt.settings.medium) {
      case 'phone':
        return (
          <>
            <Icon name="phone" /> {intl.formatMessage(messages.contactByPhone)}
          </>
        );

      case 'visio':
        return (
          <>
            <Icon name="seeklive" /> {intl.formatMessage(messages.contactByVisio)}<br />
            <a href={getApiUrl(appt.candidateVisioUrl)} target="_blank">{intl.formatMessage(messages.goToAppointment)}</a>
          </>
        );

      default:
        return appt.medium;
    }
  };

  if (appointment && appointment.status !== 'canceled') {
    contactAppointment = (
      <If condition={!isUndefined(appointment._timeslot) && !isNull(appointment._timeslot)}>
        <Then>
          <div className={styles.plannedInfos}>
            {mediumLink(appointment)}
          </div>
        </Then>
        <Else>
          <Link to={event ? `/${event.slug}/candidate/jobdating/appointment/${appointment ? appointment._id : ''}` : ''}>
            <Button>{intl.formatMessage(messages.appointmentChooseSlot)}</Button>
          </Link>
        </Else>
      </If>
    );
  }

  const offerBlock = offer ? (
    <>
      { offer._user ? (
        <div className="owner">
          <div className={styles.name}>
            {offer ? offer._user.fullName : ''}
          </div>
          <div className={styles.job}>
            {offer ? offer._user.title : ''}
          </div>
        </div>
      ) : ''}

      <div className={!offer._user ? 'apply forceDisplay' : 'apply'}>
        <Button onClick={() => handleOpenOffer(offer.id)}>
          {intl.formatMessage(messages.showCurrentOffer)}</Button>
      </div>
    </>
  ) : '';

  const recruiterBlock = (
    <div className="hover-actions">
      <div>
        { offer._user ? (
          <div className="owner">
            <div className={styles.name}>
              {recruiterName()}
            </div>
            <div className={styles.job}>
              {intl.formatMessage(messages.recruiterManagerAppointment)}
            </div>
          </div>
        ) : ''}
      </div>
      <div>
        <div className={!offer._user ? 'apply forceDisplay flex' : 'apply flex'}>
          <Button className="mr-4" size="small" color="neutral" variant="outline" onClick={() => handleOpenOffer(offer, 'preview', 'offerCard')}>{intl.formatMessage(messages.preview)}</Button>
          <Button size="small" onClick={() => handleOpenOffer(offer, 'edit')}>{intl.formatMessage(messages.offerUpdate)}</Button>
        </div>
      </div>
    </div>
  );

  const userFullName = offer._user ? offer._user.shortName : '';

  return (
    <>
      <div className={styles.contact}>
        {
          offer._user ?
            <AvatarStatus appointment={appointment} offer={offer} size={70} />
            : null
        }
        <div className={styles.contactDetails}>
          <If condition={!isEmpty(offer) && !isUndefined(offer.proposal) && offer.proposal}>
            <Then>
              <div className={styles.showOffer}>
                <Button>{intl.formatMessage(messages.showRecruiterOffer)}</Button>
              </div>
            </Then>
            <Else>
              <>
                <If condition={!isNull(interaction) && !isUndefined(interaction)}>
                  <Then>
                    <>
                      <div className={styles.name}>
                        {appointment && appointment._organizationUser ? appointment._organizationUser.shortName : userFullName}
                      </div>

                      {contactAppointment}

                    </>
                  </Then>
                  <Else>
                    {context && context === 'recruiter' ? recruiterBlock : offerBlock}
                  </Else>
                </If>
              </>
            </Else>
          </If>
        </div>
      </div>
    </>
  );
};

Contact.propTypes = {
  offer: object,
  event: object,
  context: string,
  appointment: object,
  interaction: object,
  handleOpenOffer: func,
  intl: object,
};

export default injectIntl(Contact);
