import request from '@/utils/request';

const saveLimitsAllExponents = ({ event, authToken, body }) => {
  const requestUrl = `${process.env.FRONT_API_URL}/events/${event._id}/limits/exponents`;
  return request(requestUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': authToken
    },
    body: JSON.stringify(body),
  })
};

export { saveLimitsAllExponents };