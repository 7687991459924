import React from 'react';
import { object } from 'prop-types';
import { injectIntl } from 'react-intl';

// Containers

// Components
import { Badge } from 'antd';
import { NavLink } from 'react-router-dom';
import { NavScenePortal } from '@/containers/Layout';

// Styles & Translations
import styles from './styles.less';
import messages from './messages';


const overflowCount = 9999;

const NavJobdating = ({ event, match, stats, intl }) => (
  <NavScenePortal>
    <div className={styles.container}>
      <div className={styles.navEventContainer}>
        <div className={styles.navEvent}>
          <div className={styles.navEventItem}>
            <NavLink to={`${match.url}/sourcing`} activeClassName="activeNavLink">
              <Badge count={stats.sourcing} showZero overflowCount={overflowCount} />
              <span>{intl.formatMessage(messages.sourcing)}</span>
            </NavLink>
          </div>

          <div className={styles.navEventItem}>
            <NavLink to={`${match.url}/applications`} activeClassName="activeNavLink">
              <Badge count={stats.applications} showZero overflowCount={overflowCount} />
              <span>{intl.formatMessage(messages[event.skipAppointmentValidation ? 'applications' : 'applications2'], { count: stats.applications })}</span>
            </NavLink>
          </div>

          <div className={styles.navEventSeparator} />

          <div className={styles.navEventItem}>
            <NavLink
              to={`${match.url}/interview${(event.areInteractionsClosed && event.skipAppointmentValidation) || !event.skipAppointmentValidation ? '/confirmed' : ''}`}
              activeClassName="activeNavLink"
              className={window.location.pathname.indexOf('/interview/') > -1 ? 'activeNavLink' : null}
            >
              <Badge count={stats.allInterview} showZero overflowCount={overflowCount} />
              <span>{intl.formatMessage(messages.interview, { count: stats.allInterview })}</span>
            </NavLink>
          </div>

          <div className={styles.navEventSeparator} />

          <div className={styles.navEventItem}>
            <NavLink to={`${match.url}/top`} activeClassName="activeNavLink">
              <Badge count={stats.top} showZero overflowCount={overflowCount} />
              <span>{intl.formatMessage(messages.top)}</span>
            </NavLink>
          </div>

          <div className={styles.navEventItem}>
            <NavLink to={`${match.url}/save`} activeClassName="activeNavLink">
              <Badge count={stats.save} showZero overflowCount={overflowCount} />
              <span>{intl.formatMessage(messages.save)}</span>
            </NavLink>
          </div>

          <div className={styles.navEventItem}>
            <NavLink to={`${match.url}/refuse`} activeClassName="activeNavLink">
              <Badge count={stats.refuse} showZero overflowCount={overflowCount} />
              <span>{intl.formatMessage(messages.refuse)}</span>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  </NavScenePortal>
);

NavJobdating.propTypes = {
  event: object,
  match: object,
  stats: object,
  intl: object,
};

export default injectIntl(NavJobdating);
