import React from 'react';
import { string, func } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import colors from '@seekube-tech/ui-kit/dist/colors';
import { IconMinusCircle, Body1 } from '@seekube-tech/ui-kit';
import styles from './styles.less';

export const RemoveReport = ({ _id,  handleClick }) => {
  const t = useFormatMessage();

  return (
    <a
      className={styles.removeReport}
      tabIndex={0}
      role="button"
      onClick={() => handleClick(_id)}
    >
      <IconMinusCircle size={12} color={colors.error['500']} className=" mr-6" />
      <Body1>
        {t({ id: 'delete' })}
      </Body1>
    </a>
  );
}


RemoveReport.propTypes = {
  _id: string,
  handleClick: func,
}