/* eslint-disable arrow-body-style */
import React, { useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Button } from '@seekube-tech/ui-kit';
import IconAlerte from '@/components/svg/IconAlerte';
import { toJS } from '@/utils';
import { exponentActions } from '@/store/exponent';
import { authSelectors } from '@/store/auth';
import ModalV2 from '@/components/ModalV2/index.js'


import styles from './styles.less';
import { InformalDescription } from '@/scenes/Event/scenes/Recruiter/scenes/Preparation/scenes/Timeslot/containers/Calendar/components/SettingsSlots/components';

const EditInformal1to1Description = ({ description, closePopover, event, patchExponent, authUser, handleOnInformalDescriptionChange, isPopoverVisible }) => {
  /* Vars */
  const t = useFormatMessage();
  const [settings, setSettings] = useState({informalDescription: description})

  const handleOnSettingsChange = (val) => {
    setSettings({informalDescription: val});
  }

  const handleSubmit = () => {
    if(settings.informalDescription === description) {
      closePopover()
    }
    else {
      const _event = authUser._events.find((e) => e._id === event._event);
      const exponentId = _event._exponent._id;
      const exponentUser = _event._exponent.users.find((e) => e._user === authUser._id);
      exponentUser.informalDescription = settings.informalDescription;
      patchExponent({
        exponentId,
        eventId: event._event,
        exponentParams: {
          updatedUser: exponentUser,
          updatedUserKey: 'informalDescription',
        },
        notificationParams: {
          success: {
            message: t({ id: 'EditInformal1to1Description.message.success' }),
            kind: 'info',
            style: {
              bottom: '5%',
              top: 'inherit',
            },
          },
          error: {
            message: t({ id: 'EditInformal1to1Description.message.error' }),
            kind: 'error',
            style: {
              bottom: '5%',
              top: 'inherit',
            },
          },
        },
      })

      handleOnInformalDescriptionChange(settings.informalDescription);
    }
    
  }

  return (
    <ModalV2 visible={isPopoverVisible} onClose={closePopover}>
      <p className={styles.title}>Modifier la description </p>
      <div className={styles.warningContainer}>
        <IconAlerte color='#DC7200' />
        <p>{t({ id: 'EditInformal1to1Description.warning' })}</p>
      </div>
      <InformalDescription
        settings={settings}
        handleOnBlur={(val) => handleOnSettingsChange(val)}
        withWarning={false}
      />
      <div className={styles.buttonsContainer}>
        <button className={styles.cancelBtn} onClick={closePopover}>{t({ id: 'EditInformal1to1Description.button.cancel' })}</button>
        <Button onClick={handleSubmit}>{t({ id: 'EditInformal1to1Description.button.validate' })}</Button>
      </div>
    </ModalV2>
  );
}

EditInformal1to1Description.propTypes = {
  event: object,
  currentEvent: object,
  description: string,
  authUser: object,
  handleOnInformalDescriptionChange: func,
  isPopoverVisible: bool,
};



const mapDispatchToProps = {
  patchExponent: exponentActions.patchExponent,
};

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
});

export default connect(mapStateToProps, mapDispatchToProps)(toJS(EditInformal1to1Description));
