import React, { useEffect } from 'react';
import moment from 'moment';
import { any, array, bool, func, object, string } from 'prop-types';
import { size, isEmpty } from 'lodash';

import { useFormatMessage } from 'react-intl-hooks';

import { Select as SelectAntd } from 'antd';
import Icon from '@/components/Icon';
import Select from '@/components/Form/Select';
import styles from './styles.less';
import { Body1, Caption1, Tag } from '@seekube-tech/ui-kit';

const { Option } = SelectAntd;

const SelectStand = ({
  defaultValue = 'master',
  events,
  onSelect,
}) => {
  const t = useFormatMessage();

  const options = events.map((stand) => {
    if (stand._event) {
      return (
        <Option key={stand._event._id} value={stand._event._id} title={stand._event.name}>
          <div className={styles.optionStandContainer}>
            <div className={styles.optionStandLeft}>
              <Body1 className={styles.optionStandName}>{stand._event.name} {stand.isContextual && <Tag className={styles.tagContextual} color='orchid' variant='text' size='small'>{t({id: 'stand.contextual'})}</Tag>} </Body1>
              <Caption1 className={styles.optionStandDate}>{stand._event.planners._organizations[0].name} - {moment(stand._event.readOnly.jobfairBeginAt).format('DD MMM')} - {moment(stand._event.readOnly.jobfairEndAt).format('DD MMM')}</Caption1>
            </div>
            {stand.profile && <Tag variant='tonal' size='small' color={stand.profile?.status === 'draft' ? 'warning' : 'neutral'}>{t({id: stand.profile?.status})}</Tag>}
          </div>
        </Option>
      );
    }
    return (
      <Option key={'master'} value={'master'} title={'Stand Principal'}>
        <div className={styles.optionStandContainer}>
          <div className={styles.optionStandLeft}>
            <Body1 className={styles.optionStandName}>{t({id: 'master.stand'})}</Body1>
            <Caption1 className={styles.optionStandDate}>{t({id: 'apply.all.events'})}</Caption1>
          </div>
          {stand.profile && <Tag variant='tonal' size='small' color={stand.profile?.status === 'draft' ? 'warning' : 'neutral'}>{t({id: stand.profile?.status})}</Tag>}
        </div>
      </Option>
    )
  })

  return (
    <div className={styles.selectStand}>
      <Select
        showSearch={false}
        id="selectStand"
        value={defaultValue}
        suffixIcon={<Icon name="chevron" />}
        addOnIcon="house"
        // placeholder={placeholder}
        onSelect={(e) => onSelect(e)}
      >
        {options}
      </Select>
    </div>
  );
};

SelectStand.propTypes = {
  defaultValue: any,
  events: array,
  intl: object,
  onSelect: func,
  placeholder: string,
  userEventIds: array,
  allowAll: bool,
  hasError: bool,
  onBlur: func,
  onFocus: func,
  slots: object,
  exponentFormat: string,
};

SelectStand.defaultProps = {
  allowAll: false,
  userEventIds: [],
  slots: [],
};

export default SelectStand;
