import React, { useEffect, useState } from 'react';
import { object, func } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { get } from 'lodash';

// Store

// Components
import { injectIntl } from 'react-intl';
import { Form, Modal } from 'antd';
import { Body1, Button, H4, IconInfo } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { eventActions, eventSelectors } from '@/store/event';
import { toJS } from '@/utils';
import { InputNumber } from '@/components/Input';
import { Bloc, BlocHeader } from '@/components/Bloc';
import { saveLimitsAllExponents } from './request.js'
import { authSelectors } from '@/store/auth';

import styles from '../../../../styles.less';
import { exponentActions } from '@/store/exponent/index.js';
import { notificationActions } from '@/store/notification/index.js';


const FormItem = Form.Item;

const LimitsbyDefault = ({
  patchEvent,
  event,
  form,
  form: { getFieldDecorator },
  authToken,
  getExponents,
  sendNotification,
  setRefetch,
}) => {
  const t = useFormatMessage();
  const [modalConfirmationOpen, setModalConfirmationOpen] = useState(false)

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const { limits = {} } = event;

    form.validateFields((err, val) => {
      if (!err) {
        patchEvent({
          event: { limits: { ...limits, ...val.limits } },
          notificationParams: {
            success: {
              message: t({ id: 'notifications.update.success' }),
              kind: 'success',
              style: {
                bottom: '5%',
                top: 'inherit',
              },
            },
          }
        });
      }
    });
  };

  const handleSubmitAll = () => {
    const { limits = {} } = event;
    form.validateFields((err, val) => {
      if (!err) {
        const body = { ...limits, ...val.limits }
        setModalConfirmationOpen(false)
        saveLimitsAllExponents({event, authToken, body})
          .then((res) => {
            sendNotification({
              message: t({ id: 'notifications.update.success' }),
              kind: 'success',
              style: {
                bottom: '5%',
                top: 'inherit',
              },
            })
            setRefetch(true);
          })
      }
    })
  }

  return (
    <div className={styles.formWrapper}>
      <Modal
        width={723}
        visible={modalConfirmationOpen}
        footer={false}
        closable={false}
      >
        <H4 className='mb-24'>{t({id: 'are.you.sure'})}</H4>
        <Body1 className='mb-24'>{t({id: 'save.all.organizations.limits.description'})}</Body1>
        <div className='flex justify-end'>
          <Button variant='text' style={{ marginRight: '24px' }} onClick={() => setModalConfirmationOpen(false)}>{t({id: 'cancel'})}</Button>
          <Button onClick={() => handleSubmitAll()}>{t({id: 'submit'})}</Button>
        </div>
      </Modal>
      <Bloc>
        <Form onSubmit={handleOnSubmit} style={{ textAlign: 'left' }} layout="vertical">
          <BlocHeader>
            <H4 className="mb-24">{t({ id: 'event.owner.setting.limits.default.title' })}</H4>
            <a href='https://www.notion.so/Gestion-des-limitations-d-un-event-d0803947f2a94e7dba4f5f79248edead' target='_blank'><Button size='small' variant='text' imageComponentLeft={<IconInfo size={12} />}>{t({id: 'help.limitations'})}</Button></a>
          </BlocHeader>
          <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between' }}>
            <FormItem label={t({ id: 'event.owner.setting.limits.users.input.label' })}>
              {getFieldDecorator('limits.recruiterMax', {
                initialValue: get(event, 'limits.recruiterMax'),
                normalize: (val) => val < 0 ? 1000 : val,
              })(
                <InputNumber style={{ width: '100%' }} formatter={(val) => val < 0 || val > 999 ? '∞' : val} width={50} min={-1} defaultValue={get(event, 'limits.recruiterMax')} customSize="small" />
              )}
            </FormItem>
            <FormItem label={t({ id: 'event.owner.setting.limits.offers.input.label' })}>
              {getFieldDecorator('limits.offerMax', {
                initialValue: get(event, 'limits.offerMax'),
                normalize: (val) => val < 0 ? 1000 : val,
              })(
                <InputNumber style={{ width: '100%' }} formatter={(val) => val < 0 || val > 999 ? '∞' : val} width={50} min={-1} defaultValue={get(event, 'limits.offerMax')} customSize="small" />
              )}
            </FormItem>
            <FormItem label={t({ id: 'event.owner.setting.limits.cvtheque.input.label' })}>
              {getFieldDecorator('limits.cvtheque', {
                initialValue: get(event, 'limits.cvtheque'),
                normalize: (val) => val < 0 ? 1000 : val,
              })(
                <InputNumber style={{ width: '100%' }} formatter={(val) => val < 0 || val > 999 ? '∞' : val} width={50} min={-1} defaultValue={get(event, 'limits.cvtheque')} customSize="small" />
              )}
            </FormItem>
            <FormItem label={t({ id: 'event.owner.setting.limits.default.input.label' })}>
              {getFieldDecorator('limits.liveMax', {
                initialValue: get(event, 'limits.liveMax'),
                normalize: (val) => val < 0 ? 1000 : val,
              })(
                <InputNumber style={{ width: '100%' }} disabled={!get(event, 'modules.live.recruiterEnable')} onChange={() => { }} formatter={(val) => val < 0 || val > 999 ? '∞' : val} width={50} min={-1} defaultValue={get(event, 'limits.liveMax')} customSize="small" />
              )}
            </FormItem>
          </div>
          <div className={styles.btnContainerLimit}>
            <FormItem className={styles.formButton}>
              <Button type="submit">{t({ id: 'save' })}</Button>
            </FormItem>
            <Button className={styles.btnSaveAll} size='small' onClick={() => setModalConfirmationOpen(true)} variant='tonal'>{t({ id: 'save.all' })}</Button>
          </div>
        </Form>
      </Bloc>
    </div>
  );
}

LimitsbyDefault.propTypes = {
  patchEvent: func.isRequired,
  event: object,
  form: object,
};

const mapStateToProps = createStructuredSelector({
  event: eventSelectors.getCurrentEvent,
  authToken: authSelectors.getAuthToken
});

const mapDispatchToProps = {
  patchEvent: eventActions.patchEvent,
  getExponents: exponentActions.getExponents,
  sendNotification: notificationActions.sendNotification
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withForm = Form.create();

export default compose(
  injectIntl,
  withConnect,
  withForm,
  toJS,
)(LimitsbyDefault);
