import React from 'react';
import { object } from 'prop-types';
import Icon from '@/components/Icon';
import { useFormatMessage } from 'react-intl-hooks';

import styles from './styles.less';

const Location = ({
  settings: { medium },
}) => {
  const t = useFormatMessage();

  return (
    medium === 'physical ' && (
      <span className={styles.locationContainer}>
        <Icon name="location" /> {`${t({ id: 'event.recruiter.preparation.timeslots.location.label' })} : ${location}`}
      </span>
    )
  );
};

Location.propTypes = {
  settings: object,
};

export { Location };
