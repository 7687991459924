import { defineMessages } from 'react-intl';

export default defineMessages({
  applications: {
    id: 'participants.results.applications',
  },
  applications2: {
    id: 'participants.results.applications2',
  },
  sourcing: {
    id: 'participants.results.sourcing',
  },
  all: {
    id: 'participants.results.all',
  },
  selection: {
    id: 'participants.results.selection',
  },
  interview: {
    id: 'participants.results.interview',
  },
  save: {
    id: 'participants.results.save',
  },
  top: {
    id: 'participants.results.top',
  },
  refuse: {
    id: 'participants.results.refuse',
  },
});
