import React, { useState } from 'react';
import { func, shape, object } from 'prop-types';
import { Form } from 'antd';
import { Body2, H3, Button, IconLinkedinFill } from '@seekube-tech/ui-kit';
import colors from '@seekube-tech/ui-kit/dist/colors';
import { useFormatMessage } from 'react-intl-hooks';
import ReactPixel from 'react-facebook-pixel';
import { useHistory } from 'react-router-dom';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import styles from './styles.less';
import { Bloc } from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/components/Bloc';
import { track } from '@/utils/analytics';
import { ANALYTICS_CANDIDATE, API_CUSTOM_ERRORS } from '@/utils/constants';
import { authActions } from '@/store/auth';
import Wrapper from '@/components/Wrapper';
import { getStoredUtmParams } from '@/utils/url';
import { LinkedinOauth } from '@/components/LinkedinOauth';
import { Cgu, Password, Rgpd, Username } from '@/forms/components';
import { getAuthUser } from '@/store/auth/selectors';
import {
  ErrorAlert
} from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/SignupDev/ErrorAlert';

const SignupDev = ({
  event,
  form,
}) => {
  const t = useFormatMessage();
  const authUser = useSelector(getAuthUser)?.toJS();
  const history = useHistory();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const handleOnSubmitRegistration = (e) => {
    e.preventDefault();

    return form.validateFields((err, values) => {
      if (!err) {
        if ((values.cgu && values.rgpd)) {
          const signupResponse = values;
          signupResponse._event = event || null;
          signupResponse.termsAcceptedAt = moment()?.toISOString();

          setIsLoading(true);

          handleSignup(signupResponse);

          setIsLoading(false);
        }
      }
    });
  };

  const handleSignup = (data) => {
    const userProvider = getStoredUtmParams();

    const postAuth = {
      roles: [],
      ...data,
      userProvider,
      locale: 'fr',
      callback: (response) => {
        if (response?.error) {
          if ([API_CUSTOM_ERRORS.wrongPassword].includes(response.message)) {
            return setErrorMessage(response.message);
          }

          return null;
        }

        return !event ? history.push('/auth/signup/candidate/profile') : history.push('profile')

      },
      notificationParams: {
        error: {
          message: t({ id: "signup.error" })
        },
      }
    };

    track({
      name: ANALYTICS_CANDIDATE.FILLED_ONBOARDING_SIGNUP,
      event,
      properties: {
        eventSlug: event?.slug,
        username: data.username,
        email: data.username,
      },
    });

    if (window.location.hostname === 'app.seekube.com') {
      ReactPixel.track('signup', {
        eventName: event?.slug,
        userId: Buffer.from(data.username, 'base64').toString(),
      });
    }

    dispatch(authActions.authSignup(postAuth));
  };

  const onClickToLoginRedirection = (e) => {
    e.preventDefault();

    if (!event) return history.push(`/auth/login/candidate`);

    return history.push('signin');
  }

  return (
    !authUser ?
      <Wrapper size="large">
        <div className={styles.container}>
          <Bloc maxWidth={500}>
            <Form className={styles.signupForm} onSubmit={handleOnSubmitRegistration}>
              <H3 className={styles.title}>
                {t({ id: !event ? 'signup.candidate' : 'signin.signup' })}
              </H3>

              <Username form={form} />
              <Password form={form} withPasswordPolicy />

              <ErrorAlert apiMessage={errorMessage} onClickToLoginRedirection={onClickToLoginRedirection} />

              <Cgu form={form} />
              <Rgpd form={form} />

              <div className={styles.submitBtns}>
                <Button
                  type="submit"
                  className="w-full"
                  loading={isLoading}
                >
                  {t({ id: 'signin.signup' })}
                </Button>
                <div className="w-full mr-8">
                  <LinkedinOauth className="w-full p-0" >
                    <Button className="w-full whitespace-nowrap" variant="outline" color="neutral" imageComponentLeft={<IconLinkedinFill size={16} />}>
                      {t({ id: 'signup.linkedin' })}
                    </Button>
                  </LinkedinOauth>
                </div>
              </div>
            </Form>
          </Bloc>

          <Bloc className={styles.BlocLogin}>
            <div className={styles.loginContainer}>
              <Body2 color={colors.neutral['400']}>
                {t({ id: 'signup.existingAccount' })}
              </Body2>
              <Button className={styles.login} onClick={onClickToLoginRedirection} variant="outline">
                {t({ id: 'login' })}
              </Button>
            </div>
          </Bloc>
        </div>
      </Wrapper> : false
  )
}

SignupDev.propTypes = {
  event: object,
  form: shape({
    getFieldDecorator: func,
    getFieldsError: func,
    getFieldError: func,
    isFieldTouched: func,
  }),
}

const withForm = Form.create();

export default compose(
  withForm,
)(SignupDev);
