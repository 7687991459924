import React from 'react';
import { func, object } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Form } from 'antd';
import { Bloc, BlocHeader } from '@/components/Bloc';

import Hybrid
  from '@/scenes/Event/scenes/Owner/scenes/Setting/scenes/Goals/components/Form/Hybrid';
import NonHybrid
  from '@/scenes/Event/scenes/Owner/scenes/Setting/scenes/Goals/components/Form/NonHybrid';


const ParticipantForm = ({
  form,
  initialValues,
  onSubmit,
  event,
}) => {


  return (
    <Bloc>
      <BlocHeader>
        <FormattedMessage id="event.goals.stats.title" tagName="h2" />
      </BlocHeader>
      { event.format === 'hybrid' && <Hybrid onSubmit={onSubmit} event={event} form={form} />}
      { ['physical', 'virtual'].includes(event.format) && <NonHybrid event={event} onSubmit={onSubmit} initialValues={initialValues} form={form} />}
    </Bloc>
  );
};

ParticipantForm.propTypes = {
  onSubmit: func,
  form: object,
  initialValues: object,
  event: object,
};

export default Form.create()(ParticipantForm);
