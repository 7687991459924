import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { func } from 'prop-types';

import { useFormatMessage } from 'react-intl-hooks';


import { appActions } from '@/store/app';
import Icon from '@/components/Icon';


import styles from '../../../styles.less';

const Calendar = ({ toggleCalendar }) => {
  const t = useFormatMessage();

  return (
    <div className={[styles.colAuth]}>
      <a onClick={() => { toggleCalendar(); }} role="button" tabIndex={0}>
        <Icon name="calendar" style={{ width: '22px', height: '22px', color: '#A5ABB9' }} />
        <span>{t({ id: 'profile.settings.calendar.title' })}</span>
      </a>
    </div>
  );
};

Calendar.propTypes = {
  toggleCalendar: func,
};

const mapDispatchToProps = {
  toggleCalendar: appActions.toggleCalendar,
};

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect)(Calendar);
