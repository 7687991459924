import React, { useEffect } from 'react';
import { compose } from 'redux';
import { toJS } from '@/utils/index';
import { withRouter } from 'react-router-dom';

import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAuthUser } from '@/store/auth/selectors';
import { useGetCheckCandidateOnboardings } from '@/queries/users/useGetCheckCandidateOnboardings';

const HomeScene = () => {
  const history = useHistory();
  const authUser = useSelector(getAuthUser)?.toJS();

  const { isLoading } = useGetCheckCandidateOnboardings(
    { user: authUser, onSuccess: ({ currentOnboardings, countOnboardingsCompleted }) => {
      const recentActiveParticipation = currentOnboardings?.[0];

      if (recentActiveParticipation && countOnboardingsCompleted === 0) {
        const slug = recentActiveParticipation._event.slug

        history.replace(`/${slug}/candidate/preparation/onboarding/profile`);
      } else {
        history.replace(`/events`);
      }
    } });


  if (authUser?.isActive && !isLoading) {
    history.replace('/events');
  }

  if (!authUser) {
    history.replace('/auth/login');
  }

  return null;
}


export default compose(
  withRouter,
)(toJS(HomeScene));
