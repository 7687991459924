import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { object, func } from 'prop-types';
import { Button } from '@seekube-tech/ui-kit';
import Html from '@/components/Html';

import messages from '../../messages';
import styles from './styles.less';
import parentStyles from '../../styles.less';

const Failure = ({ intl, onValueSelected }) => (
  <div className={styles.failure}>
    <FormattedMessage tagName="h2" {...messages.recommendationsFailureTitle} />
    <hr />

    <Html
      value={intl.formatMessage(messages.recommendationsFailureText)
      }
    />
    <div className={parentStyles.actions}>
      <Button onClick={() => { onValueSelected('phone'); }}>
        <FormattedMessage {...messages.recommendationsFailureBtn} />
      </Button>
    </div>
  </div>
);

Failure.propTypes = {
  intl: object,
  onValueSelected: func,
};

export default injectIntl(Failure);
