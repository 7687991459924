import React from 'react';
import { FormattedMessage } from 'react-intl';
import { object, oneOf } from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { EVENT_STATUS } from '@/utils/constants';
import { trackCandidateClickedRegistration, trackCandidateClickedSigninLanding } from '@/utils/analytics';

import { Button } from '@seekube-tech/ui-kit';
import styles from '../../../styles.less';

import messages from '../../../messages';
import UserDropdown from '../UserDropdown';
import LanguageSelector from '@/components/LanguageSelector';

const Auth = ({
  event,
  authUser,
  history,
  context,
  participant,
}) => {
  if (authUser?.isActive) return <UserDropdown history={history} />;

  const onRegistrationClick = () => {
    if (!isEmpty(participant)) {
      trackCandidateClickedRegistration({ authUser, event, countOffers: participant.compatibleOffers });
    }
  };
  const logUrls = getLogsUrls(event?.slug);

  return (
    <div className={styles.headerAuthentication}>
      {context !== 'landing' && <LanguageSelector/>}
      {event &&
        <Link to={logUrls.signin}>
          <Button
            className={styles.signinTransparent}
            onClick={() => trackCandidateClickedSigninLanding(
              { event })}><FormattedMessage {...messages.signin} />
          </Button>
        </Link>
      }</div>
  );
};

Auth.propTypes = {
  event: object,
  authUser: object,
  participant: object,
  history: object,
  context: oneOf(['event', 'landing', 'public', 'home', 'client']),
};

const getLogsUrls = (slug) => ({
  signin: `/${slug}/candidate/preparation/onboarding/signin`,
  signup: `/${slug}/candidate/preparation/onboarding/signup`,
  preregistration: `/${slug}/candidate/preparation/participation`,
});

export { Auth };
