import { useQuery } from 'react-query';
import request from '@/utils/request';
import { objectToParams } from '@/utils/url';

export function useGetEventsSelect({ userId, search = {}}) {
  const searchParams = objectToParams({ ...search, userId });

  const requestUrl = `${process.env.FRONT_API_URL}/organizations/agenda/planning/events-select?${searchParams}`

  const queryFn = () => request(requestUrl, { method: 'GET' });

  const query = useQuery({
    queryKey: ['agenda', 'planning', 'eventsSelect', userId],
    queryFn,
    refetchOnWindowFocus: false,
  });

  return query;
}
