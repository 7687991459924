import React, { useEffect } from 'react';
import { bool, func, number, object, string } from 'prop-types';
import { Row, Col } from 'antd';
import { useFormatMessage } from 'react-intl-hooks';
import InlineEditor from '@/components/InlineEditor';
import SelectIcons from '@/components/Form/SelectIcons';
import Number from './Number';
import Title from '@/scenes/Event/components/Title';
import styles from '@/scenes/Event/containers/Landing/components/Contents/components/GeneralInfos/components/LandingContent/styles.less';

const Numbers = ({
  event,
  contentIndex,
  editMode,
  onChange,
  onInit,
  locale,
}) => {

  const t = useFormatMessage()

  useEffect(() => {
    onInit(contentIndex)({
      inNav: true,
      label: t({id: 'event.content.text.label'}),
      h2: t({id: 'event.content.text.h2'}),
      h3: t({id: 'event.content.text.h3'}),
      content: [
        { label: t({id: 'event.content.numbers.content[0].label'}, {value: '81'})},
        { label: t({id: 'event.content.numbers.content[1].label'}, {value: '44'})},
        { label: t({id: 'event.content.numbers.content[2].label'}, {value: '31'})},
      ],
    });
  }, [])

  const renderNumbers = () => {
    const { content } = event._contents.find(e => e.name === 'numbers')[locale]
    if (typeof content !== 'object' || !content || content.length <= 0) {
      return null;
    }

    return content.map((c, index) => (
      <Col md={12} lg={4} key={index} style={editMode ? { flex: 1 } : {}}>
        <Number
          style={editMode ? { textAlign: 'center' } : {}}
          number={(
            <InlineEditor
              editorEnabled={editMode}
              value={c.value}
              onChange={onChange(['_contents', contentIndex, locale, 'content', index, 'value'])}
            />
          )}
          text={(
            <InlineEditor
              editorEnabled={editMode}
              value={c.label}
              onChange={onChange(['_contents', contentIndex, locale, 'content', index, 'label'])}
            />
          )}
          icon={editMode ? (
            <SelectIcons initialValue={c.icon} onChange={onChange(['_contents', contentIndex, locale, 'content', index, 'icon'])} />
          ) : c.icon}
        />
      </Col>
    ));
  };

  return (
    <div className={styles.numbersWrapper}>
      <Title
        event={event}
        contentIndex={contentIndex}
        editMode={editMode}
        onChange={onChange}
        locale={locale}
      />
      <Row type="flex" justify="space-evenly" style={{ justifyContent: 'space-evenly' }} className={styles.numbersContainer}>
        {renderNumbers()}
      </Row>
    </div>
  );
}

Numbers.propTypes = {
  event: object,
  contentIndex: number,
  editMode: bool,
  onChange: func,
  onInit: func,
  locale: string,
}

export default Numbers;
