import React, { PureComponent } from 'react';
import { object, oneOf, func, bool } from 'prop-types';
import { injectIntl } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import classNames from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isEmpty, get } from 'lodash';
import { toJS } from '@/utils';

// Store
import { eventSelectors } from '@/store/event';
import { authSelectors } from '@/store/auth';
import { participantSelectors } from '@/store/participant';
import { exponentSelectors } from '@/store/exponent';

// Components
import { ClientHeader, BasicRecruiterHeader, LandingHeader, CandidateHeader, AlertIe } from './components';
import { getUserRole } from './helpers';

// Styles & Translations
import styles from './styles.less';


class Header extends PureComponent { // eslint-disable-line
  static propTypes = {
    event: object,
    location: object,
    intl: object,
    authUserConnectedAs: object,
    authUser: object,
    toggleCalendar: func,
    participant: object,
    context: oneOf(['event', 'landing', 'public', 'home', 'client']),
    history: object,
  };

  static defaultProps = {
    context: 'event',
  };

  static getDerivedStateFromProps(nextProps, nextState) {
    const newState = {};

    if (nextProps.location.pathname !== nextState.currentRoute) {
      newState.currentRoute = nextProps.location.pathname;
    }

    const {event} = nextProps;

    if (event && event._id && !isEmpty(event.keyDates)) {
      let targetDate = null;

      if (event.keyDates) {
        switch (event.dateStatus) {
          case 'open':
            targetDate = event.keyDates.jobfair.beginAt;
            break;

          case 'discovering':
            targetDate = event.keyDates.jobfair.beginAt;
            break;

          case 'preregistration':
            targetDate = event.keyDates.jobfair.beginAt;
            break;

          case 'in_progress':
            targetDate = event.keyDates.jobfair.endAt;
            break;

          default:
            targetDate = event.keyDates.jobfair.endAt;
        }
      }

      if (nextState.targetDate !== targetDate) {
        newState.targetDate = targetDate;
      }
    }

    if (!isEmpty(newState)) {
      return newState;
    }

    return null;
  }

  state = {
    headerHeight: 0,
    panelIsOpen: false,
    scrollTop: null,
    currentRoute: this.props.location.pathname,
    targetDate: null,
    spotlightIsVisible: false,
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  isClient = () => {
    const { props: { authUserConnectedAs, authUser } } = this;
    const isRecruiter = getUserRole(authUser, authUserConnectedAs) === 'recruiter';

    const connectedUser = !isEmpty(authUserConnectedAs) ? authUserConnectedAs : authUser;

    return get(connectedUser, '_currentOrganization.isClient') && isRecruiter;
  };

  handleScroll = () => {
    const supportPageOffset = window.pageXOffset !== undefined;
    const isCSS1Compat = ((document.compatMode || '') === 'CSS1Compat');
    const scrollTop = supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop; // eslint-disable-line

    this.setState({ scrollTop });
  };

  render() {
    const {
      props: { context, authUser, authUserConnectedAs },
      state: { targetDate },
    } = this;

    const showEvent = location.href.indexOf('candidate/preparation/onboarding') === -1 && context !== 'landing';
    const authUserRole = getUserRole(authUser, authUserConnectedAs);
    const isClient = this.isClient();

    return (
      <>
        <AlertIe />
        <header
          className={classNames(styles.header, styles[context], styles[authUserRole])}
          ref={(el) => this.headerRef = el} // eslint-disable-line
        >
          {
            (isClient || context === 'client') && context !== 'landing' ?
              <ClientHeader
                {...this.props}
                targetDate={targetDate}
                showEvent={showEvent}
              />
              : context !== 'landing' && authUserRole === 'recruiter' && <BasicRecruiterHeader {...this.props} {...this.state} />
          }
          { authUserRole === 'candidate' && context !== 'landing' && <CandidateHeader {...this.props} {...this.state} /> }
          { context === 'landing' && <LandingHeader {...this.props} {...this.state} /> }
        </header>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  event: eventSelectors.getCurrentEvent,
  authUserConnectedAs: authSelectors.getAuthUserConnectedAs,
  authUser: authSelectors.getAuthUserNotConnectedAs,
  participant: participantSelectors.getCurrentParticipant,
  exponent: exponentSelectors.getCurrentExponent,
});

const withConnect = connect(mapStateToProps, null);

export default compose(
  withRouter,
  withConnect,
  injectIntl,
)(toJS(Header));
