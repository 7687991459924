import React, { PureComponent } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { func, object } from 'prop-types';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';

import { toJS } from '@/utils';

import { Route, Switch } from 'react-router-dom';
import { Tab } from '@/components/Tab';
import Wrapper from '@/components/Wrapper';


import { eventActions } from '@/store/event';
import OrganizationJobdating from '../../../Admin/scenes/Organizations/scenes/Organization/scenes/Jobdating';
import ClientMembersPage from '../../../Admin/scenes/Organizations/scenes/Organization/scenes/Users';
import messages from './messages';
import styles from './styles.less';
import { createStructuredSelector } from 'reselect';
import { organizationSelectors } from '@/store/organization';
import NotFound from '@/scenes/NotFound/Loadable';


class ClientMembersScene extends PureComponent {
  render() {
    const { props: { intl, match, push, currentOrganization } } = this;
    const { pathname } = window.location;

    if (match.isExact) {
      push('member/by-collaborator');
    }

    return (
      <Wrapper type="scene">
        <Tab
          className={styles.tabHeader}
          items={[
            { label: intl.formatMessage(messages.viewByCollaborator), onClick: () => push('by-collaborator') },
            { label: intl.formatMessage(messages.viewByEvent), onClick: () => push('by-event') },
          ]}
          defaultIndex={pathname.indexOf('by-event') > -1 ? 1 : 0}
        />
        <Switch>
          <Route path={`${match.path}/by-collaborator`} render={() => currentOrganization && <ClientMembersPage organization={currentOrganization} />} />
          <Route path={`${match.path}/by-event`} render={() => <OrganizationJobdating context="client" />} />
        </Switch>
      </Wrapper>

    );
  }
}

ClientMembersScene.propTypes = {
  intl: object,
  match: object,
  push: func,
  getEvents: func
};

const mapStateToProps = createStructuredSelector({
  isFetching: organizationSelectors.getOrganizationFetching,
  organization: organizationSelectors.getSpecificOrganization,
  currentOrganization: organizationSelectors.getCurrentOrganization,
});

const mapDispatchToProps = {
  push,
  getEvents: eventActions.getEvents
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  injectIntl,
)(toJS(ClientMembersScene));
