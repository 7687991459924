import { parseJSON, checkStatus } from '@/utils/request';

const getExponentsStats = async ({ eventId, eventFormat = 'all' }) => {
  const requestUrl = `${process.env.FRONT_GO_API_URL_OLD}/eventkpi-light?apiKey=41a1289e-fee1-430e-a849-5b2b7ed23f1a&eventId=${eventId}&eventFormat=${eventFormat}`;

  return fetch(requestUrl).then(checkStatus)
    .then(parseJSON);
};

const getExponentsUsers = async ({ eventId, notReady }) => {
  const requestUrl = `${process.env.FRONT_GO_API_URL}/stats/exponents-users-list?apiKey=41a1289e-fee1-430e-a849-5b2b7ed23f1a&eventId=${eventId}&notReady=${notReady}`;

  return fetch(requestUrl).then(checkStatus)
    .then(parseJSON);
};

const getExponents = async ({ eventId }) => {
  const requestUrl = `${process.env.FRONT_GO_API_URL}/stats/exponents-list?apiKey=41a1289e-fee1-430e-a849-5b2b7ed23f1a&eventId=${eventId}`;

  return fetch(requestUrl).then(checkStatus)
    .then(parseJSON);
};

const getParticipants = async ({ eventId, eventFormat = 'all' }) => {
  const requestUrl = `${process.env.FRONT_GO_API_URL_OLD}/eventkpi-participants?apiKey=41a1289e-fee1-430e-a849-5b2b7ed23f1a&eventId=${eventId}&eventFormat=${eventFormat}`;

  return fetch(requestUrl).then(checkStatus)
    .then(parseJSON);
};

export { getExponentsStats, getExponentsUsers, getExponents, getParticipants };
