import { getId } from "@/utils/global";

export function getParticipantResumeUrl(jwt, participant, light = false) {
  return `${process.env.FRONT_API_URL}/events/${getId(participant._event)}/participants/${participant._id}/download-cv?shouldLight=${light}&jwt=${jwt}`;
}

export function getUserResumeUrl(jwt, light = false) {
  return `${process.env.FRONT_API_URL}/users/me/download-cv?shouldLight=${light}&jwt=${jwt}`;
}

export function getUserMediaUrl(jwt, s3key, light = false) {
  return `${process.env.FRONT_API_URL}/auth/me/download?key=${s3key}&shouldLight=${light}&jwt=${jwt}`;
}
