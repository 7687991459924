import React, { useState } from 'react';
import { object } from 'prop-types';
import { isEmpty } from 'lodash';
import { Form, Row } from 'antd';
import { Body1, H3, Button, IconAtSign } from '@seekube-tech/ui-kit';
import colors from '@seekube-tech/ui-kit/dist/colors';
import { useFormatMessage } from 'react-intl-hooks';
import { compose } from 'redux';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import InputMaterial from '@/components/Input/InputMaterial/index';
import { Bloc } from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/components/Bloc';
import styles2 from './styles2.less';
import { authLogin } from '@/store/auth/actions';
import Wrapper from '@/components/Wrapper';
import { queryStringToObject } from '@/utils/url';
import { withLayout } from '@/containers/Layout';
import { Footer } from '@/scenes/Auth/scenes/Login/Footer';
import { AlertNoAccount } from '@/scenes/Auth/scenes/Login/AlertNoAccount';
import { SubscriptionRedirectionBloc } from '@/scenes/Auth/scenes/Login/SubscriptionRedirectionBloc';
import { Password } from '@/forms/components';
import { API_CUSTOM_ERRORS } from '@/utils/constants';

const FormItem = Form.Item;

const Login = ({
  form,
  match
}) => {
  const t = useFormatMessage();
  const dispatch = useDispatch();
  const history = useHistory();
  const { role } = match.params;
  const [errorMessage, setErrorMessage] = useState()

  const handleOnSubmitRegistration = (e) => {
    e.preventDefault();

    return form.validateFields((err, values) => {
      if (!err) {

        dispatch(authLogin({
          ...values,
          callback:(response = {}) => {
            if (response.message === 'blocked_account') {
              setErrorMessage(t({ id: `api.error.${API_CUSTOM_ERRORS.blockedAccount}` }))
            }

            if ([API_CUSTOM_ERRORS.wrongPassword, API_CUSTOM_ERRORS.userNotFound].includes(response.message)) {
              setErrorMessage(t({ id: 'signin.error' }));
            }

            if (isEmpty(response?.error)) {
              history.replace(queryStringToObject(window.location.search).redirect)
            }
          },
          notificationParams: {
            error: {
              message: t({ id: "signin.error" })
            }
          }
        }))
      }
    });
  };

  return (
    <Wrapper size="large">
      <div className={styles2.container}>
        <Bloc maxWidth={500}>
          <div className={styles2.blocContent}>
            <Form className={styles2.signupForm} onSubmit={handleOnSubmitRegistration}>
              <H3 className={styles2.title}>{t({ id: 'login' })}</H3>

              <Row>
                <FormItem>
                  {form.getFieldDecorator('username', {
                    initialValue: window.localStorage.getItem('signup-email'),
                    rules: [{ required: true, type: 'email', message: t({ id: 'form.mandatory' }) }],
                    validateTrigger: 'onSubmit',
                  })(
                    <InputMaterial addOnIcon={<IconAtSign size={24} />} label={t({ id: 'form.email' })} placeholder={t({ id: 'form.email' })} type="mail" validations={['required']} />
                  )}
                </FormItem>
              </Row>
              <Row>
                <Password form={form} />
              </Row>

              {errorMessage &&
                <Body1 className="mb-20" color={colors.error['500']}>
                  {errorMessage}
                </Body1>
              }

              <div className="mb-20 login-actions">
                <Button
                  type="submit"
                  className="w-full mt-8"
                  id="submit-login"
                >
                  {t({ id: 'login' })}
                </Button>
              </div>
            </Form>
            <Footer role={role} />

          </div>
        </Bloc>

        <SubscriptionRedirectionBloc role={role} />

      </div>
      <AlertNoAccount role={role} />
    </Wrapper>
  )
}

Login.propTypes = {
  form: object,
  match: object,
}

const withForm = Form.create();

export default compose(
  withForm,
  withLayout({ size: 'full', navType: 'candidate' }),
)(Login);
