import React from 'react';
import { shape, func, object } from 'prop-types';
import { Form } from 'antd';
import { Button, H5 } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { useHistory } from 'react-router-dom';
import InputMaterial from '@/components/Input/InputMaterial';
import { removeWhitespace } from '@/utils/format';

const FormItem = Form.Item;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

const EmailForm = ({
  form,
  resetPasswordQuery,
}) => {
  const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = form;
  const usernameError = isFieldTouched('username') && getFieldError('username');
  const t = useFormatMessage();
  const history = useHistory();

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const username = form.getFieldValue('username');
    resetPasswordQuery.mutate({
      username,
      url: window.location.href
    });
  }

  return (
    <>
      <H5 className="mb-24">{t({ id: 'resetPassword.title' })}</H5>
      <Form onSubmit={handleOnSubmit}>
        <FormItem
          validateStatus={usernameError ? 'error' : ''}
          help={usernameError || ''}
        >
          {getFieldDecorator('username', {
            normalize: removeWhitespace,
            rules: [{ required: true, type: 'email', message: t({ id: 'form.mandatory' }) }],
          })(
            <InputMaterial
              addOnIcon="mail"
              label={t({ id: 'form.email' })}
              placeholder={t({ id: 'form.email' })}
              type="email"
              validations={['required']}
            />
          )}
        </FormItem>

        <div className="confirm-actions">
          <Button
            onClick={() => history.push('/')}
            variant="outline"
            color="neutral"
          >
            {t({ id: 'resetPassword.backToLogin' })}
          </Button>
          <Button
            disabled={hasErrors(getFieldsError()) || resetPasswordQuery.isLoading }
            loading={resetPasswordQuery.isLoading}
            type="submit"
          >
            {t({ id: 'resetPassword.reset'})}
          </Button>
        </div>
      </Form>
    </>
  );
}

EmailForm.propTypes = {
  form: shape({
    getFieldDecorator: func,
    getFieldsError: func,
    getFieldError: func,
    isFieldTouched: func,
  }),
  resetPasswordQuery: object,
}

export default Form.create()(EmailForm);
