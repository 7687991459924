import React from 'react';
import { object, array, func, string, bool } from 'prop-types';

import { trackRecruiterClickedViewAllAppointments } from '@/utils/analytics';

import { useFormatMessage } from 'react-intl-hooks';

import Select from '@/components/Form/Select';
import Icon from '@/components/Icon';
import TagWithAvatar from '@/components/Tag/TagWithAvatar';
import { Select as SelectAntd } from 'antd';

import styles from './styles.less';

const { Option } = SelectAntd;

const SelectRecruiter = ({
  authUser,
  selectedUser = {},
  users = [],
  handleSelectRecruiter,
  displayOptionAll = true,
}) => {
  const t = useFormatMessage();
  const options = users
    ?.map((orgUser) => (
      <Option key={orgUser.fullName} value={orgUser._id} title={orgUser.fullName}>
        <TagWithAvatar user={orgUser} src={orgUser.pictureUrl ?? ''} name={orgUser.fullName} />
      </Option>
    )) || [];

  const optionSelectAll = (
    <Option key="allUsers" onClick={() => trackRecruiterClickedViewAllAppointments({ authUser })} value="all">
      <Icon name="users" style={{ width: '28px', height: '24px' }} /> {t({ id: 'calendar.allAppointments' })}
    </Option>)
  ;

  return (
    <div id="users">
      <Select
        showSearch
        id="users"
        value={selectedUser}
        className={styles.inputSelect}
        suffixIcon={<Icon name="chevron" />}
        addOnIcon="user"
        placeholder={t({ id: 'calendarOf' })}
        onSelect={handleSelectRecruiter}
        filterOption={(input, option) => option.props.title && option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        {displayOptionAll ? [optionSelectAll, ...options] : options}
      </Select>
    </div>);
};

SelectRecruiter.propTypes = {
  authUser: object,
  selectedUser: string,
  users: array,
  handleSelectRecruiter: func,
  displayOptionAll: bool,
};

export { SelectRecruiter };
