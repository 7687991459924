import React, { useState, useEffect } from 'react';
import moment from 'moment';
import classnames from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import { object, string, bool, any, func } from 'prop-types';
import { Steps } from 'antd';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useFormatMessage } from 'react-intl-hooks';
import { push } from 'connected-react-router';
import {
  IconMapPin,
  IconWifi,
  IconEye,
  IconEyeOff,
  IconInfo,
  BlankState,
  Button,
  IconEdit,
  Tag,
  Tab,
} from '@seekube-tech/ui-kit';
import { toJS } from '@/utils';
import { dateFormat } from '@/utils/format';
import { authSelectors } from '@/store/auth';
import { eventSelectors } from '@/store/event';
import { participantSelectors } from '@/store/participant';
import { DASHBOARD_MODE, EVENT_FORMAT } from '@/utils/constants';
import Wrapper from '@/components/Wrapper';
import Icon from '@/components/Icon';
import request from '@/utils/request';
import Separator from '@/components/Separator';
import ModalV2 from '@/components/ModalV2';
import { RenderStat, StatsRaw } from '@/components/Stats';
import { getEventKeyMoments } from '@/helpers/event/getEventKeyMoments';
import { getAvailableActions } from '@/store/availableActions/selectors';
import { getExponentsStats, getParticipants } from '../OnBoarding/containers/Dashboard/requests';
import { StatsSections } from './components';

import styles from './styles.less';
import RealTimeActivity from './components/RealTimeActivity';
import ModalActivities from './components/ModalActivities';
import { Header } from '@/scenes/Event/scenes/Owner/scenes/Dashboard/components/Header';

const { Step } = Steps;

const OwnerDashboardPage = ({
  authUser,
  event,
  history
}) => {
  const {
    keyDates,
    keyMoments,
    skipAppointmentValidation, enableAddingExponent, status,
  } = event;

  const availableActions = useSelector(getAvailableActions);

  const t = useFormatMessage();

  const [stats, setStats] = useState({ exponents: {}, participants: {} });
  const [view, setView] = useState(EVENT_FORMAT.PHYSICAL);
  const [indexModalIsOpen, setIndexModalIsOpen] = useState(false);
  const [participantsStatus, setParticipantsStatus] = useState('ready');
  const [offersStatus, setOffersStatus] = useState('ready');
  const [exponentsStatus, setExponentsStatus] = useState('ready');
  const [isActivitiesModalVisible, setIsActivitiesModalVisible] = useState(false);

  useEffect(
    () => {
      Promise.all([
        getExponentsStats({ eventId: event._id, eventFormat: event.format === EVENT_FORMAT.HYBRID ? view : 'all'  }),
        getParticipants({ eventId: event._id, eventFormat: event.format === EVENT_FORMAT.HYBRID ? view : 'all'  }),
      ]).then((res) =>
        setStats({ exponents: res[0], participants: res[1] }));
    }, [view],
  );

  const indexRequest = (type, init, callback) => {
    init();
    request(`${process.env.FRONT_API_URL}/events/${event._id}/index/${type}`, {
      method: 'POST',
      body: '',
    }).then(() => callback());
  }

  const handleIndexData = async () => {
    // Fetch participants
    setIndexModalIsOpen(true);

    indexRequest(
      'participants',
      () => setParticipantsStatus('pending'),
      () => setParticipantsStatus('done'),
    );

    indexRequest(
      'offers',
      () => setOffersStatus('pending'),
      () => setOffersStatus('done'),
    );

    indexRequest(
      'exponents',
      () => setExponentsStatus('pending'),
      () => setExponentsStatus('done'),
    );
  }

  const getActivePhase = () => {
    const now = moment();
    const subscriptionCandidate = moment(keyDates?.discovering.beginAt).isBefore(now);
    const eventVisibleForCandidate = status === 'published';
    const appointments = skipAppointmentValidation ? moment(keyDates?.discovering.beginAt).isBefore(now) : moment(keyDates?.hunt.beginAt).isBefore(now);
    const eventFinished = moment(keyDates?.jobfair.endAt).isBefore(now);
    const activeStep = appointments ? 2 : (subscriptionCandidate && 1) || 0;

    return {
      enableAddingExponent,
      eventVisibleForCandidate,
      subscriptionCandidate,
      canAccessCandidate: appointments,
      appointments,
      activeStep,
      eventFinished,
    };
  };

  if (!keyDates) {
    return (
      <div className={styles.blankStateContainer}>
        <BlankState />
        <p>{t({id: 'event.owner.exponents.blankState.prevent.from.access.dashboard'})}</p>
      </div>
    )
  }

  const phases = getActivePhase();
  const { activeStep } = phases;
  const syncIsOver = participantsStatus === 'done' && offersStatus === 'done' && exponentsStatus === 'done';

  const beginAt = event.format === EVENT_FORMAT.HYBRID ? keyMoments?.jobfair.find(e => e.format === view).beginAt : keyDates?.jobfair.beginAt;
  const endAt = event.format === EVENT_FORMAT.HYBRID ? keyMoments?.jobfair.find(e => e.format === view).endAt : keyDates?.jobfair.endAt;
  const isLinkVisible = availableActions.dashboardView.isLinkVisible({event, view});

  return (
    <Wrapper type="scene" context="owner">
      <div className={styles.dashboard}>
        <Header
          title={event.name}
          subtitle={dateFormat(beginAt, endAt)}
          withTag={phases.eventFinished}
          action={
            <div className={styles.actionsHeader}>
              <a href={t({ id: "owner.settings.dashboard.helpLink" })} target="_blank" style={{ padding: 0 }}>
                <Button imageComponentLeft={<IconInfo size="16" />} variant='outline'>
                  <FormattedMessage id="helpcenter.owner" />
                </Button>
              </a>
              {event.modules.dashboard.enable &&
                <Button onClick={() => setIsActivitiesModalVisible(true)} imageComponentLeft={<IconEdit size="16" />} className={styles.activitiesBtn}>
                  {t({id: 'button.manage.activities'})}
                </Button>
              }
            </div>
          }
          event={event}
          history={history}
          view={view}
          setView={setView}
          availableActions={availableActions}
          isLinkVisible={isLinkVisible}
        />

        {isActivitiesModalVisible &&
          <ModalActivities event={event} onCancel={() => setIsActivitiesModalVisible(false)} />
        }

        {location.pathname.includes('realtime') ?
          <div>
            <RealTimeActivity event={event} view={event.format !== EVENT_FORMAT.HYBRID ? 'all' : view}/>
          </div>
          :
          <>
            <div className={styles.stepBarContainer}>
              <div className="">
                <Steps progressDot current={activeStep}>
                  <Step />
                  <Step />
                  <Step />
                </Steps>
              </div>
            </div>
            <StatsSections stats={stats} event={event} phases={phases} view={event.format === EVENT_FORMAT.HYBRID ? view : 'all' }/>
          </>
        }

        {authUser.isAdmin ? (<div style={{ textAlign: 'center' }}>
          <Separator height={24} />
          {participantsStatus === 'pending' || offersStatus === 'pending' || exponentsStatus === 'pending' ? (
            <Button type="primary" onClick={() => setIndexModalIsOpen(true)}>
              {t({ id: 'admin.syncAlgolia.cta.inProgress' })}
            </Button>
          ) : (
            <Button type="primary" onClick={handleIndexData}>
              {t({ id: 'admin.syncAlgolia.cta' })}
            </Button>
          )}
        </div>) : null}
      </div>


      {authUser.isAdmin ? (
        <ModalV2
          template="defaultSophie"
          visible={indexModalIsOpen}
          onClose={() => setIndexModalIsOpen(false)}
        >
          <div>
            {syncIsOver ?
              (<h2>{t({ id: 'admin.syncAlgolia.ended' })}</h2>) : (<h2>{t({ id: 'admin.syncAlgolia.pending' })}</h2>)}
          </div>

          {!syncIsOver ? (<p className="help">{t({ id: 'admin.syncAlgolia.help' })}</p>) : null}

          <Separator height={32} />
          <div>
            <StatsRaw>
              <RenderStat
                key="participants"
                title={t({ id: 'stats.participants'})}
                style={{ marginRight: 16 }}
                text={participantsStatus !== 'done' ? (
                  <Button type="warning" loading>{t({ id: 'events.inProgress' })}</Button>
                ) : (<Button type="success">{t({ id: 'ended' })}</Button>)}
              />

              <RenderStat
                key="offers"
                title={t({ id: 'stats.offers'})}
                text={participantsStatus !== 'done' ? (
                  <Button type="warning" loading>{t({ id: 'events.inProgress' })}</Button>
                ) : (<Button type="success">{t({ id: 'ended' })}</Button>)}
              />

              <RenderStat
                key="exponents"
                title={t({ id: 'stats.exponents'})}
                text={participantsStatus !== 'done' ? (
                  <Button type="warning" loading>{t({ id: 'events.inProgress' })}</Button>
                ) : (<Button type="success">{t({ id: 'ended' })}</Button>)}
              />
            </StatsRaw>
          </div>

          <Separator height={24} />
        </ModalV2>
      ) : null}
    </Wrapper>
  );
};


const PageHeader = ({ title, subtitle, withTag = false, action = null, event, history, view = EVENT_FORMAT.PHYSICAL, setView, availableActions, isLinkVisible }) => {
  const t = useFormatMessage();
  const realtimeDashboard = `/${event.slug}/owner/dashboard/realtime`;
  const defaultDashboard = `/${event.slug}/owner/dashboard`;
  const today = moment(new Date());
  const isJobDatingBegun = moment(event.keyDates?.jobfair.beginAt).isSameOrBefore(today)
    && moment(event.keyDates?.jobfair.endAt).isAfter(today);

  const [dashboardMode, setDashboardMode] = useState(DASHBOARD_MODE.STATIC)

  useEffect(() => {
    if (event.format !== EVENT_FORMAT.HYBRID && isJobDatingBegun) {
      setDashboardMode(DASHBOARD_MODE.DYNAMIC)
    }
    if (event.format === EVENT_FORMAT.HYBRID && isLinkVisible) {
      setDashboardMode(DASHBOARD_MODE.DYNAMIC)
    }
  }, [])

  useEffect(() => {
    if (dashboardMode === DASHBOARD_MODE.DYNAMIC) {
      history.push(realtimeDashboard)
    }
    else {
      history.push(defaultDashboard)
    }
  }, [dashboardMode])

  const getLinkToTheOtherView = () => {
    if (!isLinkVisible) {
      return null;
    }
    return (
      <button onClick={changeDashboardMode}>
        <div className={styles.linkToTheOtherView}>
          {location.pathname.includes('realtime') ? (
              <>
                <IconEyeOff size={16} />
                <FormattedMessage id="dashboard.link.realtime.close" />
              </>
            )
            : (
              <>
                <IconEye size={16} />
                <FormattedMessage id="dashboard.link.realtime.open" />
              </>
            ) }
        </div>
      </button>
    )
  }

  const getDateSubtitle = () => {
    if (event.format === 'hybrid') {
      const physicalDates = getEventKeyMoments(event, { type: 'jobfair', format: EVENT_FORMAT.PHYSICAL })
      const virtualDates = getEventKeyMoments(event, { type: 'jobfair', format: EVENT_FORMAT.VIRTUAL })
      return (
        <div className={styles.date}>
          <div className={styles.dateFormat}>
            <IconMapPin />
            {dateFormat(physicalDates.beginAt, physicalDates.endAt, true, 4)}
          </div>
          <div className={styles.dateFormat}>
            <IconWifi />
            {dateFormat(virtualDates.beginAt, virtualDates.endAt, true, 4)}
          </div>
        </div>
      )
    }
    return (
      <div className={styles.date}>
        <div><Icon name="calendar" /> </div>
        <span>{subtitle}</span>
      </div>
    )
  }

  const changeView = (newView) => {
    setDashboardMode(availableActions.dashboardView.getNewDashboardMode({event, view: newView, dashboardMode}))
    setView(newView)
  }

  const changeDashboardMode = () => {
    if (dashboardMode === DASHBOARD_MODE.DYNAMIC) {
      setDashboardMode(DASHBOARD_MODE.STATIC)
    }
    else {
      setDashboardMode(DASHBOARD_MODE.DYNAMIC)
    }
  }

  return (
    <div className={styles.header}>
      <div className={styles.title}>
        <div className={styles.leftCol}>
          <h2>{title}</h2>
          <Tag color="error" size="medium" className={classnames('ml-16', !withTag ? 'hide' : '')}>
            <FormattedMessage id="past.plgr" values={{ count: 1, gender: 'male' }} />
          </Tag>
        </div>
        {action ? (<div className={styles.rightCol}>{action}</div>) : null}
      </div>

      <div className={styles.subtitle}>
        {getDateSubtitle()}
        {event.format !== EVENT_FORMAT.HYBRID && getLinkToTheOtherView()}
      </div>
      {event.format === EVENT_FORMAT.HYBRID &&
        <div className="flex my-20 justify-between">
          <Tab.Group defaultIndex={0} className="grow mr-5">
            <Tab.List>
              <Tab as="span" icon={<IconMapPin size={16} />} onClick={() => changeView(EVENT_FORMAT.PHYSICAL)}>
                {t({ id: 'exponent.changeView.physical' })}
              </Tab>
              <Tab as="span" icon={<IconWifi size={16} />} onClick={() => changeView(EVENT_FORMAT.VIRTUAL)}>
                {t({ id: 'exponent.changeView.virtual' })}
              </Tab>
            </Tab.List>
          </Tab.Group>
          {getLinkToTheOtherView()}
        </div>
      }
    </div>
  )
};


PageHeader.propTypes = {
  title: string,
  subtitle: any,
  action: any,
  withTag: bool,
  push: func,
  history: object,
  view: string,
  setView: func,
  event: object,
};

OwnerDashboardPage.propTypes = {
  authUser: object,
  event: object,
  history: object,
};

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
  event: eventSelectors.getCurrentEvent,
  stats: participantSelectors.getParticipantsStats,
});

const mapDispatchToProps = {
  push,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  injectIntl,
  toJS,
)(OwnerDashboardPage);
