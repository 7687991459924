import React, { useEffect, useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useFormatMessage } from 'react-intl-hooks';
import { Modal } from 'antd';
import { Button, IconMapPin, IconWifi, IconMapPinWifi, IconCompany } from '@seekube-tech/ui-kit';
import { toJS } from '@/utils';
import { EVENT_FORMAT } from '@/utils/constants';

// Ducks
import { eventSelectors } from '@/store/event';
import { exponentSelectors, exponentActions } from '@/store/exponent';
import { organizationActions, organizationSelectors } from '@/store/organization';
import { authSelectors } from '@/store/auth';

// Components
import Icon from '@/components/Icon';
import notification from '@/components/Notification';

import styles from '../../../ExponentModal/styles.less';
import ConfirmationParticipationMode from '../ConfirmationParticipationMode';
import { getKeyMomentLink } from '@/helpers/exponent/getKeyMomentLink';



function ParticipationModeModal({
  event,
  isOpen = false,
  onOk,
  onCancel,
  patchExponent,
  exponents,
  exponentId,
}) {
  const t = useFormatMessage();

  const [participationModeError, setParticipationModeError] = useState(false);
  const [participationMode, setParticipationMode] = useState(null);
  const [keyMomentLinks, setKeyMomentLinks] = useState([]);
  const [exponent, setExponent] = useState(null);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
  const [oldMode, setOldMode] = useState('none');
  
  useEffect(() => {
    setExponent(exponents.find(e => e._id === exponentId));
  }, [exponentId]);

  useEffect(() => {
    if (exponent && exponent._keyMomentLinks && exponent._keyMomentLinks.length > 0) {
      if (exponent._keyMomentLinks.length > 1) {
        setOldMode(EVENT_FORMAT.HYBRID);
      }
      else {
        setOldMode(event.keyMoments.jobfair.find(e => e._id === exponent._keyMomentLinks[0]._keyMoment._id)?.format);
      }
      if (exponent._keyMomentLinks.length > 1) {
        setParticipationMode(EVENT_FORMAT.HYBRID);
      }
      else if (event.keyMoments.jobfair.find(e => e._id === exponent._keyMomentLinks[0]._keyMoment._id)?.format === EVENT_FORMAT.PHYSICAL) {
        setParticipationMode(EVENT_FORMAT.PHYSICAL);
      }
      else {
        setParticipationMode(EVENT_FORMAT.VIRTUAL);
      }
    }
  }, [exponent]);

  useEffect(() => {
    if (participationMode === EVENT_FORMAT.PHYSICAL) {
      setKeyMomentLinks([{
        _keyMoment: {
          _id: event.keyMoments.jobfair.find(e => e.format === EVENT_FORMAT.PHYSICAL)._id
        },
        type: 'jobfair',
        isReady: getKeyMomentLink(exponent, participationMode)?.isReady || false,
      }])
    }
    else if (participationMode === EVENT_FORMAT.VIRTUAL) {
      setKeyMomentLinks([{
        _keyMoment: {
          _id: event.keyMoments.jobfair.find(e => e.format === EVENT_FORMAT.VIRTUAL)._id
        },
        type: 'jobfair',
        isReady: getKeyMomentLink(exponent, participationMode)?.isReady || false,
      }])
    }
    else if (participationMode === EVENT_FORMAT.HYBRID) {
      setKeyMomentLinks([{
        _keyMoment: {
          _id: event.keyMoments.jobfair.find(e => e.format === EVENT_FORMAT.PHYSICAL)._id
        },
        type: 'jobfair',
        isReady: getKeyMomentLink(exponent, EVENT_FORMAT.PHYSICAL)?.isReady || false,
      },
      {
        _keyMoment: {
          _id: event.keyMoments.jobfair.find(e => e.format === EVENT_FORMAT.VIRTUAL)._id
        },
        type: 'jobfair',
        isReady: getKeyMomentLink(exponent, EVENT_FORMAT.VIRTUAL)?.isReady || false,
      }
    ])
    }
  }, [participationMode]);

  const handleOnOk = () => {
    if (!participationMode) {
      setParticipationModeError(true)
      return notification.error(t({ id: 'event.owner.exponents.input.participationMode.errorMessage' }));
    }

    patchExponent({
      eventId: event._id,
      exponentId: exponent._id,
      exponentParams: {
        _keyMomentLinks: keyMomentLinks,
      },
      callback: onOk,
    });
    return true;
  };

  const toggleConfirmationModal = () => {
    if (oldMode !== participationMode && participationMode === EVENT_FORMAT.HYBRID) {
      handleOnOk();
    }
    else if (oldMode !== participationMode) {
      setOpenConfirmationModal(!openConfirmationModal)
    } else {
      onCancel();
    }
  };

  return (
    <Modal
      onOk={handleOnOk}
      onCancel={() => onCancel()}
      visible={isOpen}
      width={660}
      footer={false}
      className={`customConfirm ${styles.exponentModalContainer}`}
      okText={t({ id: 'add' })}
      cancelText={t({ id: 'cancel' })}
    >
      <ConfirmationParticipationMode
        isOpen={openConfirmationModal}
        organizationName={exponent?._organization?.name}
        oldMode={oldMode}
        newMode={participationMode}
        onCancel={toggleConfirmationModal}
        onOk={() => {
          handleOnOk()
          toggleConfirmationModal();
        }} />
      <a role="button" tabIndex={0} className="modal-close" onClick={onCancel}>
        <Icon name="close" className="modal-close-icon" />
      </a>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '30px' }}>
        <h4 style={{ fontSize: '30px' }}>{t({ id: 'event.owner.participationModeModal.title'})}</h4>
      </div>

      <div className={styles.inputDisabled}>
        <IconCompany />
        <div className={styles.text}>
          <p className={styles.label}>{t({ id: 'event.owner.participationModeModal.inputDisabled.label'})} <span>*</span></p>
          <p className={styles.value}>{exponent?._organization?.name}</p>
        </div>
      </div>
      

      <div className={`${styles.participationMode} ${participationModeError && styles.participationModeError}`} style={{ marginTop: '20px' }}>
        <p className={styles.formLabel}>{t({ id: 'event.owner.exponents.input.participationMode.label' })} <span>*</span></p>
        <div className={styles.btnGroup}>
          <button onClick={() => setParticipationMode(EVENT_FORMAT.PHYSICAL)} className={participationMode === EVENT_FORMAT.PHYSICAL && styles.btnActive}><IconMapPin size={16} /> {t({ id: 'event.owner.exponents.input.participationMode.optionPhysical' })}</button>
          <button onClick={() => setParticipationMode(EVENT_FORMAT.VIRTUAL)} className={participationMode === EVENT_FORMAT.VIRTUAL && styles.btnActive}><IconWifi size={16} /> {t({ id: 'event.owner.exponents.input.participationMode.optionVirtual' })}</button>
          <button onClick={() => setParticipationMode(EVENT_FORMAT.HYBRID)} className={participationMode === EVENT_FORMAT.HYBRID && styles.btnActive}><IconMapPinWifi size={14} /> {t({ id: 'event.owner.exponents.input.participationMode.optionHybrid' })}</button>
        </div>
        {participationModeError && <p className={styles.errorMessage}>{t({ id: 'event.owner.exponents.participationMode.errorMessage' })}</p>}
      </div>

      <Button
        className="w-full mt-20"
        onClick={toggleConfirmationModal}
      >
        {t({ id: 'event.owner.participationModeModal.button'})}
      </Button>
    </Modal>
  );
}

ParticipationModeModal.propTypes = {
  isOpen: bool,
  onOk: func,
  onCancel: func,
  patchExponent: func,
  exponents: object,
  event: object,
  exponentId: string,
};

const mapStateToProps = createStructuredSelector({
  organization: organizationSelectors.getCurrentOrganization,
  authUser: authSelectors.getAuthUser,
  event: eventSelectors.getCurrentEvent,
  exponents: exponentSelectors.getExponents,
});

const mapDispatchToProps = {
  setCurrentOrganization: organizationActions.setCurrentOrganization,
  pushOrganization: organizationActions.pushOrganization,
  postOrganization: organizationActions.postOrganization,
  postExponent: exponentActions.postExponent,
  patchExponent: exponentActions.patchExponent,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(toJS(ParticipationModeModal));
