import React, { useMemo } from 'react';
import { Row, Col, Button } from 'antd';
import { arrayOf, number, object, func } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import moment from 'moment';

import globalStyles from '../../../less/common.less';

import iconChevronLeft from '../../../assets/images/IconChevronLeft.svg';
import iconChevronRight from '../../../assets/images/IconChevronRight.svg';

function DateSelector({ weeks, indexWeek, handleWeekChange }) {
  /* Vars */

  const currentWeek = weeks[indexWeek];
  const t = useFormatMessage();
  const isPrevButtonDisabled = indexWeek === 0;
  const isNextButtonDisabled = Boolean(weeks[indexWeek + 1]) === false;

  /* Memos */

  /**
   * Return a current week date formated
   */
  const getDateText = useMemo(() => {
    const beginAt = moment(currentWeek?.beginAt);
    const endAt = moment(currentWeek?.endAt);

    if (beginAt?.month() === endAt?.month()) {
      return `${beginAt.format('DD')} - ${endAt.format('DD MMMM YYYY')}`;
    }

    return `${beginAt?.format('DD MMM')} - ${endAt?.format('DD MMM')}`;
  }, [currentWeek]);

  /* Functions */

  /**
   * When call decrement a indexWeek
   */
  const handlePrevButton = () => {
    handleWeekChange(indexWeek - 1);
  };

  /**
   * When call increment a indexWeek
   */
  const handleNextButton = () => {
    handleWeekChange(indexWeek + 1);
  };

  /* Render */

  return (
    <Row type="flex" justify="center" align="middle" gutter={16}>
      <Col>
        <a
          role="button"
          tabIndex={0}
          onClick={() => handleWeekChange(0)}
        >
          {t({ id: 'event.recruiter.preparation.timeslots.event.firstDay' })}
        </a>
      </Col>

      <Col>
        <Button disabled={isPrevButtonDisabled} onClick={handlePrevButton}>
          <img src={iconChevronLeft} alt="" />
        </Button>
      </Col>

      <Col>
        <span className={globalStyles.span}>{getDateText}</span>
      </Col>

      <Col>
        <Button disabled={isNextButtonDisabled} onClick={handleNextButton}>
          <img src={iconChevronRight} alt="" />
        </Button>
      </Col>
    </Row>
  );
}

DateSelector.propTypes = {
  weeks: arrayOf(object),
  indexWeek: number,
  handleWeekChange: func,
};

export default DateSelector;
