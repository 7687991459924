import React from 'react';
import { func, bool } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { authActions, authSelectors } from '@/store/auth';
import { Banner } from '@seekube-tech/ui-kit';

import styles from './styles.less';
import { useFormatMessage } from 'react-intl-hooks';

const AlertIe = ({ hasClosedIeAlert, closeIeAlert }) => {
  const showIeAlert = !hasClosedIeAlert && Modernizr.flexboxlegacy === false; // eslint-disable-line
  const t = useFormatMessage();

  return showIeAlert && (
    <Banner
      className={styles.ieAlert}
      color="warning"
      onClose={closeIeAlert}
    >
      <div>
        {t({ id: 'header.browserAlert' })}
        <a href="https://www.google.com/chrome/" className="ml-5">
          {t({ id: 'header.browserAlertDownload' })}
        </a>
      </div>
    </Banner>
  );
};

AlertIe.prototype = {
  hasClosedIeAlert: bool,
  closeIeAlert: func,
};

const mapStateToProps = createStructuredSelector({
  hasClosedIeAlert: authSelectors.getHasClosedIeAlert,
});

const mapDispatchToProps = {
  closeIeAlert: authActions.closeIeAlert,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);


export default compose(withConnect)(AlertIe);
