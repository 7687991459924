import React from 'react';
import { object, oneOfType, string, array } from 'prop-types';
import Table from '@/components/Table';
import { getColumnSearchProps } from '../BasicRanking';

const RankingOfferVisit = ({ dataSource, messageTitle = { id: ' ' } }) => {
  const useColumnSearchOnOffers = getColumnSearchProps('offer');
  const useColumnSearchOnCompanies = getColumnSearchProps('name');
  return (
    <div>
      <h2>{messageTitle}</h2>
      <Table
        dataSource={dataSource}
        columns={[{
          title: 'N.',
          dataIndex: 'index',
          key: 'index',
          width: '10%',
        }, {
          title: 'Offre',
          dataIndex: 'offer',
          key: 'offer',
          width: '40%',
          ...useColumnSearchOnOffers,
        }, {
          title: 'Entreprise',
          dataIndex: 'name',
          key: 'name',
          width: '30%',
          ...useColumnSearchOnCompanies,
        }, {
          title: 'Nombre de candidatures',
          dataIndex: 'count',
          key: 'count',
          align: 'center',
          width: '20%',
        }]}
        size={'middle'}
        pagination={false}
        scroll={{ y: 400 }}
      />
    </div>
  );
};

RankingOfferVisit.propTypes = {
  dataSource: array,
  messageTitle: oneOfType([string, object]),
};

export { RankingOfferVisit };
