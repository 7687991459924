import { FormattedMessage } from 'react-intl';
import React from 'react';
import { find } from 'lodash';
import { object } from 'prop-types';

import messages from '../../messages';

const PositionsSought = ({ _event, matching }) => {
  const filters = matching.filters ? matching.filters : [];
  const positionsStr = find(_event._criteria, ['alias', 'position']);
  const positionsChoices = positionsStr ? positionsStr._choices
    .filter((choice) => choice.enable && filters.includes(choice._id.toString()))
    .map((position) => position.label).join(', ') : null;
  return (
    positionsChoices ? <span className="grey">
      <FormattedMessage {...messages.interestedBy} values={{ positions: <span className="secondaryColor">{positionsChoices} </span> }} />
    </span> : '');
};

PositionsSought.propTypes = {
  _event: object,
  matching: object,
};

export { PositionsSought };
