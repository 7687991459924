import React, { useEffect, useMemo, useState } from 'react';
import { array, bool, number, object, oneOf, string } from 'prop-types';
import { Link } from 'react-router-dom';
import { useFormatMessage } from 'react-intl-hooks';
import isEqual from 'lodash/isEqual';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
import classnames from 'classnames';
import { createStructuredSelector } from 'reselect';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';
import { Avatar } from '@seekube-tech/ui';

import { Button } from '@seekube-tech/ui-kit';
import { currentLocale } from '@/containers/LanguageProvider/selectors';

import { ANALYTICS_CANDIDATE, EVENT_FORMAT, LOCALES } from '@/utils/constants';
import { track } from '@/utils/analytics';

// Components
import Html from '@/components/Html';
import Avatars from '@/components/Avatars';
import { CornersTag } from '@/components/Corners';

import styles from './styles.less';
import messages from './messages';
import { ParticipationsIcons } from '@/components/ParticipationsIcons';
import { getCurrentParticipant } from '@/store/participant/selectors';
import { arrayContainAll } from '@/utils/math';
import { If } from "@/components/If";

function Identity({ organization, link, preview, sectors, countOffers, offers, event, currentLocale, exponent, context }) {
  const participant = useSelector(getCurrentParticipant)?.toJS();
  /* Vars */

  const profileStand = exponent.profile ? exponent.profile : organization?.profile;
  const corners = (exponent && exponent.cornersPopulate) || [];
  const isMobile = window.innerWidth <= 650;
  const locale = event.landingIntl ? currentLocale : event.language || 'fr';
  const [recruitersPicture, setRecruitersPicture] = useState([]);
  const [pictureUrl, setPictureUrl] = useState(null);
  const t = useFormatMessage();
  const standPresentation = organization?.profile?.presentation?.content ?? '';
  /* Effects */

  useEffect(() => {
    if (profileStand) {
      setPictureUrl(
        isUndefined(profileStand.logo) ?
          profileStand.pictureUrl :
          profileStand.logo
      );
    }
  }, [organization]);

  useEffect(() => {
    offers
    .forEach((offer) => {
      if (offer._organization._id === organization._id) {
        let url = null;
        const newRecruitersPicture = [];

        if (offers._user && offer._user.pictureUrl && offer._user.pictureUrl !== 'undefined') {
          url = offer._user.pictureUrl;
        }

        if (url !== null && !newRecruitersPicture.includes(url)) {
          newRecruitersPicture.push(url);
        }

        setRecruitersPicture(newRecruitersPicture);
      }
    });
  }, [offers]);

  /* Memos */

  // eslint-disable-next-line arrow-body-style
  const renderSectors = useMemo(() => {
    return sectors.filter((item) => !isUndefined(item)).map((sector) => sector.key ? t({ id: `sector[${sector.key}]` }) : sector.name).join(' - ');
  }, [sectors]);

  /* Functions */

  /**
   * Send track when user click to exponent button
   */
  const onExponentBtnClick = () => {
    window.open(link, !isMobile ? '_blank' : null)
    track({
      name: ANALYTICS_CANDIDATE.VIEW_COMPANY_CLICK,
      event,
      properties: {
        company: organization.name,
        'Count Opportunities': countOffers,
        'Current url TRUE': window.location.href,
      },
    });
  };

  /* Renders */

  const name = link !== null ?
    (<Link to={link} target={!isMobile ? '_blank' : null}>
      <h3 className={organization.name.length >= 50 ? styles.firstStyle : styles.secondStyle}>
        {organization.name}
      </h3>
    </Link>) : (<h3 className={organization.name.length >= 50 ? styles.firstStyle : styles.secondStyle}>{organization.name}</h3>);

  const matchingLabel = (
    <span className={styles.countOffers}>
      {countOffers === 0 ? t(messages.knowMore) : t(messages.matchOpportunities, { count: countOffers })}
    </span>
  );

  const exponentBtn = (
      <Button onClick={onExponentBtnClick}>
        {countOffers > 0 ?
          t(messages.showOpportunities, { count: 2 })
          : t(messages.showStand)}
      </Button>
  );

  return (
    <div className={classnames(styles.identityContainer, preview ? styles.identityContainerPreview : '')}>
      <div className={styles.companyIdentity}>
        <div className={styles.picture}>
          <Avatar
            variant="business"
            src={pictureUrl}
            color={exponent?.isPartner ? event?.modules?.partner?.color : 'default'}
            badge={exponent?.isPartner ? event?.modules?.partner?.icon : null}
            tooltip={event?.modules?.partner?.[locale === 'fr' ? 'label_fr' : 'label_en']}
            size="large"
          />
        </div>

        <div className={styles.companyInfos}>
          <div className={styles.companyName}>{name}
            <ParticipationsIcons
              formats={exponent?.keyMomentFormats}
              className={styles.participationsIcons}
              name={organization.name}
              visible={context === 'owner' || arrayContainAll(participant?.keyMomentFormats, [EVENT_FORMAT.PHYSICAL, EVENT_FORMAT.VIRTUAL])}

            />
          </div>

          <div className={styles.data}>
            <If
              condition={!isEmpty(corners)}
              otherwise={event.type !== 'jpo' ? renderSectors : null}
            >
              <CornersTag corners={corners} locale={locale} />
            </If>
          </div>
        </div>
      </div>

      {!isEmpty(standPresentation) && (
        <div className={styles.description}>
            <Html
              value={
                `${standPresentation.substr(0, 160)}${!isEqual(standPresentation.substr(0, 150), standPresentation) ? '...' : ''}
              `}
            />
        </div>
      )}

      <div className={styles.showExponent}>
        <div>{exponentBtn}{matchingLabel}</div>

        <div className={styles.recruitersIcon}>
          <Avatars
            pictures={recruitersPicture}
            others={recruitersPicture.length - 3}
            maxPictures={3}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  currentLocale,
});

const mapDispatchToProps = {};

Identity.propTypes = {
  organization: object,
  link: string,
  preview: bool,
  sectors: array,
  countOffers: number,
  offers: array,
  event: object,
  currentLocale: string,
  exponent: object,
  context: oneOf(['owner', undefined]),
};

Identity.defaultProps = {
  preview: false,
  sectors: [],
  countOffers: 0,
  offers: [],
  event: { modules: { corner: { items: [] } } },
  currentLocale: LOCALES.fr,
  exponent: {},
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(Identity);
