import React from 'react';
import { string, object, array } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { Form, Select as SelectAntd } from 'antd';
import { Body2 } from '@seekube-tech/ui-kit';
import Avatar from '@/components/Avatar';
import Select from '../../../../../../../../components/Form/Select';
import styles from '@/scenes/Event/scenes/Owner/scenes/Dashboard/components/AddActivity/styles.less';
const FormItem = Form.Item;
const { Option } = SelectAntd;

export const Author = ({ form, initialValue, exponentUsers }) => {
  const t = useFormatMessage();

  return (
    <FormItem className="w-5/12">
      {form.getFieldDecorator('_author', {
        initialValue
      })(
        <Select
          addOnIcon ="user"
          placeholder="User"
          label={t({ id: 'author' })}
          required
          onChange={() => {}}
        >
          {exponentUsers
            .filter((expUser) => expUser.isActive)
            .sort((a, b) => a.lastName?.localeCompare(b.lastName, 'en', { sensitivity: 'base' }))
            .map((expUser, key) => (
              <Option value={expUser._id} key={key}>
                <div className={styles.option}>
                  <Avatar size={28} src={expUser.pictureUrl} user={expUser}/>
                  <Body2 className={styles.optionText}>{expUser.fullName}</Body2>
                </div>
              </Option>
            ))}
        </Select>
      )}
    </FormItem>
  )
}

Author.propTypes = {
  form: object,
  initialValue: string,
  exponentUsers: array,
}
