import React from 'react';
import { object, any, string, func } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { Dropdown, Menu } from 'antd';
import {
  IconEdit2,
  IconMail,
  IconMoreVertical,
  IconSettings2,
  IconTrash2,
} from '@seekube-tech/ui-kit';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import Icon from '@/components/Icon';
import messages
  from '@/scenes/Event/scenes/Owner/scenes/OnBoarding/containers/Dashboard/messages';
import styles
  from '@/scenes/Event/scenes/Owner/scenes/OnBoarding/containers/Dashboard/components/Recruiters/styles.less';
import { useUserAclHandler } from '@/store/role/helpers';
import { ACL } from '@/utils/constants';
import { getAuthUser } from '@/store/auth/selectors';
const { Item: MenuItem, SubMenu } = Menu;

const CONTEXT_URL = { ownerExponents: 'owner/exponents', ownerSettings: 'owner/settings', adminOrganizations: 'admin/organizations', memberByEvent: 'member/by-event' };


const ActionMenuItem = ({ text = {}, icon, className, onClick }) => (
  <a role="button" tabIndex={0} onClick={onClick} className={className}>
    {icon}
    {text}
  </a>
);

ActionMenuItem.propTypes = {
  text: object,
  icon: any,
  className: string,
  onClick: func,
}

const MoreActions = ({ _user, onActionClick, exponent, organization }) => {
  const t = useFormatMessage();
  const { pathname } = useLocation();
  const authUser = useSelector(getAuthUser)?.toJS();
  const isOwnerExponentsContext = pathname.includes(CONTEXT_URL.ownerExponents);
  const isOwnerSettingsContext = pathname.includes(CONTEXT_URL.ownerSettings);
  const isAdminOrganizationsContext = pathname.includes(CONTEXT_URL.adminOrganizations);
  const organizationUser = getOrganization();

  const { isClient } = organizationUser;
  const userAclHandler = useUserAclHandler(_user, organizationUser);
  const authUserAclHandler = useUserAclHandler(authUser);
  const hasAuthUserPriority = authUserAclHandler.role.priority >= userAclHandler.role.priority
  let actionsItems;

  function getOrganization() {
    if (isOwnerExponentsContext) {
      return exponent._organization;
    }
    if (isOwnerSettingsContext) {
      return organization;
    }
    if (isAdminOrganizationsContext) {
      return organization;
    }
    return authUser._currentOrganization;
  }

  /**
   * This is for our test env, if user has not been transfered
   */
  if (!_user) {
    return null;
  }

  function getContext() {
    if (isOwnerExponentsContext) {
      return CONTEXT_URL.ownerExponents;
    }
    if (isOwnerSettingsContext) {
      return CONTEXT_URL.ownerSettings;
    }
    if (isAdminOrganizationsContext) {
      return CONTEXT_URL.adminOrganizations;
    }

    return CONTEXT_URL.memberByEvent

  }

  const getDropdownItems = (menuItem) => {
    switch (menuItem) {
      case ACL.EDIT_USER:
        return _user.isActive &&
          <ActionMenuItem text={t({ id: 'recruiter.actions.modifyProfile' })} icon={<IconEdit2 size={16} />} onClick={() => onActionClick(ACL.EDIT_USER)} />;
      case ACL.EDIT_ROLE:
        return isClient && _user.isActive &&
          <ActionMenuItem text={t({ id: 'recruiter.actions.editRole' })} icon={<IconSettings2 size={16} />} onClick={() => onActionClick(ACL.EDIT_ROLE)} />;
      case ACL.INVITE_USER:
        return !_user.isActive &&
          <ActionMenuItem text={t({ id: 'recruiter.resendInvitationEmail' })} icon={<IconMail size={16} />} onClick={() => onActionClick('inviteCollaborator')} />;
      case ACL.REMOVE_USER_FROM_EXPONENT:
        return <ActionMenuItem text={t({ id: 'event.owner.candidate.tooltip.removeFromJd' })} icon={<IconTrash2 size={16} />} onClick={() => onActionClick(ACL.REMOVE_USER_FROM_EXPONENT)} className="error" />;
      case 'readProfile':
        return (
          <SubMenu key="sub3" title={<><Icon style={{ width: '24px', height: '24px' }}
                                             name="mail"/><FormattedMessage {...messages.infoRecruiter} /></>}>
            <Menu.Item key="7"><a role="button" tabIndex={0} onClick={(e) => e.stopPropagation()}>
              <Icon name="mail"/><span>{_user.username}</span></a>
            </Menu.Item>
            {_user.phone && <Menu.Item key="8">
              <a role="button" tabIndex={0} onClick={(e) => e.stopPropagation()}>
                <Icon name="phone"/><span>{_user.phone}</span></a>
            </Menu.Item>}
          </SubMenu>)
      default:
        return null;
    }
  }

  const context = getContext();

  switch (context) {
    case CONTEXT_URL.ownerExponents:
      actionsItems = [
        ACL.INVITE_USER,
        authUser.isAdmin && ACL.EDIT_USER,
        'readProfile',
        ACL.REMOVE_USER_FROM_EXPONENT];
      break;

    case CONTEXT_URL.ownerSettings:
      actionsItems = [
        ACL.INVITE_USER,
        authUserAclHandler.checkAcl(ACL.REMOVE_USER_FROM_EXPONENT) && _user.isActive && ACL.EDIT_USER,
        authUserAclHandler.checkAcl(ACL.EDIT_ROLE) && ACL.EDIT_ROLE,
        'readProfile',
         ACL.REMOVE_USER_FROM_EXPONENT];
      break;

    case CONTEXT_URL.adminOrganizations:
      actionsItems = [
        ACL.INVITE_USER,
        ACL.EDIT_USER,
        ACL.EDIT_ROLE,
        'readProfile',
        ACL.REMOVE_USER_FROM_EXPONENT,
      ];
      break;

    default:
      actionsItems = [
        ACL.INVITE_USER,
        (authUserAclHandler.checkAcl(ACL.EDIT_USER) || authUser._id === _user._id) && hasAuthUserPriority && ACL.EDIT_USER,
        (authUserAclHandler.checkAcl(ACL.EDIT_ROLE || authUser._id === _user._id)) && hasAuthUserPriority && ACL.EDIT_ROLE,
        'readProfile',
        (authUserAclHandler.checkAcl(ACL.REMOVE_USER_FROM_EXPONENT) || authUser._id === _user._id) &&  ACL.REMOVE_USER_FROM_EXPONENT];
  }

  return (
    <Dropdown
      overlay={
        <Menu>
          {actionsItems.map((a, i) => {
            const dropdownItem = getDropdownItems(a);
            if (a === 'readProfile') return (dropdownItem);

            return dropdownItem && <MenuItem key={i}>{dropdownItem}</MenuItem>;
          })}
        </Menu>
      }
      placement="bottomRight"
    >
      <a role="button" tabIndex={0} className={styles.moreActions}>
        <IconMoreVertical className="fill-neutral-300" />
      </a>
    </Dropdown>
  );
}

MoreActions.propTypes = {
  exponent: object,
  organization: object,
  role: string,
  _user: object,
  onActionClick: func
}

export { MoreActions }
