import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Map, List } from 'immutable';
import { FormattedMessage, injectIntl } from 'react-intl';
import { NavLink, withRouter } from 'react-router-dom';
import classnames from 'classnames';

// Store
import { eventActions, eventSelectors } from '@/store/event';
import { criterionSelectors, criterionActions } from '@/store/criterion';
import { jobActions, jobSelectors } from '@/store/job';

// Components
import { H1 } from '@/components/Title';
import CriteriaTableView from '../../components/CriteriaTableView';

// Styles & Translations
import parentStyles from '../../../../styles.less';
import styles from '../../styles.less';
import messages from '../../messages';
import { Button } from '@seekube-tech/ui-kit';

/**
 * MatchingCriteria
 */
class MatchingCriteriaScene extends React.PureComponent {
  static propTypes = {
    event: PropTypes.object,
    eventCriteria: PropTypes.object,
    jobs: PropTypes.array,
    getJobs: PropTypes.func,
    patchEvent: PropTypes.func,
    getDefaultCriteria: PropTypes.func,
    deleteCriterion: PropTypes.func,
    intl: PropTypes.object
  };

  static defaultProps = {
    event: new Map(),
    eventCriteria: new List(),
  };

  /**
   * Constructor
   *
   * @param {object} props
   */
  constructor(props) {
    super(props);

    // Get the default criteria
    props.getDefaultCriteria();
    props.getJobs({
      page: 1,
      offset: 0,
      limit: 500,
    });
  }

  /**
   * Handle criteraTable submit
   *
   * @param {Object} criteria
   * @param {func} callback
   */
  handleOnSubmit = (criteria, callback) => {
    const { props: { event, patchEvent, intl } } = this;

    patchEvent({
      eventId: event.get('slug'),
      event: { _criteria: criteria.toJS() },
      notificationParams: {
        success: {
          message: intl.formatMessage({ id: 'notifications.update.success' }),
          kind: 'info',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        },
      },
      callback,
    });
  };

  /**
   * Handle click for delete a criterion
   *
   * @param {string} criterionId
   */
  handleOnDeleteCriterion = (criterionId) => {
    const { props: { event, deleteCriterion } } = this;

    deleteCriterion({
      eventId: event.get('_id'),
      criterionId,
    });
  };

  /**
   * Render
   */
  render() {
    const {
      handleOnSubmit, handleOnDeleteCriterion,
      props: { eventCriteria, jobs, event, intl },
    } = this;

    return (
      <div className={parentStyles.formWrapperSmallContainer}>
        <H1 className={styles.title}><FormattedMessage {...messages.matchingSearchEngineOfferTitle} /></H1>
        <div className={styles.helper}>
          <span role="img" aria-labelledby="info">ℹ️</span> <FormattedMessage {...messages.matchingSearchEngineCandidateHelp} />
        </div>
        <div className={styles.helper}>
          <span role="img" aria-labelledby="warning">⚠️️</span>&nbsp;
          <FormattedMessage {...messages.matchingHelp} />&nbsp;
          <NavLink to={`/${event.toJS().slug}/owner/settings/matching/dashboard`} activeClassName="activeNavLink">
            <FormattedMessage {...messages.matchingDashboard} />
          </NavLink>
        </div>
        <CriteriaTableView
          jobs={jobs}
          criteria={eventCriteria}
          onSubmit={handleOnSubmit}
          onDeleteCriterion={handleOnDeleteCriterion}
          context="searchEngineParticipant"
          disableColumns={['min', 'max', 'position']}
        />
        <Button
          className={styles.defaultMarginLeft}
          onClick={(() => window.open(`/${event.toJS().slug}/owner/settings/matching/preview/search-participant`, '_blank'))}
        >
          {intl.formatMessage({ id: 'preview.result' })}
        </Button>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  event: eventSelectors.getCurrentEvent,
  jobs: jobSelectors.getJobs,
  defaultCriteria: criterionSelectors.getDefaultCriteria,
  eventCriteria: eventSelectors.getCurrentEventCriteria,
});

const mapDispatchToProps = {
  getDefaultCriteria: criterionActions.getDefaultCriteria,
  getJobs: jobActions.getJobs,
  patchEvent: eventActions.patchEvent,
  deleteCriterion: criterionActions.deleteCriterion,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  withRouter,
)(injectIntl(MatchingCriteriaScene));
