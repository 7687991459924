import React from 'react';
import { AvatarCandidate, Body1, Caption1, IconCalendar, Subtitle2 } from '@seekube-tech/ui-kit';
import { getCdnUrl } from '@/components/ImgCdn';
import { object } from 'prop-types';

//Styles
import styles from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Exponents/scenes/Exponent/components/Home/Sidebar/Cards/styles.less';
import { useFormatMessage } from 'react-intl-hooks';

const CardInformal1to1 = ({
  informal1to1
}) => {
  const t = useFormatMessage();
  const hasAvailableSlots = !!informal1to1.countTimeslots;

  return <div className={styles.cardInformal1to1}>
    <div>
      <AvatarCandidate
        className={styles.avatar}
        user={informal1to1._user}
        pictureUrl={getCdnUrl(informal1to1._user.pictureUrl, 80) ?? ''}
        size={42}/>
    </div>
    <div className={styles.infos}>
      <Subtitle2 className="text-neutral-500" fontWeight={800}>{informal1to1._user.fullName}</Subtitle2>
      <Caption1 className="text-neutral-400">{informal1to1._user.title}</Caption1>
      <div className={`${styles.slots} ${hasAvailableSlots ? styles.available : styles.unavailable}`}>
        <IconCalendar size={20} />
        <Body1 className="text-warning-400">
          {t({ id: 'count.free.slot.pl' }, { count: informal1to1.countTimeslots })}
        </Body1>
      </div>
    </div>
  </div>
}

CardInformal1to1.propTypes = {
  informal1to1: object,
}

export default CardInformal1to1;
