import React, { useState } from 'react';
import { Button, H5 } from '@seekube-tech/ui-kit';
import { FormattedMessage } from 'react-intl';
import { func, bool } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import ModalV2 from '@/components/ModalV2';

const RemoveDealBtn = ({ onRemove, isLoading }) => {
  const t = useFormatMessage();
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Button
        variant="outline"
        color="error"
        onClick={() => setVisible(true)}
        className="w-full mr-14" >
        <FormattedMessage id="form.deal.btn.remove" />
      </Button>

      <ModalV2
        template="default"
        visible={visible}
        onCancel={() => setVisible(false)}
        onClose={() => setVisible(false)}
      >
        <H5 style={{ marginBottom: '20px' }}>{t({ id: 'form.deal.remove.confirm' })}</H5>
        <div className="confirm-actions mb-2" style={{ marginTop: '20px' }}>

          <Button
            variant="tonal"
            type="submit"
            onClick={() => setVisible(false)}
          >
            {t({ id: 'cancel' })}
          </Button>
          <Button
            type="submit"
            loading={isLoading}
            disabled={isLoading}
            onClick={onRemove}
          >
            {t({ id: 'btn.confirm' })}
          </Button>
        </div>
      </ModalV2>
    </>
  )
}

RemoveDealBtn.propTypes = {
  onRemove: func,
  isLoading: bool,
}

export { RemoveDealBtn }