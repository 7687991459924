import React, { useEffect, useState } from 'react';
import { func, object, array } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Grid, Typography } from '@seekube-tech/ui';
import { useFormatMessage } from 'react-intl-hooks';
import { injectIntl } from 'react-intl';
import { Pagination, H5 } from '@seekube-tech/ui-kit';
import { replace } from 'connected-react-router';

// Stores
import { authSelectors } from '@/store/auth';
import { interactionSelectors, interactionActions } from '@/store/interaction';
import { participantActions, participantSelectors } from '@/store/participant';
import { eventSelectors } from '@/store/event';
import { appointmentActions } from '@/store/appointment';

import { useGetExponentsUsers } from '@/queries/exponent/useGetExponentsUsers';
import { withLayout } from '@/containers/Layout';
import toJS from '@/utils/toJS';

import { queryStringToObject } from '@/utils/url';
import MobileMenu from '@/containers/Layout/containers/MobileMenu';
import SearchEngine from '../../components/SearchEngine';

import DiscoverOffersCard from './components/DiscoverOffersCard';
import Informal1to1Card from './components/Informal1to1Card';

import useStyles from './styles';
import MobileHeader from '@/containers/Layout/containers/MobileHeader';
import { LOCALES } from '@/utils/constants';

const Informal1to1 = ({
  authUser,
  createInformal1To1,
  interactions,
  getInteractions,
  match,
  event,
  participant,
  intl,
  cancelAppointment,
  goTo,
  location,
}) => {

  const [pagination, setPagination] = useState({ page: 1, limit: event?.modules?.offer?.enable ? 11 : 12 });
  let initialOrganizationFilter = null;
  if (queryStringToObject(location.search).organizations) {
    initialOrganizationFilter = typeof queryStringToObject(location.search).organizations === 'string' ? queryStringToObject(location.search).organizations : queryStringToObject(location.search).organizations[0]
  }
  const [search, setSearch] = useState(initialOrganizationFilter ? { organizations: [initialOrganizationFilter] } : {});

  const { isFetched, data, isSuccess } = useGetExponentsUsers({
    eventId: match.params.eventSlug,
    search,
    page: pagination.page,
    limit: pagination.limit,
  });

  const styles = useStyles();
  const t = useFormatMessage();

  const { slug, _id } = event;

  /* Effects */

  useEffect(() => {
    getInteractions({
      eventSlug: slug,
      body: {
        users: [authUser._id],
      },
    });
  }, []);

  /* Functions */

  const handleSearch = (filters) => {
    setSearch({
      keywords: filters.keywords,
      organizations: filters.organizations,
    });
  };

  const handleOnChangePagination = (page) => {
    window.scrollTo(0, 0);

    setPagination({
      ...pagination,
      page,
    });
  };

  const handleUpdateAppointment = (appointmentId) => {
    goTo(`/${event.slug}/candidate/jobdating/appointment/${appointmentId}?edit=true`);
  };

  /* Render */

  return (
    <div>
      <div className={styles.container}>
        <H5>
          {event?.modules?.informal1to1?.[authUser?.locale === LOCALES.fr ? 'labelMultiple_fr' : 'labelMultiple_en']}
        </H5>
  
        <div className={styles.filters}>
          <SearchEngine
            event={event}
            onSearch={handleSearch}
            context="informal1to1"
            intl={intl}
            countConferences={data?.total}
            facetsInformal={data?.facets}
          />
        </div>
  
        <Typography
          variant="body2"
          color="grey[500]"
          align="center"
          className={styles.countResult}
        >
          {t({ id: 'candidate.jobdating.informal1to1.countResult' }, { size: data?.total, allSize: data?.totalAll })}
        </Typography>
  
        {isFetched && isSuccess && (
          <Grid container spacing={3}>
            {data?.docs?.map((exponentUser) => (
              <Grid item xs={12} md={4}>
                <Informal1to1Card
                  exponentUser={exponentUser}
                  event={event}
                  createInformal1To1={createInformal1To1}
                  cancelAppointment={cancelAppointment}
                  updateAppointment={handleUpdateAppointment}
                  participant={participant}
                  interactions={interactions}
                />
              </Grid>
            ))}
  
            {event?.modules?.offer?.enable && (
              <Grid item xs={12} md={4}>
                <DiscoverOffersCard />
              </Grid>
            )}
          </Grid>
        )}
  
        <div className={styles.pagination}>
          <Pagination
            className="flex justify-center"
            current={(data?.page + 1)}
            pageSize={data?.limit}
            total={data?.total || 0}
            onChange={handleOnChangePagination}
          />
        </div>
      </div>
      <MobileHeader event={event} />
      <MobileMenu
        displayInformal={event.modules?.informal1to1?.enable}
        displayLive={event.modules.live && event.modules.live.participantEnable}
        liveLabel={event.modules.live && event.modules.live.labelMultiple}
      />
    </div>
  );
}

Informal1to1.propTypes = {
  interactions: array,
  getInteractions: func,
  event: object,
  authUser: object,
  participant: object,
  createInformal1To1: func,
  match: object,
  intl: object,
  cancelAppointment: func,
  goTo: func,
};

const mapStateToProps = createStructuredSelector({
  participant: participantSelectors.getCurrentParticipant,
  interactions: interactionSelectors.getInteractions,
  authUser: authSelectors.getAuthUser,
  event: eventSelectors.getCurrentEvent,
});

const mapDispatchToProps = {
  createInformal1To1: participantActions.createInformal1To1,
  cancelAppointment: appointmentActions.cancelAppointment,
  getInteractions: interactionActions.getInteractions,
  goTo: replace,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withLayout({ size: 'default', withHeader: false, navType: 'candidate' }),
  withConnect,
  withRouter,
  toJS,
)(injectIntl(Informal1to1));
