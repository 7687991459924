import React from 'react';
import { Subtitle2, Body1, H4, IconInfo, Popover, PopoverTrigger, PopoverContent, Button } from '@seekube-tech/ui-kit';
import { string, number, object } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useFormatMessage } from 'react-intl-hooks';
import styles from './styles.less';
import interviewHelpPicture from '@/assets/images/interviewHelpPicture.svg'
import informalHelpPicture from '@/assets/images/informalHelpPicture.svg'
import liveHelpPicture from '@/assets/images/liveHelpPicture.svg'

export const MyStat = ({ title, popover = {}, count = 0, module }) => {
  const history = useHistory();
  const t = useFormatMessage();

  const getPicture = () => {
    switch (module) {
      case 'interview':
        return <img src={interviewHelpPicture} alt='interview' />
      case 'informal':
        return <img src={informalHelpPicture} alt='informal' />
      case 'live':
        return <img src={liveHelpPicture} alt='live' />
      default:
        return null
    }
  }

  return (
    <Popover placement="top" open={popover.title ? undefined : false}>
      <PopoverTrigger>
        <div className={styles.stat}>
          <div className="uppercase text-neutral-300 flex items-center">
            <Body1 className="text-sm mr-14">
              {title}
            </Body1>
            {popover.title && <IconInfo size={16} />}
          </div>

          <div>
            <H4>{count}</H4>
          </div>
        </div>
      </PopoverTrigger>

      <PopoverContent>
        <div className='flex'>
          <div className="text-neutral-500 mx-20 my-20">
            <Subtitle2 fontWeight={700}>{popover.title}</Subtitle2>
            <Body1 className="text-neutral-400 mt-4 mb-12">{popover.description}</Body1>
            <Button variant="outline" onClick={() => history.push(popover.cta)}>{t({ id: 'discover'})} {popover.title}</Button>
          </div>
          {getPicture()}
        </div>
      </PopoverContent>
    </Popover>);

}


MyStat.propTypes = {
  title: string,
  count: number,
  popover: object,
}