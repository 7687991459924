import React from 'react';
import { H5 } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import styles from './styles.less';
import { useGetDashboardTodo } from '@/queries/participants/useGetDashboardTodo';
import { Todo } from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Dashboard/components/Todos/Todo';

export const Todos = () => {
  const t = useFormatMessage();
  const { data: stats } = useGetDashboardTodo();

  return (
    <div className={styles.todos}>
      <div className={styles.title}><H5> {t({ id: 'todo' })} </H5></div>
      {stats?.map((todo, i) => (<Todo key={i} todo={todo} />))}
    </div>
  )
};
