import React, { useEffect, useState } from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { Modal } from 'antd';
import notification from '@/components/Notification';

import styles from '../../../ExponentModal/styles.less';
import { changeReferent } from '../ActionColumnV2/request';
import { Body1, Button, H4 } from '@seekube-tech/ui-kit';
import { func, object } from 'prop-types';



function DefineReferentModal({
  referent,
  onCancel,
}) {
  const t = useFormatMessage();

  const handleOnOk = () => {
    changeReferent({exponentId: referent.exponent._id, body: {referentUserId: referent.referent._user._id}, eventId: referent.exponent._event._id})
      .then(() => {
        onCancel()
      })
  }

  return (
    <Modal
      onOk={handleOnOk}
      onCancel={() => onCancel()}
      visible={true}
      width={720}
      footer={false}
      className={`customConfirm ${styles.defineReferentContainer}`}
      okText={t({ id: 'add' })}
      cancelText={t({ id: 'cancel' })}
    >
      <H4 className={styles.defineReferentTitle}>{t({id: 'defineReferent.title'}, {name: referent.referent._user.fullName})}</H4>
      <Body1 className={styles.defineReferentDescription}>{t({id: 'defineReferent.description'})}</Body1>
      <div className={styles.btnContainer}>
        <Button onClick={onCancel} variant='outline' color='neutral'>{t({id: 'cancel'})}</Button>
        <Button onClick={handleOnOk}>{t({id: 'submit'})}</Button>
      </div>
    </Modal>
  );
}

DefineReferentModal.propTypes = {
  referent: object,
  onCancel: func,
};

export default DefineReferentModal;
