import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { object, func, array, bool } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { isEmpty, reject, get, partition } from 'lodash';
import { replace } from 'connected-react-router';
import moment from 'moment';
import classnames from 'classnames';
import { Menu, Modal } from 'antd';
import { Alert, Button, IconAlertTriangle } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { toJS } from '@/utils';
import { PIPE_DRIVE, isOfferLimitExceeded } from '@/utils/pipeDrive';
import { queryStringToObject } from '@/utils/url';
import { track } from '@/utils/analytics';
import { ANALYTICS_RECRUITER } from '@/utils/constants';
import { offerActions, offerSelectors } from '@/store/offer';
import { eventActions, eventSelectors } from '@/store/event';
import { exponentSelectors } from '@/store/exponent';
import { authSelectors } from '@/store/auth';
import Separator from '@/components/Separator';
import BlankState from '@/components/BlankState';
import LoadingIndicator from '@/components/LoadingIndicator';
import ModalBig from '@/components/ModalBig';
import { OfferCardList } from '@/components/OfferCard';
import Icon from '@/components/Icon';
import MoreDropdown from '@/components/MoreDropdown';
import Popover from '@/components/Popover';
import { OfferCardParticipant } from '@/components/OfferCard';
import CreateOfferModal from './containers/CreateOfferModal';
import OfferSideContent from '@/scenes/Event/components/StaticOffers/components/OfferSideContent';
import OfferPreview from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Jobs/scenes/Job/components/OfferPreview';
import ImportCrossForumOffers from '@/scenes/Event/scenes/Recruiter/scenes/Preparation/scenes/Offer/containers/ImportCrossForumOffers';
import { Banner } from '@/scenes/Event/scenes/Recruiter/scenes/Preparation/scenes/Offer/components';
import styles from './styles.less';
import { If } from '@/components/If';
import { ShowMore } from '@/components/ShowMore';
import Html from '@/components/Html';
import { withLayout } from '@/containers/Layout';
import { getCurrentStand } from '@/forms/Stand/request';
import { useGetOrganizationsCurrentProfile } from '@/queries/organizations/useGetOrganizationsCurrentProfile';


const RecruiterPreparationOffersScene = ({
  match,
  isFetching,
  getOrganizationOffers,
  getCrossForumOffer,
  getAuthUserEvents,
  authUserEvents,
  duplicateCrossOffers,
  crossOffers,
  duplicateOffer,
  deleteOffer,
  offers,
  event,
  exponent,
  authUser,
  location,
  replace,
  patchOffer,
  history,
}) => {
  const t = useFormatMessage();
  const [createOfferModalIsOpen, setCreateOfferModalIsOpen] = useState(false);
  const [showOfferModalIsOpen, setShowOfferModalIsOpen] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [confirmModalIsVisible, setConfirmModalIsVisible] = useState(false);
  const [isCrossOfferVisible, setIsCrossOfferVisible] = useState(false);
  const [context, setContext] = useState();
  const [formValues, setFormValues] = useState();

  const { data: organizationProfile } = useGetOrganizationsCurrentProfile({
    orgaId: authUser._currentOrganization._id, eventId: event._id })

  useEffect(() => {
    getCrossForumOffer({ eventSlug: event.slug });
    getAuthUserEvents(moment(event.createdAt).subtract(6, 'month'));

    getOrganizationOffers({
      page: 1,
      offset: 0,
      eventSlug: match.params.eventSlug,
      loader: true,
      organizationId: authUser._currentOrganization._id,
    });
  }, [])


  const onHandleAction = (offer, action, context) => {
    if (action === 'edit') {
      openModal(offer);
    } else if (action === 'preview') {
      showOffer(offer, context);
    }
  };

  const onDeleteOfferClick = (event, offer) => {
    setConfirmModalIsVisible(true);
    setSelectedOffer(offer);
  }

  const getPastEvents = () => reject(authUserEvents, { _id: event._id });

  const setVisibleCrossOffers = (bool) => {
    setIsCrossOfferVisible(bool);
  };

  const trackAddExistingOfferBtn = (existing) => {
    track({
      name: ANALYTICS_RECRUITER.CLICKED_CREATED_OFFER,
      event,
      user: authUser,
      properties: {
        existing,
      },
    });
  };

  const showOffer = (offer, context, formValues) => {
    document.body.style.overflow = 'auto';

    setShowOfferModalIsOpen(true);
    setSelectedOffer(offer);
    setContext(context);
    setFormValues(formValues);
  };

  const closeOfferModal = () => {
    setShowOfferModalIsOpen(false);

    const urlParams = queryStringToObject(location.search);

    if (!isEmpty(urlParams) && urlParams.preview) {
      replace(match.url);
    }
  };

  const openModal = (offer) => {
    setCreateOfferModalIsOpen(true);
    setSelectedOffer(offer);
  };

  const closeModal = () => {
    setCreateOfferModalIsOpen(false);
    setSelectedOffer(null);
    setFormValues(null);
  };


  const onDuplicateOffer = (offer) => {
    duplicateOffer({
      offerId: offer._id,
      eventId: event._id,
      notificationParams: {
        success: {
          message: t({ id: 'event.recruiter.preparation.offer.notification.success.duplicate'}),
          kind: 'info',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        },
        error: true,
      },
    });
  };


  const draftOffer = (offer) => {
    const trackData = {
      user: authUser,
      event,
      name: ANALYTICS_RECRUITER.DRAFTED_OFFER,
    };

    patchOffer({
      offerId: offer._id,
      _event: event._id,
      offerParams: { status: 'draft' },
      notificationParams: {
        success: {
          message: t({ id: 'event.recruiter.preparation.offer.notification.success.draft'}),
          kind: 'info',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        },
        error: true,
      },
      callback: track(trackData),
    });
  };

  const handleOfferDeletion = (offer) => {
    const trackData = {
      user: authUser,
      event,
      name: ANALYTICS_RECRUITER.DELETED_OFFER,
      properties: {
        'Current url': window.location.href,
      },
    };

    deleteOffer({
      offerId: offer._id,
      eventId: event._id,
      organizationId: offer._organization._id,
      notificationParams: {
        success: {
          message: t({ id: 'event.recruiter.preparation.offer.notification.success.delete'}),
          kind: 'info',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        },
        error: true,
      },
      callback: track(trackData),
    });

    setConfirmModalIsVisible(false)
  };

  const getOffers = () => {
    if (authUser) {
      getOrganizationOffers({
        page: 1,
        offset: 0,
        eventSlug: match.params.eventSlug,
        loader: true,
        organizationId: authUser._currentOrganization._id,
      });
    }
  }

  const renderOffers = (offers) => {
    const offersPartitionByStatus = partition(offers, (offer) => offer.status === 'published');
    const offersGroupByStatus = {
      published: offersPartitionByStatus[0],
      draft: offersPartitionByStatus[1],
    };
    return ['published', 'draft'].map((status, index) => (
      <React.Fragment key={index}>
        <Banner status={status} count={offersGroupByStatus[status].length} />
        {offersGroupByStatus[status].map((offer) => {
          const otherActions = (
            <Menu>
              {!isEmpty(offer._event) && status === 'published' ? (
                <Menu.Item>
                  <a role="button" className={styles.hideIcon} onClick={() => draftOffer(offer)} tabIndex={0}><Icon name="icon-hide" />{t({ id: 'event.recruiter.preparation.offer.btn.draft' })}</a>
                </Menu.Item>
              ) : ''}
              {!isEmpty(offer._event) ? (
                <Menu.Item>
                  <a role="button" onClick={() => onDuplicateOffer(offer)} tabIndex={0}><Icon name="duplicate" />{t({ id: 'event.recruiter.preparation.offer.btn.duplicate' })}</a>
                </Menu.Item>
              ) : ''}
              {['draft', 'expired'].includes(status) ? (
                <Menu.Item>
                  <a role="button" onClick={() => onDeleteOfferClick(event, offer)} tabIndex={0}><Icon name="trash" />{t({ id: 'event.recruiter.preparation.offer.btn.delete' })}</a>
                </Menu.Item>
              ) : ''}
            </Menu>
          );

          const moreButton = (<MoreDropdown overlay={otherActions} placement="bottomRight" />);

          return (
            <OfferCardList key={offer._id} offer={offer} event={event} handleAction={onHandleAction} moreActions={moreButton} />
          );
        })}
      </React.Fragment>
    ));
  };

  const renderWarningOfferLimitExceeded = () => {
    const offersCount = offers.filter((offer) => offer.status === 'published').length;

    if (!isOfferLimitExceeded(exponent, offersCount)) return null;

    return exponent._organization.status === PIPE_DRIVE.status.client ?
      <div style={{ marginBottom: '20px' }}><Alert color="warning" icon={<IconAlertTriangle size={16} />}>{t({ id: 'event.recruiter.preparation.offer.form.warning.max.offers.client' })}</Alert></div>
      : <div style={{ marginBottom: '20px' }}><Alert color="warning" icon={<IconAlertTriangle size={16} />}>{t({ id: 'event.recruiter.preparation.offer.form.warning.max.offers' })}</Alert></div>;
  };


  if (isEmpty(event) || isEmpty(exponent)) {
    return null;
  }

  if (event.modules.offer.enable === false) {
    let route = `/${event.slug}/recruiter/preparation/timeslots`;


    route = `/${event.slug}/recruiter/preparation/team`;

    replace(route);

    return null;
  }

  if (isFetching) {
    return (<LoadingIndicator />);
  }

  if (showOfferModalIsOpen) {
    const currentOffer = {...(formValues || selectedOffer), organizationProfile: organizationProfile?.profile }

    return (
      <OfferPreview
        currOff={currentOffer}
        event={event}
        organization={authUser._currentOrganization}
        context={context}
        isPreview
        handleClosePreview={closeOfferModal}
      />
    );
  }

  const crossOffersFiltered = crossOffers.filter((offer) => offer._event !== event._id);
  const publishedOffers = offers.filter((offer) => offer.status === 'published');
  const pastEvents = getPastEvents();
  const maxOffers = get(exponent, 'limits.offerMax');
  const offersCount = offers.filter((offer) => offer.status === 'published').length;

  return (
    <div className={styles.sceneContainer}>
      {offers.length === 0 ?
        <>
          <If condition={get(event, 'descriptionCandidatesTarget')}>
            <Alert
              color="warning"
              icon={<IconAlertTriangle size={16} />}
              style={{ marginBottom: 20 }}
            >
              <ShowMore maxHeight={82}>
                <Html style={{ color: '#949AA6' }} value={get(event, 'descriptionCandidatesTarget')} />
              </ShowMore>
            </Alert>
          </If>
          <BlankState
            title={t({ id: 'blankState.preparation.offer.title' })}
            content={t({ id: 'blankState.preparation.offer.content' })}
            icon="blank-state-plus"
            handleAction={() => openModal(null)}
            buttonLabel={t({ id: 'blankState.preparation.offer.btn' })}
            buttonType="default"
          >
          </BlankState>
          <Popover
            id="crossForumOffers"
            placement="top"
            overlayStyle={{ zIndex: '50' }}
            content={
              <ImportCrossForumOffers
                setVisible={setVisibleCrossOffers}
                visible={isCrossOfferVisible}
                authUserEvents={pastEvents}
                duplicateCrossOffers={duplicateCrossOffers}
                crossOffers={crossOffersFiltered}
                event={event}
                getOffers={getOffers}
              />}
            trigger="click"
            showArrow={false}
          >
            <div style={{ display: 'flex', justifyContent: 'center' }} className={styles.linkCrossForumOffer}>
              <p>{t({ id: 'signin.or' })}</p>
              <Button
                className="mt-20"
                variant="tonal"
                onClick={() => { setVisibleCrossOffers(true); trackAddExistingOfferBtn(true); }}
              >
                {t({ id: 'offer.duplicate.existing' })}
              </Button>
            </div>
          </Popover>
        </>
        :
        <>
          <div className={styles.topContainer}>
            <h1 className="mainTitle" style={{ marginBottom: 0 }}>
              <FormattedMessage id="event.recruiter.preparation.offer.title" values={{ count: publishedOffers.length }} />
            </h1>
            <div className={styles.actions}>
              <Popover
                id="crossForumOffers"
                overlayStyle={{ zIndex: '40' }}
                content={
                  <ImportCrossForumOffers
                    setVisible={setVisibleCrossOffers}
                    visible={isCrossOfferVisible}
                    authUserEvents={getPastEvents()}
                    duplicateCrossOffers={duplicateCrossOffers}
                    crossOffers={crossOffersFiltered}
                    event={event}
                    getOffers={getOffers}
                  />}
                trigger="click"
                showArrow={false}
              >
                <Button variant="tonal" className="mr-20" disabled={isOfferLimitExceeded(exponent, offersCount)} onClick={() => { setVisibleCrossOffers(true); trackAddExistingOfferBtn(true); }}>
                  {t({ id: 'offer.duplicate.existing'})}
                </Button>
              </Popover>
              <Button disabled={isOfferLimitExceeded(exponent, offersCount)} onClick={() => { openModal(null); trackAddExistingOfferBtn(false); }}>
                {t({ id: 'event.recruiter.preparation.offer.create' })}
              </Button>
            </div>
          </div>
          <div className={styles.authorizedOfferNumber}>
            <p className="help">
              <FormattedMessage
                id="event.recruiter.preparation.offer.form.authorized.offers"
                values={{ total: maxOffers, value: publishedOffers.length }} />
            </p>
          </div>

          {organizationProfile?.profile?.status === 'draft' &&
            <Alert isClosable={false} color='warning' icon={<IconAlertTriangle size={16} />}>
              <div>
                {t({ id: 'event.recruiter.preparation.offer.warning.stand.not.published' })}
                <Button
                  onClick={() => history.push(`stand`)}
                  className={styles.btnPublishStand}
                  size='small'
                  variant='outline'
                  color='warning'
                >
                  {t({ id: 'event.recruiter.preparation.stand.toaster.btn.publish' })}
                </Button>
              </div>
            </Alert>
          }

          <ModalBig
            isOpen={showOfferModalIsOpen}
            onClose={closeOfferModal}
            sideContent={selectedOffer ? <OfferSideContent offer={selectedOffer} /> : ''}
          >
            <div className="modalBigWrapper">
              {!isEmpty(selectedOffer) && showOfferModalIsOpen ? (
                <OfferCardParticipant context={context} event={event} offer={selectedOffer} previewMode />) : ''}
            </div>
          </ModalBig>

          {renderWarningOfferLimitExceeded()}

          {/* Offers Container */}
          <div className={styles.offersContainer}>
            <If condition={get(event, 'descriptionCandidatesTarget')}>
              <Alert
                color="warning"
                icon={<IconAlertTriangle size={16} />}
                style={{ marginBottom: 20 }}
              >
                <ShowMore maxHeight={82}>
                  <Html style={{ color: '#949AA6' }} value={get(event, 'descriptionCandidatesTarget')} />
                </ShowMore>
              </Alert>

            </If>
            {renderOffers(offers)}
          </div>

          <Modal
            visible={confirmModalIsVisible}
            footer={false}
            maskClosable
            width={470}
            className="customConfirm"
            onCancel={() => setConfirmModalIsVisible(false)}
          >
            <a role="button" tabIndex={0} className="modal-close" onClick={() => setConfirmModalIsVisible(false)}>
              <Icon name="close" className="modal-close-icon" />
            </a>

            <h4 className={classnames(styles.offerModalDeletion, 'ant-confirm-title')}>
              <span role="img" aria-label="warning">⚠️ </span>{t({ id: 'event.recruiter.preparation.offer.deletion.confirm' })}
            </h4>

            <Separator height={15} />

            <div className="confirm-actions">
              <Button
                className="mr-6"
                variant="tonal"
                onClick={() => setConfirmModalIsVisible(false)}
              >
                {t({ id: 'cancel' })}
              </Button>
              <Button onClick={() => handleOfferDeletion(selectedOffer)}>
                {t({ id: 'btn.confirm' })}
              </Button>
            </div>
          </Modal>
        </>
      }

      <CreateOfferModal
        isOpen={createOfferModalIsOpen}
        onClose={closeModal}
        offer={{ ...selectedOffer, ...formValues }}
        onPreview={(formValues) => showOffer(selectedOffer, 'offerEdit', formValues)}
        currentEvent={event}
        exponent={exponent}
        publishedOffers={publishedOffers}
        maxOffers={maxOffers}
        warningOfferLimit={renderWarningOfferLimitExceeded()}
      />
    </div>
  );
}

RecruiterPreparationOffersScene.propTypes = {
  match: object,
  isFetching: bool,
  getOrganizationOffers: func,
  getCrossForumOffer: func,
  getAuthUserEvents: func,
  authUserEvents: array,
  duplicateCrossOffers: func,
  crossOffers: array,
  duplicateOffer: func,
  deleteOffer: func,
  offers: array,
  event: object,
  exponent: object,
  authUser: object,
  location: object,
  replace: func,
  patchOffer: func,
  authToken: object,
  history: object,
};

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
  authToken: authSelectors.getAuthToken,
  authUserEvents: eventSelectors.getAuthUserEvents,
  offers: offerSelectors.getOffers,
  event: eventSelectors.getCurrentEvent,
  exponent: exponentSelectors.getCurrentExponent,
  isFetching: offerSelectors.getOffersFetching,
  crossOffers: offerSelectors.getCrossForumOffer,
  authUserConnectedAs: authSelectors.getAuthUserConnectedAs,
});

const mapDispatchToProps = {
  getOrganizationOffers: offerActions.getOrganizationOffers,
  getOffer: offerActions.getOffer,
  duplicateOffer: offerActions.duplicateOffer,
  duplicateCrossOffers: offerActions.duplicateCrossOffers,
  deleteOffer: offerActions.deleteOffer,
  getAuthUserEvents: eventActions.getAuthUserEvents,
  patchOffer: offerActions.patchOffer,
  getCrossForumOffer: offerActions.getCrossForumOffer,
  replace,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withLayout({ size: 'default', withHeader: false, navType: 'recruiter' }),
  withConnect,
)(toJS(RecruiterPreparationOffersScene));
