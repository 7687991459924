import React from 'react';
import { bool, string, func } from 'prop-types';

import styles from './styles.less';

const CandidateName = ({
  isAuthUserAppointment,
  handleProfileClick,
  fullName,
  canAccessProfiles,
}) => (
  <div className={styles.appointmentCandidate}>
    {isAuthUserAppointment && canAccessProfiles ? (
      <a role="button" tabIndex={0} onClick={handleProfileClick}>{fullName}</a>
    ) : (
      <a role="button" tabIndex={0} className={styles.disabled}>{fullName}</a>
    )}
  </div>
);

CandidateName.propTypes = {
  isAuthUserAppointment: bool,
  handleProfileClick: func,
  fullName: string,
  canAccessProfiles: bool,
};

export { CandidateName };
