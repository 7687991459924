import React, { useMemo, useCallback, useEffect } from 'react';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment-timezone';
import { arrayOf, bool, func, number, object, any, string } from 'prop-types';

import { EVENT_FORMAT } from '@/utils/constants';
import { getEventKeyMoments } from '@/helpers/event/getEventKeyMoments';
import Toolbar from './components/Toolbar';
import Events from './components/Events';
import WeekHeader from './components/WeekHeader';
import { EVENTS_COLORS } from './ducks/BigCalendarConstants';

import './styles.less';

function BigCalendar({
  indexWeek,
  currentEvent,
  handleWeekChange,
  handleDeleteTimeSlots,
  timezone,
  events,
  jobfairs,
  handleOpenSetting,
  isSettingsVisible,
  onSelectSlot,
  currentDate,
  locale,
  handleEventResize,
  handleDeleteEvent,
  handleOnInformalDescriptionChange,
  informalDescription,
  exponentFormat,
  eventKeyMoments,
  changeEventKeyMoment,
  authUser,
}) {
  const intlMoment = moment.tz.setDefault(timezone);
  // Force default settings and force monday at first day of week
  intlMoment.locale(locale, {
    dow: 1,
  });

  // Set current locale
  intlMoment.locale(locale);

  const localizer = momentLocalizer(intlMoment);
  /**
   * Return a hashmap of jobfairs colors
   * @returns {Object}
   */
  const jobfairsColor = useMemo(() => {
    const hashmap = {};

    jobfairs.forEach((jobfair, index) => {
      hashmap[jobfair.id] = EVENTS_COLORS[(index % 10)];
    });

    return hashmap;
  }, [jobfairs]);

  /* Callbacks */

  /**
   * Return a className for disabled date when jobfair select out of the date param
   * @returns {Object}
   */
  const handleSlotPropGetter = useCallback((date) => {
    const newDate = moment.tz(date, timezone);

    if (currentEvent) {
      let isBetween = false;
      if (exponentFormat === EVENT_FORMAT.HYBRID && currentEvent.format === EVENT_FORMAT.HYBRID && !isSettingsVisible) {
        const isBetweenPhysical = newDate.isSameOrAfter(getEventKeyMoments(currentEvent, { type: 'jobfair', format: EVENT_FORMAT.PHYSICAL }).beginAt) && newDate.isSameOrBefore(getEventKeyMoments(currentEvent, { type: 'jobfair', format: EVENT_FORMAT.PHYSICAL }).endAt);
        const isBetweenVirtual = newDate.isSameOrAfter(getEventKeyMoments(currentEvent, { type: 'jobfair', format: EVENT_FORMAT.VIRTUAL }).beginAt) && newDate.isSameOrBefore(getEventKeyMoments(currentEvent, { type: 'jobfair', format: EVENT_FORMAT.VIRTUAL }).endAt);
        isBetween = isBetweenPhysical || isBetweenVirtual;
      }
      else {
        isBetween = newDate.isSameOrAfter(eventKeyMoments.beginAt) && newDate.isSameOrBefore(eventKeyMoments.endAt);
      }
      if (isBetween === false || newDate.isSameOrBefore(moment())) {
        return {
          className: 'disableDay',
        };
      }
    }

    return {};
  }, [timezone, currentEvent, eventKeyMoments]);

  /* Functions */

  const handleSelectSlot = (slot) => {
    const start = moment(slot.start);

    if (start.isAfter(moment())) {
      onSelectSlot(slot);
    }
  };

  const getTimezoneOffset = () => {
    if (currentEvent.timezone) {
      return authUser?.timezone?.offset - currentEvent?.timezone?.offset > -7 ? authUser?.timezone?.offset - currentEvent?.timezone?.offset : -7;
    }
    return 0;
  }

  const timezoneOffset = getTimezoneOffset();
  /* Render */

  return (
    <Calendar
      selectable
      localizer={localizer}
      events={events}
      exponentFormat={exponentFormat}
      defaultView={Views.WEEK}
      timeslots={4}
      step={15}
      min={moment().hours(timezoneOffset < 0 ? 7 + timezoneOffset : 7).minutes(0).seconds(0).milliseconds(0).toDate()}
      drilldownView={false}
      components={{
        toolbar: () => <Toolbar
          currentEvent={currentEvent}
          indexWeek={indexWeek}
          handleWeekChange={handleWeekChange}
          handleDeleteTimeSlots={handleDeleteTimeSlots}
          timeslots={events}
          handleOpenSetting={handleOpenSetting}
          isSettingsVisible={isSettingsVisible}
          exponentFormat={exponentFormat}
          changeEventKeyMoment={changeEventKeyMoment}
        />,
        week: {
          event: (props) => (
            <Events
              isSettingsVisible={isSettingsVisible}
              jobfairsColor={jobfairsColor}
              jobfairs={jobfairs}
              timezone={timezone}
              handleDeleteEvent={handleDeleteEvent}
              currentEvent={currentEvent}
              handleOnInformalDescriptionChange={handleOnInformalDescriptionChange}
              informalDescription={informalDescription}
              {...props}
            />
          ),
          header: (props) => (
            <WeekHeader exponentFormat={exponentFormat} event={currentEvent} {...props} />
          ),
        },
      }}
      formats={{
        dateFormat: 'LLL',
        timeGutterFormat: 'LT',
      }}
      onSelectSlot={handleSelectSlot}
      date={currentDate}
      onEventResize={handleEventResize}
      slotPropGetter={handleSlotPropGetter}
    />
  );
}

BigCalendar.propTypes = {
  indexWeek: number,
  currentEvent: object,
  handleWeekChange: func,
  handleDeleteTimeSlots: func,
  events: arrayOf(object), // Events compose timeslots and jobfair event (all day)
  timezone: string,
  jobfairs: arrayOf(object),
  handleOpenSetting: func,
  isSettingsVisible: bool,
  onSelectSlot: func,
  currentDate: any,
  locale: string,
  handleEventResize: func,
  handleDeleteEvent: func,
  handleOnInformalDescriptionChange: func,
  informalDescription: string,
  exponentFormat: string,
  eventKeyMoments: object,
  changeEventKeyMoment: func,
};

export default BigCalendar;
