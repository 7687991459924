import React from 'react';
import { string, object } from 'prop-types';

const Circle = ({
  style = {},
  size = '10px',
  color = 'red',
}) => (
  <span style={{ width: size, height: size, background: color, borderRadius: '50%', ...style }} />
);

Circle.propTypes = {
  size: string,
  color: string,
  style: object,
};

export { Circle };
