import React from 'react';
import { object, func } from 'prop-types';

// Components
import { map } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { getId } from '@/utils/global';
import Avatar from '@/components/Avatar';

// Styles & Translations
import styles from './styles.less';
import { DEAL_NAME } from '@/utils/deals';
import { useFormatMessage } from 'react-intl-hooks';

/**
 * Organization
 *
 * @param {object} props
 * @param {object} props.organization
 * @param {Function} props.onSelect
 */
const Organization = ({ organization, onSelect }) => (
  <div role="button" tabIndex={0} className={styles.organization} onClick={() => typeof onSelect !== 'undefined' ? onSelect(organization) : null}>
    <Avatar type="rounded" size={28} src={organization && organization.profile ? organization.profile.pictureUrl : null} />
    <div>
      <div className={styles.organizationInfoContainer}>
        <span className={styles.organizationName}>{organization.name}</span>
        <span className={styles.organizationInfo}>12 RECRUTEURS • 15 JOBDATINGS</span>
      </div>
    </div>

    {/* Actions */}
    <div className={styles.actions}>
    </div>
  </div>
);

const Organization2 = ({ organization, onSelect, authUser = {} }) => (
  <div role="button" tabIndex={0} className={styles.organization} onClick={() => typeof onSelect !== 'undefined' ? onSelect(organization) : null}>
    <div style={{ width: '100%' }}>
      <div className={styles.organizationInfoContainer}>
        <div>
          <Avatar className={styles.organizationAvatar} type="rounded" size={28} src={organization && organization.profile ? organization.profile.pictureUrl : null} />
          <span className={styles.organizationName}>{organization.name}</span>
        </div>
        <div className={styles.organizationInfo}>
          <span className={styles.organizationNbJd}>
            {organization.countExponents} <FormattedMessage id="jobdating.pl" values={{ count: organization.countExponents }} />
          </span>
          {authUser.isAdmin && <DisplayPack {...organization} />}
        </div>
      </div>
    </div>

    {/* Actions */}
    <div
      tabIndex={0}
      role="button"
      className={styles.actions}
      onClick={(e) => e.stopPropagation()}
    >
      {authUser.isAdmin && <a tabIndex={0} target="_blank" role="button" href={`/admin/organizations/${getId(organization)}/exponents`}>
        Admin
      </a>}
    </div>
  </div>
);


const DisplayPack = ({ activeDeals }) => {
  const t = useFormatMessage();

  const DEALS_PACK = {
    [DEAL_NAME.thematic_one_shot]: t({ id: 'form.deal.select.thematic_one_shot' }),
    [DEAL_NAME.company_one_shot]: t({ id: 'form.deal.select.thematic_one_shot' }),
    [DEAL_NAME.company_subscription]: t({ id: 'deal.company_subscription' }),
    [DEAL_NAME.schoolpack_subscription]: 'school pack',
    [DEAL_NAME.thematic_subscription]: t({ id: 'form.deal.select.thematic_subscription' }),
    [DEAL_NAME.credit_subscription]: t({ id: 'form.deal.select.credit_subscription' }),
    [DEAL_NAME.movie_party_one_shot]: t({ id: 'form.deal.select.movie_party_one_shot' }),
  };

  return map(activeDeals,
    (deal) => DEALS_PACK[deal]).join(' • ');
};

Organization2.propTypes = {
  organization: object,
  authUser: object,
  onSelect: func,
};

Organization.propTypes = {
  organization: object,
  onSelect: func,
};

export default Organization;
export { Organization2 };
