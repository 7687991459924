export const story = {
  dashboardCandidate: {
    isVisible: true,
  },
  standContextual: {
    visible: true,
  },
  orgaAccess: {
    isVisible: true,
  },
  // https://www.notion.so/Donner-la-main-aux-organisateurs-sur-la-pr-paration-0ba081a3a5ea4bd9b6cdfd1cc8e5b8ea
  giveControlToOwner: {
    editOffer: {
      isVisible: true,
    },
  },
  cvCatcher: {
    isVisible: false
  },
}
