import React, { useState } from 'react';
import { Collapse as CollapseAntd, Row, Col } from 'antd';
import { useFormatMessage } from 'react-intl-hooks';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { replace } from 'connected-react-router';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Skeleton from 'react-loading-skeleton';

import Icon from '@/components/Icon';
import ImgCdn from '@/components/ImgCdn';

import S3 from '@/services/S3';

import { authSelectors } from '@/store/auth';
import { notificationActions } from '@/store/notification';

import generateFileName from '../../utils/generateFileName';

import styles from './styles.less';
import { IconUpload } from '@seekube-tech/ui-kit';

const { Panel } = CollapseAntd;

function Pictures({ input: { value, onChange }, organizationId, authToken, sendNotification, disabled }) {
  /* Vars */

  const t = useFormatMessage();
  const [isLoading, setIsLoading] = useState(false);

  /* Functions */

  /**
   * Upload pictures to S3
   * @param {Files} files
   */
  const handleOnDropPicture = async (files) => {
    try {
      setIsLoading(true);

      const [file] = files;

      const url = await S3.getS3Url(generateFileName(file), 'organization', organizationId, authToken);

      onChange([...value, url]);
    } catch (error) {
      sendNotification({
        message: t({ id: 'event.recruiter.preparation.stand.notification.bad.format' }),
        kind: 'error',
        style: {},
      });
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * handle remove uploaded picture from pictures list
   * @param {Event} e
   * @param {String} fileName
   */
  const handleOnRemovePicture = (e, fileName) => {
    e.preventDefault();

    const newPictures = value.filter((picture) => picture !== fileName);

    onChange(newPictures);
  };

  /* Render */

  return (
    <Row className={styles.picturesGallery} type="flex" align="middle" gutter={[10, 10]}>
      <Col>
        <Dropzone
          className={styles.uploader}
          accept="image/png, image/jpeg, image/gif"
          onDrop={handleOnDropPicture}
          activeClassName={styles.activeDropzone}
          multiple={false}
          disabled={disabled}
        >
          <IconUpload size={20}/>
          <div aria-disabled={disabled} className={styles.help}>
            {t({ id: 'stand.step.picture.add' })}
          </div>
        </Dropzone>
      </Col>

      {(value || []).map((picture, i) => (
        <Col key={i} className={styles.imgContainer}>
          <div className={styles.picture}>
            <ImgCdn size={100} src={picture} />
            {!disabled &&
              <a
                role="button"
                tabIndex="0"
                className="remove-picture"
                onClick={(e) => handleOnRemovePicture(e, picture)}
              >
                <Icon name="refuse" className="ico-remove-picture" />
              </a>
            }
          </div>
        </Col>
      ))}

      {isLoading && (
        <Col>
          <Skeleton circle height={112} width={112} />
        </Col>
      )}
    </Row>
  );
}

const mapStateToProps = createStructuredSelector({
  authToken: authSelectors.getAuthToken,
});

const mapDispatchToProps = {
  sendNotification: notificationActions.sendNotification,
  replace,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

Pictures.propTypes = {
  input: PropTypes.object.isRequired,
  organizationId: PropTypes.string,
  authToken: PropTypes.string,
  sendNotification: PropTypes.func.isRequired,
};

Pictures.defaultProps = {
  organizationId: null,
  authToken: '',
};

export default compose(withConnect)(Pictures);
