import React, { useState } from 'react';
import { object, func, string } from 'prop-types';
import { createStructuredSelector } from 'reselect';

import { Col, DatePicker, Row } from 'antd';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import { replace, push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { intersection, isEmpty, get } from 'lodash';
import { authSelectors } from '@/store/auth';
import { exponentActions, exponentSelectors } from '@/store/exponent';
import { organizationActions, organizationSelectors } from '@/store/organization';
import { eventSelectors } from '@/store/event';
import { toJS } from '@/utils';
import { getId } from '@/utils/global';
import { queryStringToObject } from '@/utils/url';
import { useRequest } from '@/utils/hooks';

import Checkbox, { CheckboxGroup } from '@/components/Checkbox';
import { If } from '@/components/If';
import Wrapper from '@/components/Wrapper';
import { Tab } from '@/components/Tab';

import { StatsOffer, StatsLive, StatsEvents, StatsRecruiters, StatsSourcing, StatsFunnelConversionParticipants, StatsAttendees, StatsAppointments } from '../../components';
import {
  getStatsRequest,
  getExportUrl, getRequestUrl,
} from '../../requests';
import { getInitialType } from '../../helpers';

import styles from '../../styles.less';
import messages from '../../messages';

const { RangePicker } = DatePicker;

const useRequestState = (requestName, params) => useRequest(
  () => getStatsRequest({ requestName, ...params }),
  getRequestUrl({ requestName, ...params })
);


const StatsScene = ({
  authUser,
  specificOrganization, // for admin
  authToken,
  push,
  location,
}) => {
  const { begin, end } = queryStringToObject(location.search);
  const context = location.pathname.indexOf('/admin') > -1 ? 'admin' : 'client';
  const activePlans = get(authUser, '_currentOrganization.activePlans');
  const [period, setPeriod] = useState({ begin: begin ? moment(begin).format('YYYY-MM-DD') : moment().subtract(3, 'month').format('YYYY-MM-DD'), end: end ? moment(end).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD') });
  const [type, setType] = useState(getInitialType(activePlans, context === 'admin')); // typologie de l'event
  const organization = context === 'admin' ? getId(specificOrganization) : getId(authUser._currentOrganization);
  const requestParams = { authToken, organization, period, type };

  const [events, isEventsLoading] = useRequestState('events', requestParams);
  const [interview, isInterviewLoading] = useRequestState('interview', requestParams);
  const [recruiterData, isRecruiterLoading] = useRequestState('exponentUsers', requestParams);
  const [offers, isOffersLoading] = useRequestState('offers', requestParams);
  const [conversionDistribution] = useRequestState('conversionDistribution', requestParams);
  const [conversionLives, isConversionLivesLoading] = useRequestState('conversionLives', requestParams);
  const [conversion, isConversionLoading] = useRequestState('conversion', requestParams);


  return (
    <Wrapper type="scene">
      <div className={styles.statsContainer}>
        <Tab
          className={styles.tabs}
          defaultIndex={0}
          items={[
            { label: <FormattedMessage {...messages.toggleViewByPeriod} />, onClick: () => push('by-period') },
            { label: <FormattedMessage {...messages.toggleViewByEvent} />, onClick: () => push('by-event') },
          ]}
        />
        <If condition={(!isEmpty(intersection(activePlans, ['oneShot', 'subscription']))) || (authUser.isAdmin)}>
          <div className={styles.type}>
            <CheckboxGroup defaultValue={type} onChange={(e) => { if (!isEmpty(e)) { setType(e); } }} value={type}>
              <Checkbox value="school"><FormattedMessage {...messages.eventsSchoolsPl} values={{ count: 2 }} /></Checkbox>
              <Checkbox value="seekube"><FormattedMessage {...messages.eventsThematicsPl} values={{ count: 2 }} /></Checkbox>
            </CheckboxGroup>
          </div>
        </If>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '30px' }}>
          <div style={{ flexBasis: '75%' }}>
            <RangePicker
              disabledDate={(current) => current.isBefore(moment('2020-09-01'))}
              format="DD MMM YYYY"
              defaultValue={[moment(period.begin), moment(period.end)]}
              onChange={(([beginAt, endAt]) => { push(`?begin=${beginAt.format('YYYY-MM-DD')}&end=${endAt.format('YYYY-MM-DD')}`); setPeriod({ begin: beginAt.format('YYYY-MM-DD'), end: endAt.format('YYYY-MM-DD') }); })}
            />
          </div>
        </div>
        <div>
          <FormattedMessage tagName="h3" {...messages.participationsTitle} />
          <Row gutter={20}>
            <Col span={8}>
              <StatsEvents
                data={get(events, 'docs')}
                loading={!isEventsLoading}
              />
            </Col>
            <Col span={4}>
              <StatsRecruiters
                data={recruiterData}
                loading={!isRecruiterLoading}
                onActionClick={() => getExportUrl({ requestName: 'exponentUsers', period, type, organization })}
              />
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={6}>
              <StatsOffer
                data={offers}
                loading={!isOffersLoading}
                onActionClick={() => getExportUrl({ requestName: 'offers', period, type, organization })}
              />
            </Col>
            <Col span={6}>
              <StatsLive
                data={conversionLives}
                loading={!isConversionLivesLoading}
                onActionClick={() => getExportUrl({ requestName: 'conversionLives', period, type, organization })}
              />
            </Col>
          </Row>
          <FormattedMessage tagName="h3" {...messages.funnelConversionTitle} />
          <Row>
            <Col span={12}>
              <StatsFunnelConversionParticipants
                {...conversion}
                loading={!isConversionLoading}
              />
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={6}>
              <StatsSourcing {...conversion} />
            </Col>
            <Col span={6}>
              <StatsAttendees {...conversionDistribution} />
            </Col>
          </Row>
          <FormattedMessage tagName="h3" {...messages.interviewTitle} values={{ count: 2 }} />
          <Row>
            <Col span={12}>
              <StatsAppointments
                {...interview}
                loading={!isInterviewLoading}
              />
            </Col>
          </Row>
        </div>
      </div>
    </Wrapper>
  );
};

StatsScene.propTypes = {
  authUser: object,
  location: object,
  specificOrganization: object,
  authToken: string,
  push: func,
};

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
  authToken: authSelectors.getAuthToken,
  exponent: exponentSelectors.getCurrentExponent,
  organization: organizationSelectors.getCurrentOrganization,
  event: eventSelectors.getCurrentEvent,
  specificOrganization: organizationSelectors.getSpecificOrganization,
});

const mapDispatchToProps = {
  patchOrganization: organizationActions.patchOrganization,
  patchExponent: exponentActions.patchExponent,
  getExponentByOrganization: exponentActions.getExponentByOrganization,
  resetAlreadyExist: organizationActions.resetAlreadyExist,
  replace,
  push,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  toJS,
)(StatsScene);

