import React from 'react';
import { object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { createStructuredSelector } from 'reselect';
import Sticky from 'react-sticky-el';
import { toJS } from '@/utils';

// Store
import { eventSelectors } from '@/store/event';
import { authSelectors } from '@/store/auth';
import { participantSelectors } from '@/store/participant';
import { exponentSelectors } from '@/store/exponent';

// Components
import Separator from '@/components/Separator';
import Header from './containers/Header';
import SceneHeader from './containers/SceneHeader';
import SceneContainer from './components/SceneContainer';
import { NavScenePortal } from './containers/SceneHeader/containers/NavScene';

import { SplitView } from './containers/Templates';

import styles from './styles.less';

const defaultParams = {
  size: 'default',
  rightContent: null,
  template: {},
  navType: 'auto',
  withNav: true,
  withHeader: true,
  withFill: false,
  withContainer: true,
  withBlur: false,
};

const withLayout = (params) => (WrappedComponent) => {
  const layoutParams = {
    ...defaultParams,
    ...params,
  };

  class Layout extends React.PureComponent { // eslint-disable-line
    static propTypes = {
      location: object,
    };

    state = {
      agendaPanelIsOpen: false,
    };


    onOpenAgenda = () => {
      this.setState({ agendaPanelIsOpen: true });
    };

    onCloseAgenda = () => {
      this.setState({ agendaPanelIsOpen: false });
    };

    renderSceneContainer = () => {
      const { template } = layoutParams;

      if (template.name === 'splitView') {
        return (
          <SplitView
            template={template}
            props={this.props}
            WrappedComponent={WrappedComponent}
          />);
      }

      return <WrappedComponent {...this.props} />;
    };

    render() {
      const {
        props: { location },
        state: { agendaPanelIsOpen },
        onOpenAgenda, renderSceneContainer,
      } = this;

      const layoutWrapper = classNames(styles.layoutWrapper, layoutParams.withFill ? styles.absoluteWrapper : null);

      if (location && location.pathname.indexOf('/client/offer-edit') > -1) {
        return (<WrappedComponent {...this.props} />);
      }

      return (
        <div className={layoutWrapper}>
          {/* Header */}
          <div className={styles.headerWrapper} id="headerWrapper">
            <Sticky>
              {layoutParams.withHeader ? (<Header onShowAgenda={onOpenAgenda} agendaPanelIsOpen={agendaPanelIsOpen} />) : ''}

              {/* SceneHeader : Navigation */}
              {layoutParams.withHeader && layoutParams.withNav ? (
                <SceneHeader navType={layoutParams.navType} />
              ) : ''}
            </Sticky>
          </div>

          <SceneContainer
            size={layoutParams.size}
            withNav={layoutParams.withNav}
            withContainer={layoutParams.withContainer}
            withFill={layoutParams.withFill}
          >
            {renderSceneContainer()}
          </SceneContainer>

          {layoutParams.withFill ? null : <Separator className="hidden-mobile" height={100} />}
        </div>
      );
    }
  }

  const mapStateToProps = createStructuredSelector({
    event: eventSelectors.getCurrentEvent,
    authUser: authSelectors.getAuthUser,
    participant: participantSelectors.getCurrentParticipant,
    exponent: exponentSelectors.getCurrentExponent,
  });

  const withConnect = connect(mapStateToProps, null);

  return compose(
    withConnect,
  )(toJS(Layout));
};

export {
  withLayout,
  NavScenePortal,
};
