import React, { useState } from 'react';
import { func, string } from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import { Form } from 'antd';
import { useFormatMessage } from 'react-intl-hooks';
import { Alert } from '@seekube-tech/ui-kit';
import { toJS } from '@/utils';
import { getNavigator } from '@/utils/navigators';

import { If } from '@/components/If';

import styles from './styles.less';
import messages from '../messages';

// Images
import liveStorm from '@/assets/images/logo-livestorm.png';
import bigmarker from '@/assets/images/logo-bigmarker.svg';
import teams from '@/assets/images/logo-teams.svg';
import zoom from '@/assets/images/logo-zoom.svg';
import meet from '@/assets/images/logo-meet.svg';
import { IconCheckCircle, Tag, Button, IconXCircle, IconLink } from '@seekube-tech/ui-kit';
import classNames from 'classnames';

const EditLivetalkStep2 =
  ({ handleNext, provider }) => {
    const t = useFormatMessage();
    const [providerSelected, setProviderSelected] = useState(provider);
    const { isSafari, isExplorer } = getNavigator();

    const nextStep = () => {
      handleNext({ provider: providerSelected });
    }

    return (
      <div className={styles.editLivetalkStep2}>
        <If condition={isSafari || isExplorer}>
          <Alert color="warning">
            {t({ id: 'recruiter.live.edit.tool.navigator.alert' })}
          </Alert>
        </If>

        <div />
        <div>
          <div className={classNames(styles.liveStorm, providerSelected === 'seeklive' && styles.liveStormSelected)} onClick={() => setProviderSelected('seeklive')}>
            <div className={styles.header}>
              {/* <img src={liveStorm} alt='logo livestorm' /> */}
              <img src={bigmarker} alt='logo bigmarker' />
              <div className={styles.tags}>
                {/* #LIVESTORM Pour le lot 2 */}
                {/* <Tag 
                  variant='tonal'
                  color='success'
                  isClosable={false}
                  icon={<IconCheckCircle size={12} />}
                  className={styles.tag}
                >
                  Intégré dans Seekube
                </Tag>
                <Tag 
                  variant='tonal'
                  color='success'
                  isClosable={false}
                  icon={<IconCheckCircle size={12} />}
                  className={styles.tag}
                >
                  Replay disponible
                </Tag> */}
              </div>
            </div>
            <p className={styles.title}>Bigmarker</p>
            <a target="_blank" href="https://www.bigmarker.com/system_check">{t({id: 'test.compatibility'})}</a>
            {/* #LIVESTORM */}
            {/* <p className={styles.description}>Aucune action à faire, les liens d’accès seront créés automatiquement. Si vous le souhaitez, le replay sera disponible à la fin de votre Live directement sur l’évènement.</p> */}
            {/* <Button color='primary' variant='text'>Tester la compatibilité</Button> */}
          </div>
          <div className={styles.otherProviders}>
            <div className={styles.tags}>
              {/* #LIVESTORM */}
              {/* <Tag 
                variant='tonal'
                color='warning'
                isClosable={false}
                icon={<IconXCircle size={12} />}
                className={styles.tag}
              >
                Non intégré dans Seekube
              </Tag>
              <Tag
                variant='tonal'
                color='warning'
                isClosable={false}
                icon={<IconXCircle size={12} />}
                className={styles.tag}
              >
                Replay non disponible
              </Tag> */}
            </div>
            <p className={styles.description}>{t({id: 'live.creation.tools.description'})}</p>
            <div className={styles.providers}>
              <div className={classNames(styles.provider, providerSelected === 'teams' && styles.providerSelected)}  onClick={() => setProviderSelected('teams')}>
                 <img src={teams} alt='teams' />
                 <p>{t({id: 'microsoft.teams'})}</p>
              </div>
              <div className={classNames(styles.provider, providerSelected === 'zoom' && styles.providerSelected)}  onClick={() => setProviderSelected('zoom')}>
                 <img src={zoom} alt='zoom' />
                 <p>{t({id: 'zoom'})}</p>
              </div>
              <div className={classNames(styles.provider, providerSelected === 'meet' && styles.providerSelected)}  onClick={() => setProviderSelected('meet')}>
                 <img src={meet} alt='meet' />
                 <p>{t({id: 'google.meet'})}</p>
              </div>
              <div className={classNames(styles.provider, providerSelected === 'other' && styles.providerSelected)}  onClick={() => setProviderSelected('other')}>
                 <IconLink size={34} />
                 <p>{t({id: 'recruiter.participantView.interview.other'})}</p>
              </div>
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <Button disabled={providerSelected === ''} color='primary' variant='fill' onClick={nextStep}>{t({id: 'next'})}</Button>
          </div>
        </div>
      </div>
    );
  };


EditLivetalkStep2.propTypes = {
  provider: string,
  providerUrl: string,
  handleNext: func,
};

const withConnect = connect(null, null);
const withForm = Form.create();

export default compose(
  withConnect,
  withForm
)(toJS(EditLivetalkStep2));
