import React, { useEffect, useState } from 'react';
import { createStructuredSelector } from 'reselect';
import { object, func, oneOf, array } from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'redux';
import { range, times, size, head, isEqual } from 'lodash';
import { Form, Select as SelectAntd } from 'antd';
import { Button, H4 } from '@seekube-tech/ui-kit';
import moment from 'moment-timezone';
import { Tooltip } from '@/components/Tooltip';
import { toJS } from '@/utils';
import { getId } from '@/utils/global';
import { CONFERENCE_TOPICS } from '@/utils/constants';
import 'moment-timezone';

import { authSelectors } from '@/store/auth';
import { organizationSelectors } from '@/store/organization';

import InputMaterial from '@/components/Input/InputMaterial';
import Select from '@/components/Form/Select';

import WysiwygEditor, { isWysiwygFieldCompleted } from '@/components/WysiwygEditor';
import { If } from '@/components/If';

import { Presenters, Organizer, BeginAt } from './components';
import { FORMAT_RESTRICTIONS } from '../../helpers';

import styles from './styles.less';
import messages from '../messages';
import { useFormatMessage } from 'react-intl-hooks';
import request from '@/utils/request';


const FormItem = Form.Item;
const { Option } = SelectAntd;

const EditLivetalkStep1 = (props) => {
  const t = useFormatMessage();
  const [description, setDescription] = useState(props.conference.description);
  const [organization, setOrganization] = useState(props.conference._organization);
  const [organizer, setOrganizer] = useState(props.organizer);
  const [availableHours, setAvailableHours] = useState()
  const [presentersEmailsCurrent, setPresentersEmailsCurrent] = useState([''])
  const [date, setDate] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {
    const presentersEmails = [];
    props.form.getFieldsValue().presenters.forEach((presenter) => {
      presentersEmails.push(presenter.email)
    })
    const dateFormatted = moment(props.form.getFieldsValue().beginAt).format('YYYY-MM-DD')
    if (props.form.getFieldsValue().beginAt && presentersEmails[0] !== '' && (date !== dateFormatted || !isEqual(presentersEmailsCurrent,presentersEmails))) {
      setDate(dateFormatted);
      setPresentersEmailsCurrent(presentersEmails)

      const data = {
        eventId: props.eventId,
        date: dateFormatted,
        presentersEmails,
      }
      if (props.conference && props.conference._id) {
        data.conferenceId = props.conference._id;
      }
      const url = `${process.env.FRONT_API_URL}/conferences/check-beginat`
      const options = {
        method: 'POST',
        body: JSON.stringify(data),
      }
      request(url, options)
        .then((res) => {
          setAvailableHours(res);
        });
    }
  }, [props.form.getFieldsValue().presenters, props.form.getFieldsValue().beginAt])

  const handleSubmit = (e) => {
    e.preventDefault();
    const { form: { validateFields }, context, handleNext, authUser: { _currentOrganization }, conference } = props;

    validateFields((err, fields) => {
      if (!err && isWysiwygFieldCompleted(description)) {
        const presenters = fields.presenters.map(
          (pres) => {
            const confPresenter = (size(conference.presenters) && conference.presenters.find((confPresenter) => confPresenter.email.trim() === pres.email.trim())) || {};
            return ({ ...confPresenter, ...pres, email: pres.email.trim() });
          }
        );

        handleNext({
          ...fields,
          presenters,
          description,
          _organization: context === 'owner' ? organizer : getId(_currentOrganization),
          topics: [fields.topics],
        });
      }
    });
  };

  
  const {
    form: { getFieldDecorator }, authUser, conference, context, dateAvailable, intl,
  } = props;

  const overrideAttendees = authUser.isAdmin;

  return (
    <div className={styles.editLivetalkStep1}>
      <Form form={props.form} layout="vertical" onSubmit={handleSubmit}>
        <div className={styles.formTitle}>
          <H4>{intl.formatMessage(messages.subjectTitle)}</H4>
          <p>* Champs obligatoires</p>
        </div>
        <FormItem>
          {
            getFieldDecorator('title', {
              initialValue: conference.title,
              rules: [
                { required: true, message: <FormattedMessage {...messages.mandatory} /> },
                { max: 100, message: <FormattedMessage {...messages.limitCharacter} values={{ count: 100 }} /> },
              ],
            })(
              <InputMaterial max={100} addOnIcon="star" placeholder={intl.formatMessage(messages.conferenceTitleLabel)} label={<FormattedMessage {...messages.conferenceTitleLabel} />} type="text" validations={['required']} />
            )
          }
        </FormItem>
        <FormItem label={<FormattedMessage {...messages.conferenceDescriptionLabel} />} required>
          <WysiwygEditor
            initialValue={conference.description}
            onChange={(val) => typeof val === 'string' ? setDescription(val) : null}
            required
          />

        </FormItem>
        <FormItem>
          {
            getFieldDecorator('maxAttendees', {
              initialValue: conference.maxAttendees,
              rules: [{ required: true, message: <FormattedMessage {...messages.mandatory} /> }],
            })(
              <Select
                addOnIcon="createUser"
                placeholder={<FormattedMessage {...messages.conferenceMaxAttendeesLabel} />}
                label={<FormattedMessage {...messages.conferenceMaxAttendeesLabel} />}
                required
              >
                {
                  range(
                    FORMAT_RESTRICTIONS[conference.format].maxAttendees.min,
                    FORMAT_RESTRICTIONS[conference.format].maxAttendees[overrideAttendees ? 'override' : 'max'] + 1)
                    .map((maxAttendees) => (<Option key={maxAttendees}>{maxAttendees}</Option>))
                }
              </Select>
            )
          }
        </FormItem>

        <FormItem>
          {
            getFieldDecorator('topics', {
              initialValue: head(conference.topics),
              rules: [{ required: true, message: <FormattedMessage {...messages.mandatory} /> }],
            })(
              <Select
                mode="default"
                addOnIcon="tag"
                placeholder={<FormattedMessage {...messages.conferenceTopicsLabel} />}
                label={<FormattedMessage {...messages.conferenceTopicsLabel} />}
                required
              >
                {CONFERENCE_TOPICS
                  .map((topic) => (<Option key={topic}>{intl.formatMessage({ id: `conference.topics.${topic}` })}</Option>))}
              </Select>
            )
          }
        </FormItem>

        <If condition={['owner'].includes(context)}>
          <Organizer
            organization={organization}
            form={props.form}
            conference={conference}
            setConference={props.setConference}
            updateOrg={(orga) => {setOrganization(orga._id); setOrganizer(orga)}}
            handleSelect={(orga) => {setOrganization(orga._id); setOrganizer(orga)}}
          />
        </If>

        <Presenters {...props.form} intl={intl} conference={conference} />

        <BeginAt
          conference={conference}
          intl={intl}
          {...props.form}
          dateAvailable={dateAvailable}
          availableHours={availableHours}
          duration={props.form.getFieldsValue().duration}
          userTimezone={authUser.timezone}
        />

        <br />
        <FormItem>
          {
            getFieldDecorator('duration', {
              initialValue: conference.duration,
              rules: [{ required: true, message: <FormattedMessage {...messages.mandatory} /> }],
            })(
              <Select
                addOnIcon="hourglass"
                placeholder={intl.formatMessage(messages.conferenceDurationLabel)}
                label={<FormattedMessage {...messages.conferenceDurationLabel} />}
                required={<FormattedMessage {...messages.mandatory} />}
              >
                {times(6, (x) => 15 + (x * 15)).map((duration) =>  {
                  const isDisabled = availableHours?.[props.form.getFieldsValue().beginAt]?.[duration] !== true;
                  return (
                    
                    <Option key={duration} disabled={isDisabled}>
                      {isDisabled ?
                        <Tooltip
                          placement="left"
                          overlayClassName={styles.tooltip}
                          color="dark"
                          isVisible
                          title={`${availableHours?.[props.form.getFieldsValue().beginAt]?.[duration]} ${t({id: 'tooltip.duration.booked'})}`}
                        >
                          <div className={styles.disableHour}>
                            <span>{`${duration} minutes`}</span>
                          </div>
                        </Tooltip>
                      : 
                        `${duration} minutes`
                      }
                    </Option>
                )})}
              </Select>
            )
          }
        </FormItem>
        <br />
        <div className={styles.actions}>
          <Button type="submit">
            <FormattedMessage {...messages.next} />
          </Button>
        </div>
      </Form>
    </div>
  );
}

EditLivetalkStep1.propTypes = {
  form: object,
  conference: object,
  authUser: object,
  intl: object,
  organization: object,
  organizer: object,
  dateAvailable: array,
  context: oneOf(['owner', 'nonClient', 'client']),
  handleNext: func
};

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
  isFetching: organizationSelectors.getOrganizationFetching,
  organization: organizationSelectors.getSpecificOrganization,
});

const withConnect = connect(mapStateToProps, null);
const withForm = Form.create();

export default compose(
  withConnect,
  withForm,
  injectIntl,
)(toJS(EditLivetalkStep1));
