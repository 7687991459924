import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { object, func, string } from 'prop-types';
import { isEmpty, set } from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { replace } from 'connected-react-router';
import { scroller } from 'react-scroll';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';
import { Button, Toggle } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { eventActions, eventSelectors } from '@/store/event';
import { authSelectors } from '@/store/auth';
import { currentLocale } from '@/containers/LanguageProvider/selectors';
import { H1 } from '@/components/Title';
import notification from '@/components/Notification';
import MenuItem from './components/MenuItem';
import AddNewContentModal from './components/AddNewContentModal';
import * as ComponentsMenuItem from './components/MenuItemContent';
import styles from './styles.less';
import LanguageSelector from '@/components/LanguageSelector';
import LoadingIndicator from '@/components/LoadingIndicator';
import { Landing } from '@/scenes/Event/containers/Landing';
import Draggable from '@/components/Draggable';

const EditScene = ({
  replace,
  patchEvent,
  match,
  authUser,
  event,
  currentLocale,
}) => {
  const t = useFormatMessage()

  const componentMapping = {
    header: ComponentsMenuItem.Header,
    numbers: ComponentsMenuItem.Default,
    organizations: ComponentsMenuItem.Organizations,
    roadmap: ComponentsMenuItem.Roadmap,
    partners: ComponentsMenuItem.Partners,
    separator: ComponentsMenuItem.Default,
    text: ComponentsMenuItem.Default,
    gallery: ComponentsMenuItem.Gallery,
  };

  const [currentEvent, setCurrentEvent] = useState(event.toJS())
  const [editKey, setEditKey] = useState(0)
  const [menuOpenIndex, setMenuOpenIndex] = useState(0)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [cover, setCover] = useState();
  const [localContent, setLocalContent] = useState([]);
  const user = authUser?.toJS();

  useEffect(() => {
    // TODO: https://app.asana.com/0/1205923566144353/1205923566144480/f part 2 = use !currentEvent.userContext.roles.includes('owner') && !currentEvent.userContext.roles.includes('admin')
    if (currentEvent && !user.isAdmin && isEmpty(currentEvent.planners._organizations.find((organization) => organization._id === user._currentOrganization?._id))) {
      replace(`/${match.params.eventSlug}`);
    }
  }, [])

  const handleOnSortMenuItem = () => {
    setCurrentEvent({
      ...currentEvent, _contents: localContent
        .map(({ contentObject }) => contentObject)
    })
    setEditKey(editKey + 1)
  };

  const handleOnChangeLanding = (event) => {
    setCurrentEvent(event)
  };

  const handleOnChangeMenuItem = (key, value) => {
    const newEvent = { ...currentEvent };

    if (key[key.length - 1] === 'coverPictureUrl') {
      setCover(value)
    }
    setCurrentEvent(set(newEvent, key, value));
    setEditKey(editKey + 1);
  };

  const handleOnDeleteMenuItem = (contentIndex) => {
    const newEvent = { ...currentEvent, _contents: currentEvent._contents.filter((content, index) => index !== contentIndex) };
    setCurrentEvent(newEvent)
    setEditKey(editKey + 1)
  };

  const toggleAddNewContentModal = () => {
    setModalIsOpen(!modalIsOpen)
  };

  const handleSwitchIntl = (checked) => {
    setCurrentEvent({ ...currentEvent, landingIntl: checked })
    handleOnSave(checked)
  }

  const addNewContent = (content) => {
    // Can't add content location for a virtual event
    if ((!event.location?.formattedAdress) && (content.value === 'location')) {
      notification.warning("L'event n'est pas physique");
      return toggleAddNewContentModal();
    }

    const newContents = currentEvent._contents
    newContents.push({
      name: content.value,
      label: content.label,
      content: content.content,
      fr: {
        label: content.fr,
        h1: 'Titre',
        h2: 'Titre du bloc',
        h3: content.fr,
        small: 'Votre texte',
      },
      en: {
        label: content.fr,
        h1: 'Title',
        h2: 'Bloc title',
        h3: content.en,
        small: 'Your text',
      },
    });
    setCurrentEvent({ ...currentEvent, _contents: newContents });
    setEditKey(editKey + 1);
    toggleAddNewContentModal();
  };

  const handleOnSave = (checked = currentEvent.landingIntl) => {

    patchEvent({
      eventId: currentEvent.slug,
      event: {
        theme: currentEvent.theme,
        landingIntl: checked,
        _contents: currentEvent._contents
      },
      notificationParams: {
        success: {
          message: t({ id: 'notifications.update.success' }),
          kind: 'info',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        },
        error: true,
      },
    });
  };


  const handleOnClickMenuItem = (name, index, isOpen) => {
    const offset = (50 + (120 * index)) * -1;

    if (!isOpen) {
      return;
    }

    scroller.scrollTo(name, {
      duration: 1000,
      delay: 0,
      offset,
      smooth: true,
      isDynamic: true,
      containerId: 'LandingID',
    });

    setMenuOpenIndex(index)
  };

  const moveItem = (fromIndex, toIndex) => {
    const newArray = [...localContent];
    const elemToMove = newArray.splice(fromIndex, 1);

    newArray.splice(toIndex, 0, ...elemToMove);

    setLocalContent(newArray);
  }

  useEffect(() => {
    if (event.size <= 0) {
      return null;
    }

    const items = currentEvent._contents
      .map((content, index) => {
        const Component = componentMapping[content.name];

        const menuItemContent = Component ? <MenuItem
          index={index}
          name={`content_${index}`}
          locale={currentLocale}
          title={content.label || 'Accueil'}
          isOpen={index === menuOpenIndex}
          onClick={handleOnClickMenuItem}
          content={<Component
            event={currentEvent}
            contentIndex={index}
            onChange={handleOnChangeMenuItem}
            onDelete={handleOnDeleteMenuItem}
            locale={currentLocale}
          />}
        /> : null;

        return {
          contentObject: content,
          content: menuItemContent,
          index
        }
      }, []);
    setLocalContent(items);
  }, [editKey]);

  if (!currentEvent || isEmpty(currentEvent)) {
    return (<LoadingIndicator />);
  }

  return (
    <div className={styles.editEventContainer}>
      <div className={styles.header}>
        <div>
          <H1 className={styles.h1}>{currentEvent.name}</H1>
        </div>

        <div className={styles.buttons}>
          <div className={styles.switch}>
            <label htmlFor="mainColor">Multilangue</label>
            <Toggle
              size="small"
              checked={currentEvent.landingIntl}
              onChange={(checked) => handleSwitchIntl(checked)}
            />
          </div>

          <LanguageSelector />
          <Button color="success" onClick={() => handleOnSave()}>{t({ id: 'save' })}</Button>
          <Link to={`/${match.params.eventSlug}`} target="_blank" >
            <Button>{t({ id: 'preview' })}</Button>
          </Link>
        </div>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.menuContainer}>
          <div className={styles.modulesContainers}>
            <Draggable
              items={localContent}
              moveItem={moveItem}
              onDragEnd={handleOnSortMenuItem}
              itemType='edit'
            />
          </div>
          <div className={styles.menuBottomContainer}>
            <AddNewContentModal isOpen={modalIsOpen} onCancel={toggleAddNewContentModal} onOk={addNewContent} />
            <Button onClick={toggleAddNewContentModal}>
              {t({ id: 'event.edit.addBlock' })}
            </Button>
          </div>
        </div>
        <div className={styles.landingContainer} id="LandingID">
          <Landing
            event={currentEvent}
            editMode
            key={`landing${editKey}`}
            match={match}
            onChange={(newEvent) => handleOnChangeLanding(newEvent)}
            cover={cover}
            currentLocale={currentLocale}
          />
        </div>
      </div>
    </div>
  );
}

EditScene.propTypes = {
  replace: func,
  patchEvent: func,
  match: object.isRequired,
  authUser: object,
  event: object,
  currentLocale: string,
}

const mapStateToProps = createStructuredSelector({
  event: eventSelectors.getCurrentEvent,
  authUser: authSelectors.getAuthUser,
  currentLocale,
});

const mapDispatchToProps = {
  getEvent: eventActions.getEvent,
  patchEvent: eventActions.patchEvent,
  replace,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(injectIntl(EditScene));
