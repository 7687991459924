import React, { useState, useEffect } from 'react';
import { object, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { toString, forEach, size, get, isEmpty, filter } from 'lodash';
import { createStructuredSelector } from 'reselect';

import { Button } from '@seekube-tech/ui-kit';
import { useGetExponentsByOrganization } from '@/queries/exponent/useGetExponentsByOrganization';
import { useGetOrganizationUserOffers } from '@/queries/offers/useGetOrganizationUserOffers';
import { organizationSelectors } from '@/store/organization';
import { offerSelectors } from '@/store/offer';
import { authSelectors } from '@/store/auth';
import { getId, getIds } from '@/utils/global';
import { queryStringToObject } from '@/utils/url';
import { toJS } from '@/utils';


import { withAuth } from '@/containers/RequiredAuth';
import SplitViewsComponent from '@/components/SplitView';
import Modal from '@/components/ModalV2';
import LoadingIndicator from '@/components/LoadingIndicator';
import { CancelModal as BackModal } from './components/CancelModal';
import { CancelModal } from '../EditOffer/components';
import { Steps, Step1, Step2, Step3, Step4 } from './components';

import styles from '../EditOffer/styles.less';


function OffersSpreading(
  {
    intl,
    match,
    history,
    location,
    authToken,
    authUser,
    step,
}) {
  const { state: { checkedOffers, isBtnAllOffersActive } } = location;
  const { status } = queryStringToObject(location.search);

  const [eventSelected, setEventSelected] = useState(null);
  const [currentStep, setCurrentStep] = useState(toString(queryStringToObject(location.search).step));
  const [publishModalVisible, setPublishModalVisible] = useState(false);
  const [closeModalVisible, setCloseModalVisible] = useState(false);
  const [backModalVisible, setBackModalVisible] = useState(false);
  const [offersSelected, setOffersSelected] = useState(filter(checkedOffers, (off) => !(isEmpty(off.description) || isEmpty(off.profileDescription))));
  const [offersToPost, setOffersToPost] = useState([]);

  function countOffersWithoutDescription() {
    return size(filter(checkedOffers, (off) => (isEmpty(off.description) || isEmpty(off.profileDescription))));
  }
  const offersSelectedSize = size(offersSelected);


  const exponents = useGetExponentsByOrganization({ organizationId: getId(authUser._currentOrganization), page: 1, offset: 0, limit: 100, archive: false, authToken });
  const exponentsQuery = isBtnAllOffersActive && useGetOrganizationUserOffers({ initialData: offersSelected, authUser, search: { page: 1, offset: 0, limit: 1000 }, authToken, status });


  useEffect(() => {
    if (isBtnAllOffersActive && exponentsQuery.isFetching && exponentsQuery.isSuccess) {
      setOffersSelected(filter(exponentsQuery.data.docs, (off) => !(isEmpty(off.description) || isEmpty(off.profileDescription))));
    }
  }, [exponentsQuery.isFetching]);

  useEffect(() => {
    setCurrentStep(toString(parseInt(step, 10)));
  }, [step]);

  useEffect(() => {
    setCurrentStep(toString(parseInt(step, 10)));
  }, [eventSelected]);


  function cleanLocalStorage() {
    return forEach(['eventSelected', 'offersSelected', 'offers'],
      (i) => window.localStorage.removeItem(i)
    );
  }

  function getLightOffers(offers) {
    return offers.map((off) => ({ _id: getId(off), matching: off.matching, _user: getId(off._user) }));
  }

  const renderStep = () => {
    if (currentStep === '1') { return <Step1 handleActions={handleActions} offers={offersSelected} saveEventSelected={setEventSelected} handleNext={handleNext} exponents={exponents.data.docs} saveOffer={() => {}} setOffer={() => {}} defaultTargets={['tech', 'business', 'engineer']} offersWithoutDescriptionSize={countOffersWithoutDescription()} />; }
    if (currentStep === '2') { return <Step2 eventContracts={eventSelected?._criteria?.filter(({ enable, key }) => enable && key === 'CONTRACT')[0]?._choices.filter(({ enable }) => enable)} handleActions={handleActions} offers={offersSelected} handleNext={(newOffers) => { handleNext(); setOffersSelected(newOffers); }} exponents={exponents.data.docs} />; }
    if (currentStep === '3') { return <Step3 handleActions={handleActions} offers={offersSelected} exponents={exponents.data.docs} handleNext={(offers) => { setOffersSelected(offers); setPublishModalVisible(true); setOffersToPost(getLightOffers(offers)); }} />; }
    if (currentStep === '4') { return <Step4 offers={offersToPost} offersSelectedSize={offersSelectedSize} />; }

    return <Step1 handleActions={handleActions} saveEventSelected={setEventSelected} handleNext={handleNext} exponents={exponents.data.docs} saveOffer={() => {}} setOffer={() => {}} defaultTargets={['tech', 'business', 'engineer']} />;
  };

  const handleActions = (actionName, offer) => {
    if (actionName === 'openBackModal') {
      onClickBackModal();
    }

    if (actionName === 'setOffersSelected') {
      const newOffersSelected = filter(offersSelected, (off) => getId(off) !== getId(offer));

      setOffersSelected(newOffersSelected);
      window.localStorage.setItem('offersSelected', JSON.stringify(getIds(newOffersSelected)));
    }
  };


  const pushToStep = (step) => {
    history.push(`${match.path}?step=${parseInt(step, 10)}`, { checkedOffers: offersToPost, isBtnAllOffersActive });
  };

  const handleNext = (newOffers) => {
    history.push(`${match.path}?step=${parseInt(currentStep, 10) + 1}`, { checkedOffers: newOffers, isBtnAllOffersActive });
  };


  const backToOffers = () => {
    history.push('/client/offers');
    cleanLocalStorage();
  };

  const onValidBackModal = () => {
    backToOffers();
    setBackModalVisible(false);
  };

  const onClickBackModal = () => {
    if (isEmpty(eventSelected)) {
      backToOffers();
    } else {
      setBackModalVisible(true);
    }
  };

  const onValidCancelModal = () => {
    setCloseModalVisible(false);
    backToOffers();
  };

  const onCloseOffersSpreading = () => {
    if (currentStep === '4') {
      backToOffers();
    } else {
      setCloseModalVisible(true);
    }
  };

  return (
    <div>
      <div id="editOfferContainer" className={styles.editOfferContainer}>
        <SplitViewsComponent
          classModalContainer={styles.editOfferSplitContainer}
          handleCloseAppointment={onCloseOffersSpreading}
          leftContent={(!exponents.isFetching && exponents.isSuccess) ? renderStep() : <LoadingIndicator />}
          rightContent={<Steps pushToStep={pushToStep} />}
        />
      </div>
      <BackModal intl={intl} count={offersSelectedSize} event={get(eventSelected, 'name')} onOk={onValidBackModal} onCancel={() => setBackModalVisible(false)} visible={backModalVisible} />
      <CancelModal intl={intl} count={offersSelectedSize} offer={{ _id: null }} onOk={onValidCancelModal} onCancel={() => setCloseModalVisible(false)} visible={closeModalVisible} />
      <Modal visible={publishModalVisible} className={styles.publishModal} template="default" onClose={() => setPublishModalVisible(false)}>
        <div className={styles.modalHeader}>
          <FormattedMessage id="recruiter.offers.spread.publish.offers.modal.title" values={{ count: offersSelectedSize }} tagName="h3" />
        </div>
        <div className={styles.modalContent}>
          <FormattedMessage id="recruiter.offers.spread.publish.offers.modal.content1" values={{ count: offersSelectedSize, event: get(eventSelected, 'name') }} tagName="p" />
        </div>
        <div className="confirm-actions" style={{ justifyContent: 'flex-start' }}>
          <Button
            className="mr-6"
            onClick={() => {
              handleNext();
              setPublishModalVisible(false);
            }}
          >
            {intl.formatMessage({ id: 'confirm' })}
          </Button>
          <Button variant="tonal" onClick={() => setPublishModalVisible(false)}>
            {intl.formatMessage({ id: 'cancel' })}
          </Button>
        </div>
      </Modal>
    </div>
  );
}

OffersSpreading.propTypes = {
  intl: object,
  history: object,
  match: object,
  authUser: object,
  step: string,
  authToken: string,
  location: object,
};

const mapStateToProps = createStructuredSelector({
  organization: organizationSelectors.getCurrentOrganization,
  offers: offerSelectors.getOffers,
  authUser: authSelectors.getAuthUser,
  authToken: authSelectors.getAuthToken,
});

const withConnect = connect(mapStateToProps, null);

export default compose(
  withRouter,
  withAuth(),
  withConnect,
  injectIntl,
)(toJS(OffersSpreading));
