import moment from 'moment-business-days';

/**
 * Get Numbers of days between two dates
 *
 * @param {string} beginAt
 * @param {string} endAt
 */
export const getDaysNumbersBetweenTwoDates = (beginAt, endAt) =>
  Math.abs(moment(beginAt).diff(moment(endAt), 'days')) + 1;

/**
 * Return an array with all days or business days between two dates
 *
 * @param {string} beginAt
 * @param {string} endAt
 * @param {boolean} onlyBusinessDay
 */
export const getDatesBetweenTwoDates = (beginAt, endAt, onlyBusinessDay) => Array
  .from(new Array(getDaysNumbersBetweenTwoDates(beginAt, endAt)))
  .reduce((acc, date, index) => {
    const nextDate = moment(beginAt).add(index, 'days');

    if (onlyBusinessDay) {
      if (nextDate.isBusinessDay()) {
        acc.push(nextDate);
      }
    } else {
      acc.push(nextDate);
    }

    return acc;
  }, []);

/**
 * Return an object with all dates or business dates for each week
 *
 * @param {Array} weeks
 * @param {boolean} onlyBusinessDay
 */
export const getDatesByWeek = (weeks, onlyBusinessDay, locale = 'fr') => {
  moment.locale(locale);

  return weeks
    .reduce((acc, week, index) => {
      const dates = getDatesBetweenTwoDates(week.beginAt, week.endAt, onlyBusinessDay);

      acc[index] = dates;

      return acc;
    }, {});
};
