import React, { useState } from 'react';
import {
  Button,
  IconLink,
  IconFile,
  IconUpload, IlluDocSuccess, IlluDocError, IconX, IconTrash2
} from '@seekube-tech/ui-kit';
import { object, func, number } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import FormItem from 'antd/lib/form/FormItem';
import { isEmpty } from 'lodash';
import InputMaterial from '@/components/Input/InputMaterial';
import { getAuthToken } from '@/store/auth/selectors';
import { DragAndDropFile } from '@/components/DragAndDropFile';
import styles from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Profile/Cv/styles.less';
import { Mandatory } from '@/components/Form';
import { getUserMediaUrl } from '@/helpers/resume';
import { useIsMobile } from '@/utils/MediaQueries';

export function Media ({ form, user, onClick, order, media, handleRemove }) {
  const authToken = useSelector(getAuthToken);
  const t = useFormatMessage();
  const mediaFieldDecorator= `mediaItems[${order}]`;
  const isWiderThanMobile = !useIsMobile();
  const mediaIlluSize = isWiderThanMobile ? 100 : 60;
  const [mediaFile, setMediaFile] = useState({
    s3Key: media.fileKey,
    fileName: media.fileName,
    title: media.title,
  })

  const [hasError, setHasError] = useState(false);

  const onFileUploaded = ({ s3Key, fileName }) => {
    // If there is no value in inputTitle by default we populate it with fileName
    const title = isEmpty(media.title) ? fileName : media.title;

    const values = {
      [`${mediaFieldDecorator}.fileKey`]: s3Key,
      [`${mediaFieldDecorator}.fileName`]: fileName,
      [`${mediaFieldDecorator}.title`]: title,
    };

    form.setFieldsValue(values);

    setMediaFile({
      s3Key,
      fileName,
      title,
    })
    setHasError(false);
  };

  const onFileUploadedError = () => {
    form.setFieldsValue({ [`${mediaFieldDecorator}.fileKey`]: undefined, [`${mediaFieldDecorator}.fileName`]: undefined });
    setHasError(true);
  };

  const onFileRemove = (e) => {
    e.preventDefault();

    form.setFieldsValue({
      [`${mediaFieldDecorator}.fileKey`]: undefined,
      [`${mediaFieldDecorator}.fileName`]: null
    })

    setHasError(false);
  };

  return (
    <div className={classnames('flex mb-24', { 'flex-col': !isWiderThanMobile })}>
      <div className="mr-24 flex-1">
        <FormItem>
          {form.getFieldDecorator(`${mediaFieldDecorator}.title`, {
            initialValue: media.title,
            rules: [
              {
                required: true,
                message: t({ id: 'event.candidate.signup.identity.form.mediaItem.title.error.mandatory' }),
              }],
          })(
            <InputMaterial
              addOnIcon={<IconFile size={24}/>}
              label={t({ id: 'document.title' })}
              placeholder={t({ id: 'document.title' })}
              type="text"
              validations={['required']}
            />,
          )}
        </FormItem>
        <>
          {form.getFieldDecorator(`${mediaFieldDecorator}.fileKey`, {
            initialValue: media.fileKey,
            rules: [{ required: false, message: t({ id: 'form.resume.mandatory' }) }],
          })(
            <input type="hidden" />
          )}
          {form.getFieldDecorator(`${mediaFieldDecorator}.fileName`, {
            initialValue: media?.fileName || '',
            rules: [{ required: false, message: ' ' }],
          })(
            <input type="hidden" />
          )}
        </>

        <DragAndDropFile
          onClick={onClick}
          user={user}
          formatAccepted=".pdf,.png,.jpg"
          onFileUploaded={onFileUploaded}
          onError={onFileUploadedError}
          type="media"
        >
          <div className="flex items-center">
            <div className={classnames(styles.dragContainer, { [styles.uploaded]: mediaFile.s3Key })}>
              {!mediaFile.s3Key && !hasError && <IconUpload className={styles.iconUpload} />}
              {mediaFile.s3Key && <IlluDocSuccess size={mediaIlluSize} className="uploaded-icon white" />}
              {hasError && <IlluDocError size={mediaIlluSize} className="uploaded-icon" />}
            </div>
            <div>
              <Button size="small" className="cvBtn" variant="outline">
                <Mandatory visible={false}>
                  {!mediaFile.s3Key ? t({ id: 'form.file.import' }) : t({ id: 'form.file.update' })}
                </Mandatory>
              </Button>
            </div>
          </div>
        </DragAndDropFile>
        {mediaFile.fileName &&
          <div className={styles.cvLink}>
            <a href={getUserMediaUrl(authToken, mediaFile.s3Key)} className="flex items-center mr-5" target="_blank">
              <IconLink size={12} className="mr-5" />
              <span className={styles.fileName} title={mediaFile.fileName}>{mediaFile.fileName}</span>
              <Button variant="tonal" color="error" size="small" onClick={onFileRemove} role="button" tabIndex={0}>
                <IconX size={12} />
              </Button>
            </a>
          </div>
        }
      </div>
      <div className={classnames('self-end', { 'self-center': isWiderThanMobile })}>
        <Button
          color="error"
          variant="tonal"
          imageComponentLeft={<IconTrash2 size={16}/>}
          onClick={() => handleRemove(order)}
        >
          {t({ id: 'delete' })}
        </Button>
      </div>
    </div>
  )
}

Media.propTypes = {
  form: object,
  user: object,
  onClick: func,
  order: number,
  media: object,
  handleRemove: func,
}
