import moment from 'moment';
import { getId } from '@/utils/global';
import { getSlotsBoundedByEvent } from './getSlotsBoundedByEvent';

/**
 * CreateCalendarEvents
 *
 * @description
 * Create all timeslots and convert it in big calendar format
 *
 */
export const createTimeSlots = ({
  slots = [],
  currentEvent,
  isSaved = false,
}) => getSlotsBoundedByEvent(slots, currentEvent)
    .reduce((acc, slot, index) => {
      const lastItemInTheGroup = slot[slot.length - 1];

      return acc.concat([
        {
          start: moment(slot[0].beginAt).toDate(),
          end: moment(lastItemInTheGroup.beginAt).add(lastItemInTheGroup.duration, 'minutes').toDate(),
          groupIndex: index, // Use when we want to delete a timeslot
          medium: slot[0].medium,
          _event: getId(slot[0]._event) || null,
          eventName: slot[0]._event?.name || null,
          location: slot[0].location,
          duration: slot[0].duration,
          _appointment: slot[0]._appointment || null,
          _conference: slot[0]._conference || null,
          type: slot[0].type || null,
          isSaved,
          informalDescription: slot[0].informalDescription || null,
          _id: slot[0]._id || null,
        },
      ]);
    }, []);
