import React, { useRef } from 'react';
import { oneOf } from 'prop-types';
import { useGetSignedAwsUrl } from '@/queries/services/useGetSignedAwsUrl';
import { useUploadFileToAws } from '@/queries/services/useUploadwFileToAws';
import { useUploadResume } from '@/queries/services/useUploadResume';
import generateFileName from '@/forms/Stand/utils/generateFileName';
import styles from '@/components/DragAndDropFile/styles.less';
import { useUploadMedia } from '@/queries/services/useUploadMedia';

const TYPES = {
  cv: 'cv',
  media: 'media',
}

export const DragAndDropFile = ({
  user,
  children,
  onClick,
  onFileUploaded = (file) => {},
  handleUploadStatus,
  onError = () => {},
  isDraggable = true,
  formatAccepted = '',
  type = null
}) => {
  const [dragActive, setDragActive] = React.useState(false);
  const inputRef = useRef(null);

  const mutateUploadFileToAws = useUploadFileToAws({
    onSuccess: (_, mutationArgs) => {
      const { signedRequest, url, fileUploaded, file } = mutationArgs;

      onFileUploaded({ signedRequest, url, fileUploaded, file });
    },
    onError
  })

  const mutateGetSignedAwsUrl = useGetSignedAwsUrl({
    user,
    onSuccess: ({ signedRequest, url, s3Key }, { file, fileUploaded }) => {
      mutateUploadFileToAws.mutate({ signedRequest, url, fileUploaded, file, s3Key })
    }
  })

  const mutateUploadResume = useUploadResume({
    enabled: type === TYPES.cv,
    onSuccess: (res, _) => {
      onFileUploaded(res);
    },
  })

  const mutateUploadMedia = useUploadMedia({
    enabled: type === TYPES.media,
    onSuccess: (res, _) => {
      onFileUploaded(res);
    },
  })

  const handleChange = (e) => {
    if (e.target.files) {
      switch (type) {
        case TYPES.cv:
          mutateUploadResume.mutate({ file: e.target.files[0] } ); break;
        case TYPES.media:
          mutateUploadMedia.mutate({ file: e.target.files[0] } ); break;
        default:
          handleFile(e.target.files[0]);
      }
    }
  }

  const handleFile = (fileUploaded) => {
    if (fileUploaded) {
      const file = generateFileName(fileUploaded, user);

      mutateGetSignedAwsUrl.mutate({ file, fileUploaded } );
    }
  }

  const handleDrop = function(e) {
    e.preventDefault();
    e.stopPropagation();

    setDragActive(false);

    if (e.dataTransfer?.files?.[0]) {
      switch (type) {
        case TYPES.cv:
          mutateUploadResume.mutate({ file: e.dataTransfer.files[0] } ); break;
        case TYPES.media:
          mutateUploadMedia.mutate({ file: e.dataTransfer.files[0] } ); break;
        default:
          handleFile(e.dataTransfer.files[0]);
      }
    }
  }

  const handleDrag = function(e) {
    if (isDraggable) {
      e.preventDefault();
      e.stopPropagation();

      if (e.type === "dragenter" || e.type === "dragover") {
        setDragActive(true);
      } else if (e.type === "dragleave") {
        setDragActive(false);
      }
    }
  };

  return (
    <form className="relative" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
      <input type="file" onClick={onClick} onChange={handleChange} accept={formatAccepted} ref={inputRef} className="hide" />
      <div className="inline-block" onClick={() => inputRef.current?.click()}>
        {children}
      </div>
      { dragActive &&
        <div className={styles.dragFileElement} onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop} />
      }
    </form>
  )
}

DragAndDropFile.propTypes = {
  type: oneOf([TYPES.cv, TYPES.media, null])
}
