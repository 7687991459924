import React, { PureComponent } from 'react';
import { array, func, number, object } from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { replace } from 'connected-react-router';
import { isEmpty, pick, size } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { toJS } from '@/utils/index';

// Store
import { actionActions } from '@/store/action';
import { authSelectors } from '@/store/auth';
import { eventActions, eventSelectors } from '@/store/event';
import { appActions, appSelectors } from '@/store/app';
import { criterionSelectors } from '@/store/criterion';

import LoadingIndicator from '@/components/LoadingIndicator';
import { If } from '@/components/If';
import { Header } from '../../../Event/containers/Landing/components/Contents/components/GeneralInfos/components/LandingContent/components';
import EventInformation from './components/EventInformation';
import LocationMap from './components/LocationMap';
import { getId } from '@/utils/global';
import { trackGtmEvent } from "@/utils/pixels/helloWork";
import { Redirect } from 'react-router-dom';

class SeekalendarPage extends PureComponent {
  static propTypes = {
    authUser: object,
    event: object,
    defaultCriteria: array,
    match: object,
    getEvent: func,
    postAction: func,
    getPublicAppointments: func,
    countAppointments: number,
    fetchError: object
  }

  componentDidMount() {
    const { match: { params: { eventSlug } }, authUser, postAction, getEvent, getPublicAppointments } = this.props;

    getPublicAppointments();
    getEvent(eventSlug);

    trackGtmEvent('page.display', {
      'event': 'generic',
      'user_id_back': authUser?._id || '',
      'path': location.pathname,
      'jobdating_name': eventSlug,
    });

    if (!authUser.isAdmin) {
      postAction({
        actionParams: {
          name: 'VISIT_SEEKALENDAR_ITEM',
          _user: getId(authUser),
          _organization: getId(authUser._currentOrganization),
          eventSlug,
        },
      });
    }
  }

  handleDisplayContact = () => {
    const { postAction, authUser, event } = this.props;

    if (!authUser.isAdmin) {
      postAction({
        actionParams: {
          name: 'DISPLAY_SEEKALENDAR_CONTACT',
          _user: getId(authUser),
          _organization: getId(authUser._currentOrganization),
          _event: getId(event),
        },
      });
    }
  }

  mapTarget = (choices, values, locale) => {
    const items = [];

    choices.forEach((item) => {
      if (values.includes(item._id.toString())) {
        if (locale === 'en' && !isEmpty(item.label_en)) {
          items.push(item.label_en);
        } else if (locale === 'fr') {
          items.push(item.label);
        }
      }
    });

    return items;
  }

  computeTargets = ({ targetContracts, targetPositions, targetLevels }) => {
    const { defaultCriteria, authUser } = this.props;
    const defaultContracts = defaultCriteria.find((c) => c.key === 'CONTRACT');
    const defaultPositions = defaultCriteria.find((c) => c.key === 'POSITION');
    const defaultLevels = defaultCriteria.find((c) => c.key === 'LEVEL');

    return {
      contracts: this.mapTarget(defaultContracts?._choices || [], targetContracts, authUser.locale),
      positions: this.mapTarget(defaultPositions?._choices || [], targetPositions, authUser.locale),
      levels: this.mapTarget(defaultLevels?._choices || [], targetLevels, authUser.locale),
    };
  }

  render() {
    const { event, fetchError } = this.props;
    const { handleDisplayContact } = this;

    if (fetchError?.code === 404) {
      return <Redirect to="/404" />
    }

    if (!event) {
      return (<LoadingIndicator />);
    }

    const eventParams = pick(event, ['keyDates', 'location', 'targets', 'pricing', 'location', 'format', 'contacts', 'externalUrl']);
    const otherTargets = this.computeTargets(event);

    return (<div>
      <Header
        context="seekalendar"
        event={event}
        contentIndex={0}
        editMode={false}
      />

      <EventInformation
        {...eventParams}
        {...otherTargets}
        countOrganizations={size(event.planners._organizations)}
        description={event.ownerDescription}
        onDisplayContact={handleDisplayContact}
      />

      <If condition={event.format === 'physical' && !isEmpty(event.location)}>
        <LocationMap
          location={event.location}
          containerStyle={{ width: '100%', height: '50vh' }}
        />
      </If>
    </div>);
  }
}

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
  event: eventSelectors.getCurrentEvent,
  defaultCriteria: criterionSelectors.getDefaultCriteria,
  fetchError: appSelectors.getFetchError,
});

const mapDispatchToProps = {
  postAction: actionActions.postAction,
  getPublicAppointments: appActions.getPublicAppointments,
  getEvent: eventActions.getEvent,
  replace,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(toJS(SeekalendarPage));
