import React from 'react';
import { get, head } from 'lodash';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { compose } from 'redux';
import { func, object, any } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { toJS } from '@/utils';
import { getUtcFormat } from '@/utils/format';

import { authActions, authSelectors } from '@/store/auth';
import { appActions } from '@/store/app';

import messages from './messages';


const SetTimezone = ({ closeCalendar, push, authUser, className }) => (
  <div className={className}>
    <p className="help">
      {getUtcFormat(head(get(authUser, 'timezone.utc')), get(authUser, 'timezone.text'))}
      <a className="ml-4" role="button" tabIndex={0} onClick={() => { closeCalendar(); push('?settings=timezone'); }}>
        <FormattedMessage {...messages.change} />
      </a>
    </p>
  </div>
);

SetTimezone.propTypes = {
  authUser: object,
  closeCalendar: func,
  push: func,
  className: any,
};

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
});

const mapDispatchToProps = {
  toggleSettings: authActions.toggleSettings,
  closeCalendar: appActions.closeCalendar,
  push,
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, toJS)(SetTimezone);
