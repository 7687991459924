import { isEmpty } from 'lodash';
import { useLocation } from 'react-router-dom';
import { queryStringToObject } from '@/utils/url';
import { useGetPublicEvents } from '@/queries/events/useGetPublicEvents';
import { useInfiniteGetPublicEventsSuggestions } from '@/queries/events/useInfiniteGetPublicEventsSuggestions';

const useEventsSearchEngine = (filters) => {
  const location = useLocation();
  const search = queryStringToObject(location.search);
  const contracts = ((typeof search.contracts) !== 'object' && !isEmpty(search.contracts) ? [search.contracts] : search.contracts || []);
  const localisations = ((typeof search.localisations) !== 'object' && !isEmpty(search.localisations) ? [search.localisations] : search.localisations || []);
  
  const events = useGetPublicEvents({
    filters: filters || {
      filters: contracts,
      MOBILITY: localisations,
      keywords: search.keywords,
      facetKey: search.facetKey
    }
  });
  
  const suggestedEvents = useInfiniteGetPublicEventsSuggestions({
    enabled: events.isFetched && !events.data?.pages?.[0]?.total
  });
  const hasFoundEvents = !events.isLoading && events.data?.pages?.[0]?.total > 0;
  const shouldShowNoResults = !suggestedEvents.isFetching && !hasFoundEvents && !isEmpty(events.data);
    
  return {
    events,
    suggestedEvents,
    hasFoundEvents,
    shouldShowNoResults,
    contracts,
    localisations,
    search
  };
}

export default useEventsSearchEngine;