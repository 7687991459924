import React from 'react';
import { object } from 'prop-types';
import EventsList from '../../container/EventsList';
import { Explore } from '@/scenes/Events/scenes/Explore';

const ArchiveEvents = ({ authUser, ...props }) => (
  <Explore authUser={authUser}>
    <EventsList archive authUser={authUser} {...props} />
  </Explore>
);

ArchiveEvents.propTypes = {
  authUser: object,
};

export { ArchiveEvents };
