import { useQuery } from 'react-query';
import request from '@/utils/request';

export function useGetKpiSessions({ authToken, ...props }) {
  const requestUrl = `${process.env.FRONT_GO_API_URL}/stats/kpi/sessions`;
  const queryFn = () => request(requestUrl, { method: 'GET' });

  const query = useQuery({
    queryKey: ['kpiSessions'],
    queryFn,
    refetchOnWindowFocus: false,
    ...props,
  });
  return query;
}
