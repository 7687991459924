import React from 'react';
import { func, object, array, bool, string } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { Select as SelectAntd } from 'antd';
import { getId } from '@/utils/global';
import Select from '@/components/Form/Select';
import Icon from '@/components/Icon';
import TagWithAvatar from '@/components/Tag/TagWithAvatar';
import styles from '../../styles.less';
import { EVENT_FORMAT } from '@/utils/constants';

const { Option } = SelectAntd;

const SelectRecruiter = ({
  isDisabled,
  hasInteractionWithCollaborator,
  onRecruiterSelected,
  onAddSlots,
  currentUserInExponent,
  interactions,
  exponent,
  candidateId,
  participant,
  hasRecruiterAvailableSlots,
  event,
}) => {
  const t = useFormatMessage();

  const handleSelectRecruiter = (value) => {
    const newCurrentUserInExponent = exponent.users.filter((item) => item && item._user && item._user._id === value)[0];

    onRecruiterSelected(newCurrentUserInExponent);
  };

  const recruiterOptions = () => (
    exponent.users
      .filter((item) => item && item._user && item._user.isActive)
      .sort((a, b) => a._user.fullName.localeCompare(b._user.fullName))
      .map(recruiterOption)
  );

  const recruiterOption = (exponentUser) => (
      <Option title={exponentUser._user.fullName} key={exponentUser._user._id} value={exponentUser._user._id} disabled={interactions.findIndex((int) => getId(int._recruiter) === getId(exponentUser._user) && getId(int._candidate) === candidateId) > -1}>
        <TagWithAvatar src={exponentUser._user.pictureUrl ? exponentUser._user.pictureUrl : ''} user={exponentUser._user} name={exponentUser._user.fullName} />
      </Option>
    );

  return (
    <>
      <Select
        disabled={isDisabled}
        hasError={!hasRecruiterAvailableSlots}
        required
        placeholder={t({id: 'event.recruiter.appointmentModal.inCharge'})}
        showSearch
        addOnIcon="user"
        value={currentUserInExponent ? currentUserInExponent._user._id : null}
        onSelect={handleSelectRecruiter}
        suffixIcon={<Icon name="chevron" />}
        optionFilterProp="children"
        helpOption={hasInteractionWithCollaborator ? <span className={styles.error}>{t({id: 'event.recruiter.appointmentModal.collaboratorWithInteractionError'})}</span> : null}
        filterOption={(input, option) => option.props.title && option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        {recruiterOptions()}
      </Select>
      {!hasRecruiterAvailableSlots && ((event.format === EVENT_FORMAT.HYBRID && participant.keyMomentFormats.length > 1) || event.format !== EVENT_FORMAT.HYBRID) ? (
        <span className={styles.error}>{t({id: 'event.recruiter.appointmentModal.noSlotError'})} <a role="button" tabIndex={0} onClick={() => onAddSlots()}>{t({id: 'event.recruiter.appointmentModal.addSlot'})}</a></span>
      ) : <span>&nbsp;</span>}

      {!hasRecruiterAvailableSlots && (event.format === EVENT_FORMAT.HYBRID && participant.keyMomentFormats.length === 1) && (
        <span className={styles.error}>{t({ id: 'event.recruiter.appointmentModal.noSlotError.one.format' }, {format: participant.keyMomentFormats[0]})} <a role="button" tabIndex={0} onClick={() => handleOpenCalendar()}>{t({ id: 'event.recruiter.appointmentModal.addSlot.one.format' }, {format: participant.keyMomentFormats[0]})}</a></span>
      )}
    </>
  );
}

SelectRecruiter.propTypes = {
  isDisabled: bool,
  hasInteractionWithCollaborator: bool,
  hasRecruiterAvailableSlots: bool,
  onRecruiterSelected: func,
  onAddSlots: func,
  currentUserInExponent: object,
  interactions: array,
  exponent: object,
  candidateId: string,
  participant: object,
  event: object,
};

export default SelectRecruiter;
