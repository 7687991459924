import React from 'react';
import { number, string, oneOf, object, any } from 'prop-types';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import 'moment-business-days';
import 'moment-countdown';
import classNames from 'classnames';

// Components
import { If, Then, Else } from 'react-if';
import CircularProgressbar from '@/components/CircularProgressBar';
import Icon from '@/components/Icon';

// Styles & Translations
import styles from './styles.less';
import messages from './messages';

/**
 * Countdown
 */
class Countdown extends React.PureComponent { // eslint-disable-line
  static COUNTDOWN_NOT_STARTED = 1;
  static COUNTDOWN_STARTED = 2;

  static propTypes = {
    intl: object,
    icon: string,
    targetDate: any,
    interval: number,
    startDelay: number,
    type: oneOf(['circular', 'inline', 'flat']),
  };

  static defaultProps = {
    interval: 1000,
    startDelay: 0,
    timeSeparator: ' ',
    leadingZero: false,
    type: 'circular',
    icon: 'hourglass',
  };

  /**
   * State
   */
  state = {
    remainingTime: 0,
    status: Countdown.COUNTDOWN_NOT_STARTED,
    intervalId: null,
  };

  /**
   * Start timeout
   */
  componentDidMount() {
    setTimeout(() => {
      const timer = setInterval(() => {
        this.tick();
      }, this.props.interval);

      this.setState({
        status: Countdown.COUNTDOWN_STARTED,
        intervalId: timer,
      });

      this.tick();
    }, this.props.startDelay);
  }

  /**
   * Clear timeout
   */
  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  /**
   * Get countdown active item
   */
  getActiveItem(remainingTimeObject) {
    if (remainingTimeObject.hours === 0 && remainingTimeObject.minutes > 0) {
      return 'minute';
    } else if (remainingTimeObject.hours === 0 && remainingTimeObject.seconds >= 0) {
      return 'second';
    }

    return 'day';
  }


  calculateRemainingTime() {
    const targetDate = moment(this.props.targetDate);
    const now = moment();

    if (targetDate < now) {
      return 0;
    }

    const remainingTime = now.diff(targetDate);

    if (remainingTime <= 0) {
      return -remainingTime;
    }

    return remainingTime;
  }


  tick() {
    this.setState({
      remainingTime: this.calculateRemainingTime(),
    });
  }


  padNumber(n) {
    return (n < 10) ? (`0${n}`) : n;
  }


  render() {
    const {
      props: { type, icon, intl },
      state: { remainingTime },
    } = this;

    const remainingDuration = moment.duration(remainingTime);

    const remaining = {
      days: remainingDuration.get('months') > 0 ? moment().add(remainingDuration.get('month'), 'month').add(remainingDuration.get('days'), 'days').diff(moment(), 'days') : remainingDuration.get('days'),
      hours: remainingDuration.get('days') > 0 ? 24 - moment().hour() : remainingDuration.get('hours'),
      minutes: remainingDuration.get('minutes') > 0 ? remainingDuration.get('minutes') : 0,
      seconds: remainingDuration.get('seconds') > 0 ? remainingDuration.get('seconds') : 0,
    };

    const itemActive = this.getActiveItem(remaining);

    const dayClass = classNames(styles.countdownItem, itemActive !== 'day' ? null : styles.countdownItemActive);
    const minuteClass = classNames(styles.countdownItem, itemActive !== 'minute' ? null : styles.countdownItemActive);
    const secondClass = classNames(styles.countdownItem, itemActive !== 'second' ? null : styles.countdownItemActive);
    const dayLetter = intl.locale === 'fr' ? 'j' : 'd';

    if (type === 'circular') {
      return (
        <If condition={remainingDuration._milliseconds > 0}>
          <div className={styles.countdownContainer}>
            <div className={dayClass}>
              <If condition={remaining.days > 0}>
                <Then>
                  <div>
                    <CircularProgressbar
                      className={styles.CircularProgressbar}
                      background
                      backgroundPadding={5}
                      strokeWidth={5}
                      percentage={Math.round((remaining.hours / 24) * 100)}
                      textForPercentage={() => null}
                    />
                    <div className={styles.countdownLabel}>
                      <strong>{remaining.days}</strong>Jours
                    </div>
                  </div>
                </Then>
                <Else>
                  <div>
                    <CircularProgressbar
                      className={styles.CircularProgressbar}
                      background
                      backgroundPadding={5}
                      strokeWidth={5}
                      percentage={Math.round((remaining.hours / 24) * 100)}
                      textForPercentage={() => null}
                    />
                    <div className={styles.countdownLabel}>
                      <strong>{remaining.hours}</strong>Heures
                    </div>
                  </div>
                </Else>
              </If>
            </div>

            <div className={styles.separator}>:</div>

            <div className={minuteClass}>
              <div>
                <CircularProgressbar
                  className={styles.CircularProgressbar}
                  background
                  backgroundPadding={5}
                  strokeWidth={5}
                  percentage={Math.round((remaining.minutes / 60) * 100)}
                  textForPercentage={() => null}
                />
                <div className={styles.countdownLabel}>
                  <strong>{remaining.minutes}</strong>Min
                </div>
              </div>
            </div>

            <div className={styles.separator}>:</div>

            <div className={secondClass}>
              <div>
                <CircularProgressbar
                  className={styles.CircularProgressbar}
                  background
                  backgroundPadding={5}
                  strokeWidth={5}
                  percentage={Math.round((remaining.seconds / 60) * 100)}
                  textForPercentage={() => null}
                />
                <div className={styles.countdownLabel}>
                  <strong>{remaining.seconds}</strong>Sec
                </div>
              </div>
            </div>
          </div>
        </If>
      );
    } else if (type === 'flat') {
      return (
        <div className={styles.flatCountdown}>
          <Icon name={icon} className={styles.icon} />
          <span>
            {remainingTime === 0 ? (<>{intl.formatMessage(messages.eventClosed)}</>) : (
              <>
                {remaining.days ? `${this.padNumber(remaining.days)}${dayLetter} : ` : ''}
                {`${this.padNumber(remaining.hours)}h : ${this.padNumber(remaining.minutes)}m : ${this.padNumber(remaining.seconds)}s`}
              </>
            )}
          </span>
        </div>
      );
    }

    return (
      <div className={styles.inlineCountdown}>
        <Icon name={icon} className={styles.icon} />
        <span>
          <If condition={remainingTime === 0}>
            <Then>
              <>{intl.formatMessage(messages.eventClosed)}</>
            </Then>
            <Else>
              <Then>
                <>
                  {remaining.days ? `${this.padNumber(remaining.days)}${dayLetter} : ` : ''}
                  {`${this.padNumber(remaining.hours)}h : ${this.padNumber(remaining.minutes)}m : ${this.padNumber(remaining.seconds)}s`}
                </>
              </Then>
            </Else>
          </If>
        </span>
      </div>
    );
  }
}

export default injectIntl(Countdown);
