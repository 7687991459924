import React from 'react';
import { H4 } from '@seekube-tech/ui-kit';
import { bool, object } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import CardOffers from './Cards/Offers/CardOffers';
import CardConferences from './Cards/Conferences/CardConferences';
import SidebarSection from './SidebarSection';
import interviewHelpPicture from '@/assets/images/interviewHelpPicture.svg';
import liveHelpPicture from '@/assets/images/liveHelpPicture.svg'
import informalHelpPicture from '@/assets/images/informalHelpPicture.svg';
import CardInformal1to1s from './Cards/Informal1to1s/CardInformal1to1s';
import { getLocale } from '@/utils/localStorage';
import styles from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Exponents/scenes/Exponent/components/Home/Sidebar/styles.less';

const StandSidebarHome = ({
  profile,
  conferences,
  offers,
  informal1to1s,
  event,
  match,
  isPartner
}) => {
  if (!event || !profile) {
    return null;
  }

  const t = useFormatMessage();

  if (!offers || !event || !conferences || !informal1to1s) {
    return null;
  }

  if ((!event.modules?.offer?.enable || !offers?.countOffers) &&
    (!event.modules?.live?.participantEnable || !conferences?.countConferences) &&
    (!event.modules?.informal1to1?.enable || !informal1to1s?.countInformal1to1)) {
    return null;
  }

  const locale = getLocale();

  const labels = {
    offers: {
      singular: event.modules?.offer[`label_${locale}`],
      plural: event.modules?.offer[`labelMultiple_${locale}`],
    },
    conferences: {
      singular: event.modules?.live[`label_${locale}`],
      plural: event.modules?.live[`labelMultiple_${locale}`],
    },
    informal1to1s: {
      singular: event.modules?.informal1to1[`label_${locale}`],
      plural: event.modules?.informal1to1[`labelMultiple_${locale}`],
    },
  }

  return <div className={styles.sidebar}>
    <H4>{t({ id: 'stand.sidebar.title' })}</H4>
    <SidebarSection
      title={labels.offers.plural}
      hidden={!event.modules?.offer?.enable || !offers?.countOffers}
      popover={{
        title: t({ id: 'the.interviews' }, { label: labels.offers.plural }),
        description: t({ id: 'candidate.dashboard.stats.interview.popover.description' }),
        img: interviewHelpPicture
      }}>
      <CardOffers
        labels={labels.offers}
        countOffers={offers?.countOffers}
        link={`${match.url}/offer`}
      />
    </SidebarSection>
    <SidebarSection
      title={labels.conferences.plural}
      hidden={!event.modules?.live?.participantEnable || !conferences?.countConferences}
      popover={{
        title: t({ id: 'stand.sidebar.cardtitle.lives' }, { label: labels.conferences.plural }),
        description: t({ id: 'candidate.dashboard.stats.conference.popover.description' }),
        img: liveHelpPicture
      }}>
      <CardConferences
        conferences={conferences.conferences}
        event={event}
        link={`${match.url}/conference`}
        profile={profile}
        isPartner={isPartner}
        labels={labels.conferences}
      />
    </SidebarSection>
    <SidebarSection
      title={labels.informal1to1s.plural}
      hidden={!event.modules?.informal1to1?.enable || !informal1to1s?.countInformal1to1}
      popover={{
        title: t({ id: 'stand.sidebar.popoverTitle.informal1to1' }, { label: labels.informal1to1s.plural }),
        description: t({ id: 'candidate.dashboard.stats.informal1to1.popover.description' }),
        img: informalHelpPicture
      }}>
      <CardInformal1to1s
        informal1to1s={informal1to1s.informal1to1s}
        informal1to1s_count={informal1to1s.countInformal1to1}
        link={`${match.url}/informal1to1`}
        profile={profile}
        labels={labels.informal1to1s}
      />
    </SidebarSection>
  </div>
}

StandSidebarHome.propTypes = {
  event: object,
  profile: object,
  match: object,
  isPartner: bool,
  conferences: object,
  offers: object,
  informal1to1s: object
}

export default StandSidebarHome;
