import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const ProtectTabAccess = ({
  countOffers,
  countConferences,
  countInformal1to1s,
  match
}) => {
  const location = useLocation();
  const history = useHistory();

  const redirectHome = () => {
    history.replace(match.url);
  }

  //Tabs should not be accessible if there is no element in it
  useEffect(() => {
    switch (location.pathname) {
      case `${match.url}/offer`:
        if (countOffers === 0) {
          redirectHome();
        }
        break;
      case `${match.url}/conference`:
        if (countConferences === 0) {
          redirectHome();
        }
        break;
      case `${match.url}/informal1to1`:
        if (countInformal1to1s === 0) {
          redirectHome();
        }
        break;
    }
  }, [location, countOffers, countConferences, countInformal1to1s]);

  return null;
}

export default ProtectTabAccess;
