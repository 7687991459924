export const GET_USER_TIMESLOTS = 'app/store/timeslot/GET_USER_TIMESLOTS';
export const GET_USER_TIMESLOTS_SUCCESS = 'app/store/timeslot/GET_USER_TIMESLOTS_SUCCESS';
export const GET_USER_TIMESLOTS_ERROR = 'app/store/timeslot/GET_USER_TIMESLOTS_ERROR';

export const PATCH_USER_TIMESLOTS_DURATION = 'app/store/timeslot/PATCH_USER_TIMESLOTS_DURATION';
export const PATCH_USER_TIMESLOTS_DURATION_SUCCESS = 'app/store/timeslot/PATCH_USER_TIMESLOTS_DURATION_SUCCESS';

export const POST_USER_TIMESLOTS = 'app/store/timeslot/POST_USER_TIMESLOTS';
export const POST_USER_TIMESLOTS_SUCCESS = 'app/store/timeslot/POST_USER_TIMESLOTS_SUCCESS';
export const POST_USER_TIMESLOTS_ERROR = 'app/store/timeslot/POST_USER_TIMESLOTS_ERROR';

export const DEL_USER_TIMESLOTS = 'app/store/timeslot/DEL_USER_TIMESLOTS';
export const DEL_USER_TIMESLOTS_SUCCESS = 'app/store/timeslot/DEL_USER_TIMESLOTS_SUCCESS';
export const DEL_USER_TIMESLOTS_ERROR = 'app/store/timeslot/DEL_USER_TIMESLOTS_ERROR';

export const FIND_OR_CREATE_USER_TIMESLOT = 'app/store/timeslot/FIND_OR_CREATE_USER_TIMESLOT';
export const FIND_OR_CREATE_USER_TIMESLOT_SUCCESS = 'app/store/timeslot/FIND_OR_CREATE_USER_TIMESLOT_SUCCESS';
export const FIND_OR_CREATE_USER_TIMESLOT_ERROR = 'app/store/timeslot/FIND_OR_CREATE_USER_TIMESLOT_ERROR';
