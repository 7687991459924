import React from 'react';
import { func, object } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useFormatMessage } from 'react-intl-hooks'

import { Form, Input } from 'antd';
import { Button } from '@seekube-tech/ui-kit';
import commonMessages from '@/messages';

import styles from './styles.less';
import InputWithTitle
  from '@/scenes/Event/scenes/Owner/scenes/Setting/scenes/BasicInformation/components/Keydates/components/InputWithTitle';

const FormItem = Form.Item;

const NonHybridForm = ({
  form: { getFieldDecorator, validateFields },
  event: { format, keyMomentsGoals },
  onSubmit,
}) => {
  const t = useFormatMessage();

  const handleSubmit = (e) => {
    e.preventDefault();

    return validateFields((err, values) => onSubmit(err, { keyMomentsGoals: { ...keyMomentsGoals, ...values } }));
  };


  return (
    <Form onSubmit={handleSubmit} style={{ minWidth: '350px', maxWidth: '500px', textAlign: 'left' }} className={styles.form} layout="vertical">
      <FormItem label={<FormattedMessage {...commonMessages.companies} values={{ count: 2 }} />}>
        {getFieldDecorator(`${format}.registeredCompanies`, {
          initialValue: keyMomentsGoals?.[format]?.registeredCompanies,
          rules: [{ required: false, message: t({ id: 'event.settings.goals.error' }) }],
        })(
          <Input placeholder="Total" type="number" />
        )}
      </FormItem>

      <InputWithTitle title={t({ id: 'candidate.pl' }, { count: 2 })}>
        <div style={{ display: 'flex' }}>
          <FormItem label={t({ id: 'total' })}>
            {getFieldDecorator(`${format}.totalParticipants`, {
              initialValue: keyMomentsGoals?.[format]?.totalParticipants,
              rules: [{ required: false, message: t({ id: 'event.settings.goals.totalParticipants.error' }) }],
            })(
              <Input placeholder="Total" type="number" />
            )}
          </FormItem>
          <FormItem label={t({ id: 'stats.participants.top' })} style={{ margin: '0 20px' }}>
            {getFieldDecorator(`${format}.topParticipants`, {
              initialValue: keyMomentsGoals?.[format]?.topParticipants,
              rules: [{ required: false, message: t({ id: 'event.settings.goals.topParticipants.error' }) }],
            })(
              <Input placeholder="Top profils" type="number" />
            )}
          </FormItem>
          <FormItem label={t({ id: 'stats.participants.save' })}>
            {getFieldDecorator(`${format}.saveParticipants`, {
              initialValue: keyMomentsGoals?.[format]?.saveParticipants,
              rules: [{ required: false, message: t({ id: 'event.settings.goals.savedParticipants.error' }) }],
            })(
              <Input placeholder="Vivier" type="number" />
            )}
          </FormItem>
        </div>
      </InputWithTitle>


      <FormItem label={t({ id: 'event.settings.goals.appointmentsValidated' })}>
        {getFieldDecorator(`${format}.appointmentsValidated`, {
          initialValue: keyMomentsGoals?.[format]?.appointmentsValidated,
          rules: [{ required: false, message: t({ id: 'event.settings.goals.appointmentsValidated.error' }) }],
        })(
          <Input placeholder="Activité" type="number" />
        )}
      </FormItem>
      <FormItem>
        <Button type="submit">{t({ id: 'save' })}</Button>
      </FormItem>
    </Form>
  );
};

NonHybridForm.propTypes = {
  onSubmit: func,
  form: object,
  event: object,
};

export default Form.create()(NonHybridForm);
