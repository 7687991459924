import React from 'react';
import { string, object } from 'prop-types';

import { useGetCandidateNumberOnline } from '@/queries/dashboardRealtime/useGetCandidateNumberOnline';
import { useGetRecruiterNumberOnline } from '@/queries/dashboardRealtime/useGetRecruiterNumberOnline';
import { useFormatMessage } from 'react-intl-hooks';

import { User } from 'react-feather';
import Icon from '@/components/Icon';
import styles from './styles.less'

const SmallStatCard = ({type, eventId, view}) => {

  const t = useFormatMessage();
  const statNumber = type === 'candidate' ? useGetCandidateNumberOnline({eventId: eventId, view}) : useGetRecruiterNumberOnline({eventId: eventId, view});

  return (
    <div className={styles.smallStatCardContainer}>
      <div className={styles.leftContent}>
        <p className={styles.title}>{t({ id: `dashboard.realtime.smallCard.${type}.title`})}</p>
        <p className={styles.description}>{t({ id: `dashboard.realtime.smallCard.${type}.description`})}</p>
      </div>
      <div className={styles.rightContent}>
        <div className={`${styles.statNumberContainer} ${type === 'recruiter' && styles.statNumberContainerRecruiter}`}>
          {type === 'recruiter' ? <Icon name="building" /> : <User size={16} />}
          <span>{statNumber?.data?.countUsersConnected}</span>
        </div>
      </div>
    </div>
  )
}

SmallStatCard.propTypes = {
  type: string,
  event: object,
  view: string,
};

export default SmallStatCard;