/* eslint-disable arrow-body-style */
import React, { useMemo } from 'react';
import { Row, Col, Popover } from 'antd';
import { arrayOf, bool, func, object, string } from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import { useFormatMessage } from 'react-intl-hooks'
import { IconLives, IconVideo, IconPhone, IconMapPin } from '@seekube-tech/ui-kit';
import { SLOT_TYPE } from '@/utils/constants'
import Icon from '@/components/Icon';
import Avatar from '@/components/Avatar';

import { EVENT_MEDIUM } from '../../../../ducks/BigCalendarConstants';
import TimeslotDetails from '../TimeslotDetails';

import styles from './styles.less';

function Timeslot({ event, currentEvent, jobfairsColor, jobfairs, isSettingsVisible, timezone, handleDeleteEvent, handleOnInformalDescriptionChange, informalDescription }) {
  const t = useFormatMessage();

  const isAppointmentExist = Boolean(event._appointment);
  const isTypeConference = event.type === 'conference';
  const isAvatarVisible = isSettingsVisible === false && isAppointmentExist;
  const newStart = moment.tz(event.start, timezone);
  const newEnd = moment.tz(event.end, timezone);
  const [isPopoverVisible, setPopoverVisible] = React.useState(false);

  const badgesColor = useMemo(() => {
    if (event._event && isAppointmentExist === false) {
      return [jobfairsColor[event._event]];
    }

    return (jobfairs || [])
      .filter((jobfair) => {
        // if _event is null, the timeslot is reserved to all virtual event. We need to omit the physical ones from the frontend side
        return (
          jobfair.format && jobfair.format !== "physical" &&
          newStart.isBetween(jobfair.keyDates.jobfair.beginAt, jobfair.keyDates.jobfair.endAt)
        )
      })
      .map((jobfair) => jobfairsColor[jobfair.id]);
  }, [jobfairsColor, event, isAppointmentExist, jobfairs]);

  /**
   * Return a color for appointment
   * @return {Object}
   */
  const appointmentColor = useMemo(() => {
    if (isAppointmentExist && event._appointment) {
      return jobfairsColor[event._appointment._event.id];
    }
    
    if (event._event) {
      return jobfairsColor[event._event];
    }

    return null;
  }, [jobfairsColor, event, isAppointmentExist]);

  /**
   * Return time of slot
   * @return {string}
   */
  const time = useMemo(() => {
    let slotType;
    switch(event.type) {
      case SLOT_TYPE.INTERVIEW: 
        return `${newStart.format('HH:mm')} ${t({ id: 'interview.pl'}, { count: 0 })}` 
      case SLOT_TYPE.INFORMAL1TO1: 
        return `${newStart.format('HH:mm')} ${t({ id: 'chat.oneToOne.pl'}, { count: 0 })}`
      case SLOT_TYPE.CONFERENCE: 
        return `${newStart.format('HH:mm')} ${t({ id: 'live.pl'}, { count: 0 })}`
    }

    return `${newStart.format('HH:mm')} ${slotType}`;
  }, [timezone, newStart, newEnd]);

  /**
   * Return true when slot is disabled (slot is passed and/or slot is saved when panel is visible)
   * @returns {Boolean}
   */
  const isDisabled = useMemo(() => {
    return moment().isAfter(newEnd) || (isSettingsVisible && event.isSaved);
  }, [newEnd, isSettingsVisible, event]);

  /* Function */

  /**
   * Return an icon by medium type
   * @returns {JSX}
   */
  const getIcon = () => {
    if (isTypeConference) {
      return <IconLives size={12} />
    }
    switch (event.medium) {
      case EVENT_MEDIUM.PHONE:
        return <IconPhone size={12} />;
      case EVENT_MEDIUM.VISIO:
        return <IconVideo size={12} />;
      case EVENT_MEDIUM.PHYSICAL:
        return <IconMapPin size={12} />;
      default:
        return <IconPhone size={12} />;
    }
  };

  /**
   * Handle popover visibility
   * @param {Boolean} visibility
   */
  const handleTogglePopover = (visibility = false) => {
    setPopoverVisible(visibility);
  };

  /* Render */

  return (
    <Popover
      placement="right"
      content={
        <TimeslotDetails
          event={event}
          currentEvent={currentEvent}
          jobfairs={jobfairs}
          appointmentColor={appointmentColor && appointmentColor?.main}
          timezone={timezone}
          closePopover={() => handleTogglePopover(false)}
          handleOnInformalDescriptionChange={handleOnInformalDescriptionChange}
          informalDescription={informalDescription}
        />
      }
      visible={isPopoverVisible}
      onVisibleChange={handleTogglePopover}
      trigger="click"
      zIndex={1000} // mandatory because the Agenda Modal is in the hundreds
    >
      <div
        role="button"
        tabIndex={0}
        className={classnames(
          styles.root,
          { [styles.appointment]: isAppointmentExist || isTypeConference, [styles.expired]: isDisabled },
        )}
        style={((isAppointmentExist || isTypeConference) && appointmentColor) ? { borderLeftColor: appointmentColor?.main, backgroundColor: appointmentColor?.light } : {}}
      >
        <Row type="flex" gutter={4} align="middle" style={{ flexFlow: 'noWrap' }}>
          {isAvatarVisible && (
            <Col>
              <Avatar
                className={styles.avatar}
                user={event._appointment?._user}
                size={20}
                src={event._appointment?._user?.pictureUrl}
              />
            </Col>
          )}

          <Col>
            <Row type="flex" gutter={4} align="start"  style={{ flexFlow: 'noWrap' }}>
              <Col className={styles.iconSlot} style={((isAppointmentExist || isTypeConference) && appointmentColor) ? { color: appointmentColor?.main } : {}}>
                {getIcon()}
              </Col>

              <Col>
                <span className={styles.time} style={((isAppointmentExist || isTypeConference) && appointmentColor) ? { color: appointmentColor?.main } : {}}>
                  <span>{time}</span>
                </span>
                {isTypeConference && <span className={styles.time} style={{ color: appointmentColor?.main }}> - {event._conference.title}</span>}
              </Col>
            </Row>

            {isAppointmentExist && (
              <span className={styles.fullname}>{event._appointment?._user?.fullName}</span>
            )}
          </Col>
        </Row>

        {isAppointmentExist === false && isDisabled === false && !isTypeConference && (
          <button className={styles.trashButton} onClick={() => handleDeleteEvent(event)}>
            <Icon name="trash" />
          </button>
        )}

        {isAppointmentExist === false && !isTypeConference && (
          <Row className={styles.badgeContainer} type="flex" gutter={4} align="middle">
            {badgesColor.map((color, i) => (
              <Col key={i}>
                <div className={styles.badge} style={{ backgroundColor: color ? color.main : '#FF7A00' }} />
              </Col>
            ))}
          </Row>
        )}
      </div>
    </Popover>
  );
}

Timeslot.propTypes = {
  event: object,
  currentEvent: object,
  jobfairsColor: object,
  isSettingsVisible: bool,
  jobfairs: arrayOf(object),
  timezone: string,
  handleDeleteEvent: func,
  handleOnInformalDescriptionChange: func,
  informalDescription: string,
};

export default Timeslot;
