import React, { useRef } from 'react';
import { object, array, string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useGetExponent } from '@/queries/exponent/useGetExponent';
import StandCandidateTitle from './components/StandCandidateTitle';
import StandCandidateNavBar from './components/StandCandidateNavBar';
import StandOffers from './components/Offers';
import StandHome from './components/Home';
import StandConferences from './components/Conferences';
import { useGetOrganizationsProfilesPreviewsSidebarConferences } from '@/queries/conferences/sidebar/useGetOrganizationsProfilesPreviewsSidebarConferences';
import { useGetSidebarOffers } from '@/queries/stands/useGetSidebarOffers';
import styles from './stylesV2.less'
import {
  useGetOrganizationProfilesPreviewSidebarInformal1to1s
} from '@/queries/informals1to1/sidebar/useGetOrganizationProfilesPreviewSidebarInformal1to1s';
import Informal1to1s
  from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Exponents/scenes/Exponent/components/Informal1to1s';
import { useGetEvent } from '@/queries/events/useGetEvent';
import { getAuthUser } from '@/store/auth/selectors';
import MobileHeader from '@/containers/Layout/containers/MobileHeader';
import MobileMenu from '@/containers/Layout/containers/MobileMenu';
import {
  DateClosingCardInfos
} from '@/scenes/Event/scenes/Candidate/scenes/JobDating/containers/SceneAlerts/DateClosingCardInfos';
import ProtectTabAccess
  from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Exponents/scenes/Exponent/components/ProtectTabAccess';
import useScrollTop from '@/utils/hooks/scrollTop';
import * as actionActions from '@/store/action/actions';
import { getCurrentParticipant } from '@/store/participant/selectors';
import { useGetOrganizationsCurrentProfile } from '@/queries/organizations/useGetOrganizationsCurrentProfile';
import LoadingIndicator from '@/components/LoadingIndicator';
import { getId } from '@/utils/global';
import {
  HASH
} from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Exponents/scenes/Exponent/components/const';

const StandCandidate = ({
  match,
  profile,
  exponentId = match?.params?.exponentID,
  showSideBar = true,
  sectors
}) => {
  useScrollTop();
  const authUser = useSelector(getAuthUser)?.toJS();
  const participant = useSelector(getCurrentParticipant)?.toJS();
  const dispatch = useDispatch();
  const { data: event } = useGetEvent({ slug: match.params.eventSlug });

  const { data: exponent } = useGetExponent({
    eventSlug: match?.params?.eventSlug,
    exponentId,
    onSuccess: (exponentResult) => {
      if (participant) {
        dispatch(actionActions.postAction({
          actionParams: {
            name: 'CANDIDATE_VISIT_STAND',
            _user: authUser._id,
            _organization: exponentResult._organization._id,
            _event: exponentResult._event._id,
            participantId: participant._id,
          },
        }))
      }
    }
  });

  const organizationsCurrentProfileQuery = useGetOrganizationsCurrentProfile({
    orgaId: getId(exponent?._organization),
    eventId: getId(exponent?._event),
    enabled: !profile, // if profile is passed, we don't need to fetch the profile
  });

  const { data: sidebarConferences } = useGetOrganizationsProfilesPreviewsSidebarConferences({
    organizationId: getId(exponent?._organization),
    eventId: getId(exponent?._event),
  });
  const { data: sidebarOffers } = useGetSidebarOffers({
    organizationId: getId(exponent?._organization),
    eventId: getId(exponent?._event),
  });
  const { data: sidebarInformal1to1s } = useGetOrganizationProfilesPreviewSidebarInformal1to1s({
    organizationId: getId(exponent?._organization),
    eventId: getId(exponent?._event),
  });

  const availableNavbarTabs = getAvailableNavbarTabs();

  const scrollDiv = useRef(null);

  const Content = ({ children, organizationProfile }) => {

    switch (location.pathname) {
      case `${match.url}/${HASH.offer}`:
        return (<StandOffers exponent={exponent} match={match} event={event}>{children}</StandOffers>)
      case `${match.url}/${HASH.conference}`:
        return <StandConferences exponent={exponent} event={event} />
      case `${match.url}/${HASH.informal1to1}`:
        return <Informal1to1s exponent={exponent} event={event} match={match}>{children}</Informal1to1s>
      default:
        return <StandHome
          profile={organizationProfile}
          showSidebar={showSideBar}
          exponent={exponent}
          match={match}
          sidebarConferences={sidebarConferences}
          sidebarOffers={sidebarOffers}
          sidebarInformal1to1s={sidebarInformal1to1s}
          event={event}
        />;
    }
  }

  function getAvailableNavbarTabs () {
    const tabs = [];

    if (showSideBar) {
      if (sidebarOffers?.countOffers > 0 && event?.modules.offer.enable) {
        tabs.push(HASH.offer);
      }
      if (sidebarConferences?.countConferences > 0 && event?.modules.live.participantEnable) {
        tabs.push(HASH.conference);
      }
      if (sidebarInformal1to1s?.countInformal1to1 > 0 && event?.modules.informal1to1.enable) {
        tabs.push(HASH.informal1to1);
      }
    }
    return tabs
  }

  const onChangeTab = () => {
    if (scrollDiv.current) {
      scrollDiv.current.scrollIntoView({ block: "center" });
    }
  }

  if (!profile && (organizationsCurrentProfileQuery.isLoading || !organizationsCurrentProfileQuery.isSuccess)) {
    return <LoadingIndicator />
  }

  const organizationProfile = profile || organizationsCurrentProfileQuery?.data?.profile;

  return (
    <div className={styles.standCandidateContainer}>
      <ProtectTabAccess
        countOffers={sidebarOffers?.countOffers}
        countConferences={sidebarConferences?.countConferences}
        countInformal1to1s={sidebarInformal1to1s?.countInformal1to1}
        match={match}
      />
      {(organizationProfile.cover) &&
        <div className={styles.coverContainer}>
          <img className={styles.coverImg} src={organizationProfile.cover} alt='cover'/>
        </div>
      }
      <div ref={scrollDiv} className={`${styles.standCandidateBody} ${availableNavbarTabs.length > 0 ? styles.navbar : ''}`}>
        <div className={availableNavbarTabs.length > 0 ? styles.navbar : styles.noNavBarTitle}>
          <StandCandidateTitle
            exponent={exponent}
            profile={organizationProfile}
            sectors={sectors}
          />
        </div>
        {!profile &&
          <StandCandidateNavBar
            match={match}
            availableTabs={availableNavbarTabs}
            labels={event?.modules}
            onChangeTab={onChangeTab}
          />
        }
        {organizationProfile &&
          <div className={availableNavbarTabs.length > 0 ? styles.navbar : styles.noNavBarContent}>
            <Content organizationProfile={organizationProfile}>
              <DateClosingCardInfos authUser={authUser} event={event} match={match} ignoreView/>
            </Content>
          </div>
        }
      </div>
      <MobileHeader />
      <MobileMenu
        displayInformal={exponent?._event.modules?.informal1to1?.enable}
      />
    </div>
  )
}

StandCandidate.propTypes = {
  match: object,
  profile: object,
  sectors: array,
  exponentId: string,
}

export default StandCandidate
