import React from 'react';
import { string, array, object, bool } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import {
  ParticipationColumn
} from '@/scenes/Admin/scenes/Organizations/scenes/Organization/components/participationColumn';
import { MemberColumn } from '@/scenes/Admin/scenes/Organizations/scenes/Organization/components/memberColumn';
import { ActionColumn } from '@/scenes/Admin/scenes/Organizations/scenes/Organization/components/actionColumn';

const ColumnsUi = (context, switchAuth, organization) => (
  [
    {
      id: 'user',
      Header: <FormattedMessage id="recruiters" values={{ count: 2 }} />,
      accessor: ({ user, authUser }) =>
        <MemberColumn record={{ ...user }} organization={organization} authUser={authUser} onActionClick={() => { switchAuth(user) }} />,
    },
    {
      id: 'lastLogin',
      Header: <FormattedMessage id="last.connection" />,
      className: 'text-center',
      accessor: ({ lastLogin }) => lastLogin && <span>{(moment(lastLogin).format('DD/MM/YY') || 0)}</span> || 0
    },
    {
      id: 'username',
      Header: <FormattedMessage id="email" />,
      accessor: ({ username }) => <span>{username}</span>,
    },
    {
      id: 'jd',
      Header:  <span>JD</span>,
      accessor: ({ user }) => <ParticipationColumn record={user} />,
    },
    {
      id: 'actions',
      Header:  <FormattedMessage id="action.pl" values={{ count: 2 }} />,
      accessor: ({ actions, authUser, context, user }) => {
        const {
          handleOpenEdit,
          handleOpenAddJobdating,
          handleOpenEditPassword,
          onToolsClick,
          handleOpenDeleteUser,
          handleSwitchRole,
          handleSendInvitation,
        } = actions || {};

        return (
          <span>
            <ActionColumn
              organization={organization}
              context={context}
              record={user}
              authUser={authUser}
              onEdit={handleOpenEdit}
              onAddJobdating={handleOpenAddJobdating}
              onEditPassword={handleOpenEditPassword}
              onToolsClick={onToolsClick}
              onDeleteUser={handleOpenDeleteUser}
              onSwitchRole={handleSwitchRole}
              onSendInvitation={handleSendInvitation}
            />
          </span>
        );
      },
    },
  ]
);

ColumnsUi.propTypes = {
  tool: object,
  createdAt: string,
  _organization: object,
  users: array,
  locale: string,
  event: object,
  isHybrid: bool,
};

export default ColumnsUi;
