import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { TextField } from '@seekube-tech/ui';
import { Field } from 'react-final-form';

import useValidations from '@/utils/validations/useValidations';

import styles from './styles.less';
import { Button, IconAlignLeft, IconLink, IconTrash2 } from '@seekube-tech/ui-kit';

function Video({ field, index, onRemove, disabled }) {
  /* Vars */

  const t = useFormatMessage();
  const { isUrl, isFilled } = useValidations();
  
  /* Render */

  return (
    <Row type="flex" gutter={18} justify='space-between' align='center' className={styles.root}>
      <Col span={4}>
        <Field
          disabled={disabled}
          name={`${field}.title`}
          component={TextField}
          label={t({ id: 'event.recruiter.preparation.stand.item.videos.label' })}
          fullWidth
          icon={<IconAlignLeft />}
        />
      </Col>

      <Col span={6}>
        <Field
          disabled={disabled}
          name={`${field}.url`}
          component={TextField}
          label={t({ id: 'event.recruiter.preparation.stand.item.videos.address.label' })}
          icon={<IconLink />}
          placeholder={t({ id: 'event.recruiter.preparation.stand.item.videos.address.placeholder' })}
          fullWidth
          validate={(value) => isFilled(value) ?? isUrl(value, t({ id: 'form.error.url-video.format' }))}
        />
      </Col>

      <Col span={2} className={styles.removeButton}>
        <Button disabled={disabled}
                imageComponentLeft={<IconTrash2 size={16} />}
                variant='tonal' color='error'
                onClick={() => onRemove(index)}>{t({ id: 'delete' })}
        </Button>
      </Col>
    </Row>
  );
}

Video.propTypes = {
  field: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default Video;
