import { useQuery } from 'react-query';
import request from '@/utils/request';

export function useGetAppointmentsList({ eventId, view }) {
  const requestUrl = `${process.env.FRONT_GO_API_URL}/stats/kpi/appointments-list?eventId=${eventId}&eventFormat=${view}`;
  const queryFn = () => request(requestUrl, { method: 'GET' });

  const query = useQuery({
    queryKey: ['stats', 'appointmentsList', eventId, view],
    queryFn,
    refetchOnWindowFocus: false,
    cacheTime: 3000,
    refetchInterval: 900000,
  });
  return query;
}
