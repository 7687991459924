import React from 'react';
import { Avatar } from '@seekube-tech/ui'
import { Body1, Caption2, Link2 } from '@seekube-tech/ui-kit'
import colors from '@seekube-tech/ui-kit/dist/colors'
import { isEmpty, map, take, filter } from 'lodash'
import moment from 'moment'
import classnames from 'classnames';
import { getEarliestKeyMoment } from '@/utils/keyMoments';
import useStyles from './styles';
import Html from '@/components/Html';

const Events = ({ events }) => {
  const styles = useStyles();

  return (
    events.filter((event) => {
      const hasKeyDatesOrKeyMoments = !!event.keyDates || !!event.keyMoments;
      return (hasKeyDatesOrKeyMoments && event.name)
    }).
      sort((a, b) => getEarliestKeyMoment(b) - getEarliestKeyMoment(a)).
      map((event, index) => (
        <li key={index}>
          <Link2 href={`/${event.slug}/owner/settings`} color={colors.neutral['500']}>
            <div className={styles.resultItem}>
              <div className={styles.resultInfo}>
                <div className="mr-6">
                  <Body1>
                    <Html value={event?._highlightResult?.name?.value} />
                  </Body1>
                </div>
                <Planners {...event} />
                {event.keyDates && typeof event.keyDates.jobfair.beginAt !==
                  'undefined' && (
                    <Caption2 color={colors.neutral['400']}>
                      {moment(event.keyDates.jobfair.beginAt).format('MMM[.] Y')}
                    </Caption2>
                  )}
              </div>
            </div>
          </Link2>
        </li>
      ))
  )
}

const Planners = ({ planners }) => {
  const styles = useStyles();
  const _organizations = filter(planners._organizations, (orga) => orga.name);

  return map(take(_organizations, 3), (orga, i) => (
    orga.name && (
      <div className="flex mr-20">
        <span className="mr-6">
          <Avatar
            className={classnames(styles.plannerAvatar, styles.borderWidth)}
            variant="business"
            size="small"
            src={orga?.profile?.pictureUrl}
          />
        </span>
        <Caption2 color={colors.neutral['400']}>
          {orga.name}
        </Caption2>
        {i === 1 && ` - `}
      </div>
    )
  ),
  )
}

export { Events }
