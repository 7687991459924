import React from 'react';
import { createStructuredSelector } from 'reselect';
import { object, bool } from 'prop-types';
import { connect } from 'react-redux';
import { useGetKpiSessions } from '@/queries/kpis/useGetKpiSessions';
import { authSelectors } from '@/store/auth';

const second = 1000;

const UsersConnected = ({ authToken, isSpotlightVisible }) => {
  const kpiSessionQuery = useGetKpiSessions({
    authToken,
    refetchInterval: isSpotlightVisible && (5 * second),
  })

  return <>{kpiSessionQuery?.data?.countUsersConnected}</>;
}

UsersConnected.propTypes = {
  authToken: object,
  isSpotlightVisible: bool,
}

const mapStateToProps = createStructuredSelector({
  authToken: authSelectors.getAuthToken,
})

const withConnect = connect(mapStateToProps, null);


export default withConnect(UsersConnected)