import { normalize } from 'normalizr';
import moment from 'moment';
import { fromJS } from 'immutable';

import { call, put, takeLatest, select } from 'redux-saga/effects';
import { get } from 'lodash';
import request from '@/utils/request';
import * as actions from './actions';
import * as types from './types';
import { timeslotListSchema } from './schema';
import { userActions } from '../user';
import { entitiesActions } from '../entities';
import { authSelectors } from '../auth';
import { exponentSelectors } from '../exponent';
import { patchExponent } from '../exponent/actions';
import { notificationActions } from '../notification';
import { trackError } from '../../utils/analytics/helpers';
import { getCurrentExponent } from '@/store/exponent/selectors';

/**
 * Get user timeslots
 *
 * @param {object} currentUser
 */
function* getUserTimeslots({ payload: { currentUser, currentEvent, context, callback } }) {
  const authUser = yield select(authSelectors.getAuthUser);
  const userIdSelected = !currentUser ? authUser.get('_id') : currentUser._id;
  const eventDate = currentEvent
    ? `?beginAt=${moment(get(currentEvent, 'keyDates.jobfair.beginAt')).toISOString()}&endAt=${moment(get(currentEvent, 'keyDates.jobfair.endAt')).toISOString()}`
    : '';
  const requestUrl = `${process.env.FRONT_API_URL}/users/${userIdSelected}/timeslots${eventDate}`;

  try {
    const result = yield call(request, requestUrl);

    // Normaliz data
    const dataNormalized = normalize(result, timeslotListSchema);

    // Save entities
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    // Save timeslotsIds by userId
    yield put(actions.getUserTimeslotsSuccess({
      ids: dataNormalized.result,
      userId: userIdSelected,
      context,
    }));

    if (typeof callback === 'function') {
      return callback();
    }

    // Set Current user
    yield put(userActions.setCurrentUser(userIdSelected));
  } catch (err) {
    trackError(err);
  }
}

/**
 * Post user timeslots
 *
 * @param {Array} timeslots
 * @param {object} currentUser
 */
function* postUserTimeslots({ payload: { timeslots, currentEvent, currentUser, notificationParams, callback } }) {
  const authUser = yield select(authSelectors.getAuthUser);
  const currentExponent = yield select(exponentSelectors.getCalendarExponent);

  const userIdSelected = !currentUser ? authUser.get('_id') : currentUser._id;

  const requestUrl = `${process.env.FRONT_API_URL}/users/${userIdSelected}/timeslots`;

  try {
    const result = yield call(request, requestUrl, {
      method: 'POST',
      body: JSON.stringify([...timeslots]),
    });

    // Normaliz data
    const dataNormalized = normalize(result, timeslotListSchema);

    // Save entities
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    // Refresh the timeslot list
    yield put(actions.getUserTimeslots({ currentUser, callback }))

    if (currentExponent && currentEvent) {
      yield put(patchExponent({
        eventId: currentEvent,
        exponentId: currentExponent.get('_id'),
        userId: userIdSelected,
        exponentParams: {},
      }));
    }

    // Success notification
    if (notificationParams && notificationParams.success) {
      yield put(notificationActions.sendNotification({
        message: notificationParams.success.message,
        kind: notificationParams.success.kind,
        style: notificationParams.success.style,
      }));
    }
  } catch (err) {
    trackError(err);

    if (notificationParams && notificationParams.error) {
      yield put(notificationActions.sendNotification({
        message: err.message,
        kind: 'error',
        style: {},
      }));
    }
  }
}

function* patchUserTimeslotsDuration({ payload: { eventId, duration, currentUser, notificationParams } }) {
  const authUser = yield select(authSelectors.getAuthUser);

  const userIdSelected = !currentUser ? authUser.get('_id') : currentUser._id;

  const requestUrl = `${process.env.FRONT_API_URL}/users/${userIdSelected}/timeslots/duration`;

  try {
    const result = yield call(request, requestUrl, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        eventId,
        duration,
      }),
    });

    // Normaliz data
    const dataNormalized = normalize(result, timeslotListSchema);

    // Save entities
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    // Success notification
    if (notificationParams && notificationParams.success) {
      yield put(notificationActions.sendNotification({
        message: notificationParams.success.message,
        kind: notificationParams.success.kind,
        style: notificationParams.success.style,
      }));
    }

    // Refresh the timeslot list
    yield put(actions.getUserTimeslots({ currentUser }));
  } catch (err) {
    trackError(err);

    if (notificationParams && notificationParams.error) {
      yield put(notificationActions.sendNotification({
        message: err.message,
        kind: 'error',
        style: {},
      }));
    }
  }
}


/**
 * Delete user timeslots
 *
 * @param {object} currentUser
 * @param {array} timeslotIds
 */
function* delUserTimeslots({ payload: { currentUser, timeslots, exponent, currentEvent, notificationParams, callback } }) {
  const authUser = yield select(authSelectors.getAuthUser);

  const userIdSelected = !currentUser ? authUser.get('_id') : currentUser._id;

  const requestUrl = `${process.env.FRONT_API_URL}/users/${userIdSelected}/timeslots`;

  try {
    yield call(request, requestUrl, {
      method: 'DELETE',
      body: JSON.stringify(timeslots || { _event: currentEvent.get('_id') }),
    });

    // Refresh the timeslot list
    yield put(actions.getUserTimeslots({ currentUser }));

    if (typeof callback === 'function') {
      callback();
    }

    // Success notification
    if (notificationParams && notificationParams.success) {
      yield put(notificationActions.sendNotification({
        message: notificationParams.success.message,
        kind: notificationParams.success.kind,
        style: notificationParams.success.style,
      }));
    }
  } catch (err) {
    trackError(err);

    if (notificationParams && notificationParams.error) {
      yield put(notificationActions.sendNotification({
        message: err.message,
        kind: 'error',
        style: {},
      }));
    }
  }
}

/**
 * Listen Actions
 */
export default [
  takeLatest(types.GET_USER_TIMESLOTS, getUserTimeslots),
  takeLatest(types.POST_USER_TIMESLOTS, postUserTimeslots),
  takeLatest(types.PATCH_USER_TIMESLOTS_DURATION, patchUserTimeslotsDuration),
  takeLatest(types.DEL_USER_TIMESLOTS, delUserTimeslots),
];

