import { useState } from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { string, func } from 'prop-types';

import S3 from '@/services/S3';

import generateFileName from '../../../utils/generateFileName';

function useUpload({ onSuccess, organizationId, authToken, sendNotification }) {
  /* Vars */

  const [isLoading, setIsLoading] = useState(false);
  const t = useFormatMessage();

  /* Function */

  const onDrop = async (files, callback) => {
    try {
      setIsLoading(true);

      const file = files[0];

      const url = await S3.getS3Url(generateFileName(file), 'organization', organizationId, authToken);

      onSuccess(url);
    } catch (error) {
      sendNotification({
        message: t({ id: 'event.recruiter.preparation.stand.notification.bad.format' }),
        kind: 'error',
        style: {},
      });
    } finally {
      setIsLoading(false);

      if (callback) {
        callback();
      }
    }
  };

  /* Render */

  return [onDrop, isLoading]
}

useUpload.propTypes = {
  onSuccess: func.isRequired,
  organizationId: string.isRequired,
  authToken: string.isRequired,
  sendNotification: func.isRequired,
};

export default useUpload;
