
import { normalize } from 'normalizr';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import request from '@/utils/request';
import { entitiesActions } from '../entities';
import * as actions from './actions';
import * as types from './types';
import { getAuthToken } from '../auth/selectors';
import { eventSchema } from '../event/schema';
import { criterionListSchema } from './schema';
import { notificationActions } from '../notification';
import { trackError } from '../../utils/analytics/helpers';

/**
 * Get all default criteria
 */
function* getDefaultCriteria({ payload }) {
  const requestUrl = `${process.env.FRONT_API_URL}/defaultCriteria`;

  try {
    const result = yield call(request, requestUrl);

    // Normaliz data
    const dataNormalized = normalize(result, criterionListSchema);

    // Save entities
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    // Save default criteria IDs
    yield put(actions.getDefaultCriteriaSuccess(dataNormalized.result));

    if (payload && typeof payload.callback === 'function') {
      payload.callback();
    }
  } catch (err) {
    trackError(err); // eslint-disable-line
  }
}

function* getMergeList() {
  const requestUrl = `${process.env.FRONT_API_URL}/defaultCriteria/mergeList`;

  try {
    const result = yield call(request, requestUrl);

    // Save default criteria IDs
    yield put(actions.getMergeListSuccess(result));
  } catch (err) {
    trackError(err);
  }
}

/**
 * Post new criterion
 */
function* mergeCriterion({ payload: { eventId, oldId, newId } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/defaultCriteria/merge`;

  try {
    const result = yield call(request, requestUrl, {
      method: 'POST',
      body: JSON.stringify({ eventId, oldId, newId }),
    });

    // Normaliz data
    if (result) {
      yield put(actions.getMergeList());
    }
  } catch (err) {
    trackError(err);
  }
}

/**
 * Post new criterion
 */
function* skipCriterion({ payload: { eventSlug, criterionId } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/defaultCriteria/skip`;

  try {
    const result = yield call(request, requestUrl, {
      method: 'POST',
      body: JSON.stringify({ eventSlug, criterionId }),
    });

    // Normaliz data
    if (result) {
      yield put(actions.getMergeList());
    }
  } catch (err) {
    trackError(err);
  }
}

/**
 * Post new criterion
 */
function* postCriterion({ payload: eventId }) {
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/criteria`;

  try {
    const result = yield call(request, requestUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // Normaliz data
    const dataNormalized = normalize(result, eventSchema);

    // Save entities
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));
  } catch (err) {
    trackError(err); // eslint-disable-line
  }
}

/**
 * Delete criterion into an event
 */
function* deleteCriterion({ payload: { eventId, criterionId, callback, notificationParams } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/criteria/${criterionId}`;

  try {
    const result = yield call(request, requestUrl, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // Normaliz data
    const dataNormalized = normalize(result, eventSchema);

    // Save entities
    yield put(entitiesActions.replaceEntities({ id: result._id, type: 'events', entities: dataNormalized.entities }));
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    // Push success notification
    if (notificationParams && notificationParams.success) {
      yield put(notificationActions.sendNotification({
        message: notificationParams.success.message,
        kind: notificationParams.success.kind,
        style: notificationParams.success.style,
      }));
    }

    if (typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    trackError(err); // eslint-disable-line

    if (notificationParams && notificationParams.error) {
      yield put(notificationActions.sendNotification({
        message: err.message,
        kind: 'error',
        style: {},
      }));
    }
  }
}

/**
 * Listen Actions
 */
export default [
  takeLatest(types.GET_DEFAULT_CRITERIA, getDefaultCriteria),
  takeLatest(types.GET_MERGE_LIST, getMergeList),
  takeLatest(types.MERGE_CRITERION, mergeCriterion),
  takeLatest(types.SKIP_CRITERION, skipCriterion),
  takeLatest(types.POST_CRITERION, postCriterion),
  takeLatest(types.DELETE_CRITERION, deleteCriterion),
];

