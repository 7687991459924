import React, { useEffect, useState } from 'react';
import { object, func, array } from 'prop-types';
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push, replace } from 'connected-react-router';
import { isEmpty, isNil, isUndefined, isNull, get, filter, sortBy } from 'lodash';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';

// Store

import { Modal } from 'antd';
import { Button, IconXCircle, IconEdit3, Link1, Body2, H4 } from '@seekube-tech/ui-kit';

import { participantSelectors, participantActions } from '@/store/participant';
import { authSelectors } from '@/store/auth';
import { eventSelectors } from '@/store/event';
import { appointmentActions } from '@/store/appointment';
import { interactionSelectors, interactionActions } from '@/store/interaction';
// Components
import { ANALYTICS_CANDIDATE, USERS_TYPE } from '@/utils/constants';
import { track } from '@/utils/analytics';
import { toJS } from '@/utils/index';

import { withLayout } from '@/containers/Layout';
import Icon from '@/components/Icon';
import BlankState from '@/components/BlankState';
import Separator from '@/components/Separator';
import MobileMenu from '@/containers/Layout/containers/MobileMenu';

// Styles

import {
  AppointmentsList
} from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Appointments/components/AppointmentsList';
import { getAvailableActions } from '@/store/availableActions/selectors';
import { getMeetingStatus } from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Appointments/helpers';
import {
  CARD_STATUS,
  MEETING_STATUS,
} from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Appointments/const';
import { queryStringToObject } from '@/utils/url';
import messages from './messages';
import styles from '../../../../../../components/StaticOffers/styles.less';
import pageStyles from './styles.less';
import CancelAppointmentModal from './components/CancelAppointmentModal';
import MobileHeader from '@/containers/Layout/containers/MobileHeader';
import { getParticipantLimits } from '../Jobs/request';
import { useFormatMessage } from 'react-intl-hooks';


const Appointments = ({
  goTo,
  location,
  intl,
  cancelAppointment,
  relaunchAppointment,
  refuseAppointment,
  cancelParticipant,
  event,
  authUser,
  participant,
  interactions,
  getInteractions,
  availableActions,
  authToken
}) => { // eslint-disable-line
  const t = useFormatMessage()
  const [refuseModalVisible, setRefuseModalVisible] = useState(false);
  const [interactionSelected, setInteractionSelected] = useState(null);
  const [isRefuseLoading, setIsRefuseLoading] = useState(false);
  const [limitsApplicationModalIsOpen, setLimitsApplicationModalIsOpen] = useState(false);
  const [applicationLimits, setApplicationLimits] = useState()
  const [cancelMessage, setCancelMessage] = useState('')
  const [queryParams, setQueryParams] = useState({
    page: 1,
    ...queryStringToObject(location.search),
  });
  const [confirmCancelApplicationModalIsVisible, setConfirmCancelApplicationModalIsVisible] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    getInteractions({
      eventSlug: event.slug,
      body: {
        users: [authUser._id],
      },
    });

    if (participant && event) {
      getParticipantLimits({event, participant, authToken}).then((res) => {
        setApplicationLimits(res)
      })
    }
  }, []);


  useEffect(() => {
    setStateFromQueryParams();
  }, [interactions])


  const handleOpenOffer = (offer) => {

    dispatch(`/${event.slug}/candidate/jobdating/jobs/${offer._id}`);
  };

  /**
   * handleOnChangeAppointment
   *
   * @description : Cancel an existing appointment or relaunch a recruiter
   *
   */
  const handleOnChangeAppointment = (messageCandidate, interaction) => {
    // if candidate applied in a classic flow then delete interaction and appointment
    if ((
      interaction.type === 'informal1to1' && !event.skipAppointmentValidation && isEmpty(interaction._appointment.acceptedAt)
      || (isEmpty(interaction?._appointment))
    )) {
      return (
        interaction.type === 'informal1to1' ?
          cancelAppointment({
            appointmentId: interaction._appointment._id,
            eventId: event._id,
            userId: authUser._id,
            timeslotId: null,
            context: 'informal1to1',
            notificationParams: {
              success: {
                message: intl.formatMessage({ id: 'event.candidate.appointments.card.interview.canceled.by.candidate' }),
                kind: 'success',
                style: {
                  bottom: '5%',
                  top: 'inherit',
                },
              },
              error: false,
            },
          }) :
          cancelParticipant({
            interactionId: interaction._id,
            participantId: participant._id,
            userId: authUser._id,
            eventId: event._id,
            currentUser: authUser,
            callback: () => {
              track({
                name: ANALYTICS_CANDIDATE.CANCEL_APPLICATION_FOR_JOB,
                user: authUser,
                event,
                properties: {
                  company: interaction._organization.name,
                },
              });
            },
          })
      )
    }

    if (!isNil(interaction._appointment.date) && moment(interaction._appointment.date) < moment()) {
      return relaunchAppointment({
        appointmentId: interaction._appointment._id,
        messageCandidate,
        eventSlug: event.slug,
        eventId: event._id,
        callback: () => { handleOnVisibleChange(false); },
      });
    }

    setIsRefuseLoading(true);

    return refuseAppointment({
      appointmentId: interaction._appointment._id,
      messageCandidate,
      eventSlug: event.slug,
      eventId: event._id,
      event,
      userId: authUser._id,
      timeslotId: !isUndefined(interaction._appointment._timeslot) && !isNull(interaction._appointment._timeslot) ? interaction._appointment._timeslot._id : null,
      currentUser: authUser,
      callback: () => { handleOnVisibleChange(false); setIsRefuseLoading(false)},
    });

  };

  const handleOnVisibleChange = (refuseModalVisible) => {
    setRefuseModalVisible(refuseModalVisible);

    // Clear url params to prevent to reopen the refuse modal
    if (!refuseModalVisible) {
      const newParams = queryParams || {};
      if (newParams.appointment) {
        delete newParams.appointment;
      }
      if (newParams.modal) {
        delete newParams.modal;
      }

      setQueryParams(newParams)

      goTo(`/${participant._event.slug}/candidate/jobdating/appointments`);
    }
  };

  /**
   * handleOnModifyAppointment
   *
   * @description : Opening messaging with recruiter
   */
  const handleOnModifyAppointment = (e, interaction) => {
    goTo(`/${participant._event.slug}/candidate/jobdating/appointment/${interaction._appointment._id}?edit=true`);
  };

  const renderPopoverContent = (interaction) => {
    let popoverContent = null;
    const { timezone } = authUser;
    const type = getMeetingStatus({ interaction, event, timezone });

    switch (type) {
      case MEETING_STATUS.candidateHasAMeeting:
        popoverContent = (
          <>
            <div>
              <Link1
                disabled={availableActions.candidateModifyAppointment.isDisabled({ event, participant, interaction, role: USERS_TYPE.candidate })}
                role="button"
                className="popover-item"
                tabIndex="0"
                fontWeight={400}
                onClick={(e) => handleOnModifyAppointment(e, interaction)}
              >
                <IconEdit3 /> <FormattedMessage {...messages.edit} />
              </Link1>
            </div>
            <div>
              <Link1
                role="button"
                className="popover-item"
                tabIndex="0"
                fontWeight={400}
                onClick={() => { setInteractionSelected(interaction); setRefuseModalVisible(true) }}
              >
                <IconXCircle /> <FormattedMessage id="appointment.cancel" />
              </Link1>
            </div>
          </>
        );
        break;

      case MEETING_STATUS.candidateReceiveProposition:
        popoverContent = (
          <>
            <div>
              <Link1
                role="button"
                className="popover-item"
                tabIndex="0"
                fontWeight={400}
                onClick={() => { setInteractionSelected(interaction); setRefuseModalVisible(true) }}
              >
                <IconXCircle /> <FormattedMessage id="event.candidate.appointment.cancel" />
              </Link1>
            </div>
          </>
        );
        break;

      case CARD_STATUS.pending:
        popoverContent = (
          <>
            <div>
              <Link1
                role="button"
                className="popover-item"
                tabIndex="0"
                fontWeight={400}
                onClick={() => { setInteractionSelected(interaction); setConfirmCancelApplicationModalIsVisible(true); }}
              >
                <IconXCircle /> <FormattedMessage id="appointment.cancelApplication" />
              </Link1>
            </div>
          </>
        );
        break;

      case MEETING_STATUS.candidateMetRecruiter:
        popoverContent = (
          <>
            <div>
              <Link1
                role="button"
                className="popover-item"
                tabIndex="0"
                fontWeight={400}
                onClick={() => { setInteractionSelected(interaction); setRefuseModalVisible(true) } }
              >
                <Icon name="iconNoShow" /> <FormattedMessage id="event.candidate.appointment.noShow" />
              </Link1>
            </div>
          </>
        );
        break;

      default:
        return null;
    }

    return popoverContent;
  };

  /**
   * setStateFromQueryParams
   * @description use url params to open refuse modal when the user came from refuse link from email
   */
  const setStateFromQueryParams = () => {
    if (queryParams.appointment && interactions && interactions.length) {
      const currentInteraction = interactions.find((i) => i && i._appointment && i._appointment._id === queryParams.appointment);
      setInteractionSelected(currentInteraction)
    }

    if (queryParams.modal === 'refuse' && interactionSelected && interactionSelected._appointment) {
      setRefuseModalVisible(true)
    }
  };

  if (!participant) {
    return '';
  }
  const interactionsEnable = sortBy(filter(interactions, (interaction) => interaction.enable && interaction.type !== 'application-auto'), (interaction) => [get(interaction, '_appointment.date') && new Date(get(interaction, '_appointment.date')), new Date(interaction.updatedAt)]);

  if (interactionsEnable.length === 0) {
    const title = !isEmpty(event.modules?.offer?.[`blankTitle_${intl.locale}`]) ? event.modules?.offer?.[`blankTitle_${intl.locale}`] : intl.formatMessage(messages.blankTitle);
    const content = !isEmpty(event.modules?.offer?.[`blankText_${intl.locale}`]) ? event.modules?.offer?.[`blankText_${intl.locale}`] : intl.formatMessage(messages.blankText);
    const ctaLabel = !isEmpty(event.modules?.offer?.[`displayLabel_${intl.locale}`]) ? event.modules?.offer?.[`displayLabel_${intl.locale}`] : intl.formatMessage(messages.blankAction);
    return (
      <>
        <Separator className="hidden-mobile" height={30} />
        <BlankState
          className="screenHeight"
          title={title}
          content={event.withOfferModule ? content : ''}
          icon="umbrella"
          handleAction={event.withOfferModule ? () => dispatch(push(`/${event.slug}/candidate/jobdating/jobs`)) : null}
          buttonLabel={event.withOfferModule ? ctaLabel : null}
        />
        <MobileHeader event={event} />
        <MobileMenu
          displayInformal={event.modules?.informal1to1?.enable}
          displayLive={event.modules?.live && event.modules?.live?.participantEnable}
          liveLabel={event.modules?.live && event.modules?.live?.labelMultiple}
        />
      </>
    );
  }

  const handleCancel = (message = '') => {
    if (applicationLimits?.enableLimitApplications && interactionSelected.type === 'application') {
      setLimitsApplicationModalIsOpen(true);
      setCancelMessage(message)
    }
    else {
      handleOnChangeAppointment(message, interactionSelected)
    }
  }

  return (
    <div>
      <div className={styles.jobsContainer} style={{ margin: 'auto' }}>
        <AppointmentsList
          authUser={authUser}
          interactions={interactionsEnable}
          participant={participant}
          handleOpenOffer={handleOpenOffer}
          event={event}
          renderPopoverContent={renderPopoverContent}
        />
      </div>
      {interactionSelected?._appointment && (
        <CancelAppointmentModal
          visible={refuseModalVisible}
          onVisibleChange={handleOnVisibleChange}
          appointment={interactionSelected._appointment}
          isRefuseLoading={isRefuseLoading}
          handleCancelMessage={(message) => handleCancel(message, interactionSelected)}
        />
      )}

      <Modal
        visible={confirmCancelApplicationModalIsVisible}
        footer={false}
        maskClosable
        width={470}
        className={['customConfirm', pageStyles.confirmCancelApplicationModal].join(' ')}
        onCancel={() => setConfirmCancelApplicationModalIsVisible(false) }
      >
        <a role="button" tabIndex={0} className="modal-close" onClick={() => setConfirmCancelApplicationModalIsVisible(false)}>
          <Icon name="close" className="modal-close-icon" />
        </a>

        <h4 className="ant-confirm-title">
          <span role="img" aria-label="warning">⚠️ </span><FormattedHTMLMessage id="event.candidate.jobdating.application.cancel.modal" />
        </h4>

        <Separator height={15} />

        <div className="confirm-actions">
          <Button variant="tonal" onClick={() => handleOnChangeAppointment('', interactionSelected)}>
            {intl.formatMessage(messages.cancelModal)}
          </Button>
          <Button onClick={() => handleCancel()}>
            {intl.formatMessage(messages.btnConfirmModal)}
          </Button>
        </div>
      </Modal>

      <Modal
        visible={limitsApplicationModalIsOpen}
        footer={false}
        maskClosable
        width={470}
        className={'customConfirm'}
        onCancel={() => handleOnChangeAppointment(cancelMessage, interactionSelected) }
      >
        <H4>
          <FormattedHTMLMessage id="application.canceled" />
        </H4>
        <Body2 className={pageStyles.descriptionModal}>
          {t({id:'applications.remaining'}, {count: applicationLimits?.remainingApplications + 1})}
        </Body2>
        <div className="confirm-actions">
          <Button onClick={() => handleOnChangeAppointment(cancelMessage, interactionSelected)}>
            <FormattedHTMLMessage id="nice.understood" />
          </Button>
        </div>
      </Modal>

      <MobileHeader event={event} />
      <MobileMenu
        displayInformal={event.modules?.informal1to1?.enable}
        displayLive={event.modules?.live && event.modules?.live?.participantEnable}
        liveLabel={event.modules?.live && event.modules?.live?.labelMultiple}
      />
    </div>
  );
}

Appointments.propTypes = {
  participant: object,
  relaunchAppointment: func,
  refuseAppointment: func,
  modifyAppointment: func,
  cancelAppointment: func,
  getInteractions: func,
  event: object,
  intl: object,
  authUser: object,
  cancelParticipant: func,
  interactions: array,
  goTo: func,
  history: object,
  location: object,
  availableActions: object,
}

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
  authToken: authSelectors.getAuthToken,
  participant: participantSelectors.getCurrentParticipant,
  event: eventSelectors.getCurrentEvent,
  pagination: participantSelectors.getParticipantPagination,
  interactions: interactionSelectors.getInteractions,
  availableActions: getAvailableActions,
});

const mapDispatchToProps = {
  relaunchAppointment: appointmentActions.relaunchAppointment,
  cancelAppointment: appointmentActions.cancelAppointment,
  modifyAppointment: appointmentActions.modifyAppointment,
  refuseAppointment: appointmentActions.refuseAppointment,
  cancelParticipant: participantActions.cancelParticipant,
  getInteractions: interactionActions.getInteractions,
  goTo: replace,

};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withLayout({ size: 'default', withHeader: false, navType: 'candidate' }),
  withRouter,
  withConnect,
  injectIntl,
)(toJS(Appointments));
