import React, { useState } from 'react'
import { object, func, bool } from 'prop-types'
import { useFormatMessage } from 'react-intl-hooks'
import colors from '@seekube-tech/ui-kit/dist/colors';

import { Body1, Caption1 } from '@seekube-tech/ui-kit'
import { Typography } from '@seekube-tech/ui'
import Textarea from '@/components/Textarea'
import styles from './styles.less';

const MAX_TEXTAREA_LENGTH = 200;

const InformalDescription = ({ settings, handleOnBlur, withWarning = true }) => {
  const t = useFormatMessage();
  const [countRemainingCharacter, setCountRemainingCharacter] = useState(MAX_TEXTAREA_LENGTH - (settings?.informalDescription?.length || 0));
  const { informalDescription } = settings;
  

  return (
    <>
      {withWarning &&
        <Body1 color={colors.warning['500']}>
          {t({ id: 'calendar.settingsSlot.informal1to1.description.warning' })}
        </Body1>
      }
      {withWarning &&
        <div style={{ textAlign: 'right' }}>
          <Caption1 color={colors.error['500']}>
            {
              t({ id: 'field.required' })
            }
          </Caption1>
        </div>
      }
      <div className={`${!withWarning && styles.neutral} ${(informalDescription && informalDescription.length === 0) ? styles.error : styles.warning}`}>
        <Textarea
          maxLength={200}
          value={informalDescription}
          label={t({ id: 'description' })}
          required
          placeholder={t({ id: 'calendar.settingsSlot.informal1to1.textarea.placeholder' })}
          id="description"
          fullWidth
          onBlur={(e) => handleOnBlur(e.target.value)}
          onChange={(e) => { setCountRemainingCharacter(MAX_TEXTAREA_LENGTH - e.target.value?.length); handleOnBlur(e.target.value);}}
          withFloatingPlaceholder
        />
      </div>
      <div style={{ textAlign: 'right' }}>
        <Caption1 variant="caption1" color={colors.neutral['300']}>
          {t({ id: 'remaining.typing.pl' }, { count: countRemainingCharacter })}
        </Caption1>
      </div>
    </>
  )
};

InformalDescription.propTypes = {
  settings: object,
  handleOnBlur: func,
  withWarning: bool,
}

export { InformalDescription }
