import React from 'react';
import { injectIntl } from 'react-intl';

// components
import { bool, func, instanceOf, object, oneOf } from 'prop-types';
import { Form, Select as SelectAnt } from 'antd';
import moment from 'moment';
import { Select } from '@/components/Form';

import messages from '../../messages';
import { useFormatMessage } from 'react-intl-hooks';

const FormItem = Form.Item;
const {Option} = SelectAnt;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

const Availability = ({ availabilityKey = 'matching-date', endDate, matchingData, withInputErrorStyle, showHelper, context, criterion, getFieldDecorator, getFieldsError, intl, authUser, onChange }) => {// eslint-disable-line
  const date = endDate || new Date();
  const y = date.getFullYear();
  const m = date.getMonth();
  const firstDay = new Date(y, m, 1);
  const t = useFormatMessage();

  moment.locale(authUser.locale);

  const dates = Array.from(Array(12).keys()).map((item) => {
    const now = item > 0 ? moment(firstDay).add(item, 'M') : moment(firstDay);

    return {
      index: item,
      value: item === 0 && context !== 'offer' ? 1 : now.unix(),
      label: item === 0 && context !== 'offer' ? intl.formatMessage(messages.availableToday) : now.format('MMMM YYYY'),
    };
  });

  const options = dates.filter((item) => item !== null)
    .map((date) => (<Option key={`dates${date.value}`} value={date.value.toString()}>{date.label}</Option>));

  const isRequired = criterion.modules[context].choiceMin > 0;
  return (
    <div id="matchingDateFormItem" style={{ display: 'inline' }}>
      <FormItem>
        {getFieldDecorator(availabilityKey, {
          initialValue: matchingData && matchingData.date ? matchingData.date.toString() : null,
          rules: [{ required: isRequired, message: t({ id: 'form.mandatory' }) }],
        })(
          <Select
            addOnIcon={criterion.icon}
            placeholder={authUser.locale === 'fr' ? criterion.modules[context].label : (authUser.locale === 'en' && criterion.modules[context].label_en ? criterion.modules[context].label_en : criterion.modules[context].label)}
            hasError={hasErrors(getFieldsError([availabilityKey]))}
            withInputErrorStyle={withInputErrorStyle}
            required={isRequired}
            showHelper={showHelper}
            onChange={onChange}
            getPopupContainer={() => document.getElementById('matchingDateFormItem')}
          >
            {options}
          </Select>
        )}
      </FormItem>
    </div>
  );
};

Availability.propTypes = {
  getFieldDecorator: func,
  endDate: instanceOf(Date),
  matchingData: object,
  withInputErrorStyle: bool,
  showHelper: func,
  context: oneOf(['offer', 'onboarding']),
  criterion: object,
  intl: object,
  authUser: object,
};

export default injectIntl(Availability);
