import React, { useEffect, useRef, useState } from 'react';
import { bool, string } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { Body1, Body2, Caption1, H5, IconMapPin } from '@seekube-tech/ui-kit';
import styles from './styles.less';
import { Field, FormSpy } from 'react-final-form';
import PictureV2 from '@/forms/Stand/components/CoverEditor/components/PictureV2';
import { Autocomplete, TextField } from '@seekube-tech/ui';
import Icon from '@/components/Icon';
import useValidations from '@/utils/validations/useValidations';
import GoogleMapLoader from 'react-google-maps-loader';
import CoverEditor from '@/forms/Stand/components/CoverEditor';

const GeneralInfo = ({ organizationId, disabledFields, trueAuthUser, role, organization, sectorsOptions, googleMaps = {} }) => {
  const t = useFormatMessage();

  const { isFilled } = useValidations();

  const sectorsIntlOptions = sectorsOptions.map((s) => ({
    ...s,
    name: t({ id: `sector[${s.key}]` },),
  }))

  const [options, setOptions] = useState([]);
  const timeout = useRef();

  const getGoogleMapAddress = (address) => {
    if (googleMaps) {
      const autoCompleteService = new googleMaps.places.AutocompleteService();

      if (autoCompleteService) {
        autoCompleteService.getPlacePredictions(
          {
            input: address,
            location: new googleMaps.LatLng(0, 0),
            radius: 20,
            types: ['(cities)'],
            componentRestrictions: { country: '' },
          },
          (googleSuggests) => {
            const formatedResults = googleSuggests
              .filter((suggest) =>
                suggest.types.includes('sublocality') === false &&
                suggest.types.includes('postal_code') === false
              )
              .map((suggest) => ({
                name: suggest.description,
                placeId: suggest.place_id,
              }));

            setOptions(formatedResults);
          },
        );
      }
    }
  };

  const handleInputChange = (_, value) => {
    if (value.length >= 3) {

      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }

      timeout.current = setTimeout(() => {
        getGoogleMapAddress(value);
      }, 500)
    }
  };

  useEffect(() => {
    getGoogleMapAddress('Paris');
  }, [googleMaps]);

  return (
    <div className={styles.generalInfoContainer}>
      <div className={styles.headerGeneralInfo}>
        <H5>{t({id: 'global.information'})}</H5>
        <Caption1 className={styles.required}>{t({id: 'form.helper.multi-required'})}</Caption1>
      </div>
      <div className={styles.upsideContent}>
        <Field
          name="pictureUrl"
          id="pictureUrl"
          component={PictureV2}
          organizationId={organizationId}
          validate={(value) => isFilled(value)}
          disabled={disabledFields}
        />
        <div className={styles.inputs}>
          <div className={styles.inputName}>
            <Field
              name="name"
              id="name"
              label={t({ id: 'event.recruiter.preparation.stand.item.company.label' })}
              placeholder={t({ id: 'event.recruiter.preparation.stand.item.company.label' })}
              component={TextField}
              fullWidth
              required
              icon={<Icon name="building" className={styles.icon} />}
              validate={(value) => isFilled(value)}
              disabled={!trueAuthUser.isAdmin && (role !== 'admin'  || !organization.isClient) }
            />
          </div>

          <Field
            name="location"
            id="location"
            label={t({ id: 'stand.hq' })}
            icon={<IconMapPin />}
            component={Autocomplete}
            options={options}
            onInputChange={handleInputChange}
            fullWidth
            getOptionLabel={(option) => option.name}
            disabled={disabledFields}
          />
        </div>
      </div>

      <Field
        name="sectors"
        id="sectors"
        label={t({ id: 'event.recruiter.preparation.stand.item.sectors.label' })}
        component={Autocomplete}
        fullWidth
        required
        isMultiple
        options={sectorsIntlOptions}
        getOptionLabel={(option) => option?.name}
        getOptionValue={(option) => option?.id}
        icon={<Icon name="tag" className={styles.icon} />}
        validate={(value) => isFilled(value)}
        disabled={disabledFields}
      />

      <div className={styles.coverContainer}>
        <div className={styles.coverTitle}>
          <Body2 className={styles.coverTitle1}>Image de couverture</Body2>
          <Body1 className={styles.coverTitle2}>(Taille recommandée : 2624x560  - 15 Mo max)<span className={styles.required}>*</span></Body1>
        </div>
        <FormSpy
        subscription={{ errors: true, submitFailed: true }}
        render={({ errors, submitFailed }) => (
          <Field
            name="cover"
            id="cover"
            component={CoverEditor}
            organizationId={organizationId}
            validate={(value) => isFilled(value)}
            disabled={disabledFields}
            required
          />
        )}/>

      </div>
    </div>
  );
}

GeneralInfo.propTypes = {
  organizationId: string,
  disabledFields: bool,
};

export default GoogleMapLoader(GeneralInfo, {
  libraries: ['places'],
  key: process.env.FRONT_GOOGLE_MAPS_API_KEY,
});
