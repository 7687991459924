import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import request from '@/utils/request';
import { getAuthToken } from '@/store/auth/selectors';
import { getCurrentParticipant } from '@/store/participant/selectors';
import { getCurrentEvent } from '@/store/event/selectors';

export function useGetDashboardTodo(props = {}) {
  const participant = useSelector(getCurrentParticipant)?.toJS();
  const event = useSelector(getCurrentEvent)?.toJS();

  const requestUrl = `${process.env.FRONT_API_URL}/events/${event._id}/participants/${participant._id}/dashboards/todo`;
  const queryFn = () => request(requestUrl, { method: 'GET' });

  const query = useQuery({
    queryKey: ['dashboard', 'todo'],
    queryFn,
    cacheTime: 0,
    ...props,
  });
  return query;
}
