import React from 'react';
import { Typography, Grid } from '@seekube-tech/ui';
import { Button } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { bool, func, object } from 'prop-types';
import { isEmpty } from 'lodash'

import { useSelector } from 'react-redux';
import Icon from '@/components/Icon';

import { getAvailableActions } from '@/store/availableActions/selectors';
import useStyles from './styles';
import { USERS_TYPE } from '@/utils/constants';

function Actions({ exponentUser, createInformal1To1, interaction, skipAppointmentValidation, cancelAppointment, updateAppointment, event, participant }) {
  /* Vars */
  const availableActions = useSelector(getAvailableActions);
  const t = useFormatMessage();
  const styles = useStyles();
  const disableAction = availableActions.candidateModifyAppointment.isDisabled({ event, role: USERS_TYPE.candidate, participant, interaction, })
  const hasAlreadyAnAppointment = (interaction?._appointment?.status === 'unconfirmed'
    || interaction?._appointment?.status === 'accepted'
    || (interaction?._appointment?.status === 'pending'
      && interaction?._appointment?.wasAcceptedAndUpdatedAt));

  if (event.limits.informal1to1To1RecruiterFromSameOrga && (exponentUser.isAlreadyChatInteractionInOrga && !hasAlreadyAnAppointment)) {
    return (
      <Grid container direction="row" spacing={1}>
        <Grid item>
          <Icon name="warning-circle" className={styles.icon} />
        </Grid>

        <Grid item>
          <Typography variant="caption1" color="warning[500]">
            {t({ id: 'candidate.jobdating.informal1to1.alreadyInteractionInOrga' })}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Button color="primary" disabled className="w-full">
            {skipAppointmentValidation ?
              t({ id: 'book' }) :
              t({ id: 'event.candidate.jobdating.offer.applicationModal.apply' })
            }
          </Button>
        </Grid>
      </Grid>
    );
  }

  if (exponentUser.isAlreadyInteraction) {
    return (
      <Grid container direction="row" spacing={1}>
        <Grid item>
          <Icon name="warning-circle" className={styles.icon} />
        </Grid>

        <Grid item>
          <Typography variant="caption1" color="warning[500]">
            {t({ id: 'candidate.jobdating.informal1to1.alreadyInteraction' })}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Button color="primary" disabled className="w-full">
            {skipAppointmentValidation ?
              t({ id: 'book' }) :
              t({ id: 'event.candidate.jobdating.offer.applicationModal.apply' })
            }
          </Button>
        </Grid>
      </Grid>
    );
  }

  if (interaction?._appointment?.status === 'refused' || interaction?._appointment?.status === 'canceled') {
    return (
      <Grid container direction="row" spacing={1}>
        <Grid item>
          <Icon name="warning-circle" className={styles.icon} />
        </Grid>

        <Grid item>
          <Typography variant="caption1" color="warning[500]">
            {t({ id: 'candidate.jobdating.informal1to1.alreadyHadInteraction' })}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Button color="primary" disabled className="w-full">
            {skipAppointmentValidation ?
              t({ id: 'book' }) :
              t({ id: 'event.candidate.jobdating.offer.applicationModal.apply' })
            }
          </Button>
        </Grid>
      </Grid>
    );
  }

  if (interaction?._appointment?.status === 'pending' && isEmpty(interaction?._appointment?.wasAcceptedAndUpdatedAt)) {
    return (
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Grid item>
          <Button variant="tonal" color="primary" onClick={cancelAppointment}>
            {t({ id: 'cancel' })}
          </Button>
        </Grid>
      </Grid>
    )
  }

  if (interaction?._appointment?.status === 'unconfirmed'
    || interaction?._appointment?.status === 'accepted'
    || (interaction?._appointment?.status === 'pending'
      && interaction?._appointment?.wasAcceptedAndUpdatedAt)
  ) {
    return (
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={6} container direction="column" justifyContent="center" alignItems="center">
          <Button variant="tonal" color="primary" className="w-full" onClick={cancelAppointment}>
            {t({ id: 'cancel' })}
          </Button>
        </Grid>

        <Grid item xs={6}>
          <Button color="primary" className="w-full" onClick={updateAppointment} disabled={disableAction}>
            {t({ id: 'admin.organizations.detail.stand.edit' })}
          </Button>
        </Grid>
      </Grid>
    );
  }

  if (exponentUser.countInformal1to1Slots === 0) {
    return null;
  }

  if (availableActions.bookAppointment.isDisabled({ event, role: 'candidate', participant, exponentKeyMomentFormats: exponentUser.keyMomentFormats })) {
    return (
      <Grid container direction="row" spacing={1} wrap="nowrap" alignItems="center">
        <Grid item>
          <Icon name="warning-circle" className={styles.icon} />
        </Grid>

        <Grid item>
          <Typography variant="caption1" color="warning[500]">
            {t({ id: 'candidate.offer.applications-disabled.description' })}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Button color="primary" className="w-full" onClick={createInformal1To1} disabled={false}>
      {skipAppointmentValidation ?
        t({ id: 'book' }) :
        t({ id: 'event.candidate.jobdating.offer.applicationModal.apply' })
      }
    </Button>
  );
}

Actions.propTypes = {
  exponentUser: object,
  createInformal1To1: func,
  cancelAppointment: func,
  interaction: object,
  skipAppointmentValidation: bool,
  updateAppointment: func,
  event: object,
  participant: object,
};

export default Actions;
