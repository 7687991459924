import React, { useEffect, useState } from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import styles from './styles.less';
import { Field, FormSpy } from 'react-final-form';
import { bool } from 'prop-types';
import { TextField, Textarea } from '@seekube-tech/ui';
import { Caption1, H5, IconAlignLeft } from '@seekube-tech/ui-kit';
import useValidations from '@/utils/validations/useValidations';

const Presentation = ({ disabledFields}) => {
  const t = useFormatMessage();
  const { isFilled } = useValidations();

  return (
    <div className={styles.presentationContainer}>
      <div className={styles.headerPresentation}>
        <H5>{t({id: 'event.candidate.preparation.signup.step.presentation'})}</H5>
        <Caption1 className={styles.required}>{t({id: 'form.helper.multi-required'})}</Caption1>
      </div>
      <Field
        name={"presentation.title"}
        id="presentationTitle"
        label={t({ id: 'owner.settings.criteriaTable.view.title' })}
        placeholder={t({ id: 'owner.settings.criteriaTable.view.title' })}
        component={TextField}
        fullWidth
        required
        defaultValue={'Presentation'}
        validate={(value) => isFilled(value)}
        icon={<IconAlignLeft />}
        disabled={disabledFields}
      />
      <Field
        name={"presentation.content"}
        id="presentation"
        label={t({id:'conference.topics.company'})}
        placeholder={t({ id: 'event.recruiter.preparation.stand.item.description.placeholder' })}
        component={Textarea}
        fullWidth
        required
        validate={(value) => isFilled(value) || value === '<p class="ql-align-justify"><br></p>' || value === '<p><br></p>'}
        disabled={disabledFields}
      />
    </div>
  );
}

Presentation.propTypes = {
  disabledFields: bool,
};

export default Presentation;
