import React from 'react';
import Dropzone from 'react-dropzone';
import { useFormatMessage } from 'react-intl-hooks';
import { useFieldHelperText } from '@seekube-tech/ui';
import { func, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Spin } from 'antd';
import classnames from 'classnames';

import { authSelectors } from '@/store/auth';
import { notificationActions } from '@/store/notification';

import Icon from '@/components/Icon';

import useUpload from '../../utils/useUpload';

import styles from './styles';

function PictureV2({ meta, input, organizationId, authToken, sendNotification, id, disabled }) {
  /* Vars */

  const t = useFormatMessage();
  const { hasError } = useFieldHelperText(meta);


  // TODO : Move organizationId, authToken, sendNotification in useUpload hook when redux hook is available
  const [onDrop, isLoading] = useUpload({
    onSuccess: input.onChange,
    organizationId,
    authToken,
    sendNotification,
  });

  /* Render */

  return (
    <Dropzone
      accept="image/png, image/jpeg, image/gif"
      onDrop={onDrop}
      className={classnames(styles.logoZone, {[styles.error]: hasError} )}
      activeClassName={styles.activeDropzone}
      style={{ backgroundImage: `url(${input.value})` }}
      id={id}
      disabled={disabled}
    >
      {isLoading && (
        <div className={styles.loader}><Spin /></div>
      )}
      {!disabled &&
        <div className={classnames(styles.help, { [styles.filled]: Boolean(input.value)})}>
          <Icon name="upload" />

          <div>{t({ id: 'event.recruiter.preparation.stand.item.logo.label' })} <span className="mandatory-symbol">*</span></div>
        </div>
      }
    </Dropzone>
  );
}

const mapStateToProps = createStructuredSelector({
  authToken: authSelectors.getAuthToken,
});

const mapDispatchToProps = {
  sendNotification: notificationActions.sendNotification,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

PictureV2.propTypes = {
  meta: object.isRequired,
  input: shape({
    value: string,
    onChange: func,
  }).isRequired,
  organizationId: string,
  authToken: string,
  sendNotification: func.isRequired,
  id: string.isRequired,
};

PictureV2.defaultProps = {
  organizationId: null,
  authToken: '',
};

export default compose(withConnect)(PictureV2);
