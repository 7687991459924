import React from 'react';
import { bool, object, number, func, any, string } from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import classnames from 'classnames';
import { Typography } from '@seekube-tech/ui';

// component
import Avatar from '@/components/Avatar';
import Icon from '@/components/Icon';
import { H3 } from '@/components/Title';
import Html from '@/components/Html';

import './styles.less';
import messages from './messages';

export const UserCard = ({ user, title, content, className, style }) => (
  <div className={classnames('recruiterCard', className)} style={style}>
    <div className={'leftSide'}>
      <Avatar size={60} src={user.pictureUrl} user={user} />
    </div>
    <div className={'rightSide'}>
      <div>
        <h3 className={'title'}>{title || user.fullName}</h3>
        <p className={'content'}>{content || user.title} </p>
      </div>
    </div>
  </div>
);

const AppointmentDetails = ({ appointment, recruiter, medium, location, duration, tracker, intl, hideContact }) => (
  <div className="appointment-details">
    <div className="appointment-details-title">
      <H3 bold><FormattedMessage {...messages.about} /></H3>
    </div>

    <UserCard
      user={appointment ? appointment._organizationUser : recruiter}
    />

    <ul className="infos">
      {medium && medium !== 'physical' ?
        <li>
          <Icon name={medium} style={{ width: '22px', height: '22px', position: 'relative', top: '2px' }} /> <FormattedMessage {...messages.medium} />
          &nbsp;{intl.formatMessage(messages[medium])}
        </li>
        : null
      }

      {duration > 0 ? (
        <li>
          <Icon name="slots-calendar" style={{ width: '22px', height: '18px' }} /> <FormattedMessage {...messages.duration} /> {duration} <FormattedMessage {...messages.minutes} />
        </li>
      ) : null}

      {medium === 'physical' ?
        <li>
          <Icon name="location" /> {`${intl.formatMessage(messages.location)} : ${location}`}
        </li>
        : null
      }
    </ul>

    {appointment?.origin === 'informal1to1' && (
      <>
        <Typography variant="subtitle2" className="description">
          {intl.formatMessage({ id: 'description' })}
        </Typography>

        <Typography variant="body2">
          {appointment?.informalDescription}
        </Typography>
      </>
    )}

  </div>
);

UserCard.propTypes = {
  user: object,
  title: string,
  content: string,
  className: any,
  style: any,
};

AppointmentDetails.propTypes = {
  intl: object,
  tracker: func,
  appointment: object,
  recruiter: object,
  duration: number,
  hideContact: bool,
  medium: string,
  location: string,
};

export default React.memo(injectIntl(AppointmentDetails));
