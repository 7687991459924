import React from 'react';
import { object } from 'prop-types';
import { useSelector } from 'react-redux';
import { Col } from 'antd';
import { H3 } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { getAuthUser } from '@/store/auth/selectors';
import styles from './styles.less';
import { JobdatingCard } from '@/scenes/Events/scenes/components/JobdatingsExplore/components/JobdatingCard';
import { useGetEventSuggestions } from '@/queries/events/useGetEventSuggestions';
import { Bloc } from '@/components/Bloc';

const DiscoverMoreEvents = ({
  event,
}) => {
  const history = useHistory();
  const isVisible = isEmpty(history.location.hash) && event.type === 'seekube';

  const t = useFormatMessage()
  const authUser = useSelector(getAuthUser)?.toJS()
  const { push } = useHistory();

  const eventsSuggested = useGetEventSuggestions({
    currentEventId: event.slug,
    enabled: isVisible,
    search: {
      limit: 3,
      publications: "b2c",
      types: "seekube",
      excludeEventId: event._id,
    }
  }).data?.docs


  return (
    isVisible ?
      <Bloc>
        <div className={styles.landingV3Container}>
          <div className={styles.landingSuggestion}>
            <H3 className={styles.landingSuggestionTitle}>{t({ id: 'landing.event.suggested.title' })}</H3>
            {eventsSuggested?.map((_event) => (
              <Col xs={12} sm={12} md={6} lg={6} xl={4} key={_event._id}>
                <JobdatingCard classname={styles.landingSuggestionCard} event={_event} goTo={push} locale={authUser?.locale} authUser={authUser} />
              </Col>
            ))}
          </div>
        </div>
      </Bloc> : null
  );
}

DiscoverMoreEvents.propTypes = {
  event: object,
}

export { DiscoverMoreEvents };
