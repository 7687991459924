import React from 'react';
import { injectIntl } from 'react-intl';
import { bool, func, object, array, int, any } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Button, Alert } from '@seekube-tech/ui-kit';
import { createStructuredSelector } from 'reselect';
import { isEmpty, get } from 'lodash';
import { Modal } from 'antd';
import { toJS } from '@/utils';
import { track } from '@/utils/analytics';
import { ANALYTICS_RECRUITER } from '@/utils/constants';
import { authSelectors } from '@/store/auth';
import { offerActions } from '@/store/offer';
import { eventSelectors } from '@/store/event';
import Icon from '@/components/Icon';
import Separator from '@/components/Separator';
import ModalBig from '@/components/ModalBig';
import MatchingHelp from '@/components/MatchingHelp';
import Html from '@/components/Html';
import { If } from '@/components/If';
import CreateOfferForm from './components/CreateOfferForm';
import InputHelper from './components/InputHelper';
import styles from './styles.less';
import messages from '../../messages';

class CreateOfferModal extends React.PureComponent {
  static propTypes = {
    isOpen: bool,
    onClose: func,
    authUser: object,
    currentEvent: object,
    postOffer: func,
    onPreview: func,
    patchOffer: func,
    offer: object,
    exponent: object,
    intl: object,
    disableRegisterOffer: bool,
    maxOffers: int,
    publishedOffers: array,
    warningOfferLimit: any,
    onSubmit: any,
    isOwner: bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      hasConfirmModal: false,
      confirmRequired: true,
      currentHelper: null,
      currentOfferValues: null,
      offerFormHeight: 'auto',
    };
  }

  static getDerivedStateFromProps({ offer, exponents }, state) {
    if (isEmpty(offer)) {
      return { currentOfferValues: null };
    }
    if ((!isEmpty(offer) && state.currentOfferValues === null) ||
      (!isEmpty(offer) && !isEmpty(state.currentOfferValues) && state.currentOfferValues._id !== offer._id)) {
      return { currentOfferValues: offer, confirmRequired: true };
    }
    return null;
  }

  getOfferFormHeight = (height) => (this.setState({ offerFormHeight: height }));

  hasHelperContent = (id, title) => this.setState({ currentHelper: id !== null ? { id, title } : null });

  getCurrentHelpContent = () => {
    const {
      props: { currentEvent, intl, },
      state: { currentOfferValues, currentHelper }
    } = this;
    return currentHelper ? (
      <div>
        <InputHelper id={currentHelper ? currentHelper.id : null} title={currentHelper ? currentHelper.title : null} isVisible={currentHelper !== null} />

        <MatchingHelp
          className={styles.matchingHelp}
          criteria={currentEvent._criteria}
          matching={currentOfferValues ? currentOfferValues.matching : null}
          context="offer"
          text={intl.formatMessage(messages.offerFormCompatible)}
        />
      </div>
    ) : (
      <If condition={get(currentEvent, 'descriptionCandidatesTarget')}>
        <div>
          <InputHelper id="default" title={intl.formatMessage(messages.titleHelpDefault)} isVisible />
          <div className={styles.defaultHelpText}>
            <Html value={get(currentEvent, 'descriptionCandidatesTarget')} />
          </div>
        </div>
      </If>
    );
  }


  openConfirmModal = () => {
    this.setState({ hasConfirmModal: true });
  };

  closeConfirmModal = () => {
    this.setState({ hasConfirmModal: false });
    this.props.onClose();
  };

  handleClose = () => {
    const { props: { onClose }, state: { confirmRequired }, openConfirmModal } = this;

    if (confirmRequired) {
      openConfirmModal()
    } else {
      onClose();
    }
  };

  trackData = (values) => {
    const { props: { currentEvent, authUser, offer } } = this;
    const customFilters = currentEvent._criteria.filter((item) => item.enable && item.modules.offer.isVisible);
    let countChoices = 0;
    const formValues = values;

    customFilters.forEach(({ fieldName, modules }) => {
      if (modules.offer.choiceMin === 0) {
        if (Number.isNaN(parseInt(fieldName, 2)) === false) {
          countChoices += formValues?.[`custom${fieldName}`]?.length || 0;
        } else {
          countChoices += formValues?.[fieldName]?.length || 0;
        }
      }
    });

    return {
      user: authUser,
      event: currentEvent,
      name: values.status === 'published' ? ANALYTICS_RECRUITER.PUBLISHED_OFFER : ANALYTICS_RECRUITER.DRAFTED_OFFER,
      properties: {
        'Matching criteria': customFilters.length > 0,
        'Matching Criteria Quantity': customFilters.length,
        'Matching Selections Quantity': countChoices,
        'My Offer': formValues._user === authUser._id,
        'Edited recruiter': !isEmpty(offer) && offer._user && values._user !== ((offer._user._id && offer._user._id.toString()) || offer._user),
      },
    };
  }

  updateOffer = (values) => {
    const { props: { patchOffer, currentEvent, offer, intl, onSubmit }, } = this;
    const formValues = values;

    if (onSubmit) {
      onSubmit({ ...formValues, _id: offer._id }, () => { track(this.trackData(values)) });
    } else {
      patchOffer({
        offerId: offer._id,
        offerParams: formValues,
        _event: currentEvent._id,
        notificationParams: {
          success: {
            message: `🙌 ${intl.formatMessage(messages.offerFormNotificationSuccessSubmit)}`,
            kind: 'info',
            style: {
              bottom: '5%',
              top: 'inherit',
            },
          },
          error: true,
        },
        callback: track(this.trackData(values)),
      });
    }
  }

  createOffer = (values) => {
    const { props: { postOffer, currentEvent, authUser, intl, onSubmit } } = this;
    const formValues = values;
    if (values._user === undefined) {
      formValues._user = authUser._id;
    }
    if (onSubmit) {
      onSubmit(formValues, () => { track(this.trackData(values)) });
    } else {
      postOffer({
        offerParams: {
          ...formValues,
          _organization: authUser._currentOrganization._id,
        },
        eventId: currentEvent._id,
        notificationParams: {
          success: {
            message: intl.formatMessage(messages.offerFormNotificationSuccessAddition),
            kind: 'info',
            style: {
              bottom: '5%',
              top: 'inherit',
            },
          },
          error: true,
        },
        callback: track(this.trackData(values)),
      });
      this.setState({ currentOfferValues: null });
    }
  }


  handleOnSubmit = (values) => {
    const { props: { offer } } = this;
    this.setState({ confirmRequired: false });
    if (!isEmpty(offer) && offer._id) {
      this.updateOffer(values)
    } else {
      this.createOffer(values)
    }
    this.handleClose()
  };

  getUsers = () => {
    const { props: { exponent } } = this;
    return (exponent ? exponent.users.filter((item) => item._user).map((item) => item._user) : [])
  }

  render() {
    const {
      props: { isOpen, authUser, currentEvent, offer, onPreview, intl, disableRegisterOffer, publishedOffers, maxOffers, warningOfferLimit, isOwner },
      state: { offerFormHeight, hasConfirmModal },
      handleOnSubmit, handleClose, getOfferFormHeight, hasHelperContent, getCurrentHelpContent, getUsers
    } = this;

    if (isEmpty(authUser)) {
      return null;
    }

    return (
      <ModalBig
        isOpen={isOpen}
        onClose={handleClose}
        sideContent={getCurrentHelpContent}
        offerFormHeight={offerFormHeight}
      >
        <Modal
          visible={hasConfirmModal}
          footer={false}
          maskClosable
          width={470}
          className="customConfirm"
          onCancel={this.closeConfirmModal}
        >
          <a role="button" tabIndex={0} className="modal-close" onClick={this.closeConfirmModal}>
            <Icon name="close" className="modal-close-icon" />
          </a>

          <h4 className="ant-confirm-title">{['draft', 'expired'].includes(offer?.status) ? intl.formatMessage(messages.offerFormWarningClose) : intl.formatMessage(messages.offerPublishedFormUpdate)}</h4>

          <Separator height={30} />

          <div className="confirm-actions">
            <Button onClick={this.closeConfirmModal}>{intl.formatMessage(messages.exit)}</Button>
          </div>
        </Modal>

        <div className="modalBigWrapper">
          <h1 className="mainTitle">{offer === null ? intl.formatMessage(messages.offerFormAdd) : intl.formatMessage(messages.offerFormUpdate)}</h1>
          {isOwner && offer?.status === 'published' &&
            <Alert>{intl.formatMessage({ id: 'owner.modal.offer.alert.edit' })}</Alert>}
          {isOpen && (
            <CreateOfferForm
              event={currentEvent}
              user={authUser}
              offer={offer}
              users={getUsers()}
              onSubmit={handleOnSubmit}
              onOpen={onPreview}
              getOfferFormHeight={getOfferFormHeight}
              showHelper={hasHelperContent}
              disableRegisterOffer={disableRegisterOffer}
              publishedOffers={publishedOffers}
              maxOffers={maxOffers}
              warningOfferLimit={warningOfferLimit}
              isOwner={isOwner}
            />
          )}
        </div>
      </ModalBig>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
  currentEvent: eventSelectors.getCurrentEvent,
});

const mapDispatchToProps = {
  postOffer: offerActions.postOffer,
  patchOffer: offerActions.patchOffer,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  injectIntl,
)(toJS(CreateOfferModal));
