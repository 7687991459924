import React from 'react';
import { any, string, object, oneOf, func, bool } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { map } from 'lodash';
import randtoken from 'rand-token';
import mime from 'mime-types';

import { toJS } from '@/utils';
import request from '@/utils/request';

// Store
import { authSelectors } from '@/store/auth';
import { eventSelectors } from '@/store/event';
import { uploadActions } from '@/store/upload';
import requestExternal from '@/utils/requestExternal';


const uploadtest = async (files, askAWSUrl, uploadFile, waitAWS, uploadStart, uploadStop, onError, onChange) => {
  uploadStart();
  const result = await Promise.all(
    map(files,
      async (file) => {
        let url = '';

        const fileName = `${randtoken.generate(16)}.${mime.extension(file.type)}`;
        const newFile = new File([file], fileName, { type: file.type });
        try {
          const result = await askAWSUrl(newFile);

          // Ask AWSUrl in our api
          url = result.url; // Save public URL
          await waitAWS(); // Upload the file on AWS
          await uploadFile(result.signedRequest, newFile); // Upload the file on AWS
        } catch (e) {
          onError(e);
        }

        return { url, name: file.name };
      }
    ));
  uploadStop();
  onChange(result);
};
class S3Upload extends React.PureComponent {
  static propTypes = {
    children: any,
    uploadType: oneOf(['user', 'event']),
    authUser: object,
    authToken: string,
    currentEvent: object,
    inputAccept: string,
    onChange: func,
    onError: func,
    uploadStart: func,
    uploadStop: func,
    multi: bool,
  };

  static defaultProps = {
    inputAccept: '', // '.jpg, .png'
    uploadType: 'user',
    multi: false,
  };

  /**
   * HandleOnClick
   *
   * @description
   * Handle click and click on the input file hidden
   */
  handleOnClick = () => {
    const { fileInputEl } = this;

    fileInputEl.click();
  };

  /**
   * HandleOnChange
   *
   * @description
   * Handle change from the input and upload all files
   */
  handleOnChange = ({ target: { files } }) => {
    const {
      props: { onChange, uploadStart, uploadStop, onError, multi },
      askAWSUrl, uploadFile, waitAWS,
    } = this;
    if (multi) {
      uploadtest(files, askAWSUrl, uploadFile, waitAWS, uploadStart, uploadStop, onError, onChange);
    } else {
      const file = files[0];
      let url = '';

      uploadStart();

      const fileName = `${randtoken.generate(16)}.${mime.extension(file.type)}`;
      const newFile = new File([file], fileName, { type: file.type });

      // Ask AWSUrl in our api
      askAWSUrl(newFile)
        .then((result) => {
          url = result.url; // Save public URL
          return uploadFile(result.signedRequest, newFile); // Upload the file on AWS
        })
        .then(waitAWS) // Wait AWS
        .then(() => {
          // Need to return the generate file from AWS in _big format
          onChange(url); // Send to the props the publicURL
          uploadStop();
        })
        .catch(onError);
    }
  }

  /**
   * WaitAWS
   *
   * @description
   * Return promise when timeout is finish
   * Hack because we have AWS who resize images and it's long ...
   */
  waitAWS = () => new Promise((resolve) => {
    setTimeout(resolve, 3500);
  })

  /**
   * AskAWSUrl
   *
   * @description
   * Get signedUrl from our API
   */
  askAWSUrl = (file) => {
    const { props: { currentEvent, authUser, uploadType } } = this;

    if (uploadType === 'user') {
      return request(`
        ${process.env.FRONT_API_URL}/users/${authUser._id}/upload?fileName=${file.name}
      `);
    }

    return request(`
      ${process.env.FRONT_API_URL}/events/${currentEvent._id}/upload?fileName=${file.name}
    `);
  }

  /**
   * UploadFile
   *
   * @description
   * Upload file on AWS
   */
  uploadFile = (awsUrl, file) => {
    const headers = {
      'Content-Type': file.type,
    };

    return requestExternal(awsUrl, {
      method: 'PUT',
      headers,
      body: file,
    });
  }

  /**
   * RenderChildren
   *
   * @description
   * Render children and listen onClick props and handle it
   */
  renderChildren = () => {
    const { props: { children }, handleOnClick } = this;

    return React.cloneElement(children, { onClick: handleOnClick });
  };


  render() {
    const {
      props: { inputAccept },
      handleOnChange, renderChildren,
    } = this;

    return (
      <div>
        <input
          multiple
          onChange={handleOnChange}
          style={{ display: 'none' }}
          ref={(fileInputEl) => { this.fileInputEl = fileInputEl; }}
          type="file"
          accept={inputAccept}
        />

        {renderChildren()}
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  currentEvent: eventSelectors.getCurrentEvent,
  authUser: authSelectors.getAuthUser,
  authToken: authSelectors.getAuthToken,
});

const mapDispatchToProps = {
  uploadStart: uploadActions.uploadStart,
  uploadStop: uploadActions.uploadStop,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(toJS(S3Upload));
