import { round, last, split, words, flow, reduce, difference, size, isEmpty, filter, find, isArray, map, join } from 'lodash';
import { getId } from './global';
import isObject from 'lodash/isObject';

export const mergeArray = (...arrays) => arrays.reduce((previousValue, currentValue) => ([...(isArray(previousValue) ? previousValue : [previousValue]), ...(isArray(currentValue) ? currentValue : [currentValue])]), []);

export const reduceWithOperator = (array, key, operator, defaultValue = 0) => reduce(array, (previousValue, currentValue) => (operator(previousValue, currentValue[key])), defaultValue);

export const strictIncludes = (array = [], character) => array.indexOf(character) > -1;

export const percentage = (nbr = 0, total = 0, precision = 0, bounded = false) => bounded && nbr > total ? 100 : round((100 * nbr) / (total || 1), precision);

export const arrayContain = (a, b) => !isEmpty(a) ? size(difference(b, a)) < size(b) : false;

export const arrayContainAll = (a, b) => isEmpty(difference(b, a));

export const toggleCollection = (collection, key, val) => !isEmpty(find(collection, (coll) => getId(coll[key]) === getId(val[key]))) ? filter(collection, (obj) => getId(obj[key]) !== getId(val[key])) : [...collection, val];

export const getLastWord = flow([words, last]);

export const getLastPathInUrl = flow([(url) => split(url, '/'), last]);

export const replacePathInUrl = (url, pathToReplace, newPath) => flow([(url) => split(url, '/'), (arrOfPath) => map(arrOfPath, path => path === pathToReplace ? newPath : path)], (arrOfPath) => join(arrOfPath, '/'))(url);

export const  flatObject = (obj) => {
  if (!isObject(obj)) {
    return null;
  }
  const result = {};

  function recurse(current, prop) {
    if (Object(current) !== current) {
      result[prop] = current;
    } else if (Array.isArray(current)) {
      const l = current.length;
      for (let i = 0; i < l; i++) {
        recurse(current[i], `${prop}[${i}]`);
      }
      if (l === 0) {
        result[prop] = [];
      }
    } else {
      let isEmpty = true;
      for (const p in current) {
        if (Object.prototype.hasOwnProperty.call(current, p)) {
          isEmpty = false;
          recurse(current[p], prop ? `${prop}.${p}` : p);
        }
      }
      if (isEmpty && prop) {
        result[prop] = {};
      }
    }
  }

  recurse(obj, '');
  return result;
}
