import React from 'react';
import { shape, func, any, object, string, number } from 'prop-types';
import { isUndefined, isEmpty } from 'lodash';
import uuidv1 from 'uuid/v1';
import { injectIntl } from 'react-intl';
import { Col, Form, Row } from 'antd/lib/index';
import { Button, H5 } from '@seekube-tech/ui-kit';
import { ANALYTICS_CANDIDATE } from '@/utils/constants';
import request from '@/utils/request';
import { track } from '@/utils/analytics';
import 'react-phone-number-input/style.css';

// components
import AvatarEditorDnd from '@/components/AvatarEditorDnd';
import requestExternal from '@/utils/requestExternal';
import {
  Phone
} from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Profile/Presentation/Phone';
import {
  LastName
} from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Profile/Presentation/LastName';
import {
  FirstName
} from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Profile/Presentation/FirstName';
import {
  Gender
} from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Profile/Presentation/Gender';
import {
  StudentId
} from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Profile/Presentation/StudentId';
import { Bloc } from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/components/Bloc';
import fetchService from '@/services/fetch';
import {
  NotificationBySmsForUpcomingEvent
} from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Profile/Presentation/NotificationBySmsForUpcomingEvent';

const FormItem = Form.Item;


class Presentation extends React.PureComponent {
  static defaultProps = {
    context: 'event',
  };

  constructor(props) {
    super(props);
    this.state = {
      pictureUrl: !isUndefined(props.user.pictureUrl) ? props.user.pictureUrl : null,
      uploadUrl: null,
      uploadingProfilePicture: null,
      linkedinStatus: null,
      value: null,
    };
  }

  componentDidMount() {
    const { form: { setFieldsValue }, user, event } = this.props;

    setFieldsValue({
      gender: user.gender || window.localStorage.getItem('signup-civility'),
      firstName: user.firstName || window.localStorage.getItem('signup-fn'),
      lastName: user.lastName || window.localStorage.getItem('signup-ln'),
      pictureUrl: user.pictureUrl,
      phone: user.phone || window.localStorage.getItem('signup-phone'),
    });
  }

  /**
   * UpdateInput
   *
   * @description
   * Set input value to the new file url
   */
  updateInput = () => {
    const { form: { setFieldsValue } } = this.props;

    setFieldsValue({
      pictureUrl: this.state.pictureUrl,
    });
  }

  /**
   * SaveFile
   *
   * @description
   * Save file url on state
   */
  saveFile = (fileUrl) => {
    const { updateInput } = this;

    this.setState({
      pictureUrl: fileUrl,
      uploadUrl: null,
      uploadingProfilePicture: null,
    }, () => {
      updateInput();
    });
  };

  /**
   * UpdateInput
   *
   * @description
   * Set input value to the new file url
   */
  updateInput = () => {
    const { form: { setFieldsValue } } = this.props;

    setFieldsValue({
      pictureUrl: this.state.pictureUrl,
    });
  };

  handleRemoveProfilePicture = () => {
    this.setState({
      pictureUrl: null,
      linkedinStatus: this.state.linkedinStatus === 'success' ? null : this.state.linkedinStatus,
    }, () => this.updateInput());
  };

  handleOnSocialLoginFailure = () => {
    this.setState({ linkedinStatus: 'error' });
  };

  /**
   * HandleSocialLogin
   *
   * @description
   * Get provider user info for set fields values & pictureUrl
   *
   */
  handleSocialLogin = (user) => {
    const { props: { event, form: { setFieldsValue } } } = this;

    this.setState({ linkedinStatus: 'loading' });

    return new Promise((resolve, reject) => {
      request(`${process.env.FRONT_API_URL}/users/oauth/infos`, {
        method: 'POST',
        body: JSON.stringify({
          token: user._token.accessToken,
          provider: 'linkedin',
        }),
      })
      .then((result) => {
        this.setState({ linkedinStatus: 'success' });

        const tempUser = {
          firstName: result.infos.firstName,
          lastName: result.infos.lastName,
          title: result.infos.headline,
          description: result.infos.summary,
          linkedinProfileUrl: result.infos.publicProfileUrl,
        };

        setFieldsValue({
          firstName: result.infos.firstName,
          lastName: result.infos.lastName,
        });

        // this.setState({ profilePicture: result.pictureUrl });
        this.uploadExternalProfilePicture(result.infos.pictureUrl);

        // success
        track({
          user: Object.assign(this.props.user, tempUser),
          name: ANALYTICS_CANDIDATE.IMPORT_LINKEDIN,
          event,
          properties: {
            step: 'Sign up',
          },
        });
      })
      .catch((e) => {
        this.setState({ linkedinStatus: 'error' });
        reject(e);
      });
    });
  };

  /**
   * uploadExternalProfilePicture
   *
   * @description
   * Handle upload an external picture from provider to S3
   *
   */
  uploadExternalProfilePicture = (pictureUrl) => {
    const { updateInput, props: { user } } = this;

    return new Promise((resolve, reject) => {
      // Fetch the blob
      fetchService(pictureUrl)
      .then((result) => result.blob())
      .then((blob) => {
        // ask a signed url to S3
        request(`${process.env.FRONT_API_URL}/users/${user._id}/upload?fileName=${uuidv1()}.jpg`)
        .then((response) => {
          const fileUrl = response.url;

          // then upload the blob to s3
          requestExternal(response.signedRequest, {
            method: 'PUT',
            headers: {
              'Content-Type': blob.type,
            },
            body: blob,
          })
          .then(() => {
            this.setState({
              pictureUrl: fileUrl,
              pictureFromProvider: true,
            }, () => {
              updateInput();
            });
          })
          .catch((uploaderror) => console.log(uploaderror)); // eslint-disable-line
        })
        .catch((awserror) => console.log(awserror)); // eslint-disable-line
      })
      .catch((bloberr) => reject(bloberr));
    });
  };

  render() {
    const {
      props: { user, form, intl, event },
      state: { pictureUrl },
      saveFile,
    } = this;

    const { getFieldDecorator } = form;
    const btnPictureText = isEmpty(user) || !user.pictureUrl ? intl.formatMessage({ id: 'pictureUrl.choose' }) : intl.formatMessage({ id: 'pictureUrl.update' });


    const regexCriteria = event?._criteria?.find((criteria) => criteria.key === 'REGEXID');
    const title = event?.participants?.onboarding?.find((o) => o.name === 'presentation')?.[`title_${intl.locale}`];

    return (
      <Bloc>
        <div className="commonStep">
          <H5 className="mb-24">{!isEmpty(title) ? title : intl.formatMessage({ id: 'event.candidate.preparation.signup.step.presentation' })}</H5>
          <div className="pictureContainer">
            <div className="picture">
              <AvatarEditorDnd
                src={pictureUrl || undefined}
                onSave={saveFile}
                onRemove={this.handleRemoveProfilePicture}
                objectId={user._id}
                size={130}
              >
                <div className="pictureBtnAction">
                  <Button className="pictureBtn" variant="outline">{btnPictureText}</Button>

                  <p className="upload-infos">
                    {intl.formatMessage({ id: 'pictureUrl.format' })}
                  </p>
                </div>
              </AvatarEditorDnd>
            </div>
          </div>

          <FormItem className="pictureItem">
            {getFieldDecorator('pictureUrl', {
              rules: [{ required: false }],
            })(
              <input type="hidden" />
            )}
          </FormItem>

          <div className="fieldsContainer">
            <Row gutter={16}>
              <Col xs={16}>
                <Gender form={form} user={user} />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={6}>
                <FirstName form={form} user={user} />
              </Col>
              <Col xs={6}>
                <LastName form={form} user={user} />
              </Col>
            </Row>

            <Phone form={form} user={user} />

            <NotificationBySmsForUpcomingEvent form={form} user={user} />

            {regexCriteria?.modules?.onboarding?.isVisible && <StudentId form={form} user={user} regexCriteria={regexCriteria} />}
          </div>
        </div>
      </Bloc>
    );
  }
}

Presentation.propTypes = {
  form: shape({
    getFieldDecorator: func,
    getFieldsError: func,
    getFieldError: func,
    isFieldTouched: func,
  }),
  handleDataUpdate: func,
  currentStep: any,
  user: object,
  event: object,
  intl: object,
  context: string,
  stepPosition: number,
  countEnabledSteps: number,
  eventSteps: object,
};

export default injectIntl(Presentation);
