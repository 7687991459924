import React from 'react';
import { string } from 'prop-types';

import Icon from '@/components/Icon';

import styles from './styles.less';


const AppointmentDate = ({
  beginAt,
  endAt,
}) => (
  <span className={styles.appointmentTime}>
    <Icon name="clock" /> {beginAt} - {endAt}
  </span>
);

AppointmentDate.propTypes = {
  beginAt: string,
  endAt: string,
};

export { AppointmentDate };
