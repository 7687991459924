import React from 'react';
import { array, bool, object, func } from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { useFormatMessage } from 'react-intl-hooks';
import Separator from '@/components/Separator';
import Wrapper from '@/components/Wrapper';
import BlankState from '@/components/BlankState';
import Calendar from '../../../../../Preparation/scenes/Timeslot/containers/Calendar';

function AgendaCalendarScene({
  timeslots,
  authUser,
  event,
  getParticipant,
  events,
  isOpen,
  setCurrentOption,
}) {
  const t = useFormatMessage();
  if (isEmpty(events)) {
    return (
      <Wrapper>
        <Separator height={80} />
        <BlankState
          style={{ marginTop: '80px' }}
          largeIcon
          icon="blank-state-cactus"
        ><h1 className="mainTitle">{t({ id: 'agenda.blank' }, { name: authUser._currentOrganization.name })}</h1></BlankState>
      </Wrapper>
    );
  }

  return (
    <>
      <Separator height={25} />
      {event && <Calendar
        isOpen={isOpen}
        authUser={authUser}
        events={events}
        context="calendar"
        currentEvent={event}
        timeslots={timeslots}
        getParticipant={getParticipant}
        setCurrentOption={setCurrentOption}
      />}
    </>
  );
}

AgendaCalendarScene.propTypes = {
  timeslots: array,
  events: array,
  authUser: object,
  event: object,
  isOpen: bool,
  getParticipant: func,
  setCurrentOption: func,
};

export default AgendaCalendarScene;
