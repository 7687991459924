import React, { useEffect, useState } from 'react';
import { array, func, object, string, bool } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';
import { connect } from 'react-redux';
import { get, isEmpty, debounce } from 'lodash';
import { compose } from 'redux';
import { Modal, Input } from 'antd';
import {
  Table,
  Button,
  IconBuilding,
  IconSearch,
  Pagination,
} from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { toJS } from '@/utils';
import { ACL, ANALYTICS_RECRUITER, EVENT_FORMAT } from '@/utils/constants';
import { track } from '@/utils/analytics';
import { highlightText } from '@/utils/text';
import { objectToParams, queryStringToObject } from '@/utils/url';
import { userActions } from '@/store/user';
import { eventSelectors } from '@/store/event';
import { exponentActions, exponentSelectors } from '@/store/exponent';
import { authActions, authSelectors } from '@/store/auth';
import { organizationActions } from '@/store/organization';
import LoadingIndicator from '@/components/LoadingIndicator';
import Icon from '@/components/Icon';
import Separator from '@/components/Separator';
import Wrapper from '@/components/Wrapper';
import SubColumns
  from '@/scenes/Event/scenes/Owner/scenes/OnBoarding/containers/Dashboard/components/SubColumns';
import EditUserModal from '@/scenes/Event/scenes/Recruiter/scenes/Preparation/scenes/Team/containers/EditUserModal';
import ColumnsUi from '@/scenes/Event/scenes/Owner/scenes/OnBoarding/containers/Dashboard/components/ColumnsUi';
import {
  useGetExponentsStats
} from '@/scenes/Event/scenes/Owner/scenes/OnBoarding/containers/Dashboard/queries/useGetExponentsStats';
import CollaboratorModal from '@/scenes/Event/scenes/Owner/scenes/OnBoarding/containers/CollaboratorModal';
import { ExponentsStats } from '@/scenes/Event/scenes/Owner/scenes/OnBoarding/containers/Dashboard/components/ExponentsStats';
import {
  PlanningBtn,
  ExportBtn,
  SortFilter,
  HybridTabs,
} from '@/scenes/Event/scenes/Owner/scenes/OnBoarding/containers/Dashboard/components';
import ExponentModal from '@/scenes/Event/scenes/Owner/scenes/OnBoarding/containers/ExponentModal';
import DataSource from '@/scenes/Event/scenes/Owner/scenes/OnBoarding/containers/Dashboard/components/DataSource';
import { AddLogo } from '@/scenes/Event/scenes/Owner/scenes/OnBoarding/containers/Dashboard/components/OrganizationColumn/components';
import ParticipantModeModal from '@/scenes/Event/scenes/Owner/scenes/OnBoarding/containers/Dashboard/components/ParticipantModeModal';
import { BlanksStatePreventAddingExponent, BlanksStateNoExponent } from '@/scenes/Event/scenes/Owner/scenes/OnBoarding/containers/Dashboard/BlankStates';
import styles from '@/scenes/Event/scenes/Owner/scenes/OnBoarding/containers/Dashboard/styles.less';
import messages from '@/scenes/Event/scenes/Owner/scenes/OnBoarding/containers/Dashboard/messages';
import DefineReferentModal from '@/scenes/Event/scenes/Owner/scenes/OnBoarding/containers/Dashboard/components/DefineReferentModal';

const PAGE_SIZE = 20;
const defaultPagination = { page: 1, limit: PAGE_SIZE, offset: 0 };

const debounceSearch = debounce((fct, val) => fct(val), 300);

const VIEWS = {
  physical: EVENT_FORMAT.PHYSICAL,
  virtual: EVENT_FORMAT.VIRTUAL,
  all: 'all',
}

const Dashboard = ({
  match,
  pagination,
  history,
  exponents,
  authToken,
  getExponents,
  switchAuth,
  patchOrganization,
  deleteExponent,
  patchExponent,
  setCurrentOrganization,
  setCurrentExponent,
  isFetching,
  intl,
  authUser,
  event,
}) => {
  const paramObject = queryStringToObject(history.location.search);

  const t = useFormatMessage();
  const [modalExponentIsOpen, setModalExponentIsOpen] = useState(false);
  const [editUserModalProperties, setEditUserModalProperties] = useState({ visible: false, context: ACL.EDIT_ROLE })
  const [modalCollaboratorIsOpen, setModalCollaboratorIsOpen] = useState(false);
  const [modalParticipantRoleIsOpen, setModalParticipantRoleIsOpen] = useState(false);
  const [isAvatarModalOpen, setIsAvatarModalOpen] = useState(false);
  const [currentExponentToChangeLogo, setCurrentExponentToChangeLogo] = useState({});
  const [confirmRecruiterModalIsVisible, setConfirmRecruiterModalIsVisible] = useState(false);
  const [currentExponentState, setCurrentExponentState] = useState({});
  const [currentExponentUser, setCurrentExponentUser] = useState({});
  const [sort, setSort] = useState(null);
  const [view, setView] = useState(queryStringToObject(location.search)?.view);
  const [exponentNameForParticipationRole, setExponentNameForParticipationRole] = useState('');
  const [isInitialFetching, setIsInitialFetching] = useState(false);
  const [isBlankState, setIsBlankState] = useState(false);
  const [newReferent, setNewReferent] = useState();
  const { data: stats, ...exponentsStatsQuery } = useGetExponentsStats({ event, view: getView() })

  useEffect(() => {
    if (isFetching && pagination.total === 0 && isEmpty(sort) && isEmpty(paramObject.keywords)) {
      setIsInitialFetching(true);
    }

    setIsInitialFetching(false)
  }, [isFetching, pagination, sort, paramObject.keywords])

  useEffect(() => {
    if (!isFetching && pagination.total === 0 && isEmpty(paramObject.keywords)) {
      setIsBlankState(true);
    }

    setIsBlankState(false)
  }, [isFetching, pagination, sort, paramObject.keywords]);

  useEffect(() => {
    if (!(isEmpty(history.location.search) && event?.format === EVENT_FORMAT.HYBRID)) {
      refreshExponentsList();
    }
  }, [])

  useEffect(() => {
    if (event?.format === EVENT_FORMAT.HYBRID) {
      if (isEmpty(history.location.search)) {
        const params = objectToParams({ view: VIEWS.physical });

        history.push(`?${params}`);
      }

      else {
        const location = { ...history.location };
        setView(queryStringToObject(location.search).view);
      }
    }
  }, [history.location])

  useEffect(() => {
    if (view && event?.format === EVENT_FORMAT.HYBRID) {
      refreshExponentsList();
    }
  }, [view])

  function getView() {
    return view !== EVENT_FORMAT.HYBRID ? view : VIEWS.all.refetch();
  }

  const canExpandRow = event?.format === EVENT_FORMAT.HYBRID ? [VIEWS.virtual, VIEWS.physical].includes(view) : true;

  const getCanExponentExpand = ({ original }) => original?.tool?.users?.length > 0

  const refreshExponentsList = (params = {}) => {

    getExponents({
      eventId: match.params.eventSlug,
      ...defaultPagination,
      search: {
        keywords: paramObject.keywords
      },
      ...params,
      owner: true,
      all: true,
      eventFormat: event?.format === EVENT_FORMAT.HYBRID ? view : VIEWS.all,
    });
    setSort(params.sort);
  }

  const onToolsClick = (event) => {
    event.stopPropagation();
  };

  const preventExpand = (event) => event.stopPropagation();

  const openCollaboratorModal = (exponent) => {
    setCurrentExponentState(exponent);
    setCurrentExponent({ exponentId: exponent._id });
    setCurrentOrganization(exponent._organization._id);

    setModalCollaboratorIsOpen(true);
  };

  const closeCollaboratorModal = () => setModalCollaboratorIsOpen(false);

  const handleRemoteSearch = (val) => {
    getExponents({
      eventId: match.params.eventSlug,
      offset: 0,
      page: 1,
      search: isEmpty(val) ? {} : { keywords: val },
      limit: PAGE_SIZE,
      owner: true,
      all: true,
      eventFormat: event?.format === EVENT_FORMAT.HYBRID ? view : VIEWS.all,
    });
  }

  const handleOnChangeSearchText = (e) => {
    const paramObject = queryStringToObject(history.location.search);
    const params = objectToParams({ ...paramObject, keywords: e.target.value });

    history.push(`?${params}`);

    debounceSearch(handleRemoteSearch, e.target.value);
  }

  const toggleExponentModal = () => {

    if (!modalExponentIsOpen) {
      setCurrentOrganization(null);
    }

    setModalExponentIsOpen(!modalExponentIsOpen);
  };

  const removeExponent = (exponent) => {
    const trackData = {
      user: authUser,
      event: exponent._event,
      name: ANALYTICS_RECRUITER.CANCELED_COMPANY_PARTICIPATION,
    };

    const offset = (pagination.current * pagination.pageSize) - pagination.pageSize;

    deleteExponent({
      eventId: exponent._event._id,
      exponentId: exponent._id,
      noReload: true,
      callback: () => {
        refreshStats();
        track(trackData);
        refreshExponentsList({
          page: pagination.current,
          offset,
          search: { keywords: paramObject.keywords }
        },
        );
      },
      notificationParams: {
        success: {
          message: intl.formatMessage({ id: 'toasters.deleteExponent.success' })
        },
        error: {
          message: intl.formatMessage({ id: 'toasters.deleteExponent.error' })
        }
      }
    });
  };

  const handleToggleReady = ({ _event, _id, isReady, _keyMomentLinks }, defaultParams = null) => {
    let exponentParams = defaultParams || { isReady: !isReady }

    if (_keyMomentLinks && [VIEWS.virtual, VIEWS.physical].includes(view)) {
      const newKeyMomentLinks = _keyMomentLinks.map((keyMomentLink) => {
        if (keyMomentLink._keyMoment.format === view) {
          return {
            ...keyMomentLink,
            isReady: !keyMomentLink.isReady
          }
        }

        return keyMomentLink;
      })
      exponentParams = { _keyMomentLinks: newKeyMomentLinks }
    }

    patchExponent({
      eventId: _event._id,
      exponentId: _id,
      exponentParams,
      callback: refreshStats,
    });
  };

  const handleToggleCorner = (record, corners) => {
    patchExponent({
      eventId: record.eventId,
      exponentId: record.key,
      exponentParams: {
        corners,
      },
      notificationParams: {
        success: {
          message: `🙌 ${intl.formatMessage({ id: 'event.modules.corner.associate.success' })}`,
          kind: 'info',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        },
        error: {
          message: intl.formatMessage({ id: 'event.modules.corner.associate.error' }),
          style: {
            top: '5%',
          },
        },
      },
    });
  };

  const handleTogglePartner = (record, isPartner) => {
    patchExponent({
      eventId: record.eventId,
      exponentId: record.key,
      exponentParams: {
        isPartner,
      },
      notificationParams: {
        success: {
          message: `🙌 ${intl.formatMessage({ id: 'toaster.edit.success' })}`,
          kind: 'info',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        },
        error: {
          message: intl.formatMessage({ id: 'download.error' }),
          style: {
            top: '5%',
          },
        },
      },
    });
  };

  const refreshStats = () => {
    exponentsStatsQuery.refetch();
  };

  const removeCollaborator = () => {
    const trackData = {
      user: currentExponentUser._user,
      event: currentExponentState._event,
      name: ANALYTICS_RECRUITER.CANCELED_RECRUITER_PARTICIPATION,
    };

    patchExponent({
      eventId: currentExponentState._event._id,
      exponentId: currentExponentState._id,
      exponentParams: {
        deleteUserId: currentExponentUser._user._id,
      },
      notificationParams: {
        success: {
          message: intl.formatMessage({ id: 'toasters.coworkerRemovedFromEvent.success' }),
          kind: 'info',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        },
        error: {
          style: {
            top: '5%',
          },
        },
      },
      callback: () => {
        exponentsStatsQuery.refetch();
        track(trackData);
      },
    });

    setConfirmRecruiterModalIsVisible(false);
  };


  const inviteCollaborator = (collaboratorId, exponent) => {
    patchExponent({
      eventId: exponent._event._id,
      exponentId: exponent._id,
      exponentParams: {
        invitUser: collaboratorId,
      },
      notificationParams: {
        success: {
          message: intl.formatMessage({ id: 'toasters.inviteSent.success' }),
          kind: 'info',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        },
        error: {
          style: {
            top: '5%',
          },
        },
      },
    });
  };

  const switchRole = (exponent, exponentUser, role) => {
    const newExponentUser = {
      ...exponentUser,
      role,
    };

    patchExponent({
      exponentId: exponent._id,
      eventId: exponent._event._id,
      exponentParams: {
        updatedUser: newExponentUser,
        updatedUserKey: 'role',
      },
      notificationParams: {
        success: {
          message: intl.formatMessage({ id: 'toasters.roleUpdated.success' }),
          kind: 'info',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        },
        error: true,
      },
    });
  };

  const handleOnSwitchUser = (user) => {
    if (authUser.isAdmin) {
      switchAuth({
        email: user.username,
        notificationParams: {
          success: {
            message: intl.formatMessage({ id: 'toasters.switch.success' }),
            kind: 'success',
            style: {
              top: '7%',
            },
          },
          error: {
            message: intl.formatMessage({ id: 'toasters.switch.error' })
          },
        },
      });
    }
  };

  const handleOnOpenAvatarModal = (exponent) => {
    setIsAvatarModalOpen(true);
    setCurrentExponentToChangeLogo(exponent)
  }

  const handleOnCloseAvatarModal = () => setIsAvatarModalOpen(false);

  const handleRecruiterAction = (type, exponentUser, exponent) => {
    switch (type) {
      case ACL.REMOVE_USER_FROM_EXPONENT:
        setConfirmRecruiterModalIsVisible(true);
        setCurrentExponentState(exponent);
        setCurrentExponentUser(exponentUser);
        break;
      case ACL.EDIT_USER:
        setEditUserModalProperties({ visible: true, context: ACL.EDIT_USER });
        setCurrentExponentState(exponent);
        setCurrentExponentUser(exponentUser);
        break;
      case ACL.EDIT_ROLE:
        setEditUserModalProperties({ visible: true, context: ACL.EDIT_ROLE });
        setCurrentExponentState(exponent);
        setCurrentExponentUser(exponentUser);
        break;
      case 'switchRole':
        switchRole(exponent, exponentUser, exponentUser.role === 'manager' ? 'recruiter' : 'manager'); break;
      case 'defineReferent':
        setNewReferent({ referent: exponentUser, exponent }); break;
      case 'switchUser':
        handleOnSwitchUser(exponentUser._user); break;
      case 'inviteCollaborator':
        inviteCollaborator(get(exponentUser, '_user._id'), exponent); break;
      case 'recruiterClick':
        localStorage.setItem('switch_quit_url', `/${exponent._event.slug}/owner/exponents`);
        handleOnSwitchUser(exponentUser._user); break;
      default: break;
    }
  };

  const handleExponentActions = (type, exponent) => {
    switch (type) {
      case 'onCreateUser': openCollaboratorModal(exponent); break;
      case 'openAvatar': handleOnOpenAvatarModal(exponent); break;
      default: break;
    }
  };

  const renderModalCollaborator = () => {
    if (!modalCollaboratorIsOpen) return null;

    return (
      <CollaboratorModal
        context="admin"
        isOpen={modalCollaboratorIsOpen}
        onCancel={closeCollaboratorModal}
        onOk={() => {
          closeCollaboratorModal();
          exponentsStatsQuery.refetch();
        }}
        organization={currentExponentState._organization}
      />);
  };

  const handleOnChangePagination = (currentPage) => {
    const offset = (currentPage * pagination.pageSize) - pagination.pageSize;

    getExponents({
      eventId: match.params.eventSlug,
      offset,
      page: currentPage,
      search: isEmpty(paramObject.keywords) ? {} : { keywords: paramObject.keywords },
      limit: PAGE_SIZE,
      sort,
      owner: true,
      all: true,
      eventFormat: event?.format === EVENT_FORMAT.HYBRID ? view : VIEWS.all,
    });
  };

  const handleOnSort = (sortBy) => {
    const localSort = sortBy.map(sortByItem => {
      switch (sortByItem.id) {
        case "exponent":
          return `${sortByItem.desc ? '' : '-'}isReady`;
        default:
          return ''
      }
    }).join(';')

    if (!isEmpty(localSort) && localSort !== sort) {
      setSort(localSort);

      getExponents({
        eventId: match.params.eventSlug,
        offset: 0,
        page: 1,
        search: isEmpty(paramObject.keywords) ? {} : { keywords: paramObject.keywords },
        limit: PAGE_SIZE,
        sort: localSort,
        owner: true,
        all: true,
        eventFormat: event?.format === EVENT_FORMAT.HYBRID ? view : VIEWS.all,
      });
    }
  }

  const handleTagReadyClick = (format, organizationName) => {
    if (view === VIEWS.all) {
      const target = { value: organizationName };

      setView(format);
      handleOnChangeSearchText({ target })
    }
  }

  if (isInitialFetching) {
    return <LoadingIndicator />
  }

  if (!event.enableAddingExponent) {
    return (
      <div className={styles.dashboard}>
        <div className={styles.header}>
          <h1>{intl.formatMessage(messages.exponentTitle)}</h1>
        </div>
        <BlanksStatePreventAddingExponent action={() => history.push(`/${event.slug}/owner/settings/diffusion`)} />
      </div>);
  }
  const isLiveEnable = get(event, 'modules.live.recruiterEnable');

  const isInformalEnable = get(event, 'modules.informal1to1.enable');

  const openExponentModal = (exponentId) => {
    setExponentNameForParticipationRole(exponentId);
    setModalParticipantRoleIsOpen(true);
  }

  const onSubmitModifyProfile = () => {
    const offset = (pagination.current * pagination.pageSize) - pagination.pageSize;

    refreshExponentsList({
      page: pagination.current,
      offset,
      search: { keywords: paramObject.keywords }
    },
    );
  }

  const toggleParticipationRoleModal = () => {
    setModalParticipantRoleIsOpen(false)
  }

  const getColumns = () => {
    let columnsToDisplay = '';
    switch (view) {
      case VIEWS.all:
        columnsToDisplay = ['exponent', 'format', 'isReady'];
        break;
      case VIEWS.physical:
      case VIEWS.virtual:
        columnsToDisplay = ['exponent', 'format', 'stand', 'offers', 'countRecruiter', 'countConferences', 'createdAt', 'actions']; break;

      default:
        columnsToDisplay = ['exponent', 'stand', 'offers', 'countRecruiter', 'countConferences', 'createdAt', 'actions']
        break;
    }
    if (!isLiveEnable) {
      columnsToDisplay = columnsToDisplay.filter((columnName) => (columnName !== 'countConferences'));
    }

    return (
      ColumnsUi(
        false,
        event.slug,
        (event?.modules?.corner?.items || []),
        authUser.locale,
        event?.modules?.partner,
        event?.format === EVENT_FORMAT.HYBRID,
        view,
        authToken,
      ).filter(column => columnsToDisplay.includes(column.id))
    )
  }

  return (
    <Wrapper type="scene" context="owner">
      <div className={styles.dashboard}>
        {/* Modal for manage exponent */}
        {/* I display this modal this way so we can refresh all the stat when we close it. */}
        {modalExponentIsOpen && <ExponentModal
          isOpen={modalExponentIsOpen}
          onCancel={toggleExponentModal}
          onOk={() => {
            refreshExponentsList();
            toggleExponentModal();
            exponentsStatsQuery.refetch();
          }}
        />}
        {newReferent &&
          <DefineReferentModal onCancel={() => setNewReferent()} referent={newReferent} event={event} />
        }
        <ParticipantModeModal
          isOpen={modalParticipantRoleIsOpen}
          onCancel={toggleParticipationRoleModal}
          exponentId={exponentNameForParticipationRole}
          onOk={() => {
            refreshExponentsList();
            toggleParticipationRoleModal();
            exponentsStatsQuery.refetch();
            setExponentNameForParticipationRole('');
          }}
        />

        {isBlankState ? (
          <div>
            <div className={styles.header}>
              <h1>{t({ id: 'event.owner.companies.title' })} </h1>
            </div>
            {event?.format === EVENT_FORMAT.HYBRID &&
              <HybridTabs event={event} view={view} />
            }
            <BlanksStateNoExponent action={toggleExponentModal} />
          </div>
        ) : (
          <div>
            <div className={styles.header}>
              <h1>{t({ id: 'event.owner.companies.title' })} ({pagination.total})</h1>
              <div className={styles.actions}>
                <ExportBtn {...event} />
                <PlanningBtn {...event} />
                <Button
                  id="btnAddExponent"
                  variant="fill"
                  color="primary"
                  imageComponentLeft={<IconBuilding size={16} />}
                  onClick={toggleExponentModal}>
                  {t({ id: 'event.owner.company.add' })}
                </Button>
              </div>

              <AddLogo
                isOpen={isAvatarModalOpen}
                record={currentExponentToChangeLogo}
                authToken={authToken}
                patchOrganization={patchOrganization}
                onClose={handleOnCloseAvatarModal}
                getExponents={getExponents}
              />

            </div>
            <HybridTabs event={event} view={view} />
            <ExponentsStats stats={stats} omitStats={isLiveEnable ? [] : ['countConferences']} eventId={event._id} format={view} />
            <div className={styles.searchableWrapper}>
              <Input
                type="text"
                value={paramObject.keywords}
                onChange={handleOnChangeSearchText}
                placeholder={t({ id: 'event.owner.exponents.search' })}
                prefix={<IconSearch size={14} />}
              />
              <SortFilter hideReadyFilter={view !== 'all' && event?.format === EVENT_FORMAT.HYBRID} onSort={refreshExponentsList} searchText={paramObject.keywords} />
            </div>
            <Table
              getCustomTableProps={() => ({ style: { width: '100%' } })}
              columns={getColumns()}
              data={
                DataSource(
                  exponents,
                  '',
                  highlightText,
                  {
                    context: 'owner_exponent',
                    actions: {
                      openCollaboratorModal,
                      removeExponent,
                      onToolsClick,
                      preventExpand,
                      patchOrganization,
                      handleOnOpenAvatarModal,
                      handleExponentActions,
                      onToggle: handleToggleReady,
                      onToggleCorner: handleToggleCorner,
                      onTogglePartner: handleTogglePartner,
                      onTagReadyClick: handleTagReadyClick,
                      openExponentModal,
                    },
                  })}
              enableExpansion={canExpandRow}
              getCanRowExpand={getCanExponentExpand}
              renderRowSubComponent={
                ({ row: { original } }) => (
                  canExpandRow && original.tool.users.length ?
                    <Table
                      getCustomTableProps={() => ({ style: { border: 'none', width: '100%' } })}
                      columns={
                        SubColumns(original.tool, handleRecruiterAction, view, authUser, event).filter(
                          (columnName) => !(columnName.id === 'countInformalSlots' && !isInformalEnable),
                        )}
                      data={original.tool.users}
                    /> : null)
              }
              enableSort
              isSortByManual
              manuallySortBy={(sortBy) => handleOnSort(sortBy)}
              isLoadingData={isFetching}
            />
            <Pagination
              pageSize={PAGE_SIZE}
              total={pagination.total}
              onChange={handleOnChangePagination}
              className="flex justify-center mt-20"
              current={pagination.current}
            />

            {renderModalCollaborator()}

            <AddLogo
              isOpen={isAvatarModalOpen}
              record={currentExponentToChangeLogo}
              authToken={authToken}
              patchOrganization={patchOrganization}
              onClose={handleOnCloseAvatarModal}
              getExponents={getExponents}
            />
          </div>
        )}
        <EditUserModal
          isOpen={editUserModalProperties.visible}
          onSubmit={onSubmitModifyProfile}
          onClose={() => { setEditUserModalProperties({ ...editUserModalProperties, visible: false }); setCurrentExponentUser(null); }}
          user={get(currentExponentUser, '_user')}
          organization={currentExponentState._organization}
          context={editUserModalProperties.context}
        />
        <Modal
          visible={confirmRecruiterModalIsVisible}
          footer={false}
          maskClosable
          width={470}
          className="customConfirm"
          onCancel={() => setConfirmRecruiterModalIsVisible(false)}
        >
          <a role="button" tabIndex={0} className="modal-close" onClick={() => setConfirmRecruiterModalIsVisible(false)}>
            <Icon name="close" className="modal-close-icon" />
          </a>

          <h4 className="ant-confirm-title">
            <span role="img" aria-label="warning">⚠️ </span><FormattedHTMLMessage id="event.recruiter.preparation.team.delete.recruiter.modal.confirm" />
          </h4>

          <Separator height={15} />

          <div className="confirm-actions">
            <Button className="mr-6" variant="tonal" onClick={() => setConfirmRecruiterModalIsVisible(false)}>{intl.formatMessage(messages.cancel)}</Button>
            <Button type="primary" onClick={() => removeCollaborator()}>{intl.formatMessage(messages.btnConfirm)}</Button>
          </div>
        </Modal>
      </div>
    </Wrapper>
  );
}


PlanningBtn.propTypes = {
  handleDownload: func,
};

Dashboard.propTypes = {
  match: object,
  pagination: object,
  history: object,
  exponents: array,
  authToken: string,
  getExponents: func,
  switchAuth: func,
  patchOrganization: func,
  deleteExponent: func,
  patchExponent: func,
  setCurrentOrganization: func,
  setCurrentExponent: func,
  getUsersOrganization: func,
  isFetching: bool,
  intl: object,
  authUser: object,
  event: object,
};

const mapStateToProps = createStructuredSelector({
  pagination: exponentSelectors.getExponentPagination,
  exponents: exponentSelectors.getExponents,
  isFetching: exponentSelectors.getExponentsFetching,
  authToken: authSelectors.getAuthToken,
  authUser: authSelectors.getAuthUser,
  event: eventSelectors.getCurrentEvent,
});

const mapDispatchToProps = {
  setCurrentOrganization: organizationActions.setCurrentOrganization,
  setCurrentExponent: exponentActions.setCurrentExponent,
  getUsersOrganization: userActions.getUsersOrganization,
  getExponents: exponentActions.getExponents,
  deleteExponent: exponentActions.deleteExponent,
  patchExponent: exponentActions.patchExponent,
  patchOrganization: organizationActions.patchOrganization,
  switchAuth: authActions.switchAuth,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withRouter,
  withConnect,
  injectIntl,
)(toJS(Dashboard));
