import React, { useRef, useState } from 'react';
import { func, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useFieldHelperText } from '@seekube-tech/ui';
import { isEmpty } from 'lodash';
import { useFormatMessage } from 'react-intl-hooks';
import { Button as ButtonUiKit, IconUpload } from '@seekube-tech/ui-kit';
import { Row, Col } from 'antd';
import Dropzone from 'react-dropzone';

import { authSelectors } from '@/store/auth';
import { notificationActions } from '@/store/notification';

import classnames from 'classnames';
import styles from './styles.less';
import b64toBlob from 'b64-to-blob';
import { InputFile } from '@/components/Input';
import CropModal from './components/CropModal';
import useUpload from '@/forms/Stand/components/CoverEditor/utils/useUpload';

const AUTORIZED_TYPES = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/gif',
];

function CoverEditor({ input, meta, organizationId, authToken, sendNotification, id, disabled }) {
  /* Vars */

  // TODO : Move organizationId, authToken, sendNotification in useUpload hook when redux hook is available
  const [onDrop, isLoading] = useUpload({
    onSuccess: input.onChange,
    organizationId,
    authToken,
    sendNotification,
  });
  const { hasError } = useFieldHelperText(meta);
  const [imageUrl, setImageUrl] = useState(input.value);
  const [isDisplayCropper, setIsDisplayCropper] = useState(false);
  const [error, setError] = useState('');
  const editor = useRef(null);
  const t = useFormatMessage();
  let inputFileReference = null;

  /* Functions */

  /**
   * Handle Change when user choose new picture
   *
   * @param {Array} files
   */
  const handleOnChangeFile = (files) => {
    if (files?.[0]) {
      const file = files[0];

      if (AUTORIZED_TYPES.includes(file.type)) {
        setError('');
        setIsDisplayCropper(true);
        setImageUrl(file.preview);
      } else {
        setError(t({ id: 'error.file.format' }));
        setIsDisplayCropper(false);
      }

      return file;
    }

    return false;
  };

  /**
   * When user click to save button
   */
  const handleSave = () => {
    if (editor.current?.getImage()) {
      const dataUrl = editor.current.getImageScaledToCanvas().toDataURL();
      const dataUrlSplited = dataUrl.split(',');
      const file = b64toBlob(dataUrlSplited[1], dataUrlSplited[0].replace('data:', '').replace(';base64', ''));

      setError('');
      setImageUrl(dataUrl);
      onDrop([file]);
      setIsDisplayCropper(false);
    }
  };

  const handleOnChangeInputFile = (file) => {
    const reader = new FileReader();

    const authorizedTypes = [
      'image/jpeg',
      'image/png',
      'image/gif',
    ];

    if (!authorizedTypes.includes(file.type)) {
      setError(t({ id: 'error.file.format' }));
    } else {
      reader.onload = () => {
        setError('');
        setIsDisplayCropper(true);
        setImageUrl(reader.result);
        inputFileReference.value = null;
      };

      reader.readAsDataURL(file);
    }
  };

  const handleCloseCropper = () => {
    setIsDisplayCropper(false);
    setError('');
    setImageUrl(input.value);
    inputFileReference.value = null;
  }

  const getInputFileReference = (input) => {
    inputFileReference = input;
  }

  /* Render */

  return (
    <div className={styles.coverV2Container}>
      {imageUrl !== '' &&
        <div className={styles.inputCoverContainer}>
          <InputFile
            onChange={handleOnChangeInputFile}
            getInputFileReference={getInputFileReference}
            accept=".png,.jpeg,.gif,.jpg"
          >
            <ButtonUiKit disabled={disabled} size='small' variant='outline'>{t({id: 'stand.modify.cover'})}</ButtonUiKit>
          </InputFile>
        </div>
      }
      <div id={id} className={classnames(styles.coverWrapper, { [styles.withImage]: isEmpty(imageUrl) === false }, {[styles.error]: hasError})}>
        <Dropzone
          accept={AUTORIZED_TYPES.join(', ')}
          onDrop={handleOnChangeFile}
          className={styles.drag}
          activeClassName={styles.activeDropzone}
          multiple={false}
          disabled={disabled}
          style={{ display: isDisplayCropper ? 'none' : 'flex' }}
        >
          <img src={imageUrl} width={1312} height={280} />
          <div className={styles.activeDropzoneBorder} />

          <div className={styles.help}>
            <div>
              <IconUpload />
            </div>

              {t({ id: 'coverEditor.help' })}
          </div>
        </Dropzone>

        <CropModal
          isVisible={isDisplayCropper}
          isLoading={isLoading}
          imageUrl={imageUrl}
          handleSave={handleSave}
          editor={editor}
          onClose={handleCloseCropper}
        />
      </div>

      {isDisplayCropper &&
        <Row type="flex" gutter={24} style={{ marginTop: '60px' }}>
          <Col>
            <ButtonUiKit
              disabled={isDisplayCropper === false}
              loading={isLoading}
              onClick={handleSave}
              color="primary"
              variant="fill"
            >
              {t({ id: 'save' })}
            </ButtonUiKit>
          </Col>

          <Col>
            <ButtonUiKit
              color="neutral"
              variant="outline"
              onClick={() => {setIsDisplayCropper(false); setImageUrl(input.value)}}
            >
              {t({ id: 'cancel' })}
            </ButtonUiKit>
          </Col>
        </Row>
      }
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  authToken: authSelectors.getAuthToken,
});

const mapDispatchToProps = {
  sendNotification: notificationActions.sendNotification,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

CoverEditor.propTypes = {
  meta: object.isRequired,
  input: shape({
    value: string,
    onChange: func,
  }).isRequired,
  organizationId: string,
  authToken: string,
  sendNotification: func.isRequired,
  id: string.isRequired,
};

CoverEditor.defaultProps = {
  organizationId: null,
  authToken: '',
};

export default compose(withConnect)(CoverEditor);
