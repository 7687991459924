import React from 'react';
import { object } from 'prop-types';
import styles from './styles.less'
import { H4 } from '@seekube-tech/ui-kit';
import Html from '@/components/Html';
import Videos from './Medias/Videos';

const StandPresentation = ({
  profile,
}) => {

  if (!profile?.presentation) {
    return null;
  }

  const getVideos = () => {
    const videos = profile.videos?.filter(({ url }) => url);

    if (videos.length > 2) {
      return videos.slice(0, 2)
    }
    return videos;
  }

  const videos = getVideos();

  return (
    <div className={styles.standPresentationContainer}>
      <H4 className='mb-24'>{profile.presentation.title}</H4>
      <Html value={profile.presentation.content} />
      {videos?.length > 0 &&
        <Videos profile={profile} videos={videos} />
      }
    </div>
  )
}

StandPresentation.propTypes = {
  match: object
}

export default StandPresentation;
