import React, { useEffect } from 'react';
import { object, func, bool } from 'prop-types';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { replace } from 'connected-react-router';
import { createStructuredSelector } from 'reselect';
import { useHistory } from 'react-router';
import { toJS } from '@/utils';
import ModalBig from '@/components/ModalBig';
import { exponentSelectors } from '@/store/exponent';
import { getExponentByOrganization } from '@/store/exponent/actions';
import { authSelectors } from '@/store/auth';
import { eventSelectors } from '@/store/event';
import NavPreparation from './components/NavOnBoarding';
import RecruiterPreparationTeamPage from './scenes/Team';
import RecruiterPreparationOffersPage from './scenes/Offer';
import RecruiterPreparationTimeslotsPage from './scenes/Timeslot';
import RecruiterPreparationStandPage from './scenes/Stand';
import RecruiterJobdatingAgendaPage from '../Jobdating/scenes/Agenda';
import { appActions, appSelectors } from '@/store/app';


function RecruiterPreparationScene({ match,
  authUser,
  event,
  exponent,
  replace,
  closeCalendar,
  calendarIsOpen
}) {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!exponent) {
      dispatch(getExponentByOrganization({
        lean: true,
        eventId: event._id,
        organizationId: authUser._currentOrganization?._id,
      }));
    }
  }, [])

  useEffect(() => {
    if (match.isExact && exponent) {
      replace(`${match.url}/team`);
    }
  }, [exponent])

  const hasClickedOnTimeslot = () => {
    if (history.location.pathname.includes('/timeslots')) {
      history.goBack();
    }
  };

  return (
    <div>
      <NavPreparation match={match} exponent={exponent} user={authUser} calendarIsOpen={calendarIsOpen} event={event} />

      <ModalBig
        isOpen={calendarIsOpen}
        hideSideContent
        full
        onClose={() => { closeCalendar(); hasClickedOnTimeslot(); }}
      >
        <RecruiterJobdatingAgendaPage match={match} />
      </ModalBig>
      <Switch>
        <Route path={`${match.path}/team`} component={RecruiterPreparationTeamPage} />
        <Route
          path={`${match.path}/offers`}
          component={RecruiterPreparationOffersPage}
        />
        <Route path={`${match.path}/timeslots`} component={RecruiterPreparationTimeslotsPage} />
        <Route path={`${match.path}/stand`} component={RecruiterPreparationStandPage} />
      </Switch>
    </div>
  );
}

RecruiterPreparationScene.propTypes = {
  match: object,
  authUser: object,
  event: object,
  exponent: object,
  replace: func,
  closeCalendar: func,
  calendarIsOpen: bool,
};

const mapStateToProps = createStructuredSelector({
  exponent: exponentSelectors.getCurrentExponent,
  authUser: authSelectors.getAuthUser,
  calendarIsOpen: appSelectors.getCalendarIsOpen,
  event: eventSelectors.getCurrentEvent,
});

const mapDispatchToProps = {
  replace,
  closeCalendar: appActions.closeCalendar,
  toggleCalendar: appActions.toggleCalendar,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(toJS(RecruiterPreparationScene));
