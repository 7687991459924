import { useMutation } from 'react-query';
import request from '@/utils/request';

export function useCreateDeal(
  {
    authToken,
    organizationId,
    onSuccess = () => {},
  }) {
  const mutationFn = (body) => {
    const requestUrl = `${process.env.FRONT_API_URL}/deals`;

    return (
      request(requestUrl, {
        method: 'POST',
        body: JSON.stringify({ ...body, _organization: organizationId }),
      })
    )
  }

  return useMutation({
    mutationFn,
    onSuccess
  });
}
