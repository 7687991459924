import * as React from 'react';
import { connect } from 'react-redux';
import { func, object } from 'prop-types';

// Components
import { FormattedMessage } from 'react-intl';
import { Form, Input, Radio } from 'antd';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { useFormatMessage } from 'react-intl-hooks';
import { Button } from '@seekube-tech/ui-kit';
import { Tooltip } from '@/components/Tooltip';
import { Bloc, BlocHeader } from '@/components/Bloc';

// Services
import ApiToForm from '../../../../services/formMapping/ApiToForm';

// Store
import { getAvailableActions } from '@/store/availableActions/selectors';
import withErrorBoundary from '@/services/ErrorBoundary';

// Antd
const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;

const OfferForm = ({
  form: { validateFields, getFieldDecorator },
  initialValues: {
    slug,
    modules,
  },
  onSubmit,
  availableActions
}) => {
  const t = useFormatMessage();

  const handleSubmit = (e) => {
    e.preventDefault();

    return validateFields(onSubmit);
  };

  return (
    <Bloc>
      <Form onSubmit={handleSubmit} style={{ maxWidth: '500px', textAlign: 'left' }} layout="vertical">
        <BlocHeader>
          <FormattedMessage id="offers.plural" values={{ count: 2 }} tagName="h2" />
        </BlocHeader>

        <FormItem label={<FormattedMessage id="event.modules.activation.and.tabs.offers.activate" />}>
          {getFieldDecorator('enable', {
            initialValue: ApiToForm.module(modules, 'offer', 'enable'),
            rules: [{ required: true, message: 'Merci de renseigner le label' }],
          })(
            <RadioGroup>
              <RadioButton value ><FormattedMessage id="yes" /></RadioButton>
              <Tooltip
                title={availableActions.deactivateEventTab?.getTooltipMessage('offer')}
                color="dark"
                placement="bottom"
              >
                <RadioButton
                  disabled={availableActions.deactivateEventTab?.isEnabled('offer')}
                  value={false}
                >
                  <FormattedMessage id="no" />
                </RadioButton>
              </Tooltip>
            </RadioGroup>
          )}
        </FormItem>

        <FormItem label={<FormattedMessage id="event.modules.activation.and.tabs.label.fr" />}>
          {getFieldDecorator('label_fr', {
            initialValue: ApiToForm.module(modules, 'offer', 'label_fr'),
            rules: [{ required: true, message: 'Merci de renseigner le label' }],
          })(
            <Input />
          )}
        </FormItem>

        <FormItem label={<FormattedMessage id="event.modules.activation.and.tabs.label.fr.pl" />} >
          {getFieldDecorator('labelMultiple_fr', {
            initialValue: ApiToForm.module(modules, 'offer', 'labelMultiple_fr'),
            rules: [{ required: true, message: 'Merci de renseigner le label' }],
          })(
            <Input />
          )}
        </FormItem>

        {/* labelMultiple */}
        <FormItem label={<FormattedMessage id="event.modules.activation.and.tabs.label.en" />}>
          {getFieldDecorator('label_en', {
            initialValue: ApiToForm.module(modules, 'offer', 'label_en'),
            rules: [{ required: true, message: 'Merci de renseigner le label' }],
          })(
            <Input />
          )}
        </FormItem>

        <FormItem label={<FormattedMessage id="event.modules.activation.and.tabs.label.en.pl" />}>
          {getFieldDecorator('labelMultiple_en', {
            initialValue: ApiToForm.module(modules, 'offer', 'labelMultiple_en'),
            rules: [{ required: true, message: 'Merci de renseigner le label' }],
          })(
            <Input />
          )}
        </FormItem>

        {slug === 'les-rencontres-elle-active' ? (<>
          <FormItem label={<FormattedMessage id="event.modules.activation.and.tabs.offers.skipApplicationModal" />}>
            {getFieldDecorator('skipApplicationModal', {
              initialValue: ApiToForm.module(modules, 'offer', 'skipApplicationModal') === true,
              rules: [{ required: true, message: '' }],
            })(
              <RadioGroup>
                <RadioButton value ><FormattedMessage id="yes" /></RadioButton>
                <RadioButton
                  value={false}
                >
                  <FormattedMessage id="no" />
                </RadioButton>
              </RadioGroup>
            )}
          </FormItem>

          <FormItem label={<FormattedMessage id="event.modules.activation.and.tabs.label.fr.apply" />}>
            {getFieldDecorator('applyLabel_fr', {
              initialValue: ApiToForm.module(modules, 'offer', 'applyLabel_fr') || null,
            })(
              <Input placeholder="Postuler" />
            )}
          </FormItem>

          <FormItem label={<FormattedMessage id="event.modules.activation.and.tabs.label.en.apply" />}>
            {getFieldDecorator('applyLabel_en', {
              initialValue: ApiToForm.module(modules, 'offer', 'applyLabel_en') || null,
            })(
              <Input placeholder="Apply" />
            )}
          </FormItem>
        </>) : null}

        {/* Submit */}
        <div>
          <Button type="submit">
            {t({ id: 'save' })}
          </Button>
        </div>
      </Form>
    </Bloc>
  );
};

OfferForm.propTypes = {
  onSubmit: func.isRequired,
  form: object,
  initialValues: object,
  availableActions: object
};

const mapStateToProps = createStructuredSelector({
  availableActions: getAvailableActions
})

const withConnect = connect(mapStateToProps, {})
const withForm = Form.create()

export default compose(
  withConnect,
  withForm,
  withErrorBoundary
)(OfferForm);
