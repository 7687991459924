import React from 'react';
import { object, func } from 'prop-types';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Form, Input, Radio } from 'antd';
import { useFormatMessage } from 'react-intl-hooks';

// Components
import { Button, H4 } from '@seekube-tech/ui-kit';
import { Bloc, BlocHeader } from '@/components/Bloc';

// Styles & Translations
import styles from '../../../../styles.less';
import messages from '../../messages';
import isAlphanumeric from '@/utils/isAlphanumeric';
import { hasErrors } from '@/helpers/form/formHelper';

// Antd
const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;

const DiffusionForm = ({
  form: { getFieldValue, getFieldDecorator, validateFields, getFieldsError },
  initialValues,
  onSubmit,
  sendNotification,
  formatMessage,
}) => {
  const t = useFormatMessage();

  const getPasswordQuery = () => {
    const password = getFieldValue('visibility') === 'private' ? getFieldValue('participants.filters.password') : '';

    return password ? `?pwd=${password}` : '';
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    return validateFields(onSubmit);
  };

  const isEventPrivate = () => getFieldValue('visibility') === 'private';

  const copy = (sendNotification) => {
    function fallbackCopyTextToClipboard(text) {
      const textArea = document.createElement('textarea');
      textArea.value = text + document.getElementById('slug').value + getPasswordQuery();
      document.body.appendChild(textArea);
      textArea.select();

      document.execCommand('copy');

      document.body.removeChild(textArea);

      sendNotification({
        message: formatMessage(messages.copySuccess),
        kind: 'success',
      });
    }
    fallbackCopyTextToClipboard(`${location.origin}/`);
  };

  return (
    <Bloc>
      <Form onSubmit={handleSubmit} style={{ width: '100%', textAlign: 'left' }} layout="vertical">
        <BlocHeader>
          <H4 className="mb-20">{t({ id: 'event.diffusion.title' })}</H4>
        </BlocHeader>
        {/* visibility */}
        <FormItem label={t({ id: 'event.diffusion.visibility.label'})}>
          {getFieldDecorator('visibility', {
            initialValue: initialValues ? initialValues.visibility : 'private',
          })(
            <RadioGroup>
              <RadioButton value="public" ><FormattedMessage {...messages.public} /></RadioButton>
              <RadioButton value="private"><FormattedMessage {...messages.private} /></RadioButton>
            </RadioGroup>
          )}
        </FormItem>
        {isEventPrivate() ?
          <FormItem label={t({ id: 'event.participants.filters.password'})}>
            {getFieldDecorator('participants.filters.password', {
              initialValue: initialValues ? get(initialValues, 'participants.filters.password') : null,
              rules: [
                { validator: (_, value) => isAlphanumeric(value), message: t({ id: 'form.error.settings.password.alphanumeric' }) },
              ],
            })(
              <Input style={{ width: '250px' }} />
            )}
          </FormItem> : null
        }
        {/* Choisir un mot de passe */}
        <div style={{ display: 'flex', marginBottom: '16px' }}>
          <FormItem style={{ width: '450px', marginBottom: 0, paddingBottom: 0 }}>
            {getFieldDecorator('slug', {
              initialValue: initialValues ? initialValues.slug : '',
            })(
              <Input id="slug" addonBefore={location.origin} />

            )}
          </FormItem>
          {
            isEventPrivate() ? (
              <div>
                <Input value={getPasswordQuery()} className={styles.privatePassword} />
              </div>
            ) : null
          }

          <Button variant="tonal" size="small" className="ml-3" onClick={() => copy(sendNotification)}>
            {t({ id: 'copy' })}
          </Button>
        </div>

        {/* Autoriser l'ajout des exposants */}
        <FormItem label={<FormattedMessage {...messages.allowAddingExponent} />}>
          {getFieldDecorator('enableAddingExponent', {
            initialValue: initialValues ? initialValues.enableAddingExponent : false,
          })(
            <RadioGroup>
              <RadioButton value={false}><FormattedMessage {...messages.notYet} /></RadioButton>
              <RadioButton value ><FormattedMessage {...messages.yesAllValidated} /></RadioButton>
            </RadioGroup>
          )}
        </FormItem>

        {/* Status */}
        <FormItem label={<FormattedMessage {...messages.openAccessToCandidate} />}>
          {getFieldDecorator('status', {
            initialValue: initialValues ? initialValues.status : 'status',
          })(
            <RadioGroup>
              <RadioButton value="draft"><FormattedMessage {...messages.notYet} /></RadioButton>
              <RadioButton value="published" ><FormattedMessage {...messages.yesGo} /></RadioButton>
            </RadioGroup>
          )}
        </FormItem>

        <FormItem label={<FormattedMessage {...messages.eventOpenB2b} />}>
          {getFieldDecorator('openB2b', {
            initialValue: initialValues ? initialValues.openB2b : 'openB2b',
          })(
            <RadioGroup>
              <RadioButton value ><FormattedMessage {...messages.yes} /></RadioButton>
              <RadioButton value={false} ><FormattedMessage {...messages.no} /></RadioButton>
            </RadioGroup>
          )}
        </FormItem>
        {/* Submit */}
        <div>
          <Button type="submit" id="btnSubmitDiffusion" disabled={hasErrors(getFieldsError())}>
            {t({ id: 'save' })}
          </Button>
        </div>
      </Form>
    </Bloc>
  );
};

DiffusionForm.propTypes = {
  form: object,
  initialValues: object,
  onSubmit: func.isRequired,
  sendNotification: func,
  formatMessage: func,
};

export default Form.create()(DiffusionForm);
