import React, { useEffect } from 'react';
import { object, func } from 'prop-types';
import { push } from 'connected-react-router';
import { keys } from 'lodash';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';
import { Switch, Route, withRouter } from 'react-router-dom';
import { toJS } from '@/utils/index';

import Login from '@/scenes/Auth/scenes/Login/Login';
import { SwitchPage } from '@/scenes/Auth/scenes/Switch';
import LogoutPage from '@/scenes/Auth/scenes/Logout';
import InvitPage from '@/scenes/Auth/scenes/Invit';
import SmartApply from '@/scenes/Auth/scenes/SmartApply';
import { USERS_TYPE } from '@/utils/constants';
import { useHistory } from 'react-router';
import CandidateScenes from '@/scenes/Auth/scenes/CandidateScenes';
import { getAuthUser } from '@/store/auth/selectors';


const AuthScene = ({ match, push }) => {
  const history = useHistory();
  const authUser = useSelector(getAuthUser)?.toJS();

  useEffect(() => {
    if (match.isExact) {
      push('/auth/login/candidate');
    }
  }, [])

  return (
    <Switch>
      <Route exact path={`${match.path}/login`} render={() => history.replace('/auth/login/candidate')} />
      <Route
        path={`${match.path}/login/:role`}
        strict={false}
        exact={false}
        render={(routeProps) => {
          if (!authUser?.isActive && keys(USERS_TYPE).includes(routeProps.match.params.role)) {
            return <Login {...routeProps} />
          }

          if (!authUser?.isActive) {
            return history.replace('candidate');
          } else {
            return history.replace('/events')
          }
        }}
      />
      <Route exact path={`${match.path}/signup`} render={() => {
        // If candidate connected but not active, make him finish sign up
        if (authUser && !authUser.isActive && !authUser._currentOrganization) {
          return history.replace('/auth/signup/candidate/profile');
        }
        return history.replace('/auth/signup/candidate');
      }} />
      <Route
        path={`${match.path}/signup/candidate`}
        render={(routeProps) => {
          if (!authUser?.isActive) { return <CandidateScenes {...routeProps} /> }

          return history.replace('/events')
        }}
      />
      <Route path={`${match.path}/logout`} component={LogoutPage} />
      <Route path={`${match.path}/switch`} component={SwitchPage} />
      <Route path={`${match.path}/invitation/:token`} component={InvitPage} />
      <Route path={`${match.path}/smartapply/:token`} component={SmartApply} />-
    </Switch>
  );
}

AuthScene.propTypes = {
  match: object,
  push: func,
};

const mapDispatchToProps = {
  push,
};

const withConnect = connect(null, mapDispatchToProps);

export default compose(
  withRouter,
  withConnect,
)(toJS(AuthScene));
