import { useQuery } from 'react-query';

export function useGetExponentsStats(
  {
    event,
    view = event.format === 'hybrid' ? undefined : 'all',
  }) {
  const requestUrl = `${process.env.FRONT_GO_API_URL_OLD}/eventkpi-light?apiKey=41a1289e-fee1-430e-a849-5b2b7ed23f1a&eventId=${event._id}&eventFormat=${view}`;

  const queryFn = async () => {
    const res = await fetch(requestUrl);
    return res.json();
  };

  const query = useQuery({
    queryKey: ['exponentsStats', view],
    queryFn,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    enabled:  !(event.format === 'hybrid' && view === undefined)
  });

  return query;
}
