import React from 'react';
import colors from '@seekube-tech/ui-kit/dist/colors';
import styles2 from '@/scenes/Auth/scenes/Login/styles2.less';
import { Body1, Button, IconLinkedinFill } from '@seekube-tech/ui-kit';
import { Link } from 'react-router-dom';
import { USERS_TYPE } from '@/utils/constants';
import { useFormatMessage } from 'react-intl-hooks';
import { LinkedinOauth } from '@/components/LinkedinOauth';
import TextSeparator from '@/components/TextSeparator2';

export const Footer = ({ role }) => {
  const t = useFormatMessage();

  return (
    <>
      {USERS_TYPE.candidate === role && (
        <>
          <div className={styles2.footer}>
            <div className={styles2.changeMode}>
              <Body1 color={colors.neutral['400']} className="inline mr-4">
                {t({ id: 'signin.noAccount' })} </Body1>
              <Link to={`/auth/signup/candidate`}>
                {t({ id: 'signin.signup' })}
              </Link>
            </div>
            <div>
              <Link to={`/resetpassword`}>
                <span className="desktop">{t({ id: 'signin.forgotPassword' })}</span>
              </Link>
            </div>
          </div>
          <div className="mt-30">
            <TextSeparator>
              {t({ id: 'or' })}
            </TextSeparator>
          </div>
          <div className="text-center mt-16">
            <LinkedinOauth>
              <Button variant="outline" color="neutral" imageComponentLeft={<IconLinkedinFill size={16} />}>
                {t({ id: 'linkedin'})}
              </Button>
            </LinkedinOauth>
          </div>
        </>
      )}
      {[USERS_TYPE.owner, USERS_TYPE.recruiter].includes(role) && (
        <>
          <Link className="flex justify-center" to={`/resetpassword`}>
            <span className="desktop">{t({ id: 'signin.forgotPassword' })}</span>
          </Link>
          <div className="mt-30">
            <TextSeparator>
              {t({ id: 'or' })}
            </TextSeparator>
          </div>
          <div className="text-center mt-16">
            <LinkedinOauth>
              <Button variant="outline" color="neutral" imageComponentLeft={<IconLinkedinFill size={16} />}>
                {t({ id: 'linkedin'})}
              </Button>
            </LinkedinOauth>
          </div>
        </>
      )}
    </>
  )
}