import React, { useEffect } from 'react';
import { object, func } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Switch, Route, Redirect } from 'react-router-dom';
import { replace } from 'connected-react-router';
import { get } from 'lodash';
import { eventSelectors } from '@/store/event';
import { organizationActions } from '@/store/organization';
import { exponentActions, exponentSelectors } from '@/store/exponent';
import { authSelectors } from '@/store/auth';
import { toJS } from '@/utils';
import { EVENT_STATUS } from '@/utils/constants';
import { withLayout } from '@/containers/Layout';
import { withAuth } from '@/containers/RequiredAuth';
import ConferencePreview from '@/scenes/Client/scenes/Conferences/components/EditLivetalk/components/ConferencePreview/ConferencePreview';
import { CONTEXT_ROLES } from '@/scenes/Event/utils/constants';
import RecruiterPreparationPage from './scenes/Preparation';
import RecruiterJobdatingPage from './scenes/Jobdating';
import ConferencesPage from '@/scenes/Client/scenes/Conferences';
import ConferenceParticipants from '@/containers/ConferenceParticipants';
import KeyDates from '@/scenes/Event/components/KeyDates';

const LivetalkAll = () => (<ConferenceParticipants context="all" userType="recruiter" />);
const LivetalkPresent = () => (<ConferenceParticipants context="present" userType="recruiter" />);


const RecruiterScene = ({
  authUser,
  match,
  event,
  exponent,
  getExponentByOrganization,
  setCurrentOrganization,
  replace,
}) => {
  useEffect(() => {
    if (authUser) {
      if (authUser.isAdmin) {
        // replace(`/${match.params.eventSlug}/`);
      }

      if (authUser._currentOrganization?.id) {
        setCurrentOrganization(authUser._currentOrganization._id);
      }

      if (event && (event.type !== 'company' && event.format !== 'physical') && window.Intercom) {
        window.Intercom('update', { hunt_first_connexion: true, set_up_first_connexion: true });
      }
    }

    if (event?._id && authUser?._currentOrganization?._id) {
      getExponentByOrganization({
        eventId: event._id,
        organizationId: authUser._currentOrganization?._id,
      });
    }

    if (match.isExact) {
      const eventStatus = event.dateStatus ? event.dateStatus : null;

      if (eventStatus === EVENT_STATUS.CLOSED) {
        replace(`${match.url}/reports`);
      } else if (eventStatus === EVENT_STATUS.IN_PROGRESS || authUser._currentOrganization.isClient) {
        replace(`${match.url}/jobdating`);
      } else {
        replace(`${match.url}/preparation`);
      }
    }
  }, []);

  if (!event || !exponent) {
    return null;
  }

  if (!authUser) {
    return <Redirect to="/" />;
  }

  if (get(authUser, '_currentOrganization.isClient') && window.location.pathname.indexOf('/recruiter/preparation') > -1) {
    return <Redirect to={`${match.url}/jobdating`} />;
  }

  if (!authUser.isActive) {
    const token = btoa(`${authUser._id.toString()}:${authUser.username}`)

    return <Redirect to={`/auth/invitation/${token || authUser.resetToken || ''}`} />;
  }

  return (
    <Switch>
      <Route path={`${match.path}/preparation`} component={RecruiterPreparationPage} />
      <Route path={`${match.path}/jobdating`} component={RecruiterJobdatingPage} />
      <Route path={`${match.path}/conferences/:conferenceID/all`} component={LivetalkAll} />
      <Route path={`${match.path}/conferences/:conferenceID/present`} component={LivetalkPresent} />
      <Route path={`${match.path}/conferences/:conferenceID/preview`} render={() => <ConferencePreview authUser={authUser} />} />
      <Route path={`${match.path}/conferences`} component={ConferencesPage} />
      <Route path={`${match.path}/keydates`} render={() => <KeyDates userKeyMomentFormats={exponent?.keyMomentFormats} context={CONTEXT_ROLES.RECRUITER} />} />
    </Switch>
  );
}

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
  exponent: exponentSelectors.getCurrentExponent,
  event: eventSelectors.getCurrentEvent,
});

const mapDispatchToProps = {
  getExponentByOrganization: exponentActions.getExponentByOrganization,
  setCurrentOrganization: organizationActions.setCurrentOrganization,
  replace,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

RecruiterScene.propTypes = {
  match: object,
  replace: func,
  authUser: object,
  event: object,
  exponent: object,
  setCurrentOrganization: func,
  getExponentByOrganization: func,
};

export default compose(
  withConnect,
  withLayout({ size: 'full', navType: 'recruiter' }),
  withAuth({ except: 'signup' }),
)(toJS(RecruiterScene));
