import React, { useState } from 'react';
import { bool, func, object, array, oneOf } from 'prop-types';
import { compose } from 'redux';
import { isEmpty, uniq } from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage, injectIntl } from 'react-intl';
import { H4 } from '@seekube-tech/ui-kit';
import { toJS } from '@/utils';
import { getIds } from '@/utils/global';

// Store
import { exponentSelectors, exponentActions } from '@/store/exponent';
import { userActions, userSelectors } from '@/store/user';
import { eventActions, eventSelectors } from '@/store/event';
import { authSelectors } from '@/store/auth';

// Components
import Modal from '@/components/Modal';
import Icon from '@/components/Icon';
import OrganizationUsers from './components/OrganizationUsers';
import messages from '../../../../../Recruiter/scenes/Preparation/scenes/Team/messages';
import messagesModal from './messages';

// Styles & Translations
import './styles.less';
import { useFormatMessage } from 'react-intl-hooks';
import { useGetOrganizationUsers } from '@/queries/organizations/useGetOrganizationUsers';

const CONTEXTS = ['jobdating', 'admin', 'team', 'all', 'owner_settings'];


const CollaboratorModal = (props) => {
  const { organization, context = 'jobdating', currentExponent, currentEvent, patchEvent, patchExponent, error, onOk, postUserOrganization, authUser, isOpen, onCancel } = props;
  const { data: usersOrganization, ...organizationUsersQuery } = useGetOrganizationUsers({ search: { limit: 1000 }, organization });
  const t  = useFormatMessage();

  const [usersInExponent, setUsersInExponent] = useState([]);

  const handleOnAddUser = (usersInExponent, usersToAdd) => {
    if (!isEmpty(currentExponent) && context === 'admin') {
      if (usersInExponent) {
        setUsersInExponent(usersInExponent);
      }
      // TODO: modal utilisé côté client / admin / owner, mieux distinguer les impacts

      patchExponent({
        exponentId: currentExponent._id,
        eventId: currentExponent._event._id,
        exponentParams: {
          multipleUsers: usersToAdd,
        },
        notificationParams: {
          success: {
            message: t({ id: 'toasters.addCoworkerToEvent.success' }, { count: usersToAdd.length }),
            kind: 'info',
            style: {
              bottom: '5%',
              top: 'inherit',
            },
          },
          error: {
            style: {
              top: '5%',
            },
            message: `${t(messages.notificationErrorMemberCreate)}`,
          },
        },
        callback: onOk,
      });
    } else if (context === 'owner_settings') {
      const usersIds = usersToAdd.map((u) => u._user._id || { username: u._user.username, organizationId: organization._id });
      const planners = { _organizations: getIds(currentEvent.planners._organizations), _users: uniq([...getIds(currentEvent.planners._users), ...usersIds]) };

      patchEvent({
        event: { planners },
        callback: onOk,
        notificationParams: {
          success: {
            message: t({ id: 'notifications.update.success' }),
            kind: 'info',
            style: {
              bottom: '5%',
              top: 'inherit',
            },
          },
        }
      });
    } else if (organization) {
      const users = usersToAdd.map((u) => ({...u._user, roleId: u.roleId }));

      postUserOrganization({
        organizationId: organization._id,
        users,
        notificationParams: {
          success: {
            message: t({ id: 'toasters.addUserToOrganization.success' }, { count: usersToAdd.length }),
            kind: 'info',
            style: {
              bottom: '5%',
              top: 'inherit',
            },
          },
          error: true,
        },
        callback: onOk,
      });
    }
  };

  /**
   * HandleOnOk
   *
   * @description
   * When click on save, update the list into the database and close the modal
   */
  const handleOnOk = () => {
    if (!isEmpty(currentExponent) && context === 'admin') {
      patchExponent({
        exponentId: currentExponent._id,
        eventId: currentExponent._event._id,
        exponentParams: {
          users: usersInExponent,
        },
        notificationParams: {
          success: {
            message: t({ id: 'toasters.updateExponant.success' }),
            kind: 'info',
            style: {
              bottom: '5%',
              top: 'inherit',
            },
          },
          error: true,
        },
        callback: onOk,
      });
    }
  };

  const renderOrganizationUsers = () => {
    return (
      <OrganizationUsers
        usersInExponent={usersInExponent}
        isFetching={organizationUsersQuery.isFetching}
        onAddUserInExponent={handleOnAddUser}
        exponent={currentExponent}
        usersOrganization={usersOrganization.docs || []}
        error={error}
        modalCollaboratorIsOpen={isOpen}
        authUser={authUser}
        organization={organization || currentExponent._organization}
        planners={currentEvent?.planners}
      />
    );
  };


  return (
    <Modal
      visible={isOpen}
      onCancel={onCancel}
      onOk={handleOnOk}
      cancelText={t({ id: 'cancel' })}
      width={654}
      footer={false}
      className="customConfirm"
    >
      <a role="button" tabIndex={0} className="modal-close" onClick={onCancel}>
        <Icon name="close" className="modal-close-icon" />
      </a>
      <H4 className="mb-35">
        <FormattedMessage
          {...messagesModal[context === 'owner_settings' ? 'modalTitlePlanners' : 'modalTitle2']}
        />
      </H4>
      {isOpen && renderOrganizationUsers()}
    </Modal>
  );
}

CollaboratorModal.propTypes = {
  isOpen: bool,
  isFetching: bool,
  onCancel: func,
  onOk: func,
  intl: object,
  currentEvent: object,
  organization: object,
  context: oneOf(CONTEXTS),
  error: object,
  currentExponent: object,
  usersOrganization: array,
  patchExponent: func,
  postUserOrganization: func,
  patchEvent: func,
  authUser: object,
}

const mapStateToProps = createStructuredSelector({
  currentExponent: exponentSelectors.getCurrentExponent,
  isFetching: exponentSelectors.getExponentsFetching,
  error: exponentSelectors.getError,
  currentEvent: eventSelectors.getCurrentEvent,
  authUser: authSelectors.getAuthUser,
});

const mapDispatchToProps = {
  patchExponent: exponentActions.patchExponent,
  postUserOrganization: userActions.postUserOrganization,
  patchEvent: eventActions.patchEvent,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(toJS(CollaboratorModal));

