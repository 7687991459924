import React from 'react';
import { object, oneOf, string } from 'prop-types';
import { Body1, IconInfo } from '@seekube-tech/ui-kit';
import { FormattedMessage } from 'react-intl';
import { getEarliestKeyMoment } from '@/utils/keyMoments';
import styles from './styles.less';
import { EVENT_FORMAT } from '@/utils/constants';

const SeparatorParticipationExpired = ({ event, context = 'offer', format }) => {
  const interactionsClosingEarliestKeyMoment = getEarliestKeyMoment(event, 'interactionsClosing');
  const applicationsClosingEarliestKeyMoment = getEarliestKeyMoment(event, 'applicationsClosing');
  const earliestClosingDate = interactionsClosingEarliestKeyMoment?.isBefore(applicationsClosingEarliestKeyMoment) ?
    interactionsClosingEarliestKeyMoment : applicationsClosingEarliestKeyMoment;
  const earliestFormat = getEarliestKeyMoment(event, 'interactionsClosing', EVENT_FORMAT.PHYSICAL) === interactionsClosingEarliestKeyMoment ? EVENT_FORMAT.PHYSICAL : EVENT_FORMAT.VIRTUAL;

  const getMessage = () => {
    switch (context) {
      case 'offer':
        return (
            <FormattedMessage
            id="candidate.jobs.offers.divider.apply.closed"
            values={{
              date: earliestClosingDate.format('DD MMMM'),
              time: earliestClosingDate.format('H[h]')
            }}
          />
        )
      case 'participantExpired':
        return (
          <p className={styles.text}>
            <FormattedMessage
              id="recruiter.participants.list.divider"
              values={{
                date: interactionsClosingEarliestKeyMoment?.format('DD MMMM'),
                format: earliestFormat,
              }}
            />
            {' '}
            {earliestFormat === EVENT_FORMAT.VIRTUAL ?
              <FormattedMessage
                id="exponent.participationMode.physical"
              />
              :
              <FormattedMessage
                id="exponent.participationMode.virtual"
              />
            }
            <a href={`${window.location.origin}/${event.slug}/recruiter/keydates`}>
              {' '} <FormattedMessage
                id="see.calendar"
              />
            </a>
          </p>
        )
      default: return null
    } 
  }

  return (
    <div className={styles.separator}>
      <div className={styles.border}/>
      <Body1 className="flex items-center px-12 text-neutral-500">
        <IconInfo className="mr-6" size={16}/>
        {getMessage()}
      </Body1>
      <div className={styles.border}/>
    </div>
  );
}

SeparatorParticipationExpired.propTypes = {
  event: object,
  context: oneOf(['offer', 'participantExpired']),
  format: string,
}

export { SeparatorParticipationExpired }