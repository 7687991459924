import React from 'react';
import { string } from 'prop-types';
import classnames from 'classnames';
import { Element } from 'react-scroll';

// Styles
import styles from './styles.less';

/**
 * Date
 *
 * @param {object} props
 * @param {string} props.date
 * @param {string} props.activeDay
 */
const DateAgenda = ({ date, activeDay }) => (
  <Element
    role="button"
    tabIndex={0}
    className={classnames(styles.dateElement, activeDay === date ? styles.todayElement : null)}
    id={`date${date}`}
  >
    {window.moment(date).format('dddd D MMM')}
  </Element>
);

DateAgenda.propTypes = {
  date: string,
  activeDay: string,
};

export default DateAgenda;
