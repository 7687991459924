import { useEffect, useState } from 'react';

import {
  getAppointmentsStats,
  getExponentsActivities, getExponentsStats,
  getExponentsUsers,
  getOffersStats,
  getParticipants,
} from './requests';

const useStats = ({ _id, slug }) => {
  const [exponentsUsersStats, setExponentsUsersStats] = useState([]);
  const [exponentsActivitiesStats, setExponentsActivitiesStats] = useState([]);
  const [offersStats, setOffersStats] = useState([]);
  const [exponentsStats, setExponentsStats] = useState({});
  const [appointmentsStats, setAppointmentsStats] = useState({});
  const [participantsStats, setParticipantsStats] = useState({});

  useEffect(
    () => {
      getExponentsStats({ eventId: _id }).then((stats) =>
        setExponentsStats(stats)
      );
      getExponentsUsers({ eventId: _id }).then((stats) =>
        setExponentsUsersStats(stats)
      );
      getExponentsActivities({ slug }).then((stats) =>
        setExponentsActivitiesStats(stats)
      );
      getAppointmentsStats({ slug }).then((stats) =>
        setAppointmentsStats(stats)
      );
      getOffersStats({ slug }).then((stats) =>
        setOffersStats(stats)
      );
      getParticipants({ eventId: _id }).then((stats) =>
        setParticipantsStats(stats)
      );
    }, []);

  return { exponentsUsersStats, exponentsActivitiesStats, offersStats, exponentsStats, appointmentsStats, participantsStats };
};
export { useStats };
