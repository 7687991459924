import React from 'react';
import { injectIntl } from 'react-intl';
import { object } from 'prop-types';
import find from 'lodash/find';
import isUndefined from 'lodash/isUndefined';

// Containers
import NavEvent from '@/components/Nav/NavEvent';
import { NavScenePortal } from '@/containers/Layout'; // eslint-disable-line

// Styles & Translations
import messages from './messages';

const NavOnBoarding = ({ match, user, exponent, event, intl }) => {
  const elems = [];

  if (user && exponent) {
    const exponentUser = find(exponent.users, (u) => u._user && u._user._id === user._id);

    if (!isUndefined(exponentUser) || user.isAdmin) {
      elems.push({ url: `${match.url}/team`, label: intl.formatMessage(messages.team), count: exponent.users.length, icon: 'user', type: 'blue' });
    }
    if (event.modules.offer.enable) {
      elems.push({ url: `${match.url}/offers`, label: intl.formatMessage(messages.offers), count: exponentUser ? exponentUser.countOffers : 0, icon: 'contract', type: 'blue' });
    }

    elems.push({ url: `${match.url}/timeslots`, label: intl.formatMessage(messages.timeslots), count: exponentUser ? exponentUser.countSlots : 0, icon: 'calendar', type: 'blue' });

    if (!isUndefined(exponentUser) || user.isAdmin) {
      elems.push({ url: `${match.url}/stand`, label: intl.formatMessage(messages.stand), icon: 'building', type: 'blue' });
    }
  }

  return (
    <NavScenePortal>
      <NavEvent items={elems} />
    </NavScenePortal>
  );
};

NavOnBoarding.propTypes = {
  match: object,
  user: object,
  exponent: object,
  event: object,
  intl: object,
};

export default injectIntl(NavOnBoarding);
