import { createAction } from 'redux-actions';
import * as types from './types';

/**
 * Action for get user timeslots
 */
export const getUserTimeslots = createAction(types.GET_USER_TIMESLOTS);
export const getUserTimeslotsSuccess = createAction(types.GET_USER_TIMESLOTS_SUCCESS);
export const getUserTimeslotError = createAction(types.GET_USER_TIMESLOTS_ERROR);

/**
 * Action for post user timeslots
 */
export const postUserTimeslots = createAction(types.POST_USER_TIMESLOTS);
export const postUserTimeslotsSuccess = createAction(types.POST_USER_TIMESLOTS_SUCCESS);
export const postUserTimeslotError = createAction(types.POST_USER_TIMESLOTS_ERROR);

export const patchUserTimeslotsDuration = createAction(types.PATCH_USER_TIMESLOTS_DURATION);
export const patchUserTimeslotsDurationSuccess = createAction(types.PATCH_USER_TIMESLOTS_DURATION_SUCCESS);

/**
 * Action for delete user timeslots
 */
export const delUserTimeslots = createAction(types.DEL_USER_TIMESLOTS);
export const delUserTimeslotsSuccess = createAction(types.DEL_USER_TIMESLOTS_SUCCESS);
export const delUserTimeslotError = createAction(types.DEL_USER_TIMESLOTS_ERROR);
