import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { any, object, bool, func, string } from 'prop-types';
import { compose, groupBy, mapValues, merge, isEmpty, keys, flatten } from 'lodash/fp';
import { useFormatMessage } from 'react-intl-hooks';
import classnames from 'classnames';
import { getId } from '@/utils/global';
import { EVENT_FORMAT } from '@/utils/constants';
import { EVENTS_COLORS } from '@/components/BigCalendar/ducks/BigCalendarConstants';
import Timeline from './components/Timeline';
import {
  SelectEvent,
  SelectRecruiter,
} from '../../../../../Preparation/scenes/Timeslot/containers/Calendar/components';
import { getDatesBetweenTwoDates } from './services/helper';
import styles from './styles.less';
import { useGetEventsSelect } from '@/queries/agenda/planning/useGetEventsSelect';
import { useGetUsersSelect } from '@/queries/agenda/planning/useGetUsersSelect';
import { useInfiniteGetPlanning } from '@/queries/agenda/planning/useInfiniteGetPlanning';

const getItemsStructure = (dates) => dates
  .reduce((acc, date) => {
    acc[date.format(DATE_FORMAT)] = {};
    return acc;
  }, {});


const groupItemsByDate = groupBy((item) => item._timeslot ? moment(item._timeslot.beginAt).format(DATE_FORMAT) : moment(item.date).format(DATE_FORMAT));
/**
 * Group items by time
 */
const groupItemsByTime = groupBy((item) => item._timeslot ? moment(item._timeslot.beginAt).format(TIME_FORMAT) : moment(item.date).format(DATE_FORMAT));

/**
 * Group items by date and time
 */
const groupItemsByDateAndTime = compose(
  mapValues(groupItemsByTime),
  groupItemsByDate,
);
/**
 * Format used for structure object used by the timeline
 */
const DATE_FORMAT = 'YYYY-MM-DD';
const TIME_FORMAT = 'HH';
/**
 * AgendAgendaPlanningScene
 */
const AgendaPlanningScene = ({
  authUser,
  modifyAppointment,
  cancelAppointment,
  onDownload,
  events,
  exponent,
  currentOption,
  getParticipant,
  closeCalendar,
  onChangeEvent,
  onChangePlanningUser,
  onToggleArchive,
  updateAgendaView,
  push,
  archive,
  displayDownloadError,
  event,
  planningUser,
}) => {
  const t = useFormatMessage();
  const [timelineItems, setTimelineItems] = useState({});
  const [countItems, setCountItems] = useState(0);
  const [exponentFormat, setExponentFormat] = useState(EVENT_FORMAT.VIRTUAL);

  const eventsSelectQuery = useGetEventsSelect({ userId: getId(planningUser) });
  const usersSelectQuery = useGetUsersSelect({ eventId: event?._id });
  const planningInfiniteQuery = useInfiniteGetPlanning({
    eventId: event?._id, userId: getId(planningUser), archive,
    onSuccess: (data) => {
      const appointments3 = flatten(data.pages.map((page) => page.docs));

      const newState = {};
      const eventBeginAt = typeof event === 'object' && event.weeks.all ? event.weeks.all[0].beginAt : moment().toDate();
      const eventEndAt = typeof event === 'object' && event.weeks.all ? event.weeks.all[event.weeks.all.length - 1].endAt : moment().add(10, 'days').toDate();
      const eventDates = getDatesBetweenTwoDates(eventBeginAt, eventEndAt, false);
      const timelineItems = merge(
        getItemsStructure(eventDates),
        groupItemsByDateAndTime(appointments3.filter((a) => a.type === 'conference' || !isEmpty(a.elem._timeslot))),
      );

      newState.timelineItems = timelineItems;
      if (!isEmpty(timelineItems)) {
        let countItemss = 0;

        keys(timelineItems)
          .forEach((dateString) => {
            if (!isEmpty(timelineItems[dateString])) {
              countItemss += 1;
            }
          });

        if (countItems !== countItemss) {
          newState.countItems = countItemss;
          setCountItems(countItemss);
        }
      }

      if (!isEmpty(newState)) {
        setTimelineItems(timelineItems);
      }
    }
  });

  const jobfairsColor = useMemo(() => {
    const hashmap = {};

    events.forEach((jobfair, index) => {
      hashmap[jobfair._id] = EVENTS_COLORS[(index % 10)];
    });

    return hashmap;
  }, [events]);


  useEffect(() => {
    if (exponent?._keyMomentLinks?.length > 1) {
      setExponentFormat(EVENT_FORMAT.HYBRID);
    }
    else if (exponent?._keyMomentLinks[0]?._keyMoment.format === EVENT_FORMAT.PHYSICAL) {
      setExponentFormat(EVENT_FORMAT.PHYSICAL);
    }
    else {
      setExponentFormat(EVENT_FORMAT.VIRTUAL);
    }
  }, [exponent]);


  return (
    <div className={styles.planningWrapper}>

      <div className={styles.selectEvent}>
        <div style={{ flex: '1', position: 'relative', marginRight: '20px' }}>
          <SelectEvent
            context="planning"
            defaultValue={event && event._id ? event._id : 'all'}
            placeholder={t({ id: 'selectEvent' })}
            onSelect={onChangeEvent}
            events={eventsSelectQuery?.data?.docs}
            allowAll
            exponentFormat={exponentFormat}
            hasError={displayDownloadError}
            filterOption={(input, option) => option.props.title && option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          />
        </div>
        <div className={classnames(styles.userContainer)}>
          <SelectRecruiter
            authUser={authUser}
            handleSelectRecruiter={(userId)=>onChangePlanningUser(usersSelectQuery?.data?.docs.find((user) => getId(user) === userId))}
            users={usersSelectQuery?.data?.docs}
            selectedUser={planningUser ? getId(planningUser) : 'all'}
          />
        </div>
        {displayDownloadError ? (<span className="ant-message-error">{t({ id: 'recruiter.planning.downloadError' })}</span>) : null}
      </div>

      <div className={styles.timelineWrapper}>
        <Timeline
          archive={archive}
          items={timelineItems}
          countItems={countItems}
          closeCalendar={closeCalendar}
          updateAgendaView={updateAgendaView}
          event={event}
          onDownload={onDownload}
          handleOnChangeAppointment={modifyAppointment}
          handleOnCancelAppointment={cancelAppointment}
          authUser={authUser}
          currentExponent={exponent}
          currentOption={currentOption}
          planningUser={planningUser}
          activeDay={moment().format('YYYY-MM-DD')}
          getParticipant={getParticipant}
          planningInfiniteQuery={planningInfiniteQuery}
          push={push}
          onToggleArchive={onToggleArchive}
          jobfairsColor={jobfairsColor}
        />
      </div>
    </div>
  );
};

AgendaPlanningScene.propTypes = {
  authUser: object,
  planningUser: object,
  event: any,
  modifyAppointment: func,
  cancelAppointment: func,
  onDownload: func,
  events: any,
  exponent: object,
  currentOption: string,
  getParticipant: func,
  closeCalendar: func,
  onChangeEvent: func,
  onToggleArchive: func,
  updateAgendaView: func,
  onChangePlanningUser: func,
  push: func,
  archive: bool,
  displayDownloadError: bool,
};

export default AgendaPlanningScene;
