import React, { useEffect, useState } from 'react';
import { array, bool, func, string } from 'prop-types';
import DraggableItem from '@/components/DraggableItem';

const Draggable = ({
  items,
  onDragEnd,
  moveItem,
  itemType,
  localSort,
}) => {
  const [localItems, setLocalItems] = useState(items);

  const moveLocalItem = (fromIndex, toIndex) => {
    const newArray = [...localItems];
    const elemToMove = newArray.splice(fromIndex, 1);

    newArray.splice(toIndex, 0, ...elemToMove);

    setLocalItems(newArray);
  }

  const onLocalDragEnd = () => {
    onDragEnd(localItems);
  }

  useEffect(() => {
    setLocalItems(items);
  }, [items]);

  return (
    <>
      {
        (localSort ? localItems : items ?? []).map((item, index) => {
          return (
            <DraggableItem
              key={`item-${index}`}
              index={index}
              moveItem={localSort ? moveLocalItem : moveItem}
              itemType={itemType}
              onDragEnd={localSort ? onLocalDragEnd : onDragEnd}
              disabled={item.disabled}
            >
              <div>{item.content}</div>
            </DraggableItem>
          )
        })
      }
    </>
  )
}

Draggable.propTypes = {
  items: array,
  onDragEnd: func,
  moveItem: func,
  itemType: string,
  localSort: bool
};

export default Draggable;
