import { List } from 'immutable';
import moment from 'moment';
import { denormalize } from 'normalizr'; // eslint-disable-line
import { createSelector } from 'reselect';
import { timeslotListSchema } from './schema'; // eslint-disable-line

const getUserReducer = (state) => state.get('user');
const getEntities = (state) => state.get('entities');
const getTimeslotReducer = (state) => state.get('timeslot');

/**
 * Return all timeslot of the current user
 */
export const getCurrentUserTimeslots = createSelector(
  getUserReducer,
  getEntities,
  getTimeslotReducer,
  (userState, entities, timeslotState) => {
    const userId = userState.get('currentId');
    const timeslotIds = timeslotState.getIn(['byUserId', userId]) || new List();
    const timeslotEntities = entities.get('timeslots');

    const timeslots = timeslotIds
      .map((id) => timeslotEntities.get(id))
      .sortBy((timeslot) => moment(timeslot.get('beginAt')).toString());

    return timeslots;
  }
);

export const getCalendarUserTimeslots = createSelector(
  getUserReducer,
  getEntities,
  getTimeslotReducer,
  (userState, entities, timeslotState) => {
    const userId = timeslotState.get('calendarId');
    const timeslotIds = timeslotState.getIn(['byUserId', userId]) || new List();
    const timeslotEntities = entities.get('timeslots');

    return timeslotIds
      .map((id) => timeslotEntities.get(id))
      .sortBy((timeslot) => moment(timeslot.get('beginAt')).toString());
  }
);


/**
 * Get event pagination
 */
export const getUserTimeslotsFetching = createSelector(
  getTimeslotReducer,
  (timeslotState) => timeslotState.get('isFetching'),
);

export const getModalUserTimeslots = createSelector(
  getEntities,
  getTimeslotReducer,
  (entities, timeslotState) => {
    const userId = timeslotState.get('modalUserId');
    const timeslotIds = timeslotState.getIn(['modalByUserId', userId]) || new List();
    const timeslotEntities = entities.get('timeslots');

    const timeslots = timeslotIds
      .map((id) => timeslotEntities.get(id))
      .sortBy((timeslot) => moment(timeslot.get('beginAt')).toString());

    return timeslots;
  }
);
