import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { object, func } from 'prop-types';
import { Form } from 'antd';
import { Button, H4, Body1 } from '@seekube-tech/ui-kit';
import colors from '@seekube-tech/ui-kit/dist/colors';

// components
import AvatarEditorDnd from '@/components/AvatarEditorDnd';

import 'react-phone-number-input/style.css';

// Styles & Translations
import messages from '../../messages';
import {
  Phone
} from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Profile/Presentation/Phone';
import {
  FirstName
} from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Profile/Presentation/FirstName';
import {
  LastName
} from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/scenes/Profile/Presentation/LastName';
import { Description, JobTitle } from '@/forms/components';
import { useFormatMessage } from 'react-intl-hooks';
import { useIsMobile } from '@/utils/MediaQueries';
import { useHistory } from 'react-router-dom';
import { track } from '@/utils/analytics';
import { ANALYTICS_RECRUITER } from '@/utils/constants';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { patchMe, patchUser } from '@/store/user/actions';



const FormItem = Form.Item;


/**
 * <Welcome />
 *
 * @description
 * Handle first step of signup process for recruiter
 */
const Identity = (props) => {
  const { form, user } = props;
  const t = useFormatMessage();
  const [profilePicture, setProfilePicture] = useState(user?.pictureUrl || null);
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = useIsMobile();

  const updateInput = () => {

    form.setFieldsValue({
      pictureUrl: profilePicture,
    });
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const { user } = props;

    return form.validateFields((err, userParams) => {
      if (!err) {
        dispatch(patchMe({
          userParams,
          resetStatus: 'success_convert',
          callback: () => {
            track({
              authUser: user,
              name: ANALYTICS_RECRUITER.EDIT_PROFILE,
              properties: {
                step: 'Sign up',
                photo: !isEmpty(userParams.pictureUrl),
                summary: !isEmpty(userParams.description),
                myProfile: true,
                full: !isEmpty(userParams.pictureUrl) && !isEmpty(userParams.title) && !isEmpty(userParams.firstName) &&
                  !isEmpty(userParams.lastName) && !isEmpty(userParams.description),
              },
            });
            props.handleGoToNextStep(null, 3);
          },
          notificationParams: {
            success: {
              message: t({ id: 'notifications.update.success' }),
            },
          }
        }));
      }
    })


  };

  const handleSaveProfilePicture = (e) => {
    setProfilePicture(e);
    updateInput();
  };

  const handleRemoveProfilePicture = () => {
    setProfilePicture(null);
  };


  return (
    <div className="wrapperModal">
      <div className="content">
        <H4 fontWeight={900} className="mb-24">{t({ id: 'event.recruiter.preparation.signup.identity.title' })}</H4>

        <Form onSubmit={handleOnSubmit}>
          <AvatarEditorDnd
            src={profilePicture || undefined}
            onSave={handleSaveProfilePicture}
            onRemove={handleRemoveProfilePicture}
            objectId={user._id}
            size={130}
          >
            <div>
              <Button variant="outline" style={{ display: 'block' }}>
                {t(messages.pictureUrlChoose)}
              </Button>

              <Body1 color={colors.neutral['400']}>
                {isMobile ?
                  t({ id: 'import.picture.format.short' })
                  : t({ id: 'event.recruiter.preparation.signup.identity.pictureUrl.help' })
                }
              </Body1>
            </div>
          </AvatarEditorDnd>
          <FormItem>
            {form.getFieldDecorator('pictureUrl', {
              rules: [{ required: false }],
            })(
              <input type="hidden" />
            )}
          </FormItem>

          <FirstName form={form} user={user} />

          <LastName form={form} user={user} />

          <JobTitle form={form}  user={user} />

          <Phone form={form} user={user} required={false} />

          <Description form={form} user={user} />

          {form.getFieldDecorator('linkedinProfileUrl', {
            initialValue: '',
            rules: [{ required: false }],
          })(
            <input type="hidden" />
          )}
          <div className="flex justify-end">
            <Button
              className={isMobile && 'w-full'}
              type="submit"
            >{t({ id: 'next' })}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

Identity.propTypes = {
  handleGoToNextStep: func,
  user: object,
  form: object,
  handleSubmitIdentity: func,
  intl: object,
};

export default Form.create()(injectIntl(Identity));
