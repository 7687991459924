import { USERS_TYPE } from '@/utils/constants';
import { initialState } from '@/store/auth/reducer';
import { authLogoutUser } from '@/store/auth/actions';
import { store } from '@/configureStore';

export const redirectUnAuthUser = (user) => {
  store.dispatch(authLogoutUser());
  
  if (!user) {
    return window.location.replace('/auth/login/candidate');
  }
  
  const userType = user._currentOrganization ? USERS_TYPE.recruiter : USERS_TYPE.candidate;
  const savedLocation = window.location.pathname;
  
  localStorage.setItem('redux', JSON.stringify({ ...JSON.parse(window.localStorage.getItem('redux')), auth: initialState }));
  return window.location.replace(`/auth/login/${userType}?redirect=${savedLocation}`);
}