import React from 'react';
import { H4, Subtitle1 } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import styles from './styles.less';

const NoResults = () => {
  const t = useFormatMessage();
  
  return (
    <div className={styles.title}>
      <H4 className="text-neutral-500">
        {t({ id: 'searchEngine.noResults' })}
      </H4>
      <Subtitle1 className="text-neutral-400">
        {t({ id: 'searchEngine.noResults.subtitle' })}
      </Subtitle1>
    </div>
  )
}

export { NoResults };