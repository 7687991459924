import React from 'react';
import { object, func, oneOf, node, bool, array, string } from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import { isEmpty, isFunction, toString } from 'lodash';

// Components
import { injectIntl, FormattedHTMLMessage } from 'react-intl';
import Highlighter from 'react-highlight-words';
import { CONTEXTS_ALL, getId, CONTEXTS_RECRUITER } from '@/utils/global';
import { IconsHybrid } from '@/components/IconsHybrid';
import { H2 } from '@/components/Title';
import Card from '@/components/Card';
import Icon from '@/components/Icon';
import { Tooltip } from '@/components/Tooltip';
import { If } from '@/components/If';
import { getCdnUrl } from '@/components/ImgCdn';
import { AvatarCandidate, Tag, TagProps } from '@seekube-tech/ui-kit';
import { isParticipantOnboardingEnable } from '@/helpers/event/isParcicipantOnboardingEnable';
import ParticipantScoring from '../../../ParticipantScoring';
import { InteractionTag } from '../components/InteractionTag';
import {
  Resume,
  SearchRow,
  ColleagueInCharge, Applications, Matchings, LiveTag,
} from '@/components/ParticipantCards/components/components';

// Styles & Translations
import messages from '../messages';
import styles from './styles.less';

const MediumParticipantCard = ({
  actions,
  areInteractionsClosed,
  context,
  currentUser = {},
  enableCoreTarget = false,
  exponent,
  functions,
  hideCheck,
  highlightWords = [],
  interactions,
  intl,
  isChecked = false,
  isNew = false,
  isHybrid = false,
  isVisited = false,
  liveTag,
  offers,
  onCardToggle,
  onOpen,
  participant = {},
  skipAppointmentValidation,
  onClick,
  viewBy = {},
}) => {
  const handleOnScore = (score, appointment, withMessage) => {
    const scoring = { score, appointmentId: getId(appointment) };
    const statusSorted = ['score0', 'score1', 'score2'];

    if (score === 0 && withMessage) {
      functions.openModal(null, appointment, participant, 'negativeScoring');
    } else {
      functions.moveParticipant(null, participant, statusSorted[score], scoring);
    }
  };

  const { _user } = participant;
  // Display the component only when we participanthave all informations
  if (isEmpty(_user)) {
    return null;
  }

  const myInteraction = interactions
  .find((interaction) => (
    interaction.enable
    && interaction._recruiter
    && ((
      interaction._owner && toString(interaction._owner._id) === toString(currentUser._id)
    ) || toString(interaction._recruiter._id) === toString(currentUser._id))));

  const interactionEnable = interactions.find((interaction) => interaction.enable && (interaction._appointment || interaction._offer));
  const interactionEnableWithAppointmentValidated = interactions.find((interaction) => interaction.enable && interaction._recruiter?._id === currentUser._id && interaction._appointment?.status === 'accepted');

  const renderResume = () => (
    <Resume
      size="medium"
      positions={_user.positions}
      educations={_user.educations}
      highlightWords={highlightWords}
    />
  );

  const displayHybridIcons = isHybrid && (location.pathname.includes('owner') || exponent?.keyMomentFormats?.length > 1);

  const formatParticipant = participant?.keyMomentFormats?.length > 1 ? 'all' : participant.keyMomentFormats?.[0];
  const isConfirmedContext = context === 'confirmed';
  const displayScoring = interactionEnableWithAppointmentValidated && isConfirmedContext && moment(interactionEnableWithAppointmentValidated._appointment?.date).add(15, 'minutes') <= moment();

  function getTagColor() {
    switch (liveTag) {
      case 'present': return 'primary';
      case 'accepted': return 'success';
      case 'confirmed': return 'success';
      case 'pending': return 'grey';
      case 'refused': return 'error';
      case 'canceled': return 'error';

      default: return 'grey'
    }
  }
  /**
   * ⇒ SI l’étape “Parcours” est désactivé à l’onboarding candidat
   * ⇒ ET QUE aucun des 3 critères de matching (parmi contrat | début | mobilité) n’est enable sur le profil candidat
   */
  const getHasSearchExperienceOrStartDate = () => {
    const { CONTRACT, MOBILITY, date } = participant.matching
    return (CONTRACT.length > 0 || MOBILITY.length > 0 || date !== null)

  }

  return (
    <Card
      containerClassName={classnames(styles.candidateProfileContainer)}
      contentClassName={classnames(styles.noPadding, styles.content)}
      borderPosition="none"
      size="medium"
      onClick={onClick}
    >
      <div className={classnames(styles.cardOverlay, isChecked ? styles.selected : '')}>
        {!hideCheck ? (<div><span tabIndex={0} role="button" onClick={() => onCardToggle(isChecked, participant._id)} className={styles.participantSelection} /></div>) : null}
      </div>

      <If condition={(!skipAppointmentValidation || areInteractionsClosed) && displayScoring && !isEmpty(viewBy._currentOrganization) && myInteraction}>
        <div className={styles.scoreHeaderContainer}>
          <ParticipantScoring
            {...participant}
            onScore={handleOnScore}
            appointment={myInteraction?._appointment}
          />
        </div>
      </If>

      <div className={styles.cardContent}>
        <div className={styles.topContainer} style={{ paddingBottom: '20px' }}>
          <div className={styles.avatarWrapper}>
            <AvatarCandidate
              size={80}
              user={_user}
              pictureUrl={getCdnUrl(_user.pictureUrl, 80) ?? ''}
              componentBottom={displayHybridIcons && <IconsHybrid name={participant?._user?.shortName} hybridFormat={formatParticipant} />}
            />
          </div>

          {/* Right */}
          <div className={styles.candidateContainer}>
            <div className={styles.nameContainer}>
              {/* FirstName */}
              <div>
                <H2 className={classnames(styles.name, isVisited ? styles.visited : '')}>
                  <a role="button" onClick={onOpen} tabIndex={0}>
                    <Highlighter
                      highlightClassName="highlightText"
                      searchWords={highlightWords}
                      autoEscape
                      textToHighlight={_user.fullName}
                    />
                  </a>
                </H2>

                {CONTEXTS_RECRUITER.includes(context) && participant.isCoreTarget && enableCoreTarget && (
                  <Tooltip
                    color="dark"
                    placement="top"
                    trigger="hover"
                    title={(
                      <FormattedHTMLMessage
                        id="participant.profile.coreTarget.tooltip"
                        values={{ href: currentUser.locale === 'fr' ? `https://intercom.help/seekube/fr/articles/5877242-identifier-les-profils-les-plus-pertinents-sur-un-evenement-thematique-seekube` : 'https://help.seekube.com/en/articles/5877242-identify-the-most-relevant-profiles-on-seekube-s-thematic-events' }}
                      />
                    )}
                  >
                    <button className={styles.coreTarget}>
                      <Icon name="core-target" className={styles.iconCoreTarget} />
                    </button>
                  </Tooltip>
                )}

                <h4 className={styles.title}>
                  {_user.title && _user.title.length > 0 ?
                    <Highlighter
                      highlightClassName="highlightText"
                      searchWords={highlightWords}
                      autoEscape
                      textToHighlight={_user.title}
                    />
                    : '-'}</h4>
                {/* Contacts */}
                {['interview', 'save', 'top', 'transferred', 'confirmed', 'refused', 'refuse', 'unconfirmed'].includes(context) && (
                  <InteractionTag authUserId={getId(currentUser)} interaction={myInteraction} />
                )}
                {isNew && ['applications', 'unconfirmed'].includes(context) && (
                  <div className={styles.isNew}>{intl.formatMessage(messages.isNewApplications)}</div>
                )}
                {isNew && context === 'sourcing' && (
                  <div className={styles.isNew}>{intl.formatMessage(messages.isNewSourcing)}</div>
                )}
                {liveTag && (
                  <LiveTag liveTag={liveTag} />
                )}
              </div>
            </div>
          </div>

          {/* Actions */}
          <div className={styles.actionsContainer}>
            {isFunction(actions) ? actions() : actions}
          </div>
        </div>

        {/* Search */}
        <SearchRow participant={participant} authUser={currentUser} />

        {!isParticipantOnboardingEnable(participant._event, 'experience') && !getHasSearchExperienceOrStartDate() &&
          <div className="mx-20">
            <Matchings
              context="noSearchAndNoExperience"
              participant={participant}
              highlightWords={highlightWords}
              authUser={currentUser}
            />
          </div>
        }
        {
          <div className={styles.bodyContainer}>
          {/* Resume */}
          <>
            {renderResume()}
          </>
        </div>}

        {/* Applications */}
        {!isEmpty(viewBy._currentOrganization) && interactionEnable &&
          (<Applications interaction={interactionEnable} offers={offers} currentUser={currentUser} appointment={interactionEnable._appointment} size="medium" />)}

        {!isEmpty(viewBy._currentOrganization) && interactionEnable
          && interactionEnable._appointment
          && currentUser?._id !== interactionEnable?._appointment?._organizationUser?._id
          && <ColleagueInCharge context={context} appointment={interactionEnable._appointment} />
        }
      </div>
    </Card>
  );
}

MediumParticipantCard.propTypes = {
  actions: node,
  areInteractionsClosed: bool,
  context: oneOf(CONTEXTS_ALL),
  currentUser: object,
  enableCoreTarget: bool,
  functions: object,
  hideCheck: bool,
  highlightWords: array,
  interactions: array,
  intl: object,
  isChecked: bool,
  isNew: bool,
  isVisited: bool,
  liveTag: string,
  offers: array,
  onCardToggle: func,
  onOpen: func,
  participant: object,
  skipAppointmentValidation: bool,
  viewBy: object,
}

export default injectIntl(MediumParticipantCard);
