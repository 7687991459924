import React, { useEffect, useState } from 'react';
import { array, func, object, bool } from 'prop-types';
import validator from 'validator';
import classNames from 'classnames';
import renderHTML from 'react-render-html';
import { Alert } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { PIPE_DRIVE, isRecruiterLimitExceeded } from '@/utils/pipeDrive';
import Avatar from '@/components/Avatar';
import InputMaterial from '@/components/Input/InputMaterial';
import Icon from '@/components/Icon';
import styles from './styles.less';

let mainNode = null;
let mounted = false;

const OrganizationUsers = ({
  isFetching = false,
  onCreateUser,
  onAddUserInExponent,
  error,
  users = [],
  exponent = {
    users: [],
  },
}) => {
  const t = useFormatMessage();
  const [errorMessage, setErrorMessage] = useState('');
  const [dropdownIsVisible, setDropdownIsVisible] = useState(false);
  const [value, setValue] = useState('');

  useEffect(() => {
    mainNode = null;
    mounted = true;
    document.addEventListener('click', handleDocumentClick, false);
    document.addEventListener('touchend', handleDocumentClick, false);

    return () => {
      mounted = false;
      document.removeEventListener('click', handleDocumentClick, false);
      document.removeEventListener('touchend', handleDocumentClick, false);
    }
  }, []);

  function handleDocumentClick (event) {
    if (mounted) {
      if (!mainNode.contains(event.target) && dropdownIsVisible) {
        setDropdownIsVisible(false);
      }
    }
  }

  const toggleDropdown = () => {
    setDropdownIsVisible(!dropdownIsVisible)
  };


  const handleOnChangeInput = (event) => {
    const value = event.target.value.toLowerCase();

    setValue(value);
    setErrorMessage('');
    setDropdownIsVisible(true);
  };


  const handleOnAddUser = (user) => () => {
    setDropdownIsVisible(false);

    const callback = () => {};
    // Add the userId into the state

    let item = { medium: 'phone' };

    if (exponent._event.format === 'physical') {
      const location = exponent._event.location ? exponent._event.location.name : '';
      item = { medium: 'physical', location, settings: { medium: 'physical', location } };
    }

    return onAddUserInExponent([
      ...users,
      { _user: user, ...item },
    ], user, callback);
  };


  const handleCreateUser = () => {
    if (!validator.isEmail(value)) {
      setErrorMessage(t({ id: 'mail.error' }));

      return false;
    }

    const exist = users.findIndex((item) => item._user && item._user.username === value);

    if (exist > -1) {
      setErrorMessage(t({ id: 'preparation.team.error.recruiter.already.added' }));

      return false;
    }
    
    setValue('');
    setDropdownIsVisible(false);

    const callback = () => {};

    return onCreateUser({
      username: value,
    }, callback);
  };



  const highlightText = (text, value) => {
    if (value.trim().length > 0) {
      return renderHTML(text.replace(new RegExp(value, 'ig'), `<em>${value}</em>`));
    }

    return text;
  };


  const returnUsersInTheOrganization = (value) => {
    if (users.length === 0) {
      return (<li className={styles.noResult}>{t({ id: 'noResult' })}</li>);
    }

    return users.map((user, index) => (
      <li
        key={index}
        className={styles.userItem}
        onClick={handleOnAddUser(user)}
        role="button"
      >
        <Avatar src={user.pictureUrl} size={28} user={user} />
        <strong className={styles.userLabel}>{highlightText(user.fullName, value)} <small>{user.fullName !== user.username ? highlightText(user.username, value) : ''}</small></strong>
        <a role="button" tabIndex={0}>{t({ id: 'add' })}</a>
      </li>)
    );
  };

  const renderWarningRecruiterLimitExceeded = () => {
    if (!isRecruiterLimitExceeded(exponent, users.length + 1)) return null;

    return exponent._organization.status === PIPE_DRIVE.status.client ?
      <div className="mb-20">
        <Alert color="warning">
          {t({ id: 'event.owner.exponents.table.actions.popover.limit.users.client' })}
        </Alert>
      </div>
      :
      <div className="mb-20">
        <Alert color="warning">{t({ id: 'event.owner.exponents.table.actions.popover.limit.users' })}</Alert>
      </div>;
  };


    return (
      <div className={styles.container} ref={(node) => { mainNode = node; }}>
        {/* Input for search user in organization */}
        <div className={styles.inputContainer}>
          <InputMaterial
            autoComplete="new-password"
            addOnIcon="createUser"
            placeholder={t({ id: 'preparation.team.createUserHelp' })}
            type="text"
            value={value}
            onChange={handleOnChangeInput}
            onFocus={toggleDropdown}
            withInputErrorStyle={false}
            disabled={!!renderWarningRecruiterLimitExceeded() || isFetching}
          />

          {error && error.message ? (
            <div className={styles.error}>{error.message}</div>
          ) : ''}
        </div>
        {renderWarningRecruiterLimitExceeded()}
        {/* List all users */}
        {!isFetching && (
          <div className={classNames(styles.results, dropdownIsVisible ? styles.visible : null)}>
            {value !== '' && (
              <div className={styles.newUser} onClick={handleCreateUser} role="button" tabIndex={0}>
                <div className={styles.userItem}>
                  <span className={styles.createUser}><Icon name="member" /></span>
                  <strong>{t({ id: 'add' })} {value}</strong>
                  {errorMessage !== '' && (
                    <div className={styles.error}>{errorMessage}</div>
                  )}
                </div>
              </div>
            )}

            <ul className={styles.checkboxList}>
              {returnUsersInTheOrganization(value)}
            </ul>
          </div>
        )}
      </div>
    );
}

OrganizationUsers.propTypes = {
  users: array,
  error: object,
  exponent: object,
  onAddUserInExponent: func,
  onCreateUser: func,
  isFetching: bool,
}

export default OrganizationUsers;
