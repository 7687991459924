import React from 'react';
import { string, array, object, bool } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Toggle } from '@seekube-tech/ui-kit';
import { filter, reduce, size } from 'lodash';
import moment from 'moment';
import {
  OrganizationColumn
} from '@/scenes/Event/scenes/Owner/scenes/OnBoarding/containers/Dashboard/components/OrganizationColumn';
import { ValueWithWarning } from '@/components/ValueWithWarning';
import ActionColumn
  from '@/scenes/Event/scenes/Owner/scenes/OnBoarding/containers/Dashboard/components/ActionColumn';
import {
  StatusOfPreparationAccessor
} from '@/scenes/Event/scenes/Owner/scenes/OnBoarding/containers/Dashboard/components/StatusOfPreparationAccessor';
import {
  StandStatusAccessor
} from '@/scenes/Event/scenes/Owner/scenes/OnBoarding/containers/Dashboard/components/StandStatusAccessor';
import {
  KeyMomentFormatsAccessor
} from '@/scenes/Event/scenes/Owner/scenes/OnBoarding/containers/Dashboard/components/KeyMomentFormatsAccessor';
import { EVENT_FORMAT } from '@/utils/constants';
import { story } from '@/utils/manageVisibilityStory';
import ActionColumnV2 from './ActionColumnV2';

const ColumnsUi = (byOrganization, eventSlug, corners, locale, partner, isHybrid = false, view, authToken) => (
  [
    {
      id: 'exponent',
      disableSortBy: true,
      Header: <FormattedMessage
        id="event.owner.dashboard.organizations.exponent" />,
      accessor: (record => {
        const isChecked = (isHybrid && record.keyMomentFormats.includes(view)) ?
          record.tool._keyMomentLinks.find((keyMomentLink) => keyMomentLink._keyMoment.format === view).isReady
          : record.tool.isReady;

        return (
          <div className="flex items-center">
            <div style={{ marginRight: '18px' }} role="button" tabIndex={0}
              onClick={record.actions.preventExpand}>
              <Toggle
                size="small"
                checked={isChecked}
                onClick={() => { record.actions.onToggle(record.tool); }}
              />
            </div>
            <OrganizationColumn
              context="corners"
              organization={record._organization}
              byOrganization={byOrganization}
              previewUrl={`/${eventSlug}/owner/stands/${record.key}/preview`}
              corners={record.cornersPopulate}
              cornersList={corners}
              isPartner={record?.tool?.isPartner}
              partner={partner}
              organizationProfile={record.organizationProfile}
            />
          </div>
        );
      })
    },
    {
      id: 'format',
      Header: <FormattedMessage id="mode" />,
      className: 'text-center',
      accessor: (record) => <KeyMomentFormatsAccessor {...record} />,
      disableSortBy: true
    },

    {
      id: 'stand',
      Header: <FormattedMessage id="event.owner.dashboard.organizations.stand" />,
      className: 'text-center',
      accessor: (record) => <StandStatusAccessor {...record} />,
      disableSortBy: true
    },

    {
      id: 'offers',
      Header: <FormattedMessage id="event.owner.dashboard.organizations.offers" />,
      className: 'text-center',
      accessor: ({ tool }) => {
        const offersPublishedByExponent = reduce(tool.users,
          (acc, curr) => acc + curr.countOffersPublished, 0);
        return <ValueWithWarning value={offersPublishedByExponent} />
      },
      disableSortBy: true
    },

    {
      id: 'countRecruiter',
      Header: <FormattedMessage id="event.owner.dashboard.organizations.countRecruiter" />,
      SubHeaders: [
        'Total',
        <FormattedMessage id="event.owner.dashboard.organizations.countRecruiterWithOffer" />,
        <FormattedMessage id="event.owner.dashboard.organizations.countRecruiterWithSlots" />
      ],
      accessor: ({ tool, tool: { users } }) => {
        const getStat = (user, stat) => ['physical', 'virtual'].includes(view) ? user?.[view]?.[stat] : user[stat];

        const countUsers = size(users);
        const countOffersPublished = size(filter(users, (user) => getStat(user, 'countOffersPublished') > 0));
        const countTotalSlots = size(filter(users, (user) => getStat(user, 'countSlots') + getStat(user, 'countInformalSlots') > 0))

        return (
          [countUsers, countOffersPublished, countTotalSlots].map(
            (statNumber) => <ValueWithWarning value={statNumber} />
          )
        );
      },
      disableSortBy: true,
    },

    {
      id: 'countConferences',
      Header: <FormattedMessage id="event.owner.dashboard.organizations.lives" />,
      className: 'text-center',
      accessor: ({ tool }) => <ValueWithWarning value={tool.countConferences} />,
      disableSortBy: true
    },
    {
      id: 'createdAt',
      Header: <FormattedMessage id="event.owner.dashboard.organizations.createdAt" />,
      accessor: ({ tool }) => (
        <span title={moment(tool.createdAt).format('DD/MM/YYYY')}>
          {moment(tool.createdAt).format('DD/MM/YYYY')}
        </span>
      ),
      disableSortBy: true
    },
    {
      id: 'actions',
      Header: '',
      disableSortBy: true,
      accessor: (record) => (
        <span role="button" tabIndex={0} onClick={record.actions.preventExpand}>
          {/* TODO: add flag here */}
          {story.orgaAccess.isVisible ?
            <ActionColumnV2
              previewUrl={`/${eventSlug}/owner/stands/${record.key}/preview`}
              context="owner_companies"
              handleExponentActions={record.actions.handleExponentActions}
              onToggleCorner={record.actions.onToggleCorner}
              onTogglePartner={record.actions.onTogglePartner}
              record={record}
              corners={corners || []}
              partner={partner}
              removeExponent={() => record.actions.removeExponent(record.tool)}
              openAvatarModal={() => record.actions.openAvatarModal(record.tool)}
              byOrganization={byOrganization}
              locale={locale}
              preventExpand={record.actions.preventExpand}
              isHybrid={isHybrid}
              authToken={authToken}
            />
            :
            <ActionColumn
              previewUrl={`/${eventSlug}/owner/stands/${record.key}/preview`}
              context="owner_companies"
              handleExponentActions={record.actions.handleExponentActions}
              onToggleCorner={record.actions.onToggleCorner}
              onTogglePartner={record.actions.onTogglePartner}
              record={record}
              corners={corners || []}
              partner={partner}
              removeExponent={() => record.actions.removeExponent(record.tool)}
              openAvatarModal={() => record.actions.openAvatarModal(record.tool)}
              byOrganization={byOrganization}
              locale={locale}
              preventExpand={record.actions.preventExpand}
              isHybrid={isHybrid}
            />
          }

        </span>
      ),
    },

    {
      id: 'isReady',
      Header: <FormattedMessage id="event.owner.dashboard.organizations.table.header.isReady" />,
      SubHeaders: [
        <FormattedMessage id="physical" />,
        <FormattedMessage id="virtual" />
      ],
      accessor: (record) => [
        <StatusOfPreparationAccessor {...record} format={EVENT_FORMAT.PHYSICAL} />,
        <StatusOfPreparationAccessor {...record} format={EVENT_FORMAT.VIRTUAL} />,
      ],
      disableSortBy: true
    },
  ]
);

ColumnsUi.propTypes = {
  tool: object,
  createdAt: string,
  _organization: object,
  users: array,
  locale: string,
  event: object,
  isHybrid: bool,
};

export default ColumnsUi;
