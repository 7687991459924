import { parseJSON, checkStatus } from '@/utils/request';

const getLivesStats = ({ eventId }) => {
  const requestUrl = `${process.env.FRONT_GO_API_URL}/stats/event/${eventId}/lives?apiKey=41a1289e-fee1-430e-a849-5b2b7ed23f1a`;

  return fetch(requestUrl).then(checkStatus)
    .then(parseJSON);
};

export { getLivesStats };
