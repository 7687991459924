import React from 'react';
import { compose } from 'redux';
import { Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Button, IconChevronRight } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { useHistory } from 'react-router';
import {
  FooterNav
} from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/components/FooterNav/FooterNav';
import { toJS } from '@/utils';
import { userActions } from '@/store/user';
import Wrapper from '@/components/Wrapper';
import Presentation from '@/scenes/Auth/scenes/CandidateScenes/Profile/Presentation';
import Search from '@/scenes/Auth/scenes/CandidateScenes/Profile/Search';
import { getAuthUser } from '@/store/auth/selectors';

const Profile = (props) => {
  const t = useFormatMessage();
  const authUser = useSelector(getAuthUser)?.toJS();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleOnSubmit = (e) => {
    e.preventDefault();

    return props.form.validateFieldsAndScroll({
      scroll: { offsetTop: document.getElementById('headerWrapper').offsetHeight + 3 } },
      (err, values) => {
      if (!err) {
        const userParams = { ...values, isActive: true };

        dispatch(userActions.patchUserBeforeSignUp({
          userId: authUser._id,
          userParams,
          callback: () => {
           history.push('/events');
          }
        }));
      }
    });
  }

  if (!authUser) return null;

  return (
    <Wrapper size="large">
      <Form onSubmit={handleOnSubmit}>
        <Presentation user={authUser} {...props} />
        <Search user={authUser} {...props} />

        <FooterNav>
          <div />
          <Button type="submit">
            {t({ id: 'complete.my.registration' })}
          </Button>
        </FooterNav>
      </Form>
    </Wrapper>
  )
}
const withForm = Form.create();

export default compose(
  withForm,
)(toJS(Profile));
