import React from 'react';
import styles from './styles.less';
import { Button, IconChevronRight, IconChevronLeft } from '@seekube-tech/ui-kit';


export const FooterNav = ({ children }) => {
  return (
    <div className={styles.footerNavContainer}>
      <div className={styles.footerNavContent}>
        {
          children
        }

      </div>
    </div>
  )
}