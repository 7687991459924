import { object, func } from 'prop-types';
import { map } from 'lodash';
import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { FormattedMessage } from 'react-intl';
import { Tag, Tooltip } from '@seekube-tech/ui-kit';
import styles from '@/components/InteractionCard/styles.less';
import { NavLink } from 'react-router-dom';

const OffersInQuestion = ({ interaction, handleOpenOffer, event }) => {
  const t = useFormatMessage();

  const getLabel = (type) => {

    if (type === 'proposition') {
      return <FormattedMessage id="event.candidate.appointments.card.sourcing"/>
    }

    return <FormattedMessage id="event.candidate.appointments.card.apply" />
  }


  return (
    <div className={styles.offers}>
      <div className={styles.label}>
        {getLabel(interaction.type)}
      </div>

      <div className={styles.list}>
        {map(interaction._offers, (item, index) => {
          return (
            <div key={`offer_${index + 1}`}>
              <NavLink to={`/${event.slug}/candidate/jobdating/jobs/${item._id}`}>
                <Tooltip title={item.title} placement="top">
                  <Tag variant="outline" color="grey" onClick={() => handleOpenOffer(item)}>
                    {item.title.length > 30 ? `${item.title.slice(0, 29)}...` : item.title}
                  </Tag>
                </Tooltip>

              </NavLink>
            </div>
          )})}


        {interaction.type === 'informal1to1' && (
          <Tag>
            {t({ id: 'chat.oneToOne.pl'}, { count: 1 })}
          </Tag>
        )}
      </div>
    </div>
  )
}


OffersInQuestion.propTypes = {
  interaction: object,
  handleOpenOffer: func,
}

export { OffersInQuestion }