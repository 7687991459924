import React from 'react';
import { difference, keys } from 'lodash';
import { array, object, string } from 'prop-types';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@seekube-tech/ui';
import { IconLock, IconLives, IconClock, IconCalendar, IconCheckCircle2, IconEyeOff  } from '@seekube-tech/ui-kit';
import { Sun, Heart, Zap, Star } from 'react-feather';
import { Tag } from '@seekube-tech/ui-kit';
import Icon from '@/components/Icon';
import messages from '../messages';
import styles from './styles.less';
import { EVENT_STATUS, USERS_TYPE } from '@/utils/constants';
import { getLocale } from '@/utils/localStorage';

const ICONS = {
  private: 'password-lock',
  inProgress: 'live-event',
  preregistered: 'clock',
  auto: 'calendar',
  public: '',
  current: '',
  participate: 'ok-circle',
  draft: 'icon-hide',
};

const COLORS = {
  private: 'neutral',
  draft: 'neutral',
  preregistered: 'neutral',
  inProgress: 'success',
  auto: 'primary',
  public: 'neutral',
  current: 'neutral',
  participate: 'success',
}

export function JobdatingStatus({ className, criteria, dateString, role, event }) {
  const { palette } = useTheme();
  const locale = getLocale();


  function getIcon(name) {
    switch (name) {
      case ICONS.private:
        return <IconLock size={16} />

      case ICONS.inProgress:
        return <IconLives size={16} />

      case ICONS.preregistered:
        return <IconClock size={16} />

      case ICONS.auto:
        return <IconCalendar size={16} />

      case ICONS.public:
        return null

      case ICONS.current:
        return null

      case ICONS.participate:
        return <IconCheckCircle2 size={16} />

      case ICONS.draft:
        return <IconEyeOff size={16} />

      default:
        return null;
    }
  }

  function getTags() {
    let badges = [];

    if (difference([EVENT_STATUS.IN_PROGRESS], criteria).length === 0) { badges = [...badges, 'inProgress']; }
    if (difference(['preregistered'], criteria).length === 0) { badges = [...badges, 'preregistered']; }
    if (difference(['draft'], criteria).length === 0
      && criteria && criteria[0] !== 'preregistration'
      && role !== 'recruiter') {
      badges = [...badges, 'draft'];
    }
    if (difference(['private'], criteria).length === 0) { badges = [...badges, 'private']; }
    if (difference(['participate'], criteria).length === 0) { badges = [...badges, 'participate']; }
    if (difference(['auto'], criteria).length === 0) { badges = [...badges, 'auto']; }

    return badges;
  }

  function getPartnerColor() {
    return palette.common[event.modules.partner.color]?.[500];
  }

  function getPartnerLabel() {
    return event.modules.partner[`label_${locale}`];
  }

  function getPartnerIcon(icon) {
    switch (icon) {
      case 'sun':
        return <Sun className={styles.partnerIcon} />

      case 'heart':
        return <Heart className={styles.partnerIcon} />

      case 'star':
        return <Star className={styles.partnerIcon} />

      case 'flash':
        return <Zap className={styles.partnerIcon} />

      default:
        return null;
    }
  }

  return (
    <div className={classnames(styles.jobdatingStatusContainer, className || '')}>
      {keys(USERS_TYPE).includes(role) && (
        <span className={styles.date} key="footerDate">
          <Icon name="calendar" />{dateString}
        </span>
      )}

      {getTags()
        .map((tag, index) => (
          <Tag
            key={`${tag}${index.toString()}`}
            size='medium'
            color={COLORS[tag]}
            variant='tonal'
            icon={getIcon(ICONS[tag])}
          >
            <FormattedMessage {...messages[tag]} />
          </Tag>
        ))}

      {event?.isPartner && event?.modules?.partner?.enable && (
        <span className={classnames(styles.statusContainer, styles.partnerContainer)}>
          <div
            className={styles.partnerBadge}
            style={{ backgroundColor: getPartnerColor() }}
          >
            {getPartnerIcon(event.modules.partner.icon)}
          </div>

          {getPartnerLabel()}
        </span>
      )}
    </div>
  );
}

JobdatingStatus.propTypes = {
  className: string,
  dateString: object,
  role: string,
  criteria: array,
  event: object,
  authUser: object,
};
