import React from 'react';
import { object, func } from 'prop-types';
import classnames from 'classnames';
import { isEmpty } from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { createStructuredSelector } from 'reselect';
import { toJS } from '@/utils';

// Store
import { authSelectors, authActions } from '@/store/auth';

// Styles & Translations
import styles from './styles.less';

/**
 * NavConnectedAs
 */
class NavConnectedAs extends React.PureComponent { // eslint-disable-line
  static propTypes = {
    authUserConnectedAs: object,
    authUserNotConnectedAs: object,
    switchExit: func,
  };

  /**
   * Render
   */
  render() {
    const {
      props: { authUserConnectedAs, authUserNotConnectedAs, switchExit },
    } = this;

    if (
      isEmpty(authUserConnectedAs)
      || isEmpty(authUserNotConnectedAs)
      || !authUserNotConnectedAs.isAdmin
    ) {
      return null;
    }

    return (
      <div className={styles.navContainer}>
        <div className={classnames(styles.nav, styles.navConnectedAs)}>
          <span>Vous êtes connectés en tant que : {authUserConnectedAs.username} ({authUserConnectedAs._id})</span>
          <a
            role="button"
            tabIndex={0}
            onClick={() => switchExit()}
          >
            Quitter le switch
          </a>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  authUserConnectedAs: authSelectors.getAuthUserConnectedAs,
  authUserNotConnectedAs: authSelectors.getAuthUserNotConnectedAs,
});

const mapDispatchToProps = (dispatch) => ({
  switchExit() {
    dispatch(authActions.switchExit());
    dispatch(push(localStorage.getItem('switch_quit_url') || '/events'));
    localStorage.setItem('switch_quit_url', '');
    window.location.reload();
  },
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(toJS(NavConnectedAs));
