import React, { useState } from 'react';
import { object, func } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { get, isEmpty } from 'lodash';
import mime from 'mime-types';
import uuidv1 from 'uuid/v1';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useFormatMessage } from 'react-intl-hooks';
import { sectorSelectors } from '@/store/sector';
import { notificationActions } from '@/store/notification';

import { organizationActions } from '@/store/organization';

import PickOrganization
  from '../../../../../../../../../Event/scenes/Owner/scenes/OnBoarding/containers/ExponentModal/components/PickOrganization';
import messages from '../../../messages';
import standStyles from '../../../../../../../../../Event/scenes/Recruiter/scenes/Preparation/scenes/Stand/styles.less';
import { Cover } from '../../../../../../../../../Event/scenes/Recruiter/scenes/Preparation/scenes/Stand/components/StallForm/components/StallFormItems/components/Cover';
import { PictureUrl } from '../../../../../../../../../Event/scenes/Recruiter/scenes/Preparation/scenes/Stand/components/StallForm/components/StallFormItems/components/PictureUrl';
import ExponentModal from '../../../../../../../../../Event/scenes/Owner/scenes/OnBoarding/containers/ExponentModal';
import styles from './styles.less';
import { Button, IconPlusCircle } from '@seekube-tech/ui-kit';
import { If } from '@/components/If';

const Organizer = ({
  form,
  conference,
  setConference,
  sendNotification,
  handleSelect,
  patchOrganization,
}) => {
  const t = useFormatMessage();

  const [organizationSelected, setOrganizationSelected] = useState(get(conference, '_organization'));
  const [isCreateOrgaVisible, setIsCreateOrgaVisible] = useState(false);
  const [orgaCreated, setOrgaCreated] = useState(false);
  const [keywords, setKeywords] = useState(get(conference, '_organization.name') || '');

  const generateFileName = (file) => {
    // need to rename file with random name provided by uuid

    if (!file) {
      sendNotification({
        message: t({ id: 'event.recruiter.preparation.stand.notification.bad.format' }),
        kind: 'error',
        style: {},
      });

      throw new Error('Invalid File');
    }

    const ext = file.name ? file.name.substr(file.name.lastIndexOf('.') + 1) : mime.extension(file.type);

    const fileName = `${uuidv1()}.${ext}`;

    try {
      return new File([file], fileName, { type: file.type });
    } catch (err) {
      return file;
    }
  };

  const handleOnDrop = ({ cover, pictureUrl }) => {
    const orgaUpdated = orgaCreated || organizationSelected;

    if (cover) {
      setConference({...conference, cover, _organization: orgaUpdated})
    }

    if (pictureUrl) { setConference({...conference, pictureUrl, _organization: orgaUpdated}); }
  };


  return (
    <>
      <FormattedMessage {...messages.organizerTitle} tagName="h1" />

      <p className={styles.alertInfo}><FormattedMessage {...messages.tips1} /></p>
      <div className={styles.organizer}>
        <ExponentModal
          isOpen={isCreateOrgaVisible}
          onCancel={() => setIsCreateOrgaVisible(!isCreateOrgaVisible)}
          onOk={(orga) => {
            setOrganizationSelected(orga);
            setIsCreateOrgaVisible(false);
            handleSelect(orga);
          }}
          context="postOrganization"
        />
        <PickOrganization
          onViewChange={() => { }}
          onSelect={(orga) => { setOrganizationSelected(orga); handleSelect(orga); }}
          onCreateOrganization={() => { }}
          messageError={' '}
          onSelectCopyNameInInput
          nameToCopy={get(organizationSelected, 'name')}
          organizationSelected={organizationSelected}
          keywords={keywords}
          setKeywords={setKeywords}
        />
        <If condition={isEmpty(keywords) || organizationSelected?.name !== keywords}>
          <Button size='small' imageComponentLeft={<IconPlusCircle size={16} />} color='primary' variant='tonal' onClick={() => setIsCreateOrgaVisible(true)}>
            <FormattedMessage {...messages.organizerNew} />
          </Button>
        </If>
        {
          isEmpty(organizationSelected) ?
            null
            :
            <div className={classnames(standStyles.fieldContainer, !isEmpty(get(organizationSelected, 'profile.pictureUrl')) ? standStyles.withImage : '')}>
              <Cover
                form={form}
                {...form}
                required={false}
                shouldCloseModalAfterSubmitting
                initialValue={organizationSelected}
                organization={organizationSelected}
                pictureUrl={conference.pictureUrl || get(organizationSelected, 'profile.pictureUrl')}
                imageUrl={typeof conference.cover === 'string' ? conference.cover : get(organizationSelected, 'profile.cover')}
                generateFileName={generateFileName}
                handleOnDrop={handleOnDrop}
              />
              <PictureUrl {...form} initialValue={conference.pictureUrl || get(organizationSelected, 'profile.pictureUrl')} />
            </div>
        }
      </div>
    </>
  );
}

Organizer.propTypes = {
  form: object,
  organization: object,
  conference: object,
  setConference: func,
  updateOrg: func,
  sendNotification: func,
  handleSelect: func,
  patchOrganization: func,
  postOrganization: func,
};

const mapStateToProps = createStructuredSelector({
  sectorsOptions: sectorSelectors.getAllSectors,
});

const mapDispatchToProps = {
  sendNotification: notificationActions.sendNotification,
  postOrganization: organizationActions.postOrganization,
  patchOrganization: organizationActions.patchOrganization,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);


export default compose(
  withConnect,
)(Organizer);
