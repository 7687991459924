export default (
  users = [],
  {
    context,
    actions,
    authUser,
  }) => (
  users.map(
    (user, key) => ({
      key,
      user,
      authUser,
      username: user.username,
      role: user.role,
      mail: user.username,
      lastLogin: user.lastView,
      organization: user._currentOrganization ? user._currentOrganization.name : '',
      recruiter: user.fullName,
      title: user.title,
      stats: user.stats,
      context,
      actions,
    })
  ));
