import React from 'react';
import { any, object, string, func, bool, number } from 'prop-types';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import { keys, isEmpty, size, pickBy } from 'lodash';
import { useFormatMessage } from 'react-intl-hooks';
import { Select as SelectAntd } from 'antd';
import { Element } from 'react-scroll';
import Icon from '@/components/Icon';
import Select from '@/components/Form/Select';
import BlankState from '@/components/BlankState';
import Appointment from '../Appointment';
import DateAgenda from '../Date';
import Time from '../Time';
import styles from './styles.less';
import { getId } from '@/utils/global';
import LivePlanning from '../LivePlanning';
import {
  ShowMoreButton
} from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Exponents/scenes/Exponent/components/ShowMoreButton';

const { Option } = SelectAntd;


const Timeline = ({
  intl,
  event,
  items,
  countItems,
  push,
  closeCalendar,
  handleOnChangeAppointment,
  handleOnCancelAppointment,
  planningInfiniteQuery,
  onDownload,
  handleBlankStateAction,
  authUser,
  currentExponent,
  currentOption,
  planningUser,
  activeDay,
  getParticipant,
  onToggleArchive,
  archive,
  jobfairsColor,
}) => {
  const t = useFormatMessage();
  /**
   * Return Time Element and all items
   *
   * @param {object} dateObject
   * @param {string} dateString
   * @param {object} timeObject
   * @param {string} timeString
   * @param {number} index
   */
  const returnTimeElement = (dateObject, dateString, timeObject, timeString, index) => (
    <div key={`time${index}`}>
      <Element name={timeString} key={timeString}>
        <div>{keys(timeObject).map((itemIndex, index) => (
          <div className={styles.appointmentContainer} key={`item${index}`}>
            <Time date={dateString} time={timeString} showTime={parseInt(itemIndex, 10) === 0} now={moment()} />
            {timeObject[itemIndex].type === 'conference' ?
              <LivePlanning conference={timeObject[itemIndex].elem} jobfairsColor={jobfairsColor} />
              :
              <Appointment
                key={itemIndex}
                appointment={timeObject[itemIndex].elem}
                modifyAppointment={handleOnChangeAppointment}
                cancelAppointment={handleOnCancelAppointment}
                authUser={authUser}
                currentExponent={currentExponent}
                currentOption={currentOption}
                onDownload={onDownload}
                getParticipant={getParticipant}
                jobfairsColor={jobfairsColor}
              />
            }
          </div>
          ))}
        </div>
      </Element>
    </div>
    );

  /**
   * Return Date Element
   *
   * @param {object} dateObject
   * @param {string} dateString
   * @param {boolean} isEmptyDateObject
   * @param {number} index
   */
  const returnDateElement = (dateObject, dateString, isEmptyDateObject, index) => {
    moment.locale(intl.locale);
    return (
      <div key={`date${index}`} >
        {!isEmptyDateObject ? (
          <div style={{ display: 'flex' }} id={`date${dateString}`}>
            <DateAgenda
              date={dateString}
              activeDay={activeDay}
            />
          </div>
        ) : ''}

        <Element
          key={dateString}
          className={styles.dateElement}
        >
          {
            keys(dateObject)
              .sort((a, b) => a - b)
              .map((timeString, index) => returnTimeElement(
                dateObject,
                dateString,
                dateObject[timeString],
                timeString,
                index,
              ))
          }
        </Element>
      </div>
    );
  };

  /**
   * HandleAction
   *
   * @description
   * If the recruiter doesn't have appointment on the event, redirect to applications
   */
  const handleAction = () => {
    push(`/${event.slug}/recruiter/jobdating/sourcing`);
    closeCalendar();

    return handleBlankStateAction();
  };

  /**
   * Render elements in the timeline
   *
   * For Each items for a date, create Element
   */
  const renderTimelineItems = (timelineItems) => keys(timelineItems)
    .map((dateString, index) => returnDateElement(
        timelineItems[dateString],
        dateString,
        isEmpty(timelineItems[dateString]),
        index,
    ));

  const itemsCleaned = pickBy(items, size);

  return (
    <div style={{ paddingBottom: '20px' }}>
      <div className={styles.selectWrapper}>
        <Select
          value={archive ? 'archive' : 'default'}
          suffixIcon={<Icon name="chevron" />}
          onSelect={onToggleArchive}
        >
          <Option value="default">
            {t({ id: 'planning.futureInterviews' })}
          </Option>
          <Option value="archive">
            {t({ id: 'planning.pastInterviews' })}
          </Option>
        </Select>
      </div>

      {countItems === 0 ? (
        <div className={styles.emptyBlockStatement}>
          <BlankState
            className={styles.blankState}
            title={
              !archive ?
              (getId(planningUser) === getId(authUser) || !planningUser ?
                t({ id: 'planning.blankState.title' })
                : t({ id: 'planning.blankState.recruiter.no.rdv.title' }, { name: planningUser.fullName }))
                : t({ id: 'planning.blankState.titleArchive' })
            }
            icon="blank-state-cactus"
            handleAction={handleAction}
          />
        </div>
        ) : renderTimelineItems(itemsCleaned)}

      <ShowMoreButton entityInfiniteQuery={planningInfiniteQuery} />
    </div>
  );
};

Timeline.propTypes = {
  intl: object,
  jobfairsColor: object,
  planningUser: object,
  event: any,
  items: object,
  countItems: number,
  push: func,
  closeCalendar: func,
  handleOnChangeAppointment: func,
  handleOnCancelAppointment: func,
  planningInfiniteQuery: func,
  onDownload: func,
  handleBlankStateAction: func,
  authUser: object,
  currentExponent: object,
  currentOption: string,
  activeDay: string,
  getParticipant: func,
  onToggleArchive: func,
  archive: bool,
};

export default injectIntl(Timeline);
