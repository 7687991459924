import React from 'react';
import { func, object } from 'prop-types';
import { injectIntl } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import { useFormatMessage } from 'react-intl-hooks';
import { toJS } from '@/utils';
import { authActions } from '@/store/auth';
import styles from './styles.less';
import { HeaderMobile, IconInfo, IconUser, Logo } from '@seekube-tech/ui-kit';
import { push } from 'connected-react-router';
import HybridTagMenu from '../Header/components/components/EventInfos/HybridTagMenu';
import { EVENT_FORMAT } from '@/utils/constants';
import { getCurrentEvent } from '@/store/event/selectors';
import { getAuthUser } from '@/store/auth/selectors';
import { getCurrentParticipant } from '@/store/participant/selectors';

const MobileHeader = ({
  toggleSettings,
  push,
}) => {
  const t = useFormatMessage();
  const authUser = useSelector(getAuthUser)?.toJS();
  const event = useSelector(getCurrentEvent)?.toJS();
  const participant = useSelector(getCurrentParticipant)?.toJS();

  const promptSettings = (defaultView) => {
    document.getElementById('bigModalMainPanel').scrollIntoView(true);
    toggleSettings(defaultView);
  }

  const leftInfo = [{
    componentImage: event && event.format === EVENT_FORMAT.HYBRID ? <Logo showFull size="19" /> : <Logo showFull size="29" />,
    onClick: () => push('/')
  }]

  const rightInfo = [{
    componentImage: <IconInfo size="24" />,
    text: "Centre d'aide",
    link: 'https://intercom.help/seekube/fr/collections/2627090-je-suis-candidat-e'
  },{
    componentImage: <IconUser size="24" />,
    text: t({id: 'header.userDropdown.profil'}),
    onClick: () => promptSettings('profile')
  }]

  const subLineComponent = event && event.format === EVENT_FORMAT.HYBRID && participant ? <HybridTagMenu event={event} authUser={authUser} participant={participant} /> : undefined

  return (
    <div className={styles.menu}>
      <HeaderMobile leftInfo={leftInfo} rightInfo={rightInfo} subLineComponent={subLineComponent} />
    </div>
  );
}

MobileHeader.propTypes = {
  push: func,
  toggleSettings: func,
  event: object,
};

const mapStateToProps = createStructuredSelector({
});

const mapDispatchToProps = {
  toggleSettings: authActions.toggleSettings,
  push
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  injectIntl,
)(toJS(MobileHeader));
