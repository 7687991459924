import React, { memo } from 'react';
import moment from 'moment';
import { object, func } from 'prop-types';

// Components
import { Tag } from '@seekube-tech/ui-kit';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router';
import Avatar from '@/components/Avatar';

// Styles
import styles from '../../../styles.less';
import { getLocaleName, useUserAclHandler } from '@/store/role/helpers';
import { story } from '@/utils/manageVisibilityStory';

const CONTEXT_URL = { ownerExponents: 'owner/exponents', ownerSettings: 'owner/settings', preparationTeam: 'preparation/team' };

const MemberColumn = memo(
  ({ exponent, record, onActionClick = () => { }, context = 'default', name = '', authUser, organization }) => {
    const { pathname } = useLocation();

    if (context === 'additionalRow') {
      return (
        <div id={`user${record.id}`} className="memberContainer">
          {name}
        </div>
      )
    }
    const isOwnerExponentsContext = pathname.includes(CONTEXT_URL.ownerExponents);

    const isPreparationTeamContext = pathname.includes(CONTEXT_URL.preparationTeam);
    const org = organization || authUser?._currentOrganization;
    const { role } = useUserAclHandler(record, org);

    const isRoleVisible = org?.isClient && !isOwnerExponentsContext && !isPreparationTeamContext && role;

    return (
      <div id={`user${record.id}`} className="memberContainer">
        <a className={styles.memberContainer}>
          <Avatar
            className={styles.avatarInline}
            type="rounded"
            size={40}
            user={record.isActive ? record : 'default'}
            src={record.pictureUrl ? record.pictureUrl : null}
          />
          <div>
            {record.isActive ? (
              <>
                <span tabIndex={0} role="button" className={styles.exponentName} onClick={() => onActionClick('recruiterClick')}>
                  {record.firstName ?
                    <>
                      {`${record.firstName} ${record.lastName}`} {isRoleVisible && <Tag className="ml-16" variant="tonal" color="neutral">{getLocaleName(role)}</Tag>}
                    </>
                    : record.email}
                  {story.orgaAccess.isVisible && exponent?._referentUser === record?._id && <Tag className='ml-16' variant="tonal" color="neutral"><FormattedMessage id="tag.referent" /></Tag>}
                </span>
                <span title={record.title} className={styles.exponentRole}>{record.title}</span>
              </>
            ) : (
              <>
                <span className={styles.exponentRole}>
                  <div className={styles.email}>
                    {record.fullName} {isRoleVisible && <Tag className="ml-16" variant="tonal" color="neutral">{getLocaleName(role)}</Tag>}
                  </div>
                  {record.email}
                  <FormattedMessage id="invited.at" /> {moment(record.invitedAt).format('DD/MM/YYYY')}
                </span>
              </>
            )}
          </div>
        </a>
      </div>
    )
  }
);

MemberColumn.propTypes = {
  record: object,
  onActionClick: func,
  exponent: object,
  authUser: object,
  organization: object,
  context: 'string',
  name: 'string',
};

MemberColumn.defaultPros = {
  record: {},
};

export { MemberColumn };
