import React from 'react';
import { object } from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { Button, H3, H4, Subtitle2 } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { isEmpty } from 'lodash';
import ImgCdn from '@/components/ImgCdn';
import {
  trackRecruiterClickedAccessSchoolEvents, trackRecruiterClickedDiscoverOurOffers,
  trackRecruiterClickedDiscoverSchoolEvents, trackRecruiterDownloadedSeekubeCalendar,
} from '@/utils/analytics';
import styles from './styles.less';
import { useGetJobdatingDiscoveryAccess } from '@/queries/users/useGetJobdatingDiscoveryAccess';

function JobdatingsDiscovery({ authUser }) {
  const t = useFormatMessage();
  const isClient = !isEmpty(authUser?._currentOrganization?.activePlans);

  const { data } = useGetJobdatingDiscoveryAccess();

  const button = (data?.hasAccess) ?
    <Button
      type="primary"
      onClick={() => {
        trackRecruiterClickedAccessSchoolEvents({ authUser });
        window.open('/seekalendar?page=1&limit=10&offset=0', '_blank');
      }}
    >
      {t({ id: 'events.discovery.card.seekalendar.cta' })}
    </Button>
    :
    <Button
      type="primary"
      onClick={() => {
        trackRecruiterClickedDiscoverSchoolEvents({ authUser })
        window.open('https://seekube.hellowork.com/recruteur/demande-de-rappel', '_blank');
      }}
    >
      {t({ id: 'non.clients.access.plans' })}
    </Button>;

  return (
    <div className={styles.jobdatingsDiscoveryContainer}>
      <H3 className="mb-40">{t({ id: 'events.discovery.title' })}</H3>

      <div className={styles.cardList}>
        <div className={classnames(styles.cardContainer)}>
          <div className={styles.cardLeft}>
            <ImgCdn
              className="image"
              operation="crop"
              size={300}
              src="https://cdn-app-assets.seekube.com/events-seekalendar.jpg"
              backgroundImageCss="linear-gradient(0deg, rgba(56, 80, 119, 0.3), rgba(56, 80, 119, 0.3))"
              withshadow
            />
          </div>

          <div className={styles.cardRight}>
            <div className={styles.cardTop}>
              <Link
                target="blank"
                tabIndex={0}
                to="/seekalendar"
                disabled={isEmpty(authUser?._currentOrganization?.activePlans)}
              >
                <H4>{t({ id: 'events.discovery.card.seekalendar.title' })}</H4>
              </Link>

              <div className={styles.description}>
                <Subtitle2>{t({ id: 'events.discovery.card.seekalendar.description' })}</Subtitle2>
              </div>
            </div>

            <div className={styles.cardBottom}>
              {button}
            </div>
          </div>
        </div>

        <div className={classnames(styles.cardContainer)}>
          <div className={styles.cardLeft}>
            <ImgCdn
              className="image"
              operation="crop"
              size={300}
              src="https:///cdn-app-assets.seekube.com/events-calendar-img.jpg"
              backgroundImageCss="linear-gradient(0deg, rgba(56, 80, 119, 0.3), rgba(56, 80, 119, 0.3))"
              withshadow
            />
          </div>

          <div className={styles.cardRight}>
            <div className={styles.cardTop}>
              <H4>{t({ id: 'events.discovery.card.seekube.calendar.title' })}</H4>

              <div className={styles.description}>
                <Subtitle2>{t({ id: 'events.discovery.card.seekube.calendar.description' })}</Subtitle2>
              </div>
            </div>

            <div className={styles.cardBottom}>

              {isClient &&
                <Button
                  onClick={() => {
                    trackRecruiterDownloadedSeekubeCalendar({ authUser });
                    window.open(t({ id: 'events.discovery.card.seekube.calendar.cta.url' }), '_blank')
                  }}
                >
                  {t({ id: 'events.discovery.card.seekube.calendar.cta' })}
                </Button>}

              {!isClient &&
                <Button
                  onClick={() => {
                    trackRecruiterClickedDiscoverOurOffers({ authUser });
                    window.open('https://seekube.hellowork.com/recruteur/demande-de-rappel', '_blank')
                  }}
                >
                  {t({ id: 'discover.our.offers' })}
                </Button>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

JobdatingsDiscovery.propTypes = {
  authUser: object,
};

export { JobdatingsDiscovery };
