import React from 'react';
import { object, func, array } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { toJS } from '@/utils';
import { injectIntl } from 'react-intl';

// Store
import { authSelectors } from '@/store/auth';
import { eventActions, eventSelectors } from '@/store/event';
import { criterionSelectors } from '@/store/criterion';
import { notificationActions } from '@/store/notification';

// Components
import Form from './components/Form';
import { PublishOnBtoC, PublishOnBtoB } from './components';

import messages from './messages';
import styles from '../../styles.less';
import EnableExportApi from './components/EnableExportApi';
import DisableMarketingSegments
  from '@/scenes/Event/scenes/Owner/scenes/Setting/scenes/Diffusion/components/DisableMarketingSegments';

const DiffusionScene = ({
  authUser,
  event,
  defaultCriteria,
  intl: { formatMessage },
  patchEvent,
  sendNotification,
}) => {
  const handleSubmit = (err, values) => {
    const result = values;
    result.participants = { ...event.participants, ...values.participants };

    if (!err) {
      patchEvent({
        event: result,
        notificationParams: {
          success: {
            message: `🙌 ${formatMessage(messages.editSuccess)}`,
            kind: 'info',
            style: {
              bottom: '5%',
              top: 'inherit',
            },
          },
        },
      });
    }
  };

  return (
    <div className={styles.formWrapper}>
      <Form
        initialValues={event}
        onSubmit={handleSubmit}
        sendNotification={sendNotification}
        formatMessage={formatMessage}
      />
      <PublishOnBtoC initialValues={event} onSubmit={handleSubmit} />
      <PublishOnBtoB
        initialValues={event}
        onSubmit={handleSubmit}
        defaultCriteria={defaultCriteria}
      />

      {authUser?.isAdmin && (<DisableMarketingSegments initialValues={event} onSubmit={handleSubmit} />)}
      {authUser?.isAdmin && event.type === 'seekube' && (<EnableExportApi initialValues={event} onSubmit={handleSubmit} />)}
    </div>
  );
};

DiffusionScene.propTypes = {
  authUser: object,
  event: object,
  patchEvent: func,
  intl: object,
  defaultCriteria: array,
  sendNotification: func,
};

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
  event: eventSelectors.getCurrentEvent,
  defaultCriteria: criterionSelectors.getDefaultCriteria,
});

const mapDispatchToProps = {
  patchEvent: eventActions.patchEvent,
  sendNotification: notificationActions.sendNotification,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  injectIntl,
  toJS,
)(DiffusionScene);
