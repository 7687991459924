import React, { PureComponent } from 'react';
import { func, object, any, array } from 'prop-types';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';
import { createStructuredSelector } from 'reselect';
import { isUndefined, isNil, isNull, isEmpty, find, get } from 'lodash';
import { toJS } from '@/utils/index';
import { queryStringToObject } from '@/utils/url';
import { track, trackSendEmailRecruiterCalendar } from '@/utils/analytics';

import { CloseModal } from '@/components/Buttons/components';
import { If } from '@/components/If';

// Store
import { appointmentSelectors, appointmentActions } from '@/store/appointment';
import { authActions, authSelectors } from '@/store/auth';
import { eventSelectors } from '@/store/event';
import { participantSelectors } from '@/store/participant';
import { interactionSelectors, interactionActions } from '@/store/interaction';

import { ANALYTICS_CANDIDATE } from '@/utils/constants';

// Components
import LoadingIndicator from '@/components/LoadingIndicator';
import MainContent from './components/MainContent';
import AppointmentDetails from './components/AppointmentDetails';

// Styles
import styles from './styles.less';
import messages from './messages';
import { trackGtmEvent } from "@/utils/pixels/helloWork";

/**
 * <Appointment />
 *
 * @description : Let user select a timeslot for his appointment
 */
class Appointment extends PureComponent {
  static propTypes = {
    intl: object,
    match: object,
    toggleSettings: func,
    participant: object,
    getAppointment: func,
    push: func,
    appointment: any,
    timeslots: array,
    interactions: array,
    selectAppointmentSlot: func,
    refuseAppointment: func,
    selectedTimeslot: any,
    autoLogin: func,
    authUser: any,
    cancelAppointment: func,
    getInteractions: func,
    event: object,
    modifyAppointmentSuccess: func,
    patchAppointment: func,
  };

  state = {
    modalIsOpen: true,
    selectedTimeslotTmp: null,
    hideSideContent: true,
    showOfferDetails: false,
    appointmentCanceled: false,
    isEdit: queryStringToObject(window.location.search).edit === 'true',
  };

  /**
   * componentDidMount
   *
   * @description : Check if user is logged, if not, autologin with token, Handle ScreenSize for responsive modal
   *
   */
  componentDidMount() {
    const { props: { getAppointment, getInteractions, autoLogin, appointment, authUser, match: { params: { appointmentID, eventSlug } } } } = this;

    const queryObject = queryStringToObject(window.location.search);
    const authToken = queryObject.token;
    const { hideLoader } = queryObject;

    if (isNil(authUser) && !isUndefined(authToken)) {
      autoLogin({
        authToken,
      });
    } else if (!isNil(authUser) && (hideLoader !== 'true' || !appointment)) {
      getAppointment({
        appointmentID,
        eventSlug,
      });
    } else {
      // TODO : If not authed & no token, redirect somewhere ?
    }
    if (!isNil(authUser) && (hideLoader === 'true')) {
      getInteractions({
        eventSlug,
        body: {
          users: [authUser._id],
        },
      });
    }

    this.handleScreenSize();
    window.addEventListener('resize', this.handleScreenSize);
    this.onOrientationChange();
  }

  /**
   * componentDidUpdate
   *
   * @description : get appointment details if user was not authed previously
   *
   */
  componentDidUpdate(prevProps) {
    const { props: { getAppointment, push, authUser, match: { params: { appointmentID, eventSlug } } }, state: { appointmentCanceled }, handleCloseAppointment } = this;

    if (isNil(prevProps.authUser) && !isNil(authUser)) {
      getAppointment({
        appointmentID,
        eventSlug,
      });
    }

    if (!isNull(prevProps.appointment) && isNull(this.props.appointment)) {
      push(`/${eventSlug}/candidate/jobdating/appointments`);
    }

    if (appointmentCanceled) {
      handleCloseAppointment();
    }
  }

  onOrientationChange = () => {
    if (window.addEventListener) {
      window.addEventListener('orientationchange', () => {
        window.location.reload();
      });
    }
  };

  /**
   * handleScreenSize
   *
   * @description : Handle screen resize to hide side content
   */
  handleScreenSize = () => {
    this.setState({
      hideSideContent: window.innerWidth < 960,
    });
  };

  findApplicationAuto = () => {
    const { props: { interactions = [], match: { params: { appointmentID } } } } = this;
    return find(interactions, (interaction) => interaction && get(interaction, '_appointment._id') === appointmentID && interaction.type === 'application-auto');
  };

  /**
   * handleCloseOffer
   *
   * @description : Handle closing modal
   */
  handleCloseAppointment = () => {
    const { props: { modifyAppointmentSuccess, appointment, push, match: { params: { eventSlug } } }, findApplicationAuto } = this;

    if (appointment.origin === 'informal1to1') {
      push(`/${eventSlug}/candidate/jobdating/informal1to1`);
    }

    const applicationAuto = findApplicationAuto();

    if (!isEmpty(applicationAuto)) {
      push(`/${eventSlug}/candidate/jobdating/jobs/${get(applicationAuto, '_offer._id')}`);
    } else {
      this.setState({ modalIsOpen: false, showOfferDetails: false }, () => modifyAppointmentSuccess());
    }
  };

  /**
   * handleOnShowOffer
   *
   * @description : Display offer detail
   */
  handleOnShowOffer = (offer) => {
    const { match: { params: { eventSlug } }, push } = this.props;

    push(`/${eventSlug}/candidate/jobdating/jobs/${offer._id}`);
  };

  /**
   * handleOnConfirmTimeslot
   *
   * @description : Handle timeslot selection confirmation
   */
  handleOnConfirmTimeslot = (payload) => {
    const { intl, selectAppointmentSlot, patchAppointment, participant, event, authUser, match: { params: { eventSlug } } } = this.props;
    const { slotSelected, appointment, messageInformal1to1Candidate, callback } = payload;

    if (
      event.skipAppointmentValidation === false &&
      appointment.origin === 'informal1to1' &&
      appointment.status !== 'accepted' &&
      appointment.acceptedBy !== 'recruiter' &&
      isEmpty(appointment.wasAcceptedAndUpdatedAt)) {

      patchAppointment({
        eventId: event._id,
        appointmentId: appointment._id,
        messageInformal1to1Candidate,
        callback,
      });
    } else {
      selectAppointmentSlot({
        eventSlug,
        slotSelected,
        appointment,
        messageInformal1to1Candidate,
        participantId: participant._id,
        notificationParams: {
          error: {
            message: intl.formatMessage(messages.slotUnavailable),
          },
        },
        callback,
      });
    }

    this.setState({ isEdit: false });

    track({
      name: ANALYTICS_CANDIDATE.BOOK_SLOT,
      user: authUser,
      event,
      properties: {
        auto: event.skipAppointmentValidation,
      },
    });

    trackGtmEvent('meeting_planning', {
      'page_category' : 'meeting',
      'page_subject' : 'slots' ,
      'user_id_back' : authUser._id,
    });
  };

  handleOnCancelAppointment = (messageCandidate) => {
    const { props: { appointment, refuseAppointment, authUser, event, push } } = this;

    refuseAppointment({
      appointmentId: appointment._id,
      eventSlug: event.slug,
      messageCandidate,
      event,
      eventId: event.id,
      userId: authUser._id,
      timeslotId: appointment._timeslot ? (appointment._timeslot._id || appointment._timeslot) : null,
      currentUser: authUser,
      callback: () => { push(`/${event.slug}/candidate/jobdating/appointments`); },
    });
  };

  trackEmailSendToRecruiter = () => {
    const { props: { timeslots, event } } = this;
    const type = timeslots.filter((timeslot) => isEmpty(timeslot._appointment)).length ? 'Slots conviennent pas' : 'No slots';

    trackSendEmailRecruiterCalendar({ event, type });
  };

  handleOnSelect = (slot) => this.setState({ selectedTimeslotTmp: slot })

  render() {
    const {
      handleOnShowOffer, handleOnConfirmTimeslot, handleOnCancelAppointment, handleCloseAppointment,
      trackEmailSendToRecruiter, handleOnSelect,
      state: { modalIsOpen, isEdit, selectedTimeslotTmp },
      props: { appointment, timeslots, cancelAppointment, authUser, event, toggleSettings, participant },
    } = this;

    const selectedTimeslot = appointment?._timeslot;
    const { hideLoader } = queryStringToObject(window.location.search);

    if (!appointment && hideLoader !== 'true') {
      return (<LoadingIndicator />);
    } if (!appointment) {
      return null;
    }

    const slot = selectedTimeslotTmp || selectedTimeslot;

    return (
      modalIsOpen ?
        (
          <div className={styles.customModal}>
            <div className={styles.container}>
              <div className={styles.leftSide}>
                <div className={styles.leftContent}>
                  <If condition={appointment}>
                    <MainContent
                      isEdit={isEdit || appointment.status === 'pending'}
                      handleShowOffer={handleOnShowOffer}
                      appointment={appointment}
                      timeslots={timeslots}
                      handleConfirmTimeslot={handleOnConfirmTimeslot}
                      handleCancelAppointment={handleOnCancelAppointment}
                      selectedTimeslot={selectedTimeslot}
                      cancelAppointment={cancelAppointment}
                      authUser={authUser}
                      onSelect={handleOnSelect}
                      toggleSettings={toggleSettings}
                      event={event}
                      skipValidation={event.skipAppointmentValidation}
                      tracker={trackEmailSendToRecruiter}
                      participant={participant}
                    />
                  </If>
                </div>
              </div>
              <div className={styles.rightSide}>
                <div className={styles.rightContent}>
                  <If condition={appointment}>
                    <AppointmentDetails
                      medium={slot && slot.medium}
                      location={slot && slot.location}
                      duration={slot && slot.duration}
                      appointment={appointment}
                      tracker={trackEmailSendToRecruiter}
                      hideContact={event.skipAppointmentValidation === true}
                    />
                  </If>
                </div>
              </div>
            </div>
            <CloseModal onClick={handleCloseAppointment} />
          </div>
        )
        : null
    );
  }
}

const mapStateToProps = createStructuredSelector({
  appointment: appointmentSelectors.getAppointment,
  timeslots: appointmentSelectors.getTimeslots,
  selectedTimeslot: appointmentSelectors.getSelectedTimelot,
  event: eventSelectors.getCurrentEvent,
  authUser: authSelectors.getAuthUser,
  participant: participantSelectors.getCurrentParticipant,
  interactions: interactionSelectors.getInteractions,
});

const mapDispatchToProps = {
  push,
  getAppointment: appointmentActions.getAppointment,
  getInteractions: interactionActions.getInteractions,
  cancelAppointment: appointmentActions.cancelAppointment,
  refuseAppointment: appointmentActions.refuseAppointment,
  selectAppointmentSlot: appointmentActions.selectAppointmentSlot,
  patchAppointment: appointmentActions.patchAppointment,
  autoLogin: authActions.autoLogin,
  toggleSettings: authActions.toggleSettings,
  modifyAppointmentSuccess: appointmentActions.modifyAppointmentSuccess,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withRouter,
  withConnect,
  injectIntl,
)(toJS(Appointment));
