import React, { useEffect, useState } from 'react';
import { Form } from '@seekube-tech/ui';
import { FormSpy } from 'react-final-form';
import { useFormatMessage } from 'react-intl-hooks';
import {  Modal, Row } from 'antd';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { replace } from 'connected-react-router';
import PropTypes from 'prop-types';
import arrayMutators from 'final-form-arrays';
import classnames from 'classnames';
import { isEqual } from 'lodash';
import { Prompt } from 'react-router'

import { Alert, Body1, Button, H4, IconAlertTriangle  } from '@seekube-tech/ui-kit';
import Wrapper from '@/components/Wrapper';
import LoadingIndicator from '@/components/LoadingIndicator';

import { organizationActions } from '@/store/organization';
import { sectorActions, sectorSelectors } from '@/store/sector';
import { authSelectors } from '@/store/auth';

import { STATUS } from './utils/constants';
import { dataToDTO } from './utils/convertDTO';

import Preview from './components/Preview';

import styles from './styles.less';
import { editStand, resetStand } from './request';
import { eventSelectors } from '@/store/event';
import ModalEditStand from './components/ModalEditStand';
import { notificationActions } from '@/store/notification';
import SelectStand from './components/SelectStand';
import ModalVersionAdmin from './components/ModalVersionAdmin';
import FooterStand from './components/FooterStand';
import GeneralInfo from './components/GeneralInfo';
import Presentation from './components/Presentation';
import AdditionnalBlocs from './components/AdditionnalBlocs';
import KeyNumbers from './components/KeyNumbers';
import ExternalLink from './components/ExternalLink';
import BenefitsSteps from './components/BenefitsSteps';
import Medias from './components/Medias';
import { track } from '@/utils/analytics';
import { ANALYTICS_RECRUITER } from '@/utils/constants';
import { flatObject } from '@/utils/math';
import { useGetOrganizationsCurrentProfile } from '@/queries/organizations/useGetOrganizationsCurrentProfile';
import { useGetOrganizationProfiles } from '@/queries/organizations/useGetOrganizationProfiles';

const Stand = (
  {
    getOrganization,
    organization,
    sectorsOptions,
    authUser,
    authToken,
    event,
    exponent,
    getSectors,
    isFetching,
    sendNotification,
    trueAuthUser
  }) => {
  /* Vars */
  const t = useFormatMessage();
  const [isPreview, setIsPreview] = useState(false);
  const [isModalResetOpen, setIsModalResetOpen] = useState(false);
  const [isModalSaveOpen, setIsModalSaveOpen] = useState(false);
  const [eventId, setEventId] = useState(location.query?.eventSelectedId ? location.query.eventSelectedId : exponent?._event._id); // Stand Master if undefined
  const [isModalVersionAdminOpen, setIsModalVersionAdminOpen] = useState(false);
  const [modifiedInputs, setModifiedInputs] = useState();

  const role = authUser?.toJS()?.currentRole?._role?.key

  const sectorsIntlOptions = sectorsOptions.map((sectorsOption) => ({
    ...sectorsOption,
    name: t({ id: `sector[${sectorsOption.key}]` },),
  }))

  const disabledFields = (organization.isClient && role === 'member') || (organization.isClient && role === 'supervisor' && eventId === undefined)

  const { data: organizationProfile } = useGetOrganizationsCurrentProfile({
    orgaId: organization?._id,
    eventId,
  });
  const { data: events = [], ...organizationProfilesQuery } = useGetOrganizationProfiles({
    orgaId: organization?._id,
  });

  /* Effects */
  useEffect(() => {
    getSectors();
  }, []);

  /* Functions */
  const handleSave = ({ isPublish = true, saveAsContextual = false }) => (values = {}) => {
    let organizationParams = dataToDTO(values, isPublish);

    if (!isPublish) {
      organizationParams = {
        ...organizationParams,
        status: 'draft',
      };
    }
    if (!organizationParams.stepPicture) {
      organizationParams = {
        ...organizationParams,
        stepPicture: '',
      };
    }


    if (saveAsContextual) {
      organizationParams = {
        ...organizationParams,
        eventId,
      };
    }

    editStand({ orgaId: organization._id, authToken, body: organizationParams })
      .then((res) => {
        track({
          user: authUser,
          name: ANALYTICS_RECRUITER.EDIT_ORGANIZATION_PROFILE,
          ...(exponent?._event || {}),
        });
        setIsModalSaveOpen(false)
        organizationProfilesQuery?.refetch();

        getOrganization(organization._id)

        sendNotification({
          message: `${t({ id: isPublish ? 'stand.save' : 'stand.save.draft' })}`,
          kind: isPublish ? 'success' : 'warning',
          style: {
            bottom: '7%',
          },
        });
      })
  };

  const handleOnSubmit = ({ isPublish = true }) => (values, errors) => {
    const flattenError = Object.entries(flatObject(errors)).filter(([_name, value]) => value)?.[0];

    if (flattenError?.[0]) {
      document.querySelector(`[name="${flattenError?.[0]}"]`)?.scrollIntoView({ block: "center" });
    }

    if (Object.values(modifiedInputs).filter((e) => e === true).length === 1 && modifiedInputs.name === true) {
      return handleSave({isPublish, saveAsContextual: organizationProfile?.isContextual})(values)
    }
    if (!organization.isClient && organizationProfile?.isContextual) {
      return handleSave({isPublish, saveAsContextual: organizationProfile?.isContextual})(values)
    }
    // If it's an admin and it's stand master
    if (organization.isClient && role === 'admin' && eventId === undefined) {
      return handleSave({isPublish, saveAsContextual: organizationProfile?.isContextual})(values)
    }
    return setIsModalSaveOpen(true);
  }

  /**
   * Open or close a stand preview
   * @param {Boolean} isVisible
   */
  const handleOnTogglePreview = (isVisible) => () => {
    setIsPreview(isVisible);
  };

  /**
   * Convert sectors when is array object to array string
   * @param {Array<Object || String>} sectors
   * @returns {Array<String>}
   */
  const convertSectors = (sectors = []) => {
    if (sectors.length > 0 && typeof sectors[0] === 'object') {
      return sectors.map((sector) => sector._id);
    }

    return sectors;
  };

  const handleReset = () => {
    const body = { eventId }
    resetStand({orgaId: organization._id, authToken, body})
      .then((res) => {
        setIsModalResetOpen(false)
        organizationProfilesQuery?.refetch();
      })
  }

  const changeSelectedStand = (newEventId) => {
    if (newEventId === 'master') {
      setEventId(undefined)
    }
    else {
      setEventId(newEventId)
    }
  }

  /* Derived vars */

  const { name, _id: organizationId } = organization;
  const profile = organizationProfile?.profile;
  const status = profile?.status || STATUS.draft;

  const initialValues = {
    name: profile?.name || name,
    cover: profile?.cover || '',
    pictureUrl: profile?.pictureUrl || '',
    sectors: convertSectors(profile?.sectors),
    size: profile?.size ? parseInt(profile.size, 36) : null,
    website: profile?.website || '',
    websiteCareer: profile?.website_career || '',
    description: profile?.description || '' || '<p class="ql-align-justify"><br></p>' || '<p><br></p>',
    more: profile?.more || '',
    pictures: profile?.pictures || [],
    videos: profile?.videos || [{ title: '', url: '' }],
    keyNumbers: profile?.keyNumbers || {},
    additionnalTexts: profile?.additionnalTexts || [],
    benefitsJoin: profile?.benefitsJoin || [],
    externalLinks: profile?.externalLinks || {},
    presentation: profile?.presentation || {},
    stepPicture: profile?.stepPicture || '',
    stepsJoin: profile?.stepsJoin || [],
    location: profile?.location || {},
  };

  /* Render */

  return isFetching || !profile ? (
    <LoadingIndicator />
  ) : (
    <Form
      subscription={{ pristine: true, valid: true, errors: true, modified: true, values: true}}
      initialValues={initialValues}
      initialValuesEqual={(prevValues, nextValues) => isEqual(prevValues, nextValues)}
      onSubmit={handleOnSubmit({})}
      mutators={{ ...arrayMutators }}
      render={({
        pristine,
        valid,
        modified,
        values,
        form,
        errors
      }) => (
        <div className={disabledFields && styles.formDisabled}>
          <FormSpy
            subscription={{ modified: true }}
            onChange={props => setModifiedInputs(props.modified)}
          />
          <Prompt
            when={Object.values(modified).includes(true)}
            message='You have unsaved changes, are you sure you want to leave?'
          />
          {isModalVersionAdminOpen &&
            <ModalVersionAdmin
              organization={organization}
              authToken={authToken}
              handleClose={() => setIsModalVersionAdminOpen(false)}
              setProfile={organizationProfilesQuery?.refetch()}
              eventId={eventId}
            />
          }
          {isModalSaveOpen &&
            <FormSpy
            subscription={{ values: true }}
            render={({ values }) => (
              <ModalEditStand eventName={events.find((e) => e?._event?._id === eventId)?._event?.name || event.toJS().name} status={profile?.status} valid={valid} role={role} organization={organization} handleClose={() => setIsModalSaveOpen(false)} handleSubmit={handleSave} values={values} />
            )}/>
          }
          {isPreview ? (
            <FormSpy
              subscription={{ values: true, pristine: true, valid: true }}
              render={({ values, valid }) => (
                <Preview
                  onClose={handleOnTogglePreview(false)}
                  exponent={exponent}
                  sectorsOptions={sectorsIntlOptions}
                  values={values}
                  pristine={pristine}
                  status={status}
                  valid={valid}
                  modified={modified}
                  disabledFields={disabledFields}
                  handleOnSubmit={handleOnSubmit}
                />
              )}
            />
          ) : (
            <Wrapper type="scene" className={styles.root}>
              {status === STATUS.draft &&
                <Alert isClosable={false} className={styles.alertStand} color='warning' icon={<IconAlertTriangle size={16} />}>
                  <span className={styles.alertDraftTitle}>{t({id: 'stand.alert.draft.title'})}</span>
                  <br/>
                  {t({id: 'stand.alert.draft.content'})}
                </Alert>
              }
              <div className={styles.form}>
                {organization.isClient &&
                  <div className={styles.selectStandContainer}>
                    <h1 className={classnames('mainTitle', styles.mainTitle)}>
                      {t({ id: 'event.recruiter.preparation.stand.content.1' })}
                    </h1>
                    <SelectStand
                      defaultValue={eventId}
                      events={events}
                      onSelect={changeSelectedStand}
                    />
                    {(role === 'member' || (role === 'supervisor' && eventId === undefined)) &&
                      <Alert isClosable={false} className={styles.selectAlert} color='info'>{t({id: 'stand.alert.cannot.edit'}, {role: role})}</Alert>
                    }

                  </div>
                }

                <div className={styles.blocForm}>
                  <GeneralInfo
                    organizationId={organizationId}
                    disabledFields={disabledFields}
                    trueAuthUser={trueAuthUser}
                    role={role}
                    organization={organization}
                    sectorsOptions={sectorsOptions}
                  />
                </div>

                <div className={styles.blocForm}>
                  <KeyNumbers
                    disabledFields={disabledFields}
                    values={values.keyNumbers}
                    change={form.change}
                  />
                </div>

                <div className={styles.blocForm}>
                  <Presentation
                    disabledFields={disabledFields}
                  />
                </div>

                <div className={styles.blocForm}>
                  <ExternalLink
                    disabledFields={disabledFields}
                    values={values.externalLinks}
                    change={form.change}
                  />
                </div>

                <div className={styles.blocForm}>
                  <Medias
                    disabledFields={disabledFields}
                    organizationId={organizationId}
                  />
                </div>

                <div className={styles.blocForm}>
                  <AdditionnalBlocs
                    disabledFields={disabledFields}
                    profile={values}
                    push={form.mutators.push}
                  />
                </div>

                <div className={styles.blocForm}>
                  <BenefitsSteps
                    disabledFields={disabledFields}
                    organizationId={organizationId}
                    profile={values}
                    push={form.mutators.push}
                  />
                </div>

                <Row type="flex" justify="space-between" align="middle">
                  <Modal
                    visible={isModalResetOpen}
                    closable={false}
                    footer={false}
                    className={styles.resetStandModal}
                  >
                      <H4 className={styles.resetStandModalTitle}>{t({id: 'reset.stand.title'})}</H4>
                      <Body1 className={styles.resetStandModalDescription}>{t({id: 'reset.stand.description'})}</Body1>
                      <Alert color='error' isClosable={false}>{t({id: 'reset.stand.error'})}</Alert>
                      <div className={styles.btnContainer}>
                        <Button variant='outline' color='neutral' onClick={() => setIsModalResetOpen(false)}>{t({id: 'cancel'})}</Button>
                        <Button className={styles.btnResetStand} color='error' onClick={handleReset}>{t({id: 'reset'})}</Button>
                      </div>
                  </Modal>
                </Row>

                <div className={styles.footerStand}>
                  <FooterStand
                    isContextual={organizationProfile?.isContextual}
                    status={status}
                    valid={valid}
                    handleOnSubmit={handleOnSubmit}
                    disabledFields={disabledFields}
                    trueAuthUser={trueAuthUser}
                    lastUpdate={organizationProfile?.lastUpdate}
                    setIsPreview={setIsPreview}
                    setIsModalResetOpen={setIsModalResetOpen}
                    setIsModalVersionAdminOpen={setIsModalVersionAdminOpen}
                    errors={errors}
                    form={form}
                  />
                </div>
              </div>
            </Wrapper>
          )}
        </div>
      )}
    />
  );
}

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
  trueAuthUser: authSelectors.getAuthUserNotConnectedAs,
  authToken:  authSelectors.getAuthToken,
  sectorsOptions: sectorSelectors.getAllSectors,
  event: eventSelectors.getCurrentEvent,
});

const mapDispatchToProps = {
  getOrganization: organizationActions.getOrganization,
  getSectors: sectorActions.getSectors,
  sendNotification: notificationActions.sendNotification,
  replace,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

Stand.propTypes = {
  organization: PropTypes.object,
  sectorsOptions: PropTypes.array,
  authUser: PropTypes.object,
  exponent: PropTypes.object,
  getSectors: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
};

Stand.defaultProps = {
  organization: {},
  sectorsOptions: [],
  authUser: {},
  exponent: null,
  isFetching: false,
};

export default compose(withConnect)(Stand);
