import React, { memo } from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { useHistory } from 'react-router-dom';
import { objectToParams } from '@/utils/url';
import styles from './styles.less';
import EventsSearching from '@/components/SearchEvents';
import { getLocale } from '@/utils/localStorage';
import useEventsSearchEngine from '@/utils/hooks/useEventsSearchEngine';

const SearchEvents = () => {
  const t = useFormatMessage();
  const locale = getLocale();
  const history = useHistory();
  const {
    events,
    suggestedEvents,
    hasFoundEvents,
    shouldShowNoResults,
    contracts,
    localisations,
    search
  } = useEventsSearchEngine();
  
  const onSubmit = (filters, facetKey = '') => {
    
    history.push({
      search: objectToParams({ ...filters, facetKey })
    });
  }
  
  return (
    <div className={styles.container}>
      <EventsSearching.SearchEngine
        contracts={contracts}
        localisation={localisations}
        keywords={search.keywords || ''}
        facets={events?.data?.pages?.[0]?.offersFacets || {}}
        onFilterChange={onSubmit}
        onSubmit={onSubmit}
      />
      <EventsSearching.Results 
        locale={locale}
        shouldShowNoResults={shouldShowNoResults}
        isLoading={events.isFetching || suggestedEvents.isLoading}
        eventsInfiniteQuery={hasFoundEvents ? events : suggestedEvents }
        isSuggest={!hasFoundEvents}
        filters={[...contracts, ...localisations]}
        keywords={search.keywords || ''}
        title={!hasFoundEvents ? t({ id: 'searchEngine.list.suggestions' }) : t({ id: 'searchEngine.list.results.title.pl' }, { count: events.data?.pages?.[0]?.total }) }
      />
    </div>
  )
}

SearchEvents.propTypes = {}

export default memo(SearchEvents);