import React from 'react';
import { string, object } from 'prop-types';
import classnames from 'classnames';

// Components
import Avatar from '@/components/Avatar';

// Styles & Translations
import styles from './styles.less';

/**
 * TagWithAvatar
 *
 * @param {object} props
 * @param {string} props.src
 * @param {string} props.name
 */
const TagWithAvatar = ({ src, name, user, className, style }) => (
  <div className={classnames(styles.tagContainer, className)} style={style}>
    <Avatar size={22} src={src} user={user} />
    <span
      className={
        classnames(
          styles.contentText,
          (typeof src !== 'undefined' && src !== '') ? '' : styles.verticalAlignTextBottom
        )
      }
    >
      {name}
    </span>
  </div>
);

TagWithAvatar.propTypes = {
  src: string,
  user: object,
  name: string,
  className: string,
  style: string,
};

TagWithAvatar.defaultProps = {
  src: '',
  className: '',
};

export default TagWithAvatar;
