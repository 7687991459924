import { isEqual } from 'lodash';

function getHasCardATagOnCover(conference) {
  const { countAttendees, maxAttendees, isNew } = conference

  return (
    isEqual(countAttendees, maxAttendees)
    || isNew
  )
}

export { getHasCardATagOnCover }
