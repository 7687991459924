import React from 'react';
import { connect } from 'react-redux';
import { object, string } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import copyToClipboard from '@/utils/copyToClipboard';

import { notificationActions } from '@/store/notification';

import Icon from '@/components/Icon';

import styles from './styles.less';

const Visio = ({
  url,
  settings: {
    organizationUserUrl,
    shareUrl,
    medium,
  },
  sendNotification,
}) => {
  const t = useFormatMessage();

  const onCopyToClipboard = () => {
    copyToClipboard(url || shareUrl);

    sendNotification({
      message: t({ id: 'copy.link.success' }),
      kind: 'success',
    });
  };

  return (
    medium === 'visio' && <>
      <span className={styles.visio}>
        <Icon name="camera" />
        <a
          href={url || organizationUserUrl}
          target="_blank"
        >
          {t({ id: 'seeklive.access' })}
        </a>
      </span>
      <span className={styles.shareVisio}>
        <a
          tabIndex={0}
          role="button"
          onClick={onCopyToClipboard}
        >
          <Icon className={styles.visio} name="share" />
          {t({ id: 'appointment.share.visio' })}
        </a>
        {/*      <ShareSeeklive
          url={shareUrl}
        >
          <Icon className={styles.shareIcon} name="share" />{t({ id: 'appointment.share.visio' })}
        </ShareSeeklive>*/}
      </span>
    </>
  );
};
const mapDispatchToProps = {
  sendNotification: notificationActions.sendNotification,
};


Visio.propTypes = {
  url: string,
  settings: object,
};
export default connect(null, mapDispatchToProps)(Visio);
