import React, { useState } from 'react';
import { func, object} from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { Body1, Button, H4 } from '@seekube-tech/ui-kit';
import { Icon, Modal } from 'antd';
import Select from '@/components/Form/Select';
import styles from './styles.less';
import { Select as SelectAntd } from 'antd';
import Avatar from '@/components/Avatar';
import { changeReferent } from './request';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthToken } from '@/store/auth/selectors';
import { exponentActions } from '@/store/exponent';
const { Option } = SelectAntd;

const ModalAskAccess = ({ handleClose, exponent }) => {
  const t = useFormatMessage();
  const authToken = useSelector(getAuthToken);
  const dispatch = useDispatch();

  const [selectedReferent, setSelectedReferent] = useState('')

  const options = exponent.users.map((user) => (
      <Option key={user._user.fullName} value={user._user._id} title={user._user.fullName}>
        <div className={styles.optionStandContainer}>
          <div className={styles.optionStandLeft}>
            <Avatar src={user._user.pictureUrl} size={28} user={user._user}/>
            <Body1 className={styles.optionStandName}>{user._user.fullName}</Body1>
          </div>
        </div>
      </Option>
    )
  );

  const handleSubmit = () => {
    changeReferent({exponentId: exponent._id, authToken, body: {referentUserId: selectedReferent}, eventId: exponent._event._id})
    .then(() => {
      handleClose();
      dispatch(exponentActions.getExponent({
        exponentId: exponent._id,
        eventId: exponent._event._id
      }))
    })
  }

  return (
    <Modal
      visible
      onCancel={handleClose}
      closable={false}
      footer={false}
      maskClosable
      width={720}
      className={styles.modalAskAccess}
    >
      <H4>{t({id: 'ask.access.title'})}</H4>
      <Body1 className={styles.askAccessDescription}>{t({id: 'ask.access.description'}, {exponent: exponent._organization.name})}</Body1>
      <Select
        showSearch
        id={styles.selectExponentUser}
        value={selectedReferent}
        suffixIcon={<Icon name="chevron" />}
        addOnIcon='user'
        placeholder={t({id: 'select.search.recruiter'})}
        onSelect={(e) => setSelectedReferent(e)}
        filterOption={(input, option) => option.props?.title?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0}

      >
        {options}
      </Select>
      <div className="confirm-actions mt-24">
        <Button onClick={handleClose} variant='outline' color='neutral'>{t({id: 'cancel'})}</Button>
        <Button disabled={selectedReferent === ''} onClick={() => handleSubmit()}>{t({id: 'submit'})}</Button>
      </div>
    </Modal>
  );
}

ModalAskAccess.propTypes = {
  handleClose: func, exponent: object,
};

export default ModalAskAccess;