import React from 'react';
import { object, func } from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { createStructuredSelector } from 'reselect';
import { NavLink } from 'react-router-dom';
import { isEmpty, isObject } from 'lodash';
import { Menu, Dropdown } from 'antd';
import { toJS } from '@/utils';
import { ANALYTICS_ALL, LINKS } from '@/utils/constants';
import { track } from '@/utils/analytics';

// Store
import { participantSelectors } from '@/store/participant';
import { authSelectors, authActions } from '@/store/auth';
import { eventSelectors } from '@/store/event';
import { exponentSelectors } from '@/store/exponent';
import { userActions } from '@/store/user';

// Components
import Icon from '@/components/Icon';
import Avatar from '@/components/Avatar';

// Styles & Translations
import styles from './styles.less';
import messages from './messages';
import { RecruiterDropdown } from './components/RecruiterDropdown';
import { trackGtmEvent } from "@/utils/pixels/helloWork";

const { Item } = Menu;


class UserDropdown extends React.PureComponent { // eslint-disable-line
  static propTypes = {
    authUser: object,
    event: object,
    history: object,
    exponent: object,
    intl: object,
    switchOrganization: func,
    toggleSettings: func,
    push: func,
  };

  state = {
    onEvent: false,
  };

  /**
   * componentWillReceiveProps
   *
   * @param event
   * @param location
   */
  componentWillReceiveProps({ event, history: { location } }) {
    const path = !isEmpty(location) ? location.pathname.split('/')[1] : '';

    if (isEmpty(event)) {
      this.setState({ onEvent: false });
    } else if (path === event.slug || path === event._id) {
      this.setState({ onEvent: true });
    }
  }

  onOpenSettings = (defaultView) => {
    document.getElementById('bigModalMainPanel').scrollIntoView(true);
    this.props.toggleSettings(defaultView);
  };

  getExponentRole = () => {
    const { authUser, exponent } = this.props;

    if (authUser.isAdmin) return false;

    const currentExponent
      = isObject(exponent)
      && exponent.users
      && exponent.users.find((user) => user._user && user._user._id === authUser._id);

    return currentExponent ? currentExponent.role : false;
  };

  goToEvents = () => this.props.push('/events');

  trackSettingsBtn = (authUser) => {
    track({ name: ANALYTICS_ALL.SETTINGS_CLICK });
    trackGtmEvent('page.display', {
      event: 'virtual.page.display',
      'user_id_back': authUser?._id || '',
      'path': '/settings',
    })
  };

  _renderCandidateDropdownMenu = () => {
    const {
      props: { event, authUser },
      state: { onEvent },
      goToEvents,
    } = this;

    return (
      <Menu className={styles.dropdownMenu}>
        <Item>
          <a onClick={() => this.onOpenSettings('profile')} role="button" tabIndex={0}>
            <Icon name="user" />
            <span><FormattedMessage {...messages.profile} /></span>
          </a>
        </Item>

        {event && onEvent ? (
          <Item className={styles.boardContainer}>
            <div className={styles.eventName}>
              <h3>{event.name}</h3>
            </div>
            <img src={event.theme ? event.theme.pictureUrl : ''} alt={event.name} />
          </Item>) : ''}

        {event && onEvent ? (
          <Item>
            <a role="button" tabIndex={0} onClick={goToEvents}>
              <Icon name="house" />
              <span><FormattedMessage {...messages.changeEvent} /></span>
            </a>
          </Item>) : ''}

        <Item onClick={() => this.trackSettingsBtn(authUser)} className={styles.noBorder}>
          <NavLink to="?settings" tabIndex={0}>
            <Icon name="settings" />
            <span><FormattedMessage {...messages.settings} /></span>
          </NavLink>
        </Item>
        <Item className={styles.noBorder}>
          <a role="button" target="_blank" href={LINKS.intercom_help_candidate[authUser.locale]} tabIndex={0}>
            <Icon name="info-circle" />
            <FormattedMessage {...messages.help} />
          </a>
        </Item>
        <Item className={styles.noBorder}>
          <NavLink to="/auth/logout" activeClassName="activeNavLink">
            <Icon name="exit" />
            <span><FormattedMessage {...messages.logout} /></span>
          </NavLink>
        </Item>
      </Menu>
    );
  };

  _renderRecruiterDropdownMenu = () => <RecruiterDropdown {...this.props} onEvent={this.state.onEvent} />;

  render() {
    const {
      props: { authUser },
    } = this;

    if (!authUser) {
      return '';
    }

    const isRecruiter = !isEmpty(authUser._currentOrganization);
    let avatarSrc;

    if (isEmpty(authUser._currentOrganization)) {
      avatarSrc = authUser.pictureUrl;
    } else if (authUser._currentOrganization.profile) {
      avatarSrc = authUser._currentOrganization.profile.pictureUrl;
    } else {
      avatarSrc = '';
    }

    return (
      <div className={styles.userDropdown} id="dropdownuser">
        <Dropdown
          overlay={isRecruiter ? RecruiterDropdown({ ...this.props, onEvent: this.state.onEvent }) : this._renderCandidateDropdownMenu()}
          trigger={['click']}
          placement="bottomRight"
          className={styles.dropdown}
          getPopupContainer={() => document.getElementById('dropdownuser')}
        >
          <a className="defaultNavLink">
            <Avatar className={styles.avatarDropdown} user={authUser} src={avatarSrc} size={35} type="rounded" /> <span className={styles.name}>{authUser.shortName}</span> <Icon name="arrow-down" className={styles.arrow} />
          </a>
        </Dropdown>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  participant: participantSelectors.getCurrentParticipant,
  authUser: authSelectors.getAuthUser,
  event: eventSelectors.getCurrentEvent,
  exponent: exponentSelectors.getCurrentExponent,
});

const mapDispatchToProps = {
  switchOrganization: userActions.switchOrganization,
  toggleSettings: authActions.toggleSettings,
  push,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  injectIntl,
)(toJS(UserDropdown));
