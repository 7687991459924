import React from 'react';
import { isEmpty, get, map, take } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { object, string } from 'prop-types';
import moment from 'moment';
import { Tag } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { If } from '@/components/If';

import messages from './messages';
import styles from './styles.less';
import { EVENT_FORMAT } from '@/utils/constants';

const EventColumn = ({ name, keyDates, planners, format, _exponent, keyMomentFormats, context = 'default' }) => {
  const t = useFormatMessage();
  const plannersOrgas = get(planners, '_organizations');
  const KeyMoments = format === EVENT_FORMAT.HYBRID ? keyMomentFormats : [format];

  return (
    <div className={styles.eventContainer}>
      <div style={{ whiteSpace: 'nowrap', fontWeight: 600 }}>
        <div className={styles.eventNameContainer}>
        <span className={styles.eventName}>
          {name}
        </span>
          {context === 'default' &&
            <Tag variant="outline" color="neutral" className={styles.keyMomentTag}>
              {KeyMoments.map((format, i) =>
                <span key={i}>{i === 1 && ' + '} {t({ id: `event.owner.settings.keyMoments.input.tags.${format}` })}</span>,
              )}
            </Tag>}
        </div>
        <If condition={!isEmpty(plannersOrgas)}>
          <div className={styles.planners}>
            <FormattedMessage {...messages.by} /> {map(take(plannersOrgas, 3), (orga) => orga.name).join(', ')}
            <span> • </span>
            {moment(get(keyDates, 'jobfair.beginAt')).format('MMMM YYYY')} - {moment(get(keyDates, 'jobfair.endAt')).format('MMMM YYYY')}
          </div>
        </If>
      </div>
    </div>
  );
};

EventColumn.propTypes = {
  name: string,
  keyDates: object,
  planners: object,
};

export { EventColumn };
