import React, { useEffect, useState } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl';
import { func, bool, object, string } from 'prop-types';
import { push } from 'connected-react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import ReactPixel from 'react-facebook-pixel';
import LinkedInTag from 'react-linkedin-insight';
import PhoneInput from 'react-phone-number-input';
import { Modal, Form, Radio } from 'antd';
import { useFormatMessage } from 'react-intl-hooks';
import { Typography } from '@seekube-tech/ui';
import { IconPhone, IconMail, H3, Button } from '@seekube-tech/ui-kit';
import classnames from 'classnames'
import { hasErrors } from '@/helpers/form/formHelper';
import Icon from '@/components/Icon';
import InputMaterial from '@/components/Input/InputMaterial';
import { If } from "@/components/If";
import { eventActions } from '@/store/event';
import styles from './styles.less';
import messages from './messages';
import { getHelloWorkPixel } from "@/utils/pixels/helloWork";
import {
  getEarliestKeyMomentFromFormats,
  getFarthestKeyMomentFromFormats,
  getKeyMomentsFormatsFromEvent
} from '@/utils/keyMoments';
import { PHASES } from '@/store/availableActions/utils/const';
import AddToCalendar from '@/components/AddToCalendar';
import addToCalendarTracking from '@/utils/tracking';
import { ANALYTICS_CANDIDATE } from '@/utils/constants';
const FormItem = Form.Item;

const PreregistrationModal = ({
  onSubmit,
  handleClose,
  push,
  authUserPreregistered,
  event,
  form,
  visible,
  email,
  intl,
}) => {
  const t = useFormatMessage();
  const [inputMode, setInputMode] = useState(null);
  const [phone] = useState(null);
  const [currentView, setCurrentView] = useState('form');
  const [showError] = useState('');
  const [loading, setLoading] = useState(false);

  const isSubmitDisable = () => {
    const field = inputMode === 'phone' ? 'phone' : 'username';

    return isEmpty(inputMode) || isEmpty(form.getFieldValue(field));
  };

  useEffect(() => {
    if (email && visible) {
      checkInput();
    }
  }, [visible])

  const onCancel = () => {
    const password = get(event, 'participants.filters.password');

    if (handleClose === 'function') {
      handleClose();
    }

    if (!isEmpty(password)) {
      push(`/${event.slug}?pwd=${password}`);
    } else {
      push(`/${event.slug}`);
    }
  };

  const shouldSubmit = (isAlreadyPreregistered, event) => {
    if (isAlreadyPreregistered) {
      setLoading(false);
      setCurrentView('fail');
    } else {
      setLoading(false);
      handleSubmit(event);
    }
  };

  const checkInput = (e) => {
    if (e) { e.preventDefault(); }

    const username = form.getFieldValue('username') || email;
    const phone = form.getFieldValue('phone') || phone;

    setLoading(true)

    authUserPreregistered({ eventId: event._id, username, phone, callback: shouldSubmit });
  };

  const postPixel = (val) => {
    if (window.location.hostname === 'app.seekube.com') {
      ReactPixel.track('preregistration', {
        eventName: event.slug,
        ...val,
      });
      LinkedInTag.track('1383441');
    }
  }

  const validatePhoneNumber = (rule, value, callback) => {
    if (value && value.length >= 6) {
      callback();
    } else {
      callback(t({ id: 'form.phone.mandatory' }));
    }
  };

  const handleSubmit = (e) => {
    if (e) { e.preventDefault(); }

    return form.validateFields((err, values) => {
      if (err) {
        return false;
      }

      const val = values;

      postPixel();

      getHelloWorkPixel(event.slug)?.track();
      getHelloWorkPixel(event.slug)?.trackPreregistration();

      // Waiting another rendering loop for update a state
      setTimeout(() => setCurrentView('success'), 1);

      onSubmit(err, val);

      return true;
    });
  };

  const getEndAt = (event) => {
    const formats = getKeyMomentsFormatsFromEvent(event);
    const endAtJobFair = getFarthestKeyMomentFromFormats(event, PHASES.JOBFAIR, formats);
    const endAtLive = getFarthestKeyMomentFromFormats(event, PHASES.LIVE);

    if (endAtLive?.isAfter(endAtJobFair)) {
      return endAtLive
    }
    return endAtJobFair
  }

  const getEventParams = () => {
    const eventName = event.name;
    const location = (event.format !== 'virtual' && event.location?.formattedAdress) ?
      event.location?.formattedAdress : '';
    const dateFormat = 'YYYY-MM-DD';
    const hourFormat = 'HH:mm';
    const formats = getKeyMomentsFormatsFromEvent(event);
    const beginAt = getEarliestKeyMomentFromFormats(event, PHASES.DISCOVERING, formats);
    const endAt = getEndAt(event)

    return {
      title: eventName,
      description: t({ id: 'preinscription.event.description' }, {
        event_name: eventName,
        date_from: beginAt.format(dateFormat),
        date_to: endAt.format(dateFormat),
        event_url: `${window.location.origin}/${event.slug}`
      }),
      location,
      startDate: beginAt.format(dateFormat),
      startTime: beginAt.format(hourFormat),
      endDate: endAt.format(dateFormat),
      endTime: endAt.format(hourFormat),
    };
  }

  const renderSuccess = (
    <div className="heading">
      <div className={styles.confettis}>🎉</div>
      <Typography variant="h3">
        {currentView === 'fail' ? t({ id: 'preregistration.failure.already.preregistrated.title' }) : t({ id: 'preregistration.success.title' })}
      </Typography>
      <div className={styles.description}>
        {currentView === 'fail' ? t({ id: 'preregistration.failure.already.preregistrated.description' }) : <FormattedMessage id="preregistration.description" values={{ date: moment(event.keyDates?.discovering.beginAt).format('D MMMM YYYY') }} />}
      </div>
      <AddToCalendar
        className="float-right"
        visible={visible}
        params={getEventParams()}
        onSelectCalendarType={(calendar) => addToCalendarTracking(event, calendar, ANALYTICS_CANDIDATE.ADD_JOBDATING_TO_CALENDAR)}
      />
    </div>
  );

  const renderForm = () => {
    const { getFieldDecorator, getFieldError } = form;
    const usernameError = getFieldError('username');

    return (
      <>
        <div>
          <H3>
            {t({ id: 'preregistration.title' })}
          </H3>
          <div className={styles.description}>
            <FormattedMessage id="preregistration.description" values={{ date: moment(event.keyDates?.discovering.beginAt).format('D MMMM YYYY') }} />
            <br /><br />
            <FormattedMessage id="preregistration.choose.communication.mode" />
          </div>
        </div>

        <Radio.Group onChange={(val) => setInputMode(val.target?.value)} value={inputMode} style={{ marginBottom: '16px' }}>
          <Radio.Button value="phone" className="flex align-center"><IconPhone size={16} className="mr-4" />{t({ id: 'phone' })}</Radio.Button>
          <Radio.Button value="email" className="flex align-center"><IconMail size={16} className="mr-4" />{t({ id: 'email' })}</Radio.Button>
        </Radio.Group>

        <Form className="manual-registration" onSubmit={checkInput}>
          <If condition={inputMode === 'phone'}>
            <FormItem
              validateStatus={usernameError || showError ? 'error' : ''}
              help={usernameError || showError}
            >
              {getFieldDecorator('phone', {
                initialValue: phone,
                rules: [
                  { validator: inputMode === 'phone' ? validatePhoneNumber : null, required: inputMode === 'phone' },
                ],
              })(
                <PhoneInput
                  defaultCountry={intl.locale === 'en' ? 'GB' : 'FR'}
                  className={styles.inputPhone}
                  label={t({ id: 'phone' })}
                  placeholder={`${t({ id: 'phone' })} *`}
                />
              )}
            </FormItem>
          </If>

          <If condition={inputMode === 'email'}>
            <FormItem
              validateStatus={usernameError || showError ? 'error' : ''}
              help={usernameError || showError}
            >
              {getFieldDecorator('username', {
                initialValue: email,
                rules: [{ required: inputMode === 'email', type: 'email', message: <FormattedMessage {...messages.errorFieldMandatory} /> }],
              })(
                <InputMaterial addOnIcon="message" label={intl.formatMessage({ id: 'preregistration.email.label' })} placeholder={intl.formatMessage({ id: 'preregistration.email.placeholder' })} type="email" validations={['required', 'email']} />
              )}
            </FormItem>
          </If>

          <Button className="mt-20" loading={loading} type="submit" disabled={isSubmitDisable() || hasErrors(form.getFieldsError())}>
            <FormattedMessage id="preregistration.cta" />
          </Button>
        </Form>
      </>
    );
  }

  return (
    <div className={classnames(visible ? styles.pageContainer : '')}>
      <Modal
        visible={visible}
        keyboard={false}
        onCancel={() => onCancel(null)}
        footer={null}
        maskClosable
        closable
        className="matchingModal"
        style={{ top: '8%' }}
      >
        <a
          role="button"
          tabIndex="0"
          className="modalCloseButton"
          onClick={(e) => handleClose(e)}
        >
          <Icon name="close-modal" />
        </a>
        <div className={styles.preregistrationContainer}>
          {currentView === 'form' ? renderForm() : renderSuccess}
        </div>
      </Modal>
    </div>
  );
}

PreregistrationModal.propTypes = {
  onSubmit: func,
  push: func,
  handleClose: func,
  authUserPreregistered: func,
  event: object,
  form: object,
  visible: bool,
  email: string,
  intl: object
};

const mapDispatchToProps = {
  push,
  authUserPreregistered: eventActions.isAuthUserPreregistered,
};

const withConnect = connect(null, mapDispatchToProps);
const withForm = Form.create()(PreregistrationModal);

export default compose(
  withConnect,
)(injectIntl(withForm));
