import React, { useEffect, useState } from 'react';
import { map, filter, debounce, get } from 'lodash';
import { array, func, object, number } from 'prop-types';

import { Button } from '@seekube-tech/ui-kit';
import S3Upload from '@/containers/S3Upload';
import Avatar from '@/components/Avatar';
import Icon from '@/components/Icon';
import { If } from '@/components/If';

import InputMaterial from '@/components/Input/InputMaterial';
import InlineEditor from '@/components/InlineEditor';

import styles from './styles.less';
import Draggable from '@/components/Draggable';


const Partners = ({ event, onChange, onDelete, contentIndex }) => {
  const elementsPath = `_contents.${contentIndex}.content.elements`;
  const elements = get(event, elementsPath) || [];

  const handleAction = (actionType, val, partnerIndex) => {
    switch (actionType) {
      case 'remove': onChange(['_contents', contentIndex, 'content', 'elements'], filter(elements, (partner, index) => index !== partnerIndex)); break;
      case 'setName': onChange(['_contents', contentIndex, 'content', 'elements'], map(elements, (partner, index) => index === partnerIndex ? { ...partner, name: val } : partner)); break;
      case 'setUrl': onChange(['_contents', contentIndex, 'content', 'elements'], map(elements, (partner, index) => index === partnerIndex ? { ...partner, url: val } : partner)); break;
      case 'onSort': onChange(['_contents', contentIndex, 'content', 'elements'],
        val
          .map(({ contentObject }) => contentObject.props.partner)); break;
      default: break;
    }
  };

  return (
    <div className={styles.partners}>
      <p>Logo(s):</p>
      <List partners={[...elements]} handleAction={handleAction} />
      <S3Upload
        uploadType="event"
        multi
        onChange={
          (files) => onChange(
             ['_contents', contentIndex, 'content', 'elements'], [...elements, ...map(files, (file) => ({ name: file.name, pictureUrl: file.url, url: null }))]
          )
        }
      >
        <Button>Ajouter un logo</Button>
      </S3Upload>
      <div>
        <Button color="danger" onClick={() => onDelete(contentIndex)}>Supprimer le bloc</Button>
      </div>
    </div>
  );
};

const List = ({ partners = [], handleAction }) => {
  const items = map(partners, (partner, key) => ({
    contentObject: <Item key={key} index={key} partner={partner} />,
    content: <Item action={(type, val) => handleAction(type, val, key)} partner={partner} />
  }));

  return <Draggable
    itemType='partners'
    items={items}
    localSort
    onDragEnd={(localItems) => handleAction('onSort', localItems)}
  />
}

const debounceFunc = debounce((fct) => fct(), 1000);

const Item = ({ partner, action }) => {
  const [isAddLinkVisible, setLinkVisible] = useState(false);
  const [linkValue, setLinkValue] = useState(partner.url);

  useEffect(() => {
    debounceFunc(() => {
      if (linkValue !== partner.url) {
        action('setUrl', linkValue);
      }
    });
  }, [linkValue]);

  return (
    <div className={styles.partner}>
      <div className={styles.main}>
        <Icon name="menu-burger" className={styles.menuIcon} />
        <div className={styles.partnersContent}>
          <div className={styles.partnersLogoAndName}>
            <Avatar className={styles.logo} size={40} src={partner.pictureUrl} type="rounded" imageFit="cover" />
            <span title={partner.name}>
              <InlineEditor
                editorEnabled
                value={partner.name}
                onChange={(val) => debounceFunc(() => action('setName', val))}
              />
            </span>
          </div>
          <div className={styles.actions}>
            <a tabIndex={0} role="button" onClick={() => setLinkVisible(!isAddLinkVisible)} className={isAddLinkVisible ? styles.iconActive : ''}><Icon name="link" /></a>
            <a tabIndex={0} role="button" onClick={() => action('remove')}><Icon name="trash" /></a>
          </div>
        </div>
      </div>
      <If condition={isAddLinkVisible}>
        <div className={styles.inputLink}>
          <InputMaterial
            placeholder="Ajouter un lien"
            addOnIcon="link"
            value={linkValue}
            onChange={(val) => setLinkValue(val.target.value)}
          />
        </div>
      </If>
    </div>
  );
};

Partners.propTypes = {
  event: object,
  onChange: func,
  onDelete: func,
  contentIndex: number,
};

List.propTypes = {
  partners: array,
  handleAction: func,
};

Item.propTypes = {
  partner: object,
  action: func,
};
export default Partners;
