import React from 'react';
import { object, func, string } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
// Styles
import styles from './styles.less';
import { AvatarBusiness, Button, IconClock, IconVideo } from '@seekube-tech/ui-kit';
import moment from 'moment';
import { EventName } from '../Appointment/components';
import { EVENTS_COLORS } from '@/components/BigCalendar/ducks/BigCalendarConstants';
import { getId } from '@/utils/global';

const LivePlanning = ({
  conference,
  jobfairsColor,
}) => {
  const t = useFormatMessage();

  return (
    <div className={styles.conferenceCardContainer}>
      <div className={styles.avatar}>
        <AvatarBusiness pictureUrl={conference._organization.profile.pictureUrl} size={'medium'} />
      </div>
      <div className={styles.info}>
        <p className={styles.title}>Live - {conference.title}</p>
        <div className={styles.complementary}>
          <div className={styles.hours}>
            <IconClock size={14} className={styles.iconClock} /> {moment(conference.beginAt).format('HH[h]mm')} - {moment(conference.beginAt).add(conference.duration, 'minutes').format('HH[h]mm')}
          </div>
          <div className={styles.link}>
            <a href={conference.providerUrl} target='_blank'>
              <Button size='large' variant='text' imageComponentLeft={<IconVideo size={14} />}>{t({id: 'livecard.actions.goToLive'})}</Button>
            </a>
          </div>
        </div>
        <EventName
          name={conference._event.name}
          jobfairColor={jobfairsColor[getId(conference._event)] || EVENTS_COLORS[0]}
        />
      </div>
    </div>
  );
};

LivePlanning.propTypes = {
  appointment: object,
  authUser: object,
  currentExponent: object,
  currentOption: string,
  getParticipant: func,
  onDownload: func,
  setCurrentAppointmentOpen: func,
  jobfairsColor: object,
};

export default LivePlanning;
