import { EVENT_FORMAT, USERS_TYPE, WORDS_IN_SLUG_FOR_WOMEN_EVENT } from './constants';
import { intersection } from 'lodash';

export function isEventHybrid(event) {
  return event.format === EVENT_FORMAT.HYBRID;
}

function getIsEventForWomenOnly(slug) {
  return WORDS_IN_SLUG_FOR_WOMEN_EVENT.reduce((prev, currentWord) =>
    (prev || slug.includes(currentWord)), false)
}


export function getIsUserAuthorizedToAccessEvent(slug, gender) {
  const isWomen = gender === '1';

  // Disable registration for men if event is for women only
  if (getIsEventForWomenOnly(slug)) return isWomen;

  return true;
}

export function getCTARole(event) {
  
  if (!event) {
    return null;
  }
  
  const isRecruiterAndOwner = intersection([USERS_TYPE.owner, USERS_TYPE.recruiter], event.userContext?.roles || []).length === 2;
  const isRecruiterAndAdmin = intersection([USERS_TYPE.admin, USERS_TYPE.recruiter], event.userContext?.roles || []).length === 2;
  
  if (isRecruiterAndOwner || isRecruiterAndAdmin) {
    return USERS_TYPE.recruiter;
  }
  
  return USERS_TYPE[event.context.toLowerCase()];
}
