import React from 'react';
import { func } from 'prop-types';
import { debounce } from 'lodash';
import { useFormatMessage } from 'react-intl-hooks';
import styles from './styles.less';
import InputMaterial from '@/components/Input/InputMaterial';

const debounceSearch = debounce((fct, val) => fct(val), 500);

const SearchEvent = ({ onSearchChange }) => {
  const t = useFormatMessage();

  const onSearchChanged = (val) => {
    debounceSearch(onSearchChange, val.target.value);
  };

  return (
    <div className={styles.searchEvent}>
      <InputMaterial
        type="string"
        autoComplete="new-password"
        placeholder={t({ id: 'events.explore.search.placeholder' })}
        addOnIcon="search"
        onChange={onSearchChanged}
      />
    </div>
  );
}

SearchEvent.propTypes = {
  onSearchChange: func,
}

export { SearchEvent };
