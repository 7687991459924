import React from 'react';
import { isEmpty } from 'lodash';
import { object } from 'prop-types';

import logo from '@/assets/images/mainLogo.svg';
import logoColor from '@/assets/images/logo-color.svg';

import { EVENT_STATUS } from '@/utils/constants';
import Spotlight from '@/scenes/Admin/components/Spotlight';
import LanguageSelector from '@/components/LanguageSelector';
import { Calendar, Auth, SeekubeLogo } from '../components';

import styles from '@/containers/Layout/containers/Header/styles.less';

const LandingHeader = (props) => (
  <div className={styles.headerStructure}>
    <div className={styles.leftSide}>
      <SeekubeLogo
        logo={logo}
        logoColor={logoColor}
        context="default"
        orgaLogo={props.event.slug === 'school-dating-mai-2023-1'}
      />
    </div>
    <div className={styles.rightSide}>
      <div className={styles.colRight}>
        <div className={styles.colAuth}>
          <Auth {...props} />
        </div>

        <div className={styles.colAuth}>
          <LanguageSelector />
        </div>

        {!isEmpty(props.event) && (location.href.indexOf('recruiter/jobdating') > -1 || location.href.indexOf('recruiter/preparation') > -1) &&
        [EVENT_STATUS.OPEN, EVENT_STATUS.IN_PROGRESS, EVENT_STATUS.HUNT_POST_EVENT].includes(props.event.dateStatus) ? <Calendar /> : null}

        <Spotlight className={styles.colAuth} />
      </div>
    </div>
  </div>
);

LandingHeader.propTypes = {
  event: object,
  intl: object,
};

export { LandingHeader };
