import React, { useState } from 'react';
import { object, func, string } from 'prop-types';
import { find } from 'lodash';
import { Form } from 'antd/lib';
import { Button, H5, Body1 } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { getTimezoneOptions } from '@/utils/format';
import Select from '@/components/Form/Select';
import 'moment-timezone';
import timezones from './timezone.json';
import styles from '@/scenes/Settings/styles.less';


const FormItem = Form.Item;

const Timezone = ({
  timezoneText,
  form,
  onSave
}) => {
  const [loading, setLoading] = useState(false);

  const t = useFormatMessage();

  const handleOnSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    form.validateFields((err, { timezone }) => {
      if (!err) {
        onSave({
          timezone: find(timezones, { text: timezone }),
          notificationParams: {
            success: {
              message: `${t({ id: 'form.information.success' })}`,
              kind: 'info',
              style: {
                bottom: '5%',
                top: 'inherit',
              },
            },
          },
          callback: () => setLoading(false),
        });
      }
    });
  };

  return (
    <Form className={styles.emailFormWrapper} onSubmit={handleOnSubmit}>
      <FormItem>
        <H5 className="mb-20">{t({ id: 'profile.settings.timezone.title' })}</H5>
        <Body1 className="mb-20 text-neutral-400">{t({ id: 'profile.settings.timezone.subtitle' })}</Body1>
        {form.getFieldDecorator('timezone', {
          initialValue: timezoneText,
          rules: [{ required: true, message: t({ id: 'form.error.required' }) }],
        })(
          <Select
            showSearch
            addOnIcon="location-world"
            label={t({ id: 'profile.settings.timezone.label' })}
            placeholder={t({ id: 'profile.settings.timezone.label' })}
          >
            {getTimezoneOptions()}
          </Select>
        )}
      </FormItem>

      <Button type="submit" loading={loading}>
        {t({ id: 'save' })}
      </Button>
    </Form>
  );
}

Timezone.propTypes = {
  timezoneText: string,
  form: object,
  onSave: func,
}
export default Form.create()(Timezone);
