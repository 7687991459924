import React, { useEffect, useState } from 'react';
import { object, func, any, array } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';
import ReactPixel from 'react-facebook-pixel';
import { createStructuredSelector } from 'reselect';
import { isEmpty, get, find } from 'lodash';
import { Modal } from 'antd';
import { Button } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { actionActions } from '@/store/action';
import { participantSelectors, participantActions } from '@/store/participant';
import { interactionSelectors } from '@/store/interaction';
import { eventSelectors } from '@/store/event/index';
import { authSelectors } from '@/store/auth';
import { offerActions, offerSelectors } from '@/store/offer';
import { track } from '@/utils/analytics';
import { ANALYTICS_CANDIDATE } from '@/utils/constants';
import { toJS } from '@/utils';
import LoadingIndicator from '@/components/LoadingIndicator';
import Wrapper from '@/components/Wrapper';
import MobileMenu from '@/containers/Layout/containers/MobileMenu';
import { getAvailableActions } from '@/store/availableActions/selectors';
import { deleteStoredUtmParams, getStoredUtmParams, isFromHelloWorkUtm, storeUtmParams } from '@/utils/url';
import OfferPreview from './components/OfferPreview';
import ApplicationModal from './components/ApplicationModal';

import Icon from '@/components/Icon';
import { H3 } from '@/components/Title';
import messages from './components/ApplicationModal/messages';
import P from '@/components/P';
import UserCard from '@/components/UserCard';
import FromHelloWorkBanner from './components/FromHelloWorkBanner';

import styles from './styles.less';
import MobileHeader from '@/containers/Layout/containers/MobileHeader';
import { trackGtmEvent } from "@/utils/pixels/helloWork";

function Job({
  participant,
  interactions,
  authUser,
  event,
  match,
  applyOffer,
  applyOfferWithoutValidation,
  getOffer,
  offer,
  push,
  cancelParticipant,
  availableActions,
}) {
  const [confirmationIsVisible, setConfirmationIsVisible] = useState(false);
  const [confirmationLightIsVisible, setConfirmationLightIsVisible] = useState(false);
  const [applicationModalIsVisible, setApplicationModalIsVisible] = useState(false);
  const [interaction, setInteraction] = useState()
  const t = useFormatMessage();
  const redirectUrl = `/auth/signup/authentication?redirect=${match.url}&utm_source=${getStoredUtmParams().utmSource}`
  const shouldLogin = !authUser && isFromHelloWorkUtm();
  const skipApplicationModal = event?.modules?.offer?.skipApplicationModal === true;
  const slots = availableActions.bookAppointment.getCountSlotsAvailables({event, participant, offer: offer ?? {}});

  useEffect(() => {
    window.scrollTo(0, 0);
    storeUtmParams();
    getOffer({ eventId: match.params.eventSlug, offerId: match.params.jobID });
  }, [])

  useEffect(() => {
    setInteraction(event && event.skipAppointmentValidation ?
      find(interactions, (interaction) => get(interaction, '_recruiter')?._id === offer?._user?.id && interaction.type !== 'informal1to1') :
      find(interactions, (interaction) => get(interaction, '_offers').find((off) => off?._id === offer?._id && interaction.type !== 'informal1to1')))

    if (authUser && isFromHelloWorkUtm() && offer && participant && !interaction) {
      handleApply();
    }
  }, [authUser, offer, interactions])

  const handleShowExponent = () => {
    if (authUser !== undefined) {
      track({
        name: ANALYTICS_CANDIDATE.VIEW_COMPANY_CLICK,
        event,
        user: authUser,
        properties: {
          company: offer._organization.name,
          'Current url TRUE': window.location.href,
        },
      });
    }

    if (shouldLogin) {
      push(redirectUrl)
    } else {
      push(`/${event.slug}/candidate/jobdating/exponents/${offer._organization._id}`);
    }
  };

  const handleApplyOffer = (countBadCriteria) => {
    const currentInteraction = find((interactions), (interaction) => get(interaction, '_offers').find((off) => off && offer && off._id === offer._id));

    if (event.skipAppointmentValidation === true && slots) {
      applyOfferWithoutValidation({
        appointmentId: get(currentInteraction, '_appointment._id'),
        offerId: offer._id,
        eventId: event.slug,
        callback: () => {
          ReactPixel.track('Postuler', {
            offerId: offer._id, eventSlug: event.slug,
          });

          deleteStoredUtmParams();

          track({
            name: ANALYTICS_CANDIDATE.APPLICATION_CONFIRMATION,
            user: authUser,
            event,
            properties: {
              auto: true,
              countBadCriteria,
              company: offer._organization.name,
            },
          });
        },
      });
    } else {
      applyOffer({
        offerId: offer._id,
        eventId: event._id,
        callback: () => {
          if (skipApplicationModal) {
            setConfirmationLightIsVisible(true);
          } else if (!slots) {
            setConfirmationLightIsVisible(true);
            setApplicationModalIsVisible(false);
          } else {
            setConfirmationIsVisible(true);
          }

          deleteStoredUtmParams();

          trackGtmEvent('meeting_criteria_ok', {
            'page_category' : 'meeting',
            'page_subject' : 'criteria' ,
            'user_id_back' : authUser._id,
          });

          track({
            name: ANALYTICS_CANDIDATE.APPLY_JOB_CLICK,
            user: authUser,
            event,
            properties: {
              auto: false,
              countBadCriteria,
              company: offer._organization.name,
            },
          });
        },
      });
    }
  };

  const handleOpenApplicationModal = () => {
    setApplicationModalIsVisible(true);

    trackGtmEvent('meeting', {
      'page_category' : 'meeting',
      'page_subject' : 'criteria' ,
      'user_id_back' : authUser._id,
    });

    track({
      name: ANALYTICS_CANDIDATE.APPLY_JOB_CLICK,
      user: authUser,
      event,
      properties: {
        auto: event.skipAppointmentValidation,
        company: offer._organization.name,
      },
    });
  }

  const handleApply = () => {
    if (shouldLogin) {
      push(redirectUrl)
    } else if (skipApplicationModal) {
      handleApplyOffer(0);
    } else {
      handleOpenApplicationModal()
    }

    trackGtmEvent('meeting', {
      'page_category' : 'meeting_criteria',
      'page_subject' : 'criteria' ,
      'user_id_back' : authUser._id,
    });
  }

  const handleCloseApplicationModal = () => {
    setApplicationModalIsVisible(false);
  }

  const handleCancelApply = () => {
    const currentInteraction = find((interactions), (interaction) => get(interaction, '_offers').find((off) => off && offer && off._id === offer._id));

    if (!isEmpty(currentInteraction) && authUser && participant) {
      cancelParticipant({
        interactionId: currentInteraction._id,
        participantId: participant._id,
        offerId: offer._id,
        userId: authUser._id,
        eventId: event._id,
        currentUser: authUser,
        callback: () => {
          track({
            name: ANALYTICS_CANDIDATE.CANCEL_APPLICATION_FOR_JOB,
            user: authUser,
            event,
            properties: {
              company: currentInteraction._organization.name,
            },
          });
        },
      });
    }
  };

  const handleCloseConfirmationLight = () => {
    setConfirmationLightIsVisible(false);
  }

  const handleReturnToJob = () => {
    push(`/${event.slug}/candidate/jobdating/jobs`);
  }

  if (!offer) {
    return (<LoadingIndicator />);
  }

  if (offer.deleted) {
    push(`/${event}/candidate/jobdating/jobs`);
  }

  return (
    <>
      <FromHelloWorkBanner offer={offer} event={event} />
      <div>
        <Wrapper className={styles.wrapper} size="full" style={{ marginTop: '-20px' }}>
          <OfferPreview
            match={match}
            offer={offer}
            event={event}
            interactions={interactions}
            participant={participant}
            onShowExponent={handleShowExponent}
            onApply={handleApply}
            onCancelApply={handleCancelApply}
            countSlots={offer.countSlotsAvailable}
          />
        </Wrapper>

        {skipApplicationModal === true ? null : (
          <ApplicationModal
            offer={offer}
            candidateMatching={participant?.matching || []}
            saveMatchingCriterias={event.skipAppointmentValidation === true && slots}
            visible={applicationModalIsVisible}
            onApply={handleApplyOffer}
            onClose={handleCloseApplicationModal}
            displayProgress={event.skipAppointmentValidation === true}
            confirmationIsVisible={confirmationIsVisible}
          />
        )}
        <MobileHeader event={event} />
        <MobileMenu
          displayInformal={event.modules?.informal1to1?.enable}
        />

        <Modal
          visible={confirmationLightIsVisible}
          footer={false}
          className="confirmationModal"
          onCancel={handleCloseConfirmationLight}
        >
          <a role="button" tabIndex={0} className="modal-close" onClick={handleCloseConfirmationLight}>
            <Icon name="close" className="modal-close-icon" />
          </a>

          <H3 bold>
            <span role="img" aria-label="apply-success">🎉</span> {t(messages.confirmationLightTitle)}
          </H3>
          <P style={{ marginBottom: '30px' }}>{t(messages.confirmationLightText, { recruiter: offer._user?.firstName })}</P>
          <UserCard titlePrefix={t(messages.aboutOf)} user={offer._user} showOrganizationPicture organization={offer._organization} />

          <Button onClick={handleReturnToJob}>
            {!isEmpty(event.modules?.offer?.[`backLabel_${authUser?.locale}`]) ? event.modules?.offer?.[`backLabel_${authUser.locale}`] : t(messages.confirmationLightCta)}
          </Button>
        </Modal>
      </div>
    </>
  );
}

Job.propTypes = {
  participant: object,
  interactions: array,
  authUser: object,
  event: object,
  match: object,
  availableActions: object,
  applyOffer: func,
  applyOfferWithoutValidation: func,
  getOffer: func,
  offer: any,
  push: func,
  cancelParticipant: func,
};

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
  participant: participantSelectors.getCurrentParticipant,
  event: eventSelectors.getCurrentEvent,
  offers: offerSelectors.getOffers,
  facets: offerSelectors.getFacets,
  pagination: offerSelectors.getOfferPagination,
  offer: offerSelectors.getParticipantSelectedOffer,
  interactions: interactionSelectors.getInteractions,
  availableActions: getAvailableActions,
});

const mapDispatchToProps = {
  push,
  postAction: actionActions.postAction,
  applyOffer: offerActions.applyOffer,
  applyOfferWithoutValidation: offerActions.applyOfferWithoutValidation,
  getOffer: offerActions.getOffer,
  cancelParticipant: participantActions.cancelParticipant,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withRouter,
  withConnect,
)(toJS(Job));
