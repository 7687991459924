import React, { useEffect, useState } from 'react';
import { object, func, string, array, number, bool } from 'prop-types';
import { concat, findIndex, intersection, isEmpty, replace as _replace } from 'lodash';
import { replace } from 'connected-react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { participantActions } from '@/store/participant';
import { userActions } from '@/store/user';
import { offerActions } from '@/store/offer';
import { TEMPLATE_CRITERIA_ID } from '@/utils/global';
import { H3 } from '@seekube-tech/ui-kit';

// Components
import MatchingFilterFormItem from '@/components/MatchingFilterFormItem';

// Services
import ApiToForm from '@/services/Form/ApiToForm';

import { useFormatMessage } from 'react-intl-hooks';
import { Bloc } from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Onboarding/components/Bloc';
import { arrayContain } from '@/utils/math';
import hasDurationContract from '@/utils/forms/contractDuration';

const Matching = (props) => {
  const { form, event, context, participant, authUser, onUpdate } = props;
  const t = useFormatMessage();
  const [disableDuration, setDisableDuration] = useState(false);
  const sortCriteria = event._criteria
  .filter((criterion) => !['LEVEL', 'SECTORS', 'SIZE'].includes(criterion.key) && criterion.enable && criterion.modules.onboarding.isVisible)
  .sort((a, b) => a.modules.onboarding.position - b.modules.onboarding.position);

  useEffect(() => {
    if (event?._criteria) {
      const initialValuess = ApiToForm.matchingFiltersParticipant(sortCriteria, participant.matching);

      checkContractActiveOptions(participant.matching.filters.flat());
      initializeDurationFilter(event, initialValuess); // We don't pass here on the first page load
      initializeContractFilter(initialValuess);
    }

    // form.validateFields();

    if (typeof onUpdate === 'function') {
      // onUpdate(form, props.handleSubmit);
    }
  } , [])

  /**
   * initializeDurationFilter
   *
   * @description
   * Disable filter duration on page load if only CDI is enabled in Contracts
   *
   */
  const initializeDurationFilter = (event, initialValues) => {
    const contractCriterion = event._criteria.find((criterion) => criterion.key === 'CONTRACT');

    if (!isEmpty(contractCriterion)) {
      const cdiFilter = contractCriterion._choices
      .filter((choice) => TEMPLATE_CRITERIA_ID.cdi === choice._id || choice.label === 'CDI')
      .map((choice) => choice._id);

      if (!isEmpty(cdiFilter)) {
        initialValues.filters.map((filter) => {
          if (arrayContain(filter, cdiFilter)) {
            form.setFieldsValue({ 'matching-filters-DURATION': undefined })
            setDisableDuration(true);

            return true;
          }

          return false;
        });
      }
    }
  };

  /**
   * isDurationDisabled
   *
   * @description
   * Disable duration if CDI or CDD is select in contract dropdown
   *
   * @param rule
   * @param value
   * @param callback
   */
  const isDurationDisabled = (rule, value, callback) => {
    const selectedValue = typeof value === 'object' ? value : [value];

    //Check if there is at least one contract with duration selected
    const hasDurationValues = hasDurationContract(selectedValue);
    const errors = !hasDurationValues ? undefined : [' '];

    if (disableDuration !== !hasDurationValues) {
      form.setFields({ 'matching-filters-DURATION': { value: [], errors } });
      setDisableDuration(!hasDurationValues);
    }

    callback();
  };

  const checkContractActiveOptions = (selectedIdContracts) => {
    const contractCriterion = event._criteria.filter((criterion) => criterion.key === 'CONTRACT')[0];

    if (!isEmpty(contractCriterion)) {
      const contractOptions = contractCriterion._choices
      .filter((choice) => selectedIdContracts.includes(choice._id))
      .map((choice) => choice.label);
    }
  };

  const initializeContractFilter = (initialValues) => {
    const initializeContracts = [];

    if (!isEmpty(initialValues) && !isEmpty(initialValues.filters)) {
      const contractIndex = findIndex(event._criteria, (criterion) => criterion.key === 'CONTRACT');
      const contractCriterion = event._criteria[contractIndex];
      if (!isEmpty(initialValues.filters[contractIndex])) {
        const contractsArray = initialValues.filters[contractIndex];

        if (contractCriterion) {
          contractCriterion._choices.forEach((choice) => {
            if (contractsArray.includes(choice._id)) {
              initializeContracts.push(choice.label);
            }
          });
        }
      }
    }
  };

  return (
    <Bloc>
      <div className="commonStep">
        <H3 className="mb-30">{t({ id: 'event.candidate.matching.title' })}</H3>
        {
          sortCriteria
          .map((criterion, index) => {
            if (criterion.modules.onboarding.isVisible === false) {
              return null;
            }

            const fieldKey = criterion.key !== undefined ?
              `matching-filters-${criterion.key}` :
              _replace(`matching-filters-${criterion.name}-${index}`, '.', '');

            const initialValue = criterion.key !== undefined ? participant?.matching[criterion.fieldName] : participant?.matching[`custom${criterion.fieldName}`];

            return (
              <MatchingFilterFormItem
                getFieldDecorator={form.getFieldDecorator}
                key={`formItem${criterion._id}`}
                criterion={criterion}
                fieldDecoratorKey={fieldKey} // Warning: Required fieldDecoration doesn't work with array in fieldDecoratorKey
                initialValue={initialValue}
                matchingData={participant?.matching || null}
                context={context}
                disableDuration={disableDuration}
                validatorCallback={criterion.key === 'CONTRACT' ? isDurationDisabled : null}
                form={form}
                checkContractActiveOptions={checkContractActiveOptions}
                withInputErrorStyle={props.withInputErrorStyle}
                authUser={authUser}
              />
            );
          })
        }
      </div>
    </Bloc>
  );
}
Matching.propTypes = {
  defaultCriteria: array,
  form: object,
  event: object,
  authUser: object,
  participant: object,
  totalOffers: number,
  onUpdate: func,
  setLastParticipantOfferSearch: func,
  context: string,
  patchUser: func,
  patchParticipant: func,
  postParticipant: func,
  onCancel: func,
  replace: func,
  intl: object,
  withInputErrorStyle: bool
};


const mapStateToProps = createStructuredSelector({});
const mapDispatchToProps = {
  patchParticipant: participantActions.patchParticipant,
  postParticipant: participantActions.postParticipant,
  setLastParticipantOfferSearch: offerActions.setLastParticipantOfferSearch,
  patchUser: userActions.patchUser,
  replace
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  withRouter,
  injectIntl,
)(Matching);
