import React from 'react';
import { object, func, string } from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import { isEmpty, get } from 'lodash';
import { useFormatMessage } from 'react-intl-hooks';
import { ButtonRound } from '@seekube-tech/ui-kit';
import { getId } from '@/utils/global';

// Components
import { Tag } from "@seekube-tech/ui-kit";
import Icon from '@/components/Icon';
import Avatar from '@/components/Avatar';
import { Tooltip } from '@/components/Tooltip';
import { EVENT_STATUS } from '@/utils/constants'
import { Applications } from '@/components/ParticipantCards/components/components';
import { EVENTS_COLORS } from '@/components/BigCalendar/ducks/BigCalendarConstants';
import { getApiUrl } from '@/utils/url';
import { If } from "@/components/If";

import { Visio, Location, Owner, PhoneNumber, AppointmentDate, CandidateName, EventName } from './components';

// Styles
import styles from './styles.less';

const Appointment = ({
  appointment,
  authUser,
  currentExponent,
  currentOption,
  getParticipant,
  onDownload,
  jobfairsColor,
}) => {
  const t = useFormatMessage();

  const handleDownload = (appointment) => {
    if (typeof onDownload === 'function') {
      onDownload(appointment);
    }
  };

  const handleProfileClick = () => {
    const overflowDays = get(appointment, '_organization._plan.cvthequeClosingCountDown') || 0;
    if (
      !isEmpty(get(appointment, '_event.slug'))
      && moment() < moment(appointment._event.keyDates.jobfair.endAt).add(overflowDays, 'days')) {
      getParticipant(appointment._user._id || appointment._user, appointment._event.slug);
    }
  };


  if (isEmpty(get(appointment, '_user'))) {
    return null;
  }

  moment.locale(authUser.locale);

  const appointmentDate = appointment._timeslot?.beginAt || appointment?.date;
  const appointmentBeginDate = moment(appointmentDate);
  const appointmentEndDate = moment(appointmentDate).add(appointment._timeslot?.duration || appointment.settings?.duration, 'minutes');
  const pastItem = moment(appointmentBeginDate).diff(moment(), 'minutes') < -(appointment._timeslot?.duration || appointment.settings?.duration || 30);

  const interaction = {
    type: appointment.origin === 'sourcing' ? 'proposition' : 'application',
    _offers: appointment._offers || [],
  };

  const appointmentClass = classnames(
      styles.itemElement,
      currentOption === currentExponent && currentExponent._id && getId(appointment._organizationUser) !== authUser._id ? styles.itemOwner : '',
      currentOption === isEmpty(appointment._offers) && currentOption !== getId(authUser) ? styles.bottomRadius : '',
      pastItem ? styles.passItem : null,
    );

  return (
    <div className={styles.appointmentContainer}>
      <div className={appointmentClass}>
        {/* Avatar */}
        <div className={styles.avatarContainer}>
          <Avatar src={appointment._user.pictureUrl} user={appointment._user} size={54} />
        </div>

        {/* Informations */}
        <div className={styles.informationsContainer}>
          <CandidateName
            handleProfileClick={handleProfileClick}
            isAuthUserAppointment={getId(appointment._organizationUser) === getId(authUser)}
            canAccessProfiles={appointment._event.dateStatus === EVENT_STATUS.OPEN || appointment._event.dateStatus === EVENT_STATUS.IN_PROGRESS}
            fullName={get(appointment, '_user.fullName')}
          />
          <div className={styles.appointmentInfo}>
            <If condition={appointment.status === 'canceled'}
              otherwise={
                <AppointmentDate
                  beginAt={appointmentBeginDate.format('H[h]mm').replace(/h00/, 'h')}
                  endAt={appointmentEndDate.format('H[h]mm').replace(/h00/, 'h')}
                />
              }
            >
              <Tag
                variant="tonal"
                color="error"
                className={styles.tag}
              >
                {appointment.canceledBy === 'candidate' ? t({ id: 'appointment.status.canceled.by.candidate'}) : t({ id: 'appointment.status.canceled.by.recruiter'}) }
              </Tag>
            </If>

            <PhoneNumber
              phone={get(appointment, '_user.phone')}
            />
            <Visio
              url={getApiUrl(appointment.recruiterVisioUrl)}
              settings={appointment.settings}
            />
            <Location
              settings={appointment.settings}
            />
          </div>

          <EventName
            name={appointment._event.name}
            jobfairColor={jobfairsColor[getId(appointment._event)] || EVENTS_COLORS[0]}
          />
        </div>

        {/*  Actions */}
        <div className={styles.actionsContainer}>
          <div className={styles.container}>
            <Tooltip
              title={t({ id: 'recruiter.participantsList.download.cv' })}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              color="dark"
              placement="bottom"
            >
              <ButtonRound variant="fill" color="success" onClick={() => handleDownload(appointment)}>
                <Icon name="download" />
              </ButtonRound>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className={styles.appointmentBottomInfo}>
        {appointment._offers &&
          <div>
            <Applications
              interaction={interaction}
              offers={appointment._offers}
              currentUser={authUser}
              appointment={appointment}
              forceRender
              count={2}
            />
          </div>
        }
        {
          currentOption !== getId(authUser) &&
          <Owner _organizationUser={appointment._organizationUser} />
        }
      </div>
    </div>
  );
};

Appointment.propTypes = {
  appointment: object,
  authUser: object,
  currentExponent: object,
  currentOption: string,
  getParticipant: func,
  onDownload: func,
  jobfairsColor: object,
};

export default Appointment;
