import React from 'react';
import { bool, object, string, array } from 'prop-types';
import {
  Alert,
  AvatarBusiness,
  Button,
  Caption1,
  CardLive,
  H3
} from '@seekube-tech/ui-kit';
import moment from 'moment';
import { useFormatMessage } from 'react-intl-hooks';
import { isEmpty } from 'lodash';
import styles from '../styles.less';
import {
  ShowMoreButton
} from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Exponents/scenes/Exponent/components/ShowMoreButton';
import addUtmToLink from '@/store/availableActions/utils/event/addUtmToLink';
import LoadingIndicator from '@/components/LoadingIndicator';
import { EventDates } from '@/components/SearchEvents/Results/components/EventDates';
import { EventFormat } from '@/components/SearchEvents/Results/components/EventFormat';
import { EventCompanies } from '@/components/SearchEvents/Results/components/EventCompanies';
import { getAppBaseUrl } from '../../../../public/utils/hostname';
import { NoResults } from '@/components/SearchEvents/Results/components/NoResults/NoResults';

const Results = ({
  eventsInfiniteQuery,
  isSuggest,
  title,
  filters,
  keywords,
  isLoading,
  shouldShowNoResults,
  locale
}) => {
  const t = useFormatMessage();
  moment.locale('fr');
  const appBaseUrl = getAppBaseUrl();
  const hasResults = !isEmpty(eventsInfiniteQuery?.data?.pages?.[0]?.docs);
  const filtersParams = `?keywords=${keywords}&filters=${filters.join(',')}`;
  const utmOffer = {
    source: 'seekube',
    campaign: 'discover_opportunities',
    medium: 'landing_search'
  }
  const utmGeneral = {
    source: 'seekube',
    campaign: 'learn_more',
    medium: 'landing_search'
  }
  
  if (shouldShowNoResults) {
    return <NoResults />;
  }
  
  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <div className={styles.eventsListContainer}>
      {title && hasResults && <H3 color="#3C4E73">{title}</H3>}
      <div className={styles.eventsLayout}>
        {(eventsInfiniteQuery?.data?.pages)?.map(page => page?.docs?.map((event) => (
          <div key={event.slug}>
            <CardLive
              className="w-auto justify-between h-full"
              contentClassName="h-auto"
              pictureUrl={event.theme?.coverPictureUrl || event.theme?.pictureUrl || ''}
              description={event.calendarB2CDescription?.[locale]}
              infosOrga={<div className={styles.infosOrga}>
                <div className={styles.head}>
                  <AvatarBusiness className={styles.left}
                                  pictureUrl={event.planners?._organizations?.[0]?.profile.pictureUrl} />
                  <div className={styles.right}>
                    <Caption1
                      className="text-neutral-300">{t({ id: 'organizedBy' })} {event.planners?._organizations?.[0]?.name}</Caption1>
                  </div>
                </div>
                {!isSuggest && <Alert color="success" isClosable={false} className={styles.alert}>
                  <span className={styles.text}>{event.countMatchingOffers} {t({ id: 'searchEngine.eventsList.alert.pl' }, { count: event.countMatchingOffers })}</span>
                </Alert>}
              </div>}
              title={<a className={styles.title} href={addUtmToLink(`${appBaseUrl}/${event.slug}`, utmGeneral)}>
                {event.name}
              </a>}
              infosLive={
                <div className={styles.infosContainer}>
                  <EventDates {...event} />
                  <EventFormat {...event} />
                  <EventCompanies
                    count={event.countExponents}
                    companyName={event.exponentsName?.[0]}
                  />
                </div>
              }
              actions={<div className={styles.actions}>
                <a href={addUtmToLink(`${appBaseUrl}/${event.slug}`, utmGeneral)}>
                  <Button color="neutral" variant="outline"
                          className={styles.btn}>{t({ id: 'customizeModal.actions.findMore' })}</Button>
                </a>
                <a href={`${addUtmToLink(`${appBaseUrl}/${event.slug}${filtersParams}`, utmOffer)}#offers`}>
                  <Button className={styles.btn}>{t({ id: 'searchEngine.eventsList.discoverOffers' })}</Button>
                </a>
              </div>}
            />
          </div>
        )))}
      </div>
      <ShowMoreButton
        entityInfiniteQuery={eventsInfiniteQuery}
        label={t({ id: 'searchEvents.list.showMore' })}
        variant="outline"
      />
    </div>
  )
}

Results.propTypes = {
  eventsInfiniteQuery: object,
  title: string,
  isSuggest: bool,
  filters: array,
  keywords: string,
  isLoading: bool,
  locale: string,
  shouldShowNoResults: bool
}

export { Results };