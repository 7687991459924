import React from 'react';
import { array, string, object } from 'prop-types';
import classnames from 'classnames';
import { size as _size, isEmpty } from 'lodash';
import { injectIntl } from 'react-intl';

// Components
import ResumeItem from './ResumeItem';

// Styles & Translations
import styles from './styles.less';
import messages from '../../messages';

/**
 * Resume
 */
class Resume extends React.PureComponent {
  static propTypes = {
    size: string,
    positions: array,
    educations: array,
    highlightWords: array,
    intl: object,
  };

  static maxLengthEducations = 2;
  static maxLengthPositions = 2;

  static getDerivedStateFromProps({ educations, positions }) {
    const newState = {};

    if (!isEmpty(educations)) {
      newState.educations = educations;
    }

    if (!isEmpty(positions)) {
      newState.positions = positions;
    }

    if (!isEmpty(newState)) {
      return newState;
    }

    return null;
  }

  /**
   * State
   */
  state = {
    educations: this.props.educations || [],
    positions: this.props.positions || [],
  };

  renderResumeItems = (key, items) => {
    const { props: { highlightWords, size } } = this;

    return items.slice(0, Resume.maxLengthEducations).map((item, index) => (
      <ResumeItem
        key={index}
        resumeItem={item}
        size={size}
        resumeType={key}
        highlightWords={highlightWords}
      />
    ));
  };

  render() {
    const {
      state: { educations, positions },
      props: { intl, size },
      renderResumeItems,
    } = this;

    const hasEducations = _size(educations) > 0;
    const hasPositions = _size(positions) > 0;
    const resumeContainer = classnames(styles.resumeContainer);
    const responsive = {
      medium: { flexBasis: '50%' },
      big: { flexBasis: '100%' },
    };

    return hasEducations || hasPositions ? (
      <div className={classnames(resumeContainer, styles[size])}>
        <div className={styles.border} />
        {/* Formations */}
        <div className={classnames(styles.formationsContainer, styles[size])} style={responsive[size]}>
          <h5 className="sectionTitle">{hasEducations ? intl.formatMessage(messages.educations) : ''}</h5>
          <div>{renderResumeItems('educations', educations)}</div>
        </div>
        { size === 'big' && !hasPositions ?
          null :
          (
            <div className={classnames(styles.experiencesContainer, styles[size])} style={responsive[size]}>
              <h5 className="sectionTitle">{intl.formatMessage(messages.experiences)}</h5>
              <div>{ hasPositions ? renderResumeItems('positions', positions) : intl.formatMessage(messages.blankNoExperience)}</div>
            </div>
          )
        }
      </div>
    ) : null;
  }
}

export default injectIntl(Resume);

