import { DEAL_NAME } from '@/utils/deals';
import { Tag } from '@seekube-tech/ui-kit';
import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';

const DealTypeTag = ({ deal }) => {
  const t = useFormatMessage();

  return ((
      () => {
        switch (deal.name) {
          case DEAL_NAME.company_subscription:
            return <Tag variant="tonal" color="pink">{t({ id: 'deal.company_subscription' })}</Tag>;
          case DEAL_NAME.thematic_one_shot:
            return <Tag variant="tonal" color="teal">{t({ id: 'deal.thematic_one_shot' })}</Tag>;
          case DEAL_NAME.company_one_shot:
            return <Tag variant="tonal" color="cherry">{t({ id: 'deal.company_one_shot' })}</Tag>;
          case DEAL_NAME.movie_party_one_shot:
            return <Tag variant="tonal" color="chocolate">{t({ id: 'form.deal.select.movie_party_one_shot' })}</Tag>;
          case DEAL_NAME.schoolpack_subscription:
            return <Tag variant="tonal" color="orange">{t({ id: 'deal.schoolpack_subscription' })}</Tag>;
          case DEAL_NAME.thematic_subscription:
            return <Tag variant="tonal" color="royalBlue">{t({ id: 'deal.thematic_subscription' })}</Tag>;
          case DEAL_NAME.credit_subscription:
            return (
              <Tag variant="tonal" color="orchid">
                {t({ id: 'deal.credit_subscription' })}
                <strong>{`: ${deal.creditRemainingQty}/${deal.creditQty}`}</strong>
              </Tag>);
          default:
            return null;
        }
      })()
  );
}
export { DealTypeTag }