import React, { useState } from 'react';
import { any, func } from 'prop-types';
import omit from 'lodash/omit';
import Icon from '@/components/Icon';
import { icons } from '@/components/Icon/icons';

// Components
import { Select } from 'antd';

const Option = Select.Option;

/**
 * Button
 */
const SelectIcons = (props) => {
  const [value, setValue] = useState(props.initialValue);

  const options = icons.map((item) => (
    <Option key={item}><Icon name={item} /> {item}</Option>
  ));

  const handleChange = (value) => {
    setValue(value);
    props.onChange(value);
  };

  // Render
  return (
    <Select
      showSearch
      value={value}
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
      style={{ minWidth: '150px' }}
      onChange={(value) => handleChange(value)}
      {...omit(props, ['onChange', 'className', 'shape', 'outline', 'type', 'style'])}
    >
      {options}
    </Select>
  );
};

SelectIcons.propTypes = {
  initialValue: any,
  onChange: func,
};

export default React.memo(SelectIcons);
