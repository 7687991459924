import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import { Row, Col } from 'antd';
import InlineEditor from '@/components/InlineEditor';
import Title from '@/scenes/Event/components/Title';
import Timeline from './Timeline';
import convertKeyDatesToArray from '@/scenes/Event/utils/convertKeyDatesToArray';
import { KEY_DATES_TYPE } from '@/scenes/Event/utils/constants';
import styles from '@/scenes/Event/containers/Landing/components/Contents/components/GeneralInfos/components/LandingContent/styles.less';

class Roadmap extends React.PureComponent {
  static propTypes = {
    event: PropTypes.object,
    intl: PropTypes.object,
    contentIndex: PropTypes.number,
    editMode: PropTypes.bool,
    onChange: PropTypes.func,
    onInit: PropTypes.func,
    locale: PropTypes.string,
  };

  /**
   * Send default value to the parent
   */
  componentDidMount() {
    const { props: { event, intl, contentIndex, onInit, locale } } = this;
    const dateFormat = locale === 'en' ? 'MMMM DD' : 'DD MMMM';
    const hourFormat = locale === 'en' ? 'hh a' : 'HH';
    
    moment.locale(locale);

    const keyDates = convertKeyDatesToArray(event.keyDates);
    const content = keyDates.map(keyDate => {
      let date = '';
      let color = 'blue';

      if (keyDate.type === KEY_DATES_TYPE.JOBFAIR) {
        let keyTranslate = 'landing.edit.keyDates.dateTitle';
        if (moment(keyDate.endAt).diff(moment(keyDate.beginAt), 'days') === 0) {
          keyTranslate = `${keyTranslate}.oneDay`;
        } else {
          keyTranslate = `${keyTranslate}.multipleDays`;
        }
        date = intl.formatMessage(
          { id: keyTranslate },
          { 
            dateFrom: moment(keyDate.beginAt).format(dateFormat), 
            dateTo: moment(keyDate.endAt).format(dateFormat),
          }
        );
      } else if ([KEY_DATES_TYPE.LIVE, KEY_DATES_TYPE.DISCOVERING].includes(keyDate.type)) {
        date = intl.formatMessage(
          { id: 'landing.edit.keyDates.dateTitle.multipleDays' },
          { 
            dateFrom: moment(keyDate.beginAt).format(dateFormat), 
            dateTo: moment(keyDate.endAt).format(dateFormat),
          }
        );
      } else {
        date = intl.formatMessage(
          { id: 'event.landing.roadmap.date' },
          { date: moment(keyDate.beginAt).format(dateFormat), hour: moment(keyDate.beginAt).format(hourFormat) },
        );
      }

      return {
        color,
        date,
        label: intl.formatMessage({ id: `event.keydates.${keyDate.type}.title` }, {
          dateFrom: moment(keyDate.beginAt).format(dateFormat), 
          year: moment(keyDate.beginAt).format('YYYY')
        }),
        content: intl.formatMessage({ id: `event.keydates.${keyDate.type}.description` }, { 
          dateFrom: moment(keyDate.beginAt).format(dateFormat), 
          hour: moment(keyDate.beginAt).format(hourFormat),
        }),
      };
    });

    if (onInit) {
      onInit(contentIndex)({
        inNav: true,
        label: intl.formatMessage({ id: 'event.content.roadmap.label' }),
        h2: intl.formatMessage({ id: 'event.content.roadmap.h2' }),
        h3: intl.formatMessage({ id: 'event.content.roadmap.h3' }),
        content,
      });
    }
  }

  /**
   * Return formated date for the timeline
   *
   * @param {string} startAt
   * @param {string} endAt
   *
   * @returns {string}
   */
  returnFormatedDate = (startAt, endAt) => {
    startAt = moment(startAt); // eslint-disable-line
    endAt = moment(endAt); // eslint-disable-line

    return `${startAt.format('DD')} - ${endAt.format('DD')} ${startAt.format('MMMM')} ${startAt.format('YYYY')}`;
  };

  /**
   * Render the timeline
   */
  renderTimeline = () => {
    const { props: { event, contentIndex, editMode, onChange, locale } } = this;
    const roadmap = event._contents.find(e => e.name === 'roadmap')
    const content = roadmap[locale].content || [];

    // Create a Array of date with for the label and the content the InlineEditor
    const dates = content.map((date, index) => ({
      color: date.color,
      date: (
        <InlineEditor
          editorEnabled={editMode}
          value={roadmap[locale].content[index].date}
          onChange={onChange(['_contents', contentIndex, locale, 'content', index, 'date'])}
        />
      ),
      label: (
        <InlineEditor
          editorEnabled={editMode}
          value={roadmap[locale].content[index].label}
          onChange={onChange(['_contents', contentIndex, locale, 'content', index, 'label'])}
        />
      ),
      content: (
        <InlineEditor
          editorEnabled={editMode}
          area
          value={roadmap[locale].content[index].content}
          onChange={onChange(['_contents', contentIndex, locale, 'content', index, 'content'])}
          className={styles.roadmapContent}
        />
      ),
    }));

    return <Timeline dates={dates} />;
  };

  /**
   * Render
   */
  render() {
    const {
      props: { event, contentIndex, editMode, onChange, locale },
      renderTimeline,
    } = this;

    return (
      <div className={styles.roadmap}>
        <Title
          event={event}
          contentIndex={contentIndex}
          editMode={editMode}
          onChange={onChange}
          locale={locale}
        />
        <Row type="flex" className={styles.leftAlign}>
          <Col span={12} className={styles.timelineContainer}>
            { renderTimeline() }
          </Col>
        </Row>
      </div>
    );
  }
}

export default injectIntl(Roadmap);
