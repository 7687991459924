import React from 'react';
import { object } from 'prop-types';
import { H3 } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { size, isEmpty } from 'lodash';
import styles from './styles.less';
import {
  BenefitsList
} from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Exponents/scenes/Exponent/components/Home/BenefitsJoin/BenefitsList';
import {
  OpeningBtnModal
} from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Exponents/scenes/Exponent/components/Home/BenefitsJoin/OpeningBtnModal';

const maxBenefitsJoinDisplay = 6;

export const BenefitsJoin = ({ profile }) => {
  const t = useFormatMessage();
  const benefitSize = size(profile?.benefitsJoin);
  const isButtonToOpenModalVisible = benefitSize > maxBenefitsJoinDisplay;

  const benefits = profile?.benefitsJoin?.filter(n => n);

  if (isEmpty(benefits)) {
    return null
  }

  return (
    <div className={styles.benefitsJoinContainer}>
      <H3 className='mb-24'>{t({  id: 'stand.join.benefits' })}</H3>
      <div className={styles.listContainer}>
        <BenefitsList profile={profile} maxBenefits={maxBenefitsJoinDisplay} />
        {
          isButtonToOpenModalVisible && (
            <OpeningBtnModal profile={profile} />
          )
        }
      </div>
    </div>
  )
}

BenefitsJoin.propTypes = {
  profile: object,
}
