import React, { useState } from 'react';
import { object, any, bool, func, oneOf } from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import LinesEllipsis from 'react-lines-ellipsis';
import { Button } from '@seekube-tech/ui-kit';
import { NavLink } from 'react-router-dom';
import ImgCdn from '@/components/ImgCdn';
import {
  CardOwner, Description,
  InfosList,
  LiveStatus, MoreActions,
} from './components';
import styles from './styles.less';
import { PresentersModal } from '@/scenes/Client/scenes/Conferences/components';
import { getHasCardATagOnCover } from './utils';

const CardLive = ({
  context,
  actions = null,
  handleAction = null,
  onCardClick,
  conference,
  filterByTopicEnable,
  hideParticipants = false,
  event,
  authUser,
}) => {
  const { title, description = '', _organization } = conference;
  const [isPresentersModalVisible, setIsPresentersModalVisible] = useState(false);
  const hasCoverShadow = getHasCardATagOnCover(conference)

  const getUrlLiveDetail = () => {
    if (context === 'owner') {
      return `/${event.slug}/owner/conferences/${conference._id}/preview?`
    }
    if (context === 'candidate') {
      return `conferences/${conference._id}/all`
    }
    return `/${event?.slug}/recruiter/conferences/${conference._id}/preview`
  }

  return (
    <>
      <div className={classnames(styles.cardOverlay, styles[context])}>
        <div className={classnames(styles.cardContainer, styles[context])} role="button" tabIndex={0} onClick={typeof onCardClick === 'function' ? onCardClick : () => { }}>
          <div className={styles.coverContainer}>
            {handleAction && <MoreActions context={context} handleAction={(type) => handleAction(type, conference)} conference={conference} />}
            {['candidate'].includes(context)
              && <LiveStatus {...conference} />}
            <ImgCdn
              size={500}
              src={conference.cover || _organization?.profile?.cover}
              background={{ backgroundSize: 'cover' }}
              withshadow={hasCoverShadow}
            />
          </div>
          <div className={styles.cardContent}>
            <CardOwner
              {...conference}
              context={context}
              filterByTopicEnable={filterByTopicEnable}
              partner={event?.modules?.partner}
              locale={authUser?.locale}
            />

            <h2 className={classnames(styles.title, conference.isVisited && styles.visited)} title={title}>
              {context !== 'candidate' ?
                <NavLink to={getUrlLiveDetail()} target='_blank'>
                  <LinesEllipsis
                    maxLine="2"
                    ellipsis="..."
                    trimRight
                    basedOn="letters"
                    text={title}
                  />
                </NavLink>
                :
                <LinesEllipsis
                  maxLine="2"
                  ellipsis="..."
                  trimRight
                  basedOn="letters"
                  text={title}
                />
              }
            </h2>
            <InfosList
              {...conference}
              context={context}
              hideParticipants={hideParticipants}
              handleOpenLinksModal={() => setIsPresentersModalVisible(true)}
            />
            {['client', 'nonClient'].includes(context)
              && (moment(conference.beginAt).subtract(30, 'minute').isBefore(moment().add('30', 'minutes'))
                && moment().isBefore(moment(conference.beginAt).add(conference.duration, 'minutes'))) ?
              <div className={styles.center}>
                <Button onClick={() => setIsPresentersModalVisible(true)}>
                  <FormattedMessage id="livecard.actions.goto" />
                </Button>
              </div>
              :
              <Description description={description} />
            }
          </div>
        </div>
        {actions}
      </div>
      <PresentersModal
        title={title}
        presenters={conference.presenters}
        visible={isPresentersModalVisible}
        handleCancel={() => setIsPresentersModalVisible(false)}
      />
    </>
  );
};


CardLive.propTypes = {
  conference: object,
  onCardClick: func,
  actions: any,
  handleAction: any,
  hideParticipants: bool,
  filterByTopicEnable: bool,
  context: oneOf(['owner', 'client', 'nonClient', 'candidate']),
  event: object,
  authUser: object,
};

export { CardLive };
