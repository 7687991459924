import { useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { filter } from 'lodash';
import request from '@/utils/request';
import { getAuthToken } from '@/store/auth/selectors';

export function useDeleteTemplate({
  onSuccess = () => {},
  onError = () => {},
  userId,
  ...props
}) {
  const queryClient = useQueryClient();
  const authToken = useSelector(getAuthToken);

  const mutationFn = (template) => {
    const requestUrl = `${process.env.FRONT_API_URL}/users/${userId}/templates/${template._id}`;

    return request(requestUrl, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken,
      },
    });
  };

  return useMutation({
    mutationFn,
    onSuccess: async (_, template) => {
      const templates = queryClient.getQueryData(['templates']);

      const newTemplates = filter(templates, (prevTemplates) => prevTemplates._id !== template._id);

      queryClient.setQueryData(['templates'], newTemplates);

      onSuccess();

      return newTemplates;
    },
    onError,
  });

}
