import { isEmpty, partition } from 'lodash';
import React, { Fragment, useState } from 'react';
import { Banner } from '@/scenes/Event/scenes/Recruiter/scenes/Preparation/scenes/Offer/components';
import { Menu } from 'antd';
import styles from '@/scenes/Event/scenes/Recruiter/scenes/Preparation/scenes/Offer/styles.less';
import { IconEyeOff, Alert, IconAlertTriangle } from '@seekube-tech/ui-kit';
import MoreDropdown from '@/components/MoreDropdown';
import { OfferCardList } from '@/components/OfferCard';
import { useFormatMessage } from 'react-intl-hooks';
import { ModalOfferInDraft } from '@/scenes/Event/scenes/Owner/scenes/Exponents/Offers/ModalOfferInDraft';
import CreateOfferModal
  from '@/scenes/Event/scenes/Recruiter/scenes/Preparation/scenes/Offer/containers/CreateOfferModal';
import { isOfferLimitExceeded, PIPE_DRIVE } from '@/utils/pipeDrive';
import { ModalConfirmPublishOffer } from '@/scenes/Event/scenes/Owner/scenes/Exponents/Offers/ModalConfirmPublishOffer';
import { useUpdateOffer } from '@/queries/offers/useUpdateOffer';
import OfferPreview
  from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Jobs/scenes/Job/components/OfferPreview';
import { useUpdateOfferByOwner } from '@/queries/offers/useUpdateOfferByOwner';

const modalDraftOfferDefault = { isVisible: false, offer: null };

export const OffersList = ({ offers, event, exponent, modalCreateOffer, setModalCreateOffer, modalPreviewOffer, setModalPreviewOffer }) => {
  const t = useFormatMessage();

  const [modalDraftOffer, setModalDraftOffer] = useState(modalDraftOfferDefault);
  const [modalConfirmPublishOffer, setModalConfirmPublishOffer] = useState({ ...modalDraftOfferDefault, callback: null });

  const mutatePublishOffer = useUpdateOffer({
    eventSlug: exponent?._event?.slug,
    organizationId: exponent?._organization?._id,
    onSuccess: () => {
      setModalCreateOffer({ ...modalDraftOfferDefault });
    }})


  const mutateUpdateOffer = useUpdateOfferByOwner({
    eventSlug: exponent?._event?.slug,
    organizationId: exponent?._organization?._id,
    onSuccess: () => {
      setModalCreateOffer({ ...modalDraftOfferDefault });
    }})

  const offersPartitionByStatus = partition(offers, (offer) => offer.status === 'published');

  const offersGroupByStatus = {
    published: offersPartitionByStatus[0],
    draft: offersPartitionByStatus[1],
  };

  function onHandleAction (offer, action, context) {
    if (action === 'edit') {
      setModalCreateOffer({ isVisible: true, offer });
    } else if (action === 'preview') {
      // this.showOffer(offer, context);
      setModalPreviewOffer({ isVisible: true, offer, context });
    }
  };

  function renderWarningOfferLimitExceeded() {
    const offersCount = offers.filter((offer) => offer.status === 'published').length;
    if (!isOfferLimitExceeded(exponent, offersCount)) return null;
    return exponent._organization.status === PIPE_DRIVE.status.client ?
      <div style={{ marginBottom: '20px' }}><Alert color="warning" icon={<IconAlertTriangle size={16} />}>{t({ id: 'event.recruiter.preparation.offer.form.warning.max.offers.client' })}</Alert></div>
      : <div style={{ marginBottom: '20px' }}><Alert color="warning" icon={<IconAlertTriangle size={16} />}>{t({ id: 'event.recruiter.preparation.offer.form.warning.max.offers' })}</Alert></div>;
  }

  function onSubmit (offerValues, callback) {
    if (isEmpty(offerValues?._id)) {
      openCreateOfferConfirmationModal(offerValues, callback);
    } else {
      updateOffer(offerValues);
    }
  }

  function updateOffer (offerValues) {
    const isOfferInDraft = modalCreateOffer.offer.status === 'draft';
    const args = { ...offerValues, _organization: exponent?._organization?._id, offerId: offerValues._id };

    if (isOfferInDraft) {
      mutatePublishOffer.mutate(args);
    } else {
      mutateUpdateOffer.mutate(args);
    }
  }

  function openCreateOfferConfirmationModal (offerValues, callback) {
    setModalConfirmPublishOffer({
      isVisible: true,
      offer: offerValues,
      callback: () => { setModalCreateOffer({ ...modalCreateOffer, isVisible: false }); callback()} })
  }

  if (modalPreviewOffer.isVisible) {
    return (
      <div className="mt-20">
        <OfferPreview
          currOff={modalPreviewOffer.offer}
          event={event}
          organization={exponent._organization}
          context={'OfferCard'}
          isPreview
          handleClosePreview={() => setModalPreviewOffer({ ...modalDraftOfferDefault })}
        />
      </div>
    );
  }

  return ['published', 'draft'].map((status, index) => (
    <Fragment key={index}>
      <Banner status={status} count={offersGroupByStatus[status].length} />

      {offersGroupByStatus[status].map((offer) => {
        const otherActions = (
          <Menu>
            {!isEmpty(offer._event) && status === 'published' && (
              <Menu.Item>
                <a role="button" className={styles.hideIcon} onClick={() => {setModalDraftOffer(
                  { isVisible: true, offer })}} tabIndex={0}>
                  <IconEyeOff size={12} />{t({ id: 'event.recruiter.preparation.offer.btn.draft' })}
                </a>
              </Menu.Item>
            )}
          </Menu>
        );

        const moreButton = (<MoreDropdown overlay={otherActions} placement="bottomRight" />);

        return (
          <OfferCardList key={offer._id} offer={offer} event={event} handleAction={onHandleAction} moreActions={moreButton} />
        );
      })}

      <ModalOfferInDraft
        exponent={exponent}
        isVisible={modalDraftOffer.isVisible}
        offer={modalDraftOffer.offer}
        onClose={() => setModalDraftOffer(modalDraftOfferDefault)}
      />

      <ModalConfirmPublishOffer
        exponent={exponent}
        isVisible={modalConfirmPublishOffer.isVisible}
        offer={modalConfirmPublishOffer.offer}
        callback={modalConfirmPublishOffer.callback}
        onClose={() => {setModalConfirmPublishOffer({ ...modalConfirmPublishOffer, isVisible: false });}}
      />

      {exponent && <CreateOfferModal
        isOwner
        isOpen={modalCreateOffer.isVisible}
        onClose={() => setModalCreateOffer({ ...modalDraftOfferDefault, isVisible: false })}
        offer={modalCreateOffer.offer}
        onPreview={(formValues) => { setModalPreviewOffer({ isVisible: true, offer: formValues, context: 'offerEdit' });}}
        currentEvent={event}
        exponent={exponent}
        publishedOffers={offers.filter((offer) => offer.status === 'published')}
        maxOffers={100}
        warningOfferLimit={renderWarningOfferLimitExceeded()}
        onSubmit={onSubmit}
      />}

    </Fragment>
  ));
};
