import { Menu } from 'antd';
import { func, string, object, bool } from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import styles from './styles.less';
import messages from './messages';
import { useSelector } from 'react-redux';
import { getAvailableActions } from '@/store/availableActions/selectors';

const InterviewAutoNav = ({ push, context, event, exponent, skipAppointmentValidation, slug, stats, onMenuItemChange }) => {
  const [currentMenuItem, setCurrentMenuItem] = useState(context);

  const availableActions = useSelector(getAvailableActions);

  const onMenuClick = ({ key }) => {
    const keyFixed = key.replace('-hidden', '');
    onMenuItemChange(keyFixed);
    setCurrentMenuItem(keyFixed);
    push(`/${slug}/recruiter/jobdating/interview/${keyFixed}`);
  };

  return (
    <Menu
      onClick={onMenuClick}
      selectedKeys={[currentMenuItem]}
      mode="horizontal"
      style={{ background: 'none', zIndex: 999999 }}
      className={classnames('horizontal-menu-tab', styles.interviewNav)}
    >
      {
        availableActions.acceptApplication.isDisabled({event, exponent}) || !skipAppointmentValidation ?
          null :
          <Menu.Item key="unconfirmed">
            <div><FormattedMessage {...messages.unconfirmed} />
              <div className="horizontal-menu-tab-count">{stats.unconfirmed}</div>
            </div>
          </Menu.Item>
      }
      <Menu.Item key="confirmed">
        <div><FormattedMessage {...messages.confirmed} /><div className="horizontal-menu-tab-count">{stats.confirmed}</div></div>
      </Menu.Item>

      <Menu.Item key="interview">
        <div><FormattedMessage {...messages.interview} /><div className="horizontal-menu-tab-count">{stats.interview}</div></div>
      </Menu.Item>
      <Menu.SubMenu
        title={<div><FormattedMessage {...messages.other} /><div className="horizontal-menu-tab-count">{stats.refused + stats.transferred}</div></div>}
      >
        <Menu.Item key="refused">
          <div><FormattedMessage {...messages.refused} /><div className="horizontal-menu-tab-count">{stats.refused}</div></div>
        </Menu.Item>
        <Menu.Item key="transferred">
          <div><FormattedMessage {...messages.transferred} /><div className="horizontal-menu-tab-count">{stats.transferred}</div></div>
        </Menu.Item>
      </Menu.SubMenu>
    </Menu>
  );
};

InterviewAutoNav.propTypes = {
  push: func,
  stats: object,
  onMenuItemChange: func,
  slug: string,
  context: string,
  areInteractionsClosed: bool,
  skipAppointmentValidation: bool,
  event: object,
  exponent: object,
};

export { InterviewAutoNav };
