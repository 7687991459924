import React, { useState } from 'react';
import { func, bool } from 'prop-types';

import { FormattedMessage } from 'react-intl';
import { Button, IconChevronDown, IconChevronUp, IconPlusCircle, Tag } from '@seekube-tech/ui-kit';
import Popover from '@/components/Popover';
import Icon from '@/components/Icon';

import { FORMAT_RESTRICTIONS } from '../EditLivetalk/helpers';

import messages from '../../messages';
import styles from './styles.less';
import { useFormatMessage } from 'react-intl-hooks';

const PostConferenceBtn = ({ startEditFlow, disabled }) => {
  const t = useFormatMessage();
  const [visible, setVisible] = useState(false);

  return (
    <div className={styles.postConferenceBtn}>
      <Popover
        content={
          <div className={styles.popoverFormats}>
            <div tabIndex={0} role="button" className={styles.format} onClick={() => startEditFlow('meeting')}>
              <span className={styles.pageTitle}>
                <div><Icon name="camera" /><FormattedMessage {...messages.editTitle_meeting} tagName="h3" /></div>
                <Tag color="grey"><FormattedMessage {...messages.popoverPlaces} values={{ count: FORMAT_RESTRICTIONS.meeting.maxAttendees.max }} /></Tag>
              </span>
              <span style={{ fontWeight: 500 }}>
                <FormattedMessage {...messages.chatText} tagName="p" />
              </span>
            </div>
            <div tabIndex={0} role="button" className={styles.format} onClick={() => startEditFlow('webinar')}>
              <span className={styles.pageTitle}>
                <div><Icon name="playVideo2" /><FormattedMessage {...messages.editTitle_webinar} tagName="h3" /></div>
                <Tag color="grey"><FormattedMessage {...messages.popoverPlaces} values={{ count: FORMAT_RESTRICTIONS.webinar.maxAttendees.max }} /></Tag>
              </span>
              <FormattedMessage {...messages.webinarText} tagName="p" />
            </div>
            <div tabIndex={0} role="button" className={styles.format} onClick={() => startEditFlow('chat')}>
              <span className={styles.pageTitle}>
                <div><Icon name="messages" /><FormattedMessage {...messages.editTitle_chat} tagName="h3" /></div>
                <Tag color="grey"><FormattedMessage {...messages.popoverUnlimited} values={{ count: 150 }} /></Tag></span>
              <FormattedMessage {...messages.chatText} tagName="p" />
            </div>
          </div>
        }
        trigger="click"
        overlayStyle={{ maxWidth: '430px' }}
        visible={visible}
        getPopupContainer={(trigger) => trigger.parentNode}
        onVisibleChange={() => setVisible(!visible)}
      >
        <Button disabled={disabled} imageComponentLeft={<IconPlusCircle size={16} />} imageComponentRight={visible ? <IconChevronUp size={16} /> : <IconChevronDown size={16} />}>
          {t({ id: 'recruiter.live.btn.post' })}
        </Button>
      </Popover>
    </div>
  );
};

PostConferenceBtn.propTypes = {
  startEditFlow: func,
  disabled: bool,
};

export { PostConferenceBtn };
