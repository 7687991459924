import React, { memo } from 'react';
import { compose } from 'redux';

import { SyncCalendarAlert } from './components';
import {
  DateClosingCardInfos
} from '@/scenes/Event/scenes/Recruiter/scenes/Jobdating/container/SceneAlerts/DateClosingCardInfos';
import styles from '@/scenes/Event/scenes/Candidate/scenes/JobDating/containers/SceneAlerts/styles.less';
import Wrapper from '@/components/Wrapper';
import { withRouter } from 'react-router-dom';

const GlobalAlert = ({ context, match }) => {
  return  <Wrapper size="large" className={styles.wrapperMobile}>
    <DateClosingCardInfos context={context} match={match}/>
  </Wrapper>;
};


const SceneAlerts = compose(memo, withRouter)(GlobalAlert);

export { SceneAlerts, SyncCalendarAlert };
