import React, { memo } from 'react';
import moment from 'moment';
import { any, array, bool, func, object, string } from 'prop-types';
import { size, isEmpty, includes } from 'lodash';
import { injectIntl } from 'react-intl';
import { useFormatMessage } from 'react-intl-hooks';
import { Select as SelectAntd } from 'antd';
import Icon from '@/components/Icon';
import Select from '@/components/Form/Select';
import Html from '@/components/Html';
import messages from './messages';
import { getIds } from '@/utils/global';

const { Option } = SelectAntd;

const SelectEvent = ({
  defaultValue,
  value,
  events,
  placeholder,
  onSelect,
  intl,
  allowAll = false,
  hasError,
  onBlur,
  onFocus,
  context = 'calendar'
}) => {
  const t = useFormatMessage();
  const userEventIds = getIds(events);
  const options = allowAll ? [(
    <Option key="all" value="all">
      <span>{t({ id: 'calendar.allAppointments' })}</span>
    </Option>)] : [];

  const eventOptions = events?.map((event) => {
    const { countAvailableTimeslots } = event;
    const beginAt = moment(event.readOnly.jobfairBeginAt);
    const endAt = moment(event.readOnly.jobfairEndAt);


    return (
      <Option key={event._id} value={event._id} title={event.name}>
        <label><Icon name="calendar" /> {beginAt.format('DD MMM')} - {endAt.format('DD MMM')}</label> {event.name}
        {context === 'calendar' && !isEmpty(userEventIds) && includes(userEventIds, event._id) ? (
          <span className={countAvailableTimeslots === 0 ? 'text warning' : 'text'}>
            <Html raw value={intl.formatHTMLMessage(messages.slots, { count: countAvailableTimeslots })} />
          </span>) : null}
      </Option>
    );
  }) || [];

  return (
    <Select
      showSearch={size(events) > 5}
      id="selectEvent"
      defaultValue={defaultValue}
      disabled={isEmpty(events)}
      value={value}
      suffixIcon={<Icon name="chevron" />}
      addOnIcon="house"
      placeholder={placeholder}
      onSelect={onSelect}
      hasError={hasError}
      filterOption={(input, option) => option.props.title && option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      onBlur={onBlur}
      onFocus={onFocus}
    >
      {[...options, ...eventOptions]}
    </Select>
  );
};

SelectEvent.propTypes = {
  defaultValue: object,
  value: object,
  events: array,
  intl: object,
  onSelect: func,
  placeholder: string,
  allowAll: bool,
  hasError: bool,
  onBlur: func,
  onFocus: func,
  context: string,
};

export default memo(injectIntl(SelectEvent));
