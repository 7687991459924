import React, { useEffect } from 'react';
import { any, func, number, bool } from 'prop-types';

import { useFormatMessage } from 'react-intl-hooks';

// components
import { Select } from '@seekube-tech/ui';
import { EVENT_FORMAT } from '@/utils/constants';

const ParticipationMode = ({
  onParticipationModeChange,
  value,
  style,
  disabled,
}) => {
  const t = useFormatMessage();
  const PARTICIPATION_MODE = [t({ id: `calendar.settingsSlot.participationMode.virtual`}), t({ id: `calendar.settingsSlot.participationMode.physical`})];
  const onSelectChange = (value) => {
    if(PARTICIPATION_MODE[0] === value) {
      onParticipationModeChange(EVENT_FORMAT.VIRTUAL);
    }
    else {
      onParticipationModeChange(EVENT_FORMAT.PHYSICAL);
    }
  };

  return (
    <div id="duration" style={style}>
      <Select
        fullWidth="100%"
        disabled={disabled}
        input={{
          label: t({ id: 'event.recruiter.preparation.timeslots.slot.participationMode' }),
          onChange: onSelectChange,
          value: PARTICIPATION_MODE.includes(value) ? value : t({ id: `calendar.settingsSlot.participationMode.${value}`}) ,
        }}
        options={PARTICIPATION_MODE}
        label={t({ id: 'event.recruiter.preparation.timeslots.slot.participationMode' })}
        renderValue={(option) => <span style={{ display: 'flex', alignItems: 'center' }}>{option}</span>}
        meta={
          { error: '' }
        }
      />
    </div>
  );
}

ParticipationMode.propTypes = {
  onParticipationModeChange: func,
  value: number,
  disabled: bool,
  style: any,
}

export default ParticipationMode;
