import React, { useState } from 'react';
import { object } from 'prop-types';
import styles from './styles.less'
import Videos from './Videos';
import isUrl from '@/utils/validations/url'

const GalleryVideo = ({
  profile,
}) => {

  if (!profile?.additionnalTexts) {
    return null;
  }

  const getVideos = () => {
    const lengthText = profile.additionnalTexts.length;
    const videos = profile.videos?.filter(({ url }) => isUrl(url));

    if (lengthText > 0) {
      return videos?.slice(2 + (lengthText * 2), videos?.length)
    }
    return videos?.slice(2, 4)
  }

  const videos = getVideos();

  if (!videos.length) {
    return null;
  }

  return (
    <div className={styles.picturesContainer}>
      <Videos profile={profile} videos={videos} />
    </div>
  )
}

GalleryVideo.propTypes = {
  profile: object
}

export default GalleryVideo;
