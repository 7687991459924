import React from 'react';
import { bool, object } from 'prop-types';
import { AvatarBusiness, Body2, Caption1, H2 } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';

// Styles & Translations
import styles from '../stylesV2.less';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { getAuthUser } from '@/store/auth/selectors';
import { IconsHybrid } from '@/components/IconsHybrid';

const StandCandidateTitle= ({
  exponent,
  profile,
  sectors
}) => {
  const authUser = useSelector(getAuthUser)?.toJS()
  const formatExponent = exponent?.keyMomentFormats?.length > 1 ? 'all' : exponent?.keyMomentFormats?.[0];

  return (
    <div className={styles.standTitleContainer}>
      <div className={classNames(styles.standPlannersAvatars, 'mr-24')}>
        <AvatarBusiness
          size={'large'}
          pictureUrl={((exponent?._organization.profile ?? profile)?.logo || (exponent?._organization.profile ?? profile)?.pictureUrl)}
          variant="business"
          className={styles.landingPlannersAvatar}
        />
      </div>
      <div>
        <div className={styles.titleText}>
          <H2>{(exponent?._organization.profile ?? profile)?.name}</H2>
          <IconsHybrid hybridFormat={formatExponent} />
        </div>
        <Body2 className={styles.titleInfo}>
          {(exponent?._organization.profile ?? profile)?.location?.name &&
            <span>{(exponent?._organization.profile ?? profile)?.location?.name} | </span>
          }
          {(exponent?._organization.profile?.sectors ?? sectors)?.map((sector, index) => {
            if (index > 0) {
              return <span key={index}> - {sector.name}</span>
            }
            return (
              <span key={index}>{sector.name}</span>
            )})
          }
        </Body2>
        <div className={styles.cornerList}>
          {exponent?.cornersPopulate.map((corner, key) => {
            return (
              <div key={key} className={styles.corner} style={{backgroundColor: corner.color}}>
                <Caption1>{corner[`label_${authUser?.locale}`]}</Caption1>
              </div>
            )

          })}
        </div>
      </div>
    </div>
  );
}

StandCandidateTitle.propTypes = {
  exponent: object,
}

export default StandCandidateTitle;
