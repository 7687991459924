import React, { useEffect, useState } from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { injectIntl } from 'react-intl';
import { array, bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment-timezone';
import { debounce, isEmpty, times, join } from 'lodash';
import classnames from 'classnames';
import { Row, Col } from 'antd';
import { Button } from '@seekube-tech/ui-kit';
import { toJS } from '@/utils';
import { getId, getIds } from '@/utils/global';
import { setLocalStorage } from '@/utils/localStorage';
import { DEBOUNCE, SLOT_TYPE, EVENT_FORMAT } from '@/utils/constants'
import {
  slotOverlaps,
  eventContainsSlot,
  isSlotPassed,
  setDuration,
  createAllCalendarEvents,
  getSlotsCleaned,
  createFreeSlots,
} from '@/components/Calendar/utils';
import {
  trackRecruiterClickedQuitCreatingSlots,
  trackRecruiterQuitCreatingSlots,
} from '@/utils/analytics';
import { timeslotActions } from '@/store/timeslot';
import { appointmentActions } from '@/store/appointment';
import { exponentActions } from '@/store/exponent';
import { notificationActions } from '@/store/notification';
import { actionActions } from "@/store/action";
import BlankState from '@/components/BlankState';
import { If } from '@/components/If';
import LoadingIndicator from '@/components/LoadingIndicator';
import Modal from '@/components/Modal';
import BigCalendar from '@/components/BigCalendar';
import { getEventKeyMoments } from '@/helpers/event/getEventKeyMoments';
import { analyticsRecruiterClickedCreatedSlots, analyticsRecruiterCreatedSlots, analyticsRecruiterDeleteSlots } from './analytics';
import { SelectEvent, SettingsSlots, TimestampAlert, SelectRecruiter } from './components';
import styles from './styles.less';
import ModalFormatNotAvailable from './components/ModalFormatNotAvailable';
import { useGetEventsSelect } from '@/queries/agenda/calendar/useGetEventsSelect';
import { useGetUsersSelect } from '@/queries/agenda/calendar/useGetUsersSelect';
import { useGetExponentUser } from '@/queries/agenda/calendar/useGetExponentUser';

const DEFAULT_VALUE_MODAL_CONTEXT = { isVisible: false, context: 'cancelEditing', onSubmit: () => { } };

const debounceFunc = debounce((fct) => fct(), DEBOUNCE, { leading: true });

const getIsOfferModuleEnable = (event) => event?.modules?.offer?.enable || false;

const getIsInformal1to1ModuleEnable = (event) => event?.modules?.informal1to1?.enable || false;

const getEventDefaultSlotType = (event) => {
  const isOfferModuleEnable = getIsOfferModuleEnable(event);
  const isInformal1to1ModuleEnable = getIsInformal1to1ModuleEnable(event);
  // If only informal1to1 module is enabled, so default slot type is informal1to1
  return !isOfferModuleEnable && isInformal1to1ModuleEnable ? SLOT_TYPE.INFORMAL1TO1 : SLOT_TYPE.INTERVIEW;
}

const Calendar = ({
                    authUser,
                    currentEvent: currentEventProp,
                    setCurrentOption,
                    postUserTimeslots,
                    delUserTimeslots,
                    events,
                    timeslots,
                    intl,
                    isOpen,
                    context = 'calendar',
                    sendNotification,
                    postAction,
                  }) => {
  const t = useFormatMessage();
  const [isInit, setIsInit] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(currentEventProp);
  const defaultSlotType = getEventDefaultSlotType(currentEvent);

  const [currentWeek, setCurrentWeek] = useState(null);
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(getId(authUser));
  const [isSelectEventFocus, setIsSelectEventFocus] = useState(false);
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [settings, setSettings] = useState({ participationMode: 'physical', duration: 15, medium: 'visio', type: defaultSlotType, location: null, isMultiEvents: false, informalDescription: null });
  const [tmpSlots, setTmpSlots] = useState([]);
  const [modalContext, setModalContext] = useState(DEFAULT_VALUE_MODAL_CONTEXT);
  const [eventKeyMoments, setEventKeyMoments] = useState({});
  const [formatDefineByTimeslot, setFormatDefineByTimeslot] = useState();
  const [openModalFormatNotAvailable, setOpenModalFormatNotAvailable] = useState(false);
  const [displayFormatTypeSetting, setDisplayFormatTypeSetting] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const eventsSelectQuery = useGetEventsSelect({
    userId: selectedUserId,
    onSuccess: ({ docs: selectedUserEvents }) => {
      const isCurrentEventInUserList = !isEmpty(selectedUserEvents?.find((event) => event?._id === currentEvent?._id));

      if (isCurrentEventInUserList) return;

      setCurrentEvent(currentEventProp)
    }
  });

  const usersSelectQuery = useGetUsersSelect({ eventId: currentEvent?._id });
  const exponentUserQuery = useGetExponentUser({
    eventId: currentEvent?._id,
    userId: selectedUserId,
    onSuccess: (exponent) => setCurrentOption(exponent)
  });
  const exponentFormat = getExponentParticipation();
  const currentExponentUserEvents = events.filter((event) => getIds(eventsSelectQuery?.data?.docs).includes(event._id));
  const selectedUser = usersSelectQuery?.data?.docs?.find((user) => user._id === selectedUserId);

  useEffect(() => {
    if (!isOpen) {
      setIsEditingMode(false);
      setTmpSlots([]);
    }
  }, [isOpen]);


  useEffect(() => {
    if (currentEvent?.format === EVENT_FORMAT.HYBRID) {
      if (exponentFormat) {
        changeEventKeyMoment(exponentFormat);
      }
      else {
        changeEventKeyMoment(EVENT_FORMAT.HYBRID);
      }
    }
    else {
      setEventKeyMoments(currentEvent?.keyDates?.jobfair)
    }
  }, [currentEvent, exponentFormat])

  useEffect(() => {
    if (eventsSelectQuery?.data?.total < 2) {
      setSettings({
        ...settings,
        isMultiEvents: false
      });
    }
  }, [exponentUserQuery?.data])

  useEffect(() => {
    if (formatDefineByTimeslot) {
      changeEventKeyMoment(formatDefineByTimeslot);
      handleOnParticipationModeChange(formatDefineByTimeslot);
    }
  }, [formatDefineByTimeslot])


  function getExponentParticipation() {
    if (currentEvent?.format === EVENT_FORMAT.HYBRID) {
      if (exponentUserQuery?.data?.keyMomentFormats?.length > 1) {
        return EVENT_FORMAT.HYBRID;
      }
      if (exponentUserQuery?.data?.keyMomentFormats[0] === EVENT_FORMAT.PHYSICAL) {
        return EVENT_FORMAT.PHYSICAL;
      }
      return EVENT_FORMAT.VIRTUAL;
    }

    if (currentEvent?.format === EVENT_FORMAT.PHYSICAL) {
      return EVENT_FORMAT.PHYSICAL
    }

    return EVENT_FORMAT.VIRTUAL
  }

  const changeEventKeyMoment = (format) => {
    if (format === EVENT_FORMAT.PHYSICAL) {
      setEventKeyMoments({
        beginAt: getEventKeyMoments(currentEvent, { type: 'jobfair', format: EVENT_FORMAT.PHYSICAL })?.beginAt,
        endAt: getEventKeyMoments(currentEvent, { type: 'jobfair', format: EVENT_FORMAT.PHYSICAL })?.endAt
      });
    }
    else if (format === EVENT_FORMAT.VIRTUAL) {
      setEventKeyMoments({
        beginAt: getEventKeyMoments(currentEvent, { type: 'jobfair', format: EVENT_FORMAT.VIRTUAL })?.beginAt,
        endAt: getEventKeyMoments(currentEvent, { type: 'jobfair', format: EVENT_FORMAT.VIRTUAL })?.endAt
      });
    }
    else {
      setEventKeyMoments({ beginAt: currentEvent?.readOnly?.jobfairBeginAt, endAt: currentEvent?.readOnly?.jobfairEndAt })
    }
  }

  useEffect(() => {
    setLocalStorage('is_editing_calendar', !isEmpty(tmpSlots));
  }, [tmpSlots]);


  useEffect(() => {
    if (currentEvent && !isEditingMode && exponentFormat === EVENT_FORMAT.HYBRID) {
      changeEventKeyMoment(exponentFormat);
    }
  }, [isEditingMode])

  useEffect(() => {
    if (currentEvent && eventsSelectQuery?.data?.docs) {
      const calendarEvents = createAllCalendarEvents({
        slots: timeslots,
        jobfairs: currentExponentUserEvents,
        currentEvent,
        isSaved: true,
        exponentFormat,
        isEventHybrid: currentEvent.format === EVENT_FORMAT.HYBRID
      });
      setCalendarEvents(calendarEvents);
      setFormatDefineByTimeslot();
      setDisplayFormatTypeSetting(false);
    }
  }, [timeslots, selectedUser]);

  useEffect(() => {
    const currentDate = moment();
    let week = 0;
    const isExponentHybrid = exponentFormat === EVENT_FORMAT.HYBRID;
    const physicalPeriod = currentEvent.weeks.physical;
    const virtualPeriod = currentEvent.weeks.virtual;
    let isBetweenTwoKeymoment = false;
    let furthestFormat = virtualPeriod;
    let keyMomentPhysical;
    let keyMomentVirtual;
    if (isExponentHybrid && currentEvent.format === EVENT_FORMAT.HYBRID) {
      keyMomentPhysical = currentEvent.keyMoments.jobfair.find(keyMoment => keyMoment.format === 'physical')
      keyMomentVirtual = currentEvent.keyMoments.jobfair.find(keyMoment => keyMoment.format === 'virtual')
      if (physicalPeriod && virtualPeriod) {
        furthestFormat = moment(physicalPeriod[0].beginAt).isAfter(moment(virtualPeriod[0].beginAt)) ? physicalPeriod : virtualPeriod;
      }
      // (!isBetweenPhysical && !isBetweenVirtual && one format has begun)
      isBetweenTwoKeymoment = !(moment(keyMomentPhysical.beginAt) <= currentDate && moment(keyMomentPhysical.endAt) >= currentDate) &&
        !(moment(keyMomentVirtual.beginAt) <= currentDate && moment(keyMomentVirtual.endAt) >= currentDate) &&
        (currentDate >= moment(keyMomentPhysical.beginAt) || currentDate >= moment(keyMomentVirtual.beginAt))
    }

    if (currentEvent.weeks[isExponentHybrid ? 'all' : exponentFormat] && currentWeek === 0) {
      week = currentEvent.weeks[isExponentHybrid ? 'all' : exponentFormat].findIndex((week) => {
        if (isBetweenTwoKeymoment) {
          return furthestFormat.filter(w => w.beginAt === week.beginAt).length > 0;
        }

        return (
          moment(week.beginAt) <= currentDate && moment(week.endAt) >= currentDate
        )

      });
      if (week === -1) {
        week = 0;
      }
      setCurrentWeek(week)
    }
  }, [currentEvent, exponentFormat]);

  useEffect(() => {
    if (exponentUserQuery.data) {
      setSettings({
        ...settings,
        location: exponentUserQuery?.data?.location,
        informalDescription: exponentUserQuery?.data.location?.informalDescription,
      })
    }

  }, [exponentUserQuery?.data?._id]);

  useEffect(() => {
    if (!isEmpty(authUser) && exponentUserQuery?.data && !isInit) {
      handleSelectRecruiter(authUser._id);

      const newState = {};

      if (authUser) {
        const currentDate = moment();
        const isExponentHybrid = exponentFormat === EVENT_FORMAT.HYBRID;
        const physicalPeriod = currentEvent?.weeks.physical;
        const virtualPeriod = currentEvent?.weeks.virtual;
        let isBetweenTwoKeymoment = false;
        let furthestFormat;
        let keyMomentPhysical;
        let keyMomentVirtual;
        if (isExponentHybrid) {
          furthestFormat = moment(physicalPeriod[0].beginAt).isAfter(moment(virtualPeriod[0].beginAt)) ? physicalPeriod : virtualPeriod;
          keyMomentPhysical = currentEvent.keyMoments.jobfair.find(keyMoment => keyMoment.format === 'physical')
          keyMomentVirtual = currentEvent.keyMoments.jobfair.find(keyMoment => keyMoment.format === 'virtual')
          // (!isBetweenPhysical && !isBetweenVirtual && one format has begun)
          isBetweenTwoKeymoment = !(moment(keyMomentPhysical.beginAt) <= currentDate && moment(keyMomentPhysical.endAt) >= currentDate) &&
            !(moment(keyMomentVirtual.beginAt) <= currentDate && moment(keyMomentVirtual.endAt) >= currentDate) &&
            (currentDate >= moment(keyMomentPhysical.beginAt) || currentDate >= moment(keyMomentVirtual.beginAt))
        }
        if (currentEvent.weeks[isExponentHybrid ? 'all' : exponentFormat] && currentWeek === null) {
          const week = currentEvent.weeks[isExponentHybrid ? 'all' : exponentFormat].findIndex((week) => {
              if (isBetweenTwoKeymoment) {
                return furthestFormat.filter(w => w.beginAt === week.beginAt).length > 0;
              }

              return (
                moment(week.beginAt) <= currentDate && moment(week.endAt) >= currentDate
              )

            }
          );
          newState.currentWeek = week === -1 ? 0 : week;
        }
      }

      if (!isEmpty(newState)) {
        setCurrentWeek(newState.currentWeek);
        setSettings({
          participationMode: 'physical',
          duration: 15,
          medium: currentEvent && currentEvent.format === 'physical' ? 'physical' : 'visio',
          location: exponentUserQuery?.data?.location,
          isMultiEvents: false,
          type: defaultSlotType,
        });
      }

      setIsInit(true);
    }

    const deviceOffset = new Date().getTimezoneOffset();

    if (deviceOffset > 10) {
      console.warn('Your device timezone is different of your Seekube account timezone. Please sync your device timezone to prevent calendar issues', deviceOffset);
    }
  }, [authUser]);

  const getModalProperty = () =>
    ({
      deleteAllSlots: {
        text: t({ id: 'calendar.slot.confirmDeleteAll' }),
        onSubmit: handleOnDeleteTimeslots,
      },
      deleteSlot: {
        text: t({ id: 'calendar.slot.confirmDelete' }),
        onSubmit: modalContext.onSubmit,
      },
      quitWithoutSaving: {
        text: t({ id: 'event.recruiter.preparation.offer.published.form.update' }),
        onSubmit: () => {
          setIsEditingMode(false);
          trackRecruiterQuitCreatingSlots({ authUser, event: currentEvent });
          modalContext.onSubmit();
        },
      },
      cancelEditing: {
        text: t({ id: 'modal.cancel.confirmation' }),
        helpText: t({ id: 'modal.cancel.confirmationText' }),
        okCta: t({ id: 'modal.calendar.cancel' }),
        cancelCta: t({ id: 'modal.calendar.cancelConfirm' }),
        onSubmit: () => {
          setTmpSlots([]);
          setFormatDefineByTimeslot()
          setDisplayFormatTypeSetting(false)
          setModalContext({ ...modalContext, isVisible: false });
          setIsEditingMode(false);
        },
      },
    }[modalContext.context]);

  const handleOnParticipationModeChange = (participationMode) => {
    if (exponentFormat === EVENT_FORMAT.HYBRID) {
      let newSlots = tmpSlots;
      if (participationMode === EVENT_FORMAT.VIRTUAL) {
        newSlots.forEach((slot) => {
          slot._keyMomentLink = {
            _keyMoment: {
              _id: currentEvent.keyMoments.jobfair.find(e => e.format === EVENT_FORMAT.VIRTUAL)._id
            },
            type: 'jobfair',
          }
        })
      }
      else {
        newSlots.forEach((slot) => {
          slot._keyMomentLink = {
            _keyMoment: {
              _id: currentEvent.keyMoments.jobfair.find(e => e.format === EVENT_FORMAT.PHYSICAL)._id
            },
            type: 'jobfair',
          }
          slot._event = currentEvent._id;
        })
      }
      const medium = participationMode === EVENT_FORMAT.VIRTUAL ? 'visio' : 'physical';
      newSlots = newSlots.map((slot) => ({ ...slot, medium }));
      if (!isEmpty(tmpSlots)) {
        setTmpSlots(newSlots);
      }
      // update medium
      setSettings({ ...settings, participationMode, medium });
      if (isEditingMode) {
        changeEventKeyMoment(participationMode);
      }
    }
  }

  const handleOnDurationChange = (duration) => {
    const cleanTmpSlots = getSlotsCleaned(tmpSlots, timeslots, eventKeyMoments, duration);

    const newSlots = setDuration([...timeslots, ...cleanTmpSlots], timeslots, duration);

    setTmpSlots(newSlots);

    setSettings({ ...settings, duration });
  }

  const handleOnDeleteTimeslots = () => {
    setModalContext({ ...modalContext, isVisible: false });

    const params = {
      currentUser: exponentUserQuery?.data?._user,
      timeslots: calendarEvents.filter((slot) =>
        isEmpty(slot._appointment) &&
        slot.isSaved &&
        (getId(currentEvent) === getId(slot._event) ||
          (isEmpty(slot._event) &&
            moment(slot.start).isBetween(moment().toDate(), eventKeyMoments.endAt))
        )),
      currentEvent,
      notificationParams: {
        success: getNotification({ id: 'toaster.edit.success' }, 'info', { emoji: '🙌 ' }),
        error: true,
      },
    };

    delUserTimeslots(params);

    analyticsRecruiterDeleteSlots({ authUser, event: currentEvent, isAllSlots: true });
  }

  const handleOnMediumChange = (medium) => {
    const newSlots = tmpSlots.map((slot) => ({ ...slot, medium }));

    setTmpSlots(newSlots);

    setSettings({ ...settings, medium });
  }

  const handleOnLocationChange = (location) => {
    const newSlots = tmpSlots.map((slot) => ({ ...slot, location }));
    setTmpSlots(newSlots);

    setSettings({ ...settings, location });
  }

  const handleOnTypeChange = (type) => {
    const isMultiEvents = type === SLOT_TYPE.INFORMAL1TO1 ? false : settings.isMultiEvents;
    const newEvent = isMultiEvents ? null : getId(currentEvent)

    const newSlots = tmpSlots.map((slot) => ({ ...slot, type, isMultiEvents, _event: newEvent }));
    setTmpSlots(newSlots);

    setSettings({
      ...settings,
      type,
      isMultiEvents
    });
  }

  const handleOnInformalDescriptionChange = (informalDescription) => {
    const newSlots = tmpSlots.map((slot) => ({ ...slot, informalDescription }));
    setTmpSlots(newSlots);

    setSettings({ ...settings, informalDescription });
  }


  // Create the slot
  const handleOnSelectSlot = debounce((slot) => {
    if (authUser.timezone) { moment.tz.setDefault(authUser.timezone.utc[0]); } else { moment.tz.setDefault(); }

    let { slots } = slot;

    if (slot.action === 'click') {
      slots.pop(); // calendar fix we remove the last slot
    }

    const { duration } = settings;

    // add select too, it wasn't there. Don't know why
    if (slot.action === 'click' || slot.action === 'select') {
      if (isSlotPassed(currentEvent, slot)) {
        return sendNotification(getNotification({ id: 'event.recruiter.preparation.timeslots.slot.notification.error.past' }, 'error'));
      }
      if (currentEvent.format === EVENT_FORMAT.HYBRID && exponentFormat === EVENT_FORMAT.HYBRID && !isEditingMode) {
        if (!eventContainsSlot(undefined, getEventKeyMoments(currentEvent, { type: 'jobfair', format: EVENT_FORMAT.PHYSICAL }), getEventKeyMoments(currentEvent, { type: 'jobfair', format: EVENT_FORMAT.VIRTUAL }), slot, duration)) {
          return sendNotification(getNotification({ id: 'event.recruiter.preparation.timeslots.slot.notification.error.notInEvent' }, 'error'));
        }
      } else if (!eventContainsSlot(eventKeyMoments, undefined, undefined, slot, duration)) {
        return sendNotification(getNotification({ id: 'event.recruiter.preparation.timeslots.slot.notification.error.notInEvent' }, 'error'));
      }

      // Check if the slot is free
      if (slotOverlaps(slot, [...tmpSlots, ...timeslots], duration)) {
        return sendNotification(getNotification({ id: 'event.recruiter.preparation.timeslots.slot.notification.error.slot.conflict' }, 'error'));
      }
    }

    // If settings are closed, open it and track the click on mixpanel
    if (!isEditingMode) {
      analyticsRecruiterClickedCreatedSlots({ authUser, clickedButton: false });
      setIsEditingMode(true);
    }

    if (currentEvent.format === EVENT_FORMAT.HYBRID && !isEditingMode) {
      if (exponentFormat === EVENT_FORMAT.HYBRID) {
        const physicalDates = getEventKeyMoments(currentEvent, { type: 'jobfair', format: EVENT_FORMAT.PHYSICAL });
        const virtualDates = getEventKeyMoments(currentEvent, { type: 'jobfair', format: EVENT_FORMAT.VIRTUAL });
        if (moment(slots[0]).isBetween(virtualDates.beginAt, virtualDates.endAt) && moment(slots[0]).isBetween(physicalDates.beginAt, physicalDates.endAt)) {
          setDisplayFormatTypeSetting(true);
        }
        if (moment(slots[0]).isBetween(virtualDates.beginAt, virtualDates.endAt)) {
          setFormatDefineByTimeslot(EVENT_FORMAT.VIRTUAL)
        }
        else if (moment(slots[0]).isBetween(physicalDates.beginAt, physicalDates.endAt)) {
          setFormatDefineByTimeslot(EVENT_FORMAT.PHYSICAL);
        }
      }
      else {
        setFormatDefineByTimeslot(exponentFormat)
      }
    }
    const physicalDates = getEventKeyMoments(currentEvent, { type: 'jobfair', format: EVENT_FORMAT.PHYSICAL });
    const virtualDates = getEventKeyMoments(currentEvent, { type: 'jobfair', format: EVENT_FORMAT.VIRTUAL });

    if (slots.length > 1) {
      slots.pop(); // remove the end slot cause we don't need it

      // ARRAY OF INDEX FOR KEEPING  A CAUSE DE LA DUREE
      const iterate = duration / 15;

      const indexListToKeep = times(Math.ceil(slots.length / iterate), (index) => index * iterate);

      slots = indexListToKeep.map((index) => slots[index]);
      // Handle hybrid
      if (exponentFormat === EVENT_FORMAT.HYBRID && !isEditingMode) {
        if (moment(slots[0]).isBetween(virtualDates.beginAt, virtualDates.endAt)) {
          slots.forEach((slot, index) => {
            if (!moment(slot).isBetween(virtualDates.beginAt, virtualDates.endAt)) {
              setOpenModalFormatNotAvailable(true);
              return slots.splice(index, slots.length - (index));
            }
          })
        }
        else if (moment(slots[0]).isBetween(physicalDates.beginAt, physicalDates.endAt)) {
          slots.forEach((slot, index) => {
            if (!moment(slot).isBetween(physicalDates.beginAt, physicalDates.endAt)) {
              setOpenModalFormatNotAvailable(true);
              return slots.splice(index, slots.length - (index));
            }
          })
        }
      }
    }

    const newTimeslots = createFreeSlots({
      slots,
      settings,
      currentEvent,
      userId: getId(exponentUserQuery?.data?._user),
      ownerId: getId(authUser),
    });

    if (currentEvent.format === EVENT_FORMAT.HYBRID && !settings.isMultiEvents) {
      if (moment(slots[0]).isSameOrAfter(virtualDates.beginAt) && moment(slots[0]).isSameOrBefore(virtualDates.endAt) && exponentFormat !== EVENT_FORMAT.PHYSICAL && (!isEditingMode || isEditingMode && settings.participationMode === EVENT_FORMAT.VIRTUAL)) {
        const medium = !isEditingMode ? 'visio' : settings.medium;
        newTimeslots.forEach((slot) => {
          slot.medium = medium;
          slot._event = currentEvent._id;
          slot._keyMomentLink = {
            _keyMoment: {
              _id: currentEvent.keyMoments.jobfair.find(e => e.format === EVENT_FORMAT.VIRTUAL)._id
            },
            type: 'jobfair',
          }
        })
        setSettings({ ...settings, medium, participationMode: EVENT_FORMAT.VIRTUAL })
      }
      else {
        newTimeslots.forEach((slot) => {
          slot.medium = 'physical'
          const medium = 'physical';
          slot._event = currentEvent._id;
          slot._keyMomentLink = {
            _keyMoment: {
              _id: currentEvent.keyMoments.jobfair.find(e => e.format === EVENT_FORMAT.PHYSICAL)._id
            },
            type: 'jobfair',
          }
          setSettings({ ...settings, medium, participationMode: EVENT_FORMAT.PHYSICAL })
        })
      }
    }
    else if (currentEvent.format === EVENT_FORMAT.HYBRID) {
      if (moment(slots[0]).isSameOrAfter(virtualDates.beginAt) && moment(slots[0]).isSameOrBefore(virtualDates.endAt) && exponentFormat !== EVENT_FORMAT.PHYSICAL && (!isEditingMode || (isEditingMode && settings.participationMode === EVENT_FORMAT.VIRTUAL))) {
        const medium = !isEditingMode ? 'visio' : settings.medium;
        newTimeslots.forEach((slot) => {
          slot.medium = medium
          setSettings({ ...settings, medium, participationMode: EVENT_FORMAT.VIRTUAL })
        })
      }
      else {
        newTimeslots.forEach((slot) => {
          slot.medium = 'physical'
          const medium = 'physical';
          slot._event = currentEvent._id;
          slot._keyMomentLink = {
            _keyMoment: {
              _id: currentEvent.keyMoments.jobfair.find(e => e.format === EVENT_FORMAT.PHYSICAL)._id
            },
            type: 'jobfair',
          }
          setSettings({ ...settings, medium, participationMode: EVENT_FORMAT.PHYSICAL })
        })
      }
    }
    const cleanTmpSlots = getSlotsCleaned(newTimeslots, [...tmpSlots, ...timeslots], eventKeyMoments, duration);
    setTmpSlots([...tmpSlots, ...cleanTmpSlots]);

    return true;
  }, 0);

  const getNotification = (messageIntl, kind, options = { emoji: '' }) => {
    const { emoji } = options;
    const space = ' ';
    const message = emoji ? [emoji, t(messageIntl)] : [t(messageIntl)];

    return {
      message: join(message, space),
      kind,
      style: {
        bottom: '5%',
        top: 'inherit',
      },
    };
  }

  const handleOnDeleteFreeSlot = (id) => {
    const callback = () => {
      setModalContext({ ...modalContext, isVisible: false });
      analyticsRecruiterDeleteSlots({ authUser, event: currentEvent, isAllSlots: false });
    };

    const params = {
      currentUser: exponentUserQuery?.data?._user,
      currentEvent,
      timeslots: [id],
      notificationParams: {
        success: getNotification({ id: 'toaster.edit.success' }, 'info', { emoji: '🙌 ' }),
        error: true,
      },
      callback,
    };

    delUserTimeslots(params);
  }

  const handleOnDeleteTmpSlot = (eventToDelete) => {
    const newSlots = tmpSlots
      .filter((timeslot, index) => index !== eventToDelete.groupIndex || timeslot.allDay === true);
    setTmpSlots(newSlots);
  }


  const resizeEvent = (resizeType, { event, start, end }) => {
    const nextEvents = calendarEvents.map((existingEvent) => existingEvent.id === event.id
      ? { ...existingEvent, start, end }
      : existingEvent);

    setCalendarEvents(nextEvents);
  }

  const handleSelectRecruiter = (value, evt, showWarningModal = true) => {
    if (!isEmpty(tmpSlots) && showWarningModal) {
      trackRecruiterClickedQuitCreatingSlots({ authUser, event: currentEvent });
      setModalContext({
        isVisible: true,
        context: 'quitWithoutSaving',
        onSubmit: () => {
          handleSelectRecruiter(value, evt, false);
          setModalContext({ ...modalContext, isVisible: false });
        },
      });
    } else {
      setSelectedUserId(value);
    }
  }

  const handleSelectEvent = (value, evt, showWarningModal = true) => {
    if (!isEmpty(tmpSlots) && showWarningModal) {
      trackRecruiterClickedQuitCreatingSlots({ authUser, event: currentEvent });
      setModalContext({
        isVisible: true,
        context: 'quitWithoutSaving',
        onSubmit: () => {
          handleSelectEvent(value, evt, false);
          setModalContext({ ...modalContext, isVisible: false });
        },
      });
    } else {
      const event = currentExponentUserEvents.find((e) => getId(e) === value);
      const eventDefaultSlotType = getEventDefaultSlotType(event);
      const currentDate = moment();
      let week = 0;
      changeEventKeyMoment(exponentFormat)

      if (event) {
        const isExponentHybrid = exponentFormat === EVENT_FORMAT.HYBRID;
        const physicalPeriod = event.weeks.physical;
        const virtualPeriod = event.weeks.virtual;
        let isBetweenTwoKeymoment = false;
        let furthestFormat = virtualPeriod;
        let keyMomentPhysical;
        let keyMomentVirtual;
        if (isExponentHybrid && event.format === EVENT_FORMAT.HYBRID) {
          keyMomentPhysical = event.keyMoments.jobfair.find(keyMoment => keyMoment.format === 'physical')
          keyMomentVirtual = event.keyMoments.jobfair.find(keyMoment => keyMoment.format === 'virtual')
          if (physicalPeriod && virtualPeriod) {
            furthestFormat = moment(physicalPeriod[0].beginAt).isAfter(moment(virtualPeriod[0].beginAt)) ? physicalPeriod : virtualPeriod;
          }
          // (!isBetweenPhysical && !isBetweenVirtual && one format has begun)
          isBetweenTwoKeymoment = !(moment(keyMomentPhysical.beginAt) <= currentDate && moment(keyMomentPhysical.endAt) >= currentDate) &&
            !(moment(keyMomentVirtual.beginAt) <= currentDate && moment(keyMomentVirtual.endAt) >= currentDate) &&
            (currentDate >= moment(keyMomentPhysical.beginAt) || currentDate >= moment(keyMomentVirtual.beginAt))
        }

        if (event.weeks[isExponentHybrid ? 'all' : exponentFormat]) {
          week = event.weeks[isExponentHybrid ? 'all' : exponentFormat].findIndex((week) => {
            if (isBetweenTwoKeymoment) {
              return furthestFormat.filter(w => w.beginAt === week.beginAt).length > 0;
            }

            return (
              moment(week.beginAt) <= currentDate && moment(week.endAt) >= currentDate
            )

          });
          if (week === -1) {
            week = 0;
          }
        }
        setCurrentEvent(event)

        setCurrentWeek(week);
        setSettings({
          ...settings,
          location: event && (event.format === 'physical' || event.format === 'hybrid') && exponentUserQuery?.data?.location,
          medium: event && (event.format === 'physical' || event.format === 'hybrid') ? 'physical' : 'phone',
          isMultiEvents: false,
          type: eventDefaultSlotType,
        });
      }
    }
  };

  const handleOnSettingsChange = (inputName, val) => {
    switch (inputName) {
      case 'duration': return handleOnDurationChange(val);
      case 'medium': return handleOnMediumChange(val);
      case 'isMultiEvents': return handleOnCheckSpecificEvent(val);
      case 'location': return handleOnLocationChange(val);
      case 'informalDescription': return handleOnInformalDescriptionChange(val);
      case 'type': return handleOnTypeChange(val);
      case 'participationMode': return handleOnParticipationModeChange(val);

      default: return null;
    }
  }

  const handleOnSubmit = () => {
    setIsSubmitLoading(true);
    let tmpSlotsWithFixedLocation = null
    if (tmpSlots[0]._event === null) {
      tmpSlots.forEach((slot) => {
        delete slot._keyMomentLink;
      })
    }
    if (tmpSlots[0].medium !== EVENT_FORMAT.PHYSICAL) {
      tmpSlots.forEach((slot) => {
        delete slot.location;
      })
    } else {
      tmpSlotsWithFixedLocation = tmpSlots.map((slot) => ({
        ...slot, location: currentEvent.location?.formattedAdress
      }))
    }
    const callback = () => {
      setIsSubmitLoading(false);
      setTmpSlots([])
      setFormatDefineByTimeslot();
      setIsEditingMode(false);
      eventsSelectQuery.refetch();

      analyticsRecruiterCreatedSlots({
        authUser,
        currentEvent,
        currentExponentUserEvents,
        settings,
        selectedUser: selectedUserId,
        currentUserInExponent: exponentUserQuery?.data,
        newTimeslots: tmpSlotsWithFixedLocation ?? tmpSlots,
      });
    };

    debounceFunc(
      () => {
        postUserTimeslots({
          currentUser: exponentUserQuery?.data?._user,
          timeslots: [...tmpSlots],
          notificationParams: {
            success: getNotification(
              { id: 'event.recruiter.preparation.timeslots.slot.notification.success.add' },
              'info',
              { emoji: '🙌 ' }),
            error: true,
          },
          callback,
        });
      }
    );
  }

  const handleOnCheckSpecificEvent = (evt) => {
    const isMultiEvents = !evt.target.checked;
    const _event = isMultiEvents ? null : currentEvent._id;
    const newTimesSlots = tmpSlots
      .filter((slot) => isEmpty(slot._id))
      .map((slot) => ({ ...slot, _event }));

    if (!isMultiEvents && currentEvent.format === EVENT_FORMAT.HYBRID) {
      newTimesSlots.forEach((slot) => {
        slot._keyMomentLink = {
          _keyMoment: {
            _id: currentEvent.keyMoments.jobfair.find(e => e.format === EVENT_FORMAT.VIRTUAL)._id
          },
          type: 'jobfair',
        }
      })
    }

    setSettings({ ...settings, isMultiEvents });

    setTmpSlots(newTimesSlots);
  }

  const handleDeleteEvent = (event) => {
    if (event.isSaved) {
      setModalContext({
        isVisible: true,
        context: 'deleteSlot',
        onSubmit: () => {
          handleOnDeleteFreeSlot(getId(event));
        },
      });
    } else {
      handleOnDeleteTmpSlot(event);
    }
  }

  const handleOpenSetting = (participationMode) => {
    let formatOngoing = participationMode;
    const currentDate = moment();
    if (participationMode === EVENT_FORMAT.HYBRID) {
      if (moment(getEventKeyMoments(currentEvent, { type: 'jobfair', format: EVENT_FORMAT.PHYSICAL }).endAt) < currentDate) {
        formatOngoing = EVENT_FORMAT.VIRTUAL;
      }
      else {
        formatOngoing = EVENT_FORMAT.PHYSICAL;
      }
    }
    const medium = formatOngoing === EVENT_FORMAT.PHYSICAL ? 'physical' : 'visio';
    setSettings({ ...settings, participationMode: formatOngoing, medium })
    setIsEditingMode(!isEditingMode);
    analyticsRecruiterClickedCreatedSlots({ authUser, clickedButton: true, event: currentEvent });
  }

  if (!currentEvent) {
    return null;
  }

  if (authUser.timezone) { moment.tz.setDefault(authUser.timezone.utc[0]); } else { moment.tz.setDefault(); }
  // Define currentDate base on the week selected
  const currentDate = currentEvent
  && currentWeek !== null
  && currentEvent?.weeks?.[exponentFormat === EVENT_FORMAT.HYBRID ? 'all' : exponentFormat]?.[currentWeek] ?
    moment(currentEvent?.weeks?.[exponentFormat === EVENT_FORMAT.HYBRID ? 'all' : exponentFormat]?.[currentWeek]?.beginAt).add(10, 'hours').toDate()
    : moment(currentEvent?.weeks?.[exponentFormat === EVENT_FORMAT.HYBRID ? 'all' : exponentFormat]?.[0]?.beginAt).add(10, 'hours').toDate();


  const hasJdStarted = currentEvent.keyDates || currentEvent.keyMoments ? moment().isAfter(eventKeyMoments?.beginAt) : false;

  const intlMoment = moment;

  intlMoment.locale(intl.locale === 'en' ? 'en' : intl.locale, {
    week: {
      dow: 1, // Monday is the first day of the week.
    },
  });

  const handleSettingSlotCancel = () => {
    if (!isEmpty(tmpSlots)) {
      setModalContext({ isVisible: true, context: 'cancelEditing' })
    }
    else {
      setIsEditingMode(false);
    }
  }

  return (
    <div className={styles.calendarContainer} id='calendarContainer'>
      {openModalFormatNotAvailable &&
        <ModalFormatNotAvailable
          event={currentEvent}
          onCancel={() => setOpenModalFormatNotAvailable(false)}
          onOk={() => setOpenModalFormatNotAvailable(false)}
          isOpen={openModalFormatNotAvailable}
          authUser={authUser}
        />
      }
      <div className={styles.selectsContainer} id="selectsContainer">
        {context === 'calendar' && (
          <div className={classnames(styles.eventsList, isSelectEventFocus ? styles.zIndex2 : '')}>
            <div id="events">
              <SelectEvent
                defaultValue={!isEmpty(eventsSelectQuery?.data?.docs) ? currentEvent?._id : undefined}
                value={!isEmpty(eventsSelectQuery?.data?.docs) ? currentEvent?._id : undefined}
                placeholder={t({ id: 'selectEvent' })}
                onSelect={handleSelectEvent}
                events={eventsSelectQuery?.data?.docs}
                exponentFormat={exponentFormat}
                filterOption={(input, option) => option.props.title && option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onFocus={() => setIsSelectEventFocus(true)}
                onBlur={() => setIsSelectEventFocus(false)}
              />
            </div>
          </div>
        )}
        <div className={classnames(styles.userContainer, hasJdStarted && context === 'jobdating' ? styles.userContainerOnModal : '')}>
          {/* recruiter */}
          <SelectRecruiter
            handleSelectRecruiter={handleSelectRecruiter}
            users={usersSelectQuery?.data?.docs}
            selectedUser={selectedUserId}
            currentEvent={currentEvent}
            displayOptionAll={false}
          />
        </div>
      </div>
      <hr />
      {!exponentUserQuery?.isLoading && currentDate && currentExponentUserEvents?.length ? (
        <>
          <TimestampAlert
            authUser={authUser}
            currentUserInExponent={exponentUserQuery?.data}
          />
          <If condition={isOpen || window.location.href.indexOf('recruiter/preparation') > -1}>
            <Row type="flex" className={styles.rbcContainer} gutter={24}>
              <Col className={isEditingMode ? styles.settingsSlotColumnActive : styles.settingsSlotColumn}>
                <SettingsSlots
                  style={{ maxWidth: '360px', position: "relative" }}
                  authUser={authUser}
                  isEditingMode={isEditingMode}
                  currentUserInExponent={exponentUserQuery?.data}
                  handleOnSettingsChange={handleOnSettingsChange}
                  handleOnSubmit={handleOnSubmit}
                  isSubmitLoading={isSubmitLoading}
                  settings={settings}
                  timeslots={tmpSlots}
                  countEventAvailable={eventsSelectQuery?.data?.total}
                  currentEvent={currentEvent}
                  postAction={postAction}
                  handleOnCancel={handleSettingSlotCancel}
                  exponentFormat={exponentFormat}
                  displayFormatTypeSetting={displayFormatTypeSetting}
                />
              </Col>

              <Col style={{ flex: 1 }}>
                <BigCalendar
                  indexWeek={currentWeek}
                  currentEvent={currentEvent}
                  handleWeekChange={setCurrentWeek}
                  handleDeleteTimeSlots={() => setModalContext({ isVisible: true, context: 'deleteAllSlots' })}
                  events={calendarEvents ?
                    [...calendarEvents, ...createAllCalendarEvents({ slots: tmpSlots, currentEvent })]
                    : [...createAllCalendarEvents({ slots: tmpSlots, currentEvent })]
                  }
                  timezone={authUser.timezone ? authUser.timezone.utc[0] : null}
                  authUser={authUser}
                  jobfairs={currentExponentUserEvents}
                  handleOpenSetting={handleOpenSetting}
                  isSettingsVisible={isEditingMode}
                  onSelectSlot={handleOnSelectSlot}
                  currentDate={currentDate}
                  locale={authUser.locale}
                  handleEventResize={resizeEvent}
                  handleDeleteEvent={handleDeleteEvent}
                  informalDescription={settings.informalDescription}
                  handleOnInformalDescriptionChange={handleOnInformalDescriptionChange}
                  exponentFormat={exponentFormat}
                  eventKeyMoments={eventKeyMoments}
                  changeEventKeyMoment={changeEventKeyMoment}
                />
              </Col>
            </Row>
          </If>
        </>
      ) : (
        <>
          {
            exponentUserQuery?.isLoading ? (<LoadingIndicator />) : (
              <BlankState
                className={styles.blankState}
                icon="blank-state-cactus"
                title={
                  <strong>
                    {t({ id: 'calendar.slot.blankExponentUser' }, { recruiter: selectedUser?.fullName  })}
                  </strong>
                }
              />)
          }
        </>)
      }
      <Modal
        visible={modalContext.isVisible}
        footer={false}
        maskClosable
        width={680}
        className="customConfirm"
        onCancel={() => { }}
      >
        <h4 style={{ fontWeight: 900, fontSize: '24px' }}>
          {getModalProperty().text}
        </h4>

        {getModalProperty().helpText && (
          <p className={styles.modalHelpText}>{getModalProperty().helpText}</p>
        )}

        <div className="confirm-actions" style={{ marginTop: '40px' }}>
          <Button className="mr-6" variant="tonal" color="neutral" onClick={() => setModalContext({ ...modalContext, isVisible: false })}>
            {getModalProperty().okCta || t({ id: 'cancel' })}
          </Button>
          <Button
            color="error"
            onClick={(e) => {
              setTmpSlots([]);
              getModalProperty().onSubmit(e);
            }}>
            {getModalProperty().cancelCta || t({ id: 'btn.confirm' })}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

Calendar.propTypes = {
  authUser: object,
  currentEvent: object,
  setCurrentOption: func,
  postUserTimeslots: func,
  delUserTimeslots: func,
  events: array,
  timeslots: array,
  intl: object,
  isOpen: bool,
  context: string,
  sendNotification: func,
  postAction: func,
};

const mapDispatchToProps = {
  delUserTimeslots: timeslotActions.delUserTimeslots,
  postUserTimeslots: timeslotActions.postUserTimeslots,
  modifyAppointment: appointmentActions.modifyAppointment,
  patchExponent: exponentActions.patchExponent,
  getExponent: exponentActions.getExponent,
  patchUserTimeslotsDuration: timeslotActions.patchUserTimeslotsDuration,
  sendNotification: notificationActions.sendNotification,
  postAction: actionActions.postAction,
};

const withConnect = connect(null, mapDispatchToProps);

export default compose(
  withConnect,
  injectIntl,
)(toJS(Calendar));
