import { requestFile } from '@/utils/request';
import { objectToParams } from '@/utils/url';


const getStatsRequest = async ({ requestName, authToken, ...params }) => (
  requestFile(getRequestUrl({ requestName, ...params }))
);


const getRequestUrl = ({ requestName, organization, period = {}, eventId, type, slug }) => {
  const params = objectToParams({ ...period, organization, eventId, type });

  switch (requestName) {
    case 'offers': return `${process.env.FRONT_GO_API_URL}/stats/offers?${params}`;
    case 'lives': return `${process.env.FRONT_GO_API_URL}/stats/lives?${params}`;
    case 'activities': return `${process.env.FRONT_GO_API_URL_OLD}/events/${slug}/exponentsUsersActivities?apiKey=41a1289e-fee1-430e-a849-5b2b7ed23f1a&${params}`;
    case 'exponentUsers': return `${process.env.FRONT_GO_API_URL}/stats/exponentUsers?${params}`;
    case 'events': return `${process.env.FRONT_GO_API_URL}/stats/events?${params}`;
    case 'conversionDistribution': return `${process.env.FRONT_GO_API_URL}/stats/conversion/distribution?${params}`;
    case 'conversionLives': return `${process.env.FRONT_GO_API_URL}/stats/conversion/lives?${params}`;
    case 'conversionOffers': return `${process.env.FRONT_GO_API_URL}/stats/conversion/offers?${params}`;
    case 'conversion': return `${process.env.FRONT_GO_API_URL}/stats/conversion?${params}`;
    case 'interview': return `${process.env.FRONT_GO_API_URL}/stats/interview?${params}`;

    default: return '';
  }
};


const getExportUrl = ({ requestName, ...params }) =>
  window.open(`${process.env.FRONT_EXPORT_API_URL}/apps/export?${objectToParams({ url: btoa(getRequestUrl({ requestName, ...params })) })}`, '_blank');

export {
  getExportUrl,
  getStatsRequest,
  getRequestUrl,
};
