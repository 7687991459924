import React, { useEffect, useState } from 'react'
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { string, func, object } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { filter, isEmpty } from 'lodash';
import { useFormatMessage } from 'react-intl-hooks'
import { H4, H6, IconSettings, Body1, Caption1, Button } from '@seekube-tech/ui-kit'
import classnames from 'classnames';
import Table from '@/components/Table';
import { highlightText } from '@/utils/text';
import { toJS } from '@/utils';
import { getId, getIds } from '@/utils/global';

import { authSelectors } from '@/store/auth';
import { userActions } from '@/store/user';
import { organizationActions } from '@/store/organization';
import { eventActions, eventSelectors } from '@/store/event';

import { Bloc, BlocHeader } from '@/components/Bloc';
import Radio, { RadioGroup } from '@/components/Radio';

import { track } from '@/utils/analytics';
import { ACL, ANALYTICS_ORGA } from '@/utils/constants';
import { getColumns, getDataSource } from './components';
import Recruiters from '../../../../../OnBoarding/containers/Dashboard/components/Recruiters';
import { AddLogo } from '../../../../../OnBoarding/containers/Dashboard/components/OrganizationColumn/components';
import CollaboratorModal from '../../../../../OnBoarding/containers/CollaboratorModal';
import ExponentModal from '../../../../../OnBoarding/containers/ExponentModal';

import styles from './styles.less';



const Planners = ({
  event,
  patchEvent,
  getEvent,
  getUsersOrganization,
  patchOrganization,
  authToken,
  authUser,
}) => {
  const [modalCollaboratorIsOpen, setModalCollaboratorIsOpen] = useState(false);
  const [isAvatarModalOpen, setIsAvatarModalOpen] = useState(false);
  const [modalExponentIsOpen, setModalExponentIsOpen] = useState(false);
  const [organizationSelected, setOrganizationSelected] = useState(null);
  const [currentOrganizationToChangeLogo, setCurrentOrganizationToChangeLogo] = useState(null);
  const [ownerOpeningEmailIsEnable, setOwnerOpeningEmailIsEnable] = useState(false);
  const t = useFormatMessage();

  const preventExpand = (event) => event.stopPropagation();

  useEffect(() => {
    setOwnerOpeningEmailIsEnable(event.ownerOpeningEmailIsEnable)
  }, [event])

  const openCollaboratorModal = (organization) => {
    getUsersOrganization({ organizationId: organization._id });

    setModalCollaboratorIsOpen(true);
    setOrganizationSelected(organization);
  };

  const toggleExponentModal = () => setModalExponentIsOpen(!modalExponentIsOpen);


  const handlePlannerActions = (type, exponent) => {
    switch (type) {
      case 'onCreateUser' : openCollaboratorModal(exponent); break;
      case 'openAvatar' : handleOnOpenAvatarModal(exponent); break;
      default: break;
    }
  };

  const inviteCollaborator = (userId) => {
    patchEvent({
      params: {
        type: 'invite-planner',
        userId,
      },
      notificationParams: {
        success: {
          message: t({ id: 'toasters.inviteSent.success' }),
        },
      },
    });
  };

  const removePlanner = (organization) => {
    const planners = {
      _users: filter(_users, (user) => !getIds(user._organizations).includes(getId(organization))),
      _organizations: filter(_organizations, (orga) => orga._id !== getId(organization)),
    };

    patchEvent({
      event: { planners },
      notificationParams: {
        success: {
          message: t({ id: 'notifications.update.success' }),
          kind: 'info',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        },
      }
    });
  };

  const sendMailOrga = () => {

    patchEvent({
      eventId: event._id,
      event: { ownerOpeningEmailIsEnable },
      notificationParams: {
        success: {
          message: t({ id: 'notifications.update.success' }),
          kind: 'info',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        },
      }
    });
  };

  const removeUserPlanner = (userId) => {
    const planners = { ...event.planners, _users: filter(event.planners._users, (user) => getId(user) !== getId(userId)) };

    patchEvent({
      event: { planners },
      notificationParams: {
        success: {
          message: t({ id: 'notifications.update.success' }),
          kind: 'info',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        },
      }
    });
  };

  const handleRecruiterAction = (type, exponentUser) => {
    switch (type) {
      case ACL.REMOVE_USER_FROM_EXPONENT : removeUserPlanner(exponentUser._id); break;
      case 'inviteCollaborator' : inviteCollaborator(exponentUser._id); break;
      default: break;
    }
  };

  const handleOnOpenAvatarModal = (organization) => {
    setCurrentOrganizationToChangeLogo(organization);
    setIsAvatarModalOpen(true);
  };

  const toggleReportings = (e) => {
    const checked = e.target.value;
    if (event.keyDatesOk) {
      patchEvent({
        event: {
          modules: {
            ...event.modules,
            reports: {
              ...event.modules.reports,
              enable: e.target.value,
            },
          },
        },
        callback: () => {
            track({
              name: checked ? ANALYTICS_ORGA.ENABLE_REPORTINGS : ANALYTICS_ORGA.DISABLE_REPORTINGS,
              user: authUser,
              event,
              properties: {
                page: 'settings/basicInformations'
              }
            })
          }
      })
    }
  }

  const { planners: { _organizations, _users }, slug } = event;

  return (
    <Bloc>
      <BlocHeader className="mb-20">
        <H4>{t({ id: 'event.owner.planner' })}</H4>
        <Button
          onClick={toggleExponentModal}
        >
          {t({ id: 'event.owner.planner.add' })}
        </Button>
      </BlocHeader>
      <div className={styles.plannersTable}>
        {modalCollaboratorIsOpen &&
          <CollaboratorModal
            context="owner_settings"
            organization={organizationSelected}
            isOpen={modalCollaboratorIsOpen}
            onCancel={() => setModalCollaboratorIsOpen(false)}
            onOk={() => setModalCollaboratorIsOpen(false)}
          />
        }
        <AddLogo
          isOpen={isAvatarModalOpen}
          record={{ _organization: currentOrganizationToChangeLogo, _event: event }}
          authToken={authToken}
          patchOrganization={patchOrganization}
          onClose={() => setIsAvatarModalOpen(false)}
          callback={() => getEvent(slug)}
        />
        {_organizations &&
          <Table
            columns={getColumns()}
            showHeader={false}
            pagination={false}
            rowClassName={styles.tableRow}
            className={styles.plannerTable}
            dataSource={getDataSource(
              _organizations,
              _users,
              '',
              highlightText,
              {
                actions: {
                  openCollaboratorModal,
                  removePlanner,
                  preventExpand,
                  patchOrganization,
                  handleExponentActions: handlePlannerActions,
                  handleOnOpenAvatarModal,
                },
              })}
            expandedRowRender={(record, i) => <Recruiters organization={record.organization} users={record.users} handleRecruiterAction={handleRecruiterAction} key={i} />}
            expandRowByClick
          />}
      </div>
      {!isEmpty(event.planners._organizations) &&
        <div className={styles.reporting}>
          <div>
            <div className={styles.heading}>
              <IconSettings size="16" className="fill-neutral-300 mr-6" />
              <H6>
                {t({ id: 'owner.planner.reporting.title' })}
              </H6>
            </div>
            {authUser.isAdmin &&
              <div className={classnames(styles.mb18)}>
                <Body1>
                  {t({ id: 'owner.planner.reporting.activate.title' })}
                </Body1>
                <Body1 className="text-neutral-300">
                  {t({id: 'owner.planner.reporting.activate.subtitle'})}
                </Body1>

                <RadioGroup
                  value={event.modules?.reports?.enable}
                  defaultValue={event.modules?.reports?.enable}
                  className={classnames(styles.mt18)}
                  disabled={!event.keyDatesOk}
                  onChange={toggleReportings}
                  name="1"
                >
                  <Radio value>
                    {t({id: 'yes'})}
                  </Radio>
                  <Radio value={false}>
                    {t({id: 'no'})}
                  </Radio>
                </RadioGroup>
                {
                  !event.keyDatesOk &&
                  <Caption1 className="text-warning-500" style={{marginBottom: '10px'}}>
                    {t({id: 'owner.reporting.warning.no.retroPlanning'})}
                  </Caption1>
                }
              </div>
            }
            <Body1 className="text-neutral-500">
              {t({ id: 'owner.planner.mail.activate.title' })}
            </Body1>
            <Body1 className="text-neutral-300">
              {t({ id: 'owner.planner.mail.activate.subtitle' })}
            </Body1>
            <RadioGroup
              value={ownerOpeningEmailIsEnable}
              className={classnames(styles.my18)}
              onChange={() => setOwnerOpeningEmailIsEnable(!ownerOpeningEmailIsEnable)}
              name="1"
            >
              <Radio
                checked={ownerOpeningEmailIsEnable && event.modules?.reports?.enable === true}
                value>
                {t({ id: 'yes' })}
              </Radio>
              <Radio
                checked={!ownerOpeningEmailIsEnable}
                value={false}>
                {t({ id: 'no' })}
              </Radio>
            </RadioGroup>
          </div>
          <Button type="submit" onClick={sendMailOrga}>
            {t({ id: 'save' })}
          </Button>
        </div>}
      <ExponentModal
        isOpen={modalExponentIsOpen}
        onCancel={toggleExponentModal}
        onOk={toggleExponentModal}
        context="withRecruiters"
      />
    </Bloc>
  );
}

Planners.propTypes = {
  event: object,
  patchEvent: func,
  getEvent: func,
  getUsersOrganization: func,
  patchOrganization: func,
  authToken: string,
  authUser: object,
}

const mapStateToProps = createStructuredSelector({
  event: eventSelectors.getCurrentEvent,
  authToken: authSelectors.getAuthToken,
  authUser: authSelectors.getAuthUser,
});

const mapDispatchToProps = {
  getEvent: eventActions.getEvent,
  getUsersOrganization: userActions.getUsersOrganization,
  patchEvent: eventActions.patchEvent,
  patchOrganization: organizationActions.patchOrganization,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  injectIntl,
)(toJS(Planners));
