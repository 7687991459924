import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { object, string } from 'prop-types';

import styles from './styles.less';
import { EVENT_FORMAT } from '@/utils/constants';
import { useFormatMessage } from 'react-intl-hooks';

const WeekHeader = ({ date, exponentFormat, event }) => {
  /* Vars */

  const t = useFormatMessage();
  const [columnWidth, setColumnWidth] = useState();
  const [tagType, setTagType] = useState();

  const day = moment(date).format('dddd');
  const dateOfMonth = moment(date).format('DD');

  const ref = useRef();
  const expectedWidth = ref?.current?.getBoundingClientRect().width || 0;

  useEffect(() => {
    if(ref.current) {
      setColumnWidth(expectedWidth)
    }
  }, [ref.current])

  useEffect(() => {
    if (event.format === EVENT_FORMAT.HYBRID && exponentFormat === EVENT_FORMAT.HYBRID) {
      const datesPhysical = event.keyMoments.jobfair[0];
      const datesVirtual = event.keyMoments.jobfair[1];
      if (moment(date).isSameOrAfter(moment(datesPhysical.beginAt), 'day') && moment(date).isSameOrBefore(moment(datesPhysical.endAt), 'day')) {
        if (moment(date).isSameOrAfter(moment(datesVirtual.beginAt), 'day') && moment(date).isSameOrBefore(moment(datesVirtual.endAt), 'day')) {
          setTagType(EVENT_FORMAT.HYBRID)
        }
        else {
          setTagType(EVENT_FORMAT.PHYSICAL)
        }
      }
      else if (moment(date).isSameOrAfter(moment(datesVirtual.beginAt), 'day') && moment(date).isSameOrBefore(moment(datesVirtual.endAt), 'day')) {
        setTagType(EVENT_FORMAT.VIRTUAL)
      }
    }
  }, [event, exponentFormat])

  /* Render */
  return (
    <div className={styles.agendaHeader} ref={ref}>
      <div>
        <span className={styles.day}>{day} </span> <span className={styles.dateOfMonth}>{dateOfMonth}</span>
      </div>

      {event.format === EVENT_FORMAT.HYBRID && exponentFormat === EVENT_FORMAT.HYBRID && tagType &&
        <div className={styles.tagContainer} style={{width: columnWidth || 200 }}>
          {tagType === EVENT_FORMAT.HYBRID ?
            <div className={styles.tagWrapper}>{t({ id:'event.owner.exponents.input.participationMode.optionHybrid'})}</div>
            :
            <div className={styles.tagWrapper}>{t({ id:`exponent.changeView.${tagType}` })}</div>
          }
        </div>
      }
    </div>
  );
}

WeekHeader.propTypes = {
  date: string,
  exponentFormat: string,
  event: object,
};

export default WeekHeader;
