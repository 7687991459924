import React from 'react';
import { compose } from 'redux';
import { Body1, Button, H4 } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { withLayout } from '@/containers/Layout';
import { toJS } from '@/utils';
import Wrapper from '@/components/Wrapper';
import { Bloc } from '@/components/Bloc';
import styles from './styles.less';
import MobileHeader from '@/containers/Layout/containers/MobileHeader';
import { getAuthUser } from '@/store/auth/selectors';

const NoShowRedirect = () => {
  const t = useFormatMessage();
  const authUser = useSelector(getAuthUser)?.toJS();

  return (
    <div className={styles.containerTop}>
      <Wrapper size="large">
        <Bloc maxWidth={500}>
          <div className={styles.noShowContainer}>
            <div className={styles.noShowTitle}>
              <H4>{t({ id: 'appointment.feedback.success.title' })}</H4>
              <Body1 className={styles.noShowSubtitle}>{t({ id: 'appointment.feedback.success.subtitle' })}</Body1>
              {authUser &&
                <Link to="/">
                  <Button className={styles.titleButton}>{t({ id: 'goback' })}</Button>
                </Link>
              }
            </div>
          </div>
        </Bloc>
      </Wrapper>
      <MobileHeader />
    </div>
  )
}

export default compose(
  withLayout(),
)(toJS(NoShowRedirect));