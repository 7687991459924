import { Button } from '@seekube-tech/ui-kit';
import React, { useState } from 'react';
import uuidv1 from 'uuid/v1';
import { notification, Upload } from 'antd';
import mime from 'mime-types';
import { isEmpty, isUndefined } from 'lodash';
import slugify from 'slugify';
import request from '@/utils/request';

import styles from './styles.less';
import Icon from '@/components/Icon';
import requestExternal from '@/utils/requestExternal';



const { Dragger } = Upload;

const SmartApply = ({history}) => {

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [mail, setMail] = useState('');
  const [phone, setPhone] = useState('');
  const [gender, setGender] = useState("1");
  const [workPermit, setWorkPermit] = useState('5c53258492a04c00613682e2')
  const [experience, setExperience] = useState('5ba35b6eac2018003364104f')

  const [user, setUser] = useState({});
  const [authToken, setAuthToken] = useState();

  const [file, setFile] = useState();
  const [uploadUrl, setUploadUrl] = useState();
  const [resumeUrl, setResumeUrl] = useState();
  const [resumeTitle, setResumeTitle] = useState();
  const [uploadingResume, setUploadingResume] = useState();

  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleSubmitUser = (e) => {
    e.preventDefault();
    const data = {
      eventId: location.query.eventId,
      username: mail,
      firstName,
      lastName,
      phone,
      gender,
    }

    const url = `${process.env.FRONT_API_URL}/users/smartapply`
    const options = {
      method: 'POST',
      body:  JSON.stringify(data),
    }
    request(url, options)
      .then((data) => {
        setUser(data.user);
        setAuthToken(data.token)
      })
      .catch((error) => {
        history.push('smartApply/error')
        setIsError(true)
      });
  }

  const generateFileName = (file, user) => {
    const ext = file.name ? file.name.substr(file.name.lastIndexOf('.') + 1) : mime.extension(file.type);

    const fileName = `${slugify(user.fullName)}-${uuidv1()}.${ext}`;

    try {
      return new File([file], fileName, { type: file.type });
    } catch (err) {
      history.push('smartApply/error')
      setIsError(true)
    }
  };

  const askAWSUrl = (file) => {
    
    if (file && file.size > 2000000) {
      notification.error('trop lourd');
      
      return false;
    }
    
    const newFile = generateFileName(file, {fullName: 'test'});
    setFile(file)
    setResumeTitle(file.name)

    return new Promise((resolve, reject) => {
      request(`${process.env.FRONT_API_URL}/users/${user._id}/upload?fileName=${newFile.name}`)
        .then((result) => {
          if (newFile.type.includes('pdf')) {
            setUploadUrl(result.signedRequest)
            setUploadingResume(result.url)
            resolve()
          } else {
            reject(notification.error('dommage'));
          }
        })
        .catch((e) => {
          history.push('smartApply/error')
          setIsError(true)
        });
    });
  };

  const uploadFile = ({ action }) => {
    const newFile = file;
    requestExternal(action, {
      method: 'PUT',
      headers: {
        'Content-Type': newFile.type,
      },
      body: newFile,
    }).then(() => {
        if (newFile.type.includes('pdf')) {
          setResumeUrl(uploadingResume)
          setUploadingResume(null)
          setUploadUrl(null)
        } else {
          console.log('error')
        }
      })
      .catch(() => {
        history.push('smartApply/error')
        setIsError(true)
      });
  };

  const handleFinalSubmit = (e) => {
    e.preventDefault()

    const data = {
      userId: user._id,
      workPermit,
      experience,
    }
    const url = `${process.env.FRONT_API_URL}/events/${location.query.eventId}/offers/${location.query.offerId}/applyWithSmartapply`
    const options = {
      method: 'POST',
      body: JSON.stringify(data),
    }
    const dataPatch = {
      resumeTitle,
      resumeUrl,
    }
    const urlBis = `${process.env.FRONT_API_URL}/users/${user._id}`
    const optionsBis = {
      method: 'PATCH',
      body: JSON.stringify(dataPatch),
    }
    request(urlBis, optionsBis)
      .then(() => {
        request(url, options)
          .then((data) => {
            if (data) {
              setIsSuccess(true)
              history.push('smartApply/success')
            }
          })
          .catch((error) => {
            history.push('smartApply/error')
            setIsError(true)
          });
      })
      .catch((error) => {
        history.push('smartApply/error')
        setIsError(true)
      });
  }

  if (isError) {
    return (
      <div>
        Error
      </div>
    )
  }

  if (isSuccess) {
    return (
      <div>
        Success
      </div>
    )
  }

  return (
    <div className={styles.smartApply}> 
      <form onSubmit={(e) => handleSubmitUser(e)}>
        <label>
          First Name:
          <input type="text" name='firstName' value={firstName} onChange={(e) => setFirstName(e.target.value)} />
        </label>
        <label>
          Last Name:
          <input type="text" name='lastName' value={lastName} onChange={(e) => setLastName(e.target.value)} />
        </label>
        <label>
          Mail:
          <input type="email" name='email' value={mail} onChange={(e) => setMail(e.target.value)} />
        </label>
        <label>
          Phone:
          <input type="number" name='phone' value={phone} onChange={(e) => setPhone(e.target.value)} />
        </label>
        <label>
          Gender:
          <select value={gender} name='gender' onChange={(e) => setGender(e.target.value)}>
            <option value="1">Monsieur</option>
            <option value="2">Madame</option>
          </select>
        </label>
        <label>
          Work Permit:
          <select value={workPermit} name='workPermit' onChange={(e) => setWorkPermit(e.target.value)}>
            <option value="5c53258492a04c00613682e2">Non</option>
            <option value="5c53258492a04c00613682e3">Oui</option>
          </select>
        </label>
        <label>
          Experience:
          <select value={experience} name='experience' onChange={(e) => setExperience(e.target.value)}>
            <option value="5ba35b6eac2018003364104f">Etudiant</option>
            <option value="5ba35b8eac20180033641055">Jeune diplomé</option>
            <option value="5ba35b8eac20180033641054">1 an</option>
            <option value="5ba35b8eac20180033641053">2 ans</option>
            <option value="5ba35b8eac20180033641052">3 ans et plus</option>
          </select>
        </label>
        <input type="submit" value="Submit" />
      </form>
      {!isEmpty(user) &&
        <form onSubmit={(e) => handleFinalSubmit(e)}>
          <div className="dragContainer cvContainer">
            <Dragger
              name="file"
              multiple={false}
              accept=".pdf"
              action={uploadUrl}
              beforeUpload={askAWSUrl}
              customRequest={uploadFile}
            >
              <div className="ant-upload-drag-icon">
                {resumeUrl !== null && !isUndefined(resumeUrl) ? (
                  <Icon name="cat-cv" className="uploaded-icon" />
                ) : (
                  <Icon name="jobPosition" />
                )}
              </div>
              <div className="cvBtnAction">
                <Button size="small" className="cvBtn" variant="outline">
                  Upload a cv
                </Button>
              </div>
            </Dragger>
          </div>
          <input type="submit" value="Submit" />
        </form>
      }
    </div>
    
  )
}

export default SmartApply

