import { get, head, last } from 'lodash';
import {
  trackRecruiterCreatedSlots,
  trackRecruiterClickedCreatedSlots,
  trackRecruiterDeleteSlots,
} from '@/utils/analytics';
import { getId } from '@/utils/global';
import { getSlugsWhereSlotsAreAdded } from '@/components/Calendar/utils';

import moment from 'moment';


const analyticsRecruiterCreatedSlots = ({
  authUser,
  selectedUser,
  settings: { medium, duration, isMultiEvents },
  currentEvent,
  currentUserInExponent,
  newTimeslots,
  events = [],
}) => {
  const firstSlot = head(newTimeslots);
  const lastSlot = last(newTimeslots);
  const slugs = getSlugsWhereSlotsAreAdded({ slots: newTimeslots, events, currentEvent, isMultiEvents });

  return trackRecruiterCreatedSlots({
    authUser,
    event: currentEvent,
    currentUsernameInExponent: get(currentUserInExponent, '_user.username'),
    isOwner: selectedUser === getId(authUser),
    date: moment(firstSlot).format('DD MMM Y'),
    medium,
    duration,
    beginAt: moment(firstSlot.beginAt).format('HH:mm'),
    endAt: moment(lastSlot.beginAt).add(lastSlot.duration, 'minute').format('HH:mm'),
    isShared: slugs.length > 1,
    countEvents: slugs.length,
    slugs,
  });
};

export {
  analyticsRecruiterCreatedSlots,
  trackRecruiterClickedCreatedSlots as analyticsRecruiterClickedCreatedSlots,
  trackRecruiterDeleteSlots as analyticsRecruiterDeleteSlots,
};
