import React from 'react';
import { object, func, oneOf } from 'prop-types';
import { get, keys, isEmpty, size } from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Col, Row } from 'antd';
import { Button, Pagination, Tooltip, TooltipContent, TooltipTrigger } from '@seekube-tech/ui-kit';
import { toJS } from '@/utils/index';
import Separator from '@/components/Separator';
import { CardLive } from '@/components/CardLive';
import { CalendarLive } from '@/components/CalendarLive';
import SearchResultsStats from '@/components/SearchResultsStats';
import {
  BlankCactus
} from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Conferences/containers/ConferencesList/BlankCactus';
import SearchEngine from '@/scenes/Event/scenes/Candidate/scenes/JobDating/components/SearchEngine';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthUser } from '@/store/auth/selectors';
import moment from 'moment';
import { getId } from '@/utils/global';
import { postAction } from '@/store/action/actions';
import { useFormatMessage } from 'react-intl-hooks';
import { useHistory } from 'react-router-dom';


const ConferencesList = ({
  event,
  onSearch,
  conferencesQuery,
  listContext,
}) => {
  const t = useFormatMessage();
  const authUser = useSelector(getAuthUser)?.toJS();
  const history = useHistory();
  const dispatch = useDispatch()

  if (!event) return;

  const onActionClick = (actionType, conference) => {
    if (actionType === 'seeConference') {
      dispatch(postAction({
        actionParams: {
          name: 'CANDIDATE_VISIT_CONFERENCE',
          _user: getId(authUser),
          _organization: getId(conference._organization),
          _event: getId(conference._event),
          data: { conference: getId(conference._id) },
        },
        callback: () => window.open(`conference/${conference._id}`, '_blank')
      }))
    }
  };


  const getAccessLiveLink = (conference) => {
    return `${process.env.FRONT_API_URL}/conferences/${conference?._id}/join/${conference?.attendee?._id}`
  }

  const handleOnChangePagination = (page) => {
    onSearch({ page, offset: (page * conferencesQuery.data?.limit) - conferencesQuery.data?.limit });
    window.scrollTo(0, 0);
  }

  const onBlankStateClick = () => {
    history.push(`/${event.slug}/candidate/jobdating/conferences/discovery?page=1&archive=false`);
    onSearch({ page: 1, archive: false }, false);
  }

  return (
    <div className="layout-large">
      <Separator height={34} />

      {listContext === 'discovery' &&
        <SearchEngine
          event={event}
          onSearch={onSearch}
          context="conference"
          facetsConferences={conferencesQuery.data?.facets}
          calendarLive={<CalendarLive
            event={event}
            onSelect={onSearch}
            liveDates={conferencesQuery.data?.displayConferencesBeginAt}
          />}
        />}

      <SearchResultsStats
        intlId="live.pl"
        variant="full"
        count={size(conferencesQuery.data?.docs)}
        total={conferencesQuery.data?.countAll}
        style={{ marginBottom: '30px', color: '' }}
      />

      <div className="mb-32">
        <Row gutter={25} className="gutter-row">
          {
            conferencesQuery.data?.countAll > 0 && (
              <div className="hidden-mobile">
                <Col xs={12} sm={6} md={6} lg={4}>
                  <CalendarLive
                    event={event}
                    onSelect={onSearch}
                    liveDates={conferencesQuery.data?.displayConferencesBeginAt}
                  />
                </Col>
              </div>
            )
          }
          {
            isEmpty(conferencesQuery.data?.docs) && !conferencesQuery.loading && (
              <BlankCactus
                isConfirmedView={listContext === 'confirmed'}
                goToDiscovery={onBlankStateClick}
                total={conferencesQuery.data?.countAll}
              />)
          }
          {conferencesQuery.data?.docs?.map((conf) => (
            <Col xs={12} sm={6} md={6} lg={4} key={conf._id}>
              <CardLive
                variant="live"
                authUser={authUser}
                filterByTopicEnable={get(event, 'modules.live.filterByTopicEnable')}
                conference={conf}
                event={event}
                context="candidate"
                onCardClick={() => onActionClick('seeConference', conf)}
                actions={listContext === 'confirmed' && !conf.isArchive &&
                  <div className="pb-16 text-center">
                    <Tooltip>
                      <TooltipTrigger>
                        <Button
                          onClick={() => window.open(getAccessLiveLink(conf), '_blank')}
                          disabled={moment(conf.beginAt).subtract(15, 'minute').isAfter(moment())}
                        >
                          <FormattedMessage id="livecard.actions.goto" />
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        {moment(conf.beginAt).subtract(15, 'minute').isAfter(moment()) && t({ id: 'live.join.tooltip.title' })}
                      </TooltipContent>
                    </Tooltip>
                  </div>}
              />
            </Col>
          ))}
        </Row>
      </div>

      <div className="pagination-container">
        <Pagination
          className="flex justify-center"
          current={conferencesQuery.data?.page + 1}
          pageSize={conferencesQuery.data?.limit}
          total={conferencesQuery.data?.total}
          onChange={handleOnChangePagination}
        />
      </div>
    </div>
  );
}


ConferencesList.propTypes = {
  event: object,
  onSearch: func,
  listContext: oneOf(['confirmed', 'discovery']),
};

export default toJS(injectIntl(ConferencesList));
