const DEAL_NAME = {
  thematic_one_shot: 'thematic_one_shot',
  thematic_subscription: 'thematic_subscription',
  company_one_shot: 'company_one_shot',
  company_subscription: 'company_subscription',
  movie_party_one_shot: 'movie_party_one_shot',
  credit_subscription: 'credit_subscription',
  schoolpack_subscription: 'schoolpack_subscription', // deprecated (keep only for old deals)
}

const CANDIDATE_JD_VIEWS = {
  exponents: 'exponents',
  jobs: 'jobs',
  appointments: 'appointments',
  informal1to1: 'informal1to1',
}


export { DEAL_NAME }