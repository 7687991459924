import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import classnames from 'classnames';
import Sticky from 'react-sticky-el';
import { Row, Col } from 'antd';
import { useFormatMessage } from "react-intl-hooks";
import Countdown from '@/scenes/Event/components/Countdown';
import { toJS } from '@/utils';
import { EVENT_FORMAT, EVENT_STATUS } from '@/utils/constants';
import Avatars from '@/components/Avatars';
import InlineEditor from '@/components/InlineEditor';
import MainHeader from '@/containers/Layout/containers/Header';
import Icon from '@/components/Icon';
import { H1 } from '@/components/Title';
import JobfairDate from './components/JobfairDate';
import { Location, PreregistrationDate, Nav, Planners, OrganizationCount } from './components';
import EventAccessCta, {
  EVENT_ACCESS_CTA_COMPONENT_TYPE,
  EVENT_ACCESS_CTA_CONTEXT,
} from '../../../../../../../../../../components/EventAccessCta';
import styles from './styles.less';
import { If } from '@/components/If';
import { getCdnUrl } from '@/components/ImgCdn';
import JobfairDateHybrid from './components/JobfairDateHybrid';

const defaultCovers = [
  'https://cdn-app-assets.seekube.com/event/5f2a6f3bdb3452001852cc0b/momlkoTcIzjLzvx6.jpeg',
  'https://cdn-app-assets.seekube.com/event/5f2955e69f55c1001165e7a3/n6HAtid0fC6lfBeU.jpeg',
  'https://cdn-app-assets.seekube.com/event/603f9fc534c54d0011c08e4f/2sdjIrPTs9pvfttG.jpeg',
];

/**
 * Header
 *
 * @description
 * Display content 'header' for the landing
 */
const Header = ({
  authUser,
  event,
  participant,
  exponents,
  intl,
  contentIndex,
  editMode,
  onChange,
  isPreregistered,
  onInit,
  postParticipant,
  locale,
  context,
  authUserEvents,
  isLoadingParticipant,
}) => {

  const t = useFormatMessage();
  const isMobile = window.innerWidth <= 650;
  const [headerElement, setHeaderElement] = useState({ backgroundImage: '' });

  const [exponentUsersPictures, setExponentUsersPictures] = useState(null)
  const [targetDate, setTargetDate] = useState(null)
  const [displayResponsiveNavigation, setDisplayResponsiveNavigation] = useState(false)
  const [totalUsers, setTotalUsers] = useState(null)

  useEffect(() => {
    if (event._id) {
      let targetDateTemp = null;

      if (!isEmpty(event.keyDates)) {
        switch (event.dateStatus) {
          case 'open':
            targetDateTemp = event.keyDates.jobfair.beginAt;
            break;

          case 'preregistration':
            targetDateTemp = event.keyDates.jobfair.beginAt;
            break;

          case 'discovering':
            targetDateTemp = event.keyDates.jobfair.beginAt;
            break;

          case 'in_progress':
            targetDateTemp = event.keyDates.jobfair.endAt;
            break;

          default:
            targetDateTemp = event.keyDates.jobfair.endAt;
        }

        setTargetDate(targetDateTemp);
      }
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(exponents) && exponentUsersPictures === null) {
      const rand = (min, max) => (Math.random() * (max - min)) + min;

      let totalUsers = 0;

      const usersPictures = [];
      const limit = 12;

      exponents.forEach((item) => {
        if (item.users) {
          const pictures = item.users.filter((el) => el._user && el._user.pictureUrl).map((item) => item._user.pictureUrl);

          usersPictures.push(...pictures);

          totalUsers += item.users.length;
        }
      });

      const randSlice = usersPictures.length > limit ? Math.floor(rand(0, usersPictures.length - limit)) : 0;

      setExponentUsersPictures(usersPictures.slice(randSlice, randSlice + limit));
      setTotalUsers(totalUsers);
    }
  }, [exponents]);

  /**
   * Send default value to the parent
   */
  useEffect(() => {
    if (typeof onInit === 'function') {
      onInit(contentIndex)({
        inNav: true,
        label: intl.formatMessage({ id: 'event.content.header.label' }),
        h1: event.name,
      });
    }

    displayCoverPicture();
  }, [])

  /**
   * Get URL, if it's a File object, create a preview.
   */
  const displayCoverPicture = () => {
    if (!event.theme && !headerElement.current) {
      return;
    }

    // Display a preview if url is a File
    if (event.theme.coverPictureUrl instanceof File) {
      const reader = new FileReader();

      // Display a preview
      reader.onload = (e) => {
        setHeaderElement({ backgroundImage: `url(${e.target.result})` });
      };

      reader.readAsDataURL(event.theme.coverPictureUrl);
    } else if (typeof event.theme.coverPictureUrl === 'string' && !isEmpty(event.theme.coverPictureUrl)) {
      setHeaderElement({ backgroundImage: `url(${getCdnUrl(event.theme.coverPictureUrl, 1500)})` });
    } else if (context === 'seekalendar' && isEmpty(event.theme.coverPictureUrl)) {
      const randomInt = !isEmpty(event._id) ? event._id.charCodeAt(23) % (defaultCovers.length) : 0;
      setHeaderElement({ backgroundImage: `url(${getCdnUrl(defaultCovers[randomInt], 1500)})` });
    }
  };

  const handleToggleResponsiveNavigation = () => {
    setDisplayResponsiveNavigation(!displayResponsiveNavigation);
  };

  const authUserEvent = (authUserEvents || []).find((e) => e && event && e._id === event._id);
  const currentEvent = authUserEvent || event;
  const { keyDates } = currentEvent;

  const headerContainerCSS = classnames(styles.headerContainer, displayResponsiveNavigation && styles.headerContainerAdjustNavigation, context === 'seekalendar' && styles.headerSeekalendar);

  const eventNav = (
    <div className="eventNav">
      <button onClick={handleToggleResponsiveNavigation}><Icon name="arrow-down" /></button>
      <Nav event={event} locale={locale} displayResponsiveNavigation={displayResponsiveNavigation} />
    </div>
  );

  const role = authUser && !isEmpty(authUser._currentOrganization) ? 'recruiter' : 'candidate';
  const orgIds = get(currentEvent, 'planners._organizations').map((o) => o._id);
  const isJPOWithDiplomeo = event.slug === 'school-dating-mai-2023-1';

  return (
    <>
      <div style={headerElement} className={headerContainerCSS}>
        <div style={{ position: 'relative', zIndex: 5 }}>
          <Sticky topOffset={60}>
            <MainHeader
              context="landing"
              nav={eventNav}
            />
          </Sticky>

        </div>

        <Row className={styles.bodyContainer} type="flex" align="middle" justify="center">
          <Col span={8} className={styles.content}>
            {/* Title */}
            <H1 bold>
              <InlineEditor
                editorEnabled={editMode}
                value={event._contents[contentIndex].h1 || event.name}
                onChange={typeof onChange === 'function' ? onChange(['_contents', contentIndex, 'h1']) : null}
              />
            </H1>
            {event.format !== EVENT_FORMAT.HYBRID &&
              <JobfairDate
                type={context === 'seekalendar' && !event.keyDatesOk ? 'short' : 'long'}
                beginAt={event.keyDates.jobfair.beginAt.toString()}
                endAt={event.keyDates.jobfair.endAt.toString()}
                intl={intl}
              />
            }

            <OrganizationCount event={event} intl={intl} exponents={exponents} />

            {event.format === EVENT_FORMAT.HYBRID &&
              <JobfairDateHybrid event={event} intl={intl} />
            }

            {context !== 'seekalendar' &&
              <PreregistrationDate
                beginAt={event.keyDates.discovering.beginAt}
              />
            }
            {context !== 'seekalendar' && event.format !== EVENT_FORMAT.HYBRID &&
              <Location event={event} />
            }

            {exponentUsersPictures && exponentUsersPictures.length > 0 && (
              <div className={styles.recruiters}>
                <Avatars
                  pictures={exponentUsersPictures}
                  maxPictures={12}
                  others={totalUsers - exponentUsersPictures.length}
                />
                <p>{totalUsers} {t({ id: 'event.landing.recruitersAvatars' }, {
                  count: totalUsers,
                  label: !isEmpty(event.modules.organization[`recruiterLabel_${intl.locale}`]) ? event.modules.organization[`recruiterLabel_${intl.locale}`] : t({ id: 'recruiters'}, { count: 0 })
                })}
                </p>
              </div>
            )}
            <If condition={isJPOWithDiplomeo && !isMobile}>
              <Sticky topOffset={-60}>
                  <div className={styles.fixedCountdownJPO}>
                    <EventAccessCta
                      authUser={authUser}
                      context={EVENT_ACCESS_CTA_CONTEXT.LANDING}
                      ctaComponentType={EVENT_ACCESS_CTA_COMPONENT_TYPE.BUTTON}
                      editMode={editMode}
                      event={currentEvent}
                      postParticipant={postParticipant}
                      eventUserStats={currentEvent.stats}
                      intl={intl}
                      role={(authUser && authUser.isAdmin) || (role === 'recruiter' && isEmpty(currentEvent.stats) && (orgIds.indexOf(authUser._currentOrganization._id) > -1)) ? 'owner' : role}
                      customClass={styles.floatingBtn}
                    />
                  </div>
                </Sticky>
            </If>

            <If condition={context !== 'seekalendar'}>
              <div className={styles.countdownContainer}>
                <Countdown date={keyDates.jobfair.beginAt} type="circular" />
              </div>
            </If>

            {
              isLoadingParticipant || context === 'seekalendar' || (participant && participant.isBan) || isJPOWithDiplomeo ?
                null : (
                  <EventAccessCta
                    authUser={authUser}
                    context={EVENT_ACCESS_CTA_CONTEXT.LANDING}
                    ctaComponentType={EVENT_ACCESS_CTA_COMPONENT_TYPE.BUTTON}
                    editMode={editMode}
                    event={currentEvent}
                    postParticipant={postParticipant}
                    eventUserStats={currentEvent.stats}
                    intl={intl}
                    role={(authUser && authUser.isAdmin) || (role === 'recruiter' && isEmpty(currentEvent.stats) && (orgIds.indexOf(authUser._currentOrganization._id) > -1)) ? 'owner' : role}
                  />
                )
            }
          </Col>
        </Row>

        <Planners
          organizations={event.planners._organizations}
          event={currentEvent}
        />
        <div className={styles.overlay} />
      </div>

      <If condition={context !== 'seekalendar' && !isJPOWithDiplomeo}>
        {
          event.dateStatus === EVENT_STATUS.CLOSED ||
          (moment() > moment(event.keyDates.discovering.endAt) && (!authUser || !authUser._currentOrganization)) ?
            '' : (
              <div className={styles.fixedCountdown}>
                <Countdown targetDate={targetDate} type="inline" icon="clockColor" />
              </div>
            )
        }
      </If>

      <If condition={isJPOWithDiplomeo && isMobile}>
        <div className={styles.fixedCountdownJPO}>
            <EventAccessCta
              authUser={authUser}
              context={EVENT_ACCESS_CTA_CONTEXT.LANDING}
              ctaComponentType={EVENT_ACCESS_CTA_COMPONENT_TYPE.BUTTON}
              editMode={editMode}
              event={currentEvent}
              postParticipant={postParticipant}
              eventUserStats={currentEvent.stats}
              intl={intl}
              role={(authUser && authUser.isAdmin) || (role === 'recruiter' && isEmpty(currentEvent.stats) && (orgIds.indexOf(authUser._currentOrganization._id) > -1)) ? 'owner' : role}
              customClass={styles.floatingBtn}
            />
          </div>
      </If>
    </>
  );

}

Header.propTypes = {
  authUser: PropTypes.object,
  event: PropTypes.object,
  participant: PropTypes.object,
  exponents: PropTypes.array,
  intl: PropTypes.object,
  contentIndex: PropTypes.number,
  editMode: PropTypes.bool,
  onChange: PropTypes.func,
  isPreregistered: PropTypes.bool,
  onInit: PropTypes.func,
  postParticipant: PropTypes.func,
  locale: PropTypes.string,
  context: PropTypes.string,
  authUserEvents: PropTypes.array,
  isLoadingParticipant: PropTypes.bool
}

export default injectIntl(toJS(Header));
