import React from 'react';
import { FormattedMessage } from 'react-intl';
import { bool, func, array, string } from 'prop-types';
import { map } from 'lodash';

import Separator from '@/components/Separator';
import ModalV2 from '@/components/ModalV2';
import { PresenterCard } from './components';

import styles from './styles.less';
import classnames from 'classnames';

const PresentersModal = ({ title, presenters, visible, handleCancel }) => (
  <ModalV2
    template="defaultSophie"
    visible={visible}
    onClose={handleCancel}
    className={styles.removeUserModal}
    handleCancel={handleCancel}
  >
    <div className={styles.modalHeader}>
      <FormattedMessage id="the.presenters" tagName="h3" />
    </div>

    <div className={styles.modalContent}>
      <p>{title}</p>
    </div>
    <Separator height={15} />
    <div className={classnames(styles.presenters, presenters.length > 3 && styles.scrollable)}>
      <PresentersCards presenters={presenters} />
    </div>
  </ModalV2>
);

const PresentersCards = ({ presenters }) =>
  map(presenters,
    (presenter) => (
      <PresenterCard
        key={presenter._id}
        presenter={presenter}
      />)
);

PresentersModal.propTypes = {
  title: string,
  presenters: array,
  visible: bool,
  handleCancel: func,
};

export { PresentersModal, PresentersCards };
