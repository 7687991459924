import React from 'react';
import { string } from 'prop-types';

import Icon from '@/components/Icon';

import styles from './styles.less';

const PhoneNumber = ({
  phone,
}) => (
  <span className={styles.phoneNumber}>
    <Icon name="phone" /> {removeFrenchPrefix(phone)}
  </span>
);

const removeFrenchPrefix = (number) =>
  number.indexOf('+33') > -1 ?
    number.replace('+33', '0').replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5')
    : number;

PhoneNumber.propTypes = {
  phone: string,
};

export { PhoneNumber };
