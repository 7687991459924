import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { bool, func, number, string } from 'prop-types';
import { Steps } from 'antd';

import Modal from '@/components/ModalV2';
import Result from './components/Result';
import Success from './components/Success';
import Failure from './components/Failure';

import messages from './messages';
import styles from './styles.less';

const { Step } = Steps;

const StepContent = ({
  testUrl,
  currentStep,
  setCurrentStep,
  onValueSelected,
}) => {
  const [context, setContext] = useState('yes');

  switch (currentStep) {
    // case 0: return <Home nextStep={setCurrentStep} hasAtLeastOneInterview={hasAtLeastOneInterview} />;
    case 0: return <Result nextStep={setCurrentStep} setContext={setContext} />;
    case 1: return context === 'yes' ? <Success onValueSelected={onValueSelected} testUrl={testUrl} /> : <Failure onValueSelected={onValueSelected} />;
    default: return null;
  }
};

StepContent.propTypes = {
  currentStep: number,
  testUrl: string,
  onValueSelected: func,
  setCurrentStep: func,
};

const TestVisioModal = ({
  visible = false,
  handleSelectedMode,
  testUrl,
  handleCloseModal,
  hasAtLeastOneInterview,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  useEffect(() => (() => {
    if (currentStep !== 0 && !visible) { setCurrentStep(0); }
  }), [visible]);

  return (
    <Modal
      visible={visible}
      className="customConfirm"
      width="720px"
      onClose={handleCloseModal}
    >
      <div className={styles.testSeekliveModal}>
        <StepContent
          currentStep={currentStep}
          setCurrentStep={() => setCurrentStep(currentStep + 1)}
          onValueSelected={handleSelectedMode}
          testUrl={testUrl}
          hasAtLeastOneInterview={hasAtLeastOneInterview}
        />
        <div className={styles.footerContainer}>
          <div className="steps-indicator-wrapper">
            <Steps
              current={currentStep}
              progressDot
            >
              <Step title={<FormattedMessage {...messages.resultTitle} />} />
              <Step title={<FormattedMessage {...messages.stepRecommendations} />} />
            </Steps>
          </div>
        </div>
      </div>
    </Modal>
  );
};

TestVisioModal.propTypes = {
  visible: bool,
  hasAtLeastOneInterview: bool,
  handleSelectedMode: func,
  handleCloseModal: func,
  testUrl: string,
};

export { TestVisioModal };
