import React from 'react';
import { func } from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { replace } from 'connected-react-router';

// Store
import { authActions } from '@/store/auth';

// Containers
import { withAuth } from '@/containers/RequiredAuth';

/**
 * LogoutScene
 */
class LogoutScene extends React.PureComponent { // eslint-disable-line
  static propTypes = {
    logout: func,
  }

  /**
   * ComponentDidMount
   *
   * @description
   * Disconnect the authUser and redirect to /
   */
  componentDidMount() {
    const { props: { logout } } = this;

    logout();
  }

  /**
   * Render
   */
  render() {
    return null;
  }
}

const mapDispatchToProps = (dispatch) => ({
  logout() {
    dispatch(authActions.authLogout({
      callback: () => {
        setTimeout(() => {
          dispatch(replace('/'));
        }, 300);
      }
    }));
  },
});

const withConnect = connect(null, mapDispatchToProps);

export default compose(
  withConnect,
  withAuth(),
)(LogoutScene);
