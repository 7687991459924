import React from 'react';
import { object, func, bool } from 'prop-types';
import { Modal } from 'antd';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useFormatMessage } from 'react-intl-hooks';
import { Button } from '@seekube-tech/ui-kit';
import { toJS } from '@/utils';
import { participantActions, participantSelectors } from '@/store/participant';
import { EVENT_FORMAT } from '@/utils/constants';
import { getEventKeyMoments } from '@/helpers/event/getEventKeyMoments';
import styles from './styles.less'
import { trackCandidateModifiedParticipationMode } from '@/utils/analytics';
import { authSelectors } from '@/store/auth';

const ChangeParticipantParticipationModal = ({ event, isOpen, setIsOpen, patchParticipant, participant, authUser }) => {
  const t = useFormatMessage();

  const handleOnOk = () => {
    const _keyMomentLinks = [{
      type: 'jobfair',
      _keyMoment: {
        _id: getEventKeyMoments(event, { type: 'jobfair', format: EVENT_FORMAT.PHYSICAL })._id
      }
    },
    {
      type: 'jobfair',
      _keyMoment: {
        _id: getEventKeyMoments(event, { type: 'jobfair', format: EVENT_FORMAT.VIRTUAL })._id
      }
    }
    ];

    patchParticipant({
      participantId: participant._id,
      eventId: event._id,
      participantParams: {
        _keyMomentLinks,
      },
      slug: event.slug,
      callback: () => {
        setIsOpen(false);
        trackCandidateModifiedParticipationMode({ authUser, event });
      },
    });
  }

  const remainingFormat = participant?.keyMomentFormats?.includes(EVENT_FORMAT.PHYSICAL) ? EVENT_FORMAT.VIRTUAL : EVENT_FORMAT.PHYSICAL;

  return (
    <Modal
      onOk={handleOnOk}
      onCancel={() => setIsOpen(false)}
      visible={isOpen}
      width={660}
      footer={false}
      className="customConfirm"
      okText="ok"
      cancelText="cancel"
    >
      <div className={styles.changeParticipationModalContainer}>
        <p className={styles.title}>
          {t({ id: `participant.participation.change.modal.title.${remainingFormat}` }) }
        </p>
        <p className={styles.description}>
          {t({ id: `participant.participation.change.modal.description.one.${remainingFormat}` }) }
        </p>
        <p className={styles.description}>
          {t({ id: 'participant.participation.change.modal.description.two' })}
        </p>
        <div className={styles.buttons}>
          <Button className="mr-6" onClick={() => setIsOpen(false)} variant='text'>{t({ id: "cancel" })}</Button>
          <Button onClick={handleOnOk}>{t({ id: "btn.confirm" })}</Button>
        </div>
      </div>
    </Modal>
  );
};

ChangeParticipantParticipationModal.propTypes = {
  event: object,
  patchParticipant: func,
  participant: object,
  isOpen: bool,
  setIsOpen: func,
  authUser: object,
};

const mapStateToProps = createStructuredSelector({
  participant: participantSelectors.getCurrentParticipant,
  authUser: authSelectors.getAuthUser,
});

const mapDispatchToProps = {
  patchParticipant: participantActions.patchParticipant,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(toJS(ChangeParticipantParticipationModal));
