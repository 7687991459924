import React from 'react';
import { object, string } from 'prop-types';

import { Circle } from '@/components/Circle';

import styles from './styles.less';

const EventName = ({
  name,
  jobfairColor,
}) => (
  <div className={styles.event}>
    <Circle size="12px" style={{ marginRight: '7px' }} color={jobfairColor?.main} />{name}</div>
);

EventName.propTypes = {
  jobfairColor: object.isRequired,
  name: string,
};

export { EventName };
