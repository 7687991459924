import { useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { filter } from 'lodash';
import request from '@/utils/request';
import { getAuthToken } from '@/store/auth/selectors';

export function useDeleteUser({
  onSuccess = () => {},
  organization,
  onError = () => {},
  ...props
}) {
  const queryClient = useQueryClient();

  const mutationFn = ({ userId }) => {
    const requestUrl = `${process.env.FRONT_API_URL}/organizations/${organization?._id}/users/${userId}`;

    return request(requestUrl, { method: 'DELETE' });
  };

  return useMutation({
    mutationFn,
    onSuccess: async ({ userId }) => {
      const users = queryClient.getQueryData(['organizationUsers', props.search.page, organization?._id, props.search.q]);

      const newDocs = filter(users.docs, (prevDeals) => prevDeals._id !== userId);
      const newDeals = { ...users, docs: newDocs };

      queryClient.setQueryData(['organizationUsers',  props.search.page, organization?._id, props.search.q], newDeals);

      onSuccess();

      return newDeals;
    },
    onError,
  });

}
