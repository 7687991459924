import { useQuery } from 'react-query';
import request from '@/utils/request';

export function useGetNextReportsToSend(
  {
    eventId,
  }) {
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/reports/next-to-send`;

  return useQuery({
    queryKey: ['nextReportsToSend'],
    queryFn: async () => {
      try {
        const data = await request(requestUrl, { method: 'GET' })
        return data
      } catch (e) {
        return null;
      }
    },
    initialData: {},
    select: (data) => data,
    refetchOnWindowFocus: false,
  });
}
