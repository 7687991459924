import { capitalize, head, indexOf, map, size, split, upperFirst } from 'lodash';
import moment from 'moment';
import React from 'react';
import { Select as SelectAnt } from 'antd';
import timezones from '@/scenes/Settings/components/General/components/Timezone/timezone.json';


const formatName = (val) => {
  let name = val;
  const hasSpace = indexOf(name, ' ') !== -1;
  const hasDash = indexOf(name, '-') !== -1;

  if (hasSpace) {
    name = name.split(' ').map((b) => indexOf(b, '-') !== -1 ? upperFirst(b) : capitalize(b)).join(' ');
  }

  if (hasDash) {
    name = name.split('-').map((b) => indexOf(b, ' ') !== -1 ? upperFirst(b) : capitalize(b)).join('-');
  }

  if (!(hasSpace && hasSpace)) { name = upperFirst(name); }
  return name;
};


const getUtcFormat = (zone, text = '') => `(UTC${moment().tz(zone || 'Europe/Paris').format('Z')})${text && split(text, ')')[1]}`;

const convertStringFormatToDate = (dateString, format = 'YYYY-MM-DD') => moment(dateString, format).toDate() || null;

const dateFormat = (beginAt, endAt, numberInBold = true, nbOfLettersDisplayed = 3, format = 'MMM') => {
  const begin = moment(beginAt).format('D');
  const end = moment(endAt).format('D');


  const beginAtMonth = moment(beginAt).format(format).slice(0, nbOfLettersDisplayed);
  const beginAtFormatted = numberInBold ? <strong>{begin}</strong> : begin;
  const endAtMonth = moment(endAt).format(format).slice(0, nbOfLettersDisplayed);
  const endAtFormatted = numberInBold ? <strong>{end}</strong> : end;

  if (begin === end && beginAtMonth === endAtMonth) {
    return <span>{beginAtFormatted} {beginAtMonth}</span>;
  }

  const beginAtDate = beginAt && <>{beginAtFormatted} {beginAtMonth}</>;
  const endAtDate = endAt && (<>- {endAtFormatted} {endAtMonth}</>);

  return <span>{beginAtDate} {endAtDate}</span>;
};

const getTimezoneOptions = () => {
  const getTimezoneOption = (utc, text, key) => {
    const utcNumber = size(head(utc)) && moment().tz(head(utc)).utcOffset() / 60;
    const utcString = utcNumber >= 0 ? `+${utcNumber}` : utcNumber;

    return <SelectAnt.Option key={key} value={text}>{`(UTC${utcString}) ${split(text, ')')[1]}`}</SelectAnt.Option>;
  };

  return map(timezones, (obj, i) => getTimezoneOption(obj.utc, obj.text, i));
};

const getTimezoneText = (timezone) => {
  const utcNumber = size(head(timezone.utc)) && moment().tz(head(timezone.utc)).utcOffset() / 60;
  const utcString = utcNumber >= 0 ? `+${utcNumber}` : utcNumber;

  return `(UTC${utcString}) ${split(timezone.text, ')')[1]}`;
}

const removeWhitespace = (val) => val ? val.replace(/ /g, '') : val;

export {
  formatName,
  getUtcFormat,
  dateFormat,
  convertStringFormatToDate,
  getTimezoneOptions,
  removeWhitespace,
  getTimezoneText,
};

