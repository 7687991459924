import { useQuery } from 'react-query';
import request from '@/utils/request';

export function useGetShouldRefreshFeeds({ authToken, date, ...props }) {
  const requestUrl = `${process.env.FRONT_GO_API_URL}/stats/kpi/feeds/refresh?date=${date}`;
  const queryFn = () => request(requestUrl, {
    method: 'GET',
  });

  const query = useQuery({
    queryKey: ['refreshFeed'],
    queryFn,
    refetchOnWindowFocus: false,
    ...props,
  });
  return query;
}
