import { makeStyles } from '@seekube-tech/ui';

export default makeStyles(theme => ({
  root: {
    padding: '16px',
    border: `1px solid #D3DBE8`,
    background: theme.palette.common.white,
    borderRadius: '5px',
    marginBottom: theme.spacing(2),
    '&:hover': {
      border: `1px solid ${theme.palette.primary['500']}`,
      cursor: 'pointer'
    }
  },
  selected: {
    border: `1px solid ${theme.palette.primary['500']}`,
    background: theme.palette.primary['100'],
    borderRadius: '5px'
  },
  description: {
    marginTop: theme.spacing(0.5),
  },
  field: {
    marginTop: theme.spacing(2),
  },
  switch: {
    marginTop: theme.spacing(0.5),
  },
  button: {
    marginTop: theme.spacing(3),
  },
}));
