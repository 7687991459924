import React from 'react';
import { object } from 'prop-types';
import styles from './styles.less'
import { H4 } from '@seekube-tech/ui-kit';
import Html from '@/components/Html';
import Videos from '../Medias/Videos';

const AdditionnalText = ({
  profile,
}) => {

  if (!profile?.additionnalTexts) {
    return null;
  }

  const getVideos = (index) => {
    const videos = profile.videos?.filter(({ url }) => url);
    return videos.slice((2 * index) + 2, (2 * index) + 4)
  }

  return (
    <div className={styles.additionnalTextContainer}>
      {profile.additionnalTexts.map((text, key) => (
          <div key={key} className={key > 0 && 'mt-38'}>
            <H4 className="mb-24">{text.title}</H4>
            <Html value={text.content} />
            {getVideos(key).length > 0 &&
              <div className='mt-24'>
                <Videos profile={profile} videos={getVideos(key)} />
              </div>
            }
          </div>
        )
      )}
    </div>
  )
}

AdditionnalText.propTypes = {
  profile: object
}

export default AdditionnalText;
