const ANALYTICS_ALL = {
  CLICKED_SIGNIN: 'Clicked Sign in ',
  CLICKED_SIGNUP: 'Clicked Sign up ',
};

// Matching criterion types
const CRITERIA = {
  CONTRACT: 'CONTRACT',
  MOBILITY: 'MOBILITY'
}

export { ANALYTICS_ALL, CRITERIA };
