import React, { useState } from 'react';
import { Body1, Body2, Button, H4, Toggle } from '@seekube-tech/ui-kit';
import { Modal } from 'antd';
import { useFormatMessage } from 'react-intl-hooks';
import classNames from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';
import { acceptOwnerAccess } from './request';
import styles from './styles.less';
import { queryStringToObject } from '@/utils/url';

const AcceptOwnerAccess = () => {
  const location = useLocation();
  const history = useHistory();
  const t = useFormatMessage();

  const { state, referentUserId, exponent, event } = queryStringToObject(location.search);
  
  const [locationState, setLocationState] = useState(state || '')
  const [isReferent, setIsReferent] = useState(true)
  
  const handleSubmit = () => {
    const body = {
      referentUserId,
      state: locationState,
    }
    acceptOwnerAccess({exponentId: exponent, body, eventId: event})
      .then(() => {
        history.push('/')
      })
      .catch(() => {
        setIsReferent(false);
      })
  }

  return (
    <div className={styles.acceptOwnerAccess}> 
      <Modal
        visible
        closable={false}
        footer={false}
        maskClosable={false}
        width={720}
        className={classNames(styles.acceptOwnerAccessModal, !isReferent && styles.acceptOwnerAccessModalOops)}
      >
        {isReferent ?
          <>
            <H4>{t({id: 'acceptOwnerAccess.modal.title'})}</H4>
            <div className={styles.choiceContainer}>
              <div className={styles.choiceLeft}>
                <Body2 className={styles.choiceLeftTitle}>{t({id: 'acceptOwnerAccess.modal.choice.title'})}</Body2>
                <Body1 className={styles.choiceLeftDescription}>{t({id: 'acceptOwnerAccess.modal.choice.description'})}</Body1>
              </div>
              <Toggle checked={locationState === 'accepted'} onChange={(e) => setLocationState(e === true ? 'accepted' : 'refused')} />
            </div>
            <div className={styles.btnContainer}>
              <Button onClick={() => handleSubmit()}>{t({id: 'save'})}</Button>
            </div>
          </>
        :
          <H4>{t({id: 'accept.owner.access.not.referent'})}</H4>
        }
      </Modal>
    </div>
  )
}

export default AcceptOwnerAccess