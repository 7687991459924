import React from 'react';
import { object, node, func } from 'prop-types';
import { injectIntl } from 'react-intl';
import { isEmpty } from 'lodash';

// Components
import Card from '@/components/Card';

// Styles
import styles from './styles.less';
import { Title } from '@/components/InteractionCard/components/Title';
import {
  ActionBtn,
  AvatarRecruiter,
  MoreActions,
  OffersInQuestion,
} from '@/components/InteractionCard/components';
import { Content } from '@/components/InteractionCard/components/Content';


const InteractionCard = ({ interaction, participant, popoverContent, handleOpenOffer, event, intl }) => {
  const { _appointment, _organization } = interaction;
  const _user = interaction._recruiter || interaction._user;
  const hasAppointmentBeenModified = !interaction?.pending && _appointment?.status === 'pending';

  if (isEmpty(_user)) {
    return '';
  }


  return (
    <Card noBorder containerClassName={styles.card}>
      <div className={styles.wrapper}>
        <AvatarRecruiter _appointment={_appointment} _organization={_organization} _user={_user} />

        <div className={styles.content}>
          <Title interaction={interaction} event={event} hasAppointmentBeenModified={hasAppointmentBeenModified} />

          <Content
            intl={intl}
            participant={participant}
            interaction={interaction}
            hasAppointmentBeenModified={hasAppointmentBeenModified}
            eventLocation={event.location?.formattedAdress ?? ''}
          />
        </div>

        <ActionBtn event={event} interaction={interaction} participant={participant} />

      </div>

      <OffersInQuestion interaction={interaction} handleOpenOffer={handleOpenOffer} event={event} />

      <MoreActions _appointment={_appointment} popoverContent={popoverContent} />

    </Card>
  );
};

InteractionCard.propTypes = {
  participant: object,
  interaction: object,
  popoverContent: node,
  handleOpenOffer: func,
  event: object,
  intl: object,
};

export default React.memo(injectIntl(InteractionCard));
