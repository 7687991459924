/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router/immutable';
import { datadogRum } from '@datadog/browser-rum';
import MobileDetect from 'mobile-detect';

/**
 * styles
 */
import 'sanitize.css/sanitize.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './less/fonts.less';

import 'antd/dist/antd.less';
import '@/containers/App/styles.less';
import './less/antd-theme.less';

// Import root app
import { ThemeProvider } from '@seekube-tech/ui';
import { QueryClient, QueryClientProvider } from 'react-query';
import App from '@/containers/App';

// Import constant

// Import Provider
import LanguageProvider from '@/containers/LanguageProvider';


/* eslint-enable import/no-unresolved, import/extensions */


import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import configureStore, { history as customHistory } from './configureStore';

// Import i18n messages
import { translationMessages } from './i18n';


// Init Sentry.io
import pkg from '../package.json';
import '@seekube-tech/ui-kit/dist/index.css';

const md = new MobileDetect();
const queryClient = new QueryClient();

// SegmentIO Snippet
!(function () { // eslint-disable-line
  if (window.location.hostname === 'app.seekube.com') {
    const analytics = window.analytics = window.analytics || []; if (!analytics.initialize) { // eslint-disable-line
      if (analytics.invoked) window.console && console.error && console.error('Segment snippet included twice.'); else { // eslint-disable-line
        analytics.invoked = !0; analytics.methods = ['trackSubmit', 'trackClick', 'trackLink', 'trackForm', 'pageview', 'identify', 'reset', 'group', 'track', 'ready', 'alias', 'debug', 'page', 'once', 'off', 'on']; analytics.factory = function (t) { return function () { const e = Array.prototype.slice.call(arguments); e.unshift(t); analytics.push(e); return analytics; }; }; for (let t = 0; t < analytics.methods.length; t++) { const e = analytics.methods[t]; analytics[e] = analytics.factory(e); } analytics.load = function (t, e) { const n = document.createElement('script'); n.type = 'text/javascript'; n.async = !0; n.src = `${document.location.protocol === 'https:' ? 'https://' : 'http://'}cdn.segment.com/analytics.js/v1/${t}/analytics.min.js`; const o = document.getElementsByTagName('script')[0]; o.parentNode.insertBefore(n, o); analytics._loadOptions = e; }; analytics.SNIPPET_VERSION = '4.1.0';  // eslint-disable-line
        analytics.load(process.env.FRONT_SEGMENT_API_KEY);  // eslint-disable-line
      }
    }
  } else {
    console.warn('[Seekube 🤘] Segment analytics is disabled in development mode. Please check utils/analytics/helpers to enable it');
  }

  /**
   * Intercom hides current (20/09/2022) navigation on mobile, hence the forced deactivation on mobile devices
   */
  if (!md.mobile()) {
    // Set your APP_ID
    const APP_ID = process.env.FRONT_INTERCOM_APP_ID;
    (function () { var w = window; var ic = w.Intercom; if (typeof ic === "function") { ic('reattach_activator'); ic('update', w.intercomSettings); } else { var d = document; var i = function () { i.c(arguments); }; i.q = []; i.c = function (args) { i.q.push(args); }; w.Intercom = i; var l = function () { var s = d.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://widget.intercom.io/widget/' + APP_ID; var x = d.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); }; if (w.attachEvent) { w.attachEvent('onload', l); } else { w.addEventListener('load', l, false); } } })(); // eslint-disable-line
  }
}());

if (process.env.NODE_ENV === 'production') {
  window.datadogRum = datadogRum;

  window.datadogRum.init({
    applicationId: 'f1129d6b-4350-40ae-ab5a-62fae3e00d5f',
    clientToken: 'pub02f2f9f00e1604da4b78ed947837f678',
    site: 'datadoghq.com',
    service: 'app.seekube.com',
    sampleRate: 100,
    version: pkg.version,
    trackInteractions: true,
    allowedTracingOrigins: [process.env.FRONT_API_URL],
  });
}

// Create redux store with history
const initialState = {};
const store = configureStore(initialState, customHistory);

ReactDOM.render(
  <Provider store={store}>
    <LanguageProvider messages={translationMessages}>
      <ConnectedRouter history={customHistory}>
        <DndProvider backend={HTML5Backend}>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider>
              <App />
            </ThemeProvider>
          </QueryClientProvider>
        </DndProvider>
      </ConnectedRouter>
    </LanguageProvider>
  </Provider >,
  document.getElementById('app')
);

if (module.hot) {
  module.hot.accept()
}
