import React, { useState } from 'react';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

import { toJS } from '@/utils';
import { getCurrentEvent } from '@/store/event/selectors';
import { Bloc, BlocHeader } from '@/components/Bloc';
import { Button, H4, Link1, RadioButtonCard, Subtitle2, Toggle } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { Link2 } from 'react-feather';
import styles from '@/scenes/Event/scenes/Owner/scenes/Setting/styles.less';
import { eventActions } from '@/store/event';

const OwnersScene = (props) => {
  const event = useSelector(getCurrentEvent)?.toJS();
  const t = useFormatMessage();
  const dispatch = useDispatch();

  const [canAlwaysAuthorize, setCanAlwaysAuthorize] = useState(event?.authorizePreparationByOwnerMode === 'auto');

  const handleSave = () => {
    dispatch(eventActions.patchEvent({
      event: { authorizePreparationByOwnerMode: canAlwaysAuthorize ? 'auto' : 'manual'},
      notificationParams: {
        success: {
          message: t({ id: 'notifications.update.success' }),
          kind: 'success',
        },
      }
    }));
  }

  return (
    <div style={{ width: '100%', flex: 1, paddingTop: '20px' }}>
      <div className={styles.formWrapper}>
        <Bloc>
          <BlocHeader className="mb-24">
            <H4>
              {t({ id: 'event.owner.setting.limits.owners.title' })}
            </H4>
            <Toggle checked={event?.authorizePreparationByOwner} onChange={(authorizePreparationByOwner) => {
              dispatch(eventActions.patchEvent({
                event: { authorizePreparationByOwner: authorizePreparationByOwner },
                notificationParams: {
                  success: {
                    message: t({ id: 'notifications.update.success' }),
                    kind: 'success',
                  },
                }
              })); }} />
          </BlocHeader>

          <div className="flex items-center">
            <Subtitle2>
              {t({ id: 'event.owner.setting.limits.candidates.owners.description' }, {
                clickHere:
                  <Link1 className="lowercase" target="_blank" href="https://help.seekube.com/fr/articles/7172024-prendre-la-main-sur-la-preparation-des-entreprises">
                    {t({ id: 'click.here' })}
                  </Link1>
              })}
            </Subtitle2>
          </div>

          {event?.authorizePreparationByOwner &&
            <>
              <div className={styles.radioBtnContainer}>
                <RadioButtonCard
                  className={styles.radioBtn}
                  description={t({id: 'limits.owners.radio.punctually.description'})}
                  checked={!canAlwaysAuthorize}
                  onClick={() => setCanAlwaysAuthorize(false)}
                >
                  {t({id: 'limits.owners.radio.punctually.title'})}
                </RadioButtonCard>
                <RadioButtonCard
                  description={t({id: 'limits.owners.radio.always.description'})}
                  checked={canAlwaysAuthorize}
                  onClick={() => setCanAlwaysAuthorize(true)}
                >
                  {t({id: 'limits.owners.radio.always.title'})}
                </RadioButtonCard>
              </div>
    
              <Button onClick={() => handleSave()}>
                {t({id: 'save'})}
              </Button>
            </>
          }
        </Bloc>
      </div>
    </div>
  );
};

export default compose(
  toJS,
)(OwnersScene);
