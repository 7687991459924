import moment from 'moment';
import { isEmpty, concat, isArray } from 'lodash';
import { getId } from '@/utils/global';
import 'moment-timezone';

/**
 * Class for transform data from the API to the form
 */
export default class ApiToForm {
  /**
   * Return new Array with all organizations
   *
   * @param {Array} organizations
   * @param {string} type
   *
   * @returns {Array}
   */
  static organizations = (organizations = [], type = 'ids') => organizations
    .reduce((acc, organization) => {
      if (type === 'ids') {
        acc.push(organization._id);
      } else {
        acc.push({
          key: organization._id,
          label: organization.name,
          title: organization.name,
        });
      }
      return acc;
    }, []);

  /**
   * Return new Array with all modules enable
   *
   * @param {Array|object} eventModules
   *
   * @returns {Array}
   */
  static modules = (eventModules) => {
    const modules = ['jobdating', 'live', 'hunt', 'feedback', 'offer'];

    // POST
    if (isEmpty(eventModules)) {
      return modules;
    }

    // PATCH
    return Object
      .keys(eventModules)
      .reduce((acc, moduleKey) => {
        if (eventModules[moduleKey].enable) {
          acc.push(moduleKey);
        }
        return acc;
      }, []);
  };


  static utcDate = (dateForm, utcZone) => moment(dateForm.toDate()).tz(utcZone).utcOffset(moment(dateForm.toDate()).tz(utcZone).utcOffset())

  /**
   * Return array with the beginAt and endAt for a specific keyDate
   *
   * @param {Array} keyDates
   * @param {string} keyName
   */
  static keyDates = (keyDates = {}, keyName) => {
    if (isEmpty(keyDates[keyName])) return [];

    return [
      moment(keyDates[keyName].beginAt),
      moment(keyDates[keyName].endAt),
    ];
  };

  static matchingFiltersNew = (criteria, selectedValues) => {
    const values = {
      filters: [],
    };

    criteria
      .filter((criterion) => criterion.enable)
      .map((criteria) => {
        let choices = criteria._choices;

        if (criteria.key === 'JOBS') {
          choices = criteria._jobs;
        }

        values.filters[criteria._id.toString()] = choices
          .filter((choice) => selectedValues.filters.includes(choice._id.toString()))
          .map((choice) => choice._id);

        return values;
      });

    if (selectedValues.date) {
      values.availability = selectedValues.date;
    }

    if (selectedValues.experiences) {
      values.experiences = selectedValues.experiences.toString();
    }

    if (selectedValues.license) {
      values.license = selectedValues.license;
    }

    return values;
  };

  /**
   * Return array with the initial values
   *
   * @param {Array} criteria
   * @param {Array} selectedValues
   */
  static matchingFilters = (criteria, selectedValues) => {
    const values = {
      filters: [],
    };

    criteria
      .filter((criterion) => criterion.enable && criterion.isCustom)
      .map((criteria, index) => {
        let choices = criteria._choices;

        if (criteria.key === 'JOBS') {
          choices = criteria._jobs;
        }

        values.filters[index] = choices
          .filter((choice) => selectedValues.filters.includes(getId(choice)))
          .map((choice) => choice._id);

        return values;
      });

    if (selectedValues.date) {
      values.availability = selectedValues.date;
    }

    if (selectedValues.experiences) {
      values.experiences = selectedValues.experiences.toString();
    }

    if (selectedValues.license) {
      values.license = selectedValues.license;
    }

    return values;
  };

  static matchingDefaultCriteria = (criteria, selectedValues) => {
    const values = {
      filters: [],
    };

    criteria
      .map((criteria, index) => {
        let choices = criteria._choices;

        if (criteria.key === 'JOBS') {
          choices = criteria._jobs;
        }

        values.filters[index] = choices
          .filter((choice) => selectedValues.filters.includes(choice._id.toString()))
          .map((choice) => choice._id);

        return values;
      });

    if (selectedValues.date) {
      values.availability = selectedValues.date;
    }

    if (selectedValues.experiences) {
      values.experiences = selectedValues.experiences.toString();
    }

    if (selectedValues.license) {
      values.license = selectedValues.license;
    }

    return values;
  };

  /**
   * Return array with the initial values
   *
   * @param {Array} criteria
   * @param {Array} selectedValues
   */
  static matchingFiltersParticipant = (criteria, selectedValues) => {
    const values = {
      filters: [],
    };

    let criteriaIndex = 0;

    if (!isEmpty(selectedValues?.filters)) {
      criteria
        .forEach((criteria) => {
          if (criteria) {
            let choices = criteria._choices;

            if (criteria.key === 'JOBS') {
              choices = criteria._jobs;
            }

            values.filters[criteriaIndex] = choices
              .filter((choice) => choice.enable && choice._id && selectedValues.filters && selectedValues.filters.flat().includes(choice._id.toString()))
              .map((choice) => choice._id);

            criteriaIndex += 1;
          }
        });
    }
    if (!isArray(selectedValues.dateStr) || (isArray(selectedValues.dateStr) && !isEmpty(selectedValues))) {
      values.availability = selectedValues.dateStr;
      values.dateStr = selectedValues.dateStr;
    }

    if (selectedValues.experiences) {
      values.experiences = selectedValues.experiences === -1 ? '' : selectedValues.experiences.toString();
    }

    if (selectedValues.languages) {
      values.languages = selectedValues.languages;
    }

    if (selectedValues.targets) {
      values.targets = selectedValues.targets;
    }

    return values;
  };

  /**
   * Return array with the initial values
   *
   * @param {Array} criteria
   * @param {Array} selectedValues
   */
  static matchingDefaultFiltersUser = (criteria, selectedValues) => {
    const contractFilters = [];
    const durationFilters = [];
    let availabilityFilters = '';
    const mobilityFilters = [];
    const positionFilters = [];
    const techFilters = [];

    const matching = selectedValues.matching || selectedValues;
    criteria
      .filter((criterion) => criterion.key === 'CONTRACT')
      .forEach((criterion) => {
        criterion._choices.forEach((choice) => {
          if (matching.filters.includes(choice._id.toString())) {
            contractFilters.push(choice._id.toString());
          }
        });
      });

    criteria
      .filter((criterion) => criterion.key === 'DURATION')
      .forEach((criterion) => {
        criterion._choices.forEach((choice) => {
          if (matching.filters.includes(choice._id.toString())) {
            durationFilters.push(choice._id.toString());
          }
        });
      });

    if (matching.date || matching.availability) {
      availabilityFilters = matching.date || matching.availability;
    }

    criteria
      .filter((criterion) => criterion.key === 'MOBILITY')
      .forEach((criterion) => {
        criterion._choices.forEach((choice) => {
          if (matching.filters.includes(choice._id.toString())) {
            mobilityFilters.push(choice._id.toString());
          }
        });
      });

    criteria
      .filter((criterion) => criterion.key === 'POSITION')
      .forEach((criterion) => {
        criterion._choices.forEach((choice) => {
          if (matching.filters.includes(choice._id.toString())) {
            positionFilters.push(choice._id.toString());
          }
        });
      });

    criteria
      .filter((criterion) => criterion.key === 'TECHNOS')
      .forEach((criterion) => {
        criterion._choices.forEach((choice) => {
          if (matching.filters.includes(choice._id.toString())) {
            techFilters.push(choice._id.toString());
          }
        });
      });

    return { filters: concat(contractFilters, durationFilters, mobilityFilters, positionFilters, techFilters), date: availabilityFilters };
  };

  /**
   * Localization
   */
  static localizationType = (location = {}) => isEmpty(location) ? 'virtual' : 'physique';
  static localizationObject = (location = {}) => isEmpty(location) ? {} : location;
  static localizationName = (location = {}) => isEmpty(location) ? '' : location.formattedAdress;
  static localizationsObject = (locations = []) => isEmpty(locations) ? [] : locations;
  static localizationsName = (locations = []) => isEmpty(locations) ? [] : locations.map((location) => location.formattedAdress);
}
