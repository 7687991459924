import React, { useEffect, useState } from 'react';
import { array, func, object, string, bool, oneOf } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classnames from 'classnames';
import { debounce, get } from 'lodash';
import { Table as TableUi, Pagination } from '@seekube-tech/ui-kit';
import { Button } from '@seekube-tech/ui-kit';
import { toJS } from '@/utils';
import { highlightText } from '@/utils/text';
import { track } from '@/utils/analytics';
import { ACL, ANALYTICS_RECRUITER, EVENT_FORMAT } from '@/utils/constants';

// Store
import { appointmentActions } from '@/store/appointment';
import { exponentActions, exponentSelectors } from '@/store/exponent';
import { authSelectors, authActions } from '@/store/auth';
import { organizationActions } from '@/store/organization';
import { organizationSelectors } from '@/store/organization';

// Components
import LoadingIndicator from '@/components/LoadingIndicator';
import DropdownOptions from '@/components/DropdownOptions';
import Separator from '@/components/Separator';
import InputMaterial from '@/components/Input/InputMaterial';
import ColumnsUi from '@/scenes/Admin/scenes/Organizations/scenes/Organization/scenes/Jobdating/ColumnsUi';
import SubColumnsUi from '@/scenes/Admin/scenes/Organizations/scenes/Organization/scenes/Jobdating/SubColumnsUi';
import { getKeyMomentFormats } from '@/helpers/exponent/getKeyMomentFormats';
import { arrayContainAll } from '@/utils/math';
import { eventActions } from '@/store/event';
import ModalAtsSource from '@/components/ModalAtsSource';
import AddJobDatingModal from '../../components/AddJobdatingModal';
import CollaboratorModal from '../../../../../../../Event/scenes/Owner/scenes/OnBoarding/containers/CollaboratorModal';
import RemoveUserModal from '../../components/RemoveUserModal';
import RemoveExponentUserModal from '../../components/RemoveExponentUserModal';

// Styles & Translations
import styles from '../../../../../../../Event/scenes/Owner/scenes/OnBoarding/containers/Dashboard/styles.less';
import messages from '../../../../../../../Event/scenes/Owner/scenes/OnBoarding/containers/Dashboard/messages';
import DataSource
  from '../../../../../../../Event/scenes/Owner/scenes/OnBoarding/containers/Dashboard/components/DataSource';
import EditUserModal
  from '../../../../../../../Event/scenes/Recruiter/scenes/Preparation/scenes/Team/containers/EditUserModal';
import { useFormatMessage } from 'react-intl-hooks';

const PAGE_SIZE = 10;
const OrganizationJobdating = ({
  match,
  exponents,
  context,
  getExponentsByOrganization,
  patchOrganization,
  deleteExponent,
  patchExponent,
  setCurrentExponentAction,
  isFetching,
  authUser,
  organization,
  getOrganization,
  switchAuth,
  postExponents,
  getEvents,
  pagination,
}) => {
  const t = useFormatMessage();
  const { params } = match;

  const [modalAtsSourceIsOpen, setModalAtsSourceIsOpen] = useState(false);
  const [modalCollaboratorIsOpen, setModalCollaboratorIsOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [confirmRecruiterModalIsVisible, setConfirmRecruiterModalIsVisible] = useState(false);
  const [confirmRemoveExponentIsVisible, setConfirmRemoveExponentIsVisible] = useState(false);
  const [editUserModalProperties, setEditUserModalProperties] = useState({ visible: false, context: ACL.EDIT_ROLE })
  const [currentExponent, setCurrentExponent] = useState({});
  const [currentExponentUser, setCurrentExponentUser] = useState({});
  const [addJobDatingModalIsVisible, setAddJobDatingModalIsVisible] = useState(false);
  const [archive, setArchive] = useState(false);
  const [dropdownLabel, setDropdownLabel] = useState('A venir');


  useEffect(() => {
    if (context === 'admin') {
      getEvents({ offset: 0, page: 1, limit: 100, context: 'jobdating' });
    }

    getExponentsByOrganization({
      organizationId: params.organizationId || get(authUser, '_currentOrganization._id'),
      page: 1,
      offset: 0,
      limit: PAGE_SIZE,
      archive,
    });

    getOrganization(params.organizationId || get(authUser, '_currentOrganization._id'));
  }, []);


  const onToolsClick = (event) => {
    event.stopPropagation();
  };

  const preventExpand = (event) => {
    event.stopPropagation();
  };

  const openCollaboratorModal = (exponent) => {
    setCurrentExponentAction({ exponentId: exponent._id });
    setModalCollaboratorIsOpen(true)
  };

  const openAtsSourceModal = (exponent) => {
    setModalAtsSourceIsOpen(true);
    setCurrentExponentAction({ exponentId: exponent._id });
    setCurrentExponent(exponent);
  };

  const closeCollaboratorModal = () => setModalCollaboratorIsOpen(false);

  const handleOnChangeSearchText = debounce((val) => {
    getExponentsByOrganization({
      organizationId: params.organizationId || get(authUser, '_currentOrganization._id'),
      page: 1,
      offset: 0,
      limit: PAGE_SIZE,
      archive,
      q: val
    });
  }, 500);

  const removeExponent = (exponent) => {
    deleteExponent({
      eventId: exponent._event._id,
      exponentId: exponent._id,
      noReload: true,
      callback: () => {
        getExponentsByOrganization({
          organizationId: params.organizationId || get(authUser, '_currentOrganization._id'),
          page: 1,
          offset: 0,
          limit: PAGE_SIZE,
          archive,
        });
      },
      notificationParams: {
        success: {
          message: t({ id: 'toasters.deleteExponent.success' })
        },
        error: {
          message: t({ id: 'toasters.deleteExponent.error' })
        }
      }
    });
  };

  const removeCollaborator = () => {
    const trackData = {
      user: currentExponentUser._user,
      event: currentExponent._event,
      name: ANALYTICS_RECRUITER.CANCELED_RECRUITER_PARTICIPATION,
    };

    patchExponent({
      eventId: currentExponent._event._id,
      exponentId: currentExponent._id,
      exponentParams: {
        deleteUserId: currentExponentUser._user._id,
      },
      notificationParams: {
        success: {
          message: t({ id: 'toasters.coworkerRemovedFromEvent.success' }),
          kind: 'info',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        },
        error: {
          style: {
            top: '5%',
          },
        },
      },
      callback: track(trackData),
    });

    setConfirmRecruiterModalIsVisible(false);
    setConfirmRemoveExponentIsVisible(false);
  };


  const inviteCollaborator = (collaboratorId, exponent) => {
    patchExponent({
      eventId: exponent._event._id,
      exponentId: exponent._id,
      exponentParams: {
        invitUser: collaboratorId,
      },
      notificationParams: {
        success: {
          message: t({ id: 'toasters.invite.success' }),
          kind: 'info',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        },
        error: {
          style: {
            top: '5%',
          },
        },
      },
    });
  };

  const handlePatchAtsSource = (exponent, source ) => {
    patchExponent({
      exponentId: exponent._id,
      eventId: exponent._event._id,
      exponentParams: {
        atsSource: source,
      },
      notificationParams: {
        success: {
          message: t({ id: 'ats.sourceUpdated.success' }),
          kind: 'info',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        },
        error: true,
      },
    });

    setModalAtsSourceIsOpen(false);
    setCurrentExponent(null)
  };

  const handleOnSwitchUser = (user) => {
    if (authUser.isAdmin) {
      switchAuth({
        email: user.username,
        notificationParams: {
          success: {
            message: t({ id: 'toasters.switch.success' }),
            kind: 'success',
            style: {
              top: '7%',
            },
          },
          error: {
            message: t({ id: 'toasters.switch.error' })
          },
        },
      });
    }
  };

  const handleRecruiterAction = (type, exponentUser, exponent) => {
    switch (type) {
      case ACL.EDIT_USER:
      case ACL.EDIT_ROLE:
        setEditUserModalProperties({  visible: true, context: type });
        setCurrentExponent(exponent);
        setCurrentExponentUser(exponentUser);
        break;

      case ACL.REMOVE_USER_FROM_ORGANIZATION:
        setConfirmRecruiterModalIsVisible(true);
        setCurrentExponent(exponent);
        setCurrentExponentUser(exponentUser);
        break;
      case ACL.REMOVE_USER_FROM_EXPONENT:
        setConfirmRemoveExponentIsVisible(true);
        setCurrentExponent(exponent);
        setCurrentExponentUser(exponentUser);
        break;

      case 'switchUser':
        handleOnSwitchUser(exponentUser._user);
        break

      case 'inviteCollaborator':
        inviteCollaborator(get(exponentUser, '_user._id'), exponent);
        break;

      case 'recruiterClick':
        localStorage.setItem('switch_quit_url', `/admin/organizations/${exponent._organization._id}/exponents`);
        handleOnSwitchUser(exponentUser._user);
        break;

      default:
        break;
    }
  };

  const handleExponentActions = (type, exponent) => {
    switch (type) {
      case 'onCreateUser':
        openCollaboratorModal(exponent); break;

      case 'onEditAtsSource':
        openAtsSourceModal(exponent); break;

      default:
        break;
    }
  };


  const handleOnAddExponents = (eventIds, keyMomentFormatsByEvent) => {
    setAddJobDatingModalIsVisible(false);

    postExponents({
      organizationId: organization._id,
      eventIds,
      keyMomentFormatsByEvent,
      userIds: [],
      notificationParams: {
        success: {
          message: t({ id: 'toasters.addEvent.success' }, { count: eventIds.length }),
          kind: 'info',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        },
        error: true,
      },
      callback: () => {
        getExponentsByOrganization({
          organizationId: organization._id,
          page: 1,
          offset: 0,
          limit: 10,
          archive,
        });
      },
    });
  };

  const canEventExpand = ({ original }) => original?.tool?.users?.length > 0

  const canExponentUserExpand = ({ original }) =>  original?.event?.format === EVENT_FORMAT.HYBRID

  const getExponentUsersData = (original) => {
    return (
      original.tool.users.map(exponentUser => ({
        authUser: original.authUser,
        ...exponentUser,
        organization: original._organization,
        event: {
          format: original.eventFormat,
        },
      }))
    );
  };

  const handleOnChangePagination = (val) => {
    const page = parseInt(val, 10);
    getExponentsByOrganization({
      organizationId: params.organizationId || get(authUser, '_currentOrganization._id'),
      page,
      offset: (page - 1) * PAGE_SIZE,
      limit: 10,
      archive,
    });

    window.scrollTo(0, 0);
  }

  const options = [];

  options.push((
    <a
      role="button"
      key={0}
      tabIndex={0}
      onClick={() => {
        setArchive(false);
        setDropdownLabel('A venir');
        getExponentsByOrganization({ organizationId: params.organizationId || get(authUser, '_currentOrganization._id'), page: 1, offset: 0, limit: PAGE_SIZE, archive }); }}
    >
      A venir
    </a>
  ));
  options.push((
    <a
      role="button"
      key={1}
      tabIndex={0} 
      onClick={() => {
        setArchive(true);
        setDropdownLabel('Passé');
        getExponentsByOrganization({ organizationId: params.organizationId || get(authUser, '_currentOrganization._id'), page: 1, offset: 0, limit: PAGE_SIZE, archive });
      }}
    >
      Passé
    </a>
  ));

  const orga = context === 'admin' ? organization : authUser._currentOrganization;

  if (!orga || !exponents) {
    return (<LoadingIndicator />);
  }

  return (
    <div className={classnames(styles.dashboard, styles[context])}>
      <div>
        <div className={styles.header}>
          <h1>{t(messages.exponentByOrganizationTitle)}{` ${orga.name} (${pagination.total})`}</h1>
          {context !== 'client' &&
            <div className={styles.actions}>
              <div className={styles.filter}>
                <div className={styles.selectBox}>
                  <DropdownOptions getPopupContainer={(triggerNode) => triggerNode.parentNode} label={dropdownLabel} placement="bottomRight">
                    {options}
                  </DropdownOptions>
                </div>
              </div>
              <Button onClick={() => { setAddJobDatingModalIsVisible(true); }}>
                {t(messages.addJobdating)}
              </Button>
            </div>
          }
        </div>
        <div className={styles.searchOrganization}>
          <InputMaterial
            type="string"
            placeholder={t(messages.searchByJobdating)}
            addOnIcon="search"
            onChange={(e) => handleOnChangeSearchText(e.target.value)}
          />
        </div>
        <TableUi
          columns={ColumnsUi(null, context, !archive, orga)}
          isLoadingData={isFetching}
          data={DataSource(
            exponents,
            searchText,
            highlightText, {
              authUser,
              actions: {
                handleExponentActions,
                openCollaboratorModal,
                removeExponent,
                onToolsClick,
                patchExponent,
                preventExpand,
                patchOrganization,
              },
            })}
          enableExpansion
          getCanRowExpand={canEventExpand}
          getCustomTableProps={() => ({ style: { width: '100%' } })}
          renderRowSubComponent={({ row: { original }, row, rowProps }) => (
            <TableUi
              getCustomTableProps={() => ({ style: { width: '100%' } })}
              columns={SubColumnsUi(original.tool._event, [context], handleRecruiterAction, original.tool, handleOnSwitchUser)}
              data={getExponentUsersData(original)}
              enableExpansion
              hasSeparator={false}
              getCanRowExpand={canExponentUserExpand}
              renderAdditionalRow={({ row, rowProps }) => {
                if (original.tool._event.format === 'hybrid' && arrayContainAll(getKeyMomentFormats(original.tool), [EVENT_FORMAT.PHYSICAL, EVENT_FORMAT.VIRTUAL])) {
                  const disableValue = <span style={{ color: '#A7B6D2' }}>-</span>;
                  const noValue = <span style={{ color: '#A7B6D2' }}>•</span>;
                  return (
                    <>
                      {[{
                        name: <div style={{ fontSize: '12px', padding: '12px 14px', color: '#3C4E73' }}>
                          <FormattedMessage id="calendar.settingsSlot.participationMode.physical" />
                        </div>,
                        ...row.original.physical,
                        countOffersPublished: noValue,
                        countConferences: original.tool._event.modules.live.recruiterEnable ? noValue : disableValue,
                        context: 'additionalRow',
                      },
                        {
                          name: <div style={{ fontSize: '12px', padding: '12px 14px', color: '#3C4E73' }}>
                            <FormattedMessage id="virtual" />
                          </div>,
                          ...row.original.virtual,
                          countOffersPublished: noValue,
                          countConferences: original.tool._event.modules.live.recruiterEnable ? noValue : disableValue,
                          context: 'additionalRow',
                        },

                      ].map((x, i) => (
                        <tr
                          {...rowProps}
                          key={`${rowProps.key}-expanded-${i}`}
                        >
                          {row.cells.map((cell) => {
                            const isExpanderColumn = cell.column.id === "expander";

                            return (
                              <td
                                className={isExpanderColumn && styles.additionalRowNotVisible}
                                {...cell.getCellProps()}
                              >
                                {cell.render('Cell', {
                                  value:
                                    cell.column.accessor &&
                                    cell.column.accessor(x, i),
                                  row: { ...row, original: x }
                                })}
                              </td>
                            );
                          })}
                        </tr>
                      ))}
                    </>
                  )
                }
                return null
              }}
            />
          )}
        />

        <Separator height={24} />
        <Pagination
          className="flex justify-center"
          current={pagination.current}
          pageSize={pagination.pageSize}
          total={pagination.total}
          onChange={handleOnChangePagination}
        />
      </div>

      <CollaboratorModal
        context="admin"
        isOpen={modalCollaboratorIsOpen}
        onCancel={closeCollaboratorModal}
        onOk={closeCollaboratorModal}
      />

      <ModalAtsSource
        visible={authUser?._currentOrganization?.syncAts?.enable && modalAtsSourceIsOpen}
        syncAts={authUser?._currentOrganization?.syncAts}
        defaultSource={currentExponent?.atsSource || null}
        onClose={() => setModalAtsSourceIsOpen(false) }
        onSourceSelected={(val) => {
          handlePatchAtsSource(currentExponent, val);
        }}
      />

      <EditUserModal
        isOpen={editUserModalProperties.visible}
        onSubmit={() => { getExponentsByOrganization({ organizationId: params.organizationId || get(authUser, '_currentOrganization._id'), page: 1, offset: 0, limit: PAGE_SIZE, archive }); }}
        onClose={() => {
          setEditUserModalProperties({ ...editUserModalProperties, visible: false });
          setCurrentExponent(null);
        }}
        user={get(currentExponentUser, '_user')}
        organization={currentExponent?._organization}
        context={editUserModalProperties.context}
      />

      <RemoveUserModal
        visible={confirmRecruiterModalIsVisible}
        onOk={removeCollaborator}
        handleCancel={() => setConfirmRecruiterModalIsVisible(false)}
      />

      <RemoveExponentUserModal
        visible={confirmRemoveExponentIsVisible}
        onOk={removeCollaborator}
        handleCancel={() => setConfirmRemoveExponentIsVisible(false)}
      />

      <AddJobDatingModal
        visible={addJobDatingModalIsVisible}
        handleClose={() => { setAddJobDatingModalIsVisible(false) }}
        onSubmit={handleOnAddExponents}
        exponents={[]}
        context="jobdating"
        existEvents={exponents.map((e) => e._event._id)}
        user={get(currentExponentUser, '_user')}
      />
    </div>
  );
}

OrganizationJobdating.propTypes = {
  match: object,
  exponents: array,
  context: oneOf(['client', 'admin']),
  getExponentsByOrganization: func,
  patchOrganization: func,
  deleteExponent: func,
  patchExponent: func,
  setCurrentExponent: func,
  isFetching: bool,
  intl: object,
  authUser: object,
  organization: object,
  getOrganization: func,
  switchAuth: func,
  postExponents: func,
  getEvents: func,
  pagination: object
};

const mapStateToProps = createStructuredSelector({
  exponents: exponentSelectors.getExponents,
  isFetching: exponentSelectors.getExponentsFetching,
  pagination: exponentSelectors.getExponentPagination,
  authUser: authSelectors.getAuthUser,
  organization: organizationSelectors.getSpecificOrganization,
});

const mapDispatchToProps = {
  getOrganization: organizationActions.getOrganization,
  setCurrentExponentAction: exponentActions.setCurrentExponent,
  getExponentsByOrganization: exponentActions.getExponentsByOrganization,
  downloadAppointments: appointmentActions.downloadAppointments,
  deleteExponent: exponentActions.deleteExponent,
  patchExponent: exponentActions.patchExponent,
  patchOrganization: organizationActions.patchOrganization,
  switchAuth: authActions.switchAuth,
  postExponents: organizationActions.postExponents,
  getEvents: eventActions.getEvents
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withRouter,
  withConnect,
  injectIntl,
)(toJS(OrganizationJobdating));
