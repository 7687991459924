import React, { useEffect, useState } from 'react';
import { object, func, string, bool, any } from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { useFormatMessage } from 'react-intl-hooks';
import { FormattedMessage } from 'react-intl';
import { Form } from 'antd';


import { useDebounce } from 'react-use';
import { If } from '@/components/If';
import InputMaterial from '@/components/Input/InputMaterial';

import { Organization2 } from '../Organization';
import CreateOrganization from '../CreateOrganization';

import { useGetOrganizationsProfileSearch } from '@/queries/organizations/useGetOrganizationsProfileSearch';

import styles from './styles.less';

const FormItem = Form.Item;


const PickOrganization = ({
  authUser,
  view,
  nameToCopy,
  onSelect,
  onCreateOrganization,
  onSelectCopyNameInInput,
  messageError = "Le nom de l'organisation est obligatoire",
  errCompanyExist = '',
  organizationSelected,
  keywords,
  setKeywords,
  form: { getFieldDecorator, setFieldsValue },
}) => {
  const t = useFormatMessage();
  const [debouncedKeywords, setDebouncedKeywords] = useState(keywords);

  useDebounce(() => setDebouncedKeywords(keywords), 300,[keywords]);

  const organizationsQuery = useGetOrganizationsProfileSearch({ keywords: debouncedKeywords, keepPreviousData: true });

  useEffect(() => {
    setFieldsValue({ name: nameToCopy });
  }, [nameToCopy]);

  const handleOnChange = ({ target: { value: keyWords } }) => {
    setKeywords(keyWords);
  };

  const handleSelectOrganization = (organization) => {
    // Hide result and return organization
    if (onSelectCopyNameInInput) {
      setFieldsValue({ name: organization.name });
    }

    setKeywords(onSelectCopyNameInInput ? organization.name : null);

    onSelect(organization);
  };

  const renderOrganizations = () => (
    <div className={styles.resultContainer} id="addOrganizationList">
      {organizationsQuery?.data?.docs?.length > 0 ?
        organizationsQuery?.data?.docs
          .map((organization) => (
            <Organization2
              key={organization._id}
              organization={organization}
              onSelect={handleSelectOrganization}
              authUser={authUser}
            />)
          ) : <div className={styles.noResult}><FormattedMessage id="event.owner.exponents.add.no.result" /></div>}
    </div>
  );

  return (
    <div className={styles.pickOrganization}>
      {
        view === 'createOrganization' ?
          <CreateOrganization
            onSubmit={onCreateOrganization}
            errCompanyExist={errCompanyExist}
          />
          :
          <>
            <FormItem label="">
              {getFieldDecorator('name', {
                initialValue: keywords,
                rules: [{ required: true, message: messageError }],
              })(
                <InputMaterial
                  addOnIcon="building"
                  validations={['required']}
                  label={t({ id: 'live.creation.orga.placeholder' })}
                  value={keywords}
                  onChange={handleOnChange}
                  placeholder={t({ id: 'live.creation.orga.placeholder' })}
                />
              )}
            </FormItem>
            <If condition={!isEmpty(keywords) && organizationSelected?.name !== keywords}>
              {renderOrganizations()}
            </If>
          </>
      }
    </div>
  );
};

const withForm = Form.create();

PickOrganization.propTypes = {
  errCompanyExist: any,
  form: object,
  authUser: object,
  onSelectCopyNameInInput: bool,
  view: string,
  initialValue: string,
  nameToCopy: string,
  messageError: string,
  onSelect: func,
  onCreateOrganization: func,
};

export default withForm(PickOrganization);
