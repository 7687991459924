import React, { useState } from 'react';
import { object, string, func, array } from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { useFormatMessage } from 'react-intl-hooks';
import { createStructuredSelector } from 'reselect';
import { AlignLeft } from 'react-feather';
import { Button } from '@seekube-tech/ui-kit';
import { Popover } from 'antd';
import { toJS } from '@/utils';

import copyToClipboard from '@/utils/copyToClipboard';
import { authSelectors } from '@/store/auth';
import Icon from '@/components/Icon';
import Avatar from '@/components/Avatar';

import styles from './styles.less';
import { getParticipant as getParticipantAction } from '../../../../../../store/participant/actions';
import { notificationActions } from '../../../../../../store/notification';
import Details from '../Details';
import EditInformal1to1Description from '../EditInformal1to1Description/EditInformal1to1Description';
import { getApiUrl } from '@/utils/url';

function AppointmentDetails({
  event,
  appointmentColor,
  getParticipant,
  timezone,
  closePopover,
  sendNotification,
  authUser,
  jobfairs,
  handleOnInformalDescriptionChange,
  informalDescription,
  currentEvent,
}) {
  const t = useFormatMessage();
  const [isPopoverVisible, setPopoverVisible] = useState(false);

  const isMyAppointment = authUser._id === event._appointment._organizationUser;
  const jobfair = isMyAppointment ? jobfairs.find(jobfair => jobfair.id === currentEvent._id) : undefined;
  const appointment = event._appointment;
  const appointmentStart = moment.tz(event.start, timezone);
  const appointmentEnd = moment.tz(event.end, timezone);
  const today = moment.tz(new Date(), timezone);
  const isInformal1to1 = event.type === "informal1to1";
  const canSeeProfile = jobfair
    && moment.tz(jobfair.keyDates.hunt.beginAt, timezone).isBefore(today)
    && moment.tz(jobfair.keyDates.jobfair.endAt, timezone).isAfter(today);

  function openProfileInNewTab() {
    getParticipant({
      eventId: appointment._event.slug,
      userId: appointment._user.id,
      noLoader: true,
      openProfile: true,
      redirectUrl: `/${appointment._event.slug}/recruiter/jobdating/`,
    });
  }

  function copyVisioShareUrlToClipboard() {
    copyToClipboard(getApiUrl(appointment.shareVisioUrl));
    sendNotification({
      message: t({ id: 'event.recruiter.preparation.timeslots.event.visioShareLinkCopied' }),
      kind: 'success',
      style: {
        bottom: '7%',
      },
    });
  }
  /**
   * Handle popover visibility
   * @param {Boolean} visibility
   */
  const handleTogglePopover = (visibility = false) => {
    setPopoverVisible(visibility);
  };

  return (
    <Popover
      placement="right"
      content={
        <EditInformal1to1Description description={event.informalDescription} closePopover={() => handleTogglePopover(false)} event={event} handleOnInformalDescriptionChange={handleOnInformalDescriptionChange} isPopoverVisible={isPopoverVisible} />
      }
      visible={isPopoverVisible}
      onVisibleChange={handleTogglePopover}
      trigger="click"
      zIndex={1000} // mandatory because the Agenda Modal is in the hundreds
    >
      <Details type={event.type} closePopover={closePopover} >
        <div className={styles.row}>
          <div>
            <Avatar size={24} user={appointment._user} src={appointment._user.pictureUrl} />
          </div>
          <div className={styles.spaceBetween}>
            <p>{appointment._user.fullName}</p>
            {
              canSeeProfile && (
                <a
                  role="button"
                  tabIndex={0}
                  onClick={() => openProfileInNewTab()}
                >
                  {t({ id: 'event.recruiter.preparation.timeslots.event.profileLink' })}
                </a>
              )
            }
          </div>
        </div>
        <div className={styles.row}>
          <div>
            <div className={styles.colorBadge} style={{ backgroundColor: appointmentColor }} />
          </div>
          <p>{appointment._event.name}</p>
        </div>
        <div className={styles.row}>
          <div>
            <Icon name="clock" className={styles.icon} style={{ width: 16, height: 16 }} />
          </div>
          <div>
            <p>{appointmentStart.format('dddd DD MMMM YYYY')}</p>
            <p className={styles.appointmentHours}>{appointmentStart.format('HH:mm')} - {appointmentEnd.format('HH:mm')}</p>
          </div>
        </div>
        {
          appointment.settings.medium === 'physical' && (
            <div className={styles.row}>
              <div>
                <Icon name="location-world" className={styles.icon} style={{ width: 16, height: 16 }} />
              </div>
              <div>
                <p>{appointment.settings.location}</p>
              </div>
            </div>
          )
        }
        <div className={styles.row}>
          <div>
            <Icon name="phone" className={styles.icon} style={{ width: 16, height: 16 }} />
          </div>
          <div>
            <p>{appointment._user.phone}</p>
          </div>
        </div>
        {
          appointment.settings.medium === 'visio' && (
            <>
              <div className={styles.row}>
                <div>
                  <Icon name="camera" className={styles.icon} style={{ width: 16, height: 16 }} />
                </div>
                <div className={styles.spaceBetween}>
                  <p>{t({ id: 'event.recruiter.preparation.timeslots.event.visioLabel' })}</p>
                  <div className={styles.column}>
                    <a href={getApiUrl(appointment.recruiterVisioUrl)} target="_blank">
                      <Button>
                        {t({ id: 'event.recruiter.preparation.timeslots.event.openVisioLink' })}
                      </Button>
                    </a>
                  </div>
                </div>
              </div>
              <div className={styles.shareUrl}>
                <a role="button" tabIndex={0} onClick={() => copyVisioShareUrlToClipboard()}>{t({ id: 'event.recruiter.preparation.timeslots.event.shareVisioLink' })}</a>
              </div>
            </>
          )
        }
        {
          isInformal1to1 && (
            <>
              <div className={styles.row}>
                <div>
                  <AlignLeft styles={styles.icon} style={{ width: 16, height: 16 }} />
                </div>
                <div>
                  <p>{informalDescription}</p>
                </div>
              </div>
              <div className={styles.editDesciptionBtnContainer}>
                <button className={styles.editDesciptionBtn} onClick={() => handleTogglePopover(true)}>Modifier la description</button>
              </div>
            </>
          )
        }
      </Details>
    </Popover>
  );
}

AppointmentDetails.propTypes = {
  event: object.isRequired,
  appointmentColor: string.isRequired,
  timezone: string.isRequired,
  getParticipant: func,
  closePopover: func.isRequired,
  sendNotification: func,
  authUser: object,
  jobfairs: array.isRequired,
  handleOnInformalDescriptionChange: func,
  informalDescription: string,
  currentEvent: object,
};

const mapDispatchToProps = {
  getParticipant: getParticipantAction,
  sendNotification: notificationActions.sendNotification,
};

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
});

export default connect(mapStateToProps, mapDispatchToProps)(toJS(AppointmentDetails));
