import { useQuery } from 'react-query';
import request from '@/utils/request';

export function useGetKpis({ begin, end, authToken }) {
  const requestUrl = `${process.env.FRONT_GO_API_URL}/stats/kpi?begin=${begin}&end=${end}`;
  const queryFn = () => request(requestUrl, { method: 'GET' });

  const query = useQuery({
    queryKey: ['kpi', begin, end],
    queryFn,
    refetchOnWindowFocus: false,
    cacheTime: 3000,
  });
  return query;
}
