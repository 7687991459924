import React, { memo, useEffect, useRef, useState } from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { Alert } from '@seekube-tech/ui-kit';
import { bool } from 'prop-types';
import { useLocation } from 'react-router-dom';
import styles from './styles.less';
import Wrapper from '@/components/Wrapper';
import { LOCAL_STORAGE, removeLocalStorage } from '@/utils/localStorage';

const SignupSuccessAlert = memo(({ isJpo = false }) => {
  const t = useFormatMessage();
  const signUpSuccess = window.localStorage.getItem('signupSuccess') === 'true';
  const { pathname } = useLocation();
  const [showSuccess, setShowSuccess] = useState(false);
  const { current: originalUrl } = useRef(pathname);
  const alertWording = isJpo ? 'event.candidate.signup.jpo.success' : 'event.candidate.signup.success';
  
  useEffect(() => {
    setShowSuccess(signUpSuccess === true);
    removeLocalStorage(LOCAL_STORAGE.event.signUpSuccess);
  }, []);

  useEffect(() => {
    const isSameUrl = originalUrl === pathname;
    
    setShowSuccess(isSameUrl && signUpSuccess)
  }, [pathname]);
  
  if (!showSuccess) {
    return null;
  }

  return (<Wrapper size="large" className={styles.alertContainer}>
    <Alert color="success">{t({ id: alertWording })}</Alert>
  </Wrapper>)
});

SignupSuccessAlert.propTypes = {
  isJpo: bool
}

export { SignupSuccessAlert };