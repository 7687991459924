import React from 'react';
import { FormattedMessage } from 'react-intl';
import { oneOf, number } from 'prop-types';

import { IconEyeOff, Body2 } from '@seekube-tech/ui-kit';
import colors from '@seekube-tech/ui-kit/dist/colors';
import messages from '../../messages';
import styles from './styles.less';
import { useFormatMessage } from 'react-intl-hooks';


const Banner = ({ status, count = 0 }) => {
  const t = useFormatMessage();

  if (status === 'published' || count === 0) return '';
  let bannerProperties = {};

  switch (status) {
    case 'draft': bannerProperties = { icon: <IconEyeOff size={16} fill={colors.neutral['300']} />, message: messages.offerDraftCount }; break;

    default: break;
  }
  return (
    <div className={`${styles.statusBanner} banner-draft whitespace-nowrap`}>
      <span>{bannerProperties.icon}</span>
      <Body2 className="mr-20" color={colors.neutral['500']} fontWeight={700}>
        <span>{t({ id: 'event.recruiter.preparation.offer.draft.count' }, { count })}</span>
      </Body2>
    </div>
  );
};

Banner.propTypes = {
  status: oneOf(['published', 'draft', 'expired']),
  count: number,
};

export { Banner };
