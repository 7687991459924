import React from 'react';
import { FormattedMessage } from 'react-intl';
import { array, object } from 'prop-types';
import { size } from 'lodash';
import { AvatarBusiness } from '@seekube-tech/ui-kit';

import { mapAndJoin } from '@/utils/text';
import styles from './styles.less';
import { getLocale } from '@/utils/localStorage';

const Planners = ({ organizations = [], event, avatarSize = 'medium' }) => (
  organizations.length ?
    <div className={styles.owner}>
      <h2>
        <FormattedMessage id="event.landing.organizedBy.test" values={{ count: size(organizations) }} /><br />
        {mapAndJoin(organizations, 'name', ', ')}
      </h2>
      {
        organizations.map((orga) =>
          <AvatarBusiness
            size={avatarSize}
            pictureUrl={(orga.profile?.logo || orga.profile?.pictureUrl)}
            variant="business"
            color={orga.isPartner ? event?.modules?.partner?.color : 'default'}
            badge={orga.isPartner ? event?.modules?.partner?.icon : null}
            tooltip={event?.modules?.partner?.[`label_${getLocale()}`]}
          />
        )
      }
    </div> : null
);

Planners.propTypes = {
  organizations: array,
  event: object,
};

export default Planners;
