import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bool, object } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { isEmpty } from 'lodash';
import { toJS } from '@/utils';
import { authSelectors } from '@/store/auth';
import { eventSelectors } from '@/store/event';
import { useGetUserEvents } from '@/queries/events/useGetUserEvents';
import Index2 from '@/scenes/Event/scenes/Recruiter/scenes/Jobdating/scenes/Agenda/Agenda';
import { useGetEventsSelect } from '@/queries/agenda/calendar/useGetEventsSelect';

const RecruiterJobdatingAgendaScene = ({ authUser, event, isOpen }) => {
  const isClient = authUser?._currentOrganization?.isClient;
  const isAgendaIconVisible = isClient || (!isEmpty(event) && !isClient);
  const userEventsQuery = useGetUserEvents({
    authUser, context: 'exponent', limit: 200, page: 1, loader: false, enabled: !!authUser && isAgendaIconVisible, });

  const authEventsQuery = useGetEventsSelect({
    userId: authUser._id,
    cacheTime: 0,
  });

  const isAgendaNotReady = !userEventsQuery.isLoading && !userEventsQuery.isSuccess && !authEventsQuery.isLoading && !authEventsQuery.isSuccess && !isOpen;

  if (isAgendaNotReady) {
    return null;
  }

  // TODO: remove this when we have a better way to handle this, this is a temporary fix
  const currentEvent = userEventsQuery?.data?.docs.find((e) => e._id === event?._id)
    || userEventsQuery?.data?.docs.find((e) => e._id === authEventsQuery?.data?.docs[0]?._id);

  return (
    <Index2
      isOpen={isOpen}
      authUser={authUser}
      event={currentEvent}
      events={userEventsQuery?.data?.docs}
    />
  );
}

RecruiterJobdatingAgendaScene.propTypes = {
  authUser: object,
  event: object,
  isOpen: bool,
}

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
  event: eventSelectors.getCurrentEvent,
});


const withConnect = connect(mapStateToProps, null);

export default compose(
  withConnect,
  withRouter,
)(toJS(RecruiterJobdatingAgendaScene));
