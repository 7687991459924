import React from 'react';
import { oneOf } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { IconWifi } from '@seekube-tech/ui-kit';
import appMessages from '@/messages';
import messages from './messages';
import Icon from '@/components/Icon';

const Properties = ({ mode }) => (
  {
    duplicate: {
      title: {
        text: <FormattedMessage {...messages.duplicateEventTitle} />,
      },
      cta: {
        text: <FormattedMessage {...messages.duplicate} />,
      },
    },
    new: {
      title: {
        text: <FormattedMessage {...messages.createEventTitle} />,
      },
      cta: {
        text: <FormattedMessage {...messages.create} />,
      },
    },
    default: {
      name: {
        name: 'name',
        label: <FormattedMessage {...messages.inputNameLabel} />,
        html: {
          placeholder: 'Femmes ingénieur 2017',
        },
      },
      type: {
        name: 'type',
        type: 'string',
        label: <FormattedMessage {...messages.jobDatingTypeLabel} />,
        html: [
          { value: 'school', text: <><Icon name="formation" /><FormattedMessage {...messages.jobDatingTypeSchool} /></> },
          { value: 'company', text: <><Icon name="building" /><FormattedMessage {...messages.jobDatingTypeCompany} /></> },
          { value: 'seekube', text: <><Icon name="logo" />Seekube</> },
          { value: 'jpo', text: <>Jpo</> },
        ],
      },
      format: {
        name: 'format',
        type: 'string',
        label: 'Format',
        html: [
          { value: 'virtual', text: <><IconWifi style={{ fill: 'transparent' }} /><FormattedMessage id="event.localization.virtual" /></> },
          { value: 'physical', text: <><Icon name="building" /><FormattedMessage id="event.localization.physic" /></> },
          { value: 'hybrid', text: <><Icon name="logo" /><FormattedMessage id="event.localization.hybrid" /></> },
        ],
      },
      openB2b: {
        name: 'openB2b',
        type: 'boolean',
        label: <FormattedMessage {...messages.eventOpenB2b} />,
        html: [
          { value: true, text: <FormattedMessage {...messages.yes} /> },
          { value: false, text: <FormattedMessage {...messages.no} /> },
        ],
      },
      targets: {
        name: 'targets',
        type: 'array',
        label: <FormattedMessage {...messages.targetsLabel} />,
        html: [
          { value: 'engineer', text: <FormattedMessage {...messages.targetsEngineer} /> },
          { value: 'tech', text: <FormattedMessage {...messages.targetsTech} /> },
          { value: 'business', text: <FormattedMessage {...messages.targetsBusiness} /> },
          { value: 'other', text: <FormattedMessage {...messages.targetsOther} /> },
        ],
      },
      errorFieldMessage: <FormattedMessage {...messages.errorFieldRequired} />,
    },
  }[mode]
);

Properties.propTypes = {
  mode: oneOf(['new', 'duplicate', 'default']),
};

export { Properties };
