import React from 'react';
import { func, object, string, any } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Menu, Dropdown } from 'antd';
import Icon from '@/components/Icon';
import { IconSettings2, IconPlusCircle, IconEdit2, IconTrash2, IconMail, IconLock } from '@seekube-tech/ui-kit';

import styles from '../../../styles.less';
import messages from './messages';
import { ACL } from '@/utils/constants';
import { checkAcl, checkUserAcl, getCurrentRole, useUserAclHandler, handleUserAcl } from '@/store/role/helpers';

const { Item: MenuItem } = Menu;

const ActionMenuItem = ({ intlMessage, intlValues = {}, icon, className, onClick }) => (
  <a role="button" tabIndex={0} onClick={onClick} className={className}>
    {icon}
    <FormattedMessage {...messages[intlMessage]} values={intlValues} />
  </a>
);

const ActionColumn = ({
  onEdit,
  authUser,
  onAddJobdating,
  onEditPassword,
  onToolsClick,
  onDeleteUser,
  organization,
  onSendInvitation,
  record,
  context = 'admin',
}) => {
  let actionsItems;
  const getDropdownItems = (menuItem) => {
    // TODO: Ajouter les context pour les non admin, ex: client/member
    const adminContext = window.location.href.indexOf('client/member') === -1;

    const isRecordUserTheAuthUser = record._id === authUser._id;

    const { checkAcl, role, isSAdmin } = useUserAclHandler(authUser);
    const userAclHandler = useUserAclHandler(record, organization);
    const hasAuthUserPriority = adminContext && isSAdmin ? true : role.priority >= userAclHandler.role.priority;

    switch (menuItem) {
      case ACL.EDIT_USER:
        return (checkAcl(ACL.EDIT_USER) && hasAuthUserPriority || isRecordUserTheAuthUser) && record.isActive && <ActionMenuItem intlMessage="modifyProfile" icon={<IconEdit2 size={16} />} onClick={() => onEdit(record, ACL.EDIT_USER)} />;
      case 'handlePassword':
        return adminContext ? <ActionMenuItem intlMessage="handlePassword" icon={<IconLock size={16} />} onClick={() => onEditPassword(record)} /> : null;
      case 'addJobdating':
        return <ActionMenuItem intlMessage="addJobdating" icon={<IconPlusCircle size={16} />} onClick={() => onAddJobdating(record)} />;
      case ACL.INVITE_USER:
        return !record.isActive && <ActionMenuItem intlMessage="sendInvitation" icon={<IconMail size={16} />} onClick={() => onSendInvitation(record)} />;
      case ACL.EDIT_ROLE:
        return organization.isClient && checkAcl(ACL.EDIT_ROLE) && hasAuthUserPriority && <ActionMenuItem intlMessage={ACL.EDIT_ROLE} icon={<IconSettings2 size={16} />} onClick={() => onEdit(record, ACL.EDIT_ROLE)} />;
      case ACL.REMOVE_USER_FROM_ORGANIZATION:
        return (checkAcl(ACL.REMOVE_USER_FROM_ORGANIZATION) || isRecordUserTheAuthUser) && <ActionMenuItem intlMessage="removeUser" icon={<IconTrash2 size={16} />} onClick={() => onDeleteUser(record)} className="error" />;
      default:
        return null;
    }
  };

  switch (context) {
    case 'admin': actionsItems = [ACL.EDIT_USER, 'addJobdating', ACL.INVITE_USER, ACL.EDIT_ROLE, ACL.REMOVE_USER_FROM_ORGANIZATION]; break;
    case 'admin_users': actionsItems = [ACL.EDIT_USER, 'addJobdating', ACL.INVITE_USER, ACL.EDIT_ROLE, 'handlePassword', ACL.REMOVE_USER_FROM_ORGANIZATION]; break;

    default: actionsItems = [ACL.EDIT_USER, 'addJobdating', ACL.INVITE_USER, ACL.EDIT_ROLE, ACL.REMOVE_USER_FROM_ORGANIZATION];
  }

  return (
    <div className={styles.actionContainer}>
      <Dropdown
        overlay={
          <Menu>
            {actionsItems.map((a, i) => {
              const dropdownItem = getDropdownItems(a);

              return dropdownItem && <MenuItem key={i}>{dropdownItem}</MenuItem>;
            })}
          </Menu>
        }
        trigger={['hover']}
        placement="bottomRight"
      >
        <a role="button" tabIndex={0} onClick={onToolsClick}>
          <Icon
            name="more-vertical"
            className={styles.iconGrey}
            style={{ width: '24px', height: '24px', margin: '0' }}
          />
        </a>
      </Dropdown>
    </div>
  );
};

ActionMenuItem.propTypes = {
  onClick: func,
  intlMessage: string,
  intlValues: object,
  icon: any,
  className: object,
};

ActionColumn.propTypes = {
  onEdit: func,
  onAddJobdating: func,
  onEditPassword: func,
  onToolsClick: func,
  onDeleteUser: func,
  onSendInvitation: func,
  record: object,
  organization: object,
  authUser: object,
  context: string,
};

ActionColumn.defaultProps = {
  record: {},
};

export { ActionColumn };

