
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { normalize } from 'normalizr';
import { isEmpty } from 'lodash';
import request from '@/utils/request';
import { trackCanceledAppointment, trackDeclinedJobOffer } from '@/utils/analytics';
import * as actions from './actions';
import * as types from './types';
import { getAuthToken, getAuthUser } from '../auth/selectors';
import { getParticipant, getParticipants } from '../participant/actions';

import { entitiesActions } from '../entities';
import { appointmentListSchema, appointmentSchema } from './schema';
import { notificationActions } from '../notification';
import { objectToParams } from '../../utils/url';
import { eventSelectors } from '../event';
import { trackError } from '../../utils/analytics/helpers';


/**
 * GetUserAppointments
 *
 * @description
 * Get all appointments by userId and event
 */
function* getUserAppointments({ payload: { eventSlug, userId, callback } }) {
  const authUser = yield select(getAuthUser);
  const requestUrl = eventSlug ? `${process.env.FRONT_API_URL}/events/${eventSlug}/appointments?user=${userId || authUser.get('_id')}` : `${process.env.FRONT_API_URL}/appointments?user=${userId || authUser.get('_id')}`;

  try {
    const result = yield call(request, requestUrl);

    // Normalize data
    const dataNormalized = normalize(result, appointmentListSchema);

    // Save entities
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    // Merge allIds
    yield put(actions.getUserAppointmentsSuccess({
      result: dataNormalized.result,
    }));

    if (typeof callback === 'function') {
      callback(result);
    }
  } catch (err) {
    trackError(err);
  }
}

/**
 * GetAppointments
 *
 * @description
 * Get all appointments by userId, organizationId and eventId
 */
function* getAppointments({ payload: { eventId, userId, organizationId, limit = 10, offset = 0, archive = false, callback } }) {

  const searchParams = objectToParams({ limit, offset, eventId, userId, organizationId, archive });
  const requestUrl = `${process.env.FRONT_API_URL}/appointments?${searchParams}`;

  try {
    const { docs, limit, total } = yield call(request, requestUrl, {
      method: 'GET',
    });

    // Normalize data
    const dataNormalized = normalize(docs, appointmentListSchema);

    // Save entities
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    // Merge allIds
    yield put(actions.getAppointmentsSuccess({
      ids: docs.map((d) => d._id), limit, total, offset,
    }));

    if (typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    trackError(err);
  }
}

/**
 * getAppointment
 *
 * @description : Get appointment details & timeslots
 */
function* getAppointment({ payload: { appointmentID, eventSlug, redirect } }) {
  const authToken = yield select(getAuthToken);
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventSlug}/appointments/${appointmentID}`;

  try {
    const result = yield call(request, requestUrl, {
      method: 'GET',
    });

    yield put(actions.getAppointmentSuccess(result));

    if (redirect === true) {
      yield put(push(`/${eventSlug}/candidate/jobdating/appointment/${appointmentID}?hideLoader=true`));
    }
  } catch (err) {
    trackError(err);
  }
}

/**
 * selectAppointmentSlot
 *
 * @description : Select a slot for an appointment
 */
function* selectAppointmentSlot({ payload: { eventSlug, appointment, slotSelected, messageInformal1to1Candidate, participantId, notificationParams, callback } }) {
  const authUser = yield select(getAuthUser);
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventSlug}/appointments/${appointment._id}`;
  try {
    const result = yield call(request, requestUrl, {
      method: 'PUT',
      body: JSON.stringify({
        timeslot: slotSelected?._id,
        date: slotSelected?.beginAt,
        participantId,
        messageInformal1to1Candidate,
      }),
    });

    yield put(actions.selectAppointmentSlotSuccess(result));
    yield put(getParticipant({ eventId: eventSlug, userId: authUser.get('_id'), noLoader: true }));

    if (typeof callback === 'function') {
      callback();
    }

  } catch (err) {
    trackError(err);

    yield put(actions.getAppointment({
      appointmentID: appointment._id,
      eventSlug,
    }));

    if (notificationParams.error && notificationParams.error.message) {
      yield put(notificationActions.sendNotification({
        message: notificationParams.error.message,
        kind: 'error',
        style: {},
      }));

      setTimeout(() => window.location.reload(), 500);
    }
  }
}

/**
 *  RefuseAppointment
 *
 * @description
 * Candidate refuse appointment
 */
function* refuseAppointment({ payload: { appointmentId, event, eventId, eventSlug, messageCandidate, timeslotId, notificationParams, callback } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/appointments/${appointmentId}/refuse`;
  const authUser = yield select(getAuthUser);
  // Patch the appointment

  try {
    const result = yield call(request, requestUrl, {
      method: 'PATCH',
      body: JSON.stringify({
        messageCandidate,
        timeslotId,
      }),
    });

    // Normaliz data
    const dataNormalized = normalize(result, appointmentSchema);

    // Save entities
    yield put(entitiesActions.replaceEntities({ id: appointmentId, type: 'appointments', entities: dataNormalized.entities }));
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    // Push success notification
    if (notificationParams && notificationParams.success) {
      yield put(notificationActions.sendNotification({
        message: notificationParams.success.message,
        kind: notificationParams.success.kind,
        style: notificationParams.success.style,
      }));
    }
    if (!authUser.getIn(['_currentOrganization', '_id'])) {
      yield put(getParticipant({ eventId: eventSlug, userId: authUser.get('_id') }));
    }

    if (timeslotId) {
      trackCanceledAppointment({ user: authUser.toJS(), event, message: messageCandidate });
    } else {
      trackDeclinedJobOffer({ user: authUser.toJS(), event, message: messageCandidate });
    }

    if (typeof callback === 'function') {
      callback(result);
    }
  } catch (err) {
    trackError(err);

    yield put(notificationActions.sendNotification({
      message: err.message,
      kind: 'error',
      style: {},
    }));

    setTimeout(() => window.location.reload(), 500);
  }
}


/**
 * cancelAppointment
 *
 * @description : Recruiter cancel an appointment
 */
function* cancelAppointment({ payload: { appointmentId, eventSlug, eventId, userId, timeslotId, notificationParams, message, context, participantsParams, callback } }) {
  const authUser = yield select(getAuthUser);
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/appointments/${appointmentId}/cancel`;
  // if (messageCandidate.length) { params = { ...params, messageCandidate }; }
  // const requestUrl = `${process.env.FRONT_API_URL}/events/${eventSlug}/appointments/${appointmentID}`;

  try {
    yield call(request, requestUrl, {
      method: 'PATCH',
      body: JSON.stringify({
        message,
        date: null,
        _timeslot: null,
        timeslotId,
        userId,
        authUser,
      }),
    });

    if (context === 'informal1to1') {
      setTimeout(() => window.location.reload(), 1000);
    } else {
      yield put(actions.modifyAppointmentSuccess());
      yield put(actions.patchAppointmentDateSuccess({ appointmentId }));

      if (!authUser.getIn(['_currentOrganization', '_id'])) {
        yield put(getParticipant({ eventId, userId }));
      }

      if (typeof callback === 'function') {
        callback();
      }

      if (context !== 'agenda') {
        yield put(getParticipants({
          eventId: eventSlug || eventId,
          ...participantsParams,
        }));
      }
    }

    // Push success notification
    if (notificationParams && notificationParams.success) {
      yield put(notificationActions.sendNotification({
        message: notificationParams.success.message,
        kind: notificationParams.success.kind,
        style: notificationParams.success.style,
      }));
    }
  } catch (err) {
    trackError(err);
    // Push error notification
    if (notificationParams && notificationParams.error) {
      yield put(notificationActions.sendNotification({
        message: err.message,
        kind: 'error',
      }));
    }
  }
}

function* modifyAppointment({ payload: { appointmentId, eventSlug, participantsParams, timeslotId, notificationParams, messageCandidate, userId, currentUser, context, callback } }) {
  const authUser = yield select(getAuthUser);
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventSlug}/appointments/${appointmentId}`;

  try {
    yield call(request, requestUrl, {
      method: 'PATCH',
      body: JSON.stringify({
        status: 'pending',
        timeslotId,
        messageCandidate,
        userId,
        authUser: currentUser,
      }),
    });
    yield put(actions.modifyAppointmentSuccess());

    if (isEmpty(messageCandidate)) {
      if (context !== 'agenda') {
        if (timeslotId && !authUser.getIn(['_currentOrganization', '_id'])) {
          yield put(getParticipant({
            userId: participantsParams.userId,
            eventId: eventSlug,
          }));
        } else {
          yield put(getParticipants({
            eventId: eventSlug,
            participantsParams,
          }));
        }
      }
    }


    if (callback) {
      callback();
    }

    // Push success notification
    if (notificationParams && notificationParams.success) {
      yield put(notificationActions.sendNotification({
        message: notificationParams.success.message,
        kind: notificationParams.success.kind,
        style: notificationParams.success.style,
      }));
    }
  } catch (err) {
    trackError(err);
    // Push error notification
    if (notificationParams && notificationParams.error) {
      yield put(notificationActions.sendNotification({
        message: err.message,
        kind: 'error',
      }));
    }
  }
}
// Recruiter want to change the appointment date
function* patchAppointmentDate({ payload: { appointmentId, eventSlug, participantsParams, message, timeslotId, notificationParams, userId, currentUser, callback } }) {
  const authUser = yield select(getAuthUser);
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventSlug}/appointments/${appointmentId}/change_date`;

  try {
    yield call(request, requestUrl, {
      method: 'PATCH',
      body: JSON.stringify({
        message,
        timeslotId,
        userId,
        authUser: currentUser,
      }),
    });
    yield put(actions.patchAppointmentDateSuccess({ appointmentId }));

    if (timeslotId && !authUser.getIn(['_currentOrganization', '_id'])) {
      yield put(getParticipant({
        userId: participantsParams.userId,
        eventId: eventSlug,
      }));
    } else {
      yield put(getParticipants({
        eventId: eventSlug,
        ...participantsParams,
      }));
    }

    if (callback) {
      callback();
    }

    // Push success notification
    if (notificationParams && notificationParams.success) {
      yield put(notificationActions.sendNotification({
        message: notificationParams.success.message,
        kind: notificationParams.success.kind,
        style: notificationParams.success.style,
      }));
    }
  } catch (err) {
    trackError(err);
    // Push error notification
    if (notificationParams && notificationParams.error) {
      yield put(notificationActions.sendNotification({
        message: err.message,
        kind: 'error',
      }));
    }
  }
}

function* patchAppointmentOwner({ payload: { appointmentId, ownerId, eventSlug, participantsParams, message, timeslotId, notificationParams, userId, context, callback } }) {
  const authUser = yield select(getAuthUser);
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventSlug}/appointments/${appointmentId}/change_owner`;

  try {
    yield call(request, requestUrl, {
      method: 'PATCH',
      body: JSON.stringify({
        message,
        timeslotId,
        userId,
        ownerId,
        authUser,
      }),
    });
    yield put(actions.modifyAppointmentSuccess());

    if (context !== 'agenda') {
      if (timeslotId && !authUser.getIn(['_currentOrganization', '_id'])) {
        yield put(getParticipant({
          userId: participantsParams.userId,
          eventId: eventSlug,
        }));
      } else {
        yield put(getParticipants({
          eventId: eventSlug,
          ...participantsParams,
        }));
      }
    }

    if (callback) {
      callback();
    }

    // Push success notification
    if (notificationParams && notificationParams.success) {
      yield put(notificationActions.sendNotification({
        message: notificationParams.success.message,
        kind: notificationParams.success.kind,
        style: notificationParams.success.style,
      }));
    }
  } catch (err) {
    trackError(err);
    // Push error notification
    if (notificationParams && notificationParams.error) {
      yield put(notificationActions.sendNotification({
        message: err.message,
        kind: 'error',
      }));
    }
  }
}

function* downloadAppointments({ payload: { eventSlug, queryTarget, appointment, recruiterId, notificationParams, callback } }) {
  const authUser = yield select(getAuthUser);
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventSlug}/appointments/recruiter/export`;

  try {
    yield call(request, requestUrl, {
      method: 'POST',
      body: JSON.stringify({
        queryTarget,
        recruiterId,
        authUser,
        appointment,
      }),
    });
    yield put(actions.downloadAppointmentsSuccess());

    if (typeof callback === 'function') {
      callback();
    }

    if (notificationParams && notificationParams.success) {
      yield put(notificationActions.sendNotification({
        message: notificationParams.success.message,
        kind: notificationParams.success.kind,
        style: notificationParams.success.style,
      }));
    }
  } catch (err) {
    trackError(err);
  }
}

/**
 *  RelaunchAppointment
 *
 * @description
 * Candidate relaunch a recruiter for an appointment
 */
function* relaunchAppointment({ payload: { appointmentId, eventId, eventSlug, messageCandidate, notificationParams, callback } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/appointments/${appointmentId}/relaunch`;
  const authUser = yield select(getAuthUser);

  // Patch the appointment

  try {
    const result = yield call(request, requestUrl, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        messageCandidate,
      }),
    });

    // Normaliz data
    const dataNormalized = normalize(result, appointmentSchema);

    // Save entities
    yield put(entitiesActions.replaceEntities({ id: appointmentId, type: 'appointments', entities: dataNormalized.entities }));
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    // Push success notification
    if (notificationParams && notificationParams.success) {
      yield put(notificationActions.sendNotification({
        message: notificationParams.success.message,
        kind: notificationParams.success.kind,
        style: notificationParams.success.style,
      }));
    }
    if (!authUser.getIn(['_currentOrganization', '_id'])) {
      yield put(getParticipant({ eventId: eventSlug, userId: authUser.get('_id') }));
    }
    /* track({
      name: ANALYTICS_CANDIDATE[timeslotId ? 'CANCELED_APPOINTMENT' : 'DECLINED_JOB_OFFER'],
      user: authUser.toJS(),
      event,
      properties: {
        hasMessage: !isEmpty(messageCandidate),
        message: messageCandidate,
      },
    }); */
    if (typeof callback === 'function') {
      callback(result);
    }
  } catch (err) {
    trackError(err);

    yield put(notificationActions.sendNotification({
      message: err.message,
      kind: 'error',
      style: {},
    }));
    setTimeout(() => window.location.reload(), 500);
  }
}

function* confirmAppointment({ payload: { appointmentId, eventSlug, timeslotId, notificationParams, userId, currentUser, nextProfile, context, callback, search, page } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventSlug}/appointments/${appointmentId}/confirm`;

  try {
    yield call(request, requestUrl, {
      method: 'PATCH',
      body: JSON.stringify({
        status: 'pending',
        date: null,
        _timeslot: null,
        timeslotId,
        userId,
        authUser: currentUser,
      }),
    });
    yield put(actions.modifyAppointmentSuccess());

    if (nextProfile) {
      const event = yield select(eventSelectors.getCurrentEvent);
      const updatedSearch = search;
      const redirectContext = ['confirmed', 'unconfirmed', 'interview', 'refused'].includes(context) ? `interview/${context}` : context;

      updatedSearch.participant = nextProfile._id;

      const searchParams = objectToParams(updatedSearch);
      const nextUrl = `/${event.get('slug')}/recruiter/jobdating/${redirectContext}?page=${page}&${searchParams}`;

      yield put(push(nextUrl));
    }

    if (callback) {
      callback();
    }

    // Push success notification
    if (notificationParams && notificationParams.success) {
      yield put(notificationActions.sendNotification({
        message: notificationParams.success.message,
        kind: notificationParams.success.kind,
        style: notificationParams.success.style,
      }));
    }
  } catch (err) {
    trackError(err);
    // Push error notification
    if (notificationParams && notificationParams.error) {
      yield put(notificationActions.sendNotification({
        message: err.message,
        kind: 'error',
      }));
    }
  }
}

function* patchAppointment({ payload: { appointmentId, eventId, messageInformal1to1Candidate, callback } }) {
  try {
    const requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/appointments/${appointmentId}`;

    yield call(request, requestUrl, {
      method: 'PATCH',
      body: JSON.stringify({
        messageInformal1to1Candidate,
        status: 'pending',
      }),
    });

    if (typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    trackError(err);

    yield put(notificationActions.sendNotification({
      message: err.message,
      kind: 'error',
      style: {},
    }));
  }
}

/**
 * Listen Actions
 */
export default [
  takeLatest(types.GET_APPOINTMENT, getAppointment),
  takeLatest(types.SELECT_APPOINTMENT_SLOT, selectAppointmentSlot),
  takeLatest(types.CANCEL_APPOINTMENT, cancelAppointment),
  takeLatest(types.PATCH_APPOINTMENT_DATE, patchAppointmentDate),
  takeLatest(types.CHANGE_APPOINTMENT_OWNER, patchAppointmentOwner),
  takeLatest(types.MODIFY_APPOINTMENT, modifyAppointment),
  takeLatest(types.GET_USER_APPOINTMENTS, getUserAppointments),
  takeLatest(types.GET_APPOINTMENTS, getAppointments),
  takeLatest(types.REFUSE_APPOINTMENT, refuseAppointment),
  takeLatest(types.DOWNLOAD_APPOINTMENTS, downloadAppointments),
  takeLatest(types.RELAUNCH_APPOINTMENT, relaunchAppointment),
  takeLatest(types.CONFIRM_APPOINTMENT, confirmAppointment),
  takeLatest(types.PATCH_APPOINTMENT, patchAppointment),
];
