import { defineMessages } from 'react-intl';

export default defineMessages({
  pageTitle: {
    id: 'event.owner.statistics.title',
  },
  exportBtn: {
    id: 'event.owner.statistics.export.btn',
  },
  exportAppointments: {
    id: 'event.owner.statistics.export.appointments',
  },
  exportInteractions: {
    id: 'export.interactions'
  },
  exportCandidates: {
    id: 'event.owner.statistics.export.candidates',
  },
  exportCandidatesNotActive: {
    id: 'event.owner.statistics.export.candidatesnotactive',
  },
  exportCompanies: {
    id: 'event.owner.statistics.export.companies',
  },
  exportRecruiters: {
    id: 'event.owner.statistics.export.recruiters',
  },
  exportOffers: {
    id: 'event.owner.statistics.export.offers',
  },
  exportKpi: {
    id: 'event.owner.statistics.export.kpi',
  },
  exportConversion: {
    id: 'event.owner.statistics.export.conversion',
  },
  exportFilters: {
    id: 'event.owner.statistics.export.filters',
  },
  exportPreregistrations: {
    id: 'event.owner.statistics.export.preregistrations',
  },
});
