import request from '@/utils/request';

const acceptOwnerAccess = ({ exponentId, authToken, body, eventId }) => {
  let requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/exponents/${exponentId}/responseAuthorizePreparationByOwner`;
  return request(requestUrl, {
    method: 'POST',
    body: JSON.stringify(body),
  })
};

export { acceptOwnerAccess };