import React, { useEffect } from 'react';
import { object } from 'prop-types';
import { H5, Button, IconRefreshCcw, Caption1, IconInfo } from '@seekube-tech/ui-kit';
import colors from '@seekube-tech/ui-kit/dist/colors';
import { useFormatMessage } from 'react-intl-hooks';

import { useSelector } from 'react-redux';
import { Bloc, BlocHeader } from '@/components/Bloc';
import { useDeleteReport } from '@/queries/reports/useDeleteReport';
import { useCreateReport } from '@/queries/reports/useCreateReport';
import { useUpdateReport } from '@/queries/reports/useUpdateReport';
import Recipients from '@/scenes/Event/scenes/Owner/scenes/Setting/scenes/Reporting/components/EmailingSchedule/components/Recipients/Recipients';
import { getCurrentEvent } from '@/store/event/selectors';
import { getAuthToken } from '@/store/auth/selectors';
import { useResetReportDates } from '@/queries/reports/useResetReportDates';
import { EVENT_FORMAT } from '@/utils/constants';
import useStyles from './styles';
import NonHybridScheduling from './components/NonHybrid/NonHybrid';
import HybridScheduling from './components/Hybrid/Hybrid';
import { useGetNextReportsToSend } from '@/queries/reports/useGetNextReportsToSend';


export const EmailingSchedule = ({ reports }) => {
  const t = useFormatMessage();
  const event = useSelector(state => getCurrentEvent(state)?.toJS())
  const authToken = useSelector(getAuthToken)
  const mutateUpdateReports = useUpdateReport({ eventId: event._id, authToken });
  const mutateCreateReport = useCreateReport({ eventId: event._id, authToken });
  const mutateDeleteReport = useDeleteReport({ eventId: event._id, authToken });
  const mutateResetReportDates = useResetReportDates({ eventId: event._id, authToken });
  const nextReportsToSendQuery = useGetNextReportsToSend({ eventId: event._id, authToken })
  const classes = useStyles();


  const onUpdateReport = (report) => mutateUpdateReports.mutate({ ...report });

  const onCreateReport = (phase, format) => mutateCreateReport.mutate({ phase, format });

  const onDeleteReport = (report) => mutateDeleteReport.mutate({ ...report });

  const onResetReportDates = () => mutateResetReportDates.mutate()

  useEffect(() => {
    if (mutateResetReportDates.isSuccess) {
      console.log('refetch nextReport');
      nextReportsToSendQuery.refetch();
    }
  }, [mutateResetReportDates.isSuccess])

  useEffect(() => {
    if (mutateUpdateReports.isSuccess) {
      nextReportsToSendQuery.refetch();
    }
  }, [mutateUpdateReports.isSuccess])

  useEffect(() => {
    if (mutateCreateReport.isSuccess) {
      nextReportsToSendQuery.refetch();
    }
  }, [mutateCreateReport.isSuccess])

  useEffect(() => {
    if (mutateDeleteReport.isSuccess) {
      nextReportsToSendQuery.refetch();
    }
  }, [mutateDeleteReport.isSuccess])

  return (
    <Bloc>
      <Recipients event={event} />

      <hr className={classes.hr} />

      <BlocHeader>
        <H5 color={colors.neutral['500']}>
          {t({ id: 'owner.reporting.sending.schedule.title' }, { count: 2 })}
        </H5>
        <Button
          variant="text"
          onClick={() => onResetReportDates()}
          loading={mutateResetReportDates.isLoading}
          imageComponentLeft={<IconRefreshCcw size="16px"/>}
        >
            {t({ id: "owner.reporting.sending.schedule.reset" })}
        </Button>
      </BlocHeader>

      { event.format !== EVENT_FORMAT.HYBRID && <NonHybridScheduling reports={reports} onUpdateReport={onUpdateReport} onCreateReport={onCreateReport} onDeleteReport={onDeleteReport} /> }
      { event.format === EVENT_FORMAT.HYBRID && <HybridScheduling reports={reports} onUpdateReport={onUpdateReport} onCreateReport={onCreateReport} onDeleteReport={onDeleteReport} /> }
    </Bloc>
  );
}

EmailingSchedule.propTypes = {
  reports: object,
}
