import React from 'react'
import { IconMapPin, IconWifi, Tooltip, TooltipContent, TooltipTrigger }  from '@seekube-tech/ui-kit';
import classnames from 'classnames'
import { useFormatMessage } from 'react-intl-hooks';

import { EVENT_FORMAT } from '@/utils/constants';
import styles from './styles.less'
import { arrayContainAll } from '@/utils/math';

export const ParticipationsIcons = ({
  formats = [],
  name = undefined,
  visible = true,
  className,
  context
}) => {
  if(!visible || !formats.length) {
    return null;
  }

  const tooltipMessage = getTooltipMessage(formats, name, context);

  return (
    <Tooltip>
      <TooltipTrigger>
        <div className={classnames('bg-basic-white rounded-md', styles.container, className)}>
          {formats.map((format) => {
            if (format === EVENT_FORMAT.PHYSICAL) { return <span><IconMapPin size={16} /></span>}

            return <span><IconWifi size={16} /></span>
          })}
        </div>
      </TooltipTrigger>
      <TooltipContent>
        {tooltipMessage}
      </TooltipContent>
    </Tooltip>
  );
}

const getTooltipMessage = (formats, name, context) => {
  const t = useFormatMessage();
  const isOffer = context && context === 'offer';

  if (name === undefined) return null;

  if (arrayContainAll(formats, [EVENT_FORMAT.PHYSICAL, EVENT_FORMAT.VIRTUAL])) {
    return t(
      {
        id: isOffer ?
          'offer.published.virtual.and.physical' :
          'name.participate.virtual.and.physical'
      },
      { name }
    );
  }

  if (formats.includes(EVENT_FORMAT.PHYSICAL)) {
    return t(
      {
        id: isOffer ?
          'offer.published.physical' :
          'name.participate.physical'
      },
      { name }
    );
  }

  if (formats.includes(EVENT_FORMAT.VIRTUAL)) {
    return t({
        id: isOffer ?
          'offer.published.virtual' :
          'name.participate.virtual'
      },
      { name }
    );
  }

  return null
}
