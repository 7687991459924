import React from 'react';
import { bool, object, arrayOf, string, func } from 'prop-types';

import { EVENT_TYPE } from '../../ducks/BigCalendarConstants';
import Jobfair from './components/Jobfair';
import Timeslot from './components/Timeslot';

function Events({ event, currentEvent, jobfairsColor, isSettingsVisible, jobfairs, timezone, handleDeleteEvent,handleOnInformalDescriptionChange, informalDescription }) {
  /* Render */
  return event.type === EVENT_TYPE.JOBFAIR ? (
    <Jobfair jobfairsColor={jobfairsColor} event={event} />
  ) : (
    <Timeslot
      jobfairsColor={jobfairsColor}
      jobfairs={jobfairs}
      isSettingsVisible={isSettingsVisible}
      timezone={timezone}
      event={event}
      currentEvent={currentEvent}
      handleDeleteEvent={handleDeleteEvent}
      handleOnInformalDescriptionChange={handleOnInformalDescriptionChange}
      informalDescription={informalDescription}
    />
  );
}

Events.propTypes = {
  event: object,
  currentEvent: object,
  jobfairsColor: object,
  isSettingsVisible: bool,
  jobfairs: arrayOf(object),
  timezone: string,
  handleDeleteEvent: func,
  handleOnInformalDescriptionChange: func,
  informalDescription: string,
};

export default Events;
