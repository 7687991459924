import request from '@/utils/request';

const changeReferent = ({ exponentId, authToken, body, eventId }) => {
  let requestUrl = `${process.env.FRONT_API_URL}/events/${eventId}/exponents/${exponentId}/requestAuthorizePreparationByOwner`;
  return request(requestUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': authToken
    },
    body: JSON.stringify(body),
  })
};

export { changeReferent };