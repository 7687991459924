import React from 'react';
import { object, func, array, bool } from 'prop-types';
import { isUndefined, isEmpty, get, omit } from 'lodash';
import { replace, push } from 'connected-react-router';
import { injectIntl } from 'react-intl';
import { useFormatMessage } from 'react-intl-hooks';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Row, Col } from 'antd';
import { Button } from '@seekube-tech/ui-kit';
import { useLocation } from 'react-router';
import { useEffect } from 'react';
import { queryStringToObject } from '@/utils/url';
import { track } from '@/utils/analytics';
import { ANALYTICS_CANDIDATE, ANALYTICS_RECRUITER, ANALYTICS_ALL } from '@/utils/constants';
import { toJS } from '@/utils/index';
import { authSelectors, authActions } from '@/store/auth';
import { userActions, userSelectors } from '@/store/user';
import { participantActions, participantSelectors } from '@/store/participant';
import { criterionSelectors } from '@/store/criterion';
import { getLanguages } from '@/containers/LanguageProvider/selectors';
import General from '@/scenes/Settings/components/General';
import Password from '@/scenes/Settings/components/Password';
import Language from '@/scenes/Settings/components/General/components/Language';
import Timezone from '@/scenes/Settings/components/General/components/Timezone';
import AccountDeletion from '@/scenes/Settings/components/General/components/AccountDeletion';
import styles from '@/scenes/Settings/styles.less';
import { getAuthToken } from "@/store/auth/selectors";
import { Menu } from '@/scenes/Settings/CandidateSettings/Menu/Menu';

const CandidateSettings = (props) => {
  const {
    authUser,
    languages,
    patchMe,
    deleteUser,
    getParticipant,
    changeEmail,
    isOpen,
    defaultCriteria,
    push,
  } = props;

  const authToken = useSelector(getAuthToken);
  const t = useFormatMessage();
  const location = useLocation();

  const [currentView, setCurrentView] = React.useState('profile');

  useEffect(() => {
    if (queryStringToObject(window.location.search).settings === 'synchro') {
      setCurrentView('settings');
    }
    const queryReset = queryStringToObject(window.location.search).reset;

    if (authUser) {
      getParticipant({
        eventId: null,
        userId: authUser._id,
      });
    }

    if (!isUndefined(queryReset)) {
      changeEmail({
        resetToken: queryReset,
        notificationParams: {
          success: {
            message: t({ id: 'toasters.updateEmail.success' }),
            kind: 'info',
            style: {
              bottom: '5%',
              top: 'inherit',
            },
          },
        },
      });
    }
  }, []);

  useEffect(() => {
    const settings = new URLSearchParams(location.search).get('settings');

    // scroll to corresponding setting view if existing
    if (settings) {
      setCurrentView('settings');
      handleOnNavigate(null, 'settings', settings);
    }

  }, [location]);


  const handleOnNavigate = (e, view, scrollTo) => {

    setCurrentView(view);

    if (currentView !== view) {
      if (!isEmpty(scrollTo)) {
        setTimeout(() => {
          scrollToTarget(scrollTo, true);
        }, 500);
      }
    } else {
      scrollToTarget(scrollTo, true);
    }
  };

  function handleOnSaveUserKey(userParams, withHardRefresh = false) {
    patchMe({
      userId: authUser._id,
      userParams: omit(userParams, ['notificationParams']),
      notificationParams: userParams.notificationParams,
      callback: () => {
        if (typeof userParams.callback === 'function') {
          userParams.callback();
        }

        if (userParams.calendarSync !== authUser.calendarSync) {
          track({
            user: authUser,
            name: ANALYTICS_RECRUITER.EDITED_SEND_INVITATION,
            properties: {
              status: userParams.calendarSync,
            },
          });
        }

        track({
          user: authUser,
          name: ANALYTICS_CANDIDATE.EDIT_PROFILE,
          properties: {
            step: 'Settings',
            photo: !isEmpty(userParams.pictureUrl),
            summary: !isEmpty(userParams.description),
            myProfile: true,
            full: !isEmpty(userParams.pictureUrl) && !isEmpty(userParams.title) && !isEmpty(userParams.firstName) && !isEmpty(userParams.lastName),
          },
        });
        if (withHardRefresh) { window.location.reload(); }
      },
    });
  };

  function handleOnDeleteUser(params) {
    deleteUser({
      ...params,
      callback: () => {
        track({
          user: authUser,
          name: ANALYTICS_ALL.DELETE_ACCOUNT,
        });
      },
    });
  }

  function scrollToTarget(target, smooth) {
    const element = document.getElementById(target);

    if (element) {
      if (smooth) {
        element.scrollIntoView({
          behavior: 'smooth',
        });
      } else {
        element.scrollIntoView(true);
      }
    }
  };

  function _renderView() {
    switch (currentView) {
      case 'settings':
        return (
          <div id="userSettings">
            <div id="timezone">
              <Timezone timezoneText={get(authUser, 'timezone.text') || ''} onSave={(params) => { handleOnSaveUserKey(params, true); }} />
            </div>

            <div id="language">
              <Language authUser={authUser} onSave={handleOnSaveUserKey} languages={languages} />
            </div>
            <div id="password">
              <Password handleSaveUserKey={handleOnSaveUserKey} />
            </div>
            <div id="deletion">
              <AccountDeletion authUser={authUser} handleDeleteUser={handleOnDeleteUser} />
            </div>

            <div id="logout" style={{ textAlign: 'center' }} className="mb-20">
              <Button
                color="neutral"
                onClick={() => push('/auth/logout')}
              >{t({ id: 'header.userDropdown.logout' })}</Button>
            </div>
          </div>
        );

      case 'profile':
      default:
        return (
          <General
            authToken={authToken}
            authUser={authUser}
            handleSaveUserKey={handleOnSaveUserKey}
            defaultCriteria={defaultCriteria}
          />
        );
    }
  }

  if (!isOpen) {
    return null;
  }

  return (
    <div id="profile-settings" className={`profile-settings ${styles.settingWrapper}`}>
      <Row gutter={0}>
        <Col span={2} offset={2}>
          <Menu handleOnNavigate={handleOnNavigate} currentView={currentView} />
        </Col>
        <Col className={styles.contentView} span={6} xs={12} md={6}>
          <div className={styles.content}>
            {_renderView()}
          </div>
        </Col>
      </Row>
    </div>
  );
}

CandidateSettings.propTypes = {
  authUser: object,
  participant: object,
  languages: array,
  patchMe: func,
  deleteUser: func,
  getParticipant: func,
  changeEmail: func,
  isOpen: bool,
  defaultCriteria: array,
  push: func,
}

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
  defaultSettingsView: authSelectors.getDefaultSettingsView,
  currentUser: userSelectors.getCurrentUser,
  calendars: userSelectors.getCalendars,
  isCalendarsFetching: userSelectors.getIsCalendarsFetching,
  participant: participantSelectors.getSettingParticipant,
  languages: getLanguages,
  defaultCriteria: criterionSelectors.getDefaultCriteria,
});

const mapDispatchToProps = {
  patchMe: userActions.patchMe,
  getSyncConnectUrl: userActions.getSyncConnectUrl,
  getSyncDisconnectUrl: userActions.getSyncDisconnectUrl,
  replace,
  push,
  getParticipant: participantActions.getParticipant,
  deleteUser: userActions.deleteUser,
  changeEmail: authActions.changeEmail,
  getCalendars: userActions.getCalendars,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  injectIntl,
)(toJS(CandidateSettings));
