import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

import { Button, H4, Body1 } from '@seekube-tech/ui-kit';
import colors from '@seekube-tech/ui-kit/dist/colors';
import { useFormatMessage } from 'react-intl-hooks';
import ModalV2 from '@/components/ModalV2';
import Checkbox from '@/components/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { getAuthUser } from '@/store/auth/selectors';
import { userActions } from '@/store/user';


const ModalCgu = () => {
  const t = useFormatMessage();
  const [checked, setChecked] = React.useState(false);
  const authUser = useSelector(getAuthUser).toJS();
  const dispatch = useDispatch();

  return (
    <ModalV2 visible closable={false}>
      <div style={{ color: colors.neutral['500']}}>
        <H4 className="mb-16">
          {t({ id: 'modal.cgu.title' })}
        </H4>

        <Body1 className="mb-30">
          <FormattedHTMLMessage id="modal.cgu.link" />
        </Body1>

        <div className="mb-30">
          <label htmlFor="topStatusFilter" className="flex" >
            <Checkbox
              id="topStatusFilter"
              onChange={(check) => { setChecked(check.target.checked); }}
              value={true}
            /> <Body1 className="ml-4"> {t({ id: 'modal.cgu.accept' })}  <span style={{ color: colors.error['500']}}>*</span></Body1></label>
        </div>

        <Button
          disabled={!checked}
          className="w-full"
          onClick={() => dispatch(userActions.patchMe({ userId: authUser._id, userParams: {
              termsAcceptedAt: moment().toISOString(),
            },
            callback: () => {
              sessionStorage.clear();
              localStorage.clear();

              window.location.reload();
            }}))}
        >
          {t({ id: 'submit' })}
        </Button>
      </div>
    </ModalV2>
  );
};

export default React.memo(ModalCgu);
