import { Affix, Modal } from 'antd';
import AvatarWithDate from '@/components/AvatarWithDate';
import Icon from '@/components/Icon';
import moment from 'moment';
import Separator from '@/components/Separator';
import { If } from '@/components/If';
import { isEmpty } from 'lodash';
import { Button } from '@seekube-tech/ui-kit';
import React, { useState } from 'react';
import { usePostParticipateConference } from '@/queries/conferences/usePostParticipateConference';
import { usePostCancelParticipation } from '@/queries/conferences/usePostCancelParticipation';
import { H3 } from '@/components/Title';
import { FormattedHTMLMessage } from 'react-intl';
import { useFormatMessage } from 'react-intl-hooks';

export const AffixConferenceInfo = ({ match, conference, event }) => {
  const t = useFormatMessage();
  const [confirmationIsVisible, setConfirmationIsVisible] = useState(false);
  const participateConferenceQuery = usePostParticipateConference({
    conferenceId: match.params.conferenceID,
    onSuccess: () => {
      setConfirmationIsVisible(true);
    }});

  const cancelParticipationQuery = usePostCancelParticipation({
    conferenceId: match.params.conferenceID
  });
  const handleCloseConfirmationModal = () => setConfirmationIsVisible(false);
  const attendee = conference?.attendee || {};

  const handleParticipate = () => {
    if (!participateConferenceQuery.isLoading) {
      participateConferenceQuery.mutate();
    }
  }

  return (
    <>
      <Affix offsetTop={130}>
        <div className="offerInfos border-none">
          <div className="appointment">
            <div className="appointmentData">
              <div className="appointmentDay">
                <AvatarWithDate
                  src={conference._organization?.profile?.pictureUrl}
                  alt={conference._organization?.name}
                  date={conference.beginAt}
                />
              </div>
              <ul>
                <li><Icon name="clock" /> {moment(conference.beginAt).format('HH[h]mm')}</li>
                <li><Icon name="calendar" /> {t({ id: 'conference.duration' }, { duration: conference.duration })}</li>
              </ul>
            </div>

            <Separator height={20} />
          </div>

          <If condition={isEmpty(attendee) || attendee.canceled}>
            {!conference.isFull ? (
              <Button
                loading={participateConferenceQuery.isLoading}
                className="w-full"
                size="large"
                onClick={handleParticipate}
              >
                {t({ id: 'conference.participate' })}
              </Button>
            ) : (
              <Button
                disabled
                size="large"
                className="w-full"
              >
                {t({ id: 'conference.full' })}
              </Button>
            )}
          </If>

          {!isEmpty(attendee) && !attendee.canceled &&
            <>
              <Button
                color="success"
                size="large"
                className="w-full mb-20"
              >
                {t({ id: 'conference.participating' })}
              </Button>

              <p className="help">
                {t({id: 'conference.notAvailable'})}
                <a
                  className="ml-4"
                  role="button"
                  tabIndex={0} onClick={() => cancelParticipationQuery.mutate()}
                >
                  {t({id: 'conference.cancelParticipation'})}
                </a>
              </p></>
          }

          <Separator height={105} />
        </div>
      </Affix>
      <Modal
        visible={confirmationIsVisible}
        footer={false}
        className="confirmationModal"
        onCancel={handleCloseConfirmationModal}
      >
        <a role="button" tabIndex={0} className="modal-close" onClick={handleCloseConfirmationModal}>
          <Icon name="close" className="modal-close-icon" />
        </a>

        <H3 bold>
          {t({ id: 'event.candidate.jobdating.conference.participationIsConfirmedText.title' })}
        </H3>
        <FormattedHTMLMessage
          id={'event.candidate.jobdating.conference.participationIsConfirmedText.text'}
          values={{ event_name: event.name }}
        />
      </Modal>
    </>
  )
}
