import React, { useState } from 'react';
import { object, func } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { Button, H4 } from '@seekube-tech/ui-kit';
import { connect } from 'react-redux';
import { compose } from 'redux';
import InputMaterial from '@/components/Input/InputMaterial';
import { Bloc, BlocHeader } from '@/components/Bloc';
import styles from '@/scenes/Admin/scenes/Organizations/scenes/Organization/scenes/Details/styles.less';
import { organizationActions } from '@/store/organization';

const UpdateOrganizationName = ({ patchOrganization, organization }) => {
  const t = useFormatMessage();
  const [name, setName] = useState(organization?.name);

  const onSubmit = () => {
    patchOrganization({
      organizationId: organization._id,
      organizationParams: {
        name,
      },
      syncExponents: false,
      notificationParams: {
        success: {
          message: t({ id: 'toaster.edit.success' }),
          kind: 'info',
          style: {
            bottom: '10%',
            top: 'inherit',
          },
        },
        error: true,
      },
    });
  };

  return (
    <div className={styles.updateOrgaContainer}>
      <Bloc>
        <BlocHeader className={styles.heading}>
          <H4>{t({ id: 'about' })}</H4>
        </BlocHeader>
        <div>
          <InputMaterial
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
            addOnIcon="building"
            label={t({ id: 'admin.organizations.modal.input.organization.name' })}
            placeholder={t({ id: 'admin.organizations.modal.input.organization.name' })}
            type="text"
            validations={['required', 'validator']}
          />
        </div>

        <Button className="mb-1" onClick={onSubmit}>{t({ id: 'save' })}</Button>

      </Bloc>
    </div>
  )
}

UpdateOrganizationName.propTypes = {
  patchOrganization: func,
  organization: object,
}


const mapDispatchToProps = {
  patchOrganization: organizationActions.patchOrganization,
};


const withConnect = connect(null, mapDispatchToProps);

export default compose(
  withConnect,
)(UpdateOrganizationName);

