import React, { useEffect, useState } from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { Field } from 'react-final-form';
import { bool } from 'prop-types';
import { Select, TextField } from '@seekube-tech/ui';
import {
  Caption1,
  H5,
  Button,
  IconCandidates,
  IconEuro,
  IconFlag,
  IconHome,
  IconUsers,
  IconBirthday,
  IconPieChart,
  IconTrash2,
  IconPlusCircle,
  IconChevronUp,
  IconChevronDown,
  Alert,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
  Body1,
} from '@seekube-tech/ui-kit';
import classNames from 'classnames';
import Popover from '@/components/Popover';
import styles from './styles.less';
import useValidations from '@/utils/validations/useValidations';
import { Mandatory } from '@/components/Form';

const REMOTE_OPTIONS = ['none', 'partial', 'full'];
const EMPLOYEES_OPTIONS = ['mini', 'small', 'medium', 'large', 'maxi'];

const KeyNumbers = ({ disabledFields, values, change }) => {
  const t = useFormatMessage();
  const keyNumbersList = Object.keys(values).filter((e) => values[e] !== null);
  const activeFields = Object.values(values).filter((value) => value !== null).length;
  const [visible, setVisible] = useState(false);
  const [isMinimumField, setIsMinimunField] = useState(true);
  const [isMaximumField, setIsMaximunField] = useState(false);
  const { isFilled } = useValidations();

  const getIcons = (name) => {
    switch (name) {
      case 'employees_number':
        return <IconCandidates size={16} />
      case 'remote_working':
        return <IconHome size={16} />
      case 'year_founded':
        return <IconFlag size={16} />
      case 'age_avg':
        return <IconUsers size={16} />
      case 'sales_revenue':
        return <IconEuro size={16} />
      case 'seniority_avg':
        return <IconBirthday size={16} />
      case 'gender_parity':
        return <IconPieChart size={16} />
      default:
        return <IconCandidates size={16} />
    }
  }

  const removeKeyNumber = (name) => {
    change(`keyNumbers.${name}`, null);
  }

  const addKeyNumber = (name) => {
    change(`keyNumbers.${name}`, '');
  }

  useEffect(() => {
    setIsMinimunField(activeFields > 2)
    setIsMaximunField(activeFields === 6)
    // si on atteint le maxium de champs, on ferme le dropdown
    if (activeFields === 6) {
      setVisible(false)
    }
  }, [values]);

  const isFieldValidate = (value) => {
    if (isFilled(value) === null && isMinimumField) {
      return undefined
    }
    return t({ id: 'form.error.required' })
  }

  return (
    <div className={styles.keyNumbersContainer}>
      <div className={styles.headerKeyNumbers}>
        <span className="flex items-center">
          <H5>{t({id: 'keyNumbers.title'})}</H5>
          <Mandatory>
            <Body1 className="ml-16 text-neutral-400">
              {t({ id: 'stand.keyNumber.minimunFields' })}
          </Body1>
          </Mandatory>
        </span>

        <Caption1 className={styles.required}>{t({id: 'form.helper.multi-required'})}</Caption1>
      </div>
      {!isMinimumField &&
        <Alert isClosable={false} className={styles.alertKeyNumbers} color='error'>{t({id: 'stand.keyNumber.minimunFields'})}</Alert>
      }
      {isMaximumField &&
        <Alert isClosable={false} className={styles.alertKeyNumbers} color='info'>{t({id: 'stand.keyNumber.maximumFields'})}</Alert>
      }
      <div className={styles.keyNumbersList}>
        <div className={styles.keyNumberField} key={0}>
          <Field
            name="keyNumbers.employees_number"
            id="employees_number"
            label={t({ id: 'employees_number' })}
            component={Select}
            fullWidth
            required
            options={EMPLOYEES_OPTIONS}
            getOptionLabel={(option) => t({ id: `stand.employees.${option}` })}
            icon={getIcons('employees_number')}
            validate={(value) => isFieldValidate(value)}
            disabled={disabledFields}
            key={isMinimumField ? 1 : 0}
          />
          <Tooltip placement="top">
            <TooltipTrigger onClick={(e) => e.stopPropagation()}>
              <Button
                className={styles.btnDelete}
                disabled
                variant='tonal'
                color='error'
                imageComponentLeft={<IconTrash2 size={16}/>}
              >
                {t({id: 'delete'})}
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              {t({id: 'stand.employees.tooltip'})}
            </TooltipContent>
          </Tooltip>
        </div>
        {keyNumbersList.map((value, key) => {
          if (values[value] !== null) {
            if (value === 'employees_number') {
              return null
            }
            if (value === 'remote_working') {
              return (
                <div className={styles.keyNumberField} key={key}>
                  <Field
                    name={`keyNumbers.${value}`}
                    id={value}
                    label={t({ id: value })}
                    component={Select}
                    fullWidth
                    required
                    options={REMOTE_OPTIONS}
                    getOptionLabel={(option) => t({ id: `stand.remote.${option}` })}
                    icon={getIcons(value)}
                    validate={(value) => isFieldValidate(value)}
                    disabled={disabledFields}
                    key={isMinimumField ? 1 : 0}
                  />
                  <Button
                    disabled={disabledFields}
                    className={styles.btnDelete}
                    onClick={() => removeKeyNumber(value)}
                    variant='tonal'
                    color='error'
                    imageComponentLeft={<IconTrash2 size={16}/>}
                  >
                    {t({id: 'delete'})}
                  </Button>
                </div>
              )
            }
            return (
              <div className={styles.keyNumberField} key={key}>
                <div className={styles.inputKeyNumber}>
                  <Field
                    parse={(v) => v}
                    name={`keyNumbers.${value}`}
                    id={value}
                    label={t({ id: value })}
                    component={TextField}
                    fullWidth
                    required
                    icon={getIcons(value)}
                    validate={(value) => isFieldValidate(value)}
                    disabled={disabledFields}
                    key={isMinimumField ? 1 : 0}
                  />
                  <Caption1 className={styles.infoMessage}>{t({id: `stand.${value}.infoMessage`})}</Caption1>
                </div>
                <Button
                  disabled={disabledFields}
                  className={styles.btnDelete}
                  onClick={() => removeKeyNumber(value)}
                  variant='tonal'
                  color='error'
                  imageComponentLeft={<IconTrash2 size={16}/>}
                >
                  {t({id: 'delete'})}
                </Button>
              </div>
            )
          }
        })}
      </div>
      <div className={styles.addKeyNumbersContainer}>
        <Popover
          content={
            <div className={styles.popoverFormats}>
              {Object.entries(values).map((value, key) => (
                <div className={classNames(styles.popoverOption, value[1] !== null && styles.popoverOptionUsed)} onClick={() => value[1] === null && addKeyNumber(value[0])} key={key}>
                  <span>{getIcons(value[0])}</span>
                  {t({id: value[0]})}
                </div>
              ))}
            </div>
          }
          trigger="click"
          overlayStyle={{ maxWidth: '430px' }}
          visible={visible}
          placement="bottom"
          getPopupContainer={(trigger) => trigger.parentNode}
          onVisibleChange={() => setVisible(!visible)}
        >
          <Button
            disabled={isMaximumField || disabledFields}
            variant='outline'
            imageComponentLeft={<IconPlusCircle size={16} />}
            imageComponentRight={visible ? <IconChevronUp size={16} /> : <IconChevronDown size={16} />}
          >
            {t({ id: 'add.keyNumber' })}
          </Button>
        </Popover>
      </div>
    </div>
  );
}

KeyNumbers.propTypes = {
  disabledFields: bool,
};

export default KeyNumbers;
