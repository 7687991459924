/* eslint-disable arrow-body-style */
import React, { useState } from 'react';
import { array, func, node, object } from 'prop-types';
import { Field, FormSpy } from 'react-final-form';
import { Form, TextField, Textarea, Select } from '@seekube-tech/ui';
import { Button, Alert } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { injectIntl } from 'react-intl';
import { Row, Col } from 'antd';
import { isEqual, isEmpty } from 'lodash';
import GoogleMapLoader from 'react-google-maps-loader';
import { find } from 'lodash';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import useValidations from '@/utils/validations/useValidations';
import { stripTags } from '@/utils/text';
import { notificationActions } from '@/store/notification';

import Icon from '@/components/Icon';
import Avatar from '@/components/Avatar';

import CriteriaFields from './components/CriteriaFields';

import styles from './styles.less';
import { CRITERION_KEY } from './utils/constants';
import { useLocation } from 'react-router';

function CreateOfferForm({ onSubmit, event, user, offer, users, onOpen, warningOfferLimit, showHelper, googleMaps, isOwner, intl }) {
  /* Vars */
  const t = useFormatMessage();
  const location = useLocation()
  const { isFilled } = useValidations();
  const [loading, setLoading] = useState(false);

  /* Derived vars */
  const criteria = event._criteria
  .filter(
    (criterion) => criterion.enable && criterion.modules.offer.isVisible && criterion.key !== CRITERION_KEY.regexid
  )
  .sort((criterionA, criterionB) => criterionA.modules.offer.position - criterionB.modules.offer.position);
  const userOptions = users
  .filter((user) => user.isActive)
  .sort((a, b) => a.fullName.localeCompare(b.fullName));

  /* Functions */

  /**
   * Open a preview offer
   * @param {Object} values
   */
  const handleOpenPreview = (values) => () => {
    onOpen({
      ...offer,
      ...values,
      matching: {
        ...values,
      },
    });
  };

  const isFieldDisabledForOwner = isOwner && !isEmpty(offer?._id) && offer.status === 'published';

  const computeLocationFromGeocodeItem = ({ address_components, formatted_address }) => {
    const city = find(address_components, (ac) => ac.types[0] === 'locality' || ac.types[0] === 'political');
    const region = find(address_components, (ac) => ac.types[0] === 'administrative_area_level_1');
    const country = find(address_components, (ac) => ac.types[0] === 'country');
    const department = find(address_components, (ac) => ac.types[0] === 'administrative_area_level_2');

    return {
      shortAddress: `${city !== undefined ? city.long_name : null}, ${country !== undefined ? country.long_name : null}`,
      city: city?.long_name || '',
      name: formatted_address,
      formattedAdress: formatted_address,
      countryCode: country.short_name,
      department: department?.long_name,
      region: region?.long_name,
    };
  }

  /**
   * Compare if prev and next initials values is equal
   * @param {Object} prevValues
   * @param {Object} nextValues
   * @returns {Boolean}
   */
  const isInitialValuesEqual = (prevValues, nextValues) => {
    return isEqual(prevValues, nextValues);
  };

  // Remove choices that exceeds the limits (because that was a problem when you wanted to save it as a draft)
  const removeChoiceMaxErrors = (criteriaValues) => {
    Object.entries(criteriaValues).forEach(([key, value]) => {
      const criteriaToCompare = criteria.find(e => (e.fieldName === key) || (`custom${e.fieldName}` === key))
      if (criteria.some(e => (e.fieldName === key) || (`custom${e.fieldName}` === key))) {
        if (criteriaToCompare.modules.offer.choiceMax < value.length) {
          value.length = criteriaToCompare.modules.offer.choiceMax;
        }
      }
    })
  }

  const handleSubmit = async (values, isPublished = true) => {
    setLoading(true);
    let formValues = {
      ...values,
      license: values?.license === 'true',
    };

    if (isPublished) {
      formValues = {
        ...formValues,
        status: 'published',
        publishedAt: new Date(),
      };
    } else {
      removeChoiceMaxErrors(formValues)
      formValues.status = 'draft';
    }
    if (!isEmpty(values.locations)) {
      const promiseLocations = values.locations.map(({ name, placeId }, index) => {
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            const placesService = new googleMaps.Geocoder();
            /**
             * placeId not save in backend, issue only with edition so we use the name
             */
            if (placeId) {
              placesService.geocode({ placeId }, resolve, reject);
            }
            else {
              placesService.geocode({ address: name }, resolve, reject);
            }
          }, 500 * index);
        });
      });

      Promise.all(promiseLocations)
      .then((locationsDetail) => {
        const locations = locationsDetail
        .filter((locationDetail) => Boolean(locationDetail))
        .map(([locationDetail]) => computeLocationFromGeocodeItem(locationDetail));

        onSubmit({ ...formValues, locations });

        setLoading(false);
      });
    } else {
      onSubmit({ ...formValues, locations: [] });

      setLoading(false);
    }
  };

  /* Initials values */

  const initialValues = {
    title: offer?.title || '',
    description: offer?.description || '',
    profileDescription: offer?.profileDescription || '',
    date: offer?.matching?.date,
    locations: offer?.locations,
    status: offer?.status || 'draft',
    _user: offer?._user?.id,
    ...offer?.matching,
    license: offer?.matching?.license && `${offer.matching.license}`,
    _organization: user._currentOrganization,
  };

  return (
    <Form
      subscription={{ hasValidationErrors: true }}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      // Necessary because the reference of initialValues changes with each render
      initialValuesEqual={isInitialValuesEqual}
      render={({ hasValidationErrors }) => (
        <div className={styles.root}>
          <Row type="flex" justify="end">
            <Col>
              <p className={styles.helper}>{t({ id: 'form.helper.multi-required' })}</p>
            </Col>
          </Row>

          <Field
            name="title"
            id="title"
            component={TextField}
            label={t({ id: 'offer.form.title.label' })}
            placeholder={t({ id: 'offer.form.title.placeholder' })}
            required
            fullWidth
            icon={<Icon name="attachment" />}
            validate={value => isFilled(value)}
            showHelper={showHelper}
            disabled={isFieldDisabledForOwner}
          />

          <div className={styles.marginTop}>
            <Field
              name="_user"
              id="_user"
              component={Select}
              label={t({ id: 'offer.form.user.placeholder' })}
              disabled={isFieldDisabledForOwner}
              required
              fullWidth
              icon={<Icon name="user" />}
              options={userOptions}
              validate={value => isFilled(value)}
              getOptionLabel={option => (
                <Row type="flex" align="middle" gutter={8} className={styles.userItem}>
                  <Col>
                    <Avatar size={22} src={option.pictureUrl} user={option} />
                  </Col>

                  <Col>{option.fullName}</Col>
                </Row>
              )}
              getOptionValue={option => option._id}
              showHelper={showHelper}
            />
          </div>

          <div className={styles.marginTop}>
            <Field
              name="description"
              disabled={isFieldDisabledForOwner}
              id="description"
              label={!isEmpty(event?.modules?.offer[`labelDescription_${intl.locale}`]) ? event?.modules?.offer[`labelDescription_${intl.locale}`] : t({ id: 'offer.form.description.label' })}
              component={Textarea}
              fullWidth
              required
              validate={value => isFilled(value)}
              showHelper={showHelper}
            />
          </div>

          {event.modules?.offer?.hideProfileText !== true && (
            <div className={styles.marginTop}>
              <Field
                name="profileDescription"
                id="profileDescription"
                label={!isEmpty(event?.modules?.offer[`profileDescription_${intl.locale}`]) ? event?.modules?.offer[`profileDescription_${intl.locale}`] : t({ id: 'offer.form.profileDescription.label' })}
                component={Textarea}
                disabled={isFieldDisabledForOwner}
                fullWidth
                required
                validate={value => isFilled(value)}
                showHelper={showHelper}
              />
            </div>
          )}

          <CriteriaFields
            criteria={criteria}
            endAt={event.keyDates.jobfair.endAt}
            showHelper={showHelper}
            disabled={isFieldDisabledForOwner}

          />

          {!isOwner &&
            <div className={styles.marginTop}>
              <Alert>
                {t({ id: 'event.recruiter.preparation.offer.form.infos.stand.warning' })}
              </Alert>
            </div>
          }

          {warningOfferLimit && (
            <div className={styles.marginTop}>
              {warningOfferLimit}
            </div>
          )}


          <FormSpy
            subscription={{ values: true }}
            render={({ values }) => (
                <Row type="flex" justify="end" align="middle" gutter={16} className={styles.marginTop}>
                  {!isOwner && <Col>
                    <Button
                      variant="tonal"
                      disabled={Boolean(warningOfferLimit) || loading}
                      onClick={handleOpenPreview(values)}
                    >
                      {t({ id: 'preview' })}
                    </Button>
                  </Col>}

                  {!isOwner &&
                    <Col>
                      <Button
                        variant="tonal"
                        onClick={() => handleSubmit(values, false)}
                        disabled={Boolean(values?.title) === false || loading}
                      >
                        {t({ id: 'save.draft' })}
                      </Button>
                    </Col>}

                <Col>
                  <Button
                    type="submit"
                    color="primary"
                    loading={loading}
                    disabled={isEmpty(stripTags(values.description)) || isEmpty(stripTags(values.profileDescription)) || Boolean(warningOfferLimit) || loading}
                  >
                    {t({ id: 'publish.offer' })}
                  </Button>
                </Col>
              </Row>
            )}
          />
        </div>
      )}
    />
  );
}

CreateOfferForm.propTypes = {
  onSubmit: func.isRequired,
  intl: object,
  event: object,
  offer: object,
  user: object,
  users: array,
  onOpen: func.isRequired,
  warningOfferLimit: node,
  showHelper: func.isRequired,
  googleMaps: object.isRequired,
};

CreateOfferForm.defaultProps = {
  event: {
    _criteria: [],
  },
  offer: {},
  users: [],
  warningOfferLimit: null,
};

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = {
  sendNotification: notificationActions.sendNotification,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps)(injectIntl(CreateOfferForm));


export default GoogleMapLoader(withConnect, {
  libraries: ['places'],
  key: process.env.FRONT_GOOGLE_MAPS_API_KEY,
});
