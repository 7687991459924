import React from 'react';
import { get } from 'lodash';
import Avatars from '@/components/Avatars';
import ActionColumn from '../../../../../../../OnBoarding/containers/Dashboard/components/ActionColumn';
import { OrganizationColumn } from '../../../../../../../OnBoarding/containers/Dashboard/components/OrganizationColumn';

import styles from '../../styles.less';

export default (byOrganization) => (
  [
    {
      title: 'ENTREPRISES',
      key: 'organization',
      dataIndex: 'organization',
      width: '40%',
      render: (organization) => <OrganizationColumn organization={organization} />,
    },
    {
      title: 'COLLABORATEURS',
      dataIndex: 'users',
      key: 'users',
      width: '40%',
      render: (users) => (
        <div className={styles.collaborators}>
          <div className={styles.countCollaborators}>{users.length}</div>
          <Avatars
            className={styles.avatars}
            users={users}
            maxPictures={24}
            others={users.length - (users.length < 24 ? users.length : 24)}
          />
        </div>
      ),
    },
    { title: 'ACTIONS',
      key: 'actions',
      width: '20%',
      render: (text, record) => (
        <span role="button" tabIndex={0} onClick={record.actions.preventExpand}>
          <ActionColumn
            record={record}
            handleExponentActions={record.actions.handleExponentActions}
            addCollaborator={() => record.actions.openCollaboratorModal(record.organization)}
            removeExponent={() => record.actions.removePlanner(record.organization)}
            onToolsClick={get(record, 'actions.onToolsClick')}
            authToken={get(record, 'actions.authToken')}
            patchOrganization={get(record, 'actions.patchOrganization')}
            openAvatarModal={() => record.actions.openAvatarModal(record.organization)}
            byOrganization={byOrganization}
            context="owner_planners"
            containerClass={styles.actionsContainer}
          />
        </span>
      ),
    }]
);
