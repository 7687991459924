import React, { useEffect, useState } from 'react';
import { object, func } from 'prop-types';
import { compose } from 'redux';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';
import classnames from 'classnames';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { createStructuredSelector } from 'reselect';
import { Modal, Form } from 'antd';
import { Button, H5, Body2 } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { toJS } from '@/utils';
import ButtonInline from '@/components/ButtonInline';
import { authSelectors, authActions } from '@/store/auth';
import Textarea from '@/components/Textarea';
import Icon from '@/components/Icon';
import Separator from '@/components/Separator';
import styles from '@/scenes/Settings/styles.less';


const FormItem = Form.Item;

const AccountDeletion = ({ authUser, form, handleDeleteUser, switchExit, authUserConnectedAs }) => {
  const t = useFormatMessage();

  const [confirmModalIsVisible, setConfirmModalIsVisible] = useState(false);
  const [btnFocus, setBtnFocus] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const btnOptions = {
    dislike: t({ id: 'settings.account.deletion.modal.option.dislike' }),
    offers: t({ id: 'settings.account.deletion.modal.option.jobFound' }),
    messages: t({ id: 'other' }),
  };

  useEffect(() => {
    form.validateFields();
  }, []);

  const handleBtnClick = (btnName) => {
    setBtnFocus(btnName);
    setShowMessage(btnName === 'messages');
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    form.validateFields((err, values) => {
      if (!err && authUser) {
        setDisabledSubmit(true);
        handleDeleteUser({
          userId: authUser._id,
          userParams: {
            reason: btnOptions[btnFocus],
            messages: values.messages || '',
          },
          isCandidate: true,
        });

        if (authUserConnectedAs) {
          switchExit();
        }
      }
    });
  };

  return (
    <div className={styles.emailFormWrapper}>
      <H5 className="mb-20">{t({ id: 'form.accountDeletion' })}</H5>
      <div>
        <Body2 className="mb-20 text-neutral-400">
          {t({ id: isEmpty(authUser._currentOrganization) ?
              'settings.account.deletion.text.candidate' : 'settings.account.deletion.text.recruiter' }
          )}
        </Body2>
        <Button
          color="error"
          onClick={() => setConfirmModalIsVisible(true)}
        >
          {t({ id: 'settings.account.deletion.btn' })}
        </Button>
      </div>
      <Modal
        visible={confirmModalIsVisible}
        footer={false}
        maskClosable
        width={470}
        className="customConfirm deletion"
        onCancel={() => setConfirmModalIsVisible(false)}
        zIndex={1002}
      >
        <a role="button" tabIndex={0} className="modal-close" onClick={() => setConfirmModalIsVisible(false)}>
          <Icon name="close" className="modal-close-icon" />
        </a>
        {isEmpty(authUser._currentOrganization) ? (
          <div>
            <H5><FormattedHTMLMessage id="settings.account.deletion.modal.title" /></H5>
            <div className={styles.deletionChoice}>
              {Object.keys(btnOptions).map((option, index) => (
                <ButtonInline
                  key={index}
                  icon={option}
                  text={btnOptions[option]}
                  className={classnames(styles.btnList, btnFocus.toString() === option ? styles.focus : '')}
                  onClick={() => handleBtnClick(option)}
                />
              ))}
            </div>
            <Form className="form" onSubmit={handleOnSubmit}>
              {showMessage && (
                <FormItem
                  className="textareaWrapper"
                >
                  {form.getFieldDecorator('messages', {
                    rules: [{ required: true, message: ' ' }],
                  })(
                    <Textarea
                      label="Message"
                      maxLength="1000"
                      style={{ height: '8em' }}
                    />
                  )}
                </FormItem>
              )}

              <div className="confirm-actions">
                <Button
                  type="submit"
                  disabled={disabledSubmit || isEmpty(btnFocus) || (btnFocus.toString() === 'messages' && isEmpty(form.getFieldValue('messages')))}
                  className="mr-6"
                >
                  {t({ id: 'btn.confirm' })}
                </Button>
                <Button
                  variant="tonal"
                  onClick={() => setConfirmModalIsVisible(false)}
                >
                  {t({ id: 'cancel' })}
                </Button>
              </div>
            </Form>
          </div>
        ) : (
          <div>
            <h4 className="ant-confirm-title">
              <span role="img" aria-label="warning">⚠️ </span><FormattedHTMLMessage id="settings.account.deletion.modal.recruiter.confirm" />
            </h4>

            <Separator height={15} />

            <div className="confirm-actions">
              <Button
                onClick={() => { setDisabledSubmit(true); handleDeleteUser({ userId: authUser._id, organizationId: authUser._currentOrganization._id }); }}
                disabled={disabledSubmit}
                className="mr-6"
              >
                {t({ id: 'btn.confirm' })}
              </Button>
              <Button
                variant="tonal"
                onClick={() => setConfirmModalIsVisible(false)}
              >
                {t({ id: 'cancel' })}
              </Button>
            </div>
          </div>
        )}
      </Modal>
    </div>);
}

AccountDeletion.propTypes = {
  authUser: object,
  form: object,
  handleDeleteUser: func,
  switchExit: func,
  authUserConnectedAs: object,
};

const withForm = Form.create();

const mapStateToProps = createStructuredSelector({
  authUserConnectedAs: authSelectors.getAuthUserConnectedAs,
});

const mapDispatchToProps = (dispatch) => ({
  switchExit() {
    dispatch(authActions.switchExit());
    dispatch(push('/events'));
    localStorage.setItem('/events', '');
    window.location.reload();
  },
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  withForm,
  injectIntl,
)(toJS(AccountDeletion));
