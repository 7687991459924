import * as React from 'react';
import { string, func, node } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';

import Icon from '@/components/Icon';

import styles from './styles.less';

function AppointmentDetails({
  children,
  type,
  closePopover,
  title = null,
}) {

  const t = useFormatMessage();

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        {title && <span className={styles.titleConferenceDetail}>{title}</span>}
        { type === "interview" && <h4>{t({ id: 'event.recruiter.preparation.timeslots.event.appointment' })}</h4> }
        { type === "informal1to1" && <h4>{t({ id: 'event.recruiter.preparation.timeslots.event.informal1to1' })}</h4>}
        <a type="button" className={styles.roundIconContainer} role="button" onClick={closePopover} tabIndex={0}>
          <Icon name="close" className={styles.icon} style={{ width: 8, height: 8 }} />
        </a>
      </div>
      {children}
    </div>
  );
}

AppointmentDetails.propTypes = {
  type: string.isRequired,
  children: node.isRequired,
  closePopover: func.isRequired
};

export default AppointmentDetails;
