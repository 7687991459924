import React, { useState } from 'react';
import { object } from 'prop-types';
import styles from './styles.less'
import { Body2, IconPlayFilled } from '@seekube-tech/ui-kit';
import Html from '@/components/Html';
import ModalVideos from './ModalVideo';
import classNames from 'classnames';
import { convertToPrivacyEnhancedUrl } from "@/helpers/gdpr";

const Videos = ({
  profile,
  videos = [],
}) => {

  if (!profile) {
    return null;
  }

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [videoIndex, setVideoIndex] = useState(0)

  const addEmbeddedLink = (embeddedLink) => {
    return convertToPrivacyEnhancedUrl(embeddedLink);
  }

  const getThumbnail = (embeddedLink = '') => {
    const videoId = addEmbeddedLink(embeddedLink).split('/')[addEmbeddedLink(embeddedLink).split('/').length - 1].split('?')[0];
    if (embeddedLink.indexOf("youtu") >= 0) {
      return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`
    }
    if (embeddedLink.indexOf("vimeo") >= 0) {
      return `https://vumbnail.com/${videoId}_large.jpg`
    }
  }

  return (
    <div className={classNames(styles.videosContainer, videos.length === 1 && styles.videosContainerOne) }>
      {isModalVisible &&
        <ModalVideos setIsModalVisible={setIsModalVisible} addEmbeddedLink={addEmbeddedLink} profile={profile} videos={videos} videoIndexInitial={videoIndex} />
      }
      {videos.map((video, key) => {
        return (
          <div key={key} className={styles.imgContainer} onClick={() => {setVideoIndex(key) ;setIsModalVisible(true)}}>
            <img src={getThumbnail(video.url)} />
            <div className={styles.overImg}>
              <div></div>
              <div className={styles.icon}><IconPlayFilled size={16} /> </div>
              <Body2 fontWeight={700}>{video.title}</Body2>
            </div>
          </div>
        )
      })}
    </div>
  )
}

Videos.propTypes = {
  profile: object
}

export default Videos;
