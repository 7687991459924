import { defineMessages } from 'react-intl';

export default defineMessages({
  descriptionTitle: {
    id: 'seeklive.test.description.title',
  },
  alertMediumChange: {
    id: 'seeklive.test.description.alert.medium',
  },
  stepDescription: {
    id: 'seeklive.test.description.step.title',
  },
  descriptionMainText: {
    id: 'seeklive.test.description.text.1',
  },
  descriptionStartTest: {
    id: 'seeklive.test.description.btn.start.test',
  },
  resultTitle: {
    id: 'seeklive.test.result.title',
  },
  resultMainText: {
    id: 'seeklive.test.result.text',
  },
  resultBtnYes: {
    id: 'seeklive.test.result.btn.yes',
  },
  resultBtnNo: {
    id: 'seeklive.test.result.btn.no',
  },
  stepRecommendations: {
    id: 'seeklive.recommendation.step.title',
  },
  recommendationsFailureTitle: {
    id: 'teams.recommendation.failure.title',
  },
  recommendationsFailureText: {
    id: 'teams.recommendation.failure.text',
  },
  recommendationsFailureBtn: {
    id: 'seeklive.recommendation.failure.btn',
  },
  recommendationsSuccessTitle: {
    id: 'teams.recommendation.success.title',
  },
  recommendationsSuccessText1: {
    id: 'teams.recommendation.success.text.1',
  },
  recommendationsSuccessText2: {
    id: 'seeklive.recommendation.success.text.2',
  },
  recommendationsSuccessBtn: {
    id: 'seeklive.recommendation.success.btn',
  },
  copyLink: {
    id: 'copy.link',
  },
  copySuccess: {
    id: 'copy.success',
  },
});
