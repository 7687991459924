import React from 'react';
import { FormattedMessage } from 'react-intl';
import { object, node, bool, func } from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { isEmpty, get } from 'lodash';
import { useFormatMessage } from 'react-intl-hooks';
import { Typography, Grid } from '@seekube-tech/ui';
import { Button } from '@seekube-tech/ui-kit';
import { useSelector } from 'react-redux';
import { compose } from 'redux';
import { trackCandidateClickedViewKeyDates } from '@/utils/analytics';

// Components
import Card from '@/components/Card';
import AvatarWithDate from '@/components/AvatarWithDate';
import Popover from '@/components/Popover';
import Icon from '@/components/Icon';
import { If } from '@/components/If';
import { Tooltip } from '@/components/Tooltip';
import { Tag } from '@/components/Tag';

// Styles
import offerCardStyles from '@/components/OfferCard/OfferCardListParticipant/styles.less';
import styles from './styles.less';
import messages from './messages';
import { getAvailableActions } from '@/store/availableActions/selectors';
import { toJS } from '@/utils';


const PropositionCard = ({ user, organization, interaction, participant, intl, popoverContent, match, event, handleOpenOffer }) => {
  const t = useFormatMessage();
  const availableActions = useSelector(getAvailableActions);

  const { _appointment } = interaction;
  const { _event: { areInteractionsClosed } } = participant;
  const hasAppointmentBeenModified =
    interaction?._appointment?.status === 'pending' &&
    interaction?._appointment?.wasAcceptedAndUpdatedAt;

  let renderMoreAction = '';

  if (!isEmpty(popoverContent) && (!_appointment || _appointment.status !== 'canceled')) {
    renderMoreAction = (
      <div className={styles.more}>
        <Popover
          popoverContent={<div className={styles.popoverContent}>{popoverContent}</div>}
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
          placement="bottomRight"
        >
          <button><Icon name="more" className={styles.icon} /></button>
        </Popover>
      </div>
    );
  }

  
  const canChooseTimeslot = !availableActions.choosingTimeslot.isDisabled({event, role: 'candidate', participant, appointment: interaction._appointment});

  return (
    <div className={offerCardStyles.card}>
      <Card noBorder>
        <div className={styles.wrapper}>
          <div className={styles.mainContent}>
            <AvatarWithDate
              src={user?.pictureUrl}
              altSrc={organization?.profile?.pictureUrl || ''}
              alt={user?.shortName}
              user={user}
              rounded
              partner={event?.modules?.partner}
              locale={intl?.locale}
              isPartner={organization?.isPartner}
            />

            <div className={styles.content}>
              {
                _appointment.status === 'canceled' || hasAppointmentBeenModified ?
                  <h3>{user.firstName} {user.lastName} @{get(organization, 'name')}</h3>
                  : (
                    user ? <FormattedMessage tagName="h3" {...messages.propositionCardSourcingRecruiter} values={{ recruiterFirstName: user.firstName, organization: organization.name }} />
                      : <FormattedMessage tagName="h3" {...messages.propositionCardSourcingRecruiter} values={{ organization: organization.name }} />

                  )
              }
              <div className={styles.message}>
                <CardText hasAppointmentBeenModified={hasAppointmentBeenModified} interaction={interaction} />
              </div>
            </div>
          </div>
          <div className={styles.action}>
            <div className={styles.btnWrapper}>
              {interaction?._appointment?.status !== 'canceled' && (
                <Tooltip
                  title={!canChooseTimeslot &&
                  <div>
                    <Typography variant="body2" color="basic.white">
                      {t({ id: 'event.candidate.appointments.card.confirm.disabled'})}
                      <Typography
                        variant="link2"
                        target="_blank"
                        component="a"
                        href={`${window.location.origin}/${match.params.eventSlug}/candidate/keydates`}
                        onClick={() => trackCandidateClickedViewKeyDates({ authUser: participant._user })}
                      >
                        {t({ id: 'see.keyDates' })}
                      </Typography>
                    </Typography>
                  </div>}
                  placement="bottom"
                  color="dark"
                >
                  <span>
                    <Link
                      disabled={!canChooseTimeslot}
                      to={`/${participant._event.slug}/candidate/jobdating/appointment/${interaction._appointment._id}`}
                    >
                      <Button
                        disabled={!canChooseTimeslot}
                        color="primary"
                      >
                        {hasAppointmentBeenModified ?
                          t({ id: 'event.candidate.appointments.card.chooseSlot' }) :
                          t({ id: 'recruiter.participantsList.appointment.confirm' })}
                      </Button>
                    </Link>
                  </span>
                </Tooltip>
              )}
            </div>
          </div>
        </div>

        {renderMoreAction}
      </Card>

      <div className={styles.offers}>
        <Grid container alignItems="center" spacing={3}>
          <Grid item>
            <span className={styles.label}>{t({ id: 'event.candidate.appointments.card.propose' })}</span>
          </Grid>

          {interaction?._offers.map((offer) => (
            <Grid item key={offer._id}>
              <Tag onClick={() => handleOpenOffer(offer)}>
                <Tooltip title={offer.title} color="dark">
                  {offer.title.length > 30 ? `${offer.title.slice(0, 29)}...` : offer.title}
                </Tooltip>
              </Tag>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};

const CardText = ({ interaction, hasAppointmentBeenModified }) => {
  const t = useFormatMessage();
  const isInterviewCanceledByCandidate = get(interaction, '_appointment.canceledBy') === 'candidate';
  const isInterviewCanceledByRecruiter = get(interaction, '_appointment.canceledBy') === 'recruiter';
  const canceledBy = get(interaction, '_appointment.canceledBy');
  const text = get(interaction, '_appointment.message') || '';

  return (
    hasAppointmentBeenModified ?
      <div className={styles.text2}>
        {t({ id: 'event.candidate.appointments.card.interview.modified' })}
      </div>
      :
      <div className={styles.text}>
        <If
          condition={!(isInterviewCanceledByCandidate || isInterviewCanceledByRecruiter)}
          otherwise={<FormattedMessage id={`event.candidate.appointments.card.interview.canceled.by.${canceledBy}`} />}
        >
          <span>{ text.length > 120 ? (`${text.substring(0, 120)}...`) : text }</span>
          <Icon name="quote-left" className={styles.icon} />
        </If>
      </div>
  );
};


CardText.propTypes = {
  hasAppointmentBeenModified: bool,
  interaction: object,
};

PropositionCard.propTypes = {
  user: object,
  organization: object,
  participant: object,
  interaction: object,
  match: object,
  popoverContent: node,
  event: object,
  intl: object,
  handleOpenOffer: func,
  availableActions: object,
};


export default compose(withRouter)(toJS(PropositionCard));
