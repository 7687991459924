import React, { useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { object, func, string } from 'prop-types';
import { Button } from '@seekube-tech/ui-kit';
import Html from '@/components/Html';

import messages from '../../messages';
import styles from './styles.less';
import parentStyles from '../../styles.less';

const copyToClipboard = (value) => {
  function fallbackCopyTextToClipboard(text) {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();

    document.execCommand('copy');

    document.body.removeChild(textArea);
  }
  fallbackCopyTextToClipboard(value);
};

const Success = ({ intl, onValueSelected, testUrl }) => {
  const [copyPaste, setCopyPasteText] = useState(false);
  const btnText = {
    text: intl.formatMessage(copyPaste ? messages.copySuccess : messages.copyLink),
    type: copyPaste ? styles.success : '',
  };

  return (
    <div>
      <FormattedMessage tagName="h2" {...messages.recommendationsSuccessTitle} />
      <hr />
      <FormattedMessage tagName="div" {...messages.recommendationsSuccessText1} />
      <div className={styles.shareTestContainer}>
        <span>{`${testUrl.substring(0, 50)}...`}</span>
        <a role="button" className={btnText.type} tabIndex={0} onClick={() => { copyToClipboard(testUrl); setCopyPasteText(true); }}>{btnText.text}</a>
      </div>
      <Html
        value={intl.formatMessage(messages.recommendationsSuccessText2)
        }
      />
      <div className={parentStyles.actions}>
        <Button onClick={() => { onValueSelected('visio'); }}>
          <FormattedMessage {...messages.recommendationsSuccessBtn} />
        </Button>
      </div>
    </div>
  );
};

Success.propTypes = {
  intl: object,
  testUrl: string,
  onValueSelected: func,
};

export default injectIntl(Success);
