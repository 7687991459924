/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { Button } from '@seekube-tech/ui-kit';
import { arrayOf, func, number, object, bool, string } from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import { useFormatMessage } from 'react-intl-hooks';

import { IconMapPin, IconWifi } from '@seekube-tech/ui-kit';
import { EVENT_FORMAT } from '@/utils/constants';
import globalStyles from '../../../less/common.less';
import styles from './styles.less';


import DateSelector from './DateSelector';
import { EVENT_TYPE } from '../ducks/BigCalendarConstants';

const getExponentAvailableTimeslots = (exponentFormat, timeslotsAvailable) => {
  return exponentFormat === EVENT_FORMAT.PHYSICAL ? timeslotsAvailable.physical : timeslotsAvailable.virtual
}

function Toolbar({
  indexWeek,
  handleWeekChange,
  handleDeleteTimeSlots,
  timeslots,
  handleOpenSetting,
  isSettingsVisible,
  currentEvent: { weeks, keyDates, id, format, modules, keyMoments },
  exponentFormat,
  changeEventKeyMoment,
}) {
  /* Vars */

  const t = useFormatMessage();
  const [interviewTimeslotAvailable, setInterviewTimeslotAvailable] = useState();
  const [informal1to1TimeslotAvailable, setInformal1to1TimeslotAvailable] = useState();

  const [selectSettingModeIsOpen, setSelectSettingModeIsOpen] = useState(false);
  const [bothFormatOngoing, setBothFormatOngoing] = useState(true);
  const [formatWeeks, setFormatWeeks] = useState(weeks.all)
  const isExponentPhysical = exponentFormat === EVENT_FORMAT.PHYSICAL;
  const isExponentHybrid = exponentFormat === EVENT_FORMAT.HYBRID;
  const isModuleInformal1to1Enable = modules?.informal1to1?.enable || false;
  const isModuleOfferEnable = modules?.offer?.enable || false;


  useEffect(() => {
    switch(exponentFormat) {
      case EVENT_FORMAT.VIRTUAL:
        setFormatWeeks(weeks.virtual)
        break;
      case EVENT_FORMAT.PHYSICAL:
        setFormatWeeks(weeks.physical)
        break;
      default:
        setFormatWeeks(weeks.all)
        const currentDate = moment();
        if (moment(keyMoments?.jobfair?.[1].endAt) < currentDate || moment(keyMoments?.jobfair?.[0].endAt) < currentDate) {
          setBothFormatOngoing(false);
        }
        break;
    }
  }, [exponentFormat])

  /* Memos */

  useEffect(() => {
    if (timeslots) {
      const timeslotAvailable = timeslots
        .filter((timeslot) => (
          (format === 'physical' ? timeslot.medium === 'physical' : true) &&
          timeslot.type !== EVENT_TYPE.JOBFAIR &&
          Boolean(timeslot._appointment) === false &&
          (Boolean(timeslot._event) === false || timeslot._event === id) &&
          moment().isBefore(moment(timeslot.start)) &&
          moment(timeslot.start).isBefore(keyDates.jobfair.endAt)
        ))
      setInterviewTimeslotAvailable(timeslotAvailable.filter((timeslot) => timeslot.type === "interview"))
      setInformal1to1TimeslotAvailable(timeslotAvailable.filter((timeslot) => timeslot.type === "informal1to1"))
    }
  }, [timeslots]);

  const interviewNumberOfTimeslotAvailable = React.useMemo(() => {
    if(interviewTimeslotAvailable) {
      const numberOfSlotPhysical = interviewTimeslotAvailable.filter((timeslot) => timeslot.medium === "physical").length;
      const numberOfSlotVirtual = interviewTimeslotAvailable.filter((timeslot) => timeslot.medium !== "physical").length;
      return {
        physical: numberOfSlotPhysical,
        virtual: numberOfSlotVirtual
      }
    }

    return {
      physical: 0,
      virtual: 0
    }
  }, [interviewTimeslotAvailable]);


  const informal1to1NumberOfTimeslotAvailable = React.useMemo(() => {
    if(informal1to1TimeslotAvailable) {
      const numberOfSlotPhysical = informal1to1TimeslotAvailable.filter((timeslot) => timeslot.medium === "physical").length;
      const numberOfSlotVirtual = informal1to1TimeslotAvailable.filter((timeslot) => timeslot.medium !== "physical").length;
      return {
        physical: numberOfSlotPhysical,
        virtual: numberOfSlotVirtual
      }
    }

    return {
      physical: 0,
      virtual: 0
    }
  }, [informal1to1TimeslotAvailable]);

  const openSetting = (format) => {
    if (format) {
      handleOpenSetting(format);
      changeEventKeyMoment(format);
    }
    else {
      handleOpenSetting(exponentFormat);
    }
  }

  const handleButtonClick = () => {
    if (format === EVENT_FORMAT.HYBRID && exponentFormat === EVENT_FORMAT.HYBRID && bothFormatOngoing) {
      setSelectSettingModeIsOpen(!selectSettingModeIsOpen);
    }
    else {
      handleOpenSetting(exponentFormat);
    }
  }

  /* Render */
  return (
    <Row type="flex" justify="space-between" align="middle" style={{padding: '0 32px'}} >
      <Row type="flex" align='middle'>
        <Col className={styles.colButton}>
          {!isSettingsVisible && (
            <span>
              <Button
                color="primary"
                onClick={() => handleButtonClick()}
              >
                {t({ id: 'recruiter.participantsList.btn.add-slot' })}
              </Button>
            </span>
          )}
          {selectSettingModeIsOpen &&
            <div className={styles.buttonsOptions}>
              <div
                className={styles.buttonOption}
                onClick={() => openSetting(EVENT_FORMAT.VIRTUAL)}
              >
                <IconWifi size={16}/>
                {t({ id: 'recruiter.participantsList.btn.add-slot.virtual' })}
              </div>
              <div
                className={styles.buttonOption}
                onClick={() => openSetting(EVENT_FORMAT.PHYSICAL)}
              >
                <IconMapPin size={16}/>
                {t({ id: 'recruiter.participantsList.btn.add-slot.physical' })}
              </div>
            </div>
          }
        </Col>

        <Col className={styles.timeslotsInfoContaier}>
          <Row type="flex" align="middle" gutter={8}>
            <Col>
              <Row type='flex' justify='space-between' align='middle'>
                <span className={`${styles.timeslotAvailableTotal} ${!isModuleInformal1to1Enable && !isExponentHybrid && getExponentAvailableTimeslots(exponentFormat, interviewNumberOfTimeslotAvailable) === 0 && styles.timeslotAvailableTotalZero} ${!isModuleInformal1to1Enable && isExponentHybrid && styles.timeslotAvailableTotalHidden}`}>
                  {t({ id: `components.bigCalendar.toolbar.available-timeslot${(isModuleInformal1to1Enable && isModuleOfferEnable) || isExponentHybrid ? '-withoutSettings' : ''}`}, {count: getExponentAvailableTimeslots(exponentFormat, isModuleOfferEnable ? interviewNumberOfTimeslotAvailable : informal1to1NumberOfTimeslotAvailable) })}
                </span>
                <button
                  onClick={handleDeleteTimeSlots}
                  className={classnames(globalStyles.button, globalStyles.danger, styles.timeslotAvailableDeleteButton )}
                >
                  {t({ id: 'action.delete-all' })}
                </button>
              </Row>
              {((isModuleInformal1to1Enable && isModuleOfferEnable) || isExponentHybrid) && 
                <Row type='flex' align='middle'>
                  {/* Timeslots virtual */}
                  {!isExponentPhysical &&
                    <Col style={{marginRight: "24px"}}>
                      {exponentFormat === 'hybrid' &&
                        <Row type='flex' align='middle'>
                          <div className={styles.formatTag}>
                            {t({id: 'calendar.settingsSlot.participationMode.virtual'})}
                          </div>
                        </Row>
                      }
                      <Row type='flex' align='middle'>
                        {isModuleOfferEnable && (
                          <p className={`${styles.timeslotNumberContainer} ${interviewNumberOfTimeslotAvailable.virtual === 0 && styles.timeslotNumberZeroContainer}`}>
                            <span className={styles.numberOfSlots}>{interviewNumberOfTimeslotAvailable.virtual}</span> {isModuleInformal1to1Enable ? t({ id: `components.bigCalendar.toolbar.available-timeslot-interview` }, { count: interviewNumberOfTimeslotAvailable.virtual}) : t({ id: `components.bigCalendar.toolbar.available-timeslot-withoutSettings` }, { count: interviewNumberOfTimeslotAvailable.virtual})}
                          </p>
                        )}
                        {isModuleInformal1to1Enable &&
                          <>
                            <div className={styles.separator}></div>
                            <p className={`${styles.timeslotNumberContainer} ${informal1to1NumberOfTimeslotAvailable.virtual === 0 && styles.timeslotNumberZeroContainer}`}>
                            <span className={styles.numberOfSlots}>{informal1to1NumberOfTimeslotAvailable.virtual}</span> {t({ id: `components.bigCalendar.toolbar.available-timeslot${isModuleOfferEnable ? '-informal1to1' : ''}`}, { count: informal1to1NumberOfTimeslotAvailable.virtual } )}
                            </p>
                          </>
                        }
                      </Row>
                    </Col>
                  }
                  {/* Timeslots physical */}
                  {exponentFormat !== 'virtual' &&
                    <Col>
                      {isExponentHybrid &&
                        <Row type='flex' align='middle'>
                          <div className={styles.formatTag}>
                            {t({id: 'calendar.settingsSlot.participationMode.physical'})}
                          </div>
                        </Row>
                      }
                      <Row type='flex' align='middle'>
                        {isModuleOfferEnable && (
                          <p className={`${styles.timeslotNumberContainer} ${interviewNumberOfTimeslotAvailable.physical === 0 && styles.timeslotNumberZeroContainer}`}>
                            <span className={styles.numberOfSlots}>{interviewNumberOfTimeslotAvailable.physical}</span> {isModuleInformal1to1Enable ? t({ id: `components.bigCalendar.toolbar.available-timeslot-interview` }, { count: interviewNumberOfTimeslotAvailable.physical}) : t({ id: `components.bigCalendar.toolbar.available-timeslot-withoutSettings` }, { count: interviewNumberOfTimeslotAvailable.physical})}
                          </p>
                        )}
                        {isModuleInformal1to1Enable &&
                          <>
                           <div className={styles.separator}></div>
                            <p className={`${styles.timeslotNumberContainer} ${informal1to1NumberOfTimeslotAvailable.physical === 0 && styles.timeslotNumberZeroContainer}`}>
                            <span className={styles.numberOfSlots}>{informal1to1NumberOfTimeslotAvailable.physical}</span> {t({ id: `components.bigCalendar.toolbar.available-timeslot${isModuleOfferEnable ? '-informal1to1' : ''}` }, {count: informal1to1NumberOfTimeslotAvailable.physical})}
                            </p>
                          </>
                        }
                      </Row>
                    </Col>
                  }
                </Row>
              }
            </Col>
          </Row>
        </Col>
      </Row>

      <Col>
        <DateSelector weeks={formatWeeks} indexWeek={indexWeek} handleWeekChange={handleWeekChange} />
      </Col>

    </Row>
  );
}

Toolbar.propTypes = {
  indexWeek: number,
  handleWeekChange: func,
  handleDeleteTimeSlots: func,
  timeslots: arrayOf(object),
  currentEvent: object,
  handleOpenSetting: func,
  isSettingsVisible: bool,
  exponentFormat: string,
  changeEventKeyMoment: func,
};

export default Toolbar;
