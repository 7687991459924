import request from '@/utils/request';

const getParticipantLimits = ({ event, participant, authToken }) => {
  const requestUrl = `${process.env.FRONT_API_URL}/events/${event._id}/participants/${participant._id}/applicationsCounter`;
  return request(requestUrl, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': authToken
    },
  })
};

export { getParticipantLimits };