import { useQuery } from 'react-query';

import request from '@/utils/request';

export function useGetExponent({ eventSlug, exponentId, isPreview, ...props }) {

  const requestUrl = `${process.env.FRONT_API_URL}/events/${eventSlug}/exponents/${exponentId}?lean=true`;

  const queryFn = () => request(requestUrl, { method: 'GET' });

  const query = useQuery({
    queryKey: ['exponent', isPreview, exponentId],
    queryFn,
    enabled: !!exponentId,
    refetchOnWindowFocus: false,
    ...props
  });

  return query;
}
