import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { EVENT_FORMAT } from '@/utils/constants';
import { getEventKeyMoments } from '@/helpers/event/getEventKeyMoments';

/**
 * @description
 * It creates the period of all events, this is displayed on the top of the calendar
 *
 */
export const createJobfairEventsSlots = (
  events = [],
  isEventHybrid,
  exponentFormat,
) => {
  if (isEventHybrid) {
    if (exponentFormat === EVENT_FORMAT.HYBRID) {
      return (
        !isEmpty(events) ? events
          .reduce((acc, event, index) =>
              acc
                .concat([
                  {
                    allDay: true,
                    eventIndex: index, // Use when we want to delete a timeslot
                    label: event.name,
                    isPrior: event.format === 'physical',
                    _appointment: null,
                    _conference: null,
                    start: moment(event.readOnly.jobfairBeginAt).toDate(),
                    end: moment(event.readOnly.jobfairEndAt).toDate(),
                    type: 'jobfair',
                    _event: event.id,
                  },
                ])
            , []) : []
      )
    }

    return (
      !isEmpty(events) ? events
        .reduce((acc, event, index) =>
            acc
              .concat([
                {
                  allDay: true,
                  eventIndex: index, // Use when we want to delete a timeslot
                  label: event.name,
                  isPrior: event.format === 'physical',
                  _appointment: null,
                  _conference: null,
                  start: moment(getEventKeyMoments(event, {type: 'jobfair', format: exponentFormat})?.beginAt).toDate(),
                  end: moment(getEventKeyMoments(event, {type: 'jobfair', format: exponentFormat})?.endAt).toDate(),
                  type: 'jobfair',
                  _event: event.id,
                },
              ])
          , []) : []
    )
  }

  return (
    !isEmpty(events) ? events
      .reduce((acc, event, index) =>
          acc
            .concat([
              {
                allDay: true,
                eventIndex: index, // Use when we want to delete a timeslot
                label: event.name,
                isPrior: event.format === 'physical',
                _appointment: null,
                _conference: null,
                start: moment(event.keyDates?.jobfair?.beginAt)?.toDate(),
                end: moment(event.keyDates?.jobfair?.endAt)?.toDate(),
                type: 'jobfair',
                _event: event.id,
              },
            ])
        , []) : []
  )
}
