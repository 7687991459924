import React from 'react';
import { array, func, string, object, oneOf } from 'prop-types';

// components
import { Form, Select as SelectAntd } from 'antd';
import Select from '@/components/Form/Select';
import TagWithAvatar from '@/components/Tag/TagWithAvatar';

import messages from '../../messages';
import styles from './styles.less';
const FormItem = Form.Item;
const Option = SelectAntd.Option;

const OfferOwnerForm = (
  {
    initialValue,
    placeholder,
    users,
    showHelper,
    handleOnOfferOwnerChange,
    fieldDecoratorName = '_user',
    getFieldDecorator,
    intl,
    selectProps = {},
    context = 'default',
    handleSearch
  }) => {// eslint-disable-line
  const formItemStyle = {};

  if (context === 'default') {
    formItemStyle.zIndex = 2;
  }

  const onOfferOwnerChange = (val) => {
    handleOnOfferOwnerChange(val);
  };

  const handleOwnerFiltering = (input, option) => {
    if (option.props.children && option.props.children.props) {
      return !option.props.children.props.name || option.props.children.props.name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }

    return false;
  };

  const ownerOptions = users
    .map((user) => (
      <Option key={`ownerOption${user._id}`} value={user._id}>
        <TagWithAvatar user={user} src={user.pictureUrl ? user.pictureUrl : ''} name={user.fullName} />
      </Option>
    ));

  return (
    <FormItem className={styles.formItem} style={formItemStyle}>
      <div id="_userFormItem">
        {getFieldDecorator(fieldDecoratorName, {
          initialValue,
          rules: [{ required: true, message: ' ' }],
        })(
          <Select
            showSearch
            onSearch={handleSearch}
            onChange={onOfferOwnerChange}
            addOnIcon="user"
            placeholder={placeholder || intl.formatMessage(messages._userPlaceholder)}
            required
            optionFilterProp="children"
            filterOption={(input, option) => handleOwnerFiltering(input, option)}
            withInputErrorStyle={false}
            showHelper={showHelper}
            key="user"
            {...selectProps}
          >
            {ownerOptions}
          </Select>
        )}
      </div>
    </FormItem>
  );
};

OfferOwnerForm.propTypes = {
  initialValue: string,
  showHelper: func,
  getFieldDecorator: func,
  handleOnOfferOwnerChange: func,
  fieldDecoratorName: string,
  placeholder: string,
  intl: object,
  selectProps: object,
  users: array,
  context: oneOf(['offer', 'default']),
  handleSearch: func
};

export { OfferOwnerForm };
