import React from 'react';
import { func, array, object } from 'prop-types';
import { isEmpty } from 'lodash';
import { injectIntl } from 'react-intl';
import { track } from '@/utils/analytics';
import { ANALYTICS_RECRUITER } from '@/utils/constants';

// Components
import { Collapse } from 'antd';
import Radio, { RadioGroup } from '@/components/Radio';
import Avatar from '@/components/Avatar';
import Separator from '@/components/Separator';
import Icon from '@/components/Icon';
import SearchableList from '../Filter/components/SearchableList';

// Styles & Translations
import styles from '../../styles.less';
import messages from '../../messages';

const Panel = Collapse.Panel;

/**
 * EventList
 */
class SelectOffer extends React.PureComponent {
  static propTypes = {
    offers: array,
    defaultValue: object,
    event: object,
    authUser: object,
    onChange: func,
    intl: object,
  };

  state = {
    selectedItem: null,
  };

  componentWillReceiveProps({ defaultValue }) {
    this.setState({ selectedItem: defaultValue ? defaultValue._id : null });
  }

  handleOnClick = (e) => {
    if (e.currentTarget.value === this.state.selectedItem) {
      e.currentTarget.value = undefined;
      this.handleOnChange(e);
    }
  };
  handleOnChange = (e) => {
    const {
      props: { offers, onChange, authUser, event },
    } = this;

    const item = e.target.value;
    this.setState({ selectedItem: item },
      () => {
        track({
          name: ANALYTICS_RECRUITER.SEARCHED_FOR_CANDIDATES_BY_OFFERS,
          user: authUser,
          event,
        });
      });

    if (item === 'undefined') {
      onChange(null);
    } else {
      const offer = offers.find((offer) => offer._id === item);

      if (offer) {
        onChange(offer);
      }
    }
  };

  render() {
    const {
      state: { selectedItem },
      props: { offers, authUser, intl },
      handleOnChange, handleOnClick,
    } = this;

    const headerOffers = (
      <div className={styles.header}>{intl.formatMessage(messages.myOffers)}
        <span style={{ top: '11px', right: '18px', position: 'absolute', zIndex: 1, fill: '#385077' }}><Icon name="chevron" style={{ width: '24px', height: '24px' }} /></span>
      </div>
    );

    const headerCollaboratorsOffers = (
      <div className={styles.header}>{intl.formatMessage(messages.teamOffers)}
        <span style={{ top: '11px', right: '18px', position: 'absolute', zIndex: 1, fill: '#385077' }}><Icon name="chevron" style={{ width: '24px', height: '24px' }} /></span>
      </div>
    );

    const authUserOffers = [];
    const otherOffers = [];

    offers.forEach((offer) => {
      if (offer._user && offer._user._id === authUser._id) {
        authUserOffers.push(offer);
      } else if (offer._user) {
        otherOffers.push(offer);
      }
    });

    const renderOfferItem = (offer, withAvatar) => (
      <li key={offer._id.toString()} id={offer._id.toString()}>
        <label htmlFor={`offerRadio${offer._id}`}>
          <Radio className={styles.label} id={`offerRadio${offer._id}`} value={offer._id.toString()} checked={selectedItem === offer._id.toString()} onClick={handleOnClick}>
            {withAvatar ? (<><Avatar src={offer._user.pictureUrl} size={22} user={offer._user} /> </>) : ''} { offer.title }
          </Radio>
        </label>
      </li>
    );

    /*    const renderOffers = (
          <SearchableList limit={10}>
            <ul>
              {authUserOffers.map((offer) => renderOfferItem(offer, false))}
            </ul>
          </SearchableList>
        );*/

    const renderOffers = (
      <RadioGroup onChange={handleOnChange}>
        <SearchableList limit={10}>
          <ul style={{ width: '100%' }}>
            {authUserOffers.map((offer) => renderOfferItem(offer, false))}
          </ul>
        </SearchableList>
      </RadioGroup>
    );

    const renderOtherOffers = (
      <div className={styles.otherOffers}>
        <RadioGroup onChange={handleOnChange}>
          <SearchableList limit={10}>
            <ul>
              {otherOffers.map((offer) => renderOfferItem(offer, true))}
            </ul>
          </SearchableList>
        </RadioGroup>
      </div>
    );

    return (
      <div>
        {!isEmpty(authUserOffers) ? (
          <>
            <Collapse defaultActiveKey="offers">
              <Panel header={headerOffers} key="offers" showArrow={false} suffix>
                <div className={styles.searchItemsList} id="authOffers">
                  {renderOffers}
                </div>
              </Panel>
            </Collapse>
            <Separator height={20} />
          </>
        ) : ''}

        {!isEmpty(otherOffers) ? (
          <Collapse defaultActiveKey={isEmpty(authUserOffers) ? 'otherOffers' : null}>
            <Panel header={headerCollaboratorsOffers} showArrow={false} key="otherOffers">
              <div className={styles.searchItemsList} id="otherOffers">
                {renderOtherOffers}
              </div>
            </Panel>
          </Collapse>
        ) : ''}

        <Separator height={30} />
      </div>
    );
  }
}

export default injectIntl(SelectOffer);

