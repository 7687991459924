import React, { useEffect, useState } from 'react';
import { bool, object, string } from 'prop-types';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import Sticky from 'react-sticky-el';
import { Icon } from 'antd';
import { includes, isEmpty, split } from 'lodash';
import { useHistory } from 'react-router-dom';
import { getAuthUser } from '@/store/auth/selectors';
import { getCurrentParticipant } from '@/store/participant/selectors';
import MainHeader from '@/containers/Layout/containers/Header';
import styles from './styles.less';
import Nav from '@/components/Nav';
import PrivateModal from '@/scenes/Event/containers/Landing/components/Contents/components/GeneralInfos/components/LandingContent/components/PrivateModal';
import { trackGtmEvent } from '@/utils/pixels/helloWork';
import { storeUtmParams } from '@/utils/url';
import { DiscoverMoreEvents } from '@/scenes/Event/containers/Landing/components/Contents/components/GeneralInfos';
import { Title, Contents, CoverPicture, Bloc } from '@/scenes/Event/containers/Landing/components';

const Landing = ({
  event,
  editMode,
  cover,
  match,
  onChange
}) => {
  if (!event) {
    return '';
  }
  const history = useHistory();

  const [displayResponsiveNavigation, setDisplayResponsiveNavigation] = useState(false)

  const authUser = useSelector(getAuthUser)?.toJS()
  const participant = useSelector(getCurrentParticipant)?.toJS()

  const { password } = event.participants.filters;
  const locale = event.language || 'fr';

  useEffect(() => {
    trackGtmEvent('page.display', {
      'user_id_back': authUser?._id || '',
      'path': location.pathname,
      page_category: 'landing_page',
      page_subject: 'jobdating',
      jobdating_name: event.slug,
      user_jobdating_signup: !isEmpty(participant),
    });

    storeUtmParams();
  }, []);

  const getIsPrivateModalVisible = (password) => {
    const isRecruiter = !isEmpty(authUser?._organizations);
    const isEventPrivate = event.visibility === 'private';
    const isEventPublic = event.visibility === 'public';
    const isPasswordCorrect = includes(split(location.search.slice(1, location.search.length), '&'), `pwd=${password}`);
    const isAlreadyInEvent = !!participant;

    return isEventPublic || isAlreadyInEvent || isRecruiter || (isEventPrivate && isPasswordCorrect);
  };

  const eventNav = (
    <div className="eventNav">
      <button onClick={() => setDisplayResponsiveNavigation(!displayResponsiveNavigation)}><Icon name="arrow-down" /></button>
      <Nav event={event} locale={locale} displayResponsiveNavigation={displayResponsiveNavigation} />
    </div>
  );

  if (!getIsPrivateModalVisible(password)) {
    return (
      <PrivateModal password={password} push={history.push} />
    )
  }


  return (
    <div className={styles.landingV3}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${event.name} - Seekube`}</title>
        {event.visibility === 'private' ? (
          <meta name="robots" content="noindex, nofollow" />
        ) : <meta name="robots" content="index, follow" />}
      </Helmet>
      <div style={{ position: 'relative', zIndex: 5 }}>
        <Sticky topOffset={60}>
          <MainHeader
            context="landing"
            nav={eventNav}
          />
        </Sticky>
      </div>
      <div className={styles.landingV3Container}>
        <CoverPicture
          event={event}
          cover={cover}
          editMode={editMode}
        />

        <div className={styles.blocMedium}>
          <Bloc>
            <div className={styles.blocContainer}>
              <Title event={event} />
              <Contents event={event} editMode={editMode} match={match} onChange={onChange} />
            </div>
          </Bloc>
          <DiscoverMoreEvents event={event} />
        </div>
      </div>
    </div>
  );
}

Landing.propTypes = {
  event: object,
  match: object,
  editMode: bool,
  cover: string,
}

export { Landing };
