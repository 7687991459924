import React, { useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { func, bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

// components
import { Modal, Form } from 'antd';
import { Button } from '@seekube-tech/ui-kit';
import { isEmpty } from 'lodash';
import Icon from '@/components/Icon';
import InputMaterial from '@/components/Input/InputMaterial';
import Separator from '@/components/Separator';


// Store
import { organizationActions } from '@/store/organization';

import styles from '../../../styles.less';
import messages from '../../../messages';
import { useUpdatePassword } from '@/queries/users/useUpdatePassword';
import { usePostResetPassword } from '@/queries/users/usePostResetPassword';

const FormItem = Form.Item;

const EditPasswordModal = ({
  visible, handleClose, intl, form, user,
}) => {
  if (isEmpty(user)) return null;

  const updatePasswordQuery = useUpdatePassword();
  const resetPasswordQuery = usePostResetPassword();

  useEffect(() => {resetPasswordQuery.reset(); updatePasswordQuery.reset();}, [user._id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const { getFieldsValue, resetFields } = form;
    const values = getFieldsValue();

    updatePasswordQuery.mutate({ userId: user._id, password: values.editPassword });

    return resetFields();
  };

  const resetPassword = () => {
    const { resetFields } = form;

    resetPasswordQuery.mutate({
      username: user.username,
      url: window.location.hr
    });

    return resetFields();
  };

  const onCancel = () => {
    handleClose(null);
    resetPasswordQuery.reset();
  }


  const renderForm = () => {
    const { getFieldDecorator } = form;

    return (
      <div>
        <span className={styles.modalTitles}><FormattedMessage {...messages.managePassword} /></span>

        <Separator height={30} />
        <Button onClick={resetPassword} className="w-full mt-20 mb-20" loading={ resetPasswordQuery.isLoading} disabled={resetPasswordQuery.isLoading || resetPasswordQuery.isSuccess} type="submit">
          {resetPasswordQuery.isSuccess ? <FormattedMessage id="forgotPassword.confirmationNotification" /> : (<FormattedMessage {...messages.sendResetOfPassword} />)}
        </Button>
        <div className={styles.lignesContainer}>
          <hr className={styles.orText} /><span><FormattedMessage {...messages.orText} /></span><hr className={styles.orText} />
        </div>

        <Form onSubmit={handleSubmit} style={{ marginTop: '20px' }}>
          <FormItem>
            {getFieldDecorator('editPassword', {
              rules: [{ required: true, message: <FormattedMessage {...messages.editPassword} /> }],
            })(
              <InputMaterial addOnIcon="building" label={intl.formatMessage(messages.editPassword)} placeholder={intl.formatMessage(messages.editPassword)} type="text" />
            )}
          </FormItem>

          <Button loading={updatePasswordQuery.isLoading} disabled={updatePasswordQuery.isLoading || updatePasswordQuery.isSuccess} type="submit">
            {updatePasswordQuery.isSuccess ?
              <FormattedMessage id="admin.organizations.members.actions.edit.password.modal.input.edit.password.success" />
              : (<FormattedMessage {...messages.registerEditedPassword} />)}
          </Button>
        </Form>
      </div>
    );
  }

  return (
    <Modal
      visible={visible}
      keyboard={false}
      onCancel={onCancel}
      footer={false}
      maskClosable
      closable={false}
      width={654}
      className="customConfirm collaboratorModal"
    >
      <a role="button" tabIndex="0" className={styles.modalClose} onClick={(e) => handleClose(e)}><Icon name="close-modal" /></a>
      {renderForm()}
    </Modal>
  );
}

EditPasswordModal.propTypes = {
  form: object,
  user: object,
  visible: bool,
  resetIsLoading: bool,
  changeIsLoading: bool,
  onReset: func,
  handleClose: func,
  intl: object,
};

const mapDispatchToProps = {
  postOrganization: organizationActions.postOrganization,
};

const withForm = Form.create()(EditPasswordModal);
const withConnect = connect(null, mapDispatchToProps);

export default compose(
  injectIntl,
  withConnect,
)(withForm);

