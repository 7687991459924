import { createSelector } from 'reselect';


const getRoleReducer = (state) => state.get('role');


export const getRolesById = createSelector(
  getRoleReducer,
  (rolesState) => {

    return rolesState.get('byId');
  }
);

export const getRolesByKey = createSelector(
  getRoleReducer,
  (rolesState) => {

    return rolesState.get('byKey');
  }
);

