import React, { useState } from 'react';
import { compose } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import { oneOf, object } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { get, omit, size } from 'lodash';
import { H4, Body1 } from '@seekube-tech/ui-kit';
import { useHistory } from 'react-router-dom';
import { useFormatMessage } from 'react-intl-hooks';
import { toJS } from '@/utils';
import { getId } from '@/utils/global';

import { exponentSelectors } from '@/store/exponent';
import { postAction } from '@/store/action/actions';

import Wrapper from '@/components/Wrapper';
import { ModalConfirm } from '@/components/ModalConfirm';
import { Nav, PageBlank } from '@/scenes/Client/scenes/Conferences/components';
import ModalEditLiveTalk from './components/EditLivetalk';
import { PostConferenceBtn } from './components';
import styles from './styles.less';
import { useGetConferences } from '@/queries/conferences/useGetConferences';
import { useDeleteConference } from '@/queries/conferences/useDeleteConference';
import { getAuthUser } from '@/store/auth/selectors';
import { getCurrentEvent } from '@/store/event/selectors';
import { objectToParams, queryStringToObject } from '@/utils/url';
import ConferencesList from '@/scenes/Client/scenes/Conferences/ConferencesList';

const DEFAULT_SEARCH = {
  page: 1,
  offset: 0,
  limit: 10,
  archive: location.pathname.includes('confirmed'),
}

const Conferences = ({
  context = 'client',
  match,
  exponent,
}) => {
  const t = useFormatMessage();
  const authUser = useSelector(getAuthUser)?.toJS();
  const event = useSelector(getCurrentEvent)?.toJS();
  const history = useHistory();
  const dispatch = useDispatch();
  const [formatSelected, setFormatSelected] = useState('webinar');
  const [conferenceSelected, setConferenceSelected] = useState({});
  const [editFlowVisible, setEditFlowVisible] = useState(false);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [action, setAction] = useState(null);
  const [search, setSearch] = useState({ ...DEFAULT_SEARCH, ...queryStringToObject(history.location.search) });

  const mutateDeleteConference = useDeleteConference({
    onSuccess: () => {
      setConferenceSelected({})
      setEditFlowVisible(false)
      setIsConfirmModalVisible(false)
      conferencesQuery.refetch();
    },
  });

  const conferencesQuery = useGetConferences({
    eventId: context === 'client' ? null : getId(event),
    organization: getId(authUser._currentOrganization),
    search
  });
  const conferences = conferencesQuery.data?.docs || [];

  const startEditFlow = (formatSelected) => {
    setFormatSelected(formatSelected)
    setEditFlowVisible(true)
    setConferenceSelected({})
  };

  const goToDiscovery = () => {
    history.push(`${match.url}/discovery?page=1&archive=false`);
    setSearch({ ...search, archive: false })
  };

  const goToConfirmed = () => {
    history.push(`${match.url}/confirmed?page=1&archive=true`);
    setSearch({ ...search, archive: true })
  };

  const handleNavClick = (name) => {
    if (name === 'discovery') { goToDiscovery() }

    if (name === 'confirmed') { goToConfirmed() }
  }

  const onConfirmModal = () => {
    if (!editFlowVisible && action === 'removeLive') {
      mutateDeleteConference.mutate({ _id: conferenceSelected._id })
    }

    if (editFlowVisible) {
      setConferenceSelected({})
      setEditFlowVisible(false)
    }

    setIsConfirmModalVisible(false)
  }


  const handleActions = (actionType, conference) => {
    switch (actionType) {
      case 'editLive':
        setEditFlowVisible(true);
        setConferenceSelected(conference);
        setFormatSelected(conference.format)
        break;

      case 'duplicateLive':
        setEditFlowVisible(true)
        setConferenceSelected({ ...omit(conference, ['_id']), isDuplicating: true })
        setFormatSelected(conference.format)
        break;

      case 'removeLive':
        setAction('removeLive')
        setConferenceSelected(conference);
        setIsConfirmModalVisible(true);
        break;

      case 'seeAttendees':
        dispatch(postAction({
          actionParams: {
            name: 'RECRUITER_VISIT_CONFERENCE',
            _user: getId(authUser),
            _organization: getId(conference._organization),
            _event: getId(conference._event),
            data: { conference: getId(conference._id) },
          },
          callback: () => history.push(`conferences/${conference._id}/all`),
        }))
        break;

      default:
        break;
    }
  }


  const updateSearchAndURL = (searchChanges) => {
    const newSearch = { ...search, ...searchChanges };

    setSearch(newSearch);

    updateURLWithSearchChanges(newSearch)
  }

  const updateURLWithSearchChanges = (searchToUpdate) => {
    history.push(`${history.location.pathname}?${objectToParams(searchToUpdate)}`)
  }

  const isLimitExceeded = ['nonClient'].includes(context) && size(conferences) >= get(exponent, 'limits.liveMax');

  return (
    <Wrapper type="scene">
      <div className={styles.conference}>
        <div className={styles.header}>
          <div className={styles.pageTitle}>
            <Nav conferencesQuery={conferencesQuery} handleClick={handleNavClick} />
          </div>
          <div className="flex justify-end items-center">
            <PostConferenceBtn
              startEditFlow={startEditFlow}
              disabled={isLimitExceeded}
            />
          </div>
        </div>

        {
          size(conferences) === 0 && !conferencesQuery.isLoading && (
            <PageBlank />
          )
        }

        <ModalConfirm
          visible={isConfirmModalVisible}
          onConfirm={onConfirmModal}
          onCancel={() => setIsConfirmModalVisible(false)}
        >
          {
            editFlowVisible ?
              <H4>{t({ id: 'event.recruiter.preparation.offer.published.form.update' })}</H4>
              : <>
                <H4>{t({ id: 'recruiter.live.confirm.delete.title' })}</H4>
                <Body1 className="mt-20">{t({ id: 'recruiter.live.confirm.delete.subtitle' })}</Body1>
              </>
          }
        </ModalConfirm>

        <ConferencesList
          onSearch={updateSearchAndURL}
          handleActions={handleActions}
          context={context}
          conferencesQuery={conferencesQuery}
        />

        <ModalEditLiveTalk
          conference={conferenceSelected}
          visible={editFlowVisible}
          format={formatSelected}
          context={context}
          onClose={(callback, hasFormBeenSubmitted) => {
            if (hasFormBeenSubmitted) {
              callback();
              conferencesQuery.refetch();
              setConferenceSelected({});
              setEditFlowVisible(false)
            } else {
              setIsConfirmModalVisible(true)
            }
          }}
        />
      </div>
    </Wrapper>
  );
}

Conferences.propTypes = {
  context: oneOf(['nonClient', 'client']),
  exponent: object,
  match: object,
};

const mapStateToProps = createStructuredSelector({
  exponent: exponentSelectors.getCurrentExponent,
});

const withConnect = connect(mapStateToProps, null);

export default compose(
  withConnect,
)(toJS(Conferences));
