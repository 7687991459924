import React, { useEffect, useState } from 'react';
import { any, func, string } from 'prop-types';
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { isUndefined } from 'lodash';
import { object } from 'is_js';
import CustomLink from './customComponents/customLink';
import { IconImage } from '@seekube-tech/ui-kit';

export const isWysiwygFieldCompleted = (wysiwygValue) => {
  if (typeof wysiwygValue === 'string') {
    const tmp = document.createElement('div');
    tmp.innerHTML = wysiwygValue;
    const cleanValue = tmp.innerText;

    return cleanValue.length > 1;
  }
  return false;
};

export const areWysiwygFieldCompleted = (fields) => fields.reduce((previousValue, currentValue) =>
  previousValue && isWysiwygFieldCompleted(currentValue), true);

const WysiwygEditor = ({
  value,
  initialValue,
  onChange,
  showHelper,
  id,
  toolbar = {
    options: ['inline', 'list', 'link'],
    inline: {
      inDropdown: false,
      className: 'groupOptions',
      options: ['bold', 'italic', 'underline', 'strikethrough'],
    },
    list: {
      className: 'groupOptions',
      inDropdown: false,
      options: ['unordered'],
    },
    link: {
      showOpenOptionOnHover: false,
    },
  },
  toolbarCustomButtons,
  localization={
    locale: 'en',
  }
}) => {

  const [editorState, setEditorState] = useState()

  useEffect(() => {
    setEditorState(initEditorState());
  }, [])

  const onBlur = () => {

    if (!isUndefined(showHelper)) {
      showHelper(null);
    }
  };

  const getHelper = () => {
    if (showHelper) {
      showHelper(id);
    }
  };

  /**
   * Initialising the value for <Editor />
   */
  const initEditorState = () => {
    const html = initialValue || value || '';
    const contentBlock = htmlToDraft(html);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    return EditorState.createWithContent(contentState);
  }

  /**
   * This is used by <Editor /> to handle change
   */
  const handleChange = (newEditorState) => {
    setEditorState(newEditorState)
    changeValue(newEditorState);
  }

  /**
   * This updates the redux-form wrapper
   */
  const changeValue = (newEditorState) => {
    const value = draftToHtml(convertToRaw(newEditorState.getCurrentContent()));
    onChange(value);
  }

  return (
    <Editor
      editorState={editorState}
      wrapperClassName="wrapper-class"
      editorClassName="editor-class"
      toolbarClassName="toolbar-class"
      onEditorStateChange={(editorState) => handleChange(editorState)}
      stripPastedStyles
      onFocus={getHelper}
      onBlur={onBlur}
      toolbar={toolbar}
      value={value}
      initialValue={initialValue}
      onChange={onChange}
      showHelper={showHelper}
      id={id}
      localization={localization}
      toolbarCustomButtons={toolbarCustomButtons?.map((tool, key) => {
        return (
          <CustomLink index={key} editorState={editorState} onChange={onChange} page={tool} />
        )
      })}
    />
  );
}

WysiwygEditor.propTypes = {
  toolbar: object,
  value: any,
  initialValue: any,
  onChange: func,
  showHelper: func,
  id: string,
}

export default WysiwygEditor;
