import { AvatarBusiness, AvatarCandidate, Body1, Button, CardOffer as OfferCard } from '@seekube-tech/ui-kit';
import React from 'react';
import { func, object, string } from 'prop-types';
import { Contracts, Locations } from '@/helpers/criterion';
import { arrayContainAll } from '@/utils/math';
import { EVENT_FORMAT } from '@/utils/constants';
import { ParticipationsIcons } from '@/components/ParticipationsIcons';
import { InteractionsTags } from '@/components/CardOffer/InteractionsTags';
import styles from './styles.less';
import { getLocale } from '@/utils/localStorage';

const CardOffer = ({
  action,
  onClickAction,
  offer,
  event,
  interaction,
  cover,
  avatar,
  context,
  participant
}) => {
  const locale = getLocale();
  const criteria = offer?._event?._criteria || event._criteria;
  const Tags = () => (
    <div className={styles.tags}>
      <InteractionsTags interaction={interaction} />
      <ParticipationsIcons
        context='offer'
        formats={offer.keyMomentFormats}
        className={styles.participationsIcons}
        name={offer._organization.name}
        visible={context === 'owner' || arrayContainAll(participant?.keyMomentFormats, [EVENT_FORMAT.PHYSICAL, EVENT_FORMAT.VIRTUAL])}
      />
    </div>
  )

  return (
    <OfferCard
      pictureUrl={cover}
      action={action &&
        <div className="flex flex-col justify-center items-center">
          <Button
            onClick={onClickAction}
            className="w-full lg:w-auto"
            variant="outline"
          >
            {action}
          </Button>
        </div>}
      user={
        <div className="flex items-center mb-4">
          <AvatarCandidate user={offer._user} pictureUrl={offer._user?.pictureUrl} size={40} />
          <Body1 className="ml-16 text-neutral-400">{offer._user.shortName}  @{offer._organization.name}</Body1>
        </div>}
      title={offer.title}
      moreInfos={
        <div className="flex">
          <Contracts _criteria={criteria} matching={offer.matching} className="mr-12" />
          <Locations locations={offer.locations} formatAddress="shortAddress" />
        </div>}
      tag={<Tags />}
      avatarBusinessProps={avatar && {
        pictureUrl: avatar,
        badge: offer._organization?.isPartner ? event.modules.partner?.icon : null,
        color: offer._organization?.isPartner ? event.modules.partner?.color : 'default',
        tooltip: event.modules.partner?.[locale === 'fr' ? 'label_fr' : 'label_en'],
      }}
    />
  )
}

CardOffer.propTypes = {
  action: func,
  onClickAction: func,
  offer: object,
  event: object,
  interaction: object,
  cover: string,
  avatar: string,
  context: string,
  participant: object
}

export default CardOffer;
