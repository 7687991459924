import React from 'react';
import { compose } from 'redux';
import { array, func, shape, any, object, string, bool, number } from 'prop-types';
import moment from 'moment';
import { isUndefined, isEmpty, isEqual, upperFirst } from 'lodash';
import { injectIntl } from 'react-intl';
import { Form } from 'antd/lib/index';
import { Button } from '@seekube-tech/ui-kit';
import { ANALYTICS_CANDIDATE } from '@/utils/constants';
import { track } from '@/utils/analytics';
import { toJS } from '@/utils/index';
import Separator from '@/components/Separator';
import { H3 } from '@/components/Title';
import PositionForm from './PositionForm';
import messages from './messages';

import './styles.less';
import FormFooter from '../FormFooter';
import { trackGtmEvent } from "@/utils/pixels/helloWork";

const hasErrors = (fieldsError) => Object.keys(fieldsError).some((field) => fieldsError[field]);

class UserExperience extends React.PureComponent {
  static propTypes = {
    form: shape({
      getFieldDecorator: func,
      getFieldsError: func,
      getFieldError: func,
      isFieldTouched: func,
    }),
    currentStep: any,
    handleDataUpdate: func,
    authUser: object,
    user: object,
    event: object,
    defaultCriteria: array,
    intl: object,
    context: string,
    stepPosition: number,
    countEnabledSteps: number,
    eventSteps: object,
  };

  static defaultProps = {
    context: 'event',
  };

  constructor(props) {
    super(props);

    this.state = {
      positionsCount: 0,
      positions: [],
      beginYears: [],
      endYears: [],
      months: Array.apply(0, Array(12))
        .map((_, i) => (
          upperFirst(moment().locale(props.user ? (props.user.locale || 'fr') : 'fr').month(i).format('MMMM')))
        ),
      formIsModify: [false, false],
      formIsEnabled: false,
    };
  }

  componentDidMount() {
    const { authUser, defaultCriteria, user } = this.props;
    this.setExperience();

    this.setBeginYears();
    this.setEndYears();


    if (!isEmpty(authUser) && !isEqual(authUser, user)) {
      this.setExperience();
    }

    if (!isEmpty(defaultCriteria) && isEmpty(this.state.educationLevels)) {
      this.setEducationLevels(defaultCriteria);
    }
  }

  setBeginYears = () => {
    const currentYear = new Date().getFullYear();
    const yearList = [];

    for (let i = currentYear; i >= currentYear - 45; i -= 1) {
      yearList.push(i);
    }

    this.setState({ beginYears: yearList });
  };

  setEndYears = () => {
    const currentYear = new Date().getFullYear() + 15;
    const yearList = [];

    for (let i = currentYear; i >= currentYear - 60; i -= 1) {
      yearList.push(i);
    }

    this.setState({ endYears: yearList });
  };

  setExperience = () => {
    const { props: { user }, state: { months } } = this;

    if (!isUndefined(user.positions) && user.positions.length > 0) {
      const newPositions = user.positions.map((position) => ({
        company: position._organization.name,
        title: position.title,
        beginAt: position.beginAt,
        endAt: !isUndefined(position.endAt) ? position.endAt : null,
        startMonth: months[new Date(position.beginAt).getMonth()],
        startYear: new Date(position.beginAt).getFullYear(),
        endMonth: !isUndefined(position.endAt) && months[new Date(position.endAt).getMonth()],
        endYear: !isUndefined(position.endAt) && new Date(position.endAt).getFullYear(),
        currentExperience: isUndefined(position.endAt),
      }));

      this.setState({
        positions: newPositions,
        positionsCount: newPositions.length,
      });
    } else {
      this.setState({
        positions: [{
          company: '',
          title: '',
          startMonth: '',
          startYear: '',
          endMonth: '',
          endYear: '',
          currentExperience: false,
        }],
        positionsCount: 1,
      });
    }
  };

  setFormIsModify = (order, itemIsModify) => {
    const { props: { form }, state: { formIsModify } } = this;

    formIsModify[order] = itemIsModify;
    this.setState({
      formIsModify,
      formIsEnabled: (!formIsModify[0] && !formIsModify[1]) || hasErrors(form.getFieldsError()),
    });
  };

  handleOnAddExperience = (e, positionForm) => {
    const { positionsCount, positions } = this.state;

    if (positions.length < 2) {
      const newPositions = [...positions, positionForm];

      this.setState({
        positionsCount: positionsCount + 1,
        positions: newPositions,
      });
    }
  }

  handleOnDeleteExperience = (order) => {
    const { positions } = this.state;
    const newPositions = positions;
    newPositions.splice(order, 1);

    this.setState({
      positionsCount: newPositions.length,
      positions: newPositions,
    });
  };

  handleOnSubmit = (e) => {
    e.preventDefault();
    const {
      props: { handleDataUpdate, user, event, form: { validateFields, getFieldsValue } },
      state: { months },
    } = this;

    const values = getFieldsValue();

    return validateFields((err) => {
      if (err) {
        return false;
      }

      const positions = [];

      for (let i = 0; i < this.state.positionsCount; i += 1) {
        if (!isEmpty(values[`company${i}`]) && !isEmpty(values[`title${i}`])) {
          const position = {
            company: values[`company${i}`],
            title: values[`title${i}`],
            startMonthExperience: values[`startMonthExperience${i}`],
            startYearExperience: values[`startYearExperience${i}`],
            endMonthExperience: values[`endMonthExperience${i}`],
            endYearExperience: values[`endYearExperience${i}`],
          };

          const startMonthIndex = months.findIndex((month) => month === position.startMonthExperience) + 1;

          position.beginAt =
            moment(`${position.startYearExperience}-${startMonthIndex < 10 ? '0' : ''}${startMonthIndex}-03`).toDate();

          if (position.endYearExperience) {
            const endMonthIndex = months.findIndex((month) => month === position.endMonthExperience) + 1;
            position.endAt =
              moment(`${position.endYearExperience}-${endMonthIndex < 10 ? '0' : ''}${endMonthIndex}-03`).toDate();
            position.isCurrent = false;
          } else {
            position.isCurrent = true;
          }
          positions.push(position);
        }
      }

      this.setState({
        positions
      }, () => {
        track({
          name: ANALYTICS_CANDIDATE.TOUR_STEP3_SUBMIT,
          user,
          event,
          properties: {
            'Count Experience': positions.length,
          },
        });

        if (event) {
          const toursStep = event.participants?.onboarding?.filter((step) => step.name === 'cv' && step.enabled === false) ? 3 : 2;

          trackGtmEvent('onboarding_experience_complete', {
            'page_category': 'inscription',
            'page_subject': 'onboarding',
            'user_id_back': user._id,
            'flow_name': 'inscription_jobdating',
            'flow_step': toursStep,
            'flow_step_total': event.countOnboardingSteps,
            'flow_modules_list': event.onboardingSteps,
            'flow_step_name': 'experience',
            'jobdating_name': event.slug,
          });
        }

        handleDataUpdate({ positions, lastEducationsUpdate: new Date() }, 3);
      });

      return values;
    });
  };

  render() {
    const { handleOnAddExperience, handleOnDeleteExperience, handleOnSubmit, setFormIsModify,
      state: { positionsCount, positions, beginYears, endYears, months, formIsEnabled },
      props: { form, user, intl, context, currentStep, eventSteps, countEnabledSteps, stepPosition },
    } = this;

    const settingExperienceTitle = (
      <H3 bold className="experienceTitle" id="userPositions">
        <span className="desktop">{intl.formatMessage(messages.experienceTitle)}&nbsp;</span>
        <span className="maxField">{`(2 ${intl.formatMessage(messages.max)})`}</span>
        <span role="img" aria-label="Flexed Biceps"> 💪</span>
      </H3>
    );

    return (
      <div className="commonStep">
        <Form className="form" onSubmit={handleOnSubmit}>
          <div>
            {context === 'event' ? (
              <H3 bold className="experienceTitle">
                <span className="desktop">{isEmpty(user) || !user.educations ? intl.formatMessage(messages.experienceTitle) : intl.formatMessage(messages.experienceTitleVerify)}&nbsp;</span>
                <span className="mobile">{intl.formatMessage(messages.experienceTitleMobile)}&nbsp;</span>
                <span className="maxField">{`(2 ${intl.formatMessage(messages.max)})`}</span>
                <span role="img" aria-label="Flexed Biceps"> 💪</span>
              </H3>
            ) : settingExperienceTitle}
            {positions.map((position, i) => (
              <PositionForm
                form={form}
                key={i}
                order={i}
                experience={position}
                handleAddExperience={handleOnAddExperience}
                handleDeleteExperience={handleOnDeleteExperience}
                experiencesCount={positionsCount}
                beginYears={beginYears}
                endYears={endYears}
                months={months}
                context={context}
                setFormIsModify={setFormIsModify}
                user={user}
              />
            ))}
            {isEmpty(positions) && context === 'settings' ? (
              <PositionForm
                form={form}
                order={0}
                experience={{}}
                handleAddExperience={handleOnAddExperience}
                handleDeleteExperience={handleOnDeleteExperience}
                experiencesCount={positionsCount}
                beginYears={beginYears}
                endYears={endYears}
                months={months}
                context={context}
                setFormIsModify={setFormIsModify}
                user={user}
              />
            ) : ''}
            {context === 'settings' && (
              <Button
                className="changeStep settings"
                disabled={formIsEnabled}
                type="submit"
                onClick={handleOnSubmit}
              >
                {intl.formatMessage(messages.save)}
              </Button>
            )}
          </div>
        </Form>
        <Separator className="hidden-mobile" height={20} />

        {context !== 'settings' ? (
          <FormFooter
            form={form}
            currentStep={currentStep}
            eventSteps={eventSteps}
            stepPosition={stepPosition}
            countEnabledSteps={countEnabledSteps}
            onSubmit={this.handleOnSubmit}
          />
        ) : null}
      </div>
    );
  }
}

const withForm = Form.create();

export default compose(
  injectIntl,
  withForm,
)(toJS(UserExperience));
