import { useMutation, useQueryClient } from 'react-query';
import { filter } from 'lodash';
import request from '@/utils/request';

export function useDeleteDeal(
  {
    authToken,
    page,
    onSuccess = () => {},
  }) {
  const queryClient = useQueryClient();

  const mutationFn = ({ _id }) => {
    const requestUrl = `${process.env.FRONT_API_URL}/deals/${_id}`;

    return (
      request(requestUrl, {
        method: 'DELETE',
      })
    )
  }

  return useMutation({
    mutationFn,
    onMutate: async (deal) => {
      const deals = queryClient.getQueryData(['deals', page]);
      const newDocs = filter(deals.docs, (prevDeals) => prevDeals._id !== deal._id);
      const newDeals = { ...deals, docs: newDocs };

      queryClient.setQueryData(['deals', page], newDeals);

      return newDeals;
    },
    onSuccess
  });
}
