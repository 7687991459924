import React from 'react';
import { keys } from 'lodash';
import ReactPixel from 'react-facebook-pixel';

const pixelsIds = {
  'metiers-des-commerciaux-mars-20221': '2488336321189882',
  'naval-group-days-metiers-de-lit-1': '494035472338200',
  'metiers-de-lingenieur-juin-20221': '703434580833475',
  'job-dating-days-ortec-1': '494035472338200',
  'forum-de-recrutement-studi-100-presentiel-lille-commerciaux-1': '1144580063106885',
  'metiers-des-commerciaux-lyon-1': '703434580833475',
  'forum-de-recrutement-studi-100-virtuel-lille-commerciaux-1': '494035472338200',
  'job-dating-virtuel-nantes-tech-1': '518455166705060',
  'metiers-des-commerciaux-lille-1': '494035472338200',
  'metiers-de-lit-lyon-1': '1144580063106885',
  'metiers-de-lit-lille-1': '518455166705060',
  'talent-fair-station-f-x-galileo-1': '518455166705060',
  'revamp-xp-mobile-1': '518455166705060',
  'school-dating-ecoles-dinformatique-a-paris-1': '494035472338200',
  'school-dating-ecoles-de-design-a-paris-1': '494035472338200',
  'school-dating-commerce-et-management-a-paris-1': '494035472338200',
}

const gtagSlugs = [
  'school-dating-ecoles-dinformatique-a-paris-1',
  'school-dating-ecoles-de-design-a-paris-1',
  'school-dating-commerce-et-management-a-paris-1',
  'talent-fair-station-f-x-galileo-1',
  'revamp-xp-mobile-1',
]

const getHelloWorkPixel = (slug) => {
  // If not in the slug list then return null
  if (
    window.location.hostname !== 'app.seekube.com'
    && !keys(pixelsIds).includes(slug)) {
    return null;
  }

  const init = () => {
    ReactPixel.init(pixelsIds[slug], {}, {
      autoConfig: true, // set pixel's autoConfig
      debug: false, // enable logs
    })
  }

  if (!keys(pixelsIds).includes(slug)) return null;

  return ({
    init,
    pageView: () => {
      init();
      ReactPixel.pageView();
    },
    track: () => {
      init();
      console.log('Track Purchase')
      ReactPixel.track('Purchase', {
        value: 1.00,
        currency: 'EUR',
      });
    },
    trackPreregistration: () => {
      if (gtagSlugs.indexOf(slug) > -1) {
        console.log('GTag school_dating_preregistration');
        window.dataLayer.push({ 'event': 'school_dating_preregistration' })
      }
    },
    trackRegistration: () => {
      if (gtagSlugs.indexOf(slug) > -1) {
        console.log('GTag school_dating_registration');
        window.dataLayer.push({ 'event': 'school_dating_registration' })
      }
    },
  })
};

export function trackGtmEvent(eventName, eventParams) {
  if (eventName === 'page.display') {
    eventParams.event = 'virtual.page.display';

    const elems = eventParams.path.split('/');

    if (['recruiter', 'candidate', 'owner'].includes(elems[2])) {
      eventParams.jobdating_name = elems[1];
    }

    if (eventParams.path?.indexOf('/auth/login') > -1) {
      eventParams.page_category = 'login';
      eventParams.page_subject = 'login';
    } else if (eventParams.path?.indexOf('/resetpassword') > -1) {
      eventParams.page_category = 'login';
      eventParams.page_subject = 'resetpassword';
    } else if (eventParams.path?.indexOf('/auth/signup/candidate/authentication') > -1) {
      eventParams.page_category = 'inscription';
      eventParams.page_subject = 'authentication';
    } else if (eventParams.path?.indexOf('/auth/signup/candidate/search') > -1) {
      eventParams.page_category = 'inscription';
      eventParams.page_subject = 'search';
    } else if (eventParams.path?.indexOf('/auth/signup/candidate/discovery') > -1) {
      eventParams.page_category = 'inscription';
      eventParams.page_subject = 'discovery';
    } else if (eventParams.path?.indexOf('/auth/signup/candidate/profile') > -1) {
      eventParams.page_category = 'inscription';
      eventParams.page_subject = 'profile';
    } else if (eventParams.path?.indexOf('/auth/signup/candidate') > -1) {
      eventParams.page_category = 'inscription';
      eventParams.page_subject = 'signup';
    } else if (eventParams.path?.indexOf('/events') > -1) {
      eventParams.page_category = 'profil';
      eventParams.page_subject = 'profil';
    } else if (eventParams.path?.indexOf('/events/archive') > -1) {
      eventParams.page_category = 'profil';
      eventParams.page_subject = 'archive';
    } else if (eventParams.path?.indexOf('/candidate/jobdating/jobs') > -1) {
      eventParams.page_category = 'jobdating';
      eventParams.page_subject = 'jobs';
    } else if (eventParams.path?.indexOf('/candidate/preparation/onboarding/signin') > -1) {
      eventParams.page_category = 'event_onboarding';
      eventParams.page_subject = 'login';
    } else if (eventParams.path?.indexOf('/candidate/preparation/onboarding/signup') > -1) {
      eventParams.page_category = 'event_onboarding';
      eventParams.page_subject = 'signup';
    } else if (eventParams.path?.indexOf('/candidate/preparation/onboarding/profile') > -1) {
      eventParams.page_category = 'event_onboarding';
      eventParams.page_subject = 'profil';
    } else if (eventParams.path?.indexOf('/candidate/preparation/onboarding/matching') > -1) {
      eventParams.page_category = 'event_onboarding';
      eventParams.page_subject = 'candidature';
    } else if (eventParams.path?.indexOf('/candidate/jobdating/exponents') > -1) {
      eventParams.page_category = 'jobdating';
      eventParams.page_subject = 'stands';
    } else if (eventParams.path?.indexOf('/candidate/jobdating/lives') > -1) {
      eventParams.page_category = 'jobdating';
      eventParams.page_subject = 'webinar';
    } else if (eventParams.path?.indexOf('/candidate/jobdating/dashboard') > -1) {
      eventParams.page_category = 'jobdating';
      eventParams.page_subject = 'dashboard';
    } else if (eventParams.path?.indexOf('/candidate/jobdating/jobs/') > -1) {
      eventParams.page_category = 'jobdating';
      eventParams.page_subject = 'job_detail';
    } else if (eventParams.path?.indexOf('/candidate/jobdating/exponents/') > -1) {
      eventParams.page_category = 'jobdating';
      eventParams.page_subject = 'stand_detail';
    } else if (eventParams.path?.indexOf('/candidate/jobdating/appointments/') > -1) {
      eventParams.page_category = 'jobdating';
      eventParams.page_subject = 'appointment';
    } else if (eventParams.path?.indexOf('/candidate/jobdating/appointments') > -1) {
      eventParams.page_category = 'jobdating';
      eventParams.page_subject = 'meetings';
    } else if (eventParams.path?.indexOf('/seekalendar') > -1) {
      eventParams.page_category = 'home';
      eventParams.page_subject = 'home_seekalendar';
    } else if (eventParams.page_category !== 'landing_page') {
      eventParams.page_category = 'default';
      eventParams.page_subject = 'default';
    }
  } else {
    eventParams.event_name = eventName;
    eventParams.event = 'generic';
  }

  window.dataLayer.push(eventParams);
  console.log(`GTAG Event ${process.env.NODE_ENV}`, eventParams);
}

export { getHelloWorkPixel };

