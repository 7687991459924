import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createStructuredSelector } from 'reselect';
import { push } from 'connected-react-router';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'redux';
import { map, size, isEqual, isEmpty, get, find, join, compact, head } from 'lodash';
import { array, func, object, bool, string, number, oneOfType } from 'prop-types';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Button, Pagination, Table } from '@seekube-tech/ui-kit';
import { withRouter } from 'react-router-dom';
import { useFormatMessage } from 'react-intl-hooks';
import getStartPublishingOffersOnEventParams from '@/helpers/action/recruiter/startPublishingOffersOnEvent';
import { toJS } from '@/utils';
import { EVENT_STATUS, LOCALES } from '@/utils/constants';
import { queryStringToObject, objectToParams } from '@/utils/url';
import { mapAndJoin } from '@/utils/text';
import { authSelectors } from '@/store/auth';
import { eventSelectors } from '@/store/event';
import { offerActions, offerSelectors } from '@/store/offer';
import { userActions } from '@/store/user';
import { organizationSelectors } from '@/store/organization';
import { criterionActions, criterionSelectors } from '@/store/criterion';
import { actionActions } from '@/store/action';
import { exponentActions, exponentSelectors } from '@/store/exponent';
import Wrapper from '@/components/Wrapper';
import { Tab } from '@/components/Tab';
import { getId, getIds } from '@/utils/global';
import BlankState from '@/components/BlankState';
import ModalV2 from '@/components/ModalV2';
import { history } from '@/configureStore';
import SearchEngineOffers from './components/SearchEngineOffers';
import Columns from './components/Columns';
import GroupedActions from '../../../Event/scenes/Recruiter/scenes/Jobdating/container/ParticipantsView/components/GroupedActions';
import { PageBlank } from './components/Blankstates';
import offersMessages from './messages';
import messages from '../../../Event/scenes/Recruiter/scenes/Preparation/scenes/Offer/messages';
import styles from './styles.less';
import { useGetOrganizationUserOffers } from '@/queries/offers/useGetOrganizationUserOffers';
import { useGetOrganizationUsersSelect } from '@/queries/organizations/useGetOrganizationUsersSelect';

const PAGE_SIZE = 25;

function ClientOffersScene({
  authUser,
  pagination: { total },
  exponents,
  defaultCriteria,
  deleteGlobalOffer,
  patchGlobalOffer,
  duplicateGlobalOffer,
  getExponentsByOrganization,
  recruiterSelectedIds = '',
  eventSelectedIds = '',
  contractSelectedIds = '',
  eventSelectedId = 'all',
  page = 1,
  offset = 0,
  keywords = '',
  status = 'published',
  updatedAt = 'desc',
  getDefaultCriteria,
  patchDraftOffers,
  deleteOffers,
  postAction,
}) {
  const t = useFormatMessage();
  const [checkedOffers, setCheckedOffers] = useState([]);
  const [removeOfferModalVisible, setRemoveOfferModalVisible] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [currentOffer, setCurrentOffer] = useState(null); // used for modal
  const [isBtnPageParticipantsActive, setIsBtnPageParticipantsActive] = useState(false);
  const [isBtnAllOffersActive, setIsBtnAllOffersActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { data: offersQuery, isFetching, refetch: refetchOffers } = useGetOrganizationUserOffers({
    authUser,
    keywords,
    status,
    search: {
      limit: PAGE_SIZE,
      page,
      offset,
      allUsers: isEmpty(recruiterSelectedIds),
      updatedAt,
      recruiterSelectedIds,
      contractSelectedIds,
      eventSelectedIds,
    },
  });
  const { data: users } = useGetOrganizationUsersSelect({
    organization: authUser._currentOrganization,
    keywords,
  });

  useEffect(() => {
    if (isEmpty(defaultCriteria)) {
      getDefaultCriteria();
    }

    getExponentsByOrganization({
      organizationId: get(authUser, '_currentOrganization._id'),
      page: 1,
      offset: 0,
      limit: 1000,
      archive: false,
    });
  }, []);

  const onSearchChange = ({ keywords, recruiterSelectedIds, eventSelectedIds, contractSelectedIds }) => {
    const paramsObject = { ...queryStringToObject(history.location.search), ...defaultPagination, keywords, recruiterSelectedIds, eventSelectedIds, contractSelectedIds };
    dispatch(push(`offers?${objectToParams(paramsObject)}`));
  };

  const onSortChange = (arr) => {
    const updatedAt = arr.find((a) => a.id === 'updatedAt')?.desc ? 'desc' : 'asc';
    const { updatedAt: beforeUpdatedAt } = queryStringToObject(history.location.search);

    if (beforeUpdatedAt !== updatedAt) {
      const paramsObject = { ...queryStringToObject(history.location.search), updatedAt, ...defaultPagination };
      dispatch(push(`offers?${objectToParams(paramsObject)}`));
    }
  };

  // const getActiveCss = (currStatus) => status === currStatus ? '' : styles.notActive;

  const handleOnStatusChange = (status) => {
    const paramsObject = { ...queryStringToObject(history.location.search), status, ...defaultPagination };
    dispatch(push(`offers?${objectToParams(paramsObject)}`));
  };

  const handleOnChangePagination = (page) => {
    const paramsObject = { ...queryStringToObject(history.location.search), page, offset: (page - 1) * PAGE_SIZE };

    dispatch(push(`offers?${objectToParams(paramsObject)}`));

    window.scrollTo(0, 0);
  };

  const defaultPagination = { page: 1, limit: PAGE_SIZE, offset: 0 };

  const isSearchActive = () => total === 0 && (
    (status === 'published' && offersQuery?.stats?.totalPublished) ||
    (status === 'draft' && offersQuery?.stats?.totalDraft)
  );

  const duplicateOffer = (offer) => {
    duplicateGlobalOffer({ offerId: getId(offer), organizationId: getId(authUser._currentOrganization._id), callback: (off) => getOverlayProperties(off).edit.onClick() });
  };

  const draftOffer = (offer) => {
    patchGlobalOffer({
      offerId: offer._id,
      params: { status: 'draft' },
      notificationParams: {
        success: {
          message: t({ id: 'notifications.update.success' }),
          kind: 'info',
          style: {
            bottom: '5%',
            top: 'inherit',
          },
        }
      }
    });
  };


  const getContracts = (items, contractCriteria) => (
    join(
      map(items, (criteriaId) =>
        get(
          find(contractCriteria._choices,
            (choice) =>
              getId(criteriaId) === getId(choice)),
          [authUser.locale === LOCALES.fr ? 'label' : 'label_en']),
      ).filter(Boolean), ', ')
  );

  const getOverlayProperties = (offer) => (
    {
      preview: {
        href: `/client/offer-edit?step=0&offerId=${offer.parentId || offer._id}&preview=true`,
        targetBlank: true,
        icon: 'eye',
        text: offersMessages.preview,
      },
      edit: {
        condition: true,
        onClick: () => dispatch(push(`/client/offer-edit?step=0&offerId=${offer._id}`)),
        icon: 'pencil',
        text: messages.offerBtnEdit,
      },
      draft: {
        condition: isEqual(offer.status, 'published') && isEqual(eventSelectedId, 'all'),
        onClick: () => draftOffer(offer),
        icon: 'icon-hide',
        text: messages.offerBtnDraft,
      },
      publishOnJobdating: {
        onClick: () => dispatch(push(`/client/offer-edit?step=0&offerId=${offer._id}`)),
        icon: 'add-plus-rounded',
        text: offersMessages.publishOfferOnJobdating,
      },
      removeFromJobdating: {
        onClick: () => dispatch(push(`/client/offer-edit?step=1&offerId=${offer._id}`)),
        icon: 'minus-rounded',
        text: offersMessages.removeOfferFromJobdating,
      },
      addRecruiter: {
        onClick: () => dispatch(push(`/client/offer-edit?step=0&offerId=${offer._id}`)),
        icon: 'user',
        text: offersMessages.addRecruiter,
      },
      duplicate: {
        onClick: () => duplicateOffer(offer),
        icon: 'duplicate',
        text: messages.offerBtnDuplicate,
      },
      delete: {
        condition: ['draft', 'expired'].includes(status),
        onClick: () => {
          setModalType('delete');
          setRemoveOfferModalVisible(true);
          setCurrentOffer(offer);
        },
        text: messages.offerBtnDelete,
        icon: 'trash',
      },
    });

  const getModalProperties = () => {
    if (isEmpty(modalType)) return null;

    const isGroupedAction = isEmpty(currentOffer);


    return ({
      publishOnJobdating: {
        onClick: () => {
          dispatch(push('/client/offers/spreading?step=1'));
        },
        icon: 'add-plus-rounded',
        title: t(offersMessages.publishOfferOnJobdating)
      },
      delete: {
        title: <FormattedMessage
          {...offersMessages.removeOffersTitlePl}
          values={isGroupedAction ? { count: size(checkedOffers), offer: size(checkedOffers) === 1 ? get(head(checkedOffers), 'title') : null } : { count: 1, offer: currentOffer.title }}
          tagName="h3"
        />,
        subtitle: <FormattedMessage {...offersMessages.confirmRemove} values={{ count: isGroupedAction ? size(checkedOffers) : 1 }} tagName="p" />,
        onClick: () => {
          if (isGroupedAction) {
            setLoading(true);
            deleteOffers({
              offersId: getIds(checkedOffers),
              batchAll: isBtnAllOffersActive,
              search: window.location.search,
              callback: () => {
                setLoading(false);
                setIsBtnAllOffersActive(false);
                setRemoveOfferModalVisible(false);
                setIsBtnPageParticipantsActive(false);
                refetchOffers();
              },
              notificationParams: {
                success: {
                  message: t({ id: 'notifications.update.success' }),
                  kind: 'info',
                  style: {
                    bottom: '5%',
                    top: 'inherit',
                  },
                },
              }
            });
          } else {
            deleteGlobalOffer({
              offerId: getId(currentOffer),
              organizationId: getId(authUser._currentOrganization),
              callback: refetchOffers,
              notificationParams: {
                success: {
                  message: t({ id: 'notifications.update.success' }),
                  kind: 'info',
                  style: {
                    bottom: '5%',
                    top: 'inherit',
                  },
                },
              }
            });
            setRemoveOfferModalVisible(false);
          }
        },
      },
      draft: {
        title: <FormattedMessage
          {...offersMessages.draftOffersTitlePl}
          values={{ count: size(checkedOffers), offer: mapAndJoin(checkedOffers, 'title', ', ') }}
          tagName="h3"
        />,
        subtitle: <FormattedHTMLMessage
          {...offersMessages.draftOffersSubtitlePl}
          values={{ count: size(checkedOffers) }}
          tagName="p"
        />,
        onClick: () => {
          if (isGroupedAction) {
            setLoading(true);

            patchDraftOffers({
              offersId: getIds(checkedOffers),
              batchAll: isBtnAllOffersActive,
              search: window.location.search,
              callback: () => {
                setLoading(false);
                setIsBtnAllOffersActive(false);
                setIsBtnPageParticipantsActive(false);
                setRemoveOfferModalVisible(false);
                refetchOffers();
              },
              notificationParams: {
                success: {
                  message: t({ id: 'notifications.update.success' }),
                  kind: 'info',
                  style: {
                    bottom: '5%',
                    top: 'inherit',
                  },
                },
              }
            });
          } else {
            deleteGlobalOffer({
              offerId: getId(currentOffer),
              organizationId: getId(authUser._currentOrganization),
              notificationParams: {
                success: {
                  message: t({ id: 'notifications.update.success' }),
                  kind: 'info',
                  style: {
                    bottom: '5%',
                    top: 'inherit',
                  },
                },
              }
            });
            setRemoveOfferModalVisible(false);
          }
        },
      },

    }[modalType]);
  };

  const groupedActionsOptions = () => ({
    delete: {
      condition: isEqual(status, 'draft'),
      onClick: () => {
        setCurrentOffer(null);
        setModalType('delete');
        setRemoveOfferModalVisible(true);
      },
      text: messages.delete,
      icon: 'trash',
    },
    draft: {
      condition: isEqual(status, 'published'),
      onClick: () => {
        setCurrentOffer(null);
        setModalType('draft');
        setRemoveOfferModalVisible(true);
      },
      text: messages.offerBtnDraft,
      icon: 'icon-hide',
    },
    publishOnJobdating: {
      onClick: () => {
        const paramsObject = { ...queryStringToObject(history.location.search), status, ...defaultPagination };

        window.localStorage.setItem('offersSelected', JSON.stringify(getIds(checkedOffers)));
        postAction(getStartPublishingOffersOnEventParams({ authUser, countOffers: size(getIds(checkedOffers)) }));
        dispatch(push(`/client/offers/spreading?step=1${objectToParams(paramsObject)}`, { checkedOffers, isBtnAllOffersActive }));
      },
      icon: 'add-plus-rounded',
      text: offersMessages.publishOfferOnJobdating,
    },
  });

  const getEventsWhereOffIsPublished = (offer) => (
    offer?.childrenOffers.reduce((acc, childOffer) => {
      if (offer.status === 'published' && !isEmpty(childOffer._event) && childOffer._event.dateStatus !== EVENT_STATUS.CLOSED) {
        return [...acc, childOffer._event.name]
      }
      return acc
    }, [])
  )

  const computeData = useMemo(() => map(offersQuery?.docs,
    (offer, key) => ({
      key,
      offer,
      title: offer.title,
      user: offer._user,
      locations: join(compact(map(offer.locations, (o) => o.formattedAdress)), ', '),
      contracts: getContracts(offer.matching.CONTRACT, find(offer?._event?._criteria, (criteria) => criteria.key === 'CONTRACT') || []),
      eventsWhereOffIsPublished: getEventsWhereOffIsPublished(offer),
      updatedAt: offer.updatedAt,
      moreDropDownProperties: getOverlayProperties(offer),
    })
  ), [offersQuery?.docs]);

  const onToggleOffer = useCallback((selection) => {
    const usersToCheck = selection.map(user => user.original.offer);
    setCheckedOffers(usersToCheck);
    setIsBtnAllOffersActive(false);
  }, []);
  const tableColumns = useMemo(() => Columns(t, updatedAt), []);
  const renderBlank = () => {
    if (isSearchActive()) return <PageBlank />;

    return (
      <BlankState
        title={t(messages.blankStateOfferTitle)}
        className={styles.blankTitle}
        icon="blank-state-plus"
        handleAction={() => dispatch(push('/client/offer-edit?step=0'))}
        buttonLabel={t(messages.blankStateOfferBtn)}
        buttonType="default"
      />
    );
  };


  const modalProperties = getModalProperties();

  return (
    <Wrapper type="scene">
      <div className={styles.offersContainer} id="offersContainer">
        <div className={styles.offersHeaderContainer}>
          <div className={styles.firstLineContainer}>
            <Tab
              defaultIndex={status === 'published' ? 0 : 1}
              items={[
                { label: t(offersMessages.titlePublishOffer), count: offersQuery?.stats?.totalPublished, onClick: () => handleOnStatusChange('published') },
                { label: t(offersMessages.titleDraftOffer), count: offersQuery?.stats?.totalDraft, onClick: () => handleOnStatusChange('draft') },
              ]}
            />

            <Button onClick={() => dispatch(push('/client/offer-edit?step=0'))}>
              <FormattedMessage {...messages.blankStateOfferBtn} />
            </Button>
          </div>
        </div>

        <SearchEngineOffers
          status={status}
          recruiters={users}
          defaultCriteria={defaultCriteria}
          events={exponents.map((exponent) => exponent._event)}
          onSearch={onSearchChange}
          values={{ keywords, recruiterSelectedIds, eventSelectedIds, contractSelectedIds }}
        />

        <div>
          <GroupedActions
            checkedParticipants={checkedOffers}
            selectedParticipants={checkedOffers}
            handleGroupedAction={(e) => {
              if (e === 'draft') {
                patchDraftOffers({
                  offersId: getIds(checkedOffers),
                  callback: refetchOffers,
                  notificationParams: {
                    success: {
                      message: t({ id: 'notifications.update.success' }),
                      kind: 'info',
                      style: {
                        bottom: '5%',
                        top: 'inherit',
                      },
                    },
                  }
                });
              }
              if (e === 'remove') {
                deleteOffers({
                  offersId: getIds(checkedOffers),
                  callback: refetchOffers,
                  notificationParams: {
                    success: {
                      message: t({ id: 'notifications.update.success' }),
                      kind: 'info',
                      style: {
                        bottom: '5%',
                        top: 'inherit',
                      },
                    },
                  }
                });
              }
            }}
            customOptions={groupedActionsOptions()}
            context="offers"
            modalVisible={false}
            isBtnPageParticipantsActive={isBtnPageParticipantsActive}
            isBtnAllParticipantsChecked={isBtnAllOffersActive}
            onTogglePageParticipants={() => {
              if (isBtnPageParticipantsActive) { setIsBtnAllOffersActive(false); }

              setCheckedOffers(isBtnPageParticipantsActive ? [] : offersQuery?.docs);
              setIsBtnPageParticipantsActive(!isBtnPageParticipantsActive);
            }}
            totalParticipants={status === 'published' ? offersQuery?.stats?.totalPublished : offersQuery?.stats?.totalDraft}
            onToggleAllParticipants={() => {
              if (isBtnAllOffersActive) { setCheckedOffers([]); }
              setIsBtnAllOffersActive(!isBtnAllOffersActive);
            }}
            pagination={{
              current: parseInt(page, 10),
              total: offersQuery?.total,
            }}
          />
        </div>

        <div className={styles.offersContainer}>
          <div>
            <Table
              getCustomTableProps={() => ({ style: { width: '100%' } })}
              enableSelection
              handleSelectedRows={onToggleOffer}
              size="default"
              data={computeData}
              columns={tableColumns}
              isLoadingData={isFetching}
              blankStateComponent={renderBlank()}
              enableSort
              isSortByManual
              manuallySortBy={onSortChange}
            />

            <Pagination
              className="flex justify-center mt-20"
              onChange={handleOnChangePagination}
              total={offersQuery?.total || 0}
              current={parseInt(page, 10)}
              pageSize={PAGE_SIZE}
            />
          </div>
        </div>
        {(!isEmpty(modalType)) && <ModalV2
          visible={removeOfferModalVisible}
          onCancel={() => {
          }}
          onConfirm={() => {
          }}
          template="confirm"
          onClose={() => setRemoveOfferModalVisible(false)}
        >
          <div className="modalHeader">

            {modalProperties.title}

          </div>
          <div className="modalContent">
            {modalProperties.subtitle}
          </div>
          <div className="confirm-actions">
            <Button
              type="primary"
              onClick={modalProperties.onClick}
              loading={loading}
            >
              {t(offersMessages.confirm)}
            </Button>
            <a role="button" tabIndex={0} onClick={() => setRemoveOfferModalVisible(false)}>
              {t(offersMessages.cancel)}
            </a>
          </div>
        </ModalV2>
        }
      </div>
    </Wrapper>
  );
}

ClientOffersScene.propTypes = {
  match: object,
  history: object,
  authUser: object,
  defaultCriteria: object,
  pagination: object,
  offers: array,
  exponents: array,
  recruiterSelectedId: string,
  eventSelectedId: string,
  page: oneOfType([string, number]),
  offset: number,
  keywords: string,
  updatedAt: string,
  recruiterSelectedIds: string,
  contractSelectedIds: string,
  eventSelectedIds: string,
  status: string,
  getUsersOrganization: func,
  deleteGlobalOffer: func,
  patchGlobalOffer: func,
  duplicateGlobalOffer: func,
  getOrganizationUserOffers: func,
  getExponentsByOrganization: func,
  getDefaultCriteria: func,
  patchDraftOffers: func,
  deleteOffers: func,
  postAction: func,
  isFetching: bool,
  isExponentsFetching: bool,
};

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
  events: eventSelectors.getEvents,
  authUserEvents: eventSelectors.getAuthUserEvents,
  offers: offerSelectors.getOffers,
  exponent: exponentSelectors.getCurrentExponent,
  isFetching: offerSelectors.getOffersFetching,
  pagination: offerSelectors.getOfferPagination,
  organization: organizationSelectors.getCurrentOrganization,
  isExponentsFetching: exponentSelectors.getExponentsFetching,
  exponents: exponentSelectors.getExponents,
  defaultCriteria: criterionSelectors.getDefaultCriteria,
});

const mapDispatchToProps = {
  getDefaultCriteria: criterionActions.getDefaultCriteria,
  duplicateGlobalOffer: offerActions.duplicateGlobalOffer,
  deleteGlobalOffer: offerActions.deleteGlobalOffer,
  patchGlobalOffer: offerActions.patchGlobalOffer,
  getUsersOrganization: userActions.getUsersOrganization,
  getOrganizationUserOffers: offerActions.getOrganizationUserOffers,
  patchDraftOffers: offerActions.patchDraftOffers,
  deleteOffers: offerActions.deleteOffers,
  getExponentsByOrganization: exponentActions.getExponentsByOrganization,
  postAction: actionActions.postAction,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withRouter,
  withConnect,
)(toJS(ClientOffersScene));
