import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push, replace } from 'connected-react-router';
import { array, bool, func, object, string, oneOfType } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { isEmpty, join, size } from 'lodash';
import classnames from 'classnames';
import { Button, IconDownload } from '@seekube-tech/ui-kit';
import { Select as SelectAntd } from 'antd';
import { useFormatMessage } from 'react-intl-hooks';
import { toJS } from '@/utils';
import { track, trackRecruiterClickedQuitCreatingSlots, trackRecruiterQuitCreatingSlots } from '@/utils/analytics';
import { ANALYTICS_RECRUITER } from '@/utils/constants';
import { getLocalStorage, removeLocalStorage } from '@/utils/localStorage';
import { appointmentActions, appointmentSelectors } from '@/store/appointment';
import { participantActions, participantSelectors } from '@/store/participant';
import { timeslotSelectors, timeslotActions } from '@/store/timeslot';
import { authSelectors, authActions } from '@/store/auth';
import { eventSelectors, eventActions } from '@/store/event';
import { exponentSelectors } from '@/store/exponent';
import { interactionSelectors } from '@/store/interaction';
import { actionActions } from '@/store/action';
import { appActions } from '@/store/app';
import Select from '@/components/Form/Select';
import Icon from '@/components/Icon';
import SetTimezone from '@/components/SetTimezone';
import Modal from '@/components/Modal';
import PlanningScene from './scenes/Planning';
import CalendarScene from './scenes/Calendar';
import styles from './styles.less';
const { Option } = SelectAntd;


const Agenda = (props) => {
  const {
    authUser,
    event,
    planningEvent,
    exponent,
    events,
    appointments,
    downloadAppointments,
    setPlanningEvent,
    getUserTimeslots,
    setAgendaView,
    agendaView,
    getParticipant,
    postAction,
  } = props;
  const t = useFormatMessage();

  const [planningUser, setPlanningUser] = useState(authUser)
  const [loadingDownload, setLoadingDownload] = useState(false)
  const [archive, setArchive] = useState(false)
  const [displayDownloadError, setDisplayDownloadError] = useState(false)
  const [isQuitWithoutSavingModalVisible, setIsQuitWithoutSavingModalVisible] = useState(false)
  const currentOption = 'calendar';

  const updateCalendarUser = ({ _user }) => {
    getUserTimeslots({ loader: true, currentUser: _user, context: 'calendar' });
  };

  const updatePlanningUser = (value) => {
    setPlanningUser(value || null);
  };

  const getNotification = (messageIntl, kind, options = { emoji: '', withHtml: false }) => {
    const { emoji, withHtml } = options;
    const messageFormated = withHtml ? messageIntl : t(messageIntl);
    const space = ' ';
    const message = emoji ? [emoji, messageFormated] : [messageFormated];
    const messageWithHtml = <>{emoji} {messageFormated}</>;

    return {
      message: withHtml ? messageWithHtml : join(message, space),
      kind,
      style: {
        bottom: '5%',
        top: 'inherit',
      },
    };
  };

  const handleDownload = (appointment) => {
    const { resumeUrl } = appointment._user;

    if (resumeUrl) {
      track({
        name: ANALYTICS_RECRUITER.DOWNLOADED_CV,
        user: authUser,
        event: planningEvent._id,
        properties: {
          'Appointment Planned': appointment.status,
        },
      });

      postAction({
        actionParams: {
          name: 'RECRUITER_DOWNLOAD_CV',
          _user: authUser._id,
          _organization: authUser._currentOrganization._id,
          _event: planningEvent._id,
          data: { candidate: appointment._user._id, resumeUrl, context: 'agenda' },
        },
      });

      window.open(resumeUrl, '_blank');
    }
  };

  const trackOnAgendaViewChange = (view) => {
    track({
      name: ANALYTICS_RECRUITER.CLICKED_AGENDA_VIEW,
      user: authUser,
      event,
      properties: {
        view,
      },
    });
  };

  const updateAgendaView = (value, byPassPreventModal = true) => {
    const isEditingCalendar = JSON.parse(getLocalStorage('is_editing_calendar'));

    if (isEditingCalendar && value !== 'calendar') {
      if (byPassPreventModal) {
        setIsQuitWithoutSavingModalVisible(false);
        removeLocalStorage('is_editing_calendar');
      } else {
        trackRecruiterClickedQuitCreatingSlots({ authUser });
        return setIsQuitWithoutSavingModalVisible(true);
      }
    }

    setAgendaView({ view: value });
    return trackOnAgendaViewChange(value);
  };

  const handleGetParticipant = (userId, slug) => {
    getParticipant({
      eventId: slug || event.slug,
      userId,
      noLoader: true,
      openProfile: true,
      redirectUrl: `/${slug}/recruiter/jobdating/`,
    });
  };

  const handleOnChangePlanningEvent = (eventId) => {
    setPlanningEvent(eventId);
  }

  const handleToggleArchive = () => {
    setArchive(!archive);
  }

  const downloadAllCv = () => {
    if (typeof planningEvent === 'string' || isEmpty(planningEvent)) {
      setDisplayDownloadError(true);

      return false;
    }

    setDisplayDownloadError(false)
    setLoadingDownload(true)

    const recruiterId = planningUser ? [planningUser._id] : null;

    const callback = () => {
      track({
        name: ANALYTICS_RECRUITER.DOWNLOADED_APPOINTMENT_PLANNING,
        user: authUser,
        event,
        properties: {
          owner: size(recruiterId) === 1 && recruiterId[0] === authUser._id,
        },
      });

      setTimeout(
        () => {
          setLoadingDownload(false)
        },
        3000
      );
    };

    const notificationParams = {
      success: getNotification(<p><span role="img" aria-labelledby="">⏱️</span> {t({ id: 'download.running' })} <br /> {t({ id: 'recruiter.export.appointments' })}</p>, 'info', { emoji: ' ', withHtml: true }),
      error: true,
    };

    downloadAppointments({ appointment: appointments[0], recruiterId, eventSlug: planningEvent.slug, notificationParams, callback });

    return true;
  };

  if (isEmpty(authUser)) {
    return null;
  }

  return (
    <div id="jobdatingAgendaContainer" className={classnames(styles.agendaContainer, styles[agendaView])}>
      <div className={styles.agendaHeader}>
        <div className={styles.agendaContent}>
          <div className={styles.titleContainer}>
            <h1>{t({ id: 'settings.menu.agenda' })}</h1>
            <SetTimezone className={classnames(styles.agendaContent, styles.timezoneContent)} />
          </div>
          <div className={styles.headerActions} style={{ display: 'flex', alignItems: 'center' }}>
            {agendaView === 'planning' && (
              <div className={styles.exportAppointments}>
                <Button imageComponentLeft={<IconDownload />} onClick={downloadAllCv} loading={loadingDownload}>
                  {t({ id: 'client.stats.byjobdating.downloadPlanning' })}
                </Button>
              </div>
            )}
            <div>
              <Select
                defaultValue={agendaView || 'calendar'}
                value={agendaView || 'calendar'}
                suffixIcon={<Icon name="chevron" />}
                onSelect={(val) => updateAgendaView(val, false)}
                className={styles.inputView}
              >
                <Option value="planning">
                  <Icon name="list" className={styles.iconCalendar} /> {t({ id: 'event.recruiter.jobdating.agenda.view.planning' })}
                </Option>
                <Option value="calendar">
                  <Icon name="calendar" className={styles.iconCalendar} /> {t({ id: 'event.recruiter.jobdating.agenda.view.calendar' })}
                </Option>
              </Select>
            </div>
          </div>
        </div>
      </div>

      {agendaView === 'planning' ? (
        <PlanningScene
          {...props}
          archive={archive}
          displayDownloadError={displayDownloadError}
          onToggleArchive={handleToggleArchive}
          authUser={authUser}
          event={planningEvent}
          exponent={exponent}
          currentOption={currentOption}
          events={planningUser ? events.filter(_event => planningUser._events?.findIndex(e => e._id === _event._id) > -1) : events}
          onDownload={handleDownload}
          updateAgendaView={updateAgendaView}
          onChangeEvent={handleOnChangePlanningEvent}
          getParticipant={handleGetParticipant}
          onChangePlanningUser={updatePlanningUser}
          planningUser={planningUser}
        />
      ) : (
        event &&
        <CalendarScene
          {...props}
          event={event}
          events={events}
          setCurrentOption={updateCalendarUser}
          getParticipant={handleGetParticipant}
        />
      )}
      <Modal
        visible={isQuitWithoutSavingModalVisible}
        footer={false}
        maskClosable
        width={470}
        className="customConfirm"
        onCancel={() => { }}
      >
        <h4 style={{ fontWeight: 600 }}>
          {t({ id: 'event.recruiter.preparation.offer.published.form.update' })}
        </h4>

        <div className="confirm-actions" style={{ marginTop: '40px' }}>
          <Button
            className="mr-6"
            variant="tonal"
            onClick={() => {
              setIsQuitWithoutSavingModalVisible(false);
            }}
          >
            {t({ id: 'cancel' })}
          </Button>
          <Button
            onClick={() => {
              trackRecruiterQuitCreatingSlots({ authUser });
              setIsQuitWithoutSavingModalVisible(false);
              updateAgendaView('planning');
            }}
          >
            {t({ id: 'btn.confirm' })}
          </Button>
        </div>
      </Modal>
    </div>
  );

}

Agenda.propTypes = {
  authUser: object,
  event: object,
  planningEvent: oneOfType([object, string]),
  exponent: object,
  events: array,
  timeslots: array,
  appointments: array,
  appointmentsByOrganization: array,
  getUserAppointments: func,
  closeCalendar: func,
  getAppointments: func,
  downloadAppointments: func,
  getUsersOrganization: func,
  setPlanningEvent: func,
  getAuthUserEvents: func,
  getUserTimeslots: func,
  setAgendaView: func,
  agendaView: string,
  authToken: string,
  location: object,
  interactions: array,
  cancelAppointment: func,
  patchAppointmentDate: func,
  selectParticipant: func,
  getParticipant: func,
  toggleSettings: func,
  postAction: func,
  participant: object,
  exponentIsInit: bool,
  isOpen: bool,
  push: func,
}

const mapStateToProps = createStructuredSelector({
  appointments: appointmentSelectors.getAppointments,
  authToken: authSelectors.getAuthToken,
  agendaView: authSelectors.getAgendaView,
  participants: participantSelectors.getParticipants,
  planningEvent: eventSelectors.getPlanningEvent,
  exponent: exponentSelectors.getCalendarExponent,
  timeslots: timeslotSelectors.getCalendarUserTimeslots,
  interactions: interactionSelectors.getInteractions,
  participant: participantSelectors.getOpenParticipant,
});

const mapDispatchToProps = {
  setPlanningEvent: eventActions.setPlanningEvent,
  getAppointments: appointmentActions.getAppointments,
  modifyAppointment: appointmentActions.modifyAppointment,
  selectParticipant: participantActions.selectParticipant,
  cancelAppointment: appointmentActions.cancelAppointment,
  patchAppointmentDate: appointmentActions.patchAppointmentDate,
  downloadAppointments: appointmentActions.downloadAppointments,
  getUserTimeslots: timeslotActions.getUserTimeslots,
  getParticipant: participantActions.getParticipant,
  closeCalendar: appActions.closeCalendar,
  toggleSettings: authActions.toggleSettings,
  setAgendaView: authActions.setAgendaView,
  postAction: actionActions.postAction,
  replace,
  push,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  withRouter,
)(toJS(Agenda));
