import React, { useEffect } from 'react';
import { object } from 'prop-types';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { story } from '@/utils/manageVisibilityStory';
import LoadingIndicator from '@/components/LoadingIndicator';
import { isFromHelloWorkUtm, storeUtmParams } from '@/utils/url';
import { SceneAlerts } from './containers/SceneAlerts';
import NavJobdating from './components/NavJobdating';
import Jobs from './scenes/Jobs';
import Job from './scenes/Jobs/scenes/Job';
import Exponents from './scenes/Exponents';
import Conferences from './scenes/Conferences';
import { Appointments } from './scenes/Appointments';
import Appointment from './scenes/Appointment';
import Informal1to1 from './scenes/Informal1to1';
import Dashboard from './scenes/Dashboard';
import StandCandidate from './scenes/Exponents/scenes/Exponent/StandCandidate';
import { useGetParticipant } from '@/queries/participants/useGetParticipant';
import { getAuthUser } from '@/store/auth/selectors';
import { eventSelectors } from '@/store/event';
import { toJS } from '@/utils';
import { withLayout } from '@/containers/Layout';
import { SignupSuccessAlert } from '@/scenes/Event/scenes/Candidate/components/SignupSuccessAlert';
import { EVENT_TYPE } from '@/utils/constants';

const JobDatingScene = ({
  event,
  match,
}) => {
  const history = useHistory();
  const location = useLocation();
  const authUser = useSelector(getAuthUser)?.toJS();
  const participant = useGetParticipant({
    eventId: match.params.eventSlug,
    userId: authUser?._id,
    enabled: !!authUser
  });
  
  if (!location.pathname.includes('/jobdating/appointment/') && match.isExact) {
    history.replace(`${match.url}/jobs`);
  }

  useEffect(() => {
    storeUtmParams();
  }, []);

  if (participant.isLoading) {
    return (<LoadingIndicator />);
  }

  if (!location.pathname.includes('/jobdating/appointment/')) {
    if (!authUser && !isFromHelloWorkUtm()) {
      history.replace(`/${match.params.eventSlug}`);
    } else if (authUser && !participant.data && !isFromHelloWorkUtm()) {
      history.replace(`/${match.params.eventSlug}`);
    } else if (authUser && (!participant.data || !participant.data.isActive)) {
      history.push(`/${match.params.eventSlug}/candidate/preparation/onboarding?redirect=${window.location.pathname}`);
    }
  }

  return (
    <div>
      <div className="px-16">
        <SignupSuccessAlert isJpo={event.type === EVENT_TYPE.jpo} />
      </div>
      {
        authUser && (
          <>
            <NavJobdating match={match} withOfferModule={event.withOfferModule} modules={event.modules} />
            <SceneAlerts event={event} authUser={authUser} />
          </>
        )
      }
      <Switch>
        <Route path={`${match.path}/jobs/:jobID`} component={Job} />
        <Route path={`${match.path}/jobs`} component={Jobs} />
        <Route path={`${match.path}/exponents/:exponentID`} component={StandCandidate} />
        <Route path={`${match.path}/exponents`} component={Exponents} />
        <Route path={`${match.path}/appointments`} component={Appointments} />
        <Route path={`${match.path}/appointment/:appointmentID`} component={Appointment} />
        <Route path={`${match.path}/appointments/`} component={Appointments} />
        <Route path={`${match.path}/conferences`} component={Conferences} />
        <Route path={`${match.path}/informal1to1`} component={Informal1to1} />
        {story.dashboardCandidate.isVisible && <Route path={`${match.path}/dashboard`} component={Dashboard} />}
      </Switch>
    </div>
  );
}

JobDatingScene.propTypes = {
  event: object,
  match: object,
};

const mapStateToProps = createStructuredSelector({
  event: eventSelectors.getCurrentEvent,
});

const withConnect = connect(mapStateToProps, {});

export default compose(
  withConnect,
  withLayout({ size: 'full', navType: 'candidate' }),
)(toJS(JobDatingScene));