import React from 'react';
import { object } from 'prop-types';
import {
  Body1,
  IconBriefcase,
  IconFacebookFill,
  IconGlobe,
  IconInstagram,
  IconLinkedinFill,
  IconTiktok,
  IconTwitterFill,
  IconYoutubeFill
} from '@seekube-tech/ui-kit';

import styles from './styles.less'
import { useFormatMessage } from 'react-intl-hooks';
import { Link } from 'react-router-dom';

const StandSocialMedia = ({
  profile,
}) => {

  const t = useFormatMessage()

  const getIcons = (name) => {
    switch (name) {
      case 'facebook':
        return <IconFacebookFill size={16} />
      case 'youtube':
        return <IconYoutubeFill size={16} />
      case 'twitter':
        return <IconTwitterFill size={16} />
      case 'tiktok':
        return <IconTiktok size={16} />
      case 'instagram':
        return <IconInstagram size={16} />
      case 'linkedin':
        return <IconLinkedinFill size={16} />
      case 'website':
        return <IconGlobe size={16} />
      default:
        return <IconBriefcase size={16} />
    }
  }

  const links = Object.entries(profile?.externalLinks || []).filter(([_media, link]) => link);

  if (!links.length) {
    return null
  }

  return (
    <div className={styles.standSocialMediaContainer}>
      <Body1>{t({id: 'stand.external.links'})}</Body1>
      {links.map((link, index) => {
        if (link[1] !== null) {
          return (
            <a key={index} href={link[1]} target={'_blank'}>
              <div className={styles.icon}>{getIcons(link[0])}</div>
            </a>
          )
        }
      })}
    </div>
  )
}

StandSocialMedia.propTypes = {
  profile: object
}

export default StandSocialMedia;
