import { call, put, takeLatest, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { isUndefined, isEmpty } from 'lodash';
import moment from 'moment';
import { normalize } from 'normalizr';
import { EVENT_STATUS } from '@/utils/constants';
import { trackRegistration, trackSignin, trackSignout, trackError } from '@/utils/analytics';
import request from '@/utils/request';
import { queryStringToObject } from '@/utils/url';
import { changeLocale } from '@/containers/LanguageProvider/actions';
import { notificationActions } from '../notification';
import { getAuthUser } from './selectors';
import * as actions from './actions';
import * as types from './types';
import { resetParticipant, postParticipantSuccess, setCurrentParticipant, postParticipant } from '../participant/actions';
import { participantSchema } from '../participant/schema';
import * as entitiesActions from '../entities/actions';

function* refreshAuth({ payload: { user } }) {
  yield put(changeLocale(user.locale));
}

function* signup({ payload }) {
  const requestUrl = `${process.env.FRONT_API_URL}/users/signup`;
  const analytics = window.analytics || null;

  try {
    const result = yield call((url, options, ignoreCodeError = []) => request(url, options, ignoreCodeError, true), requestUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    // Save the user & the token
    yield put(actions.authLoginSuccess(result));

    if (result.user) {
      const { user } = result;

      if (analytics) {
        analytics.reset();
        analytics.alias(user._id);
        analytics.identify(result.user._id, result.user);
      }

      trackRegistration({
        user,
        method: 'Manual',
        event: payload._event,
        forum: payload._event ? payload._event.name : '',
      });
    }

    if (result.participant) {
      // Normaliz data
      const dataNormalized = normalize(result.participant, participantSchema);

      // Save entities
      yield put(entitiesActions.mergeEntities(dataNormalized.entities));

      // Put id in allIds
      yield put(postParticipantSuccess(dataNormalized.result));
      yield put(setCurrentParticipant(result.participant._id));
    }

    if (typeof payload.callback === 'function') {
      payload.callback();
    }
  } catch (err) {
    if (typeof payload.callback === 'function' && !err?.message) {
      err.then(e => payload.callback(e));
    }

    yield put(notificationActions.sendNotification({
      message: 'Erreur lors de la tentative de création de compte',
      kind: 'error',
      style: {},
    }));
    yield put(actions.authLoginError(err));
  }
}

function* sso({ payload }) {
  const requestUrl = `${process.env.FRONT_API_URL}/users/sso`;
  const analytics = window.analytics || null;

  try {
    const result = yield call(request, requestUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    // Save the user & the token
    yield put(actions.authLoginSuccess(result));

    if (result.user) {
      const { user } = result;

      if (analytics) {
        analytics.reset();
        analytics.alias(user._id);
        analytics.identify(result.user._id, result.user);
      }

      trackRegistration({
        user,
        method: 'SSO',
        event: payload._event,
        forum: payload._event ? payload._event.name : '',
      });
    }

    if (result.participant) {
      // Normaliz data
      const dataNormalized = normalize(result.participant, participantSchema);

      // Save entities
      yield put(entitiesActions.mergeEntities(dataNormalized.entities));

      // Put id in allIds
      yield put(postParticipantSuccess(dataNormalized.result));
      yield put(setCurrentParticipant(result.participant._id));

      if (result.participant.isActive) {
        yield put(push(`/${payload.eventSlug}/candidate/jobdating/jobs`));
      } else {
        yield put(push(`/${payload.eventSlug}/candidate/preparation/onboarding`));
      }
    }

    if (typeof payload.callback === 'function') {
      payload.callback();
    }
  } catch (err) {
    yield put(notificationActions.sendNotification({
      message: 'Erreur lors de la tentative de création de compte',
      kind: 'error',
      style: {},
    }));
    yield put(actions.authLoginError(err));
  }
}

/**
 * Signup Provider
 */
function* signupProvider({ payload }) {
  const requestUrl = `${process.env.FRONT_API_URL}/users/oauth/${payload.provider}`;
  const { analytics } = window;

  try {
    const result = yield call(request, requestUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    // Save the user & the token
    yield put(actions.authLoginSuccess(result));

    if (result.user) {
      const { user } = result;
      analytics.reset();
      analytics.alias(user._id);
      analytics.identify(user._id, result.user);
      const trackSignupProvider = user.isActive ? trackSignin : trackRegistration;
      trackSignupProvider({
        user,
        forum: payload._event ? payload._event.name : '',
        method: payload.provider,
      });
    }

    /**
     * No need to create a participant here.
     * Participant is created when user is redirected to onboarding process.
     */

    if (typeof payload.callback === 'function') {
      payload.callback();
    }
  } catch (err) {
    trackError(err);
    yield put(notificationActions.sendNotification({
      message: payload.notificationParams.error.message,
      kind: 'error',
      style: {},
    }));
    yield put(actions.authLoginError(err));
  }
}

/**
 * Login with provider
 */
function* loginProvider({ payload }) {
  const requestUrl = `${process.env.FRONT_API_URL}/users/oauth/login`;
  const { analytics } = window;

  try {
    const result = yield call(request, requestUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    // Save the user & the token
    yield put(actions.authLoginSuccess(result));

    if (result.user) {
      const { user } = result;
      analytics.reset();
      analytics.alias(user._id);
      analytics.identify(user._id, user);
      trackSignin({
        user,
        forum: payload._event ? payload._event.name : '',
        method: payload.provider,
      });
    }

    const { redirect } = queryStringToObject(window.location.search);

    if (redirect) {
      yield put(push(redirect))
    } else if (payload.eventSlug && !result.user._currentOrganization) {
      if (payload.event && payload.event.dateStatus === EVENT_STATUS.PREREGISTRATION) {
        yield put(push(`/${payload.eventSlug}/candidate/preparation/participation`));
      } else if (payload.event && (payload.event.dateStatus === EVENT_STATUS.CLOSED || moment() > moment(payload.event.keyDates.discovering.endAt))) {
        yield put(push(`/${payload.eventSlug}`));
      } else {
        const userProvider = JSON.parse(window.localStorage.getItem('user_provider'));

        yield put(postParticipant({
          eventId: payload.eventId,
          slug: payload.eventSlug,
          toPost: {
            _event: payload.eventId,
            userProvider,
          },
          redirect: 'event',
        }));
      }
    } else if (isUndefined(payload.redirect)) {
      yield put(push('/events'));
    } else if (result.user._currentOrganization && payload.eventSlug) {
      yield put(push(`/${payload.eventSlug}`));
    } else {
      yield put(push(payload.redirect));
    }

    if (typeof payload.callback === 'function') {
      payload.callback();
    }
  } catch (err) {
    yield put(notificationActions.sendNotification({
      message: payload.notificationParams.error.message,
      kind: 'error',
      style: {},
    }));

    yield put(actions.authLoginError(err));
  }
}

/**
 * Login
 */
function* login({ payload }) {
  const requestUrl = `${process.env.FRONT_API_URL}/users/login`;
  const { analytics } = window;

  try {
    const result = yield call((url, options, ignoreCodeError = []) => request(url, options, ignoreCodeError, true), requestUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    const { redirect } = queryStringToObject(window.location.search);

    if (result && result.user && !result.user.isActive && !isEmpty(result.activationUrl)) {
      window.location.replace(result.activationUrl);
    } else {
      yield put(actions.authLoginSuccess(result));

      if (result) {
        const { user } = result;

        if (analytics) {
          analytics.reset();
          analytics.alias(user._id);
          analytics.identify(user._id, user);
        }

        trackSignin({
          user,
          event: payload._event,
          forum: payload._event ? payload._event.name : '',
          method: 'Manual',
        });
      }

      if (redirect) {
        yield put(push(redirect))
      } else if (!payload.isInviteRecruiter) {
        if (payload.eventSlug && !result.user._currentOrganization) {
          if (payload.event && payload.event.dateStatus === EVENT_STATUS.PREREGISTRATION) {
            yield put(push(`/${payload.eventSlug}/candidate/preparation/participation`));
          } else if (payload.event && (payload.event.dateStatus === EVENT_STATUS.CLOSED || moment() > moment(payload.event.keyDates.discovering.endAt))) {
            yield put(push(`/${payload.eventSlug}`));
          } else {
            const userProvider = JSON.parse(window.localStorage.getItem('user_provider'));

            yield put(postParticipant({
              eventId: payload.eventId,
              slug: payload.eventSlug,
              toPost: {
                _event: payload.eventId,
                userProvider,
              },
              redirect: 'event',
            }));
          }
        } else if (isUndefined(payload.redirect)) {
          yield put(push('/events'));
        } else if (result?.user?._currentOrganization && payload.eventSlug) {
          yield put(push(`/${payload.eventSlug}`));
        } else {
          yield put(push(payload.redirect));
        }
      } else {
        yield put(actions.authResetPasswordStatus('success_password'));
      }
    }
    if (typeof payload.callback === 'function') {
      payload.callback(result);
    }
  } catch (err) {

    if (typeof payload.callback === 'function' && !err?.message) {
      err.then(e => payload.callback(e));
    }

    yield put(notificationActions.sendNotification({
      message: payload.notificationParams?.error.message,
      kind: 'error',
      style: {},
    }));

    yield put(actions.authLoginError(err));
  }
}

/**
 * Get users in an organization
 *
 * @param {string} organizationId
 */
function* checkResetToken({ payload: resetToken }) {
  const requestUrl = `${process.env.FRONT_API_URL}/users/checkResetToken/${resetToken}`;

  try {
    const result = yield call(request, requestUrl, {
      method: 'GET',
    });

    if (!result || !result._id) {
      yield put(push('/'));
    } else {
      yield put(actions.authCheckResetTokenSuccess({ user: result }));
    }
  } catch (e) {
    trackError(e);
  }
}

/**
 * Get users in an organization
 *
 * @param {string} organizationId
 */
function* checkInvitation({ payload: token }) {
  const requestUrl = `${process.env.FRONT_API_URL}/users/checkInvitation`;

  try {
    const tokenData = Buffer.from(token, 'base64').toString().split(':');

    if (!isEmpty(tokenData)) {
      const result = yield call(request, requestUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: tokenData[0], username: tokenData[1] }),
      });

      if (!result || !result._id) {
        yield put(push('/'));
      } else {
        yield put(actions.authCheckResetTokenSuccess({ user: result }));
      }
    }
  } catch (e) {
    trackError(e);
  }
}

function* autoLogin({ payload: { redirection, resetToken, notificationParams, authToken } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/users/autologin?jwt=${authToken}`;

  try {
    const result = yield call(request, requestUrl, {
      method: 'POST',
    });
    console.log(redirection);
    // Save the user & the token
    yield put(actions.authLoginSuccess(result));

    if (resetToken) {
      yield put(actions.changeEmail({
        resetToken,
        notificationParams,
      }));
    } else if (redirection) {
      window.location.href = redirection;
    }
  } catch (err) {
    trackError(err);

    yield put(actions.authLoginError(err));
  }
}

function* autoLoginFromV1({ payload: { token } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/users/autologinfromv1`;

  try {
    const result = yield call(request, requestUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token }),
    });

    // Save the user & the token
    yield put(actions.authLoginSuccess(result));
  } catch (err) {
    trackError(err);

    yield put(actions.authLoginError(err));
  }
}

function* resetPassword({ payload, payload: { callback } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/users/resetpassword`;

  try {
    yield call(request, requestUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (typeof callback === 'function') {
      callback();
    }

    yield put(actions.authResetPasswordStatus('success_token'));
  } catch (err) {
    yield put(actions.authResetPasswordStatus('error_token'));
  }
}

function* changePassword({ payload, payload: { callback } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/users/changepassword`;

  try {
    const result = yield call(request, requestUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    // Save the user & the token
    yield put(actions.authLoginSuccess(result));

    // Reset reset process status
    yield put(actions.authResetPasswordStatus(null));

    // Put success for confirm modal
    yield put(actions.authResetPasswordStatus('success_password'));
    if (typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    yield put(actions.authResetPasswordStatus('error_password'));
  }
}

function* changeEmail({ payload: { resetToken, notificationParams } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/users/changeemail`;
  const authUser = yield select(getAuthUser);

  try {
    const result = yield call(request, requestUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ resetToken, usernameTemp: authUser.get('usernameTemp') }),
    });

    // Save the user & the token
    yield put(actions.authLoginSuccess(result));

    // Update authUser
    if (authUser.get('_id') === result.user._id) {
      yield put(actions.authRefreshUser({ user: result.user }));
    }

    yield put(push('/'));
    window.location.reload();

    if (notificationParams && notificationParams.success) {
      yield put(notificationActions.sendNotification({
        message: notificationParams.success.message,
        kind: notificationParams.success.kind,
        style: notificationParams.success.style,
      }));
    }
  } catch (err) {
    trackError(err);
  }
}

/**
 * Switch auth
 */
function* switchAuth({ payload: { userId, email, notificationParams, noRedirect } }) {
  const authUser = yield select(getAuthUser);

  const requestUrl = `${process.env.FRONT_API_URL}/users/switch?email=${encodeURI(email)}&userId=${userId}`;

  try {
    const result = yield call(request, requestUrl, {
      headers: {
        KoaUpdateCache: `/users/${authUser.get('_id')}}`,
      },
    });

    // Save the user & the token
    yield put(actions.switchAuthSuccess(result));

    // Push notification
    if (notificationParams && notificationParams.success) {
      yield put(notificationActions.sendNotification({
        message: notificationParams.success.message,
        kind: notificationParams.success.kind,
        style: notificationParams.success.style,
      }));
    }

    if (!noRedirect) {
      // Redirect to the scene with the list of all events
      if (authUser.get('username').indexOf('demo.seekube.com') > -1) {
        yield put(push('/demo'));
      } else {
        yield put(push('/events'));
      }

      window.location.reload();
    }
  } catch (err) {
    trackError(err);

    if (notificationParams.error &&
      notificationParams.error.message) {
      yield put(notificationActions.sendNotification({
        message: notificationParams.error.message,
        kind: 'error',
      }));
    }
  }
}

function* logout({ payload: { callback } }) {
  const { mixpanel } = window;
  const analytics = window.analytics || null;
  trackSignout();

  if (!isEmpty(mixpanel) && !isEmpty(mixpanel.cookie) && !isUndefined(mixpanel.cookie)) {
    mixpanel.cookie.clear();
  }

  if (!isEmpty(analytics)) {
    analytics.reset();
  }

  yield put(actions.authLogoutUser());
  yield put(resetParticipant());

  if (typeof callback === 'function') {
    callback();
  }
}

/**
 * Autologin on V1
 */
function* autoLoginV1({ payload: { email, oldId } }) {
  const token = btoa(`v2;${email};${oldId}`);

  window.location.href = `https://www.seekube.com/?token=${token}`;
}

/**
 * Listen Actions
 */
export default [
  takeLatest(types.AUTH_LOGIN, login),
  takeLatest(types.AUTH_REFRESH_USER, refreshAuth),
  takeLatest(types.SWITCH_AUTH, switchAuth),
  takeLatest(types.AUTO_LOGIN, autoLogin),
  takeLatest(types.AUTH_SIGNUP, signup),
  takeLatest(types.AUTH_SIGNUP_PROVIDER, signupProvider),
  takeLatest(types.AUTH_SSO, sso),
  takeLatest(types.AUTH_LOGIN_OAUTH, loginProvider),
  takeLatest(types.AUTH_RESET_PASSWORD, resetPassword),
  takeLatest(types.AUTH_CHANGE_PASSWORD, changePassword),
  takeLatest(types.AUTH_LOGOUT, logout),
  takeLatest(types.AUTH_CHECK_RESET_TOKEN, checkResetToken),
  takeLatest(types.AUTH_CHECK_INVITATION, checkInvitation),
  takeLatest(types.AUTH_CHANGE_EMAIL, changeEmail),
  takeLatest(types.AUTO_LOGIN_V1, autoLoginV1),
  takeLatest(types.AUTO_LOGIN_FROM_V1, autoLoginFromV1),
];

