import React from 'react';
import { compose } from 'redux';
import { array, func, shape, any, object, string, bool, number } from 'prop-types';
import moment from 'moment';
import { isUndefined, isEmpty, isEqual, upperFirst } from 'lodash';
import { injectIntl } from 'react-intl';
import { Form } from 'antd/lib/index';
import { Button } from '@seekube-tech/ui-kit';
import { ANALYTICS_CANDIDATE } from '@/utils/constants';
import { track } from '@/utils/analytics';
import { toJS } from '@/utils/index';
import Separator from '@/components/Separator';
import { H3 } from '@/components/Title';
import EducationLevelForm from './EducationLevelForm';
import EducationForm from './EducationForm';
import messages from './messages';
import './styles.less';
import FormFooter from '../FormFooter';
import { trackGtmEvent } from "@/utils/pixels/helloWork";

const hasErrors = (fieldsError) => Object.keys(fieldsError).some((field) => fieldsError[field]);

class UserFormation extends React.PureComponent {
  static propTypes = {
    form: shape({
      getFieldDecorator: func,
      getFieldsError: func,
      getFieldError: func,
      isFieldTouched: func,
    }),
    currentStep: any,
    handleDataUpdate: func,
    authUser: object,
    user: object,
    event: object,
    defaultCriteria: array,
    intl: object,
    context: string,
    countEnabledSteps: number,
    eventSteps: object,
  };

  static defaultProps = {
    context: 'event',
  };

  constructor(props) {
    super(props);

    this.state = {
      formationsCount: 1,
      formations: [{
        schoolName: '',
        programName: '',
        startMonth: '',
        startYear: '',
        beginAt: '',
        endAt: '',
        endMonth: '',
        endYear: '',
        currentFormation: false,
      }],
      beginYears: [],
      endYears: [],
      months: Array.apply(0, Array(12))
        .map((_, i) => (
          upperFirst(moment().locale(props.user ? (props.user.locale || 'fr') : 'fr').month(i).format('MMMM')))
        ),
      formIsModify: [false, false],
      formIsEnabled: false,
    };
  }

  componentDidMount() {
    const { authUser, defaultCriteria, user } = this.props;
    this.setEducation();

    this.setBeginYears();
    this.setEndYears();


    if (!isEmpty(authUser) && !isEqual(authUser, user)) {
      this.setEducation();
    }

    if (!isEmpty(defaultCriteria) && isEmpty(this.state.educationLevels)) {
      this.setEducationLevels(defaultCriteria);
    }
  }

  setBeginYears = () => {
    const currentYear = new Date().getFullYear();
    const yearList = [];

    for (let i = currentYear; i >= currentYear - 45; i -= 1) {
      yearList.push(i);
    }

    this.setState({ beginYears: yearList });
  };

  setEndYears = () => {
    const currentYear = new Date().getFullYear() + 15;
    const yearList = [];

    for (let i = currentYear; i >= currentYear - 60; i -= 1) {
      yearList.push(i);
    }

    this.setState({ endYears: yearList });
  };

  setEducation = () => {
    const { props: { user }, state: { months } } = this;

    const educations = !isEmpty(user.educations) ? user.educations.filter((item) => item._organization && item._organization.name) : [];

    if (educations.length > 0) {
      const newEducations = educations.map((position) => ({
        schoolName: position._organization.name,
        programName: position.title,
        beginAt: position.beginAt,
        endAt: !isUndefined(position.endAt) ? position.endAt : null,
        startMonth: months[new Date(position.beginAt).getMonth()],
        startYear: new Date(position.beginAt).getFullYear(),
        endMonth: !isUndefined(position.endAt) && months[new Date(position.endAt).getMonth()],
        endYear: !isUndefined(position.endAt) && new Date(position.endAt).getFullYear(),
      }));

      this.setState({
        formations: newEducations,
        formationsCount: newEducations.length,
      });
    } else {
      this.setState({
        formations: [{
          schoolName: '',
          programName: '',
          startMonth: '',
          startYear: '',
          endMonth: '',
          endYear: '',
          beginAt: null,
          endAt: null,
        }],
      });
    }
  };

  setEducationLevels = (defaultCriteria) => {
    if (defaultCriteria) {
      const educationLevels = defaultCriteria.find((c) => c.key === 'LEVEL');
      if (educationLevels) this.setState({ educationLevels: educationLevels._choices });
    }
  };

  setFormIsModify = (order, itemIsModify) => {
    const { props: { form }, state: { formIsModify } } = this;

    formIsModify[order] = itemIsModify;
    this.setState({
      formIsModify,
      formIsEnabled: (!formIsModify[0] && !formIsModify[1]) || hasErrors(form.getFieldsError()),
    });
  };

  handleOnAddFormation = (e, formationForm) => {
    const { formationsCount, formations } = this.state;

    if (formations.length < 2) {
      const newFormations = [...formations, formationForm];

      this.setState({
        FormationCount: formationsCount + 1,
        formations: newFormations,
      });
    }
  }

  handleOnAddFormation = (e, formationForm) => {
    const { formationsCount, formations } = this.state;

    if (formations.length < 2) {
      const newFormations = [...formations, formationForm];

      this.setState({
        formationsCount: formationsCount + 1,
        formations: newFormations,
      });
    }
  };

  handleOnDeleteFormation = (order) => {
    const { formations } = this.state;
    const newFormations = formations;
    newFormations.splice(order, 1);

    this.setState({
      formationsCount: 1,
      formations: newFormations,
    });
  };

  getPayloadDTO = (values) => {
    const educationsDto = [];

    for (let i = 0; i < this.state.formationsCount; i += 1) {
      const formation = {
        school: values[`schoolName${i}`],
        title: values[`programName${i}`],
        beginAt: moment(`${values[`startYear${i}`]}-03-03`).toDate(),
        endAt: moment(`${values[`endYear${i}`]}-03-03`).toDate()
      };
      educationsDto.push(formation);
    }

    return educationsDto
  }

  handleOnSubmit = (e) => {
    e.preventDefault();
    const {
      props: { handleDataUpdate, user, event, form: { validateFields, getFieldsValue } },
    } = this;

    const values = getFieldsValue();

    return validateFields((err) => {
      if (err) {
        return false;
      }

      const formations = [];

      for (let i = 0; i < this.state.formationsCount; i += 1) {
        const formation = {
          schoolName: values[`schoolName${i}`],
          programName: values[`programName${i}`],
          startMonth: values[`startMonth${i}`],
          startYear: values[`startYear${i}`],
          endMonth: values[`endMonth${i}`],
          endYear: values[`endYear${i}`],
        };

        formation.beginAt = moment(`${formation.startYear}-03-03`).toDate();
        formation.endAt = moment(`${formation.endYear}-03-03`).toDate();

        formations.push(formation);
      }

      this.setState({
        formations,
      }, () => {
        track({
          name: ANALYTICS_CANDIDATE.TOUR_STEP3_SUBMIT,
          user,
          event,
          properties: {
            'Count Formation': formations.length,
          },
        });

        if (event) {
          const toursStep = event.participants?.onboarding?.filter((step) => step.name === 'cv' && step.enabled === false) ? 3 : 2;

          trackGtmEvent('onboarding_experience_complete', {
            'page_category': 'inscription',
            'page_subject': 'onboarding',
            'user_id_back': user._id,
            'flow_name': 'inscription_jobdating',
            'flow_step': toursStep,
            'flow_step_total': event.countOnboardingSteps,
            'flow_modules_list': event.onboardingSteps,
            'flow_step_name': 'experience',
            'jobdating_name': event.slug,
          });
        }

        handleDataUpdate({ educations: this.getPayloadDTO(values), lastEducationsUpdate: new Date() }, 3);
      });

      return values;
    });
  };

  render() {
    const { handleOnAddFormation, handleOnDeleteFormation, handleOnSubmit, setFormIsModify,
      state: { formationsCount, formations, beginYears, endYears, months, formIsEnabled },
      props: { form, user, intl, context, defaultCriteria, currentStep, eventSteps, countEnabledSteps },
    } = this;

    const settingFormationTitle = (
      <H3 bold className="educationTitle" id="userEducations">
        <span className="desktop">{intl.formatMessage(messages.formationTitle)}&nbsp;</span>
        <span className="maxField">{`(2 ${intl.formatMessage(messages.max)})`}</span>
        <span role="img" aria-label="Nerd Face"> 🤓</span>
      </H3>
    );

    return (
      <div className="commonStep">
        <Form className="form" onSubmit={handleOnSubmit}>
          <div>
            {context === 'event' ? (
              <div className="educationLevel">
                <H3 bold>
                  <span>{intl.formatMessage(messages.educationLevel)}</span>
                  <span role="img" aria-label="Hands up"> 🙌</span>
                </H3>
                <EducationLevelForm form={form} matching={user.matching || { filters: [] }} defaultCriteria={defaultCriteria} />
              </div>
            ) : ''}

            {context === 'event' ? (
              <H3 bold className="educationTitle">
                <span className="desktop">{isEmpty(user) || !user.educations ? intl.formatMessage(messages.formationTitle) : intl.formatMessage(messages.formationTitleVerify)}&nbsp;</span>
                <span className="mobile">{intl.formatMessage(messages.formationTitleMobile)}&nbsp;</span>
                <span className="maxField">{`(2 ${intl.formatMessage(messages.max)})`}</span>
                <span role="img" aria-label="Nerd Face"> 🤓</span>
              </H3>
            ) : settingFormationTitle}

            {formations.map((formation, i) => (
              <EducationForm
                form={form}
                key={i}
                order={i}
                formation={formation}
                handleAddFormation={handleOnAddFormation}
                handleDeleteFormation={handleOnDeleteFormation}
                formationsCount={formationsCount}
                beginYears={beginYears}
                endYears={endYears}
                months={months}
                context={context}
                setFormIsModify={setFormIsModify}
                user={user}
              />
            ))}
            {context === 'settings' && (
              <Button
                className="changeStep settings"
                disabled={formIsEnabled}
                type="submit"
                onClick={handleOnSubmit}
              >
                {intl.formatMessage(messages.save)}
              </Button>
            )}
          </div>
        </Form>
        <Separator className="hidden-mobile" height={20} />

        {context !== 'settings' ? (
          <FormFooter
            form={form}
            currentStep={currentStep}
            eventSteps={eventSteps}
            countEnabledSteps={countEnabledSteps}
            onSubmit={this.handleOnSubmit}
          />
        ) : null}
      </div>
    );
  }
}

const withForm = Form.create();

export default compose(
  injectIntl,
  withForm,
)(toJS(UserFormation));
