import React from 'react';
import { Field } from 'react-final-form';
import classnames from 'classnames';
import { XCircle, CheckCircle } from 'react-feather';
import Icon from '@/components/Icon';
import styles from './styles.less';

function FieldsMatching({ fields, handleChangefield }) {
  return fields.map((field, index) => {
    const item = fields.value[index];

    const handleValidate = (value) => {
      return value === null ? 'error' : '';
    }

    const onValueChange = (input, value) => {
      input.onChange(value);
      handleChangefield(index, 'isValid', value);
    }

    return (
      <Field
        name={`${field}.isValid`}
        validate={handleValidate}
        render={({ input, meta }) => (
          <div key={`matchingItem${index}`} className={styles.matchingItem}>
            <div className={styles.infos}>
              <div className={styles.icon}>
                <Icon name={item.icon} />
              </div>

              <div className={styles.label}>
                <span>{item.label} - </span> {item.choices.join(' , ')} <span className={styles.asterisk}>*</span>
              </div>
            </div>

            <div className={styles.actions}>
              <button type="button" onClick={() => onValueChange(input, true)}>
                <CheckCircle
                  className={classnames(
                    styles.matchingIcon,
                    {
                      [styles.checked]: input.value === true,
                      [styles.error]: Boolean(meta.error && meta.submitFailed),
                    },
                  )}
                />
              </button>

              <button type="button" onClick={() => onValueChange(input, false)}>
                <XCircle
                  className={classnames(
                    styles.matchingIcon,
                    {
                      [styles.checked]: input.value === false,
                      [styles.error]: Boolean(meta.error && meta.submitFailed),
                    },
                  )}
                />
              </button>
            </div>
          </div>
        )}
      />
    );
  });
}

export default FieldsMatching;
