import { trackGtmEvent } from '@/utils/pixels/helloWork';

function trackOnboardingStart (authUser, event) {
  trackGtmEvent('onboarding_start', {
    'page_category': 'event_onboarding',
    'page_subject': 'signup',
    'event' : 'generic',
    'path': location.pathname,
    'flow_name' : 'inscription_jobdating',
    'user_id_back' : authUser._id,
    'flow_step' : 0,
    'flow_step_total': event.onboardingSteps.length,
    'flow_modules_list': event.onboardingSteps,
    'flow_step_name': event.onboardingSteps[0],
    'jobdating_name': event.slug,
  });
}

function trackOnboardingCvLoad(authUser, event) {
  trackGtmEvent('onboarding_cv_load', {
    'page_category': 'event_onboarding',
    'page_subject': 'signup',
    'event' : 'generic',
    'user_id_back' : authUser._id,
    'flow_step' : 1,
    'flow_step_total': event.onboardingSteps.length,
    'flow_modules_list': event.onboardingSteps,
    'flow_step_name': event.onboardingSteps[1],
    'flow_name' : 'inscription_jobdating',
    'jobdating_name': event.slug,
  });
}

function trackOnboardingCvComplete(authUser, event) {
  trackGtmEvent('onboarding_cv_complete', {
    'page_category': 'event_onboarding',
    'page_subject': 'signup',
    'event' : 'generic',
    'user_id_back' : authUser._id,
    'flow_step' : 1,
    'flow_step_total': event.onboardingSteps.length,
    'flow_modules_list': event.onboardingSteps,
    'flow_step_name': event.onboardingSteps[1],
    'flow_name' : 'inscription_jobdating',
    'jobdating_name': event.slug,
  });
}

function trackOnboardingSearchLoad(authUser, event) {
  trackGtmEvent('onboarding_search_load', {
    'user_id_back': authUser._id,
    'flow_step': 3,
    'flow_step_total': event.onboardingSteps.length,
    'flow_modules_list': event.onboardingSteps,
    'flow_step_name': event.onboardingSteps[3],
    'flow_name': 'inscription_jobdating',
    'jobdating_name': event.slug,
  });
}

function trackOnboardingSearchComplete(authUser, event) {
  trackGtmEvent('onboarding_search_complete', {
    'user_id_back': authUser._id,
    'flow_step': 3,
    'flow_step_total': event.onboardingSteps.length,
    'flow_modules_list': event.onboardingSteps,
    'flow_step_name': event.onboardingSteps[3],
    'flow_name': 'inscription_jobdating',
    'jobdating_name': event.slug,
  });
}

function trackOnboardingEnd(authUser, event) {
  trackGtmEvent('onboarding_end', {
    'event': 'generic',
    'user_id_back': authUser._id,
    'flow_name': 'inscription_jobdating',
    'flow_step': 4,
    'flow_step_total': event.onboardingSteps.length,
    'flow_modules_list': event.onboardingSteps,
    'flow_step_name': event.onboardingSteps[4],
    'jobdating_name': event.slug,
  });
}

export {
  trackOnboardingStart,
  trackOnboardingCvLoad,
  trackOnboardingCvComplete,
  trackOnboardingSearchLoad,
  trackOnboardingSearchComplete,
  trackOnboardingEnd,
}
