import React from 'react';
import { Body2, Button, IconLink, IconTrash2 } from '@seekube-tech/ui-kit';
import { isEmpty } from 'lodash';
import { useFormatMessage } from 'react-intl-hooks';
import { Form } from 'antd';
import classnames from 'classnames';
import { func, array, object } from 'prop-types';
import { useIsMobile } from '@/utils/MediaQueries';
import InputMaterial from '@/components/Input/InputMaterial';
import { urlValidator } from '@/utils/text';
const FormItem = Form.Item;
import styles from './styles.less';

export function Links({ form, links, handleRemove }) {
  const t = useFormatMessage();
  const isWiderThanMobile = !useIsMobile();
  const hasLink = !isEmpty(links);

  return (
    <>
      {hasLink &&
        <Body2 className="mb-24 text-neutral-400">
          {t({ id: 'form.profile.externalLink.label' })}
        </Body2>}

      {links?.map((link, order) => (
        <div key={order} className={styles.linkContainer}>
          <FormItem className={classnames('flex-1', { 'mr-24': isWiderThanMobile })}>
            {form.getFieldDecorator(`links[${order}].url`, {
              initialValue: link.url || '',
              rules: [
                { validator: urlValidator },
                {
                  required: false,
                  message: t({ id: 'event.recruiter.preparation.signup.identity.form.firstName.error' }),
                }],
            })(
              <InputMaterial
                addOnIcon={<IconLink size={24} />}
                label={t({ id: 'external.link' })}
                placeholder={t({ id: 'external.link' })}
                type="text"
              />,
            )}
          </FormItem>
          <div className={classnames('mb-24 self-end', { 'self-center': isWiderThanMobile })}>
            <Button
              color="error"
              variant="tonal"
              imageComponentLeft={<IconTrash2 size={16} />}
              onClick={() => handleRemove(order)}
            >
              {t({ id: 'delete' })}
            </Button>
          </div>
        </div >
      ))
      }
    </>
  )
}

Links.propTypes = {
  form: object,
  links: array,
  handleRemove: func,
}
