import React from 'react';
import { string, object, bool } from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';

// Components
import Icon from '@/components/Icon';

// Styles
import styles from './styles.less';

/**
 * Time
 * @param {object} props
 * @param {string} props.date
 * @param {string} props.time
 * @param {bool} props.showTime
 * @param {object} props.now
 */
const Time = ({ date, time, showTime, now }) => {
  const notPastTime = Number(time) >= now.hour();
  const notPastDay = moment(date) > moment(now.format('YYYY-MM-DD'));
  const sameDay = moment(date) === moment(now.format('YYYY-MM-DD'));

  const timeClass = classnames(
    styles.timeElement,
    (notPastDay || (sameDay && notPastTime)) ? styles.sameTime : null,
    !showTime ? styles.hideTime : null,
  );

  return (
    <div className={timeClass}>
      <span>{time}h</span>
      <Icon name="clock" />
    </div>
  );
};

Time.propTypes = {
  date: string,
  time: string,
  showTime: bool,
  now: object,
};

export default Time;
