import React, { cloneElement, useEffect, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { any, bool, func, number, string } from 'prop-types';

const DraggableItem = ({
  index,
  moveItem,
  children,
  itemType,
  onDragEnd,
  onDragStart,
  onOver,
  disabled
}) => {
  const ref = useRef(null)

  const [{ handlerId, isOver }, drop] = useDrop({
    accept: itemType,
    hover(item, monitor) {
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      onDragStart?.(dragIndex);
      moveItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ draggingIndex }, drag] = useDrag({
    type: itemType,
    item: () => ({ index }),
    end: () => {
      onDragEnd()
    },
    canDrag: () => !disabled
  });

  useEffect(() => {
    if (isOver) {
      onOver?.(index, draggingIndex);
    }
  }, [isOver]);

  drag(drop(ref));

  return cloneElement(children, { ref: ref, 'data-handler-id': handlerId });
}

DraggableItem.propTypes = {
  index: number,
  moveItem: func,
  children: any,
  itemType: string,
  disabled: bool
};

export default DraggableItem;
