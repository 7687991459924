import React, { useEffect, useState } from 'react'
import { isEmpty } from 'lodash';
import { useFormatMessage } from 'react-intl-hooks';
import { injectIntl } from 'react-intl'
import { Link } from 'react-router-dom';
import { bool, func, object, oneOf, string } from 'prop-types';
import classnames from 'classnames';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect';
import { Button } from '@seekube-tech/ui-kit';
import { track } from '@/utils/analytics';
import { ANALYTICS_CANDIDATE, EVENT_STATUS, LOCALES } from '@/utils/constants';
import PreregistrationModal
  from '@/scenes/Event/scenes/Candidate/scenes/Preparation/scenes/Participation/components/PreregistrationModal'
import { participantActions } from '@/store/participant'
import { getAvailableActions } from '@/store/availableActions/selectors';
import { ACTIONS } from '@/store/availableActions/event/eventsListCta';
import { eventSelectors } from '@/store/event';
import styles from './styles.less';
import { trackGtmEvent } from "@/utils/pixels/helloWork";
import { getEventPhases, isEventInPreregistration } from '@/store/availableActions/utils/event';

export const EVENT_ACCESS_CTA_CONTEXT = {
  EVENTS_LIST: 'EVENTS_LIST',
  LANDING: 'LANDING',
};

export const EVENT_ACCESS_CTA_COMPONENT_TYPE = {
  BUTTON: 'BUTTON',
  LINK: 'LINK',
};

export const EVENT_ACCESS_CTA_ROLE = {
  candidate: 'candidate',
  owner: 'owner',
  recruiter: 'recruiter',
};

export const AVAILABLE_ACTION = {
  EVENT_LANDING_CTA: 'eventLandingCta',
  EVENTS_LIST_CTA: 'eventsListCta'
}

function trackOnParticipate(authUser, context, event, postParticipant, role) {
  const eventPhases = getEventPhases(event);
  const { _id, isAuthUserParticipate, slug, isAuthUserPreregistered } = event;
  const isLandingContext = context === EVENT_ACCESS_CTA_CONTEXT.LANDING;

  if (role === 'candidate') {
    if ((isLandingContext || isAuthUserPreregistered) && !isAuthUserParticipate && !isEventInPreregistration(eventPhases)) {
      track({
        name: ANALYTICS_CANDIDATE.PARTICIPATION_CLICK,
        properties: {
          forum: event.slug,
          isLogging: !!authUser,
        },
      });

      trackGtmEvent('registration', {
        event: event.slug,
        page_category: window.location.href.indexOf(event.slug) > -1 ? 'landing_page' : 'profile',
        page_subject: window.location.href.indexOf(event.slug) > -1 ? 'inscription' : 'home_page',
        jobdating_name: event.slug,
        event_info: 'CTA',
      });

      if (authUser) {
        const userProvider = JSON.parse(window.localStorage.getItem('user_provider'));
        postParticipant({
          eventId: _id,
          slug,
          toPost: {
            _event: _id,
            userProvider,
          },
        });
      }
    }
  }
}

const EventAccessCta = ({
  authUser,
  context,
  ctaComponentType,
  editMode,
  event,
  postParticipant,
  postPreregisteredParticipant,
  role,
  availableActions,
  isAuthUserBanned,
  customClass,
  linkCustomClass,
  utmCampaign
}) => {
  const [preregistrationModalIsVisible, setPreregistrationModalIsVisible] = useState(false)
  const [preregistered, setPreregistered] = useState(false)
  const t = useFormatMessage();
  const [cta, setCta] = useState({});

  if (!context || !event || !ctaComponentType || isAuthUserBanned) {
    return null;
  }

  const isLandingContext = context === EVENT_ACCESS_CTA_CONTEXT.LANDING;
  const btnClass = isLandingContext
    ? classnames(styles.wantParticipeBtn, customClass, !isEmpty(event.theme.mainColor) && event.theme.mainColor !== '#2980b9' ? styles.btnCustom : null)
    : null;

  const isCtaButton = ctaComponentType === EVENT_ACCESS_CTA_COMPONENT_TYPE.BUTTON;

  const action = isLandingContext ? AVAILABLE_ACTION.EVENT_LANDING_CTA : AVAILABLE_ACTION.EVENTS_LIST_CTA;

  if (!availableActions[action]) {
    return null;
  }

  /**
   * We have to override the isAuthUserPreregistered & isAuthUserBanned properties in the event because
   * we do not re-fetch the event after pre-registering / banning the user
   */
  const localEvent = {
    ...event,
    isAuthUserPreregistered: event.isAuthUserPreregistered || preregistered,
    isAuthUserBanned
  }

  useEffect(() => {

    const utm = utmCampaign ? { utm: { source: 'seekube', campaign: utmCampaign  } } : {};

    setCta({
      "slug": event.slug,
      "isHidden": availableActions[action].isHidden({ event: localEvent, role }),
      "isDisabled": availableActions[action].isDisabled({ event: localEvent, role, editMode, context }),
      "label": availableActions[action].getLabel({ event: localEvent, role, t, editMode }),
      "action": availableActions[action].getAction({ event: localEvent, role }),
      "location": availableActions[action].getLinkHref({ event: localEvent, role, editMode, ...utm }),
      "target": availableActions[action].getLinkTarget({ event: localEvent, role }),
      "style": availableActions[action].getStyle({ event: localEvent, role, editMode })
    })
  }, [event])

  if (cta.isHidden && isCtaButton) {
    return null;
  }

  const disableLink = cta.isDisabled || cta.action || preregistered;
  const getAction = () => {
    if (cta.isDisabled || preregistered) {
      return () => { }
    }

    switch (cta.action) {
      case ACTIONS.PRE_REGISTRATION:
        return () => {
          setPreregistrationModalIsVisible(true);

          trackGtmEvent('pre_registration', {
            event: event.slug,
            page_category: window.location.href.indexOf(event.slug) > -1 ? 'landing_page' : 'profile',
            page_subject: window.location.href.indexOf(event.slug) > -1 ? 'inscription' : 'home_page',
            jobdating_name: event.slug,
            event_info: 'CTA',
          });
        }
      default:
        return () => { }
    }
  }

  const closePreRegistrationModal = () => setPreregistrationModalIsVisible(false)

  const preRegisterParticipant = (err, values) => {
    postPreregisteredParticipant({ eventId: event.slug, toPost: values })
    setPreregistered(true);
  }

  if (cta.action || disableLink) {
    return <>
      {(cta && isCtaButton) ? (
        <Button disabled={cta.isDisabled || preregistered} onClick={getAction()} className={linkCustomClass}>
          {cta.label}
        </Button>
      ) : <h2 className={styles.titleCta}>{event.name}</h2>}

      {preregistrationModalIsVisible &&
        <PreregistrationModal
          visible={preregistrationModalIsVisible}
          handleClose={closePreRegistrationModal}
          event={event}
          authUser={authUser}
          email={authUser?.username}
          onSubmit={preRegisterParticipant}
        />}
    </>
  }

  return (
    <Link
      role="button"
      target={disableLink ? "_self" : cta.target}
      tabIndex={0}
      to={cta.location && !disableLink ? cta.location : '#'}
      onClick={() => trackOnParticipate(authUser, context, event, postParticipant, role)}
      className={linkCustomClass}
    >
      {(cta && isCtaButton) ? (
        <Button disabled={cta.isDisabled || preregistered} className={btnClass}>
          {cta.label}
        </Button>
      ) : <h2 className={styles.titleCta}>{event.name}</h2>}
    </Link>)
}

const mapDispatchToProps = {
  postPreregisteredParticipant: participantActions.postPreregisteredParticipant,
  postParticipant: participantActions.postParticipant,
}

const mapStateToProps = createStructuredSelector({
  availableActions: getAvailableActions,
  isAuthUserPreregistered: eventSelectors.isAuthUserPreregistered
})

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  injectIntl,
)(EventAccessCta)

EventAccessCta.propTypes = {
  availableActions: object,
  authUser: object,
  context: oneOf([...Object.keys(EVENT_ACCESS_CTA_CONTEXT)]).isRequired,
  ctaComponentType: oneOf([...Object.keys(EVENT_ACCESS_CTA_COMPONENT_TYPE)]).isRequired,
  editMode: bool,
  event: object.isRequired,
  eventUserStats: object,
  intl: object,
  postParticipant: func,
  postPreregisteredParticipant: func,
  role: oneOf([...Object.keys(EVENT_ACCESS_CTA_ROLE)]).isRequired,
  isAuthUserPreregistered: bool,
  isAuthUserBanned: bool,
  utmCampaign: string
};
