import React from 'react';
import { object, func, array, bool, string } from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { replace } from 'connected-react-router';
import { isEmpty, isEqual, omit, debounce } from 'lodash';
import { Map } from 'immutable';
import { createStructuredSelector } from 'reselect';

// Store
import { Row, Col } from 'antd';
import { Button, IconDownload, H3, Pagination } from '@seekube-tech/ui-kit';
import { participantSelectors } from '@/store/participant';
import { authSelectors } from '@/store/auth';
import { eventSelectors } from '@/store/event';
import { sectorSelectors, sectorActions } from '@/store/sector';
import { offerActions, offerSelectors } from '@/store/offer';
import { exponentActions, exponentSelectors } from '@/store/exponent';
import { criterionActions, criterionSelectors } from '@/store/criterion';

// Components
import { queryStringToObject, objectToParams } from '@/utils/url';
import { toJS } from '@/utils/index';
import LoadingIndicator from '@/components/LoadingIndicator';
import BlankState from '@/components/BlankState';
import MobileMenu from '@/containers/Layout/containers/MobileMenu';
import PresentationCard from '@/components/ExponentProfile/components/PresentationCard';
import { withLayout } from '@/containers/Layout';
import { If } from '@/components/If';
import SearchResultsStats from '@/components/SearchResultsStats';
import SearchEngine from '../../components/SearchEngine';

// Styles
import styles from './styles.less';
import messages from './messages';
import { getLocale } from '@/utils/localStorage';
import MobileHeader from '@/containers/Layout/containers/MobileHeader';


class ExponentsScene extends React.PureComponent { // eslint-disable-line
  static propTypes = {
    context: string,
    event: object,
    match: object,
    getExponents: func,
    pagination: object,
    exponents: array,
    offers: array,
    allSectors: array,
    defaultCriteria: array,
    location: object,
    isFetching: bool,
    history: object,
    participant: object,
    getParticipantOffers: func,
    getSectors: func,
    intl: object,
    participantOffersLastSearch: object,
    participantExponentsLastSearch: object,
  };

  static getDerivedStateFromProps({ history, offers, sectors, pagination, location }, state) {
    const query = queryStringToObject(history.location.search);

    const newState = {};

    if (!isEqual(state.search, query)) {
      newState.search = query;
    }

    if (!isEmpty(offers)) {
      newState.countOffers = pagination.length;
    }

    if (pagination) {
      newState.countExponents = pagination.total;
    }

    if (!isEmpty(newState)) {
      return newState;
    }

    return null;
  }

  constructor(props) {
    super(props);

    const { location } = props;

    location.query = queryStringToObject(location.search);

    this.state = {
      countExponents: null,
      countOffers: null,
      modalOpen: false,
      defaultOfferLoaded: false,
      offer: null,
      search: {
        keywords: '',
        ...location.query,
      },
      facetLocations: null,
      facetSectors: null,
      highlightWords: [],
      currentPage: 1,
      queryParams: {
        ...location.query,
        page: 1,
      },
    };
  }

  componentDidMount() {
    const { props: { history, pagination, context, allSectors, getSectors, match, getExponents, defaultCriteria, participant, getParticipantOffers, participantOffersLastSearch, participantExponentsLastSearch } } = this;

    if (!isEmpty(participant)) {
      getParticipantOffers({
        eventId: match.params.eventSlug,
        participantId: participant._id,
        offset: -1,
        page: 0,
        search: {},
        params: !isEmpty(participantOffersLastSearch) ? { ...participantOffersLastSearch, noUpdateParticipant: true } : { noUpdateParticipant: true, matchingTmp: participant ? participant.matching : {} },
      });
    }

    if (isEmpty(allSectors)) {
      getSectors();
    }

    const paginationLimit = 20;

    if (context === 'owner') {
      const location = { ...history.location };

      location.query = queryStringToObject(location.search);
      location.query.context = context;
      const search = location.query;
      search.participantId = participant ? participant._id : null;

      const offset = location.query.page === 1 || location.query.page === undefined ? 0 : (location.query.page * pagination.pageSize) - pagination.pageSize;

      getExponents({
        eventId: match.params.eventSlug,
        offset,
        page: location.query.page,
        search,
        limit: paginationLimit,
      });
    } else {
      const search = context !== 'owner' && !isEmpty(participantExponentsLastSearch) ? participantExponentsLastSearch.search : { context };
      search.participantId = participant ? participant._id : null;
      getExponents({
        eventId: match.params.eventSlug,
        offset: 0,
        page: 1,
        search,
        limit: paginationLimit,
      });
    }
  }

  getExponents = debounce((getExp, eventId, search, offset, facetKey, location) => {
    getExp({
      eventId,
      offset,
      facetKey,
      page: location.query.page,
      search,
      limit: 20,
    });
  }, 400, { leading: true, trailing: true });

  /**
   * Handle change
   *
   * @param {number} pagination
   */
  handleOnChangePagination = (pagination) => {
    const { props: { history } } = this;
    const location = { ...history.location };

    location.query = queryStringToObject(location.search);

    this.processQueryChange({ page: pagination });

    window.scrollTo(0, 0);
  };

  /**
   * Handle change
   *
   * @param {object} newParams
   */
  processQueryChange = (newParams, facetKey) => {
    const { props: { context, getExponents, event, pagination, history, participant } } = this;
    const location = { ...history.location };

    location.query = queryStringToObject(location.search);

    const queryMap = isEmpty(location.query) ? Map(newParams) : Map(location.query);
    const newMapParams = queryMap.merge(newParams);

    location.query = newMapParams.toJS();
    location.search = objectToParams(location.query);

    this.setState({ queryParams: location.query, search: location.query });

    // Prevent algolia search when participant is open
    const offset = location.query.page === 1 || location.query.page === undefined ? 0 : (location.query.page * pagination.pageSize) - pagination.pageSize;
    const search = omit(location.query, ['page']);

    search.participantId = participant ? participant._id : null;
    search.context = context;

    history.push(location);

    this.getExponents(getExponents, event._id.toString(), search, offset, facetKey, location);
  }

  renderExponents = () => {
    const {
      state: { search, countExponents },
      props: { context, exponents, match, offers, participant, event, isFetching, intl },
    } = this;

    if (!isFetching && exponents.length === 0) {
      return (
        <BlankState
          content={isEmpty(omit(search, ['page'])) ? intl.formatMessage(messages.blankEmptySearch) : intl.formatMessage(messages.blank)}
          icon="blank-state-nothing-found-oops"
        />
      );
    } if (isFetching || countExponents === null) {
      return (<LoadingIndicator />);
    }

    const highlightWords = search.keywords ? search.keywords.split(' ') : [];

    const renderExponents = [];

    for (let i = 0; i < exponents.length; i += 2) {
      const exponent = exponents[i];

      const countExponentOffers = exponent && exponent.participantScore && participant ? exponent.participantScore : 0;
      const nextExponent = exponents[i + 1];
      const countNextExponentOffers = nextExponent && nextExponent.participantScore && participant ? nextExponent.participantScore : 0;

      renderExponents.push(
        <Row key={`rowExponent${i}`} gutter={20}>
          <Col xs={12} md={6} span={6}>
            <PresentationCard
              key={exponent._id}
              organization={exponent._organization}
              event={event}
              isList
              highlightWords={highlightWords}
              link={context === 'owner' ? `/${match.params.eventSlug}/owner/stands/${exponent._organization._id}/preview` : `/${match.params.eventSlug}/candidate/jobdating/exponents/${exponent._organization._id}`}
              countOffers={countExponentOffers}
              offers={offers}
              exponent={exponent}
              context={context === 'owner' ? 'owner' : undefined}
            />
          </Col>
          {nextExponent ? (
            <Col xs={12} md={6} span={6}>
              <PresentationCard
                key={nextExponent._id}
                organization={nextExponent._organization}
                event={event}
                isList
                highlightWords={highlightWords}
                link={context === 'owner' ? `/${match.params.eventSlug}/owner/stands/${nextExponent._organization._id}/preview` : `/${match.params.eventSlug}/candidate/jobdating/exponents/${nextExponent._organization._id}`}
                countOffers={countNextExponentOffers}
                offers={offers}
                exponent={nextExponent}
                context={context === 'owner' ? 'owner' : undefined}
              />
            </Col>
          ) : ''}
        </Row>
      );
    }

    return renderExponents;
  };


  render() {
    const {
      props: { context, allSectors, pagination, exponents, event, intl, defaultCriteria, participantExponentsLastSearch },
      state: { search, queryParams, countOffers, facetSectors },
      renderExponents, processQueryChange, handleOnChangePagination,
    } = this;

    const locale = getLocale();

    if (!exponents) {
      return '';
    }

    const labelMultiple = event.modules.organization[`labelMultiple_${locale}`].toLowerCase();
    const labelSingle = event.modules.organization[`label_${locale}`].toLowerCase();

    return (
      <div>
        <div className={styles.container}>
          <div className={styles.jobsHeader}>
            <div className="show-mobile">
              <SearchEngine
                event={event}
                search={search}
                onSearch={(search, matching, facetKey) => processQueryChange(search, facetKey)}
                exponents={exponents}
                context="exponent"
                intl={intl}
                countOffers={countOffers}
                countExponents={pagination.total}
                defaultCriteria={defaultCriteria}
                participantExponentsLastSearch={participantExponentsLastSearch}
                isShowCorners
              />
            </div>

            <If condition={context === 'owner'}>
              <div className="flex justify-between align-center mb-24">
                <H3>
                  <FormattedMessage id="event.participant.exponent.titleOwner" values={{ count: pagination.totalAll }} />
                </H3>
                <div>
                  <Button
                    onClick={() => window.open(`${process.env.FRONT_API_URL}/events/${event.slug}/export/exponents?apiKey=41a1289e-fee1-430e-a849-5b2b7ed23f1a`)}
                    variant="outline"
                    color="neutral"
                    imageComponentLeft={<IconDownload size={16} />}
                  >
                    {intl.formatMessage({ id: 'owner.stands.exportBtn' })}
                  </Button>
                </div>
              </div>
            </If>
            <If condition={context !== 'owner'}>
              <h1 className="mainTitle mainTitle--marginNegative">
                {event.modules.organization && !isEmpty(event.modules.organization[`label_${intl.locale}`]) ? (
                  <FormattedMessage {...messages.titleCustom} values={{ count: pagination.total, label: pagination.total > 1 ? labelMultiple : labelSingle }} />
                ) : (<FormattedMessage {...messages.title} values={{ count: pagination.total }} />)}
              </h1>
            </If>

            <div className="hidden-mobile">
              <SearchEngine
                event={event}
                search={search}
                onSearch={(search, matching, facetKey) => processQueryChange(search, facetKey)}
                exponents={exponents}
                sectors={facetSectors}
                defaultSectors={allSectors}
                context="exponent"
                statusFilter={context === 'owner'}
                hideReset={context === 'owner'}
                intl={intl}
                countOffers={countOffers}
                countExponents={pagination.total}
                defaultCriteria={defaultCriteria}
                participantExponentsLastSearch={participantExponentsLastSearch}
                isShowCorners
              />
            </div>
          </div>

          <If condition={context === 'owner' && pagination.total > 0}>
            <SearchResultsStats count={pagination.total} total={pagination.totalAll} style={{ marginBottom: '30px' }} />
          </If>

          {renderExponents()}

          <div className="pagination-container">
            <Pagination
              className="flex justify-center"
              current={queryParams.page ? parseInt(queryParams.page, 10) : 1}
              pageSize={pagination.pageSize}
              total={pagination.total}
              onChange={handleOnChangePagination}
            />
          </div>
        </div>
        <MobileHeader event={event} />
        <MobileMenu
          displayInformal={event.modules?.informal1to1?.enable}
          displayLive={event.modules.live && event.modules.live.participantEnable}
          liveLabel={event.modules.live && event.modules.live.labelMultiple}
        />
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
  participant: participantSelectors.getCurrentParticipant,
  event: eventSelectors.getCurrentEvent,
  exponents: exponentSelectors.getExponents,
  allSectors: sectorSelectors.getAllSectors,
  sectors: exponentSelectors.getSectors,
  facets: exponentSelectors.getFacets,
  offers: offerSelectors.getOffers,
  pagination: exponentSelectors.getExponentPagination,
  isFetching: exponentSelectors.getExponentsFetching,
  defaultCriteria: criterionSelectors.getDefaultCriteria,
  participantOffersLastSearch: offerSelectors.getParticipantOffersLastSearch,
  participantExponentsLastSearch: exponentSelectors.getParticipantExponentsLastSearch,
});

const mapDispatchToProps = {
  replace,
  getSectors: sectorActions.getSectors,
  getExponents: exponentActions.getExponents,
  getParticipantOffers: offerActions.getParticipantOffers,
  applyOffer: offerActions.applyOffer,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withLayout({ size: 'default', withHeader: false, navType: 'candidate' }),
  withRouter,
  withConnect,
  injectIntl,
)(toJS(ExponentsScene));
