import { useQuery } from 'react-query';

export function useGetDefaultCriteria() {
  const requestUrl = `${process.env.FRONT_API_URL}/defaultCriteria`;

  const queryFn = () => fetch(requestUrl, {
      method: 'GET'
  }).then((response) => response.json());

  const query = useQuery({
    queryKey: ['defaultCriteria'],
    queryFn,
    refetchOnWindowFocus: false,
  });
  return query;
}
