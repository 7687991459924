import React, { useEffect, useState } from 'react';
import { func, object, array, any } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { map, isUndefined, intersection, isEmpty, isNull, get, head, takeRight, toString, size, find } from 'lodash';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { Button } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { offerSelectors } from '@/store/offer';
import { exponentSelectors } from '@/store/exponent';
import { toJS } from '@/utils';
import { withAuth } from '@/containers/RequiredAuth';
import { Events } from './components';
import { TargetFilter } from '@/scenes/Events/scenes/components/JobdatingsExplore/components';
import { withExponents } from '@/scenes/Client/Hoc/RequiredExponents';
import styles from './styles.less';
import stylesBtn from '@/scenes/Client/scenes/EditOffer/styles.less';
import { getId } from '@/utils/global';

const filterUpcomingExponents = (exponents, user, targets = null) => {
  const filterExponent = (exponent, user) => exponent?._event?.modules?.offer?.enable && exponent?._event?.keyDates?.jobfair && moment().isBefore(exponent._event.keyDates.jobfair.endAt) &&
    !isEmpty(find(exponent.users, (u) => getId(u._user) === getId(user)))

  return exponents.filter((exponent) => targets ? filterExponent(exponent, user) && intersection(exponent._event.targets, targets).length > 0 : filterExponent(exponent, user));
}

const EditOfferStep2 = ({
  defaultTargets,
  exponents,
  offer,
  offerDB,
  unSavedOffer,
  setOffer,
  saveOffer,
  blank,
  goBack
}) => {
  const [eventsIds, setEventsIds] = useState([]);
  const [exponentsVisible, setExponentsVisible] = useState([]);
  const [targets, setTargets] = useState([]);
  const t = useFormatMessage();

  useEffect(() => {
    const eventsIds = (!isUndefined(unSavedOffer._events) && unSavedOffer._events)
      || (!isEmpty(offer.childrenEvents) ?
        map([offer._event, ...offer.childrenEvents], (event) => event && event._id ? event._id.toString() : event)
        : !isEmpty(offer._event) ? [offer._event._id.toString()] : []);

    setEventsIds(eventsIds);
    setExponentsVisible(filterUpcomingExponents(exponents, offer._user));
    setTargets(blank ? [] : defaultTargets);
  }, [])

  const getEvents = () => exponents.map((exponent) => exponent._event);

  const getCountDbEvents = () => size(offerDB._event) > 0;

  const saveForm = () => {
    const eventsIdsVisible = filterUpcomingExponents(exponents, offer._user).map(expo => getId(expo._event));

    if (eventsIds.length) {
      const events = getEvents();
      const newEventsIds = eventsIds.filter((eventId) => !isEmpty(events.find((evnt) => toString(evnt._id) === eventId)) && !isEmpty(eventsIdsVisible.find((eventIdVisible) => eventIdVisible === eventId)));

      const childrenEventIds = !isEmpty(offerDB.childrenOffers) ? offerDB.childrenOffers.map((childOffer) => toString(get(childOffer, '_event._id'))) : [];
      const eventsDBSelected = [toString(get(offerDB, '_event._id')), ...childrenEventIds];
      const hasChanged = !newEventsIds.reduce((prev, val) => {
        if (isNull(prev)) return true;
        return prev && eventsDBSelected.indexOf(val) > -1;
      }, null);
      const eventSelected = newEventsIds.map((eventId) => ({ _event: events.find((evnt) => toString(evnt._id) === eventId) }));
      const childrenOffers = eventSelected.length > 1 ? (!isEmpty(offerDB.childrenOffers) && offerDB.childrenOffers.filter((off) => childrenEventIds.indexOf(toString(get(off, '_event._id'))) > -1)) || takeRight(eventSelected, eventSelected.length - 1) : [];

      setOffer({ _events: newEventsIds, _event: head(eventSelected)._event, childrenOffers }, hasChanged);
      saveOffer({ _events: newEventsIds });
    } else {
      saveOffer({ _events: eventsIds });
    }
  };

  const handleOnTargetsFilter = (targets) => {
    const exponentsVisible = filterUpcomingExponents(exponents, offer._user, targets);

    setTargets(targets);
    setExponentsVisible(exponentsVisible);
  };

  const handleEventsSelected = (eventsId) => {
    setOffer({ _events: eventsId });
    setEventsIds(eventsId);
  };

  const disableNextBtn = isEmpty(exponentsVisible.find((expo) => expo && eventsIds.indexOf(toString(expo._event._id)) > -1));

  return (
    <div className={styles.editOfferStep2Container}>
      <h1>{t({ id: 'client.create.offer.events.title' })}</h1>
      <h3>{t({ id: 'client.create.offer.events.subtitle' })}</h3>
      <div className={styles.targetFilterContainer}>
        <TargetFilter targetFilters={['tech', 'business', 'engineer']} targets={targets} onTargetsChange={handleOnTargetsFilter} />
      </div>
      {blank || <Events offer={offer} exponents={exponentsVisible} events={exponentsVisible.map((expo) => expo._event)} handleEventsSelected={handleEventsSelected} defaultValue={eventsIds} context="offer" />}
      <div className={stylesBtn.submitContainer}>
        <Button onClick={goBack} variant="outline" color="neutral">
          {t({ id: 'prev' })}
        </Button>
        <Button disabled={getCountDbEvents() && eventsIds.length === 0 ? false : (blank ? true : disableNextBtn)} onClick={saveForm}>
          {getCountDbEvents() && eventsIds.length === 0 ? t({ id: 'client.create.offer.events.btn.remove.all.jd' }) : t({ id: 'next' })}
        </Button>
      </div>
    </div>
  );

}

EditOfferStep2.propTypes = {
  defaultTargets: array,
  exponents: array,
  offer: object,
  offerDB: object,
  unSavedOffer: object,
  setOffer: func,
  saveOffer: func,
  blank: any,
  goBack: func
}

const mapStateToProps = createStructuredSelector({
  offers: offerSelectors.getOffers,
  exponents: exponentSelectors.getExponents,
});

const withConnect = connect(mapStateToProps, null);

export default compose(
  withRouter,
  withExponents(),
  withConnect,
  withAuth(),
)(toJS(EditOfferStep2));
