import React from 'react';
import { any, func, string } from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';

import { Typography, Button } from '@seekube-tech/ui';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import Checkbox from '@/components/Checkbox';

import { LOCAL_STORAGE, setLocalStorage } from '@/utils/localStorage';

import useStyles from './styles';

const InteractionsAreClosedSoon = ({ title, interactionsClosingBeginAt, handleConfirm, handleCancel }) => {
  const styles = useStyles();

  return (
    <div>
      <Typography
        variant="h4"
        className="mb-35"
      >
        {title}
      </Typography>
      <Typography
        variant="body1"
        color="neutral[500]"
      >
        <FormattedHTMLMessage
          id="event.recruiter.appointmentModal.interaction.soon.close.description"
          values={{ date: moment(interactionsClosingBeginAt).format('HH[h]') }}
        />
      </Typography>

      <div className={classnames('confirm-actions', styles.actions)}>
        <Button
          color="primary"
          onClick={handleConfirm}
        >
          <FormattedMessage id="btn.confirm"/>
        </Button>

        <Typography
          variant="link1"
          onClick={handleCancel}
        >
          <FormattedMessage id="cancel"/>
        </Typography>
      </div>

      <Checkbox
        className={styles.checkbox}
        onChange={(e) => setLocalStorage(LOCAL_STORAGE.RECRUITER.hideConfirmInteractionSoonClosed, e.target.checked)}
      >
        <Typography
          variant="body2"
          color="neutral[500]"
        >
          <FormattedMessage id="do.not.display.message"/>
        </Typography>
      </Checkbox>

      <Typography
        variant="body2"
        color="neutral[300]"
        className={styles.additionalText}
      >
        <FormattedMessage id="event.recruiter.appointmentModal.interaction.soon.additionnal.text"/>
      </Typography>
    </div>
  )
}

InteractionsAreClosedSoon.propTypes = {
  title: any,
  interactionsClosingBeginAt: string,
  handleConfirm: func,
  handleCancel: func,
}


export { InteractionsAreClosedSoon }
