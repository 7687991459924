import React from 'react';
import { FormattedMessage } from 'react-intl';
import { func } from 'prop-types';

import { Button } from '@seekube-tech/ui-kit';

import messages from '../../messages';
import styles from './styles.less';

const Result = ({ nextStep, setContext }) => (
  <div className={styles.result}>
    <FormattedMessage tagName="h2" {...messages.resultTitle} />
    <hr />

    <FormattedMessage tagName="div" {...messages.resultMainText} />

    <div className={styles.actions}>
      <Button onClick={() => { nextStep(); setContext('yes'); }}>
        <FormattedMessage {...messages.resultBtnYes} />
      </Button>
      <Button onClick={() => { nextStep(); setContext('no'); }}>
        <FormattedMessage {...messages.resultBtnNo} />
      </Button>
    </div>
  </div>
);

Result.propTypes = {
  nextStep: func,
  setContext: func,
};

export default Result;
