import React from 'react';
import { Link } from 'react-router-dom';
import { object, string, oneOfType, instanceOf, func } from 'prop-types';
import { take, map, get, size } from 'lodash';
import classnames from 'classnames';
import { EVENT_FORMAT, EVENT_STATUS } from '@/utils/constants';

import Avatar from '@/components/Avatar';
import { Tooltip } from '@/components/Tooltip';


import styles from '../../../styles.less';
import { getUserRole } from '../../../helpers';
import HybridTagMenu from './HybridTagMenu';
import { useSelector } from 'react-redux';
import { getCurrentParticipant } from '@/store/participant/selectors';

const EventInfos = ({
  event,
  context,
  authUser,
  authUserConnectedAs,
  targetDate,
}) => {
  if (!event || context !== 'event' || !targetDate) {
    return '';
  }

  const role = getUserRole(authUser, authUserConnectedAs);

  return (
    <div className={styles.event}>
      <PlannersLogo event={event} />
      <EventData role={role} event={event} authUser={authUserConnectedAs || authUser} />
    </div>
  );
};


const PlannersLogo = ({ event }) => {
  if (event.type === 'seekube') return null;
  const twoFirstOrga = take(get(event, 'planners._organizations'), 2);

  return (
    <div className={classnames(styles.eventPicture, size(twoFirstOrga) === 2 ? styles.multiple : null)}>
      {
        map(twoFirstOrga,
          (orga, i) => <Avatar key={i} type="rounded" size="40" src={get(orga, 'profile.pictureUrl')} imageFit="contain" />
        )
      }
    </div>
  );
};

const EventData = ({ event, role }) => {
  if (!event || !event.keyDates) return '';

  const participant = useSelector(getCurrentParticipant)?.toJS();

  let link = '';
  const { dateStatus } = event || {};

  if (role === 'candidate') {
    link = '/candidate/jobdating/jobs';
  } else if (role === 'recruiter') {
    // PHASE DE DISCOVERING
    if ([EVENT_STATUS.PREREGISTRATION, EVENT_STATUS.DISCOVERING].includes(dateStatus)) {
      link = '/recruiter/preparation';

      // PHASE DE RENCONTRE
    } else if ([EVENT_STATUS.IN_PROGRESS, EVENT_STATUS.OPEN].includes(dateStatus)) {
      link = '/recruiter/jobdating';
      // PHASE DE CVTHEQUE
    } else if ([EVENT_STATUS.CLOSED, EVENT_STATUS.HUNT_POST_EVENT].includes(dateStatus)) {
      link = '/recruiter/jobdating/sourcing';
    }
  }

  return (
    <div className={styles.eventData}>
      <Tooltip
        overlayStyle={{ whiteSpace: 'normal', zIndex: 5 }}
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
        color="dark"
        title={event.name}
        placement="bottom"
      >
        <Link className={styles.eventName} to={`/${event.slug}${link}`}>{event.name}</Link>
      </Tooltip>
      {event.format === EVENT_FORMAT.HYBRID && participant &&
        <HybridTagMenu participant={participant} />
      }
    </div>
  );
};

EventInfos.propTypes = {
  event: object,
  authUser: object,
  authUserConnectedAs: object,
  context: string,
  targetDate: oneOfType([string, instanceOf(Date)]),
};

PlannersLogo.propTypes = {
  event: object,
};

EventData.propTypes = {
  event: object,
  role: string,
  authUser: object,
};

export { PlannersLogo, EventInfos };
