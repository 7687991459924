import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import styles from '../styles.less';
import { Field } from 'react-final-form';
import { bool } from 'prop-types';
import { TextField } from '@seekube-tech/ui';
import { Button, IconTrash2, IconPlusCircle, H6 } from '@seekube-tech/ui-kit';
import PictureStep from './PictureStep';
import { FieldArray } from 'react-final-form-arrays';

const Steps = ({ disabledFields, organizationId, push}) => {
  const t = useFormatMessage();

  return (
    <div className={styles.stepsContainer}>
      <div className={styles.headerSteps}>
        <H6>{t({id: 'steps.title'})}</H6>
      </div>
      {!disabledFields &&
        <Field
          name="stepPicture"
          component={PictureStep}
          organizationId={organizationId}
          disabled={disabledFields}
        />
      }
      <div className={styles.stepsList}>
        <FieldArray name="stepsJoin">
          {({ fields }) =>
            fields.map((name, index) => (
              <div className={styles.keyNumberField} key={name}>
                <Field
                  name={`${name}`}
                  id={`${name}`}
                  label={`${t({ id: 'step'})} ${index + 1}`}
                  placeholder={t({ id: 'step'})}
                  component={TextField}
                  fullWidth
                  disabled={disabledFields}
                />
                <Button
                  onClick={() => fields.remove(index)}
                  disabled={disabledFields}
                  variant='tonal'
                  color='error'
                  imageComponentLeft={<IconTrash2 size={16}/>}
                >
                  {t({id: 'delete'})}
                </Button>
              </div>
            ))
          }
        </FieldArray>
      </div>
      <div className={styles.addKeyNumbersContainer}>
        <Button
          disabled={disabledFields}
          onClick={() => push('stepsJoin', undefined)}
          variant='outline'
          imageComponentLeft={<IconPlusCircle size={16} />}
        >
          {t({ id: 'add.step' })}
        </Button>
      </div>
    </div>
  );
}

Steps.propTypes = {
  disabledFields: bool,
};

export default Steps;
