import React, { memo } from 'react';
import { object, func, bool } from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { Dropdown, Menu } from 'antd';
import { Button, IconChevronDown, IconDownload } from '@seekube-tech/ui-kit';
import { toJS } from '@/utils';
import { useFormatMessage } from 'react-intl-hooks';

import { eventSelectors } from '@/store/event';
import { authSelectors } from '@/store/auth';
import { participantSelectors } from '@/store/participant';

import Wrapper from '@/components/Wrapper';

import { StatisticsMenu } from './containers/StatisticsMenu';
import { StatisticsStats } from './components/StatisticsStats';
import OwnerParticipantsScene from './scenes/Participants';
import OwnerExponentsScene from './scenes/Exponents';
import OwnerExponentsRankingsScene from './scenes/ExponentsRankings';
import { useStats } from './effects';
import { Bloc } from '../../../../../../components/Bloc';

import messages from './messages';
import styles from './styles.less';

const { Item: MenuItem } = Menu;

const OwnerStatisticPage =
  ({
    match: { path },
    match,
    event,
    authUser,
  }) => {
    const {
      offersStats,
      exponentsUsersStats,
      exponentsActivitiesStats,
      exponentsStats,
      appointmentsStats,
      participantsStats,
    } = useStats({ ...event });

    const eventStats = {
      averageParticipantsAppointments: 0,
      averageOrganizationsAppointments: 0,
    };

    const handleExport = (type) => {
      const exportUrl = `${process.env.FRONT_EXPORT_API_URL}/events/${event.slug}/export/${type}?apiKey=41a1289e-fee1-430e-a849-5b2b7ed23f1a`;

      window.open(exportUrl, '_blank');
    };

    return (
      <Wrapper type="scene" context="owner">
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.pageTitle}>
              <FormattedMessage {...messages.pageTitle} tagName="h2" />
            </div>

            <div className={styles.actions}>
              <ExportBtn handleDownload={handleExport} admin={authUser?.isAdmin} />
            </div>
          </div>

          <StatisticsStats
            stats={{ ...exponentsStats, ...appointmentsStats, ...eventStats }}
            goals={event.goals}
            enableCoreTarget={event.enableCoreTarget}
          />

          <Bloc>
            <StatisticsMenu match={match} />

            <Switch>
              <Route path={`${path}/participants`} render={() => <OwnerParticipantsScene stats={{ exponentsActivitiesStats, exponentsStats, appointmentsStats, participantsStats }} />} />
              <Route path={`${path}/exponents`} render={() => <OwnerExponentsScene stats={{ exponentsActivitiesStats, exponentsStats, exponentsUsersStats, appointmentsStats }} />} />
              <Route path={`${path}/exponents-rankings`} component={() => <OwnerExponentsRankingsScene stats={{ exponentsActivitiesStats, offersStats }} />} />
              <Redirect to={`${path}/participants`} />
            </Switch>
          </Bloc>
        </div>
      </Wrapper>
    );
  };

const ExportBtn = ({ handleDownload, admin }) => {
  const t = useFormatMessage();

  const menuItemsData = [
    { downloadType: 'appointments', text: <FormattedMessage {...messages.exportAppointments} /> },
    { downloadType: 'candidates', text: <FormattedMessage {...messages.exportCandidates} /> },
    { downloadType: 'candidates-not-active', text: <FormattedMessage {...messages.exportCandidatesNotActive} /> },
    { downloadType: 'preregistrations', text: <FormattedMessage {...messages.exportPreregistrations} /> },
    { downloadType: 'organizations', text: <FormattedMessage {...messages.exportCompanies} /> },
    { downloadType: 'recruiters', text: <FormattedMessage {...messages.exportRecruiters} /> },
    { downloadType: 'offers', text: <FormattedMessage {...messages.exportOffers} /> },
    { downloadType: 'interactions', text: <FormattedMessage {...messages.exportInteractions} /> },

  ];

  if (admin) {
    menuItemsData.push(...[
      { downloadType: 'kpi', text: <FormattedMessage {...messages.exportKpi} /> },
      { downloadType: 'conversion', text: <FormattedMessage {...messages.exportConversion} /> },
      { downloadType: 'candidates-filters', text: <FormattedMessage {...messages.exportFilters} /> }
    ]);
  }

  return (
    <Dropdown
      overlay={
        <Menu>
          {menuItemsData.map((menuItemData, i) =>
            <MenuItem key={i}><a role="button" tabIndex={0} onClick={() => handleDownload(menuItemData.downloadType)}>{menuItemData.text}</a></MenuItem>)}
        </Menu>
      }
      trigger={['click']}
      placement="bottomRight"
    >
      <Button
        variant="outline"
        color="neutral"
        imageComponentLeft={<IconDownload size={16} />}
        imageComponentRight={<IconChevronDown size={16} />}
      >
        {t({ id: 'export.data' })}
      </Button>
    </Dropdown>
  );
};

ExportBtn.propTypes = {
  handleDownload: func,
  admin: bool,
};

OwnerStatisticPage.propTypes = {
  match: object,
  authUser: object,
  event: object,
};

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
  event: eventSelectors.getCurrentEvent,
  stats: participantSelectors.getParticipantsStats,
});

const withConnect = connect(mapStateToProps, null);

export default compose(
  withConnect,
  injectIntl,
  toJS,
  memo,
)(OwnerStatisticPage);
